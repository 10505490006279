import { debounce } from 'lodash';
import { useEffect, useState } from 'react';
import { tabletMaxWidth, tabletMinWidth } from 'appConstants';

export type UseWindowWidth = [number, boolean, boolean];

export const isTabletView = (width: number): boolean => width < tabletMaxWidth;
export const isMobileView = (width: number): boolean => width < tabletMinWidth;

export function useWindowWidth(debounceDelay: number = 300): UseWindowWidth {
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const [isTablet, setTabletState] = useState<boolean>(isTabletView(windowWidth));
  const [isMobile, setMobileState] = useState<boolean>(isMobileView(windowWidth));

  const windowResizeHandler = debounce(() => {
    const nextWidth = window.innerWidth;
    setWindowWidth(nextWidth);
    setTabletState(isTabletView(nextWidth));
    setMobileState(isMobileView(nextWidth));
  }, debounceDelay);

  useEffect(() => {
    window.addEventListener('resize', windowResizeHandler);
    return () => {
      window.removeEventListener('resize', windowResizeHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [windowWidth, isTablet, isMobile];
}
