import { getCookieByName } from 'services/cookies';
import { AppState } from 'store/rootReducer';
import {
  AnalyticsPageOptions,
  AvailablePages,
  LineChartMode,
  PdfCookiesTypes,
  CustomerTypes,
  PdfChartsModeMap,
  PdfCommonOptionsMap
} from './types';

export const getPdfCookies = (): Partial<PdfCookiesTypes> =>
  Object.entries({ ...PdfChartsModeMap, ...PdfCommonOptionsMap }).reduce(
    (accum, [paramName, cookieName]) => {
      const cookieValue = getCookieByName(cookieName);

      return {
        ...accum,
        [paramName]: cookieName === PdfCommonOptionsMap.pdfMode ? cookieValue === 'true' : cookieValue
      };
    },
    { pdfMode: false }
  );

export const isPdfModeEnabled = (state: AppState): boolean => state.pdf[PdfCommonOptionsMap.pdfMode];

export const getAnalyticsPdfState = (state: AppState): AnalyticsPageOptions => state.pdf[AvailablePages.analytics];

export const getLineChartMode = (state: AppState): LineChartMode =>
  getAnalyticsPdfState(state)[PdfChartsModeMap.lineChartMode];
export const getLineChartPredictiveDataEnabled = (state: AppState): boolean =>
  getAnalyticsPdfState(state)[PdfChartsModeMap.lineChartPredictiveData];

export const getCustomerTypeByChartName = (
  state: AppState,
  name: Exclude<PdfChartsModeMap, PdfChartsModeMap.lineChartMode | PdfChartsModeMap.lineChartPredictiveData>
): CustomerTypes => getAnalyticsPdfState(state)[name];

export const isPdfLoading = (state: AppState): boolean => state.pdf.loading;
