export const COOKIES_GROUPS = {
  STRICTLY_NECESSARY: 'C0001',
  PERFORMANCE: 'C0002',
  FUNCTIONAL: 'C0003',
  TARGETING: 'C0004',
  SOCIAL_MEDIA: 'C0005',
  VIDEO: 'VID1'
};

export const oneTrust = {
  init(scriptID: string, onChangeHandler: () => void) {
    this.loadOnetrustScript(scriptID);
    // @ts-ignore
    window.OptanonWrapper = function OptanonWrapper() {
      onChangeHandler();
    };
  },

  getFeatureStatus(cookieGroup: string, setStatus: (status: boolean) => void) {
    // @ts-ignore
    const status = !!(window.OptanonActiveGroups || '').includes(cookieGroup);
    setStatus && setStatus(status);
  },

  loadOnetrustScript(scriptID: string) {
    console.log(`OneTrust is starting, script ID: ${scriptID}`);
    const script = document.createElement('script');
    script.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
    script.type = 'text/javascript';
    script.charset = 'UTF-8';
    script.setAttribute('data-domain-script', scriptID);
    script.setAttribute('data-document-language', 'true');
    document.head.appendChild(script);
  },

  openConsentScreen() {
    /* eslint-disable */
    // @ts-ignore
    window['OneTrust']?.ToggleInfoDisplay();
  }
};
