import { call, takeLatest, put, select } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { push } from 'connected-react-router';
import { TERMS_MODAL_COMPONENT, MASTERCARD } from '../../appConstants';
import { getLogoutUrl, getPortalUrl } from '../branding';
import { ApiGateway } from '../../services/apiGateway';
import { setLocal } from '../../services/localStorage';
import { termsActions } from './actions';
import { AppState } from '../rootReducer';
import {
  getUserLocations,
  isFeatureOrComponentEnabledInRootOrNestedElements,
  isTermsConfirmedForPageRedirect
} from '../settings';
import { getLocalKey } from './selectors';
import { isTeaser } from '../navigation';

export function* TermsSagas() {
  yield takeLatest(
    getType(termsActions.getData.request),
    function* (action: ReturnType<typeof termsActions.getData.request>) {
      const { locale, type, withPersonalData } = action.payload;
      try {
        const response = yield call(ApiGateway.getTerms, {
          // TODO: remove this workaround after old ui will be deprecated
          type: type === MASTERCARD ? 'masterCard' : type,
          locale,
          withPersonalData
        });

        yield put(
          termsActions.getData.success({
            type,
            termsText: response.termsHtml,
            personalDataText: response.personalDataHtmml
          })
        );
      } catch (e) {
        yield put(termsActions.getData.failure({ type, error: e.message }));
      }
    }
  );

  yield takeLatest(
    getType(termsActions.confirmTerms),
    function* (action: ReturnType<typeof termsActions.getData.request>) {
      const state: AppState = yield select();
      const locations = getUserLocations(state).map(location => location.merchant_sequence_key);

      try {
        yield call(ApiGateway.agreeTerms, { dataProvider: action.payload });
        setLocal(getLocalKey(locations, action.payload), false);
      } catch (e) {
        console.log(e);
      }
    }
  );

  yield takeLatest(termsActions.cancelTerms, function* (action: ReturnType<typeof termsActions.cancelTerms>) {
    const state: AppState = yield select();
    const pageForRedirect = state.appMenu.pages
      .filter(
        page =>
          !isFeatureOrComponentEnabledInRootOrNestedElements(state, page.pageId, TERMS_MODAL_COMPONENT, action.payload)
      )
      .filter(page => !isTeaser(page))
      .find(page => isTermsConfirmedForPageRedirect(state, page));

    if (pageForRedirect) {
      yield put(push(pageForRedirect.path));
    } else {
      window.location.replace(getLogoutUrl(state) || getPortalUrl(state));
    }
  });
}
