import styled, { FlattenInterpolation } from 'styled-components';
import moment, { Moment } from 'moment';
import React, { useEffect, useRef } from 'react';
import { tint } from 'polished';
import { getColor } from 'layout/theme';
import { resetButtonStyles, textRegular } from 'layout/mixins';
import { deviceMedia } from 'appConstants';
import { localizationService } from 'services/localization';
import { dateFormatter } from 'services/dateFormatter';
import { ReactComponent as CalendarIcon } from '../../assets/icons/calendar.svg';
import { Button } from '../Button';
import { DateRange } from './index';

export const StyledCalendarIcon = styled(CalendarIcon)<{ color?: string }>`
  width: 15px;
  min-width: 15px;
  height: 18px;
  [fill^='#'] {
    fill: ${props => props.color || getColor(props, 'darkGrey')};
  }
`;

export const DateRangePickerContainer = styled.div`
  position: relative;
  display: inline-block;
  @media ${deviceMedia.mobile} {
    display: block;
    width: 100%;
  }
`;

export const StyledP = styled.p`
  font-size: 16px;
  color: ${props => getColor(props, 'darkGrey')};
  @media ${deviceMedia.mobile} {
    padding: 0px 3px;
    font-size: 14px;
  }
`;

export const DateRangePickerDropdownElement = styled.div<{
  positionStyles: string;
  isRightSide?: boolean;
  additionalStyles?: FlattenInterpolation<any>;
}>`
  border: 1px solid ${props => getColor(props, 'blueGrey')};
  position: absolute;
  top: 100%;
  z-index: 1;
  background: #fff;
  margin: 10px -10px;
  ${props => (props.isRightSide ? 'right: -25px' : props.positionStyles)};

  &:before,
  &:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid ${props => getColor(props, 'blueGrey')};
    position: absolute;
    bottom: 100%;
    margin: 0 10px;
    ${props => props.positionStyles};
    ${({ isRightSide }) => (isRightSide ? 'right: 25px' : '')};
  }
  &:after {
    border-bottom-color: white;
    margin-bottom: -1px;
  }
  @media ${deviceMedia.mobile} {
    margin: 0 -15px;
    border-width: 0 0 1px 0;
    left: 0;
    right: 0;
    &:before,
    &:after {
      display: none;
    }
  }
  ${({ additionalStyles }) => additionalStyles};
`;

export const AdvancedDatePickerRangesContainer = styled.div`
  width: 100%;
  padding: 5px 0px;
  min-width: 140px;
  display: flex;
  column-gap: 20px;
  @media ${deviceMedia.mobile} {
    width: 100%;
    flex-wrap: wrap;
    column-gap: 10px;
    justify-content: center;
    text-align: center;
  }
`;

export const DateRangePickerFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 8px;
  background: ${props => getColor(props, 'lightGrey')};
  border-top: 1px solid ${props => getColor(props, 'blueGrey')};
  > * {
    margin: 0 3px;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
`;

export const DateRangeItem = styled(Button)<{ selected: boolean }>`
  border-color: transparent;
  width: 100%;
  margin-bottom: 8px;
  min-height: auto;
  font-size: 12px;
  text-align: left;
  background: ${props => getColor(props, props.selected ? 'color1' : 'lightGrey')};
  color: ${props => getColor(props, props.selected ? 'white' : 'color1')};
  ${props => (props.selected ? 'font-weight: bold;' : '')};
  &:last-child {
    margin-bottom: 0;
  }
  @media ${deviceMedia.mobile} {
    width: 31%;
    margin: 0 1% 8px;
    text-align: center;
    padding-left: 5px;
    padding-right: 5px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    &:nth-child(3n + 3) {
      margin-right: 0;
    }
    &:nth-child(3n + 1) {
      margin-left: 0;
    }
  }
`;

export const AdvancedDateRangeItem = styled(DateRangeItem)<{ selected: boolean }>`
  ${props => `border: 1px solid ${props.theme.baseColors.grey};`};
  margin: 0 0 8px !important;
  padding: 8px 10px;
  background: ${props => getColor(props, props.selected ? 'color1' : 'white')};
  color: ${props => getColor(props, props.selected ? 'white' : 'darkGrey')};
  ${props => (props.selected ? 'font-weight: bold;' : '')};

  &:last-child {
    margin-bottom: 8px;
  }
`;

export const DateRangePickerToggle = styled.button<{ color: string }>`
  ${resetButtonStyles};
  color: ${props => props.color};
  display: flex;
  align-items: center;
  span {
    @supports (-webkit-overflow-scrolling: touch) {
      margin-bottom: -3px;
    }
  }

  strong {
    margin: 0 5px;
  }

  @media ${deviceMedia.mobile} {
    width: 100%;
    padding: 15px 0;
    justify-content: center;
  }
`;

export const PickerContent = styled.div`
  display: flex;
  padding: 8px;
  @media ${deviceMedia.mobile} {
    flex-direction: column;
  }
`;

export const DatePickerRangesContainer = styled.div`
  width: 140px;
  min-width: 140px;
  @media ${deviceMedia.mobile} {
    width: 100%;
    text-align: center;
  }
`;

export const ReactDatesWrapper = styled.div`
  .CalendarMonth_caption {
    font-size: 12px;
    padding: 10px 0 30px;
  }
  .DayPicker_weekHeader {
    top: 30px;
    font-weight: bold;
    font-size: 12px;
    color: ${props => getColor(props, 'darkGrey')};
  }
  .DayPickerNavigation {
    height: 0;
    display: flex;
    justify-content: space-between;
    padding: 0 15px;
    top: 8px;
  }
  .CalendarDay__default {
    border-color: transparent !important;
    font-size: 12px;
    color: ${props => getColor(props, 'darkGrey')};

    &.CalendarDay__highlighted_calendar {
      background: none;
    }

    &.CalendarDay__outside,
    &.CalendarDay__blocked_out_of_range {
      background: transparent !important;
      color: ${props => getColor(props, 'darkGrey')} !important;
      font-weight: normal !important;
      opacity: 0.5;
      pointer-events: none;
    }

    &.CalendarDay__selected,
    &.CalendarDay__selected:active,
    &.CalendarDay__selected:hover {
      background: ${props => getColor(props, 'color1')};
      color: #fff;
      font-weight: bold;

      &.CalendarDay__highlighted_calendar {
        background: ${props => getColor(props, 'darkGrey')};
      }
    }
    &.CalendarDay__selected_span,
    &.CalendarDay__hovered_span,
    &.CalendarDay__hovered_span:hover {
      background: ${props => tint(0.9, getColor(props, 'color1'))};
      color: ${props => getColor(props, 'darkGrey')};

      &.CalendarDay__highlighted_calendar {
        background: ${props => getColor(props, 'grey')};
      }
    }

    &:hover {
      color: ${props => getColor(props, 'darkGrey')};
    }
  }

  .CalendarMonthGrid_month__hidden {
    display: none;
  }
  .DayPicker_transitionContainer__vertical {
    height: auto !important;
  }
`;

const DateLabelWrapper = styled.div`
  width: 100%;
  border: 1px solid ${props => props.theme.baseColors.grey};
  outline: none;
  color: #54575a;
  font-size: 14px;
  line-height: 1.25;
  padding: 5px 10px;
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 10px;
  @media ${deviceMedia.mobile} {
    width: 100%;
  }
`;

const StyledInput = styled.input`
  width: 100%;
  border: 1px solid ${props => getColor(props, 'grey')};
  outline: none;
  color: ${props => getColor(props, 'darkGrey')};
  ${textRegular};
  line-height: 1.25;
  padding: 5px 10px 4px 25px;
  border-radius: 0;
`;

const InputsContainer = styled.div<{ isAdvancedCalendar?: boolean }>`
  ${props => (props.isAdvancedCalendar ? 'margin-bottom: 15px; column-gap: 20px;' : '')};
  display: flex;

  @media ${deviceMedia.mobile} {
    column-gap: 0;
  }
  > * {
    ${props => (props.isAdvancedCalendar ? '' : 'padding: 0 15px;')};
    flex: 1;
    @media ${deviceMedia.mobile} {
      padding: 0 5px;
    }
  }
`;

const InputWrapper = styled.div`
  position: relative;
  svg {
    position: absolute;
    left: 7px;
    top: 0;
    bottom: 0;
    margin: auto;
  }
`;

interface DateInputProps {
  value: Moment;
  onChange: (value: Moment) => void;
  validationFn?: (nextValue: Moment) => boolean;
}
const DateInput = ({ value, onChange, validationFn = () => true }: DateInputProps) => {
  const formatter = localizationService.getDateTimeFormat('monthDateYearBySlash');

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (value && inputRef && inputRef.current) inputRef.current.value = value.format(formatter);
  }, [value, formatter]);

  const inputBlurHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.currentTarget) {
      const nextDate = moment(e.currentTarget.value, formatter, true);
      if (nextDate.format(formatter) !== value.format(formatter)) {
        onChange(nextDate.isValid() && validationFn(nextDate) ? nextDate : moment(value, formatter));
      }
    }
  };

  return (
    <InputWrapper data-test-id="input-wrapper">
      <StyledCalendarIcon data-test-id="field-calendar-icon" />
      <StyledInput
        onKeyDown={e => {
          if (e.keyCode === 13) e.currentTarget.blur();
        }}
        ref={inputRef}
        onBlur={inputBlurHandler}
        data-test-id="input"
      />
    </InputWrapper>
  );
};

interface ManualDateRangeInputsProps extends DateRange {
  onChange: (range: DateRange) => void;
}

export const ManualDateRangeInputs = (props: ManualDateRangeInputsProps) => {
  const { onChange, startDate, endDate } = props;
  if (endDate && startDate) {
    return (
      <InputsContainer>
        <div data-test-id="start-date-field">
          <DateInput
            value={startDate}
            onChange={val => {
              onChange({ startDate: val, endDate: val >= endDate ? val : endDate });
            }}
          />
        </div>
        <div data-test-id="end-date-field">
          <DateInput
            onChange={val => {
              onChange({ startDate: val <= startDate ? val : startDate, endDate: val });
            }}
            value={endDate || moment()}
          />
        </div>
      </InputsContainer>
    );
  }
  return null;
};

interface ManualAdvancedDateRangeInputsProps extends DateRange {
  isAdvancedCalendar?: boolean;
}

export const ManualAdvancedDateRangeInputs = (props: ManualAdvancedDateRangeInputsProps) => {
  const { startDate, endDate, isAdvancedCalendar } = props;
  const formatLabelDate = (date: Moment | null): string | null => {
    if (date) {
      return dateFormatter.dateMonthYear(date);
    }
    return date;
  };
  const startDateString = formatLabelDate(startDate);
  const endDateString = formatLabelDate(endDate);

  return (
    <InputsContainer isAdvancedCalendar={isAdvancedCalendar}>
      <div data-test-id="start-date-field">
        <DateLabelWrapper>
          <StyledCalendarIcon />
          {startDateString}
        </DateLabelWrapper>
      </div>
      <div data-test-id="end-date-field">
        <DateLabelWrapper data-test-id="end-date-field">
          <StyledCalendarIcon />
          {endDateString}
        </DateLabelWrapper>
      </div>
    </InputsContainer>
  );
};
