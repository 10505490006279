import { createAction, createAsyncAction } from 'typesafe-actions';

import { FetchReviewsResponse, PaginationOptions, Review } from './types';

const fetchReviews = createAsyncAction(
  '@merchantCentric/reviews/fetch',
  '@merchantCentric/reviews/fetch/success',
  '@merchantCentric/reviews/fetch/error'
)<void, FetchReviewsResponse, void>();

const getNewestReviews = createAction(
  '@merchantCentric/reviews/getNewestReviews',
  action => (payload: Review[]) => action(payload)
);

const pagination = {
  setPagination: createAction(
    '@merchantCentric/reviews/pagination/setPagination',
    action => (paginationOptions: PaginationOptions) => action(paginationOptions)
  )
};

const reset = createAction('@merchantCentric/reviews/reset');

const reviewsActions = {
  fetchReviews,
  getNewestReviews,
  pagination,
  reset
};

export default reviewsActions;
