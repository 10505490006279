import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import React, { ReactElement, useRef, useEffect, useState } from 'react';
import { ReactComponent as QuestionIcon } from 'assets/icons/question.svg';
import { generateUID } from 'services/utils';
import { textMetaInfo } from 'layout/mixins';
import { getColor } from 'layout/theme';
import { deviceMedia } from '../appConstants';

interface IconProps {
  Size?: number;
  skipIconFill?: boolean;
  customVerticalAlign?: string;
  isNowrap?: boolean;
}

export const getSize = (props: IconProps): number => props.Size || 20;
export const Container = styled.div<IconProps>`
  width: ${props => getSize(props)}px;
  height: ${props => getSize(props)}px;
  min-width: ${props => getSize(props)}px;
  ${({ isNowrap }) => (isNowrap ? 'white-space: nowrap;' : '')};
  display: inline-block;
  margin-left: 5px;
  cursor: help;
  vertical-align: ${({ customVerticalAlign }) => customVerticalAlign || 'top'};

  svg {
    width: 100%;
    height: 100%;
    ${props => (!props.skipIconFill ? `fill: ${getColor(props, 'darkGrey')};` : '')};
  }
`;
Container.displayName = 'Container';

interface ToolTipWrapperProps {
  left?: number;
  textAlign?: string;
  withoutCustomMobileTooltip?: boolean;
  width?: number;
  isLeftPosition?: boolean;
  isFlexibleToContent?: boolean;
}

export const ToolTipWrapper = styled.div<ToolTipWrapperProps>`
  .__react_component_tooltip {
    max-width: ${props => props.width || 250}px;
    white-space: normal;
    font-weight: normal;
    background-color: rgba(79, 79, 79, 0.9);
    box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.5);
    text-transform: none;
    text-align: ${props => props.textAlign || 'inherit'};
    ${({ isFlexibleToContent }) =>
      isFlexibleToContent ? 'height: fit-content; min-height: 65px; z-index: 2;' : 'z-index: 999;'};
    ${textMetaInfo};
    border-radius: 0;
    line-height: 1.2;
    padding: 10px 15px;

    ${({ isLeftPosition }) => (isLeftPosition ? 'left: auto; right: 100%; margin-right: 10px;' : '')};
    &.type-dark {
      &.place-top {
        :before,
        :after {
          border-top-color: rgba(79, 79, 79, 0.9);
        }
      }
      &.place-left {
        :before,
        :after {
          border-left-color: rgba(79, 79, 79, 0.9);
        }
      }
      &.place-right {
        :before,
        :after {
          border-right-color: rgba(79, 79, 79, 0.9);
        }
      }
      &.place-bottom {
        :before,
        :after {
          border-bottom-color: rgba(79, 79, 79, 0.9);
        }
      }
    }
  }

  @media ${({ isFlexibleToContent }) => (isFlexibleToContent ? deviceMedia.tablet : deviceMedia.mobile)} {
    ${props =>
      !props.withoutCustomMobileTooltip
        ? `
      .__react_component_tooltip {
        left: 10px !important;
        right: 0;
        width: calc(100% - 20px);
        max-width: 100%;
        &:after {
          left: ${props.left}px;
      }
    `
        : ''};
    ${({ isFlexibleToContent }) => (isFlexibleToContent ? 'top: 100%; z-index: 1; left: 0 !important;' : '')};
    ${props =>
      !props.withoutCustomMobileTooltip && props.left === 0
        ? `
      .__react_component_tooltip {
        &:after {
          content: none;
        }
      }
    `
        : ''};
  }
`;
type tooltipProps = ReactTooltip.Props & {
  questionRef?: React.RefObject<HTMLSpanElement> | null;
  textAlign?: string;
  withoutCustomMobileTooltip?: boolean;
  maxContentWidth?: number;
};
export const ToolTip = (props: tooltipProps): ReactElement => {
  const getPos = (ref: React.RefObject<HTMLSpanElement> | undefined | null): number => {
    if (!(ref && ref.current)) return 0;
    const TOOLTIP_LEFT_INDENT = 10;
    const halfOfQuestionIconWidth = ref.current.getBoundingClientRect().width / 2;
    return ref.current.getBoundingClientRect().left - (TOOLTIP_LEFT_INDENT - halfOfQuestionIconWidth);
  };
  return (
    <ToolTipWrapper
      left={getPos(props.questionRef)}
      textAlign={props.textAlign}
      withoutCustomMobileTooltip={props.withoutCustomMobileTooltip}
      width={props.maxContentWidth}
    >
      <ReactTooltip globalEventOff="touchstart" {...props} />
    </ToolTipWrapper>
  );
};
ToolTip.displayName = 'ToolTip';

export interface HintProps {
  text: string;
  size?: number;
  textAlign?: string;
  placement?: 'top' | 'bottom' | 'right' | 'left';
  html?: boolean;
  maxContentWidth?: number;
  children?: (p: {}) => React.ReactNode;
  withoutCustomMobileTooltip?: boolean;
  customVerticalAlign?: string;
  isNowrap?: boolean;
  dataTestId?: string;
}

export const Hint = ({
  text,
  size,
  placement,
  textAlign,
  html,
  maxContentWidth,
  children,
  withoutCustomMobileTooltip,
  customVerticalAlign,
  isNowrap,
  dataTestId
}: HintProps): ReactElement => {
  const ID = generateUID();
  const qRef = useRef<HTMLSpanElement>(null);
  const [qRefAfterReRender, setQRefAfterReRender] = useState<React.RefObject<HTMLSpanElement> | null>(null);
  useEffect(() => {
    // save ref to the question icon after full component re-render to be sure that ref doesn't contain "null" inside
    setQRefAfterReRender(qRef);
  }, []);

  const iconProps = {
    'data-test-id': 'question-icon',
    'data-tip': text,
    'data-for': ID,
    'data-event': 'mouseover mouseenter touchstart',
    'data-event-off': 'mouseout mouseleave'
  };

  return (
    <>
      <Container
        className="hint-container hideForPDF"
        Size={size}
        skipIconFill={typeof children === 'function'}
        customVerticalAlign={customVerticalAlign}
        isNowrap={isNowrap}
        data-test-id={dataTestId}
      >
        <span ref={qRef}>{typeof children === 'function' ? children(iconProps) : <QuestionIcon {...iconProps} />}</span>
        <ToolTip
          textAlign={textAlign}
          questionRef={qRefAfterReRender}
          id={ID}
          multiline
          effect="solid"
          place={placement}
          html={html}
          maxContentWidth={maxContentWidth}
          withoutCustomMobileTooltip={withoutCustomMobileTooltip}
        />
      </Container>
    </>
  );
};
