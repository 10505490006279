import { random } from 'lodash';
import { stripZeroWidthSpace } from 'store/emailMarketing/utils';
import i18n from 'i18n';
import { MailBoxData, MailBox } from '../emailTemplateEditor';
import { ServerMailBox, ServerTemplate } from './types';

export const generateQuickGuid = () =>
  `${random(Date.now()).toString(36).substring(2, 15)}${random(Date.now()).toString(36).substring(2, 15)}`;

const convertBoxType = (type: string): string => {
  switch (type) {
    case 'text_2col':
      return 'text2col';
    default:
      return type;
  }
};

const decodeString = <T>(str: T) => {
  if (typeof str === 'string') {
    try {
      return decodeURIComponent(str as string);
    } catch (e) {
      return str;
    }
  }
  return str;
};

export const convertToMailBox = (box: ServerMailBox) => {
  const { type } = box;
  let boxData: MailBoxData;

  switch (type) {
    case 'text':
      boxData = {
        text: decodeString(box.text)
      };
      break;
    case 'image':
      boxData = {
        src: box.image
      };
      break;
    case 'coupon':
      boxData = {
        androidPay: box.android_pay,
        androidPayLink: box.android_pay_link,
        buttonStyle: box.button_style,
        endDate: box.end_date,
        locations: box.locations,
        startDate: box.start_date,
        text: decodeString(box.text),
        title: decodeString(box.title)
      };
      break;
    case 'text_2col':
      boxData = {
        textCol1: decodeString(box.text_col1),
        textCol2: decodeString(box.text_col2)
      };
      break;
    case 'button':
      boxData = {
        title: decodeString(box.title),
        link: box.link
      };
      break;
    default:
      boxData = {};
      break;
  }

  return {
    id: generateQuickGuid(),
    type: convertBoxType(box.type),
    boxData
  };
};

export const convertToServerBox = (box: MailBox) => {
  const { type, boxData } = box;
  let serverBox: ServerMailBox;

  switch (type) {
    case 'text':
      serverBox = {
        type,
        text: stripZeroWidthSpace(boxData.text)
      };
      break;
    case 'image':
      serverBox = {
        type,
        image: boxData.src
      };
      break;
    case 'coupon':
      serverBox = {
        type,
        android_pay: boxData.androidPay,
        android_pay_link: boxData.androidPayLink,
        button_style: boxData.buttonStyle,
        end_date: boxData.endDate,
        locations: boxData.locations,
        start_date: boxData.startDate,
        terms: '',
        text: stripZeroWidthSpace(boxData.text),
        title: boxData.title
      };
      break;
    case 'text2col':
      serverBox = {
        type: 'text_2col',
        text_col1: stripZeroWidthSpace(boxData.textCol1),
        text_col2: stripZeroWidthSpace(boxData.textCol2)
      };
      break;
    case 'button':
      serverBox = {
        type,
        ...boxData
      };
      break;
    default:
      serverBox = { type };
      break;
  }

  return serverBox;
};

export const convertServerTemplates = (templates: ServerTemplate[]) =>
  templates.map((template: ServerTemplate) => ({
    ...template,
    name: i18n.t(decodeString(template.name)),
    subject: decodeString(template.subject),
    preheader: decodeString(template.preheader),
    components: template.components.map(convertToMailBox),
    fromName: template.fromName
  }));

export const convertTemplate = (boxes: MailBox[]) => boxes.map(convertToServerBox);
