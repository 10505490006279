import moment from 'moment';
import { put, select, takeLatest, call } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import i18n from 'i18n';
import { getStartEndDates, setDateRange } from 'store/appDateRangePicker';
import { AvailablePages, getAnalyticsPdfState, pdfActions, PdfCookiesTypes, PdfChartsModeMap } from 'store/pdf';
import { AppState } from 'store/rootReducer';
import { INTERNATIONAL_DATE_FORMAT } from 'appConstants';
import { ApiGateway } from 'services/apiGateway';
import fileSaver from 'file-saver';
import { googleAnalytics } from 'services/googleAnalytics';
import { getSelectedLocations } from 'store/locations';
import { getMerchantProgramsByLocationsList } from 'store/settings';

const composeChartsOptions = (options: Partial<PdfCookiesTypes>): Partial<PdfCookiesTypes> =>
  Object.entries(options).reduce(
    (accum, [name, value]) => (name in PdfChartsModeMap && value ? { ...accum, [name]: value } : accum),
    {}
  );

export function* PdfSagas() {
  yield takeLatest(
    getType(pdfActions.getDataFromCookies),
    function* ({ payload }: ReturnType<typeof pdfActions.getDataFromCookies>) {
      const { pdfMode, startDate, endDate } = payload;

      if (pdfMode) {
        yield put(pdfActions.switchPdfMode(pdfMode));
      }

      if (startDate && endDate) {
        const start = moment(startDate);
        const end = moment(endDate);
        if (start.isValid() && end.isValid()) {
          yield put(setDateRange(start, end));
        }
      }

      const chartsOptions = composeChartsOptions(payload);

      if (Object.entries(chartsOptions).length) {
        // ToDo: define page dynamically
        yield put(pdfActions.addChartInfo({ options: chartsOptions, page: AvailablePages.analytics }));
      }
    }
  );

  yield takeLatest(getType(pdfActions.generatePdf.request), function* () {
    try {
      const state: AppState = yield select();
      const dateRange = getStartEndDates(state, {
        formatStartDate: INTERNATIONAL_DATE_FORMAT,
        formatEndDate: INTERNATIONAL_DATE_FORMAT
      });
      const analyticsPdfState = getAnalyticsPdfState(state);
      const locations = getSelectedLocations(state);
      const programs = getMerchantProgramsByLocationsList(state, locations);

      const response = yield call(ApiGateway.getPDF, {
        transactionsStartDate: dateRange.start,
        transactionsEndDate: dateRange.end,
        locations,
        programs,
        ...analyticsPdfState
      });

      // todo: make this event configurable
      fileSaver.saveAs(response, `${i18n.t('pdfFileName', 'Analytics Dashboard')}.pdf`);
      googleAnalytics.events.download.pdf('Export Analytics Dashboard');

      yield put(pdfActions.generatePdf.success());
    } catch (e) {
      console.error(e);
      yield put(pdfActions.generatePdf.failure());
    }
  });

  yield takeLatest(getType(pdfActions.generatePdfForPage.request), function* ({ payload }: any) {
    try {
      const response = yield call(ApiGateway.getPdfForPage, { ...payload });

      // TODO: make this event configurable
      fileSaver.saveAs(response, payload.filename);

      yield put(pdfActions.generatePdfForPage.success());
    } catch (e) {
      console.error(e);
      yield put(pdfActions.generatePdfForPage.failure());
    }
  });
}
