import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { deviceMedia, headerHeight } from 'appConstants';
import { ComponentThemeProp } from '../theme/types';
import { Logo } from './Logo';
import { HeaderMenu } from './HeaderMenu';

interface Props {
  isMobile?: boolean;
  isTablet?: boolean;
  hideMenu: boolean;
}

export const HeaderContainer = styled.header`
  display: flex;
  align-items: center;
  min-height: ${headerHeight}px;
  background: ${(props: ComponentThemeProp): string => props.theme.baseColors.white};
  padding: 10px 20px;
  justify-content: space-between;
  position: relative;
  @media ${deviceMedia.mobile} {
    padding: 10px;
  }
`;

export const Header = (props: Props): ReactElement => (
  <>
    <HeaderContainer>
      <Logo isMobile={props.isMobile} />
      {!props.hideMenu ? <HeaderMenu {...props} /> : null}
    </HeaderContainer>
  </>
);
