import { ActionType, createReducer } from 'typesafe-actions';
import { AnalyticsState } from './types';

import { transactionsActions } from './actions';

const evaluateDataSingleDefault = {
  sales: {
    value: 0,
    mom: 0,
    yoy: 0,
    chartData: []
  },
  visits: {
    value: 0,
    mom: 0,
    yoy: 0,
    chartData: []
  },
  avg: {
    value: 0,
    mom: 0,
    yoy: 0,
    chartData: []
  }
};

const defaultState: AnalyticsState = {
  loading: false,
  data: {},
  comparisonTableOptions: {
    sorted: [
      {
        id: 'locationInfo',
        desc: false
      }
    ],
    pageSize: 10,
    page: 0
  },
  temp: {
    chartFilter: []
  },
  testGroup: { ...evaluateDataSingleDefault },
  controlGroup: { ...evaluateDataSingleDefault },
  weeklySalesBreakdown: {
    new: [],
    returning: [],
    all: []
  },
  expandedCharts: []
};

export const analyticsReducer = createReducer<AnalyticsState, ActionType<typeof transactionsActions>>(defaultState)
  .handleAction(transactionsActions.fetchTransactionsData.request, state => ({ ...state, loading: true }))
  .handleAction(transactionsActions.fetchTransactionsData.success, (state, action) => ({
    ...state,
    loading: false,
    data: { ...state.data, ...action.payload }
  }))
  .handleAction(transactionsActions.fetchTransactionsData.failure, state => ({
    ...state,
    loading: false
  }))
  .handleAction(transactionsActions.fetchTransactionsEvaluate.request, state => ({ ...state, loading: true }))
  .handleAction(transactionsActions.fetchTransactionsEvaluate.success, (state, action) => ({
    ...state,
    loading: false,
    testGroup: action.payload.testGroup,
    controlGroup: action.payload.controlGroup
  }))
  .handleAction(transactionsActions.fetchTransactionsEvaluate.failure, state => ({
    ...state,
    loading: false
  }))
  .handleAction(transactionsActions.setTransactionsEvaluateDefaultState, state => ({
    ...state,
    testGroup: evaluateDataSingleDefault,
    controlGroup: evaluateDataSingleDefault
  }))
  .handleAction(transactionsActions.setOverviewGrowthData, (state, action) => ({
    ...state,
    data: { ...state.data, overviewGrowth: action.payload }
  }))
  .handleAction(transactionsActions.setExpandedCharts, (state, action) => ({
    ...state,
    expandedCharts: action.payload
  }))
  .handleAction(transactionsActions.fetchTicketTiers.success, (state, action) => ({
    ...state,
    data: { ...state.data, ticketTiers: action.payload }
  }))
  .handleAction(transactionsActions.fetchWeeklySalesBreakdown.success, (state, action) => ({
    ...state,
    weeklySalesBreakdown: action.payload
  }))
  .handleAction(transactionsActions.setComparisonTableOptions, (state, action) => ({
    ...state,
    comparisonTableOptions: action.payload
  }))
  .handleAction(transactionsActions.setTempChartFilter, (state, action) => ({
    ...state,
    temp: {
      ...state.temp,
      chartFilter: action.payload
    }
  }))
  .handleAction(transactionsActions.clearTransactionsState, state => ({ ...state, data: {} }));
