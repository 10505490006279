import { createAction, createAsyncAction } from 'typesafe-actions';
import { AvailableTermsTypes } from './types';

export const termsActions = {
  getData: createAsyncAction('@terms/getData', '@terms/getData/success', '@terms/getData/error')<any, any, any>(),
  confirmTerms: createAction('@terms/confirm', action => (type: AvailableTermsTypes) => action(type)),
  cancelTerms: createAction('@terms/cancel', action => (type: AvailableTermsTypes) => action(type))
};

export const fetchTermsData = termsActions.getData;
