import { getActivePage, isActivePageTeaser } from 'store/navigation';
import { AppState } from '../rootReducer';
import { Page } from '../settings/layout';
import { getUserLocations } from '../settings/selectors';
import { DEFAULT_PAGE, ERROR_PAGES, MASTERCARD, MERCHANT_CENTRIC, TEASER, DASHBOARD } from '../../appConstants';
import { PageViewLog } from './types';
import {
  isMastercardTermsConfirmed as getMastercardTermsConfirmation,
  isMerchantCentricTermsConfirmed as getMerchantCentricTermsConfirmation
} from '../terms';

const OLD_EM_PATH = '/emailmarketing';
const NEW_EM_PATH = '/email-marketing';
const OLD_DASHBOARD_PATH = '/';

const TERMS_MODAL_NAME = 'TermsModal';

const isErrorPage = (page: string): boolean => ERROR_PAGES.includes(page);

const decorateWithTeaser = (state: AppState, path: string): string =>
  isActivePageTeaser(state) && !isErrorPage(path) ? `${path}${TEASER}` : path;

const getPathItems = (path: string, separator: string = '/'): string[] =>
  path
    .split(separator)
    .filter(item => item)
    .map(item => `${separator}${item}`);

const isFullPath = (activePage: Page, subPage: string) =>
  activePage.subPages ? activePage.subPages.map((page: Page) => page.path).includes(subPage) : true;

const getFullPath = (state: AppState): { page: string; subPage: string } => {
  const routerPath = state.router.location.pathname;

  const [page, subPage] = getPathItems(routerPath);

  if (isErrorPage(page)) return { page, subPage: '' };

  const activePage = getActivePage(state) || ({} as Page);

  if (isFullPath(activePage, subPage)) return { page, subPage };

  return {
    page: page || activePage.path || DASHBOARD,
    subPage: activePage.subPages.length > 0 ? activePage.subPages[0].path : ''
  };
};

const mapToPreviousView = (path: string) => {
  switch (path) {
    case DEFAULT_PAGE:
      return OLD_DASHBOARD_PATH;
    case NEW_EM_PATH:
      return OLD_EM_PATH;
    default:
      return path;
  }
};

export const composeDataForRequest = (state: AppState) => {
  const { page, subPage } = getFullPath(state);
  return {
    payload: decorateWithTeaser(state, mapToPreviousView(page)),
    merchantSequenceKeys: getUserLocations(state).map(({ merchant_sequence_key }) => merchant_sequence_key),
    path: decorateWithTeaser(state, page + subPage),
    page,
    subPage
  };
};

export const isLogAllowed = (state: AppState, pageViewInfo: PageViewLog): boolean => {
  const isPageViewInfoValid = pageViewInfo.payload && pageViewInfo.merchantSequenceKeys.length && pageViewInfo.path;
  if (!isPageViewInfoValid) return false;
  // TODO: find a solution log 404 page
  if (isErrorPage(pageViewInfo.path)) return false;

  const activePage = getActivePage(state);

  if (!activePage) return false;

  const termsElement = activePage.elements.find(
    element => element.enabled && element.componentName === TERMS_MODAL_NAME
  );

  if (!termsElement || typeof termsElement !== 'object') return true;

  const isMastercardTermsRequired: boolean = termsElement.features[MASTERCARD];
  const isMerchantCentricTermsRequired: boolean = termsElement.features[MERCHANT_CENTRIC];

  const isMastercardTermsConfirmed = getMastercardTermsConfirmation(state);
  const isMerchantCentricTermsConfirmed = getMerchantCentricTermsConfirmation(state);

  switch (true) {
    case isMastercardTermsRequired && isMerchantCentricTermsRequired:
      return isMastercardTermsConfirmed && isMerchantCentricTermsConfirmed;
    case isMastercardTermsRequired:
      return isMastercardTermsConfirmed;
    case isMerchantCentricTermsRequired:
      return isMerchantCentricTermsConfirmed;
    default:
      return false;
  }
};
