import { createAction, createAsyncAction } from 'typesafe-actions';
import {
  UberallActiveDirectories,
  UberallDisconnectData,
  UberallIdentityResponse,
  UberallLocationInfo,
  UberallRepliesResponse,
  UberallReview,
  UberallSignupData,
  UberallStatisticsResponse
} from './apiTypes';
import { TransactionsTrendPeriod } from './types';

const fetchIdentityMapping = createAsyncAction(
  '@uberall/identityMapping/fetch',
  '@uberall/identityMapping/success',
  '@uberall/identityMapping/error'
)<
  { merchantNumber: string },
  { merchantNumber: string; data: UberallIdentityResponse },
  { merchantNumber: string; error: Error }
>();

const fetchTransactions = createAsyncAction(
  '@uberall/transactions/fetch',
  '@uberall/transactions/success',
  '@uberall/transactions/error'
)<
  { merchantNumber: string },
  { merchantNumber: string; data: TransactionsTrendPeriod[] },
  { merchantNumber: string; error: Error }
>();

const fetchStatistics = createAsyncAction(
  '@uberall/statistics/fetch',
  '@uberall/statistics/success',
  '@uberall/statistics/error'
)<{ uberallId: number }, { uberallId: number; data: UberallStatisticsResponse }, { uberallId: number; error: Error }>();

const signup = createAsyncAction('@uberall/signup/fetch', '@uberall/signup/success', '@uberall/signup/error')<
  { merchantNumber: string; data: UberallSignupData },
  { merchantNumber: string; data: UberallIdentityResponse },
  { merchantNumber: string; generalError?: string; errors: Partial<Record<keyof UberallSignupData, string>> }
>();

const resetSignup = createAction(
  '@uberall/resetSignup',
  action => (merchantNumber: string) => action({ merchantNumber })
);

const editLocation = createAsyncAction('@uberall/update/request', '@uberall/update/success', '@uberall/update/error')<
  { uberallId: number; data: UberallSignupData },
  { uberallId: number; data: UberallLocationInfo },
  { uberallId: number; error: Error }
>();

const fetchReplies = createAsyncAction('@uberall/replies/fetch', '@uberall/replies/success', '@uberall/replies/error')<
  { uberallId: number; reviewIds: string },
  { uberallId: number; data: UberallRepliesResponse },
  { uberallId: number; error: Error }
>();

const fetchLocationInfo = createAsyncAction(
  '@uberall/location/fetch',
  '@uberall/location/success',
  '@uberall/location/error'
)<{ uberallId: number }, { uberallId: number; data: UberallLocationInfo }, { uberallId: number; error: Error }>();

const fetchDirecroriesConfig = createAsyncAction(
  '@uberall/directories/fetch',
  '@uberall/directories/success',
  '@uberall/directories/error'
)<void, { data: UberallActiveDirectories }, { error: Error }>();

const sendReply = createAsyncAction(
  '@uberall/sendReply/fetch',
  '@uberall/sendReply/success',
  '@uberall/sendReply/error'
)<{ uberallId: number; reviewId: number; reply: string }, UberallReview, Error>();

const disconnectDirectory = createAsyncAction(
  '@uberall/disconnectDirectory/fetch',
  '@uberall/disconnectDirectory/success',
  '@uberall/disconnectDirectory/error'
)<UberallDisconnectData & { locationId: string }, string, void>();

const reset = createAction('@uberall/reset');

export const uberallActions = {
  fetchLocationInfo,
  fetchIdentityMapping,
  fetchStatistics,
  fetchReplies,
  reset,
  signup,
  resetSignup,
  sendReply,
  editLocation,
  fetchTransactions,
  fetchDirecroriesConfig,
  disconnectDirectory
};
