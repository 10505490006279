export const CELL_WIDTH = 160;
export const LIST_HEIGHT = 1170;
export const IMG_WIDTH = 130;
export const WRAPPER_PADDING_BORDER = 15;
export const NAME_SYMBOLS_PER_LINE = 13;
export const NAME_LINE_HEIGHT = 20;
export const NAME_BOTTOM_PADDING = 14;

export const PREVIEW_DEBOUNCE_BACK_ROW_COUNT = 20;
export const CONTACTS_PER_PAGE = 10;
export const NOTE_TITLE = 'Email Marketing campaign: ';
export const CAMPAIGN_SENT_STATUS = 1;
export const CAMPAIGN_SCHEDULED_STATUS = 2;
export const API_ANDROID_PAY_URL = 'https://www.android.com/payapp/savetoandroidpay/';

export const CAMPAIGN_SCHEDULE_DELAY = 5;
export const CAMPAIGN_AVAILABLE_TIME_US = 25;
export const CAMPAIGN_AVAILABLE_TIME_EU = 5;

/* eslint-disable no-useless-computed-key */
export const S3_SOCIAL_LINKS = {
  facebook: 'https://s3.amazonaws.com/beanstalk-customer-email-images/775/facebook_1500536018134.png',
  twitter: 'https://s3.amazonaws.com/beanstalk-customer-email-images/775/twitter_1500536228491.png',
  instagram: 'https://s3.amazonaws.com/beanstalk-customer-email-images/775/instagram_1500536093251.png',
  linkedin: 'https://s3.amazonaws.com/beanstalk-customer-email-images/775/linkedin_1500536161463.png',
  pinterest: 'https://s3.amazonaws.com/beanstalk-customer-email-images/775/pinterest_1500536191929.png',
  youtube: 'https://s3.amazonaws.com/beanstalk-customer-email-images/775/youtube_1500536256980.png'
};
/* eslint-enable no-useless-computed-key */

export const CSV_FILE_MIMES = [
  'text/comma-separated-values',
  'text/csv',
  'application/csv',
  'application/excel',
  'application/vnd.ms-excel',
  'application/vnd.msexcel',
  'text/anytext',
  'application/vnd.sun.xml.calc',
  'application/vnd.stardivision.calc',
  'application/vnd.sun.xml.calc.template',
  'application/vnd.oasis.opendocument.spreadsheet',
  'application/vnd.oasis.opendocument.text'
];

export const CSV_FILE = [
  'FirstName,LastName,Email,Birthday',
  'Anita,Jorgensen,Anita@mail.com,10/1985',
  'Dorena,Paschke,DorenaP@mail.com,10/1985',
  'Kemal,Celik,Kemal@mail.com,10/1985',
  'Shiori,Inoue,Shiori@mail.com,10/1985'
].join('\n');

export const REWARDS_WELCOME = 'welcome';
export const REWARDS_ALMOST_REWARD = 'almost_reward';
export const REWARDS_REWARD = 'loyalty_reward';
export const REWARDS_MISS_YOU = 'miss_you';
export const REWARDS_ANNIVERSARY = 'anniversary';
export const REWARDS_BIRTHDAY = 'birthday';

export const REWARDS_CAMPAIGNS: string[] = [
  REWARDS_WELCOME,
  REWARDS_ALMOST_REWARD,
  REWARDS_REWARD,
  REWARDS_MISS_YOU,
  REWARDS_ANNIVERSARY,
  REWARDS_BIRTHDAY
];

export const AUTOMATION_CAMPAIGN_STATUSES = {
  active: 'active',
  inactive: 'inactive',
  confirmed: 'confirmed',
  unconfirmed: 'unconfirmed'
};

export const CRM_AUTOMATION_CAMPAIGN_STATUSES_CODES = {
  active: 1,
  inactive: 0
};

/* eslint-disable */
export const DEFAULT_AUTOMATION_CAMPAIGNS_STATUSES = {
  almost_reward: {
    status: false,
    campaignId: null,
    scheduleDate: '',
    CRMTemplateId: null,
    templateId: null
  },
  anniversary: {
    status: false,
    campaignId: null,
    scheduleDate: '',
    CRMTemplateId: null,
    templateId: null
  },
  birthday: {
    status: false,
    campaignId: null,
    scheduleDate: '',
    CRMTemplateId: null,
    templateId: null
  },
  loyalty_reward: {
    status: false,
    campaignId: null,
    scheduleDate: '',
    CRMTemplateId: null,
    templateId: null
  },
  miss_you: {
    status: false,
    campaignId: null,
    scheduleDate: '',
    CRMTemplateId: null,
    templateId: null
  },
  welcome: {
    status: false,
    campaignId: null,
    scheduleDate: '',
    CRMTemplateId: null,
    templateId: null
  }
};
/* eslint-enable */

export const phoneValidationRegexp = /^((\d-?)+)\d?$/;
export const onlyAlphabeticOrNumberRegexp = /[^a-zA-Z0-9 ]/;
export const validationSpecialChartsForbiddenRegexp = /^[A-Za-z0-9!'"#$%&()*,‐./+-:;<=>? \n]*$/;

export const DEFAULT_SORT_ORDER = 'first_name';

export const DEFAULT_CONTACT_AGE_LIMIT = 13;

export const INSERTED_CORE_PROGRAM = 'insertedCoreProgram';
export const REWARDS_MID_STORE_BOARDING = 'rewardsMidStoreboardingResult';

export const PROMOS_AUTOMATION_CAMPAIGN_ARRAY = [INSERTED_CORE_PROGRAM, REWARDS_MID_STORE_BOARDING];
export const LOYALTY_DANUBE_PROGRAM_ID = 'LOYALTY';
export const danubePartners = ['4', '5', '6', '7'];
