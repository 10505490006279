import { createAction, createAsyncAction } from 'typesafe-actions';

const setContactsList = createAction('@em/contacts/unsubscribed/list/set', action => (data: any[]) => action({ data }));

const fetchContacts = createAsyncAction(
  '@em/contacts/unsubscribed/fetch/request',
  '@em/contacts/unsubscribed/fetch/success',
  '@em/contacts/unsubscribed/fetch/error'
)<any, any, void>();

const setUnsubscribedSortFieldAndOrder = createAction(
  '@em/contacts/unsubscribed/sort/set',
  action => (sort: string) => action(sort)
);

export const unsubscribedContactsActions = {
  setContactsList,
  fetchContacts,
  setUnsubscribedSortFieldAndOrder
};
