/* eslint-disable */
/* Return DE Static Automations Templates */

const AlmostThereEmailData = {
  styles: {
    width: '600px',
    color: '#949599',
    backgroundColor: '#fff',
    couponBorderColor: '#fff',
    buttonsDividersColor: '#fff',
    sicailLinksColor: '#fff',
  },
  components: [
    {
      type: 'spacer',
    },
    {
      type: 'image',
      image: 'em-email-images/AT/GPat_550x300_19.+Almost+There+Template_AT.jpg',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<h1 style="font-style: italic; color: #949599;">Sie haben es fast geschafft!</h1>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<p style="color: #949599; font-weight: bold;">Herzliche Glückwünsche! Nur ein Besuch/Artikel trennt Sie davon, Ihre nächste Belohnung zu erhalten. Wir freuen uns darauf, Sie bald wieder zu sehen!</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const AnniversaryEmailData = {
  styles: {
    width: '600px',
    color: '#949599',
    backgroundColor: '#fff',
    couponBorderColor: '#fff',
    buttonsDividersColor: '#fff',
    sicailLinksColor: '#fff',
  },
  components: [
    {
      type: 'spacer',
    },
    {
      type: 'image',
      image: 'em-email-images/AT/GPat_550x300_21.+Happy+Anniversary+Template_AT.jpg',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<h1 style="font-style: italic; color: #949599;">Danke für ein wunderbares Jahr!</h1>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<p style="color: #949599; font-weight: bold;">Die Zeit vergeht schneller, wenn Sie Spaß haben. Wir freuen uns, dass Sie ein Teil unserer Familie sind. Wir freuen uns darauf, Sie bald wiederzusehen!</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const RewardEmailData = {
  styles: {
    width: '600px',
    color: '#949599',
    backgroundColor: '#fff',
    couponBorderColor: '#fff',
    buttonsDividersColor: '#fff',
    sicailLinksColor: '#fff',
  },
  components: [
    {
      type: 'spacer',
    },
    {
      type: 'image',
      image: 'em-email-images/AT/GPat_550x300_22.+Reward+Template_AT.jpg',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<h1 style="font-style: italic; color: #949599;">Sie haben eine Belohnung erhalten!</h1>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<p style="color: #949599; font-weight: bold;">Herzlichen Glückwunsch! Sie haben es sich verdient. Wir freuen uns darauf, Sie bald wiederzusehen!</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const WelcomeEmailData = {
  styles: {
    width: '600px',
    color: '#949599',
    backgroundColor: '#fff',
    couponBorderColor: '#fff',
    buttonsDividersColor: '#fff',
    sicailLinksColor: '#fff',
  },
  components: [
    {
      type: 'spacer',
    },
    {
      type: 'image',
      image: 'em-email-images/AT/GPat_550x300_18.+Welcome+Template_AT.jpg',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<h1 style="font-style: italic; color: #949599;">Willkommen!</h1>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<p style="color: #949599; font-weight: bold;">Jetzt erhalten Sie unsere neuesten Angebote direkt in Ihren Posteingang. Wir freuen uns darauf, Sie bald wiederzusehen!</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const WeMissYouEmailData = {
  styles: {
    width: '600px',
    color: '#949599',
    backgroundColor: '#fff',
    couponBorderColor: '#fff',
    buttonsDividersColor: '#fff',
    sicailLinksColor: '#fff',
  },
  components: [
    {
      type: 'spacer',
    },
    {
      type: 'image',
      image: 'em-email-images/AT/GPat_550x300_20.+We+Miss+You+Template_AT.jpg',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<h1 style="font-style: italic; color: #949599;">Wie geht es Ihnen?</h1>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<p style="color: #949599; font-weight: bold;">Es kommt uns wie eine Ewigkeit vor, dass wir Sie das letzte Mal gesehen haben. Wir wollten nur Hallo sagen und hoffen, dass Sie uns bald wieder besuchen.</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

export const deStaticTemplates = [
  {
    id: 'tl0pzirpy4cill9ilyjj',
    promoId: 'almost_reward',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticAutomationTemplateAlmostThereTitle',
    status: 'origin',
    favorites: 'false',
    components: AlmostThereEmailData.components,
    styles: AlmostThereEmailData.styles,
    thumb: 'almost-there-email-preview-de.jpg',
  },
  {
    id: '4e7fn926hvlpebnawkqb',
    promoId: 'anniversary',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticAutomationTemplateAnniversaryTitle',
    status: 'origin',
    favorites: 'false',
    components: AnniversaryEmailData.components,
    styles: AnniversaryEmailData.styles,
    thumb: 'anniversary-email-preview-de.jpg',
  },
  {
    id: 'pc855pz7fquaymq6ungq',
    promoId: 'loyalty_reward',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticAutomationTemplateRewardTitle',
    status: 'origin',
    favorites: 'false',
    components: RewardEmailData.components,
    styles: RewardEmailData.styles,
    thumb: 'reward-email-preview-de.jpg',
  },
  {
    id: 'mvr059xflws3lfkj4ma9',
    promoId: 'miss_you',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticAutomationTemplateWeMissYouTitle',
    status: 'origin',
    favorites: 'false',
    components: WeMissYouEmailData.components,
    styles: WeMissYouEmailData.styles,
    thumb: 'we-miss-you-email-preview-de.jpg',
  },
  {
    id: 'fvzosjlo9zsaksq51b6c',
    promoId: 'welcome',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticAutomationTemplateWelcomeTitle',
    status: 'origin',
    favorites: 'false',
    components: WelcomeEmailData.components,
    styles: WelcomeEmailData.styles,
    thumb: 'welcome-email-preview-de.jpg',
  },
];
