import React, { EventHandler, RefObject } from 'react';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { DEFAULT_NS } from 'appConstants/translationNamespaces';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { CloseIconPure } from 'assets/icons/close';
import { ReactComponent as StarIcon } from 'assets/icons/favorites-full.svg';
import { ReactComponent as LocationPointIcon } from 'assets/icons/map-point.svg';
import { ReactComponent as ChevronIcon } from 'assets/icons/chevron.svg';
import { getColor } from 'layout/theme';
import { ellipsisText, resetButtonStyles, textRegular, textSubtitle, shadow } from 'layout/mixins';
import { generateUID, stripTags } from 'services/utils';
import { deviceMedia } from 'appConstants';

interface SearchBarHeightSize {
  searchBarHeight: number;
}

interface FontProps {
  fontSize: number;
}

const searchBarHeight = 25;
const searchBarMobileHeight = 45;

export const SearchContainer = styled.div<{ isMobile?: boolean; withBorder?: boolean }>`
  background: ${props => getColor(props, 'white')};
  border: 1px solid ${props => getColor(props, 'mediumGrey')};
  display: flex;
  height: ${searchBarHeight}px;
  align-items: center;
  @media ${deviceMedia.mobile} {
    ${props => (props.isMobile && !props.withBorder ? 'border-width: 0 0 1px 0;' : '')};
    height: ${searchBarMobileHeight}px;
    padding-left: 15px;
  }
`;
SearchContainer.displayName = 'SearchContainer';

const IconContainer = styled.div`
  width: ${searchBarHeight + 3}px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    max-width: 65%;
    max-height: 65%;
    g[fill] {
      fill: ${props => getColor(props, 'darkGrey')};
    }
  }
`;

const SearchInput = styled.input<FontProps>`
  display: block;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  line-height: 1.2;
  color: ${props => getColor(props, 'darkGrey')};
  font-size: ${props => props.fontSize}px;
  flex: 1;
  height: 100%;
  &::placeholder {
    color: ${props => getColor(props, 'darkGrey')};
  }
  @media ${deviceMedia.mobile} {
    ${textSubtitle};
    padding-left: 5px;
  }
`;
SearchInput.displayName = 'SearchInput';

const ResetSearchButton = styled.button`
  ${resetButtonStyles};
  opacity: 0.7;
  margin: 0 3px;
  display: flex;
  align-items: center;
  &:hover {
    opacity: 1;
  }
`;
ResetSearchButton.displayName = 'ResetSearchButton';

export const Label = styled.div<{ active?: boolean } & FontProps>`
  ${props => `
    border-radius: 5px;
    padding: 3px 6px;
    font-size: ${props.fontSize}px;
    line-height: 1;
    background-color: transparent;
    border: 1px solid ${getColor(props, 'color1')};
    color: ${getColor(props, 'color1')};
    margin: 0 2px;
    cursor: pointer;
    ${props.active ? shadow : ''};
    @media ${deviceMedia.mobile} {
      ${textRegular};
    }
  `};
`;
Label.displayName = 'Label';

const RightIconContainer = styled.div<{ isRotated?: boolean; isSupportSearch: boolean }>`
  width: ${searchBarHeight + 2}px;
  height: 80%;
  border-left: 1px solid ${props => getColor(props, 'blueGrey')};
  margin-left: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${props => (props.isSupportSearch ? 'not-allowed' : 'pointer')};
  position: relative;
  svg {
    ${props => (props.isRotated ? 'transform: rotate(180deg);' : '')};
    width: 55%;
    height: 15px;
    height: auto;
  }

  @media ${deviceMedia.mobile} {
    width: ${searchBarHeight + 5}px;
  }
`;
RightIconContainer.displayName = 'RightIconContainer';

const StarIconStyled = styled(({ isSupportSearch, ...rest }) => <StarIcon {...rest} />)<{ isSupportSearch: boolean }>`
  path[fill] {
    fill: ${props => (props.isSupportSearch ? getColor(props, 'grey') : getColor(props, 'color2'))};
  }
`;

const SimpleModeContentContainer = styled.div<{ CursorPointer?: boolean }>`
  flex: 1;
  ${textRegular};
  ${props => (props.CursorPointer ? 'cursor: pointer;' : '')};
  ${ellipsisText};
`;

const ToolTipWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: inherit;
`;

interface OwnProps {
  simpleMode?: boolean;
  isSupportSearch: boolean;
  onSearchStringChange: (val: string) => void;
  onInputFocus: EventHandler<any>;
  onRotateArrowClick: EventHandler<any>;
  searchValue: string;
  labelString?: string;
  onLabelClick: () => void;
  onFavoriteClick: () => void;
  onEnter: (val: string) => void;
  isLabelActive?: boolean;
  searchPlaceholder?: string;
  searchBarHeight?: number;
  fontSize?: number;
  clearSearchIconSize?: number;
  inputRef: RefObject<HTMLInputElement> | null;
  isMobile?: boolean;
  withBorder?: boolean;
  onClick?: () => void;
  isFilterToggleEnabled?: boolean;
}

interface SimpleModePropTypes {
  isDropdownShown: boolean;
  simpleBarContent: () => JSX.Element;
}

export const SearchBar = (props: OwnProps & SimpleModePropTypes): JSX.Element => {
  const {
    onSearchStringChange,
    onInputFocus,
    searchValue,
    labelString,
    onLabelClick,
    isLabelActive,
    onRotateArrowClick,
    isDropdownShown,
    simpleBarContent,
    simpleMode,
    isSupportSearch,
    searchPlaceholder,
    fontSize = 12,
    clearSearchIconSize = 10,
    onFavoriteClick,
    onEnter,
    inputRef,
    isMobile,
    withBorder,
    onClick,
    isFilterToggleEnabled
  } = props;

  const { t } = useTranslation(DEFAULT_NS);

  const keyPressHandler = (event: React.KeyboardEvent<HTMLElement>): void => {
    if (event.key === 'Enter') {
      onEnter(searchValue);
    }
  };

  const hintId = generateUID();

  return simpleMode ? (
    <SearchContainer data-test-id="collapsed-view" onClick={onInputFocus} isMobile={isMobile} withBorder={withBorder}>
      <IconContainer data-test-id="left-icon">
        <LocationPointIcon />
      </IconContainer>
      <SimpleModeContentContainer CursorPointer={!isDropdownShown} data-test-id="simple-mode-selected-items">
        {simpleBarContent()}
      </SimpleModeContentContainer>
      <RightIconContainer
        onClick={e => {
          e.stopPropagation();
          onRotateArrowClick(e);
        }}
        isRotated={isDropdownShown}
        isSupportSearch={false}
        data-test-id={isDropdownShown ? 'up-arrow-icon' : 'down-arrow-icon'}
      >
        <ChevronIcon />
      </RightIconContainer>
    </SearchContainer>
  ) : (
    <SearchContainer data-test-id="collapsed-view" isMobile={isMobile} withBorder={withBorder}>
      <IconContainer data-test-id="left-icon">
        <SearchIcon />
      </IconContainer>

      <SearchInput
        ref={inputRef}
        onKeyPress={keyPressHandler}
        onBlur={() => {
          onEnter(searchValue);
        }}
        onClick={onClick}
        fontSize={fontSize}
        onFocus={onInputFocus}
        value={searchValue}
        onChange={e => onSearchStringChange(stripTags(e.target.value))}
        placeholder={searchPlaceholder}
        data-test-id="advanced-search-input"
      />

      {searchValue.length ? (
        <ResetSearchButton onClick={() => onSearchStringChange('')} data-test-id="reset-search-icon">
          <CloseIconPure size={clearSearchIconSize} strokeWidth={1} />
        </ResetSearchButton>
      ) : null}

      {typeof labelString === 'string' && isFilterToggleEnabled && (
        <Label
          active={isLabelActive}
          fontSize={fontSize}
          onClick={onLabelClick}
          data-test-id="number-selected-locations"
        >
          {labelString}
        </Label>
      )}

      <ToolTipWrapper
        data-tip={t(
          'SupportSearchUserLimitation',
          'As a security precaution, this button has been disabled for support users.'
        )}
        data-for={hintId}
      >
        <RightIconContainer
          onClick={e => {
            e.stopPropagation();
            if (!isSupportSearch) onFavoriteClick();
          }}
          isSupportSearch={isSupportSearch}
          data-test-id="saved-filter-icon"
        >
          <StarIconStyled isSupportSearch={isSupportSearch} />
        </RightIconContainer>
        {isSupportSearch && <ReactTooltip id={hintId} multiline effect="solid" place="bottom" />}
      </ToolTipWrapper>
    </SearchContainer>
  );
};
