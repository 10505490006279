import { ReactElement } from 'react';
import { localizationService, dataProviders } from 'services/localization';
import { formatString } from 'services/utils';
import { DictionaryTranslations, DemographicDictionary, Ids, Features, Segments, FeatureRange } from './types';

export const categoriesDictionary = (i18n: DictionaryTranslations, features: Features): DemographicDictionary => {
  const formatCurrency = (value: number): string =>
    localizationService.formatNumberWithCurrency({
      value,
      dataProvider: dataProviders.mastercard
    });
  const currenciesRange = (from: number, to: number): string => `${formatCurrency(from)}-${formatCurrency(to)}`;

  const stringifiedFormatString = (string: string, ...substitutions: (string | number | ReactElement)[]): string =>
    formatString(string, ...substitutions).join('');

  const customRange = (ranges: FeatureRange): Segments => {
    return Object.entries(ranges).reduce((acc: Segments, [segment, label]): Segments => {
      const formattedLabel = (str: string): string =>
        stringifiedFormatString(i18n[str.split('_')[0]], str.split('_')[1]);
      return {
        ...acc,
        ...{
          [segment]: {
            label:
              (label.includes('_') && formattedLabel(label)) ||
              (Object.prototype.hasOwnProperty.call(i18n, label) && i18n[label]) ||
              label,
            value: 0
          }
        }
      };
    }, {});
  };

  const age = {
    label: i18n.ageTitle,
    hintText: i18n.ageTooltip,
    color: 'color1',
    compareLink: Ids.Age,
    segments: features.ageRange
      ? customRange(features.ageRange)
      : {
          segment1: { label: stringifiedFormatString(i18n.ageUnder, 25), value: 0 },
          segment2: { label: stringifiedFormatString(i18n.ageRange, '25-34'), value: 0 },
          segment3: { label: stringifiedFormatString(i18n.ageRange, '35-44'), value: 0 },
          segment4: { label: stringifiedFormatString(i18n.ageRange, '45-54'), value: 0 },
          segment5: { label: stringifiedFormatString(i18n.ageRange, '55-64'), value: 0 },
          segment6: { label: stringifiedFormatString(i18n.ageRange, '65+'), value: 0 }
        }
  };

  const gender = {
    label: i18n.genderTitle,
    hintText: i18n.genderTooltip,
    color: 'color3',
    compareLink: Ids.Gender,
    segments: {
      segment13: { label: i18n.female, value: 0 },
      segment14: { label: i18n.male, value: 0 },
      segment15: { label: i18n.unknown, value: 0 }
    }
  };

  const children = {
    label: i18n.childrenTitle,
    hintText: i18n.childrenTooltip,
    color: 'color2',
    compareLink: Ids.Children,
    segments: features?.childrenRange
      ? customRange(features.childrenRange)
      : {
          segment29: { label: i18n.haveChildren, value: 0 },
          segment28: { label: i18n.noChildren, value: 0 },
          segment30: { label: i18n.unknown, value: 0 }
        }
  };

  const income = {
    label: features.incomeTitle ? i18n[features.incomeTitle] : i18n.incomeTitle,
    hintText: i18n.incomeTooltip,
    color: 'color4',
    compareLink: Ids.Income,
    segments: features?.incomeRange
      ? customRange(features.incomeRange)
      : {
          segment16: { label: stringifiedFormatString(i18n.underValue, formatCurrency(29999)), value: 0 },
          segment37: { label: currenciesRange(30000, 49999), value: 0 },
          segment19: { label: currenciesRange(50000, 74999), value: 0 },
          segment20: { label: currenciesRange(75000, 99999), value: 0 },
          segment21: { label: currenciesRange(100000, 124999), value: 0 },
          segment38: { label: `${formatCurrency(125000)}+`, value: 0 },
          segment27: { label: i18n.unknown, value: 0 }
        }
  };

  const education = {
    label: i18n.educationTitle,
    hintText: i18n.educationTooltip,
    color: 'color6',
    compareLink: Ids.Education,
    segments: features?.educationRange
      ? customRange(features.educationRange)
      : {
          segment9: { label: i18n.upToHighSchool, value: 0 },
          segment10: { label: i18n.someCollege, value: 0 },
          segment11: { label: i18n.undergraduate, value: 0 },
          segment39: { label: i18n.graduate, value: 0 },
          segment12: { label: i18n.unknown, value: 0 }
        }
  };

  const household = {
    label: i18n.householdTitle,
    hintText: i18n.householdTypeTooltip,
    color: 'color7',
    compareLink: Ids.Household,
    segments: features?.householdRange
      ? customRange(features.householdRange)
      : {
          segment108: { label: i18n.householdSingles, value: 0 },
          segment112: { label: i18n.householdCouples, value: 0 },
          segment113: { label: i18n.householdFamilies, value: 0 },
          segment111: { label: i18n.upperFirstCharUnknown, value: 0 }
        }
  };

  const employment = {
    label: i18n.employmentTitle,
    hintText: i18n.employmentTypeTooltip,
    color: 'color2',
    compareLink: Ids.Employment,
    segments: features?.employmentRange
      ? customRange(features.employmentRange)
      : {
          segment139: { label: i18n.primaryEmploymentType, value: 0 },
          segment140: { label: i18n.secondaryEmploymentType, value: 0 },
          segment141: { label: i18n.tertiaryEmploymentType, value: 0 }
        }
  };

  return {
    [Ids.Age]: age,
    [Ids.Gender]: gender,
    [Ids.Children]: children,
    [Ids.Income]: income,
    [Ids.Education]: education,
    [Ids.Household]: household,
    [Ids.Employment]: employment
  };
};
