export const pendo = {
  initScript(apiToken?: string) {
    /* eslint-disable */
    (function (apiKey) {
      // @ts-ignore
      (function (p, e, n, d, o) {
        // @ts-ignore
        let v, w, x, y, z;
        // @ts-ignore
        o = p[d] = p[d] || {};
        // @ts-ignore
        o._q = o._q || [];
        v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
        for (w = 0, x = v.length; w < x; ++w)
          (function (m) {
            // @ts-ignore
            o[m] =
              // @ts-ignore
              o[m] ||
              function () {
                // @ts-ignore
                o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)));
              };
          })(v[w]);
        // @ts-ignore
        y = e.createElement(n);
        // @ts-ignore
        y.async = !0;
        // @ts-ignore
        y.src = 'https://cdn.pendo.io/agent/static/' + apiKey + '/pendo.js';
        y.id = 'pendo-script';
        // @ts-ignore
        z = e.getElementsByTagName(n)[0];
        // @ts-ignore
        z.parentNode.insertBefore(y, z);
      })(window, document, 'script', 'pendo');
    })(apiToken);
  },

  init(accountId?: string, visitorId?: string) {
    if (!!visitorId) {
      // @ts-ignore
      window['pendo'].initialize({
        account: {
          id: accountId
        },
        visitor: {
          id: visitorId
        }
      });
    }
  },

  enable(apiToken?: string) {
    // @ts-ignore
    window['pendo'].apiKey = apiToken;
    // @ts-ignore
    window['pendo'].startSendingEvents();
  },

  disable() {
    // @ts-ignore
    window['pendo'].stopSendingEvents();
    // @ts-ignore
    window['pendo'].apiKey = null;
  }
}
