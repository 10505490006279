import { ModuleType } from 'i18next';
import { variablesService } from 'services/variables';

const consoleLogger = {
  type: 'logger' as ModuleType,

  log(args: string[] = []) {
    if (`${args[0]}`.includes('missingKey')) {
      this.output('warn', [
        `Translation key not found: \nkey: ${args[3]}, fallback: ${args[4]}, language: ${args[1]}, ns: ${args[2]}`
      ]);
    } else {
      this.output('log', args);
    }
  },

  warn(args: string[] = []) {
    this.output('warn', args);
  },

  error(args: string[] = []) {
    this.output('error', args);
  },

  output(type: keyof typeof console, args: string[] = []) {
    if (variablesService.getItem('logTranslations') && console && console[type]) {
      /* eslint no-console: 0 */
      // eslint-disable-next-line prefer-spread
      console[type].apply(console, args);
    }
  }
};

export default consoleLogger;
