import { EventEmitter } from 'events';

export default class TaskQueue {
  private taskQueue: (() => Promise<void>)[] = [];

  private inProgress: boolean = false;

  public add(asyncTask: () => {}): EventEmitter {
    const eventEmitter = new EventEmitter();
    const task = async () => {
      try {
        const result = await asyncTask();
        eventEmitter.emit('done', result);
      } catch (e) {
        eventEmitter.emit('error', e);
      }
    };
    this.enqueueTask(task);

    if (!this.inProgress) this.execute();
    return eventEmitter;
  }

  private enqueueTask(task: () => Promise<void>): void {
    this.taskQueue.push(task);
  }

  private async execute() {
    this.inProgress = true;
    while (this.taskQueue.length) {
      const task = this.taskQueue.shift();
      if (task) await task();
    }
    this.inProgress = false;
  }
}
