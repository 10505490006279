import { LocaleSpecification } from 'moment';
import { DropdownWithButtonsListItem } from 'shared-components/DropdownWithButtons/types';
import { CompareItemChartStats } from 'components/AdvancedAnalyticsEvaluate/types';
import { UserLocation } from '../settings';

export type TransactionCustomerType = 'returning' | 'new';
export type TransactionType = 'sales' | 'visitors';
export type CustomerType = TransactionCustomerType | 'all';

export type SpotlightsData = {
  [key in CustomerType]: { [key in 'day' | 'hour']: string };
};

type LineChartDailyData = {
  [key in TransactionCustomerType]: number;
} & { noData: boolean; starttime: string };

interface LineChartDailyDataVisitors extends LineChartDailyData {
  predictionAvgCount?: number;
  predictionUpperCount?: number;
  predictionLowerCount?: number;
}

interface LineChartDailyDataSales extends LineChartDailyData {
  predictionAvgAmount?: number;
  predictionUpperAmount?: number;
  predictionLowerAmount?: number;
}

export interface LineChartData {
  currencies: string[];
  transactions: {
    visitors: LineChartDailyDataVisitors[];
    sales: LineChartDailyDataSales[];
  };
}

export type DayPartData = {
  [key in CustomerType]: {
    label: string;
    values: { [key in TransactionType | 'x']: string | number }[];
  }[];
};

export type TransactionsOverviewDataKeys = 'sales' | 'visits' | 'avg';
export type TransactionsOverviewData = {
  [key in TransactionCustomerType]: { [key in TransactionsOverviewDataKeys]: number };
};

export type CompareLocationsItem = {
  [key in 'sales' | 'visits' | 'avgTicket']: {
    [key in TransactionCustomerType]: number;
  };
} & { mid: string; locationInfo: UserLocation };

export type PerformersDataKey =
  | 'lowestSalesNew'
  | 'lowestSalesReturning'
  | 'lowestVisitsNew'
  | 'lowestVisitsReturning'
  | 'topSalesNew'
  | 'topSalesReturning'
  | 'topVisitsNew'
  | 'topVisitsReturning';

type PerformersData = {
  [key in PerformersDataKey]: {
    mid: string;
    value: number;
  };
};

export type ComparisonTableLocations = CompareLocationsItem[];

interface CompareLocationsData {
  locations: ComparisonTableLocations;
  performers: PerformersData;
}

export type PerfomerBoxData = {
  [key in TransactionType]?: {
    [key in TransactionCustomerType]?: UserLocation;
  };
};

export interface TransactionsResponse {
  lineChart?: LineChartData;
  dayPartData?: DayPartData;
  insightsOverview?: TransactionsOverviewData;
  averagesAllData?: SpotlightsData;
  compareLocations?: CompareLocationsData;
}

export interface EvaluateSingleCard {
  value: number;
  mom: number;
  yoy: number;
  chartData: CompareItemChartStats[];
}

export interface TransactionsEvaluateGroupResponse {
  sales: EvaluateSingleCard;
  visits: EvaluateSingleCard;
  avg: EvaluateSingleCard;
}

export interface TransactionsEvaluateResponse {
  testGroup: TransactionsEvaluateGroupResponse;
  controlGroup: TransactionsEvaluateGroupResponse;
}

export interface WeeklySalesBreakdownDayData {
  date: string;
  credit: number | null;
  debit: number | null;
}

export interface WeeklySalesBreakdownResponse {
  new: WeeklySalesBreakdownDayData[];
  returning: WeeklySalesBreakdownDayData[];
  all: WeeklySalesBreakdownDayData[];
}

export interface ComparisonTableSorted {
  id: string;
  desc: boolean;
}

export interface ComparisonTableOptions {
  sorted: ComparisonTableSorted[];
  pageSize: number;
  page: number;
}

export interface AnalyticsState {
  data: TransactionsResponse & { overviewGrowth?: TransactionsOverviewGrowth; ticketTiers?: TicketTiersResponse };
  loading: boolean;
  comparisonTableOptions: ComparisonTableOptions;
  expandedCharts: string[];
  temp: {
    chartFilter: DropdownWithButtonsListItem<ChartFilterValue>[];
  };
  testGroup: TransactionsEvaluateGroupResponse;
  controlGroup: TransactionsEvaluateGroupResponse;
  weeklySalesBreakdown?: WeeklySalesBreakdownResponse;
}

export enum ChartFilterValue {
  All = 'all',
  Returning = 'returning',
  New = 'new'
}

interface FetchTransactionsPayload {
  start: string;
  end: string;
  locations: string[];
}
export interface FetchTransactionsRequestPayload {
  locations: string[];
  start: string;
  end: string;
  moduleName: string;
  momentSettings?: LocaleSpecification;
}

export interface WeeklySalesBreakdownRequestPayload {
  locations: string[];
  start: string;
  end: string;
}

export interface FetchTransactionsEvaluateRequestPayload {
  locations: string[];
  start: string;
  end: string;
}

export interface FetchOverviewGrowthRequestPayload extends FetchTransactionsRequestPayload {
  currentOverview: TransactionsOverviewData;
}
export type TransactionsOverviewGrowthKeysMonth =
  | 'visitsMonthBackGrowth'
  | 'salesMonthBackGrowth'
  | 'avgMonthBackGrowth';
export type TransactionsOverviewGrowthKeysYear = 'visitsYearBackGrowth' | 'salesYearBackGrowth' | 'avgYearBackGrowth';
export type TransactionsOverviewGrowthKeys = TransactionsOverviewGrowthKeysMonth | TransactionsOverviewGrowthKeysYear;
export type TransactionsOverviewGrowthObj = { [key in TransactionsOverviewGrowthKeys]: number };
export type TransactionsOverviewGrowth = {
  [key in TransactionCustomerType]: TransactionsOverviewGrowthObj;
};

export type TicketTiersKeys = 'NEW' | 'RETURNING' | 'ALL';
export type TicketTiersValue = {
  [key in 'DEBIT' | 'CREDIT']: number;
};
export type TicketTiersResponse = {
  [key in TicketTiersKeys]: {
    [key: string]: TicketTiersValue;
  };
};
