import React, { ReactElement } from 'react';
import { Text2Col as MailBoxText2ColData } from '../../../store/emailMarketing/emailTemplateEditor';
import { MailBoxProps } from '../types';

export const Text2Col = (props: MailBoxProps<MailBoxText2ColData>): ReactElement => {
  const { boxData, styles = {} } = props;

  const { color = '' } = styles;
  const { textCol1 = '', textCol2 = '' } = boxData;
  const ownStyles = { color, width: '50%', padding: '3px' };

  return (
    <div style={{ width: '100%' }}>
      <div style={{ ...ownStyles, float: 'left' }} dangerouslySetInnerHTML={{ __html: textCol1 }} />
      <div style={{ ...ownStyles, float: 'right' }} dangerouslySetInnerHTML={{ __html: textCol2 }} />
    </div>
  );
};
