import { ActionType, createReducer } from 'typesafe-actions';
import { ReviewsTrendState } from './types';
import { reviewsTrendActions } from './actions';

const initialState: ReviewsTrendState = {
  loading: false,
  error: false,
  data: null
};

export const reviewsTrendReducer = createReducer<ReviewsTrendState, ActionType<typeof reviewsTrendActions>>(
  initialState
)
  .handleAction(reviewsTrendActions.fetchReviewsTrend.request, state => ({ ...state, loading: true, error: false }))
  .handleAction(reviewsTrendActions.fetchReviewsTrend.success, (state, action) => ({
    ...state,
    loading: false,
    error: false,
    data: action.payload
  }))
  .handleAction(reviewsTrendActions.fetchReviewsTrend.failure, () => ({ ...initialState, error: true }))
  .handleAction(reviewsTrendActions.resetReviewsTrend, () => initialState);
