import { get } from 'lodash';
import { DEFAULT_CONTACT_AGE_LIMIT } from 'appConstants/emailMarketing';
import { AppState } from 'store/rootReducer';
import { getActiveSubPage } from 'store/navigation';

export const getActiveContacts = (state: AppState) => state.emailMarketing.contacts.active;
export const getUnsubscribedContacts = (state: AppState) => state.emailMarketing.contacts.unsubscribed;
export const getBouncedContacts = (state: AppState) => state.emailMarketing.contacts.bounced;
export const getAllContacts = (state: AppState) => state.emailMarketing.contacts;
export const getBackgroundJobStatus = (state: AppState) => state.emailMarketing.contacts.backgroundJob;
export const getEmailExistsAsCompanyEmailModalState = (state: AppState) =>
  state.emailMarketing.contacts.active.showEmailExistsAsCompanyEmailModal || false;
export const getEmailFieldError = (state: AppState) =>
  state.emailMarketing.contacts.active.validationErrors
    ? state.emailMarketing.contacts.active.validationErrors.email
    : '';
export const getContactAgeLimit = (state: AppState): any => {
  const activeSubPage = getActiveSubPage(state);
  const contactsPageElement = activeSubPage?.elements.filter(element => element.componentName === 'ContactsPage')[0];
  return get(contactsPageElement, 'features.contactAgeLimit') || DEFAULT_CONTACT_AGE_LIMIT;
};
