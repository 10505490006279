import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { AppState } from 'store/rootReducer';
import { deviceMedia } from 'appConstants';
import { LinkedLogo } from 'shared-components/LinkedLogo';
import { isPdfModeEnabled } from 'store/pdf';
import {
  getHeaderLogoAltText,
  getHeaderLogoMobileStyles,
  getHeaderLogoMobileUrl,
  getHeaderLogoStyles,
  getHeaderLogoUrl
} from 'store/branding';

interface LogoComponentProps {
  className?: string;
}

interface OwnProps {
  isMobile?: boolean;
}

interface ConnectedProps {
  logo: string;
  logoAltText: string;
  logoStyles: string;
  logoMobile: string;
  logoMobileStyles: string;
  onlyImg: boolean;
}

const LogoComponent = ({
  logo,
  logoMobile,
  logoAltText,
  className,
  isMobile,
  onlyImg
}: LogoComponentProps & ConnectedProps & OwnProps): JSX.Element => (
  <LinkedLogo
    imageSrc={isMobile && logoMobile ? logoMobile : logo}
    imageAltText={logoAltText}
    linkClassName={`header-logo-link ${className}`}
    imgClassName="header-logo-image"
    onlyImg={onlyImg}
  />
);

export const StyledLogo = styled(LogoComponent)<{ logoStyles: string }>`
  display: inline-block;
  img {
    height: 24px;
    display: block;
  }
  ${props => props.logoStyles};
  @media ${deviceMedia.tablet} {
    img {
      max-width: 100%;
    }
  }
  @media ${deviceMedia.mobile} {
    ${props => props.logoMobileStyles};
  }
`;

export const Logo = connect<ConnectedProps, void, OwnProps, AppState>(
  (state: AppState): ConnectedProps => ({
    logoAltText: getHeaderLogoAltText(state),
    logo: getHeaderLogoUrl(state),
    logoStyles: getHeaderLogoStyles(state),
    logoMobile: getHeaderLogoMobileUrl(state),
    logoMobileStyles: getHeaderLogoMobileStyles(state),
    onlyImg: isPdfModeEnabled(state)
  })
)(StyledLogo);
