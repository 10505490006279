import { ActionType, createReducer } from 'typesafe-actions';
import { ReviewsState } from './types';
import reviewsActions from './actions';

const initialState: ReviewsState = {
  loading: false,
  error: false,
  data: null,
  newestReviews: [],
  pagination: {
    limit: 10,
    offset: 0,
    siteIds: [],
    sorter: 'date',
    order: 'desc'
  }
};

export const reviewsReducer = createReducer<ReviewsState, ActionType<typeof reviewsActions>>(initialState)
  .handleAction(reviewsActions.fetchReviews.request, state => ({ ...state, loading: true, error: false }))
  .handleAction(reviewsActions.fetchReviews.success, (state, action) => ({
    ...state,
    loading: false,
    error: false,
    data: action.payload
  }))
  .handleAction(reviewsActions.fetchReviews.failure, () => ({ ...initialState, error: true }))
  .handleAction(reviewsActions.pagination.setPagination, (state, action) => ({ ...state, pagination: action.payload }))
  .handleAction(reviewsActions.getNewestReviews, (state, action) => ({ ...state, newestReviews: action.payload }))
  .handleAction(reviewsActions.reset, () => initialState);
