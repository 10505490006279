import { createAction, createAsyncAction } from 'typesafe-actions';
import { ComparisonTableOptions } from 'store/transactions';
import { DemographicCategories } from 'components/CustomerDemographics/types';

const fetchMasterCardData = createAsyncAction(
  '@masterCard/data/fetch',
  '@masterCard/data/success',
  '@masterCard/data/error'
)<void, any, void>();

const fetchMasterCardCompareData = createAsyncAction(
  '@masterCard/compare/fetch',
  '@masterCard/compare/success',
  '@masterCard/compare/error'
)<void, any, void>();

const fetchGeoInfoForSelectedLocation = createAsyncAction(
  '@masterCard/geoData/fetch',
  '@masterCard/geoData/success',
  '@masterCard/geoData/error'
)<void, any, void>();

const fetchPostalCodesInfo = createAsyncAction(
  '@masterCard/geoData/postalCodesInfo/fetch',
  '@masterCard/geoData/postalCodesInfo/success',
  '@masterCard/geoData/postalCodesInfo/error'
)<void, any, void>();

const setPeriod = createAction('@masterCard/period/set', action => (period: string) => action(period));

const setComparisonTableOptions = createAction(
  '@masterCard/compare/setComparisonTableOptions',
  action => (payload: ComparisonTableOptions) => action(payload)
);

const resetMasterCardData = createAction('@masterCard/data/reset', action => () => action());

const setFeatures = createAction(
  '@masterCard/features/set',
  action => (features: (string | boolean)[]) => action(features)
);

const setDemographicsCategory = createAction(
  '@masterCard/demographicsCategory/set',
  action => (newCategory: DemographicCategories) => action(newCategory)
);

export const masterCardActions = {
  fetchMasterCardData,
  fetchGeoInfoForSelectedLocation,
  fetchPostalCodesInfo,
  fetchMasterCardCompareData,
  setPeriod,
  setFeatures,
  setComparisonTableOptions,
  resetMasterCardData,
  setDemographicsCategory
};
