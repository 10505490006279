import React from 'react';

import { getSelectedLocationsInfo, LocationsList } from 'store/settings';
import { PageElement } from 'shared-components/PageLayoutComposer';
import WidgetWrapper from 'shared-components/WidgetWrapper';
import { connect } from 'react-redux';
import { AppState } from 'store/rootReducer';
import { isPdfModeEnabled } from 'store/pdf';

interface ConnectedProps {
  pdfMode: boolean;
  locations: LocationsList;
}

const LocationsVisualiserComponent = ({ locations, pdfMode }: ConnectedProps): JSX.Element | null =>
  pdfMode && Array.isArray(locations) ? (
    <PageElement className="page-element" styles={{ order: '0' }}>
      <WidgetWrapper>
        {locations.map(location => `${location.merchant_name} (${location.merchant_number})`).join(', ')}
      </WidgetWrapper>
    </PageElement>
  ) : null;

export const LocationsVisualiser = connect(
  (state: AppState): ConnectedProps => ({
    pdfMode: isPdfModeEnabled(state),
    locations: getSelectedLocationsInfo(state)
  })
)(LocationsVisualiserComponent);
