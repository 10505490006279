import { createAction, createAsyncAction } from 'typesafe-actions';
import { LocationFilter, LocationSearch } from './types';

export const locationsActions = {
  search: {
    list: createAsyncAction('@locations/search', '@locations/search/success', '@locations/search/error')<
      void,
      LocationSearch,
      void
    >(),
    item: {
      create: createAsyncAction(
        '@locations/search/create',
        '@locations/search/create/success',
        '@locations/search/create/error'
      )<LocationFilter, LocationFilter, void>(),
      delete: createAsyncAction(
        '@locations/search/delete',
        '@locations/search/delete/success',
        '@locations/search/delete/error'
      )<LocationFilter, LocationFilter, void>()
    },
    addRecent: createAsyncAction(
      '@locations/search/recent/add',
      '@locations/search/recent/add/success',
      '@locations/search/recent/add/error'
    )<string, string, void>()
  },
  select: createAction('@locations/select', action => (selectedLocations: string[]) => action(selectedLocations)),
  selectTestGroup: createAction(
    '@locations/selectTestGroup',
    action => (selectedLocations: string[]) => action(selectedLocations)
  ),
  selectControlGroup: createAction(
    '@locations/selectControlGroup',
    action => (selectedLocations: string[]) => action(selectedLocations)
  ),
  isContactsWithoutLocations: createAction(
    '@locations/isContactsWithoutLocations',
    action => (checked: boolean) => action(checked)
  ),
  isTestGroupDropDownShown: createAction(
    '@locations/isTestGroupDropDownShown',
    action => (shown: boolean, place?: string) => action({ shown, place })
  ),
  isControlGroupDropDownShown: createAction(
    '@locations/isControlGroupDropDownShown',
    action => (shown: boolean, place?: string) => action({ shown, place })
  ),
  isDropDownShown: createAction(
    '@locations/isDropDownShown',
    action => (shown: boolean, place?: string) => action({ shown, place })
  )
};
