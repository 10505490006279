import styled from 'styled-components';
import { getColor } from '../../layout/theme';

interface CloseIconInterface {
  size: number;
  strokeWidth?: number;
  color?: string;
}
export const CloseIconPure = styled.div<CloseIconInterface>`
  display: inline-block;
  ${props => `
  width: ${props.size}px;
  height: ${props.size}px;
  position: relative;
  &:before, &:after {
    position: absolute;
    left: ${props.size / 2 - (props.strokeWidth || 2) / 2}px;
    content: ' ';
    height: ${props.size}px;
    width: ${props.strokeWidth || 2}px;
    background-color: ${getColor(props, props.color || 'darkGrey')};
  }
  `}
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
`;
