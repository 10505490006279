import { ActionType, createAction } from 'typesafe-actions';

export const feeTrendsActions = {
  setIsFeeTrendsDisabled: createAction(
    '@aa/feeTrends/setIsFeeTrendsDisabled',
    action => (payload: boolean) => action(payload)
  )
};

export type FeeTrendsActions = ActionType<typeof feeTrendsActions>;
