import { ActionType, createReducer } from 'typesafe-actions';
import { find } from 'lodash';

import * as types from './types';
import { competitorsActions } from './actions';

const {
  fetchPickedCompetitors,
  fetchPickedCompetitorsData,
  fetchCompetitorReviews,
  competitorReviewsPagination,
  fetchCompetitors,
  pickCompetitors,
  fetchInvalidCompetitors,
  filterCompetitors,
  beginPickCompetitor,
  reset
} = competitorsActions;

const initialState: types.CompetitorsState = {
  picked: {
    loading: false,
    error: false,
    data: undefined
  },
  pickedCompetitorsData: {
    loading: false,
    reviewsLoading: false,
    error: false,
    data: undefined
  },
  competitors: {
    loading: false,
    error: false,
    data: undefined
  },
  invalid: {
    loading: false,
    error: false,
    data: undefined
  },
  competitorsFilters: {},
  competitorsSelectionStatus: {
    error: false,
    isCompleted: false
  }
};

export const competitorsReducer = createReducer<types.CompetitorsState, ActionType<typeof competitorsActions>>(
  initialState
)
  // PICKED COMPETITORS
  .handleAction(fetchPickedCompetitors.request, state => ({
    ...state,
    picked: { ...state.picked, loading: true, error: false }
  }))
  .handleAction(fetchPickedCompetitors.success, (state, action) => ({
    ...state,
    picked: {
      ...state.picked,
      loading: false,
      error: false,
      data: action.payload
    }
  }))
  .handleAction(fetchPickedCompetitors.failure, state => ({
    ...state,
    picked: { ...initialState.picked, error: true }
  }))

  // PICK COMPETITORS
  .handleAction(beginPickCompetitor, state => ({
    ...state,
    competitorsSelectionStatus: { ...state.competitorsSelectionStatus, isCompleted: false }
  }))
  .handleAction(pickCompetitors.request, state => ({
    ...state,
    com: { ...initialState.competitorsSelectionStatus }
  }))
  .handleAction(pickCompetitors.success, state => ({
    ...state,
    competitorsSelectionStatus: { ...state.competitorsSelectionStatus, isCompleted: true }
  }))
  .handleAction(pickCompetitors.failure, state => ({
    ...state,
    competitorsSelectionStatus: { ...state.competitorsSelectionStatus, error: true }
  }))
  .handleAction(pickCompetitors.cancel, state => ({
    ...state,
    pickingStatus: { ...initialState.competitorsSelectionStatus }
  }))

  // PICKED COMPETITORS DATA
  .handleAction(fetchPickedCompetitorsData.request, state => ({
    ...state,
    pickedCompetitorsData: { ...state.pickedCompetitorsData, loading: true, error: false }
  }))
  .handleAction(fetchPickedCompetitorsData.success, (state, action) => ({
    ...state,
    pickedCompetitorsData: {
      ...state.pickedCompetitorsData,
      loading: false,
      error: false,
      data: action.payload
    }
  }))
  .handleAction(fetchPickedCompetitorsData.failure, state => ({
    ...state,
    pickedCompetitorsData: { ...initialState.pickedCompetitorsData, error: true }
  }))

  // COMPETITOR REVIEWS
  .handleAction(fetchCompetitorReviews.request, state => ({
    ...state,
    pickedCompetitorsData: {
      ...state.pickedCompetitorsData,
      reviewsLoading: true
    }
  }))
  .handleAction(fetchCompetitorReviews.success, (state, action) => {
    const competitorToUpdate = find<types.Competitor>(state.pickedCompetitorsData.data, {
      competitorId: action.payload.competitorId
    });
    if (competitorToUpdate && state.pickedCompetitorsData.data) {
      return {
        ...state,
        pickedCompetitorsData: {
          ...state.pickedCompetitorsData,
          reviewsLoading: false,
          data: [
            ...(state.pickedCompetitorsData.data.filter(
              competitor => competitor.competitorId !== competitorToUpdate.competitorId
            ) || []),
            {
              ...competitorToUpdate,
              reviews: action.payload.reviewsData.reviews
            }
          ]
        }
      };
    }
    return { ...state };
  })
  .handleAction(competitorReviewsPagination.setPagination, (state, action) => {
    const competitorToUpdate = find<types.Competitor>(state.pickedCompetitorsData.data, {
      competitorId: action.payload.competitorId
    });
    if (competitorToUpdate && state.pickedCompetitorsData.data) {
      competitorToUpdate.paginationOptions = action.payload.paginationOptions;
      return {
        ...state,
        pickedCompetitorsData: {
          ...state.pickedCompetitorsData,
          data: [
            ...(state.pickedCompetitorsData.data.filter(
              competitor => competitor.competitorId !== competitorToUpdate.competitorId
            ) || []),
            competitorToUpdate
          ]
        }
      };
    }
    return { ...state };
  })

  // COMPETITORS
  .handleAction(fetchCompetitors.request, state => ({
    ...state,
    competitors: { ...state.competitors, loading: true, error: false }
  }))
  .handleAction(fetchCompetitors.success, (state, action) => ({
    ...state,
    competitors: {
      ...state.competitors,
      loading: false,
      error: false,
      data: action.payload
    }
  }))
  .handleAction(fetchCompetitors.failure, state => ({
    ...state,
    competitors: { ...initialState.competitors, error: true }
  }))

  // INVALID COMPETITORS
  .handleAction(fetchInvalidCompetitors.request, state => ({
    ...state,
    invalid: { ...state.invalid, loading: true }
  }))
  .handleAction(fetchInvalidCompetitors.success, (state, action) => ({
    ...state,
    invalid: {
      ...state.invalid,
      loading: false,
      error: false,
      data: action.payload
    }
  }))
  .handleAction(fetchInvalidCompetitors.failure, state => ({
    ...state,
    invalid: { ...initialState.invalid, error: true }
  }))

  // COMPETITORS FILTERS
  .handleAction(filterCompetitors.addFilter, (state, action) => ({
    ...state,
    competitorsFilters: { ...state.competitorsFilters, ...action.payload }
  }))
  .handleAction(filterCompetitors.resetFilters, state => ({ ...state, competitorsFilters: {} }))
  .handleAction(reset, () => initialState);
