import { ActionType, createReducer } from 'typesafe-actions';
import { pdfPuppeteerActions } from './actions';

export interface PdfPuppeteerState {
  loading: boolean;
}

const defaultState: PdfPuppeteerState = {
  loading: false
};

export const pdfPuppeteerReducer = createReducer<PdfPuppeteerState, ActionType<typeof pdfPuppeteerActions>>(
  defaultState
)
  .handleAction(pdfPuppeteerActions.generatePdf.request, state => ({ ...state, loading: true }))
  .handleAction(pdfPuppeteerActions.generatePdf.success, state => ({ ...state, loading: false }))
  .handleAction(pdfPuppeteerActions.generatePdf.failure, state => ({ ...state, loading: false }));
