import { ActionType, createReducer } from 'typesafe-actions';
import { CRMAutomationProps } from './types';
import { emailMarketingCrmAutomation } from './actions';

const defaultState: CRMAutomationProps = {
  fetched: false,
  loading: false,
  loadingTemplates: false,
  savingTemplate: false,
  statuses: [],
  templates: []
};

export const emailMarketingCrmAutomationReducer = createReducer<
  CRMAutomationProps,
  ActionType<typeof emailMarketingCrmAutomation>
>(defaultState)
  .handleAction(
    [
      emailMarketingCrmAutomation.fetchStatuses.request,
      emailMarketingCrmAutomation.turnOn.request,
      emailMarketingCrmAutomation.turnOff.request
    ],
    state => ({
      ...state,
      fetched: true,
      loading: true,
      loadingTemplates: true
    })
  )
  .handleAction(emailMarketingCrmAutomation.fetchStatuses.success, (state, action) => ({
    ...state,
    loading: false,
    statuses: action.payload
  }))
  .handleAction(emailMarketingCrmAutomation.turnOn.success, state => ({
    ...state,
    loading: false
  }))
  .handleAction(emailMarketingCrmAutomation.fetchTemplates.request, state => ({
    ...state,
    loadingTemplates: true
  }))
  .handleAction(emailMarketingCrmAutomation.fetchTemplates.success, (state, action) => ({
    ...state,
    loadingTemplates: false,
    templates: action.payload
  }))
  .handleAction(emailMarketingCrmAutomation.fetchTemplates.failure, state => ({
    ...state,
    loadingTemplates: false,
    templates: []
  }))
  .handleAction(emailMarketingCrmAutomation.saveTemplate.request, state => ({
    ...state,
    savingTemplate: true
  }))
  .handleAction(emailMarketingCrmAutomation.saveTemplate.success, state => ({
    ...state,
    savingTemplate: false
  }))
  .handleAction(emailMarketingCrmAutomation.saveTemplate.failure, state => ({
    ...state,
    savingTemplate: false
  }));
