import { combineReducers } from 'redux';

import { activeContactsReducer } from './active';
import { bouncedContactsReducer } from './bounced';
import { unsubscribedContactsReducer } from './unsubscribed';
import { backgroundJobReducer } from './backgroundJob';

export const contactsReducer = combineReducers({
  active: activeContactsReducer,
  bounced: bouncedContactsReducer,
  unsubscribed: unsubscribedContactsReducer,
  backgroundJob: backgroundJobReducer
});
