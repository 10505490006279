import { createAction, createAsyncAction } from 'typesafe-actions';
import { FetchFacebookConnectInfoPayload, FetchFacebookConnectInfoResponse } from './types';

const fetchFacebookConnectInfo = createAsyncAction(
  '@merchantCentric/facebookConnect/fetch',
  '@merchantCentric/facebookConnect/fetch/success',
  '@merchantCentric/facebookConnect/fetch/error'
)<FetchFacebookConnectInfoPayload, FetchFacebookConnectInfoResponse, void>();

const reset = createAction('@merchantCentric/facebookConnect/reset');

export const facebookConnectActions = {
  fetchFacebookConnectInfo,
  reset
};
