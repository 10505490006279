import styled from 'styled-components';
import { getColor } from 'layout/theme';
import { deviceMedia } from 'appConstants';

export const Container = styled.div`
  position: relative;
  color: ${props => getColor(props, 'darkGrey')};
`;

export const DropdownContainer = styled.div<{ isMobile?: boolean; withBorder?: boolean }>`
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: ${props => getColor(props, 'white')};
  border: 1px solid ${props => getColor(props, 'blueGrey')};
  box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.5);
  border-top: 0;
  z-index: 999;
  @media ${deviceMedia.mobile} {
    ${props =>
      props.isMobile
        ? `
      box-shadow: none;
      ${props.withBorder ? '' : 'border-width: 0 0 1px 0;'}
    `
        : ''};
  }
`;

export const FooterButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 0.5 1 auto;
  margin-left: auto;
  button {
    margin-left: 10px;
    &:first-child {
      margin-left: 0;
    }
  }
  @media ${deviceMedia.mobile} {
    flex: 1;
  }
`;
FooterButtons.displayName = 'FooterButtons';

export const PaginationWrapper = styled.div`
  flex: 1;
  margin: 0px -5px;
`;

export const Footer = styled.div`
  background: ${props => getColor(props, 'lightGrey')};
  padding: 10px 15px;
  display: flex;
  border-top: 1px solid ${props => getColor(props, 'blueGrey')};
  padding-top: 5px;
  padding-bottom: 5px;
  flex-wrap: wrap;
  > * {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  @media ${deviceMedia.mobile} {
    > * {
      justify-content: flex-end;
    }
  }
`;

export const DisableLayer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  cursor: not-allowed;
  z-index: 1;
  background-color: ${props => getColor(props, 'blueGrey')};
  opacity: 0.5;
`;
