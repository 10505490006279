import { ActionType, createReducer } from 'typesafe-actions';
import { WeatherState } from './types';
import { fetchWeather } from './actions';

const defaultState: WeatherState = {
  loading: false,
  error: false,
  data: []
};

export const weatherReducer = createReducer<WeatherState, ActionType<typeof fetchWeather>>(defaultState)
  .handleAction(fetchWeather.request, state => ({ ...state, loading: true, error: false }))
  .handleAction(fetchWeather.success, (state, action) => ({
    ...state,
    loading: false,
    error: false,
    data: [...action.payload]
  }))
  .handleAction(fetchWeather.failure, state => ({ ...state, loading: false, error: true }));
