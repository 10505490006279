import { ActionType, createReducer } from 'typesafe-actions';
import { SocialPlatformsSettingsState } from './types';
import { socialPlatformsSettingsActions } from './actions';

const initialState: SocialPlatformsSettingsState = {
  loading: false,
  error: false,
  config: null
};

export const socialPlatformsSettingsReducer = createReducer<
  SocialPlatformsSettingsState,
  ActionType<typeof socialPlatformsSettingsActions>
>(initialState)
  .handleAction(socialPlatformsSettingsActions.fetchSocialPlatformsSettings.request, () => ({
    ...initialState,
    loading: true
  }))
  .handleAction(socialPlatformsSettingsActions.fetchSocialPlatformsSettings.success, (state, action) => ({
    ...state,
    loading: false,
    error: false,
    config: action.payload
  }))
  .handleAction(socialPlatformsSettingsActions.fetchSocialPlatformsSettings.failure, () => ({
    ...initialState,
    error: true
  }));
