import { ActionType, createReducer } from 'typesafe-actions';
import { compareActions } from './actions';
import { CompareState } from './types';

const initialState: CompareState = {
  compareData: [],
  reviewsCompareData: [],
  receivedAtDates: [],
  loading: false,
  error: false,
  comparisonTableOptions: {
    sorted: [
      {
        id: 'locationInfo',
        desc: false
      }
    ],
    pageSize: 10,
    page: 0
  }
};

export const compareReducer = createReducer<CompareState, ActionType<typeof compareActions>>(initialState)
  .handleAction(compareActions.fetchCompareData.request, state => ({ ...state, loading: true, error: false }))
  .handleAction(compareActions.fetchCompareData.success, (state, action) => ({
    ...state,
    loading: false,
    error: false,
    compareData: action.payload
  }))
  .handleAction(compareActions.fetchCompareData.failure, state => ({ ...state, loading: false }))
  .handleAction(compareActions.fetchReviewsCompareData.request, state => ({ ...state, loading: true, error: false }))
  .handleAction(compareActions.fetchReviewsCompareData.success, (state, action) => ({
    ...state,
    loading: false,
    error: false,
    reviewsCompareData: action.payload
  }))
  .handleAction(compareActions.fetchReviewsCompareData.failure, state => ({ ...state, loading: false }))
  .handleAction(compareActions.setComparisonTableOptions, (state, action) => ({
    ...state,
    comparisonTableOptions: action.payload
  }))
  .handleAction(compareActions.setReceivedAtDates, (state, action) => ({
    ...state,
    receivedAtDates: action.payload
  }));
