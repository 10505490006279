import { ActionType, createReducer } from 'typesafe-actions';
import { termsActions, fetchTermsData } from './actions';
import { TermsState, AvailableTermsTypes } from './types';

export const defaultState: TermsState = {
  mastercard: {
    confirmed: false,
    text: '',
    fetching: false
  },
  uberall: {
    confirmed: false,
    text: '',
    fetching: false
  },
  merchantCentric: {
    confirmed: false,
    text: '',
    fetching: false
  },
  emailMarketing: {
    text: '',
    personalDataText: '',
    fetching: false
  },
  emailMarketingPrivacyPolicy: {
    text: '',
    fetching: false
  },
  paymentAnalytics: {
    confirmed: false,
    text: '',
    fetching: false
  }
};

const setProperty = (state: TermsState, key: AvailableTermsTypes, newOption: any): TermsState => ({
  ...state,
  [key]: {
    ...state[key],
    ...newOption
  }
});

export const termsReducer = createReducer<TermsState, ActionType<typeof termsActions>>(defaultState)
  .handleAction(fetchTermsData.request, (state, action) => setProperty(state, action.payload.type, { fetching: true }))
  .handleAction(fetchTermsData.success, (state, action) =>
    setProperty(state, action.payload.type, {
      fetching: false,
      text: action.payload.termsText,
      personalDataText: action.payload.personalDataText
    })
  )
  .handleAction(fetchTermsData.failure, (state, action) =>
    setProperty(state, action.payload.type, { fetching: false, error: true })
  )
  .handleAction(termsActions.confirmTerms, (state, action) => setProperty(state, action.payload, { confirmed: true }));
