import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { AppState } from 'store/rootReducer';
import { DataLoadHOC } from 'components/DataLoadHOC';
import { AceLayout } from 'layout';
import { isSettingsLoaded, settingsActions } from 'store/settings';

interface ConnectedProps {
  settingsLoaded: boolean;
}

interface ConnectedActions {
  requestSettings: typeof settingsActions.info.request;
}

type PropTypes = ConnectedProps & RouteComponentProps & ConnectedActions;

const RootComponentWrapper = ({ settingsLoaded, requestSettings }: PropTypes): JSX.Element => (
  <DataLoadHOC action={requestSettings} condition={settingsLoaded}>
    <AceLayout />
  </DataLoadHOC>
);

export const RootComponent = withRouter(
  connect<ConnectedProps, ConnectedActions, {}, AppState>(
    (state: AppState): ConnectedProps => ({
      settingsLoaded: isSettingsLoaded(state)
    }),
    dispatch =>
      bindActionCreators(
        {
          requestSettings: settingsActions.info.request
        },
        dispatch
      )
  )(RootComponentWrapper)
);
