import React, { Fragment, ReactElement, useEffect } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { supportSearchPathname, NO_PAGE_FOUND_PATH } from 'appConstants';
import { getMenuPages } from 'store/navigation';
import { Composer } from 'shared-components/PageLayoutComposer';
import { LayoutElement, Page } from 'store/settings/layout';
import { isDemo as isDemoSelector } from 'store/settings';
import { ApiError } from './components/error-pages/ApiError';
import { errorsActions } from './store/errors/actions';
import { errorsSelectors } from './store/errors/selectors';

interface RouteItem extends Page {
  elements: LayoutElement[];
  exact?: boolean;
  programId?: string;
  subPages: RouteItem[];
}

export type RoutesConfig = RouteItem[];

export const RouteWithNestedRoutes = ({
  path,
  subPages,
  elements,
  exact,
  errorDisplayed,
  isDemo
}: RouteItem & { errorDisplayed: boolean; isDemo: boolean }): ReactElement =>
  React.createElement(Route, {
    key: path,
    exact,
    path,
    render: () => {
      return errorDisplayed ? null : (
        <>
          {elements && Array.isArray(elements) ? <Composer isDemo={isDemo} schema={elements} /> : null}
          {subPages && Array.isArray(subPages) && subPages.length ? (
            <Switch>
              {subPages.map(nestedRoute =>
                RouteWithNestedRoutes({
                  ...nestedRoute,
                  path: `${path}${nestedRoute.path}`,
                  exact: true,
                  errorDisplayed,
                  isDemo
                })
              )}
              <Redirect from={path} exact to={`${path}${subPages[0].path}`} />
              <Redirect to={NO_PAGE_FOUND_PATH} />
            </Switch>
          ) : null}
        </>
      );
    }
  });

export const AppRouting = (): ReactElement | null => {
  const location = useLocation();
  const { isDisplayed: errorDisplayed, path: errorPath } = useSelector(errorsSelectors.getApiError);
  const statePages = useSelector(getMenuPages);
  const isDemo = useSelector(isDemoSelector);
  const dispatch = useDispatch();

  const copiedPages = [...statePages];
  const supportSearch = copiedPages.find(({ path }) => path === supportSearchPathname);
  const pages = copiedPages.filter(({ path, isExternalUi }) => path !== supportSearchPathname && !isExternalUi);

  const externalUiPages = copiedPages.filter(page => page.isExternalUi);
  const defaultPage = pages.find(page => !page.isExternalUi);

  if (supportSearch) pages.push(supportSearch);

  useEffect(() => {
    if (errorDisplayed && location.pathname !== errorPath) {
      dispatch(errorsActions.hideApiError());
    }
  }, [location, errorDisplayed]);

  return Array.isArray(pages) && pages.length ? (
    <Fragment>
      {errorDisplayed && <ApiError />}
      <Switch>
        {pages.map(route => {
          const isSubPages = route.subPages && Array.isArray(route.subPages) && route.subPages.length;
          return (
            <RouteWithNestedRoutes
              key={route.path}
              isDemo={isDemo}
              {...route}
              exact={!isSubPages}
              errorDisplayed={errorDisplayed}
            />
          );
        })}
        {externalUiPages.map(page => (
          <Route
            key={page.pageId}
            path={page.path}
            component={() => {
              // we need to sync refresh page, to allow nginx to catch the updated url and proxy pass to ace-ui bundle
              window.location.href = page.path;
              return <Fragment />;
            }}
          />
        ))}
        {/* Support search page requires force redirect to it */}
        {supportSearch && <Redirect from="/" exact to={supportSearch.path} />}
        <Redirect from="/" exact to={defaultPage?.path || ''} />
        <Redirect to={NO_PAGE_FOUND_PATH} />
      </Switch>
    </Fragment>
  ) : null;
};
