import { AppState } from '../../rootReducer';
import { CRMAutomationData } from './types';

export const getStatuses = (state: AppState): CRMAutomationData[] => state.emailMarketing.crmAutomation.statuses;

export const isCRMAutomationLoading = (state: AppState): boolean => state.emailMarketing.crmAutomation.loading;
export const isCRMAutomationFetched = (state: AppState): boolean => state.emailMarketing.crmAutomation.fetched;
export const isCRMAutomationTemplatesLoading = (state: AppState): boolean =>
  state.emailMarketing.crmAutomation.loadingTemplates;
export const isCRMAutomationTemplateSaving = (state: AppState): boolean =>
  state.emailMarketing.crmAutomation.savingTemplate;

export const getTemplates = (state: AppState): any[] => state.emailMarketing.crmAutomation.templates;
