/* eslint-disable */
/* ro_RO Static Templates Data */

const AutomotiveServiceShopEmailData = {
  styles: {
    width: '600px',
    color: '#898989',
    backgroundColor: '#fff',
    couponBorderColor: '#00aeef',
    buttonsDividersColor: '#898989',
    sicailLinksColor: '#00aeef',
  },
  components: [
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_29.+Automotive+Service+Shops+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center">Depozitare gratuită pentru anvelope sezoniere!</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Obțineți service în aceeași zi pentru majoritatea reparațiilor auto</p>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Obțineți o estimare clară, fără costuri ascunse</p>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Sunați astăzi și faceți o programare!</p>',
    },
    {
      type: 'coupon',
      text: '<h2 class="ql-align-center">Dacă cumpărați o curățare manuală a vehiculului și schimbarea anvelopelor de la noi, vă stocăm gratuit anvelopele de sezon!</h2><p class="ql-align-center" style="font-size: 10px;">A nu se utiliza cu alte cupoane sau oferte.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const BakeryEmailData = {
  styles: {
    width: '600px',
    color: '#898989',
    backgroundColor: '#fff',
    couponBorderColor: '#00aeef',
    buttonsDividersColor: '#898989',
    sicailLinksColor: '#00aeef',
  },
  components: [
    {
      type: 'image',
      image: 'em-logos/bakery-email-logo_1498080292703.png',
    },
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_1.+Bakery+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center">10% reducere la următoarea achiziție</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Îți trimitem un mic cadou în semn de mulțumire. Nu ezita să utilizezi acest cupon la următoarea vizită la Bob\'s Bakery, unde totul este proaspăt copt, iar produsele sunt pregătite la fața locului folosind ingrediente de cea mai bună calitate. Intră să-ți iei produse de panificație pentru acasă sau așează-te și savurează una dintre numeroasele noastre prăjituri și plăcinte proaspăt scoase din cuptor. Așteptăm să te revedem curând!</p>',
    },
    {
      type: 'coupon',
      text: '<h2 class="ql-align-center">10% reducere la următoarea achiziție.</h2><p class="ql-align-center" style="font-size: 10px;">Cuponul poate fi folosit o singură dată și nu se cumulează cu alte cupoane sau oferte.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const BarberShopEmailData = {
  styles: {
    width: '600px',
    color: '#898989',
    backgroundColor: '#fff',
    couponBorderColor: '#00aeef',
    buttonsDividersColor: '#898989',
    sicailLinksColor: '#00aeef',
  },
  components: [
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_25.+Barber+Shop+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center">Pachet complet de îngrijire acum cu 10% reducere</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Cufundă-te într-un scaun confortabil și încredințează-ți părul și barba în grija unui frizer profesionist. Pe lângă tăierea perfectă, te poți bucura de cafea de calitate premium într-un mediu pur masculin. Sau există ceva mai clar?</p>',
    },
    {
      type: 'coupon',
      text: '<h2 class="ql-align-center">Un pachet complet de îngrijire, care include și un tratament pentru barbă, acum cu o reducere de 10% și rom la alegere din romurile noastre premium gratuit.</h2><p class="ql-align-center" style="font-size: 10px;">A nu se utiliza cu alte cupoane sau oferte.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const BarPubEmailData = {
  styles: {
    width: '600px',
    color: '#fff',
    backgroundColor: '#898989',
    couponBorderColor: '#fff',
    buttonsDividersColor: '#fff',
    sicailLinksColor: '#fff',
  },
  components: [
    {
      type: 'image',
      image: 'em-logos/bar-pub-email-logo_1498080596725.png',
    },
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_2.+Bar+Pub+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center">HAPPY HOUR IN FIECARE ZI</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center" style="font-weight: bold;">Zilnic de la 16:00 la 19:00</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Actualizăm săptămânal lista de băuturi.</p>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Vino să vezi oferta noastră de astăzi!</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'coupon',
      text: '<h2 class="ql-align-center">Reducere de 5 lei pentru produsele Happy Hour</h2><p class="ql-align-center">Valabil pentru achiziția unui singur produs.</p><br><p class="ql-align-center" style="font-size: 10px;">Cuponul poate fi folosit o singură dată și nu se cumulează cu alte oferte.</p><br>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const BeautyShopEmailData = {
  styles: {
    width: '600px',
    color: '#898989',
    backgroundColor: '#fff',
    couponBorderColor: '#00aeef',
    buttonsDividersColor: '#898989',
    sicailLinksColor: '#00aeef',
  },
  components: [
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_26.+Beauty+Shop+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center">Grăbește-te și obține o reducere de 10% la produsele cosmetice naturale exclusive</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Vino la noi pentru a te relaxa și a experimenta schimbarea conform propriilor idei. Vom avea grijă de tine din cap până în picioare.</p>',
    },
    {
      type: 'coupon',
      text: '<h2 class="ql-align-center">După finalizarea unui tratament de înfrumusețare în salonul nostru, vei primi o reducere de 10% la produsele cosmetice naturale exclusive.</h2><p class="ql-align-center" style="font-size: 10px;">A nu se utiliza cu alte cupoane sau oferte.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const BurgerEmailData = {
  styles: {
    width: '600px',
    backgroundColor: '#e11b22',
    color: '#fff',
    headingsColor: '#fff',
    buttonsDividersColor: '#fff',
  },
  components: [
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_3.+Burger+Template_CZ.jpg',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">ÎȚI ESTE FOAME? VEZI OFERTA NOASTRĂ!</p>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Pentru burgerii noștri folosim carne de cea mai bună calitate, 100% de vită, cu bacon afumat și legume proaspete! Adaugă la burgerul nostru o bere artizanală rece și începi săptămâna cu zâmbetul pe buze!</p>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Prezintă acest voucher personalului nostru.</p>',
    },
    {
      type: 'coupon',
      text: '<h3 class="ql-align-center">CUMPĂRĂ UN MENIU ȘI PE AL DOILEA ÎL PRIMEȘTI GRATUIT</h3><p class="ql-align-center">(produsul gratuit este cel cu costul cel mai mic)</p><p class="ql-align-center">Cuponul poate fi folosit o singură dată și nu se cumulează cu alte cupoane sau oferte.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const ChineseEmailData = {
  styles: {
    width: '600px',
    color: '#fff',
    backgroundColor: '#231f20',
    couponBorderColor: '#fff',
    buttonsDividersColor: '#fff',
    sicailLinksColor: '#fff',
  },
  components: [
    {
      type: 'image',
      image: 'em-logos/chinese-email-logo_1498080685909.png',
    },
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_4.+Chinese+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center" style="font-style:italic;">Rulouri de ouă gratuit!</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">sau înlocuiți rulourile de primăvară (2)</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Vedeți și ofertele noastre de băuturi și meniul actualizat.</p>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Sună înainte și fă o rezervare astăzi!</p>',
    },
    {
      type: 'coupon',
      text: '<h4 class="ql-align-center">RULOURI DE OUĂ GRATUIT</h4><p class="ql-align-center">sau înlocuiți rulourile de primăvară (2)</p><br><p class="ql-align-center" style="font-size: 10px;">Oferta limitată la un singur client. Nu poate fi combinată cu alte reduceri sau oferte.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const ClothingShopEmailData = {
  styles: {
    width: '600px',
    color: '#898989',
    backgroundColor: '#fff',
    couponBorderColor: '#00aeef',
    buttonsDividersColor: '#898989',
    sicailLinksColor: '#00aeef',
  },
  components: [
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_27.+Clothing+Store+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center">REDUCERE DE SFÂRȘIT DE VARĂ</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Vino la noi și inspiră-te. Săptămâna aceasta vă oferim 1+1 gratuit în întregul magazin.</p>',
    },
    {
      type: 'coupon',
      text: '<h2 class="ql-align-center">A nu se utiliza cu alte cupoane sau oferte.</h2>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const CoffeeEmailData = {
  styles: {
    width: '600px',
    backgroundColor: '#e6e7e8',
    color: '#000',
    headingsColor: '#000',
    buttonsDividersColor: '#000',
  },
  components: [
    {
      type: 'image',
      image: 'em-logos/top_1497255967016.png',
    },
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_5.+Coffee+Template.jpg',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<h3 class="ql-align-center">TREZIȚI-VĂ CU NOUA NOASTRĂ AROMĂ DE CAFEA PROASPĂT PRĂJITĂ!</h3>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Nu există o modalitate mai bună de a-ți începe ziua decât cu cafeaua noastră proaspăt prăjită. Conținutul bogat în aromă, vă va lăsa cu un zâmbet garantat. Vino să ne vizitezi astăzi și să încerci o mostră gratuită!</p>',
    },
    {
      type: 'coupon',
      text: '<h4 class="ql-align-center">ARTICOLE PROASPE DE BRUTARIE ZILNIC</h4><h3 class="ql-align-center">PRÂNZ SPECIAL LUNI - VINERI</h3><p class="ql-align-center">Variază în funcție de locația magazinului.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },

  ],
};

const DessertEmailData = {
  styles: {
    width: '600px',
    color: '#464646',
    backgroundColor: '#dfd',
    couponBorderColor: '#ff7d52',
    buttonsDividersColor: '#ff7d52',
    sicailLinksColor: '#464646',
  },
  components: [
    {
      type: 'image',
      image: 'em-logos/dessert-email-logo_1498080768821.png',
    },
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_6.+Dessert+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center" style="color:#ff7d52; font-style:italic;">NOUA OFERTĂ ESTE AICI!</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Primăvara a răsărit, la fel și noul nostru meniu!</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Vizitați-ne această săptămână pentru a primi o reducere de jumătate la orice articol nou din meniu.</span></p>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Veți găsi prăjituri roz, prăjituri cu cremă, batoane cu limonadă de căpșuni și multe altele.</p>',
    },
    {
      type: 'coupon',
      text: '<h4 class="ql-align-center">REDUCERE LA JUMĂTATE PENTRU ARTICOLE NOI DIN MENIU!</h4><p class="ql-align-center">(doar saptamana asta)</p><br><p class="ql-align-center" style="font-size: 10px;">Oferta nu poate fi combinată cu alte reduceri sau oferte.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const DinerEmailData = {
  styles: {
    width: '600px',
    color: '#f00',
    backgroundColor: '#fff',
    couponBorderColor: '#f00',
    buttonsDividersColor: '#f00',
    sicailLinksColor: '#f00',
  },
  components: [
    {
      type: 'image',
      image: 'em-logos/diner-email-logo_1498081690316.png',
    },
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_7.+Diner+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center">10% reducere la următoarea comandă</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Veniți pentru mâncare, rămâneți pentru atmosfera relaxantă! La Dave\'s Diner, serviciul zâmbitor este întotdeauna prioritatea noastră principală.</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'spacer',
    },
    {
      type: 'coupon',
      text: '<h2 class="ql-align-center">Reducere de 10% la următoarea comandă.</h2><p class="ql-align-center" style="font-size: 10px;">Oferta limitată la un singur client. Nu poate fi combinată cu alte reduceri sau oferte.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const FamilyDiningEmailData = {
  styles: {
    width: '600px',
    color: '#fff',
    backgroundColor: '#fc702e',
    couponBorderColor: '#fff',
    buttonsDividersColor: '#fff',
    sicailLinksColor: '#000',
  },
  components: [
    {
      type: 'image',
      image: 'em-logos/family-dining-email-2-logo_1498081751965.png',
    },
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_8.+Family+Dining+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center" style="font-style:italic;">MARȚI COPIII MANÂNCĂ GRATUIT!</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Cina este timpul în familie la Crosby\'s Kitchen. Vizitați-ne în ORICE marți, iar copiii mănâncă gratuit cu achiziționarea oricărui antreu pentru adulți.</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Vă garantăm că va fi o masă pe care atât dumneavoastră, cât și micuții dumneavoastră o veți îndrăgi.</p>',
    },
    {
      type: 'coupon',
      text: '<h4 class="ql-align-center">1 MENIU PENTRU COPII GRATUIT!</h4><p class="ql-align-center">(când cumpărați orice antreu pentru adulți)</p><br><p class="ql-align-center" style="font-size: 10px;">Oferta nu poate fi combinată cu alte reduceri sau oferte.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const FineDiningEmailData = {
  styles: {
    width: '600px',
    backgroundColor: '#f5f3ef',
    color: '#000',
    couponBorderColor: '#770e1f',
    buttonsDividersColor: '#770e1f',
    sicailLinksColor: '#000',
  },
  components: [
    {
      type: 'image',
      image: 'em-logos/fine-dining-email-logo_1498081793107.png',
    },
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_9.+Fine+Dining.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center" style="font-style:italic; color:#770e1f;">VĂ INVITĂM!</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Alăturați-vă nouă și obțineți experiența perfectă datorită combinației de arome oferite exclusiv fanilor noștri cei mai fideli.</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center" style="font-weight:bold;">Meniu degustare 350 lei</p>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">(include o masă cu 4 feluri de vin și combinație cu mâncare)</p>',
    },
    {
      type: 'coupon',
      text: '<h4 class="ql-align-center" style="font-weight:normal;">RĂSPUNDEȚI AICI pentru a fi alături de noi la acest eveniment special.</h4><p class="ql-align-center" style="font-style:italic;">Grăbește-te, numărul de locuri este limitat!</p><br><p class="ql-align-center" style="font-size: 10px;">Oferta nu poate fi combinată cu alte reduceri sau oferte.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const FloristsEmailData = {
  styles: {
    width: '600px',
    color: '#898989',
    backgroundColor: '#fff',
    couponBorderColor: '#00aeef',
    buttonsDividersColor: '#898989',
    sicailLinksColor: '#00aeef',
  },
  components: [
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_30.+Florists+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center">Vara e aici!</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Ne-am îmbrăcat magazinul într-o rochie de vară! Ca în fiecare sezon, acum ne-am amenajat localul astfel încât să puteți vedea și simți vara. Vino să te inspiri și să ia o bucată de vară cu tine.</p>',
    },
    {
      type: 'coupon',
      text: '<h2 class="ql-align-center">Anuale în ghivece doar săptămâna aceasta cu o reducere de 10%.</h2><p class="ql-align-center" style="font-size: 10px;">A nu se utiliza cu alte cupoane sau oferte.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const FruitEmailData = {
  styles: {
    width: '600px',
    color: '#f00',
    backgroundColor: '#fff',
    couponBorderColor: '#f00',
    buttonsDividersColor: '#f00',
    sicailLinksColor: '#f00',
  },
  components: [
    {
      type: 'image',
      image: 'em-logos/fruit-email-logo_1498081891611.png',
    },
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_10.+Fruit+Template_CZ.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center">10% reducere la prima livrare</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Fructele noastre sunt proaspete garantat! Aprovizionează-te cu gustări sănătoase pentru tine și prietenii tăi. Coșurile noastre cu fructe vor pune cu siguranță zâmbetul pe buze.</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'spacer',
    },
    {
      type: 'coupon',
      text: '<h2 class="ql-align-center">10% reducere la prima comandă</h2><p class="ql-align-center" style="font-size: 10px;">Oferta limitată la un singur client. Nu poate fi combinată cu alte reduceri sau oferte.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const ItalianEmailData = {
  styles: {
    width: '600px',
    color: '#000',
    backgroundColor: '#fcefd8',
    couponBorderColor: '#d82c1f',
    buttonsDividersColor: '#d82c1f',
    sicailLinksColor: '#000',
  },
  components: [
    {
      type: 'image',
      image: 'em-logos/italian-email-logo_1498081923428.png',
    },
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_11.+Italian+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center" style="font-style:italic; color:#d82c1f;">BUCURAȚI-VĂ DE 50 lei REDUCERE LA URMĂTOAREA VIZITĂ!</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">De la Penne Carbonara la faimosul Pomodoro Bolognese, cu siguranță vă veți bucura de o masă fantastică.</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Arată-ne acest e-mail și primești o reducere de 50 lei.</p>',
    },
    {
      type: 'coupon',
      text: '<h4 class="ql-align-center" style="color:#d82c1f;">50 lei REDUCERE LA URMĂTOAREA DVS. VIZITĂ</h4><br><p class="ql-align-center" style="font-size: 10px;">Oferta nu poate fi combinată cu alte reduceri sau oferte.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const MexicanEmailData = {
  styles: {
    width: '600px',
    color: '#fff',
    backgroundColor: '#efae2a',
    couponBorderColor: '#fff',
    buttonsDividersColor: '#fff',
    sicailLinksColor: '#fff',
  },
  components: [
    {
      type: 'image',
      image: 'em-logos/mexican-email-logo_1498082003319.png',
    },
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_12.+Mexican+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center">10% reducere la următoarea achiziție</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Meniul nostru autentic este garantat pentru a vă însenina ziua! Vino la noi și bucură-te de o băutură răcoroasă și de aperitive gustoase la prețuri imbatabile. Să înceapă fiesta!</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'coupon',
      text: '<h2 class="ql-align-center">10% reducere la următoarea comandă.</h2><p class="ql-align-center" style="font-size: 10px;">Oferta limitată la un singur client. Nu poate fi combinată cu alte reduceri sau oferte. </p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const MoveItSportsEmailData = {
  styles: {
    width: '600px',
    color: '#000',
    backgroundColor: '#fff',
    buttonsDividersColor: '#000',
    sicailLinksColor: '#0779be',
  },
  components: [
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<h1 class="ql-align-center" style="color:#0779be; font-style:italic;">O poți face!</h1>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Vedeți și ofertele noastre de băuturi și meniul actualizat.</p>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center" style="font-style:italic; font-weight:bold;">Sună înainte și fă o rezervare astăzi!</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_13.+Move+It+Sports+Template.jpg',
    },
  ],
};

const PetShopEmailData = {
  styles: {
    width: '600px',
    color: '#898989',
    backgroundColor: '#fff',
    couponBorderColor: '#00aeef',
    buttonsDividersColor: '#898989',
    sicailLinksColor: '#00aeef',
  },
  components: [
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_28.+Pet+Shop+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center">Întotdeauna o selecție mare!</h2>',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center">10% reducere la următoarea comandă</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Totul pentru animalele dvs. de companie cu sfaturi profesioniste.</p>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Puteți cumpăra de la noi exact ceea ce are nevoie animalul dvs. de companie.</p>',
    },
    {
      type: 'coupon',
      text: '<h2 class="ql-align-center">10% reducere la următoarea comandă</h2><p class="ql-align-center" style="font-size: 10px;">A nu se utiliza cu alte cupoane sau oferte.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const PizzaEmailData = {
  styles: {
    width: '600px',
    color: '#000',
    backgroundColor: '#fdd85c',
    couponBorderColor: '#eb6464',
    buttonsDividersColor: '#eb6464',
    sicailLinksColor: '#000',
  },
  components: [
    {
      type: 'image',
      image: 'em-logos/pizza-email-logo_1498082059029.png',
    },
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_14.+Pizza+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center" style="font-style:italic">1+1 GRATUIT!</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">De la ciuperci și pepperoni la măsline și hamsii, alegerea vă aparține. Comandați orice pizza cu două topping-uri și primiți una GRATUITĂ!</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Arată-ne acest e-mail pentru a primi această ofertă.</p>',
    },
    {
      type: 'coupon',
      text: '<h3 class="ql-align-center">1+1 GRATIUT!</h3><p class="ql-align-center">(de valoare egală sau mai mică)</p><br><p class="ql-align-center" style="font-size: 10px;">Oferta nu poate fi combinată cu alte reduceri sau oferte. </p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const QsrEmailData = {
  styles: {
    width: '600px',
    color: '#000',
    backgroundColor: '#fff',
    couponBorderColor: '#000',
    buttonsDividersColor: '#000',
    sicailLinksColor: '#000',
  },
  components: [
    {
      type: 'image',
      image: 'em-logos/burger_1505312326850_1505312462526.png',
    },
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_15.+QSR+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center" style="font-style:italic; color:#aa181c;">VINERI CARTOFI PRĂJIȚI GRATUIT</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Ne plac cartofii prăjiți. Îți plac cartofii prăjiți. Punem pariu că veți iubi și mai mult cartofii prăjiți.</p>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Comandați orice burger sau sandviș și obțineți gratuit cartofi prăjiți mari.</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Grăbește-te, oferta este valabilă doar în martie!</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'coupon',
      text: '<h3 class="ql-align-center" style="color:#aa181c;">CARTOI PRĂJIȚI MARI GRATUIT!</h3><p class="ql-align-center">(când cumpărați orice burger sau sandviș)</p><br><p class="ql-align-center" style="font-size: 10px;">Oferta nu poate fi combinată cu alte reduceri sau oferte.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const SushiEmailData = {
  styles: {
    width: '600px',
    color: '#fff',
    backgroundColor: '#231f20',
    couponBorderColor: '#fff',
    buttonsDividersColor: '#fff',
    sicailLinksColor: '#fff',
  },
  components: [
    {
      type: 'image',
      image: 'em-logos/sushi-email-logo_1498082220393.png',
    },
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_16.+Sushi+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center" style="font-style:italic;">PREȚURI LA JUMĂTATE IN HAPPY HOUR!</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Selecție Sushi și Aperitive de la 20 lei – 50 lei!</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Vedeți și ofertele noastre de băuturi și meniul actualizat.</p>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Sună înainte și fă o rezervare astăzi!</p>',
    },
    {
      type: 'coupon',
      text: '<h4 class="ql-align-center">CUMPĂRAȚI O ROLA, A DOUA ESTE LA  JUMĂTATE DE PREȚ</h4><p class="ql-align-center">(de valoare egală sau mai mică)</p><br><p class="ql-align-center" style="font-size: 10px;">Oferta limitată la un singur client. Nu poate fi combinată cu alte reduceri sau oferte. </p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const TimberlineEmailData = {
  styles: {
    width: '600px',
    color: '#000',
    backgroundColor: '#fff',
    sicailLinksColor: '#c58fbb',
    buttonsDividersColor: '#000',
  },
  components: [
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<h1 class="ql-align-center" style="font-style:italic;">REDUCERE DE SFÂRȘIT DE VARĂ</h1>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Soarele de vară apune, dar avem un ultim răsfăț pentru tine. Săptămâna aceasta vă oferim 1+1 gratuit în întregul magazin.</p>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center" style="font-style:italic; font-weight:bold;">Cumpărați la reducere!</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_17.+Timberline+Template.jpg',
    },
  ],
};

const BlankEmailData = {
  styles: {
    width: '600px',
    color: '#000',
    backgroundColor: '#fff',
    buttonsDividersColor: '#000',
    sicailLinksColor: '#000',
  },
  components: [
  ],
};

export const roStaticTemplates = [
  {
    id: 'jjxr3rb3qrp4stwr36hr',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateAutomotiveServiceShopTitle',
    status: 'origin',
    favorites: 'false',
    components: AutomotiveServiceShopEmailData.components,
    styles: AutomotiveServiceShopEmailData.styles,
    thumb: 'automotive-service-shop-email-preview-ro.jpg'
  },
  {
    id: 'd927g9njhtd6zr7bg1ns',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateBakeryTitle',
    status: 'origin',
    favorites: 'false',
    components: BakeryEmailData.components,
    styles: BakeryEmailData.styles,
    thumb: 'bakery-email-preview-ro.jpg',
  },
  {
    id: '6lwssxuwaab96enc0g5d',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateBarberShopTitle',
    status: 'origin',
    favorites: 'false',
    components: BarberShopEmailData.components,
    styles: BarberShopEmailData.styles,
    thumb: 'barber-shop-email-preview-ro.jpg',
  },
  {
    id: 'tf0zamc4wqiie7wk5eai',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateBarPubTitle',
    status: 'origin',
    favorites: 'false',
    components: BarPubEmailData.components,
    styles: BarPubEmailData.styles,
    thumb: 'bar-pub-email-preview-ro.jpg',
  },
  {
    id: 'j06za4kt2ef1e54akx1b',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateBeautyShopTitle',
    status: 'origin',
    favorites: 'false',
    components: BeautyShopEmailData.components,
    styles: BeautyShopEmailData.styles,
    thumb: 'beauty-shop-email-preview-ro.jpg',
  },
  {
    id: 'h96odai672adcig1d6dk',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateBurgerTitle',
    status: 'origin',
    favorites: 'false',
    components: BurgerEmailData.components,
    styles: BurgerEmailData.styles,
    thumb: 'burger-email-preview-ro.jpg',
  },
  {
    id: 'rfldafhvibur8jgcsain',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateChineseTitle',
    status: 'origin',
    favorites: 'false',
    components: ChineseEmailData.components,
    styles: ChineseEmailData.styles,
    thumb: 'chinese-email-preview-ro.jpg',
  },
  {
    id: 'wkc7hce8ewgwyo5kmicu',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateClothingShopTitle',
    status: 'origin',
    favorites: 'false',
    components: ClothingShopEmailData.components,
    styles: ClothingShopEmailData.styles,
    thumb: 'clothing-shop-email-preview-ro.jpg',
  },
  {
    id: 'wg0v5v542erwg9icd0af',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateCoffeeTitle',
    status: 'origin',
    favorites: 'false',
    components: CoffeeEmailData.components,
    styles: CoffeeEmailData.styles,
    thumb: 'coffee-email-preview-ro.jpg',
  },
  {
    id: 'kfh5ffum8snhi8ehucw0',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateDessertTitle',
    status: 'origin',
    favorites: 'false',
    components: DessertEmailData.components,
    styles: DessertEmailData.styles,
    thumb: 'dessert-email-preview-ro.jpg',
  },
  {
    id: 'po01x8bk3yua0cd1sapf',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateDinerTitle',
    status: 'origin',
    favorites: 'false',
    components: DinerEmailData.components,
    styles: DinerEmailData.styles,
    thumb: 'diner-email-preview-ro.jpg',
  },
  {
    id: 'ohpqydlqca18kugzvvez',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateFamilyDiningTitle',
    status: 'origin',
    favorites: 'false',
    components: FamilyDiningEmailData.components,
    styles: FamilyDiningEmailData.styles,
    thumb: 'family-dining-email-preview-ro.jpg',
  },
  {
    id: '2njgm0virawzya3dg7ce',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateFineDiningTitle',
    status: 'origin',
    favorites: 'false',
    components: FineDiningEmailData.components,
    styles: FineDiningEmailData.styles,
    thumb: 'fine-dining-email-preview-ro.jpg',
  },
  {
    id: 'mtegdz4xcw7d28psn5rx',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateFloristsTitle',
    status: 'origin',
    favorites: 'false',
    components: FloristsEmailData.components,
    styles: FloristsEmailData.styles,
    thumb: 'florists-email-preview-ro.jpg',
  },
  {
    id: 'bismcjc5nasjc75tjrds',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateFruitTitle',
    status: 'origin',
    favorites: 'false',
    components: FruitEmailData.components,
    styles: FruitEmailData.styles,
    thumb: 'fruit-email-preview-ro.jpg',
  },
  {
    id: 'g95lliw78mpt1py2rpc3',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateItalianTitle',
    status: 'origin',
    favorites: 'false',
    components: ItalianEmailData.components,
    styles: ItalianEmailData.styles,
    thumb: 'italian-email-preview-ro.jpg',
  },
  {
    id: 'h5388exfxf95ew48m1t2',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateMexicanTitle',
    status: 'origin',
    favorites: 'false',
    components: MexicanEmailData.components,
    styles: MexicanEmailData.styles,
    thumb: 'mexican-email-preview-ro.jpg',
  },
  {
    id: 'c7mhzklliqidawmtys08',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateMoveItSportsTitle',
    status: 'origin',
    favorites: 'false',
    components: MoveItSportsEmailData.components,
    styles: MoveItSportsEmailData.styles,
    thumb: 'move-it-sports-email-preview-ro.jpg',
  },
  {
    id: '65e5ame8g5kwsidgrglv',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplatePetShopTitle',
    status: 'origin',
    favorites: 'false',
    components: PetShopEmailData.components,
    styles: PetShopEmailData.styles,
    thumb: 'pet-shop-email-preview-ro.jpg',
  },
  {
    id: 'htfknt7iogqil9q2sgzj',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplatePizzaTitle',
    status: 'origin',
    favorites: 'false',
    components: PizzaEmailData.components,
    styles: PizzaEmailData.styles,
    thumb: 'pizza-email-preview-ro.jpg',
  },
  {
    id: '40oeibo7z6dp04z3y2hw',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateQSRTitle',
    status: 'origin',
    favorites: 'false',
    components: QsrEmailData.components,
    styles: QsrEmailData.styles,
    thumb: 'qsr-email-preview-ro.jpg',
  },
  {
    id: 'bdhdevls7luzc36zmrzz',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateSushiTitle',
    status: 'origin',
    favorites: 'false',
    components: SushiEmailData.components,
    styles: SushiEmailData.styles,
    thumb: 'sushi-email-preview-ro.jpg',
  },
  {
    id: 'ltyrjj4d4r3s8egsavbf',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateTimberlineTitle',
    status: 'origin',
    favorites: 'false',
    components: TimberlineEmailData.components,
    styles: TimberlineEmailData.styles,
    thumb: 'timberline-email-preview-ro.jpg',
  },
  {
    id: 'yrbbdw9gwm8g4kxi7et5',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateBlankTitle',
    status: 'origin',
    favorites: 'false',
    components: BlankEmailData.components,
    styles: BlankEmailData.styles,
    thumb: 'blank-ro.png',
  },
];
