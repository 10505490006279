import React from 'react';
import styled from 'styled-components';

// Default offset including left and right paddings to the text in inputs
const DEFAULT_OFFSET = 24;
// Symbol width to calculate position of the label
const SYMBOL_WIDTH = 6;

interface InputDecoratorProps {
  className?: string;
  isBeforeShown: boolean;
  isAfterShown: boolean;
  beforeContent?: string | number;
  afterContent?: string | number;
  afterOffset?: number;
  id: string;
  width?: number;
  children: string | React.ReactElement | React.ReactElement[];
  dataTestId?: string;
}

const Label = styled.label`
  display: inline-block;
  touch-action: manipulation;
  position: absolute;
  top: 10px;
  left: 8px;
`;

const InputDecoratorComponent = (props: InputDecoratorProps) => {
  const {
    id,
    className,
    children,
    isBeforeShown,
    isAfterShown,
    beforeContent = null,
    afterContent = null,
    afterOffset = 2,
    width = 200,
    dataTestId
  } = props;

  const countAfterOffset = (symbolsCount: number) => symbolsCount * SYMBOL_WIDTH + DEFAULT_OFFSET;

  return (
    <div className={className} style={{ width }} data-test-id={dataTestId}>
      {isBeforeShown && <Label htmlFor={id}>{beforeContent}</Label>}
      {children}
      {isAfterShown && (
        <Label htmlFor={id} style={{ left: countAfterOffset(afterOffset) }}>
          {afterContent}
        </Label>
      )}
    </div>
  );
};

export const InputDecorator = styled(InputDecoratorComponent)`
  position: relative;
  overflow: hidden;

  input {
    ${props =>
      props.isBeforeShown && props.beforeContent
        ? `padding-left: ${(props.beforeContent.toString().length + 1) * 10}px !important;`
        : ''};
  }
`;
