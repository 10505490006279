import { put, select } from '@redux-saga/core/effects';
import { CONTACTS_PER_PAGE } from 'appConstants/emailMarketing';
import { getBouncedContacts } from '../selectors';
import { ApiGateway } from '../../../../services/apiGateway';
import { bouncedContactsActions } from './actions';
import { fetchContacts } from '../../utils';

export function* fetchBouncedContacts({ payload }: any) {
  const state = getBouncedContacts(yield select());
  yield fetchContacts(payload, state, ApiGateway.fetchBounced, {
    success: bouncedContactsActions.fetchContacts.success,
    failure: bouncedContactsActions.fetchContacts.failure,
    setList: bouncedContactsActions.setContactsList
  });
}

export function* setBouncedSortFieldAndOrder({
  payload
}: ReturnType<typeof bouncedContactsActions.setBouncedSortFieldAndOrder>) {
  const state = yield select();
  const contacts = getBouncedContacts(state);
  yield put(
    bouncedContactsActions.fetchContacts.request({
      page: contacts.page,
      limit: contacts.limit || CONTACTS_PER_PAGE,
      keyword: contacts.keyword,
      sort: payload
    })
  );
}
