import { createReducer } from 'typesafe-actions';
import { declineCodes } from 'utils/declineCodes';
import { DeclineTrendsState } from './types';
import { declineTrendsActions, DeclineTrendsActions } from './actions';

const initialState: DeclineTrendsState = {
  filter: declineCodes.map(el => `${el}`).slice(0, 5)
};

export const declineTrendsReducer = createReducer<DeclineTrendsState, DeclineTrendsActions>(initialState).handleAction(
  declineTrendsActions.setFilter,
  (state, { payload }): DeclineTrendsState => ({ ...state, filter: payload })
);
