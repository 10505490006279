import React, { ChangeEvent, useState, KeyboardEvent, useEffect, ReactElement } from 'react';
import ContainerDimensions from 'react-container-dimensions';
import styled from 'styled-components';
import { DEFAULT_NS } from 'appConstants/translationNamespaces';
import { useTranslation } from 'react-i18next';
import { DropDown, DropDownDirection } from './DropDown';
import { Button } from './Button';
import { TextInput } from './TextInput';
import { formatString } from '../services/utils';
import { getColor } from '../layout/theme';
import { deviceMedia, tabletMinWidth } from '../appConstants';
import { textRegular } from '../layout/mixins';

const MIN_CONTAINER_SIZE = 600;

interface ThemedStyledProps {
  allowedMultiline?: boolean;
}

const isMultilinePaginationAllowed = (
  containerSize?: number,
  showPerPageCtrl?: boolean,
  hideRecords?: boolean
): boolean =>
  !!(
    !!showPerPageCtrl &&
    !hideRecords &&
    typeof containerSize === 'number' &&
    containerSize < MIN_CONTAINER_SIZE &&
    window.innerWidth > tabletMinWidth
  );

const PaginationWrapper = styled.div<ThemedStyledProps>`
  ${props => `
    ${textRegular};
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    ${props.allowedMultiline ? 'flex-wrap: wrap;' : ''};

    @media ${deviceMedia.mobile} {
      flex-wrap: wrap;
      & > div {
        flex: 1 1 100%;
        max-width: 100%;
        margin-bottom: 15px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  `};
`;

const TextInputWrapper = styled.div`
  display: inline-block;
  min-width: 50px;
  width: 50px;
  input {
    text-align: center;
    font-weight: inherit;
    padding-left: 3px;
    padding-right: 3px;
    &:disabled {
      opacity: 0.7;
      font-weight: normal;
    }
  }
`;

const PerPageContainer = styled.div<ThemedStyledProps>`
  display: flex;
  align-items: inherit;
  ${props => (props.allowedMultiline ? 'order: 2;' : '')};
  > * {
    margin: 0 8px;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  @media ${deviceMedia.mobile} {
    flex-direction: column;
    align-items: flex-start;
    > * {
      margin: 0 0 8px 0;
    }
  }
`;
PerPageContainer.displayName = 'PerPageContainer';

const ControlsContainer = styled.div<ThemedStyledProps>`
  display: flex;
  align-items: inherit;
  font-weight: bold;
  flex-wrap: nowrap;
  white-space: nowrap;
  ${props =>
    props.allowedMultiline
      ? `
      order: 1;
      margin-bottom: 15px;
      // justify-content: fle;
    `
      : ''};
  > * {
    margin: 0 5px;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  @media ${deviceMedia.mobile} {
    justify-content: space-between;
  }
`;

export const PrevNextBtn = styled(Button)`
  padding-left: 12px;
  padding-right: 12px;
  min-width: 0;
  color: ${props => getColor(props, 'color1')};
  text-shadow: 0 0 1px;
  font-weight: normal;
  &:disabled {
    background: transparent;
    color: ${props => getColor(props, 'darkGrey')};
    text-shadow: none;
    opacity: 0.7;
  }
`;
PrevNextBtn.displayName = 'PrevNextBtn';

export interface PaginationProps {
  selectedPage: number;
  onPageSelect: (page: number) => void;
  pagesCount?: number;
  itemsCount: number;

  availablePageSizes?: number[];
  showPerPageCtrl?: boolean;
  onPageSizeChange?: (value: number, page: number) => void;
  pageSize?: number;
  manualDirection?: DropDownDirection;
  hideRecords?: boolean;
}

export const Pagination = (props: PaginationProps): ReactElement => {
  const {
    availablePageSizes,
    showPerPageCtrl = true,
    selectedPage,
    onPageSelect,
    onPageSizeChange,
    pageSize = 0,
    itemsCount,
    manualDirection,
    hideRecords
  } = props;

  const [inputValue, setInputValue] = useState<number | string>(selectedPage + 1);

  const { t } = useTranslation(DEFAULT_NS);

  useEffect(() => {
    setInputValue(selectedPage + 1);
  }, [selectedPage]);

  const pagesCount = Math.ceil(itemsCount / pageSize);

  const dropDownItems = (
    Array.isArray(availablePageSizes) && availablePageSizes.length ? availablePageSizes : [10, 25, 50, 75, 100]
  ).map(el => ({
    value: el,
    label: formatString(t('paginationPerPageItem', 'Show {0} per page'), el).join(' ')
  }));

  const inputBlurHandler = (): void => {
    const nextValue = selectedPage + 1;
    if (nextValue !== inputValue) setInputValue(nextValue);
  };

  const inputKeyDownHandler = (event: KeyboardEvent<HTMLInputElement>): void => {
    const { keyCode, target } = event;
    if (keyCode === 13 || keyCode === 9) {
      event.preventDefault();
      const value = Number(target ? (target as HTMLInputElement).value : 0);
      if (value > 0 && value <= pagesCount) {
        onPageSelect(value - 1);
      }
      setTimeout(() => {
        (target as HTMLInputElement).blur();
      }, 100);
    }
  };

  const inputChangeHandler = (inp: ChangeEvent<HTMLInputElement>): void => {
    const { value } = inp.target;
    const valueNumber = Number(value);
    if (typeof valueNumber === 'number' && valueNumber <= pagesCount) {
      setInputValue(valueNumber === 0 ? '' : valueNumber);
    }
  };

  const canPrev = selectedPage - 1 >= 0;
  const canNext = selectedPage + 1 < pagesCount;
  const firstItem = selectedPage * pageSize + 1;
  let lastItem = (selectedPage + 1) * pageSize;
  if (lastItem > itemsCount) lastItem = itemsCount;

  return (
    <ContainerDimensions>
      {({ width }) => (
        <PaginationWrapper
          data-test-id="pagination"
          allowedMultiline={isMultilinePaginationAllowed(width, showPerPageCtrl, hideRecords)}
        >
          {showPerPageCtrl && (
            <PerPageContainer allowedMultiline={isMultilinePaginationAllowed(width, showPerPageCtrl, hideRecords)}>
              <DropDown
                manualDirection={manualDirection}
                width="160px"
                onChange={value => {
                  if (typeof onPageSizeChange === 'function') onPageSizeChange(Number(value), selectedPage);
                }}
                items={dropDownItems}
                selectedValue={pageSize}
                data-test-id="dropdown"
              />

              {!hideRecords && (
                // eslint-disable-next-line react/no-danger
                <div
                  dangerouslySetInnerHTML={{
                    __html: formatString(
                      t('paginationShowingFromToOf', 'Showing records <b>{0}-{1}</b> of <b>{2}</b>'),
                      firstItem,
                      lastItem,
                      itemsCount
                    ).join(' ')
                  }}
                />
              )}
            </PerPageContainer>
          )}

          <ControlsContainer
            data-test-id="pagination-controls"
            allowedMultiline={isMultilinePaginationAllowed(width, showPerPageCtrl, hideRecords)}
          >
            <PrevNextBtn
              disabled={!canPrev}
              secondary
              onClick={() => {
                if (canPrev) {
                  onPageSelect(selectedPage - 1);
                }
              }}
              data-test-id="prev-btn"
            >
              {t('paginationPrev', 'Prev')}
            </PrevNextBtn>
            {t('paginationRightStatusPage', 'Page')}
            <TextInputWrapper data-test-id="text-input">
              <TextInput
                disabled={!canNext && !canPrev}
                value={inputValue}
                onChange={inputChangeHandler}
                onKeyDown={inputKeyDownHandler}
                onBlur={inputBlurHandler}
              />
            </TextInputWrapper>
            {t('paginationRightStatusPrepositionOf', 'of')} {pagesCount}
            <PrevNextBtn
              disabled={!canNext}
              onClick={() => {
                if (canNext) {
                  onPageSelect(selectedPage + 1);
                }
              }}
              secondary
              data-test-id="next-btn"
            >
              {t('paginationNext', 'Next')}
            </PrevNextBtn>
          </ControlsContainer>
        </PaginationWrapper>
      )}
    </ContainerDimensions>
  );
};
