import { ActionType, createReducer } from 'typesafe-actions';
import { DEFAULT_SORT_ORDER, CONTACTS_PER_PAGE } from 'appConstants/emailMarketing';
import { bouncedContactsActions } from './actions';
import * as types from '../types';

const defaultState = {
  limit: CONTACTS_PER_PAGE,
  page: 0,
  keyword: '',
  totalCount: 0,
  overallCount: 0,
  sort: DEFAULT_SORT_ORDER,
  data: []
};

type Store = types.ContactsList;
type Actions = ActionType<typeof bouncedContactsActions>;

export const bouncedContactsReducer = createReducer<Store, Actions>(defaultState)
  .handleAction(bouncedContactsActions.setContactsList, (state, action) => ({
    ...state,
    data: action.payload.data
  }))
  .handleAction(bouncedContactsActions.fetchContacts.request, state => ({ ...state, loading: true, fetched: true }))
  .handleAction(bouncedContactsActions.fetchContacts.success, (state, action) => ({
    ...state,
    ...action.payload,
    loading: false
  }))
  .handleAction(bouncedContactsActions.fetchContacts.failure, state => ({ ...state, loading: false }))
  .handleAction(bouncedContactsActions.setBouncedSortFieldAndOrder, (state, action) => ({
    ...state,
    sort: action.payload
  }));
