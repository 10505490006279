import { ActionType, createReducer } from 'typesafe-actions';
import { ApiGatewayState, ErrorTypes } from './types';
import { apiGatewayActions } from './actions';
import { unauthorizedCodes } from './index';

const apiGatewayDefaultState = {
  error: {
    message: '',
    status: undefined,
    type: ErrorTypes.unknown
  },
  authorized: false
};

export const apiGatewayReducer = createReducer<ApiGatewayState, ActionType<typeof apiGatewayActions>>(
  apiGatewayDefaultState
).handleAction(
  apiGatewayActions.requestError,
  (
    state,
    {
      payload: {
        response: { isSessionExpired, error, type, userFriendlyError },
        status
      }
    }
  ) => ({
    ...state,
    error: {
      isSessionExpired,
      message: userFriendlyError || error || 'Unknown error occurred',
      type: type ?? ErrorTypes.unknown,
      status
    },
    authorized: unauthorizedCodes.includes(status) ? false : state.authorized
  })
);
