import { ActionType, createReducer } from 'typesafe-actions';
import { RefundsChargebacksState } from './types';
import { refundsChargebacksActions } from './actions';

const initialState: RefundsChargebacksState = {
  summary: {
    data: null,
    pending: false
  },
  cards: {
    data: null,
    pending: false
  },
  transactionsRefunded: {
    data: null,
    pending: false
  },
  transactionsRefundRates: {
    data: null,
    pending: false
  },
  transactionsChargebackRates: {
    data: null,
    pending: false
  }
};

export const refundsChargebackReducer = createReducer<
  RefundsChargebacksState,
  ActionType<typeof refundsChargebacksActions>
>(initialState)
  .handleAction(
    refundsChargebacksActions.fetchSummary.request,
    (state): RefundsChargebacksState => ({
      ...state,
      summary: {
        ...state.summary,
        pending: true,
        error: undefined
      }
    })
  )
  .handleAction(
    refundsChargebacksActions.fetchSummary.success,
    (state, { payload }): RefundsChargebacksState => ({
      ...state,
      summary: {
        ...state.summary,
        data: payload,
        pending: false,
        error: undefined
      }
    })
  )
  .handleAction(
    refundsChargebacksActions.fetchSummary.failure,
    (state, { payload }): RefundsChargebacksState => ({
      ...state,
      summary: {
        ...state.summary,
        data: null,
        pending: false,
        error: payload
      }
    })
  )
  .handleAction(
    refundsChargebacksActions.fetchCards.request,
    (state): RefundsChargebacksState => ({
      ...state,
      cards: {
        ...state.cards,
        pending: true,
        error: undefined
      }
    })
  )
  .handleAction(
    refundsChargebacksActions.fetchCards.success,
    (state, { payload }): RefundsChargebacksState => ({
      ...state,
      cards: {
        ...state.cards,
        data: payload,
        pending: false,
        error: undefined
      }
    })
  )
  .handleAction(
    refundsChargebacksActions.fetchCards.failure,
    (state, { payload }): RefundsChargebacksState => ({
      ...state,
      cards: {
        ...state.cards,
        data: null,
        pending: false,
        error: payload
      }
    })
  )
  .handleAction(
    refundsChargebacksActions.fetchTransactionsRefunded.request,
    (state): RefundsChargebacksState => ({
      ...state,
      transactionsRefunded: {
        ...state.transactionsRefunded,
        pending: true,
        error: undefined
      }
    })
  )
  .handleAction(
    refundsChargebacksActions.fetchTransactionsRefunded.success,
    (state, { payload }): RefundsChargebacksState => ({
      ...state,
      transactionsRefunded: {
        ...state.transactionsRefunded,
        data: payload,
        pending: false,
        error: undefined
      }
    })
  )
  .handleAction(
    refundsChargebacksActions.fetchTransactionsRefunded.failure,
    (state, { payload }): RefundsChargebacksState => ({
      ...state,
      transactionsRefunded: {
        ...state.transactionsRefunded,
        data: null,
        pending: false,
        error: payload
      }
    })
  )
  .handleAction(
    refundsChargebacksActions.fetchTransactionsRefundRates.request,
    (state): RefundsChargebacksState => ({
      ...state,
      transactionsRefundRates: {
        ...state.transactionsRefundRates,
        pending: true,
        error: undefined
      }
    })
  )
  .handleAction(
    refundsChargebacksActions.fetchTransactionsRefundRates.success,
    (state, { payload }): RefundsChargebacksState => ({
      ...state,
      transactionsRefundRates: {
        ...state.transactionsRefundRates,
        data: payload,
        pending: false,
        error: undefined
      }
    })
  )
  .handleAction(
    refundsChargebacksActions.fetchTransactionsRefundRates.failure,
    (state, { payload }): RefundsChargebacksState => ({
      ...state,
      transactionsRefundRates: {
        ...state.transactionsRefundRates,
        data: null,
        pending: false,
        error: payload
      }
    })
  )
  .handleAction(
    refundsChargebacksActions.fetchTransactionsChargebackRates.request,
    (state): RefundsChargebacksState => ({
      ...state,
      transactionsChargebackRates: {
        ...state.transactionsChargebackRates,
        pending: true,
        error: undefined
      }
    })
  )
  .handleAction(
    refundsChargebacksActions.fetchTransactionsChargebackRates.success,
    (state, { payload }): RefundsChargebacksState => ({
      ...state,
      transactionsChargebackRates: {
        ...state.transactionsChargebackRates,
        data: payload,
        pending: false,
        error: undefined
      }
    })
  )
  .handleAction(
    refundsChargebacksActions.fetchTransactionsChargebackRates.failure,
    (state, { payload }): RefundsChargebacksState => ({
      ...state,
      transactionsChargebackRates: {
        ...state.transactionsChargebackRates,
        data: null,
        pending: false,
        error: payload
      }
    })
  );
