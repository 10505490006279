import { ActionType, createReducer } from 'typesafe-actions';
import { rfmActions } from './actions';
import * as types from './types';

const defaultState: types.RFMState = {
  data: {
    tier1: {
      data: [],
      lifetimeValue: 0
    },
    tier2: {
      data: [],
      lifetimeValue: 0
    },
    tier3: {
      data: [],
      lifetimeValue: 0
    },
    tier4: {
      data: [],
      lifetimeValue: 0
    },
    tier5: {
      data: [],
      lifetimeValue: 0
    }
  },
  allTiersAverage: {
    recency: 0,
    frequency: 0,
    monetary: 0
  },
  tiersAverage: {
    tier1: { recency: 0, frequency: 0, monetary: 0 },
    tier2: { recency: 0, frequency: 0, monetary: 0 },
    tier3: { recency: 0, frequency: 0, monetary: 0 },
    tier4: { recency: 0, frequency: 0, monetary: 0 },
    tier5: { recency: 0, frequency: 0, monetary: 0 }
  },
  lineChartData: [],
  range: 30,
  loading: false
};

const { fetchData, setRange } = rfmActions;

export const rfmReducer = createReducer<types.RFMState, ActionType<typeof rfmActions>>(defaultState)
  .handleAction(fetchData.request, state => ({ ...state, loading: true }))
  .handleAction(fetchData.success, (state, action) => ({
    ...state,
    data: action.payload.data,
    lineChartData: action.payload.lineChartData,
    allTiersAverage: action.payload.allTiersAverage,
    tiersAverage: action.payload.tiersAverage,
    loading: false
  }))
  .handleAction(fetchData.failure, state => ({ ...state, loading: false }))
  .handleAction(setRange, (state, action) => ({ ...state, range: action.payload }));
