import { createReducer } from 'typesafe-actions';
import { errorsActions, ErrorsActions } from './actions';
import { ErrorsState } from './types';

const initialState: ErrorsState = {
  apiError: {
    isDisplayed: false,
    path: null
  }
};

export const errorsReducer = createReducer<ErrorsState, ErrorsActions>(initialState)
  .handleAction(errorsActions.showApiError, (state, { payload: { path, message } }) => ({
    ...state,
    apiError: { path, isDisplayed: true, message }
  }))
  .handleAction(errorsActions.hideApiError, state => ({
    ...state,
    apiError: { path: null, isDisplayed: false }
  }));
