import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { getColor } from '../layout/theme';

const generateElsForSpinner = () => {
  const res = [];
  for (let i = 0; i < 12; i += 1) {
    res.push(`&:nth-child(${i + 1}){ transform: rotate(${i * 30}deg); animation-delay: -${1.1 - 0.1 * i}s;}`);
  }
  return res.join('');
};

interface SpinnerProps {
  size?: number;
}
const getSize = (props: SpinnerProps) => props.size || 50;

const SpinnerContainer = styled.div<SpinnerProps>`
  @keyframes lds-spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  display: inline-block;
  position: relative;
  width: ${props => getSize(props)}px;
  height: ${props => getSize(props)}px;
  div {
    transform-origin: ${props => getSize(props) / 2}px ${props => getSize(props) / 2}px;
    animation: lds-spinner 1.2s linear infinite;
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 1px;
      left: ${props => getSize(props) / 2 - 2}px;
      width: ${props => getSize(props) / 12}px;
      height: ${props => getSize(props) / 4}px;
      border-radius: 20%;
      background: ${props => getColor(props, 'mediumGrey')};
    }
    ${generateElsForSpinner()};
  }
`;

const Spinner = (props: SpinnerProps) => (
  <SpinnerContainer {...props}>
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
  </SpinnerContainer>
);

interface LoaderProps {
  loading: boolean;
  size?: number;
  background?: string;
  children?: ReactElement;
}

const Loader = styled.div<{ isVisible: boolean; background: string } & SpinnerProps>`
  display: ${props => (props.isVisible ? 'flex' : 'none')};
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: ${props => props.background};
  z-index: 98;
  & > * {
    margin: 10px 0;
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export default (props: LoaderProps): ReactElement => {
  const { loading, background = 'rgba(256, 256, 256, .7)', children } = props;

  return (
    <Loader isVisible={loading} background={background} data-test-id="loader" data-visible={loading}>
      {children}
      <Spinner size={props.size} />
    </Loader>
  );
};
