/* eslint-disable */
/* en_US Static Templates Data */

const MoveItSportsEmailData = {
  styles: {
    width: '600px',
    color: '#000',
    backgroundColor: '#fff',
    buttonsDividersColor: '#000',
    sicailLinksColor: '#0779be'
  },
  components: [
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/'
      }
    },
    {
      type: 'spacer'
    },
    {
      type: 'text',
      text: '<h1 class="ql-align-center" style="color:#0779be; font-style:italic;">You\'re going places</h1>'
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Check out our drink specials and updated menu as well.</p>'
    },
    {
      type: 'text',
      text: '<p class="ql-align-center" style="font-style:italic; font-weight:bold;">Call ahead and make a reservation today!</p>'
    },
    {
      type: 'spacer'
    },
    {
      type: 'image',
      image: '472/retail-4-banner_1499444248387.png'
    }
  ]
};

const TimberlineEmailData = {
  styles: {
    width: '600px',
    color: '#000',
    backgroundColor: '#fff',
    sicailLinksColor: '#c58fbb',
    buttonsDividersColor: '#000'
  },
  components: [
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/'
      }
    },
    {
      type: 'spacer'
    },
    {
      type: 'text',
      text: '<h1 class="ql-align-center" style="font-style:italic;">END OF SUMMER SALE</h1>'
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">The sun is setting on summer but we\'ve got one last treat in store for you. This week we\'re offering buy one get one free on the entire store.</p>'
    },
    {
      type: 'text',
      text: '<p class="ql-align-center" style="font-style:italic; font-weight:bold;">Shop the sale!</p>'
    },
    {
      type: 'spacer'
    },
    {
      type: 'image',
      image: '472/retail-7-banner_1499444446528.png'
    }
  ]
};

const BurgerEmailData = {
  styles: {
    width: '600px',
    backgroundColor: '#e11b22',
    color: '#fff',
    headingsColor: '#fff',
    buttonsDividersColor: '#fff'
  },
  components: [
    {
      type: 'image',
      image: '1/top_1495181637059.png'
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">BUY ONE, GET ONE FREE. ANY COMBO MEAL.</p>'
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Our seasoned, 100% all-American beef patty, complete with fresh veggies and smoked bacon is here to stay! Pair your order with a cold craft brew and start your week with a smile!</p>'
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Show your server this coupon below, or print</p>'
    },
    {
      type: 'coupon',
      text: '<h2 class="ql-align-center">BUY ONE COMBO MEAL, GET ONE FREE</h2><p class="ql-align-center">(of equal or lesser value)</p><p class="ql-align-center">limit one per customer. Not to be used with any other coupons or offers. Void where prohibited. Sales tax applicable. No cash value</p>'
    },
    {
      type: 'divider'
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/'
      }
    }
  ]
};

const CoffeeEmailData = {
  styles: {
    width: '600px',
    backgroundColor: '#e6e7e8',
    color: '#000',
    headingsColor: '#000',
    buttonsDividersColor: '#000'
  },
  components: [
    {
      type: 'image',
      image: '483/top_1497255967016.png'
    },
    {
      type: 'image',
      image: '483/img1_1497255926883.png'
    },
    {
      type: 'spacer'
    },
    {
      type: 'text',
      text: '<h3 class="ql-align-center">WAKE UP TO OUR NEW FRESH ROAST FLAVOR!</h3>'
    },
    {
      type: 'spacer'
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">There\'s no better way to start your day than with our newest fresh roast coffee. Full-bodied, and rich in flavor, it\'s guaranteed to leave you with a smile. Come visit us today and try a free taste sample!</p>'
    },
    {
      type: 'coupon',
      text: '<h4 class="ql-align-center">FRESH BAKERY ITEMS DAILY</h4><h3 class="ql-align-center">LUNCH SPECIALS MONDAY - FRIDAY</h3><p class="ql-align-center">Varies by store location.</p>'
    },
    {
      type: 'divider'
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/'
      }
    }
  ]
};

const BakeryEmailData = {
  styles: {
    width: '600px',
    color: '#898989',
    backgroundColor: '#fff',
    couponBorderColor: '#00aeef',
    buttonsDividersColor: '#898989',
    sicailLinksColor: '#00aeef'
  },
  components: [
    {
      type: 'image',
      image: '472/bakery-email-logo_1498080292703.png'
    },
    {
      type: 'image',
      image: '472/bakery-email-banner_1498080122050.png'
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center">10% Off Your Next Purchase</h2>'
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">We just wanted to send you a little gift to say thank you. Please feel free to use this coupon on your next visit to Bob\'s Bakery, where everything is baked on-site, from scratch, using only the freshest ingredients. Come in and grab an order to go or just sit down to enjoy any of our numerous, freshly baked delicious cakes and pies. We look forward to seeing you soon!</p>'
    },
    {
      type: 'coupon',
      text: '<h2 class="ql-align-center">10% Off Your Next Purchase</h2><p class="ql-align-center" style="font-size: 10px;">Limit one per customer. Not to be used with any other coupons or offers. Void where prohibited. Sales tax applicable. No cash value.</p>'
    },
    {
      type: 'divider'
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/'
      }
    }
  ]
};

const BarPubEmailData = {
  styles: {
    width: '600px',
    color: '#fff',
    backgroundColor: '#898989',
    couponBorderColor: '#fff',
    buttonsDividersColor: '#fff',
    sicailLinksColor: '#fff'
  },
  components: [
    {
      type: 'image',
      image: '472/bar-pub-email-logo_1498080596725.png'
    },
    {
      type: 'image',
      image: '472/bar-pub-email-banner_1498080542760.png'
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center">HAPPY HOUR EVERY DAY</h2>'
    },
    {
      type: 'text',
      text: '<p class="ql-align-center" style="font-weight: bold;">Daily from 4pm to 7pm</p>'
    },
    {
      type: 'spacer'
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Our tap list rotates weekly.</p>'
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Come on in and see what we have to offer today!</p>'
    },
    {
      type: 'spacer'
    },
    {
      type: 'coupon',
      text: '<h4 class="ql-align-center">$1 Off Happy Hour Item</h4><p class="ql-align-center">limit of one item</p><br><p class="ql-align-center" style="font-size: 10px;">Limit one per customer. Not to be used with any other coupons or offers. Void where prohibited. Sales tax applicable. No cash value.</p><br>'
    },
    {
      type: 'divider'
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/'
      }
    }
  ]
};

const ChineseEmailData = {
  styles: {
    width: '600px',
    color: '#fff',
    backgroundColor: '#231f20',
    couponBorderColor: '#fff',
    buttonsDividersColor: '#fff',
    sicailLinksColor: '#fff'
  },
  components: [
    {
      type: 'image',
      image: '472/chinese-email-logo_1498080685909.png'
    },
    {
      type: 'image',
      image: '472/chinese-email-banner_1498080652174.png'
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center" style="font-style:italic;">FREE ORDER OF EGG ROLLS!</h2>'
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">or substitute for Spring Rolls (2)</p>'
    },
    {
      type: 'spacer'
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Check out our drink specials and updated menu as well.</p>'
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Call ahead and make a reservation today!</p>'
    },
    {
      type: 'coupon',
      text: '<h4 class="ql-align-center">FREE ORDER OF EGG ROLLS</h4><p class="ql-align-center">or substitute for Spring Rolls (2)</p><br><p class="ql-align-center" style="font-size: 10px;">Limit one per customer. Not to be used with any other coupons or offers. Void where prohibited. Sales tax applicable. No cash value.</p>'
    },
    {
      type: 'divider'
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/'
      }
    }
  ]
};

const DessertEmailData = {
  styles: {
    width: '600px',
    color: '#464646',
    backgroundColor: '#dfd',
    couponBorderColor: '#ff7d52',
    buttonsDividersColor: '#ff7d52',
    sicailLinksColor: '#464646'
  },
  components: [
    {
      type: 'image',
      image: '472/dessert-email-logo_1498080768821.png'
    },
    {
      type: 'image',
      image: '472/dessert-email-banner_1498080741499.png'
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center" style="color:#ff7d52; font-style:italic;">NEW MENU ITEMS ARE HERE!</h2>'
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Spring has sprung, and so has our new menu!</p>'
    },
    {
      type: 'spacer'
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Stop by anytime this week to <span style="font-weight: bold;">receive half off any new menu item.</span></p>'
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">You\'ll find pretty pink macaroons, cream cheese carrot cake pops, strawberry lemonade bars, and more.</p>'
    },
    {
      type: 'coupon',
      text: '<h4 class="ql-align-center">HALF OFF NEW MENU ITEMS!</h4><p class="ql-align-center">(This week only)</p><br><p class="ql-align-center" style="font-size: 10px;">Not to be used with any other coupons or offers. Void where prohibited. Sales tax applicable. No cash value.</p>'
    },
    {
      type: 'divider'
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/'
      }
    }
  ]
};

const DinerEmailData = {
  styles: {
    width: '600px',
    color: '#f00',
    backgroundColor: '#fff',
    couponBorderColor: '#f00',
    buttonsDividersColor: '#f00',
    sicailLinksColor: '#f00'
  },
  components: [
    {
      type: 'image',
      image: '472/diner-email-logo_1498081690316.png'
    },
    {
      type: 'image',
      image: '472/diner-email-banner_1498081660090.png'
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center">10% Off Your Next Purchase</h2>'
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Come for the food, stay for the relaxing atmosphere! At Dave\'s Diner, we always make service with a smile our top priority.</p>'
    },
    {
      type: 'spacer'
    },
    {
      type: 'spacer'
    },
    {
      type: 'coupon',
      text: '<h2 class="ql-align-center">10% Off Your Next Purchase</h2><p class="ql-align-center" style="font-size: 10px;">Limit one per customer. Not to be used with any other coupons or offers. Void where prohibited. Sales tax applicable. No cash value.</p>'
    },
    {
      type: 'divider'
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/'
      }
    }
  ]
};

const FamilyDiningEmailData = {
  styles: {
    width: '600px',
    color: '#fff',
    backgroundColor: '#fc702e',
    couponBorderColor: '#fff',
    buttonsDividersColor: '#fff',
    sicailLinksColor: '#000'
  },
  components: [
    {
      type: 'image',
      image: '472/family-dining-email-2-logo_1498081751965.png'
    },
    {
      type: 'image',
      image:
        '472/family-dining-email-2-banner_1498081725166.png'
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center" style="font-style:italic;">TUESDAYS KIDS EAT FREE!</h2>'
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Dinnertime is family time at Crosby\'s Kitchen. Come in <span style="font-weight: bold; font-style: italic;">ANY</span> Tuesday, and kids eat free with the purchase of any adult entree.</p>'
    },
    {
      type: 'spacer'
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">We guarantee it will be a meal that both you and your little ones will love.</p>'
    },
    {
      type: 'coupon',
      text: '<h4 class="ql-align-center">ONE FREE KID\'S MEAL!</h4><p class="ql-align-center">(with purchase of any adult entree)</p><br><p class="ql-align-center" style="font-size: 10px;">Not to be used with any other coupons or offers. Void where prohibited. Sales tax applicable. No cash value.</p>'
    },
    {
      type: 'divider'
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/'
      }
    }
  ]
};

const FineDiningEmailData = {
  styles: {
    width: '600px',
    backgroundColor: '#f5f3ef',
    color: '#000',
    couponBorderColor: '#770e1f',
    buttonsDividersColor: '#770e1f',
    sicailLinksColor: '#000'
  },
  components: [
    {
      type: 'image',
      image: '472/fine-dining-email-logo_1498081793107.png'
    },
    {
      type: 'image',
      image: '472/fine-dining-email-banner_1498081819298.png'
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center" style="font-style:italic; color:#770e1f;">YOU\'RE INVITED!</h2>'
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Please join us for a food pairing experience offered exclusively to our most loyal patrons.</p>'
    },
    {
      type: 'spacer'
    },
    {
      type: 'text',
      text: '<p class="ql-align-center" style="font-weight:bold;">&#36;75 Tasting Menu</p>'
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">(includes a 4-course wine and food pairing meal)</p>'
    },
    {
      type: 'coupon',
      text: '<h4 class="ql-align-center" style="font-weight:normal;">RSVP HERE to join us for this special event.</h4><p class="ql-align-center" style="font-style:italic;">Hurry, seating is limited!</p><br><p class="ql-align-center" style="font-size: 10px;">Not to be used with any other coupons or offers. Void where prohibited. Sales tax applicable. No cash value.</p>'
    },
    {
      type: 'divider'
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/'
      }
    }
  ]
};

const FruitEmailData = {
  styles: {
    width: '600px',
    color: '#f00',
    backgroundColor: '#fff',
    couponBorderColor: '#f00',
    buttonsDividersColor: '#f00',
    sicailLinksColor: '#f00'
  },
  components: [
    {
      type: 'image',
      image: '472/fruit-email-logo_1498081891611.png'
    },
    {
      type: 'image',
      image: '472/fruit-email-banner_1498081856413.png'
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center">10% Off Your First Delivery</h2>'
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Our fruit is guaranteed fresh! Stock up on healthy snacks for you and your friends. Our fruit baskets are sure to put a smile on everyone\'s face.</p>'
    },
    {
      type: 'spacer'
    },
    {
      type: 'spacer'
    },
    {
      type: 'coupon',
      text: '<h2 class="ql-align-center">10% Off Your First Delivery</h2><p class="ql-align-center" style="font-size: 10px;">Limit one per customer. Not to be used with any other coupons or offers. Void where prohibited. Sales tax applicable. No cash value.</p>'
    },
    {
      type: 'divider'
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/'
      }
    }
  ]
};

const ItalianEmailData = {
  styles: {
    width: '600px',
    color: '#000',
    backgroundColor: '#fcefd8',
    couponBorderColor: '#d82c1f',
    buttonsDividersColor: '#d82c1f',
    sicailLinksColor: '#000'
  },
  components: [
    {
      type: 'image',
      image: '472/italian-email-logo_1498081923428.png'
    },
    {
      type: 'image',
      image: '472/italian-email-banner_1498081945667.png'
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center" style="font-style:italic; color:#d82c1f;">ENJOY &#36;10 OFF YOUR NEXT VISIT!</h2>'
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">From our Penne Carbonara to our famous Pomodoro Bolognese, you\'re sure to enjoy a fantastic meal.</p>'
    },
    {
      type: 'spacer'
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Show us this email and receive &#36;10 off your check.</p>'
    },
    {
      type: 'coupon',
      text: '<h4 class="ql-align-center" style="color:#d82c1f;">&#36;10 OFF YOUR NEXT VISIT</h4><p class="ql-align-center">(of equal or lesser value)</p><br><p class="ql-align-center" style="font-size: 10px;">Not to be used with any other coupons or offers. Void where prohibited. Sales tax applicable. No cash value.</p>'
    },
    {
      type: 'divider'
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/'
      }
    }
  ]
};

const MexicanEmailData = {
  styles: {
    width: '600px',
    color: '#fff',
    backgroundColor: '#efae2a',
    couponBorderColor: '#fff',
    buttonsDividersColor: '#fff',
    sicailLinksColor: '#fff'
  },
  components: [
    {
      type: 'image',
      image: '472/mexican-email-logo_1498082003319.png'
    },
    {
      type: 'image',
      image: '472/mexican-email-banner_1498081977956.png'
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center">10% Off Your Next Purchase</h2>'
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Our authentic, made-from-scratch menu is guaranteed to brighten your day! Come join us and enjoy a cold drink and delicious appetizers at unbeatable prices. Let the fiesta begin!</p>'
    },
    {
      type: 'spacer'
    },
    {
      type: 'coupon',
      text: '<h2 class="ql-align-center">10% Off Your Next Purchase</h2><p class="ql-align-center" style="font-size: 10px;">Limit one per customer. Not to be used with any other coupons or offers. Void where prohibited. Sales tax applicable. No cash value.</p>'
    },
    {
      type: 'divider'
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/'
      }
    }
  ]
};

const PizzaEmailData = {
  styles: {
    width: '600px',
    color: '#000',
    backgroundColor: '#fdd85c',
    couponBorderColor: '#eb6464',
    buttonsDividersColor: '#eb6464',
    sicailLinksColor: '#000'
  },
  components: [
    {
      type: 'image',
      image: '472/pizza-email-logo_1498082059029.png'
    },
    {
      type: 'image',
      image: '472/pizza-email-banner_1498082035463.png'
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center" style="font-style:italic">BUY ONE, GET ONE FREE!</h2>'
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">From mushroom and pepperoni to olive and anchovies, the choice is yours. Order any two-topping pizza and get one FREE!</p>'
    },
    {
      type: 'spacer'
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Show us this email in-store online to receive this deal.</p>'
    },
    {
      type: 'coupon',
      text: '<h3 class="ql-align-center">BUY ONE, GET ONE FREE!</h3><p class="ql-align-center">(of equal or lesser value)</p><br><p class="ql-align-center" style="font-size: 10px;">Not to be used with any other coupons or offers. Void where prohibited. Sales tax applicable. No cash value.</p>'
    },
    {
      type: 'divider'
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/'
      }
    }
  ]
};

const QsrEmailData = {
  styles: {
    width: '600px',
    color: '#000',
    backgroundColor: '#fff',
    couponBorderColor: '#000',
    buttonsDividersColor: '#000',
    sicailLinksColor: '#000'
  },
  components: [
    {
      type: 'image',
      image: '1017/burger_1505312326850_1505312462526.png'
    },
    {
      type: 'image',
      image: '472/qsr-email-banner_1498082092016.png'
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center" style="font-style:italic; color:#aa181c;">FREE FRY FRIDAY</h2>'
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">We love fries. You love fries. We bet you\'ll love FREE fries even more.</p>'
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Order any burger or sandwich, and receive a large fry for free.</p>'
    },
    {
      type: 'spacer'
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Hurry in, offer valid for the month of March only!</p>'
    },
    {
      type: 'spacer'
    },
    {
      type: 'coupon',
      text: '<h3 class="ql-align-center" style="color:#aa181c;">FREE LARGE FRY!</h3><p class="ql-align-center">(with purchase of any burger or sandwich)</p><br><p class="ql-align-center" style="font-size: 10px;">Not to be used with any other coupons or offers. Void where prohibited. Sales tax applicable. No cash value.</p>'
    },
    {
      type: 'divider'
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/'
      }
    }
  ]
};

const SushiEmailData = {
  styles: {
    width: '600px',
    color: '#fff',
    backgroundColor: '#231f20',
    couponBorderColor: '#fff',
    buttonsDividersColor: '#fff',
    sicailLinksColor: '#fff'
  },
  components: [
    {
      type: 'image',
      image: '472/sushi-email-logo_1498082220393.png'
    },
    {
      type: 'image',
      image: '472/sushi-email-banner_1498082245482.png'
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center" style="font-style:italic;">HALF PRICE HAPPY HOUR!</h2>'
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Select Sushi & Appetizers from &#36;3.99 &ndash; &#36;9.99!</p>'
    },
    {
      type: 'spacer'
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Check out our drink specials and updated menu as well.</p>'
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Call ahead and make a reservation today!</p>'
    },
    {
      type: 'coupon',
      text: '<h4 class="ql-align-center">BUY ONE HAND ROLL, GET ONE HALF OFF</h4><p class="ql-align-center">(of equal or lesser value)</p><br><p class="ql-align-center" style="font-size: 10px;">Limit one per customer. Not to be used with any other coupons or offers. Void where prohibited. Sales tax applicable. No cash value.</p>'
    },
    {
      type: 'divider'
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/'
      }
    }
  ]
};

const BlankEmailData = {
  styles: {
    width: '600px',
    color: '#000',
    backgroundColor: '#fff',
    buttonsDividersColor: '#000',
    sicailLinksColor: '#000'
  },
  components: []
};

export const enStaticTemplates = [
  {
    id: 'as0xedywa1immkfzl3a0',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateBakeryTitle',
    status: 'origin',
    favorites: 'false',
    components: BakeryEmailData.components,
    styles: BakeryEmailData.styles,
    thumb: 'bakery-email-preview-en.jpg'
  },
  {
    id: 'b4jq91rqmk95fhy8fkc8',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateBarPubTitle',
    status: 'origin',
    favorites: 'false',
    components: BarPubEmailData.components,
    styles: BarPubEmailData.styles,
    thumb: 'bar-pub-email-preview-en.jpg'
  },
  {
    id: '7ai12s5j7ce0jsghad94',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateBurgerTitle',
    status: 'origin',
    favorites: 'false',
    components: BurgerEmailData.components,
    styles: BurgerEmailData.styles,
    thumb: 'burger-email-preview-en.jpg'
  },
  {
    id: 'gl4zl2qtyvjo4zqclkdv',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateChineseTitle',
    status: 'origin',
    favorites: 'false',
    components: ChineseEmailData.components,
    styles: ChineseEmailData.styles,
    thumb: 'chinese-email-preview-en.jpg'
  },
  {
    id: '1pf0oex8vlz9g905xvgw',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateCoffeeTitle',
    status: 'origin',
    favorites: 'false',
    components: CoffeeEmailData.components,
    styles: CoffeeEmailData.styles,
    thumb: 'coffee-email-preview-en.jpg'
  },
  {
    id: 't8o2au09qbi04tmmynnl',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateDessertTitle',
    status: 'origin',
    favorites: 'false',
    components: DessertEmailData.components,
    styles: DessertEmailData.styles,
    thumb: 'dessert-email-preview-en.jpg'
  },
  {
    id: 'k2zw29hzloh0pxrud6l5',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateDinerTitle',
    status: 'origin',
    favorites: 'false',
    components: DinerEmailData.components,
    styles: DinerEmailData.styles,
    thumb: 'diner-email-preview-en.jpg'
  },
  {
    id: '02mjw15vmtp58fbpvwvy',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateFamilyDiningTitle',
    status: 'origin',
    favorites: 'false',
    components: FamilyDiningEmailData.components,
    styles: FamilyDiningEmailData.styles,
    thumb: 'family-dining-email-preview-en.jpg'
  },
  {
    id: 'hrigcu5hmn0znu1bx7j2',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateFineDiningTitle',
    status: 'origin',
    favorites: 'false',
    components: FineDiningEmailData.components,
    styles: FineDiningEmailData.styles,
    thumb: 'fine-dining-email-preview-en.jpg'
  },
  {
    id: '096wwwsrzjm8a864bykm',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateFruitTitle',
    status: 'origin',
    favorites: 'false',
    components: FruitEmailData.components,
    styles: FruitEmailData.styles,
    thumb: 'fruit-email-preview-en.jpg'
  },
  {
    id: 'eu3jmwu1a3steilfk4g0',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateItalianTitle',
    status: 'origin',
    favorites: 'false',
    components: ItalianEmailData.components,
    styles: ItalianEmailData.styles,
    thumb: 'italian-email-preview-en.jpg'
  },
  {
    id: '0uvvp8h1yohf11h0pg08',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateMexicanTitle',
    status: 'origin',
    favorites: 'false',
    components: MexicanEmailData.components,
    styles: MexicanEmailData.styles,
    thumb: 'mexican-email-preview-en.jpg'
  },
  {
    id: 'iifhc7rnaujvt0lcx525',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateMoveItSportsTitle',
    status: 'origin',
    favorites: 'false',
    components: MoveItSportsEmailData.components,
    styles: MoveItSportsEmailData.styles,
    thumb: 'move-it-sports-en.jpg'
  },
  {
    id: '7b8w6bdfkxjjc2qfxw8p',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplatePizzaTitle',
    status: 'origin',
    favorites: 'false',
    components: PizzaEmailData.components,
    styles: PizzaEmailData.styles,
    thumb: 'pizza-email-preview-en.jpg'
  },
  {
    id: 'b9j074e2hxz94c05q9aw',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateQSRTitle',
    status: 'origin',
    favorites: 'false',
    components: QsrEmailData.components,
    styles: QsrEmailData.styles,
    thumb: 'qsr-email-preview-en.jpg'
  },
  {
    id: 'jbvaxcvztg68xe1bluh2',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateSushiTitle',
    status: 'origin',
    favorites: 'false',
    components: SushiEmailData.components,
    styles: SushiEmailData.styles,
    thumb: 'sushi-email-preview-en.jpg'
  },
  {
    id: '2w3fevauli5v312dqx6u',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateTimberlineTitle',
    status: 'origin',
    favorites: 'false',
    components: TimberlineEmailData.components,
    styles: TimberlineEmailData.styles,
    thumb: 'timberline-en.jpg'
  },
  {
    id: 'i3ehc7asdujvt0lcx525',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateBlankTitle',
    status: 'origin',
    favorites: 'false',
    components: BlankEmailData.components,
    styles: BlankEmailData.styles,
    thumb: 'blank-en.png'
  }
];
