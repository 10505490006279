import React, { ReactNode, useRef } from 'react';
import Loader from '../shared-components/Loader';

interface PropTypes {
  action: Function;
  condition: boolean;
  children: ReactNode;
}

export const DataLoadHOC = (props: PropTypes): JSX.Element => {
  const willMount = useRef(true);
  if (willMount.current) props.action();
  willMount.current = false;

  const { condition, children } = props;

  return condition ? <>{children}</> : <Loader loading />;
};
