import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { getMenuPages } from 'store/navigation';
import { AppState } from 'store/rootReducer';
import { getMerchantPages, isSettingsLoaded, settingsActions } from 'store/settings';
import { COOKIES, NO_PAGE_FOUND_PATH } from 'appConstants';
import { getCookieByName } from 'services/cookies';
import { getPreventSessionExpirationAutoRedirect } from 'store/branding';
import { ErrorTypes } from '../../store/apiGateway';
import { Error500Component } from './500';
import { Error404Component } from './404';
import { Error403Component } from './403';

export interface ErrorPageProps {
  message: string;
  isSessionExpired?: boolean;
  status?: number;
  path: string;
  homeUrl: string;
  type: ErrorTypes;
  settingsLoaded: boolean;
  errorUrl: {
    portalUrl: string;
    sessionExpirationRedirectURL?: string;
    preventSessionExpirationAutoRedirect?: boolean;
  };
}

interface ConnectedActions {
  requestSettings: typeof settingsActions.info.request;
}

const componentMap: { [key: number]: React.ComponentType<ErrorPageProps> } = {
  500: Error500Component,
  404: Error404Component,
  403: Error403Component
};

export const ErrorPageComponent = (props: ErrorPageProps & RouteComponentProps) => {
  const { status, path, history } = props;
  const componentKey = status || parseInt(path);

  if (!status && componentKey !== 404) {
    history.push(NO_PAGE_FOUND_PATH);
  }

  const Component = componentMap[componentKey];
  return Component ? <Component {...props} /> : <Error500Component {...props} />;
};

export const ErrorPage = withRouter(
  connect<ErrorPageProps, ConnectedActions, void, AppState>(
    (state: AppState) => {
      const { apiGateway, router, branding } = state;
      const pages = getMenuPages(state);
      const merchantPages = getMerchantPages(state);
      const sessionExpirationRedirectURL = decodeURIComponent(getCookieByName(COOKIES.sessionExpirationRedirectURL));
      const preventSessionExpirationAutoRedirect = getPreventSessionExpirationAutoRedirect(state);

      const homeUrl =
        pages && Array.isArray(pages) && pages.length && Object.keys(merchantPages).length
          ? `${pages[0].path}`
          : branding.portal.url;

      return {
        homeUrl,
        message: apiGateway.error.message,
        status: apiGateway.error.status,
        isSessionExpired: apiGateway.error.isSessionExpired,
        path: router.location.pathname.replace('/', ''),
        type: apiGateway.error.type,
        settingsLoaded: isSettingsLoaded(state),
        errorUrl: {
          portalUrl: branding.portal.url,
          sessionExpirationRedirectURL,
          preventSessionExpirationAutoRedirect
        }
      };
    },
    dispatch =>
      bindActionCreators(
        {
          requestSettings: settingsActions.info.request
        },
        dispatch
      )
  )(ErrorPageComponent)
);
