import styled from 'styled-components';

export const Link = styled.div`
  font-weight: bold;
  margin-top: 30px;
`;

export const Container = styled.div`
  display: flex;
  height: 60vh;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const IconWrapper = styled.div`
  margin: 0 0 15px 0;
`;

export const ContentWrapper = styled.div`
  max-width: 450px;
`;
