import { call, takeLatest, put, select } from 'redux-saga/effects';
import { difference, sortBy } from 'lodash';
import { getType } from 'typesafe-actions';
import { getActivePage, navigationActions } from 'store/navigation';
import { ApiGateway } from '../../services/apiGateway';
import { locationsActions } from './actions';
import { AppState } from '../rootReducer';
import { getSelectedLocations } from './selectors';
import { getMerchantPages, getUserLocations } from '../settings';
import { googleAnalytics } from '../../services/googleAnalytics';
import { decodeLocationsFilter, decodeLocationsSearch, encodeLocationsFilter } from './modifiers';

const listActions = locationsActions.search.list;
const itemCreateActions = locationsActions.search.item.create;
const itemDeleteActions = locationsActions.search.item.delete;
const recentSearchActions = locationsActions.search.addRecent;

export function* LocationSelectorSagas() {
  yield takeLatest(getType(listActions.request), function* () {
    try {
      const response = yield call(ApiGateway.fetchLocationsSearchData);
      yield put(listActions.success(decodeLocationsSearch(response)));
    } catch (e) {
      yield put(listActions.failure());
    }
  });

  yield takeLatest(
    getType(itemCreateActions.request),
    function* (action: ReturnType<typeof itemCreateActions.request>) {
      try {
        const encodedData = encodeLocationsFilter(action.payload);
        yield call(ApiGateway.saveLocationFilter, encodedData);
        googleAnalytics.events.filter.add();
        yield put(itemCreateActions.success(decodeLocationsFilter(encodedData)));
      } catch (e) {
        yield put(itemCreateActions.failure());
      }
    }
  );

  yield takeLatest(
    getType(itemDeleteActions.request),
    function* (action: ReturnType<typeof itemDeleteActions.request>) {
      try {
        yield call(ApiGateway.deleteLocationFilter, encodeURIComponent(action.payload.name));
        googleAnalytics.events.filter.delete();
        yield put(itemDeleteActions.success(action.payload));
      } catch (e) {
        yield put(itemDeleteActions.failure());
      }
    }
  );

  yield takeLatest(
    getType(recentSearchActions.request),
    function* (action: ReturnType<typeof recentSearchActions.request>) {
      try {
        const encodedItem = encodeURIComponent(action.payload);
        yield call(ApiGateway.addRecentSearch, encodedItem);
        yield put(recentSearchActions.success(decodeURIComponent(encodedItem)));
      } catch (e) {
        yield put(recentSearchActions.failure());
      }
    }
  );

  yield takeLatest(getType(navigationActions.setActivePage), function* () {
    try {
      const state: AppState = yield select();
      const activePage = getActivePage(state);
      const selectedLocations = getSelectedLocations(state);
      if (activePage && !!activePage.locationSelector) {
        const merchantPages = getMerchantPages(state);
        const locations = getUserLocations(state);

        const availableLocations: string[] = [];

        sortBy(locations, location => location.merchant_name).forEach(({ merchant_sequence_key }) => {
          const isAvailable = (merchantPages[merchant_sequence_key] || []).includes(activePage.pageId);
          if (isAvailable) {
            availableLocations.push(merchant_sequence_key);
          }
        });

        const nextLocations = selectedLocations.filter(el => availableLocations.includes(el));
        const diff = difference(selectedLocations, nextLocations);

        if (diff.length) {
          yield put(locationsActions.select(nextLocations.length > 0 ? nextLocations : availableLocations.slice(0, 1)));
        }
      }
    } catch (error) {
      console.error(error);
    }
  });
}
