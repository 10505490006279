import { set, omit } from 'lodash';
import { DayBookNote } from 'store/daybook';
import { RFMDataValue, RFMData, RFMLineChartTick, RFMTierValue } from './types';

interface DayValueMap {
  tier1: RFMTierValue;
  tier2: RFMTierValue;
  tier3: RFMTierValue;
  tier4: RFMTierValue;
  tier5: RFMTierValue;
}

export const getLineChartData = (data: RFMData, dayBooks: DayBookNote[]): RFMLineChartTick[] => {
  const dayValueMap: { [key: string]: DayValueMap } = {};

  Object.entries(data).forEach(([tierKey, tier]) => {
    tier.data.forEach((item: RFMDataValue) => {
      const { starttime } = item;
      set(dayValueMap, [starttime, tierKey], omit(item, 'starttime'));
    });
  });

  const dayBooksMap: { [key: string]: DayBookNote[] } = {};

  dayBooks.forEach(daybook => {
    const { noteDate } = daybook;
    if (!(noteDate in dayBooksMap)) dayBooksMap[noteDate] = [];
    dayBooksMap[noteDate].push(daybook);
  });

  return Object.entries(dayValueMap).map(([starttime, tier]) => ({
    starttime,
    ...tier,
    dayBooks: dayBooksMap[starttime] || []
  }));
};
