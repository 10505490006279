import {
  UberallActiveDirectories,
  UberallDisconnectData,
  UberallIdentityResponse,
  UberallLocationInfo,
  UberallRepliesResponse,
  UberallReview,
  UberallSignupData,
  UberallStatisticsResponse
} from './apiTypes';

export enum RequestStatusEnum {
  'IDLE' = 'idle',
  'LOADING' = 'loading',
  'SUCCEEDED' = 'succeeded',
  'FAILED' = 'failed'
}
interface DataType<D> {
  requestStatus?: RequestStatusEnum;
  error?: Error | null;
  data?: D | null;
}

export interface TransactionsTrendPeriod {
  month: string;
  new: number;
  returning: number;
  all: number;
}

export interface UberallState {
  identity: Record<string, DataType<UberallIdentityResponse>>;
  signup: Record<string, DataType<UberallSignupData> & { errors?: Partial<Record<keyof UberallSignupData, string>> }>;
  transactions: Record<string, DataType<TransactionsTrendPeriod[]>>;

  statistics: Record<number, DataType<UberallStatisticsResponse>>;
  replies: Record<number, DataType<UberallRepliesResponse>>;
  locationInfo: Record<number, DataType<UberallLocationInfo>>;
  editLocation: Record<number, DataType<{}>>;
  reply: DataType<UberallReview>;
  directoriesConfig: DataType<UberallActiveDirectories>;
  disconnectDirectory: DataType<UberallDisconnectData>;
}
