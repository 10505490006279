/* eslint-disable */
import { DayBookCustomerType } from 'store/daybook';
import { BaseColorsMap } from 'layout/theme/types';

export const defaultColorSchema = {
  color1: '#0072CE',
  color2: '#E49437',
  color3: '#8B4D9F',
  color4: '#32B56E',
  color5: '#77C9C0',
  color6: '#003C71',
  color7: '#B8E5CD',
  headerColor: '#0072CE'
};

export const baseColors: BaseColorsMap = {
  white: '#FFFFFF',
  lightGrey: '#FAFAFA',
  grey: '#E0E0E0',
  mediumGrey: '#b4b4b4',
  blueGrey: '#D7DCE1',
  darkGrey: '#54575A',
  mockedGrey: '#828486',
  deepGrey: '#979797',
  mediumLightGrey: '#EAEAEA',
  lightBlue: '#EDF5FF',
  middleBlue: '#BFDCF3',
  blue: '#66AAE2',
  red: '#D02128',
  yellow: '#FFBF3F',
  green: '#32B56E',
  transparent: 'rgba(0,0,0,0)',
  semitransparentBlack: 'rgba(0,0,0,0.03)'
};

export const leftMenuWidthAnimationDuration = 200;
export const leftMenuIconContainerWidth = 60;
export const leftMenuTextContainerWidth = 170;
export const leftMenuPaddingForTextContainer = 15;
export const headerHeight = 70;
export const toolBarHeight = 45;
export const topMenuOverlayZIndex = 100;
export const footerHeight = 210;

export const notificationTimeout = 2000;

export const templateEditorGroupName = 'templateEditor';

export const INTERNATIONAL_DATE_FORMAT = 'YYYY-MM-DD';
export const INTERNATIONAL_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const EUROPE_DATE_FORMAT = 'DD MMM YYYY';
export const SHORT_MONTH_DAY_YEAR_FORMAT = 'MMM D, YYYY';
export const MONTH_DAY_YEAR_FORMAT = 'MMMM D, YYYY';
export const YEAR_MONTH_FORMAT = 'YYYY-MM';
export const HOURS_AND_MINUTES_UTC_FORMAT = 'HH:mm';

export const DAYBOOK_CUSTOMERS_NEW: DayBookCustomerType = { label: 2, type: 'new' };
export const DAYBOOK_CUSTOMERS_RETURNING: DayBookCustomerType = { label: 1, type: 'returning' };
export const DAYBOOK_CUSTOMERS_ALL: DayBookCustomerType = { label: 3, type: 'all' };

export const tabletMinWidth = 667;
export const tabletMaxWidth = 895;

export const mobileXSWidth = 320;

export const laptopSWidth = 1250;
export const laptopMWidth = 1440;
export const laptopLWidth = 1680;

export const responsiveSize = {
  laptopS: `${laptopSWidth}px`,
  laptopM: `${laptopMWidth}px`,
  laptopL: `${laptopLWidth}px`
};

export const deviceMedia = {
  laptopS: `screen and  (max-width: ${responsiveSize.laptopS})`,
  laptopM: `screen and (max-width: ${responsiveSize.laptopM})`,
  laptopL: `screen and (max-width: ${responsiveSize.laptopL})`,

  tablet: `screen and (max-width: ${tabletMaxWidth}px)`,
  mobile: `screen and (max-width: ${tabletMinWidth}px)`,
  mobileXS: `screen and (max-width: ${mobileXSWidth}px)`
};

export const androidPayButton = {
  light:
    'https://s3.amazonaws.com/beanstalk-customer-email-images/1281/SavetoGooglePay-English-Light_small_1520002639407.png',
  dark: 'https://s3.amazonaws.com/beanstalk-customer-email-images/1281/SavetoGooglePay-English-Dark_small_1520001778194.png'
};

export const ENVIRONMENTS = {
  LOCAL: 'local',
  DEVELOPMENT: 'develop',
  STAGING: 'staging',
  UK_STAGING: 'uk-staging',
  PRODUCTION: 'production',
  UK_PRODUCTION: 'uk-production',
  TEST: 'test'
};

export const DEFAULT_RETURNING_FREQUENCY = '12';

export const USER_NAME_LOCAL_STORAGE_KEY = 'USER_NAME';

export const LOYALTY_WELCOME = 'welcome';
export const LOYALTY_ALMOST_REWARD = 'almost_reward';
export const LOYALTY_REWARD = 'loyalty_reward';
export const LOYALTY_MISS_YOU = 'miss_you';
export const LOYALTY_ANNIVERSARY = 'anniversary';
export const LOYALTY_BIRTHDAY = 'birthday';

export const LOYALTY_CAMPAIGNS: any = {
  [LOYALTY_WELCOME]: 'Welcome',
  [LOYALTY_ALMOST_REWARD]: 'Close to Next Reward',
  [LOYALTY_REWARD]: 'You Earned a Reward',
  [LOYALTY_MISS_YOU]: 'We Missed You',
  [LOYALTY_ANNIVERSARY]: 'Anniversary',
  [LOYALTY_BIRTHDAY]: 'Birthday'
};

export const INIT_DAYS_CORE_PROGRAM_ACTIVATION = 31;
export const LOYALTY_PROGRAM_ALIAS = 'loyalty';
export const REWARDS_PROGRAM_ALIAS = 'rewards';

export const LOYALTY_REWARD_TYPES: any = {
  false: 'receipt',
  true: LOYALTY_PROGRAM_ALIAS
};

export const LOYALTY_INCENTIVE_ACTIVE_TYPES: any = {
  points: 'Points',
  purchases: 'Purchases'
};

export const MASTERCARD = 'mastercard';
export const MERCHANT_CENTRIC = 'merchantCentric';
export const PAYMENT_ANALYTICS = 'paymentAnalytics';
export const UBERALL = 'uberall';
export const EMAIL_MARKETING = 'emailMarketing';
export const EMAIL_MARKETING_PROGRAM = 'EMAIL_MARKETING';
export const LOYALTY_PROGRAM = 'LOYALTY';
export const EMAIL_MARKETING_PRIVACY_POLICY = 'emailMarketingPrivacyPolicy';
export const TERMS_MODAL_COMPONENT = 'TermsModal';

export const DEFAULT_PAGE = '/payment-analytics';
export const COMPARE_PAGE = '/compare';
export const PROFILE_PAGE = '/profile';
export const TEASER = '/teaser';
export const CUSTOMER_DEMOGRAPHICS_PAGE = '/customer-demographics';
export const DASHBOARD = '/payment-analytics/dashboard';
export const REPUTATION_PAGE = '/reputation-management';
export const REPUTATION_OVERVIEW_PAGE = '/reputation-management/overview';
export const REPUTATION_MODULE = 'Reputation Management';
export const CUSTOMER_DEMOGRAPHIC_MODULE = 'Customer Demographics';

export const LOCATION_LINEUP_ROOT = 'locationLineup_root';

export const supportSearchPathname = '/support-search';

export const NO_PAGE_FOUND_PATH = '/404';

export const LOCATION_SELECTOR_SINGLE_TYPE = 'single';
export const LOCATION_SELECTOR_MULTI_TYPE = 'multi';

export const ERROR_PAGES = ['/403', NO_PAGE_FOUND_PATH, '/500', '/oops'];

export const MERCHANT_CENTRIC_SITES = ['Google', 'Facebook', 'Yelp', 'Yahoo', 'Citysearch', 'Superpages'];

export const NUMBERS_WITHOUT_ABBREVIATION_RANGE = {
  min: 9999,
  max: 100000
};

export enum CURRENCY_SIGN_POSITION {
  before = 'before',
  after = 'after'
}

export const HEADER_LOCATION_SELECTOR = 'HeaderLocationSelector';

export const INPUT_MAX_SYMBOLS_COUNT = 255;

export const HOURS_AND_MINUTES_FORMAT = 'hh:mm A';

export const ACCOUNT_NAMES_WITH_TEASER_CONDITION = ['oe-us', 'tsys.gpi', 'tsys.direct', 'gbv-ca'];

/*
  ToDo:
   Rename DANUBE_ACCOUNTS and it's usages (selectors, props, etc) + alx-api-crm Danube variables
   Tech Debt ticket: https://gpproduct.atlassian.net/browse/ALX-25961
 */
export const DANUBE_ACCOUNTS = ['mygp.cz', 'mygp.sk', 'mygp.at', 'mygp.ro', 'mygp.de'];
export const EU_ACCOUNTS = [
  'mygp.cz',
  'mygp.sk',
  'mygp.at',
  'mygp.ro',
  'mp-uk',
  'gbv-uk',
  'evo.mygp.de',
  'mygp.mt',
  'mygp.hu',
  'mygp.de'
];

export const GP_POS_ACCOUNT = 'apphub-broomfield-us';
export const US_ACCOUNTS = ['apphub-broomfield-us', 'default'];

export const TIPPY_CLASSNAME = 'alxTippyContainer';

export const predictionDatakeys = {
  lowerAmount: 'prediction.lower',
  avgAmount: 'prediction.avg',
  upperAmount: 'prediction.upper',
  margin: 'prediction.margin',
  fakeValue: 'fakeValue'
};

export const ACCOUNT_MERCHANT_CENTRIC_VIEW_PARAMETERS = [
  {
    name: 'oe-us',
    viewParameter: 'openedge-listing'
  },
  {
    name: 'gbv-ca',
    viewParameter: 'gp_canada-listing'
  },
  {
    name: 'tsys.gpi',
    viewParameter: 'gpi_tsys-listing'
  },
  {
    name: 'tsys.direct',
    viewParameter: 'hps_tsys-listing'
  },
  {
    name: 'xp-us',
    viewParameter: 'xenial-listing'
  },
  {
    name: 'xp-us-ent',
    viewParameter: 'xenial-listing'
  },
  {
    name: 'default',
    viewParameter: 'heartland-listing'
  }
];

export const ANALYTICS_MODULE_NAME_MAPPING: Record<string, string> = {
  advanced_analytics_root: 'ADVANCED_ANALYTICS',
  analytics_root: 'ANALYTICS'
};

export const API_ERROR_PAGE_PATH = '/error';

export const COOKIES = {
  sessionExpirationRedirectURL: 'sessionExpirationRedirectURL',
  apphubBackOfficeURL: 'sessionExpirationRedirectURL'
};

export const benchmarkRoot = 'benchmark_root';
export const masterCardDataFetcher = 'MasterCardDataFetcher';

export const AUSTRIA_LOCALES = ['de_at', 'de-at'];

export const DEFAULT_PREDICTIVE_DATE_RANGE_30_DAYS: string = '30';
export const DEFAULT_PREDICTIVE_DATE_RANGE_7_DAYS: string = '7';
