import { ActionType, createReducer } from 'typesafe-actions';
import { SalesState } from './types';
import { salesActions } from './actions';

const initialState: SalesState = {
  summary: {
    data: null,
    pending: false
  },
  salesGrossNetStat: {
    data: null,
    pending: false
  }
};

export const salesReducer = createReducer<SalesState, ActionType<typeof salesActions>>(initialState)
  .handleAction(
    salesActions.fetchSummary.request,
    (state): SalesState => ({
      ...state,
      summary: {
        ...state.summary,
        pending: true,
        error: undefined
      }
    })
  )
  .handleAction(
    salesActions.fetchSummary.success,
    (state, { payload }): SalesState => ({
      ...state,
      summary: {
        ...state.summary,
        data: payload,
        pending: false,
        error: undefined
      }
    })
  )
  .handleAction(
    salesActions.fetchSummary.failure,
    (state, { payload }): SalesState => ({
      ...state,
      summary: {
        ...state.summary,
        data: null,
        pending: false,
        error: payload
      }
    })
  )
  .handleAction(
    salesActions.fetchSalesGrossNetStat.request,
    (state): SalesState => ({
      ...state,
      salesGrossNetStat: {
        ...state.salesGrossNetStat,
        pending: true,
        error: undefined
      }
    })
  )
  .handleAction(
    salesActions.fetchSalesGrossNetStat.success,
    (state, { payload }): SalesState => ({
      ...state,
      salesGrossNetStat: {
        data: payload,
        pending: false,
        error: undefined
      }
    })
  )
  .handleAction(
    salesActions.fetchSalesGrossNetStat.failure,
    (state, { payload }): SalesState => ({
      ...state,
      salesGrossNetStat: {
        data: null,
        pending: false,
        error: payload
      }
    })
  );
