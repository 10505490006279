import React from 'react';
import styled from 'styled-components';
import { getColor } from '../layout/theme';
import { generateUID } from '../services/utils';
import { ToolTip } from './Hint';

interface StyledProps {
  primary?: boolean;
  secondary?: boolean;
  outline?: boolean;
  hintText?: string;
  textDecoration?: string;
  textAlign?: string;
  hintPlace?: 'bottom' | 'left' | 'right' | 'top';
  withoutCustomMobileTooltip?: boolean;
  overridePosition?: (position: { left: number; top: number }) => { left: number; top: number };
  hintID?: string;
  isMaxContentWidth?: boolean;
  dataTestId?: string;
}

export type ButtonProps = StyledProps &
  React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

const getStyled = (props: StyledProps): string => {
  if (props.primary) {
    return `
      color: ${getColor(props, 'white')};
      background-color: ${getColor(props, 'color1')};
      border: 1px solid ${getColor(props, 'color1')};
      text-shadow: 0px 0px 1px;

      &:disabled {
        color: ${getColor(props, 'white')};
        background-color: ${getColor(props, 'grey')};
        border-color: ${getColor(props, 'grey')};
      }
    `;
  }

  if (props.outline) {
    return `
    color: ${getColor(props, 'color1')};
    background-color: transparent;
    border: 1px solid ${getColor(props, 'color1')};
    text-shadow: 0px 0px 1px;

    &:disabled {
      color: ${getColor(props, 'grey')};
      background-color: transparent;
      border-color: ${getColor(props, 'grey')};
    }
  `;
  }

  return `
    color: ${getColor(props, 'darkGrey')};
    background-color:  ${props.secondary ? 'white' : 'transparent'};
    border: 1px solid ${props.secondary ? getColor(props, 'blueGrey') : 'transparent'};

    &:disabled {
      color: ${getColor(props, 'white')};
      background-color: ${getColor(props, 'grey')};
      border-color: ${getColor(props, 'grey')};
    }
  `;
};

export const StyledButton = styled.button<StyledProps>`
  min-height: 33px;
  min-width: 68px;
  ${({ isMaxContentWidth }) => (isMaxContentWidth ? 'width: max-content' : '')};
  padding: 8px 17px;
  user-select: none;
  outline: none;
  cursor: pointer;
  position: relative;

  ${props => getStyled(props)};

  &:disabled {
    cursor: not-allowed;
  }

  a {
    text-decoration: none;
    color: inherit;
    display: block;
    padding: 8px 17px;
    margin: -8px -17px;
  }
`;

StyledButton.displayName = 'StyledButton';

export const ButtonContainer = styled.div`
  display: inline-block;
  height: 100%;
`;

ButtonContainer.displayName = 'ButtonContainer';

export const Button = (props: ButtonProps) => {
  const {
    textAlign,
    hintText,
    overridePosition,
    hintPlace,
    ref,
    withoutCustomMobileTooltip,
    hintID,
    dataTestId,
    ...rest
  } = props;
  const ID = hintID || generateUID();

  return typeof hintText === 'string' && hintText.length > 0 ? (
    <ButtonContainer
      data-test-id={dataTestId}
      data-tip={hintText}
      data-for={ID}
      data-event="mouseover mouseenter touchstart"
      data-event-off="mouseout mouseleave"
    >
      <StyledButton {...rest} />
      <ToolTip
        id={ID}
        multiline
        effect="solid"
        textAlign={textAlign}
        place={hintPlace}
        overridePosition={overridePosition}
        withoutCustomMobileTooltip={withoutCustomMobileTooltip}
      />
    </ButtonContainer>
  ) : (
    <StyledButton data-test-id={dataTestId} {...rest} />
  );
};

export const LinkButton = styled(StyledButton)`
  ${props => `
    border-color: ${getColor(props, 'transparent')};
    background: ${getColor(props, 'transparent')};
    color: ${getColor(props, 'color1')};
    text-shadow: 0px 0px 1px;
    text-decoration: ${props.textDecoration || 'none'};

    &:disabled {
      border-color: ${getColor(props, 'transparent')};
      background: ${getColor(props, 'transparent')};
      color: ${getColor(props, 'darkGrey')};
    }
  `};
`;

export const StyledLink = styled.a`
  color: ${props => getColor(props, 'color1')};
  text-decoration: none;
  white-space: nowrap;
`;
