import { takeLatest, all, call, put } from 'redux-saga/effects';

import { ApiGateway } from 'services/apiGateway';
import { salesActions } from './actions';

export function* fetchSummarySaga({ payload }: ReturnType<typeof salesActions.fetchSummary.request>) {
  try {
    if (payload.locations.length) {
      const data = yield call(ApiGateway.payments.sales.fetchSummary, payload);
      yield put(salesActions.fetchSummary.success(data));
    } else {
      yield put(salesActions.fetchSummary.success(null));
    }
  } catch (e) {
    yield put(salesActions.fetchSummary.failure(e));
  }
}

export function* fetchSalesGrossNetStatSaga({
  payload
}: ReturnType<typeof salesActions.fetchSalesGrossNetStat.request>) {
  try {
    if (payload.locations.length) {
      const data = yield call(ApiGateway.payments.sales.fetchSalesGrossNetStat, payload);
      yield put(salesActions.fetchSalesGrossNetStat.success(data));
    } else {
      yield put(salesActions.fetchSalesGrossNetStat.success(null));
    }
  } catch (e) {
    yield put(salesActions.fetchSalesGrossNetStat.failure(e));
  }
}

export function* salesSagas() {
  yield all([takeLatest(salesActions.fetchSummary.request, fetchSummarySaga)]);
  yield all([takeLatest(salesActions.fetchSalesGrossNetStat.request, fetchSalesGrossNetStatSaga)]);
}
