import React from 'react';
import styled from 'styled-components';
import { DEFAULT_NS } from 'appConstants/translationNamespaces';
import { useTranslation } from 'react-i18next';
import { ErrorPageProps } from './ErrorPageWrapper';
import { ErrorPageTemplate } from './ErrorPageTemplate';

const Description = styled.div`
  & > *:first-child {
    font-weight: bold;
  }
`;

export const Error500Component = ({ message }: ErrorPageProps) => {
  const { t } = useTranslation(DEFAULT_NS);
  return (
    <ErrorPageTemplate
      renderDescription={() => (
        <>
          <Description dangerouslySetInnerHTML={{ __html: message }} />
          <p>{t('internalServerError', 'Internal Server Error')}.</p>
        </>
      )}
    />
  );
};
