import { ServerTemplate } from './types';
import { AppState } from '../../rootReducer';

export const getStaticTemplates = (state: AppState): any[] => state.emailMarketing.campaignEditor.staticTemplates || [];
export const getCustomerTemplates = (state: AppState): any[] =>
  state.emailMarketing.campaignEditor.customerTemplates || [];
export const getCustomerTemplatePreview = (
  state: AppState
): { isPreviewLoading: boolean; template: ServerTemplate } => ({
  isPreviewLoading: state.emailMarketing.campaignEditor.customerTemplatePreview.pending,
  template: state.emailMarketing.campaignEditor.customerTemplatePreview.template
});
export const isEditorLoading = (state: AppState) => state.emailMarketing.campaignEditor.loading || false;
export const isSendingCampaign = (state: AppState) => state.emailMarketing.campaignEditor.sendingCampaign || false;
export const getOptionsTabId = (state: AppState) => state.emailMarketing.campaignEditor.optionsTabId || 0;
export const getCrmCampaignId = (state: AppState) => state.emailMarketing.campaignEditor.crmCampaignId || -1;
export const getCampaignStartDate = (state: AppState) => state.emailMarketing.campaignEditor.startDate || '';
export const getCampaignName = (state: AppState) => state.emailMarketing.campaignEditor.campaignName || '';
export const getSelectedContacts = (state: AppState) => state.emailMarketing.campaignEditor.selectedContacts || [];
export const getContactsInversion = (state: AppState) => state.emailMarketing.campaignEditor.isContactsInverted;
export const getSendTestCampaignErrorType = (state: AppState) =>
  state.emailMarketing.campaignEditor.sendTestCampaignErrorType;
export const getContactMemberTypes = (state: AppState) => state.emailMarketing.campaignEditor.memberTypes || [];
export const getLinkedCompanies = (state: AppState): string[] =>
  state.emailMarketing.campaignEditor.linkedCompanies || [];
