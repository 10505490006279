import { AppState } from '../rootReducer';
import { AuthAndDeclinesState, DeclineCodesTableOptions } from './types';

export const getDeclineCodesTableOptions = (state: AppState): DeclineCodesTableOptions =>
  state.authAndDeclines.declineCodesTableOptions;

export const getAuthAndDeclinesSummary = (state: AppState): AuthAndDeclinesState['summary'] =>
  state.authAndDeclines.summary;

export const getAADeclineCodes = (state: AppState): AuthAndDeclinesState['declineCodes'] =>
  state.authAndDeclines.declineCodes || {};

export const getAAAuthRateStat = (state: AppState): AuthAndDeclinesState['authRate'] =>
  state.authAndDeclines.authRate || {};
