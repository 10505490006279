import moment from 'moment';
import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { formatString } from 'services/utils';
import { YEAR_MONTH_FORMAT, deviceMedia } from 'appConstants';
import { dateFormatter } from 'services/dateFormatter';
import { StyledCalendarIcon } from 'shared-components/DatePickerBasic/components';
import { DEFAULT_NS } from 'appConstants/translationNamespaces';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-right: 5px;
  }

  @media ${deviceMedia.mobile} {
    padding-top: 15px;
    padding-bottom: 15px;
  }
`;

interface Props {
  period?: string | null;
  useFullDate?: boolean;
}

export const PeriodVisualizer = (props: Props): ReactElement => {
  const { period, useFullDate } = props;
  const { t } = useTranslation(DEFAULT_NS);
  if (!period) return <Container>{t('PeriodVisualizerDataUnavailable', 'Data Unavailable')}</Container>;
  const formattedPeriod = dateFormatter.dateMonthYear(
    useFullDate ? moment(period) : moment(period, YEAR_MONTH_FORMAT).endOf('month')
  );
  return (
    <Container data-test-id="period-visualizer">
      <StyledCalendarIcon color="#fff" />
      {formatString(t('PeriodVisualizerDataLastUpdatedOn', 'Data last updated {0}'), formattedPeriod)}
    </Container>
  );
};
