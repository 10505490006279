import { EmailMarketingCustomerSettings } from './types';

export const encodeEMCustomerSettings = (data: EmailMarketingCustomerSettings) => ({
  ...data,
  socialLinks: data.socialLinks.map(item => ({ ...item, link: encodeURIComponent(item.link) }))
});

export const decodeEMCustomerSettings = (data: EmailMarketingCustomerSettings) => ({
  ...data,
  socialLinks: data.socialLinks.map(item => ({ ...item, link: decodeURIComponent(item.link) }))
});
