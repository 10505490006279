import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { get } from 'lodash';
import { ReactComponent as ChevronComponent } from 'assets/icons/chevron.svg';
import { deviceMedia, USER_NAME_LOCAL_STORAGE_KEY } from 'appConstants';
import { googleAnalytics } from 'services/googleAnalytics';
import { bindDocumentClickOrTouchListener } from 'services/utils';
import { withTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { DEFAULT_NS } from 'appConstants/translationNamespaces';
import { ProfileIcon } from './ProfileIcon';
import { AccountOptions } from './AccountOptions';

const ProfileContainer = styled.div`
  display: flex;
`;

const setVisibility = (props: { IsVisible: boolean }) => {
  if (props.IsVisible) return 'opacity: 1';
  return 'opacity: 0';
};
const ChevronContainer = styled.div`
  display: flex;
  align-items: center;
  width: 14.3px;
  height: auto;
  svg {
    width: 100%;
  }
  ${setVisibility};
  @media ${deviceMedia.mobile} {
    display: none;
  }
`;

const AccountComponent = (props: { t: TFunction; className?: string; supportPageRoute?: string }) => {
  const { className, supportPageRoute, t } = props;
  const [isDropDownOpen, setDropDownState] = useState(false);
  const [user, setUser] = useState({ firstName: '', lastName: '' });
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const closeDropDown = (e: Event) => {
    if (wrapperRef && wrapperRef.current && !wrapperRef.current.contains(e.target as Node)) {
      setDropDownState(false);
    }
  };

  const onProfileIconClick = (): void => {
    setDropDownState(!isDropDownOpen);
    if (!isDropDownOpen) {
      googleAnalytics.events.openAccount();
    }
  };

  const i18n = {
    welcomeLabel: t('welcomeLabel', 'Welcome!'),
    logOutModalHeader: t('logOutModalHeader', 'LOG OUT?'),
    logOutModalBody: t(
      'logOutModalBody',
      'Are you sure you want to log out? You will need to log back in to access Analytics.'
    ),
    logOutModalOkButtonText: t('logOutModalOkButtonText', 'Log Out'),
    logOutModalCancelButtonText: t('logOutModalCancelButtonText', 'Cancel'),
    logOutLinkText: t('logOutLinkText', 'Log Out'),
    contactSupportLabel: t('contactSupportLabel', 'Contact Support')
  };

  useEffect(() => {
    try {
      const storedUser: string | null = localStorage.getItem(USER_NAME_LOCAL_STORAGE_KEY);
      if (storedUser) setUser(JSON.parse(storedUser));
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    if (isDropDownOpen) return bindDocumentClickOrTouchListener(closeDropDown);
  }, [isDropDownOpen]);

  const { firstName = '', lastName = '' } = user;

  const firstLetter = get(firstName, '[0]', '');
  const lastLetter = get(lastName, '[0]', '');

  const isUserNameIsNotEmpty = !!firstName || !!lastName;

  return (
    <div data-test-id="my-account" ref={wrapperRef} className={className} role="presentation">
      <ProfileContainer>
        <ProfileIcon
          IsActive={isDropDownOpen}
          onClick={onProfileIconClick}
          firstLetter={firstLetter}
          lastLetter={lastLetter}
          isUserNameIsNotEmpty={isUserNameIsNotEmpty}
        />
        <ChevronContainer IsVisible={isDropDownOpen} data-test-id="down-chevron">
          <ChevronComponent />
        </ChevronContainer>
      </ProfileContainer>
      {isDropDownOpen && (
        <AccountOptions
          firstName={firstName}
          lastName={lastName}
          firstLetter={firstLetter}
          lastLetter={lastLetter}
          isUserNameIsNotEmpty={isUserNameIsNotEmpty}
          supportPageRoute={supportPageRoute}
          i18n={i18n}
        />
      )}
    </div>
  );
};

const AccountStyled = styled(AccountComponent)`
  position: relative;
  user-select: none;
  margin-right: -10px;
`;

export const Account = withTranslation(DEFAULT_NS)(AccountStyled);
