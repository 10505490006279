import axios, { AxiosResponse } from 'axios';
import { Store } from 'redux';
import { PostalCodes } from 'postal-codes';

import { GeocodeResponse } from 'services/googleMaps/types';
import {
  FetchOverviewGrowthRequestPayload,
  FetchTransactionsEvaluateRequestPayload,
  FetchTransactionsRequestPayload,
  TicketTiersResponse,
  TransactionsEvaluateResponse,
  TransactionsOverviewGrowth,
  TransactionsResponse,
  WeeklySalesBreakdownRequestPayload,
  WeeklySalesBreakdownResponse
} from 'store/transactions/types';
import { FetchIdentityMappingPayload, FetchIdentityMappingResponse } from 'store/merchantCentric/identityMapping/types';
import { FetchScorePayload, FetchScoreResponse } from 'store/merchantCentric/score/types';
import * as reviewsTypes from 'store/merchantCentric/reviews/types';
import { FetchReviewsSummaryPayload, FetchReviewsSummaryResponse } from 'store/merchantCentric/reviewsSummary/types';
import { FetchReviewsTrendPayload, FetchReviewsTrendResponse } from 'store/merchantCentric/reviewsTrend/types';
import {
  FetchPickedCompetitorsPayload,
  FetchPickedCompetitorsDataPayload,
  FetchPickedCompetitorsDataResponse,
  FetchCompetitorsPayload,
  CompetitorData,
  FetchInvalidCompetitorsPayload
} from 'store/merchantCentric/competitors/types';
import {
  FetchSocialPlatformsSettingsPayload,
  FetchSocialPlatformsSettingsResponse
} from 'store/merchantCentric/socialPlatformsSettings/types';
import {
  DemographicCompareResponse,
  FetchMasterCardComparePayload,
  FetchMasterCardDataPayload,
  FetchMasterCardDataResponse
} from 'store/masterCard/types';
import {
  DayBookNoteCreateData,
  DayBookNotesRequest,
  DayBookNotesResponse,
  DeleteDayBookNoteForCampaignRequest,
  DeleteDayBookNoteRequest,
  UpdateDayBookNoteRequest
} from 'store/daybook';
import { FetchWeatherRequest, WeatherData } from 'store/weather/types';
import {
  AutoAlertRequest,
  AutoAlertsStatusConfig,
  DigestStatusConfig,
  SetDigestRequest,
  SettingsConfig,
  SettingsDataResponse,
  UpdateSettingsRequest
} from 'store/settings';

import { SearchMerchantsPayload, MerchantData } from 'store/supportSearch';
import { apiGatewayActions } from 'store/apiGateway';

import { convertServerTemplates, ServerMailBox, ServerTemplate } from 'store/emailMarketing/campaignEditor';
import { TranslationsResponse } from 'services/localization/types';
import { BrandingState } from 'store/branding/types';
import { LocationFilter } from 'store/locations';
import {
  CountriesData,
  EmailMarketingCustomerSettings,
  JWTGenerationPayload,
  PublicContactForm
} from 'store/emailMarketing/settings';
import { EMCampaign, EMCampaignsDataResponse } from 'store/emailMarketing/campaigns';
import { PageViewRequestPayload } from 'store/pageViewLogs/types';
import {
  CRMStatusResponse,
  PromoSettingsRequest,
  PromoSettingsResponse,
  PromosRequest,
  PromosResponse,
  SavePromosRequest,
  MPLPayload,
  SavePromosResponse,
  LoyaltyRequest,
  RewardsLoyaltyData,
  UpdateLoyaltyCoreProgramRequest,
  UpdateStoreboardingPayload
} from 'store/rewards/types';
import { SaveAutomationTemplateRequest, SaveAutomationTemplateResponse } from 'store/emailMarketing/automation';
import { EventName, SaveTemplatePayload, CRMAutomationTemplate } from 'store/emailMarketing/crmAutomation';
import { FetchMerchantDataComparePayload } from 'store/merchantCentric/compare/types';
import { InMemoryCache } from 'services/cache/inMemoryCache';
import TaskQueue from 'services/taskQueue';
import { PdfRequest } from 'store/pdf';
import {
  UberallCategory,
  UberallIdentityResponse,
  UberallSignupDataRequest,
  UberallRepliesResponse,
  UberallStatisticsResponse,
  UberallLocationInfo,
  UberallReview,
  UberallActiveDirectories,
  UberallDisconnectResponse,
  UberallDisconnectData
} from 'store/uberall/apiTypes';
import {
  GetListingTokenInfoPayload,
  GetListingTokenInfoResponse,
  GetVisibilityPageLinkPayload,
  GetVisibilityPageLinkResponse
} from 'store/merchantCentric/facebookConnect/types';
import { ContactMemberType } from 'components/EmailMarketing/createCampaign/types';
import { PDFOptions } from 'store/pdfPuppeteer/types';
import { variablesService } from 'services/variables';
import { payments } from './payments';

const queue = new TaskQueue();

export const gatewayClient = axios.create({
  baseURL: '/',
  withCredentials: true
});
const postalCodesService = new PostalCodes(window);

export const cacheLayer = new InMemoryCache(gatewayClient, postalCodesService);

export const ApiGateway = {
  getCSRFToken: (): Promise<{ csrfToken: string }> => gatewayClient.post('/api/v1/csrf/getCSRFToken'),

  async requestWithCSRFToken<T>(url: string, payload: any, headers?: any): Promise<AxiosResponse<T>> {
    const asyncTask = async () => {
      const { csrfToken } = await ApiGateway.getCSRFToken();
      const response = await gatewayClient.post(url, payload, {
        headers: Object.assign({ 'x-csrf-token': csrfToken }, headers)
      });
      return response;
    };
    return this.wrapAsyncTaskToPromise(asyncTask);
  },

  async putRequestWithCSRFToken<T>(url: string, payload: any): Promise<AxiosResponse<T>> {
    const asyncTask = async () => {
      const { csrfToken } = await ApiGateway.getCSRFToken();
      const response = await gatewayClient.put(url, payload, { headers: { 'x-csrf-token': csrfToken } });
      return response;
    };

    return this.wrapAsyncTaskToPromise(asyncTask);
  },

  async deleteRequestWithCSRFToken<T>(url: string): Promise<AxiosResponse<T>> {
    const asyncTask = async () => {
      const { csrfToken } = await ApiGateway.getCSRFToken();
      const response = await gatewayClient.delete(url, { headers: { 'x-csrf-token': csrfToken } });
      return response;
    };

    return this.wrapAsyncTaskToPromise(asyncTask);
  },

  wrapAsyncTaskToPromise: (asyncTask: () => {}): Promise<any> =>
    new Promise((resolve, reject) =>
      queue
        .add(asyncTask)
        .on('done', result => resolve(result))
        .on('error', e => reject(e))
    ),

  authDashboard: (query: string): any =>
    gatewayClient.get(`/api/v1/dashboard${query ? `${query}&` : '?'}externalui=true`),
  dwhLogin: (): any => gatewayClient.get('/api/v1/dwh/login'),
  fetchBrandingData: (): Promise<BrandingState> => gatewayClient.get('api/v1/branding'),
  fetchTranslations: (): Promise<TranslationsResponse> => gatewayClient.get('api/v1/branding/getTranslations'),
  fetchSettingsData: (): Promise<SettingsDataResponse> => gatewayClient.get('api/v1/appsettings'),
  fetchTransactionsEvaluate: (
    payload: FetchTransactionsEvaluateRequestPayload
  ): Promise<AxiosResponse<TransactionsEvaluateResponse>> =>
    gatewayClient.post('api/v1/transactions/evaluate', {
      ...payload
    }),
  fetchTransactions: (
    payload: FetchTransactionsRequestPayload,
    features: string[]
  ): Promise<AxiosResponse<TransactionsResponse>> =>
    gatewayClient.post('api/v1/transactions', {
      ...payload,
      features
    }),
  fetchTransactionsOverviewGrowth: (
    payload: FetchOverviewGrowthRequestPayload
  ): Promise<AxiosResponse<TransactionsOverviewGrowth>> =>
    gatewayClient.post('api/v1/transactions/insightsGrowth', payload),
  fetchTicketTiers: (payload: FetchTransactionsRequestPayload): Promise<AxiosResponse<TicketTiersResponse>> =>
    gatewayClient.post('api/v1/transactions/chartsTicketTiers', payload),
  fetchWeeklySalesBreakdown: (
    payload: WeeklySalesBreakdownRequestPayload
  ): Promise<AxiosResponse<WeeklySalesBreakdownResponse>> =>
    gatewayClient.post('api/v1/transactions/salesBreakdown', payload),
  fetchWeather: (payload: FetchWeatherRequest): Promise<AxiosResponse<WeatherData[]>> =>
    gatewayClient.get(
      `${variablesService.getItem('weatherHost')}?zip=${payload.zip}&startDate=${payload.startTime}&endDate=${
        payload.endTime
      }${payload.units ? `&units=${payload.units}` : ''}${payload.country ? `&country=${payload.country}` : ''}`,
      { headers: { 'x-api-key': variablesService.getItem('weatherKey') }, withCredentials: false }
    ),
  fetchIdentityMapping: (
    payload: FetchIdentityMappingPayload
  ): Promise<AxiosResponse<FetchIdentityMappingResponse>> => {
    // eslint-disable-next-line max-len
    const queryString = `?merchantSequenceKey=${payload.merchantSequenceKey}&merchantNumber=${payload.merchantNumber}&isEnterprise=${payload.isEnterprise}&serviceName=merchant_centric`;
    return cacheLayer.performApiCall('get', `/api/v2/identityMapping${queryString}`);
  },
  fetchSocialPlatformsSettings: (
    payload: FetchSocialPlatformsSettingsPayload
  ): Promise<AxiosResponse<FetchSocialPlatformsSettingsResponse>> =>
    cacheLayer.performApiCall('post', '/api/v1/socialReputation', payload),
  fetchMarketingScore: (payload: FetchScorePayload): Promise<AxiosResponse<FetchScoreResponse>> =>
    cacheLayer.performApiCall('post', '/api/v1/socialReputation', payload),
  fetchMerchantReviews: (
    payload: reviewsTypes.FetchReviewsPayload
  ): Promise<AxiosResponse<reviewsTypes.FetchReviewsResponse>> =>
    cacheLayer.performApiCall('post', '/api/v1/socialReputation', payload),
  fetchMerchantReviewsSummary: (
    payload: FetchReviewsSummaryPayload
  ): Promise<AxiosResponse<FetchReviewsSummaryResponse>> =>
    cacheLayer.performApiCall('post', '/api/v1/socialReputation', payload),
  fetchMerchantReviewsTrend: (payload: FetchReviewsTrendPayload): Promise<AxiosResponse<FetchReviewsTrendResponse>> =>
    cacheLayer.performApiCall('post', '/api/v1/socialReputation', payload),
  fetchPickedCompetitors: (
    payload: FetchPickedCompetitorsPayload
  ): Promise<AxiosResponse<FetchPickedCompetitorsDataResponse>> =>
    cacheLayer.performApiCall('post', '/api/v1/socialReputation', payload),
  fetchPickedCompetitorsData: (
    payload: FetchPickedCompetitorsDataPayload
  ): Promise<AxiosResponse<FetchPickedCompetitorsDataResponse>> =>
    cacheLayer.performApiCall('post', '/api/v1/socialReputation', payload),
  pickCompetitors: (payload: {
    merchantNumber: string;
    mcUID: string;
    competitors: string[];
    mcServiceMethodName: 'pickCompetitors';
  }) => gatewayClient.post('/api/v1/socialReputation', payload),
  deleteCompetitors: (payload: { mcUID: string; competitors: string[]; mcServiceMethodName: 'deleteCompetitors' }) =>
    gatewayClient.post('/api/v1/socialReputation', payload),
  fetchCompetitors: (payload: FetchCompetitorsPayload): Promise<AxiosResponse<CompetitorData[]>> =>
    cacheLayer.performApiCall('post', '/api/v1/socialReputation', payload),
  fetchInvalidCompetitors: (payload: FetchInvalidCompetitorsPayload): Promise<AxiosResponse<string[][]>> =>
    cacheLayer.performApiCall('post', '/api/v1/socialReputation', payload),
  fetchCompetitorReviews: (payload: any): Promise<AxiosResponse<any>> =>
    cacheLayer.performApiCall('post', '/api/v1/socialReputation', payload),
  fetchMasterCardData: async (
    payload: FetchMasterCardDataPayload,
    isErrorPage: boolean
  ): Promise<AxiosResponse<FetchMasterCardDataResponse>> =>
    cacheLayer.performApiCall('post', '/api/proxy/mastercard/data', payload, isErrorPage),
  fetchMasterCardCompareData: async (
    payload: FetchMasterCardComparePayload
  ): Promise<AxiosResponse<DemographicCompareResponse>> =>
    cacheLayer.performApiCall('post', 'api/proxy/mastercard/data/compare', payload),
  fetchMarketingScoreCompareData: async (payload: FetchMerchantDataComparePayload): Promise<AxiosResponse<any>> =>
    cacheLayer.performApiCall('post', '/api/v1/socialReputation', payload),
  fetchGeocode: (url: string): Promise<GeocodeResponse> =>
    cacheLayer.performApiCall('get', url, { withCredentials: false }),
  connectToSocialAccount: (
    payload: reviewsTypes.ConnectToSocialAccountPayload
  ): Promise<reviewsTypes.ConnectToSocialAccountResponse> => gatewayClient.post('/api/v1/socialReputation', payload),

  getSuggestedReply: (
    payload: reviewsTypes.GetSuggestedReplyPayload
  ): Promise<reviewsTypes.GetSuggestedReplyResponse> => gatewayClient.post('/api/v1/socialReputation', payload),

  getSocialProcessingStatus: (
    payload: reviewsTypes.GetSocialProcessingStatusPayload
  ): Promise<reviewsTypes.GetSocialProcessingStatusResponse> => gatewayClient.post('/api/v1/socialReputation', payload),

  postReplyToSocial: (
    payload: reviewsTypes.PostReplyToSocialPayload
  ): Promise<reviewsTypes.PostReplyToSocialResponse> => gatewayClient.post('/api/v1/socialReputation', payload),

  insertReply: (payload: reviewsTypes.InsertReplyPayload): Promise<any> =>
    gatewayClient.post('/api/v1/socialReputation', payload),

  getVisibilityPageLink: (payload: GetVisibilityPageLinkPayload): Promise<GetVisibilityPageLinkResponse> =>
    gatewayClient.post('/api/v1/socialReputation', payload),

  getListingTokenInfo: (payload: GetListingTokenInfoPayload): Promise<GetListingTokenInfoResponse> =>
    gatewayClient.post('/api/v1/socialReputation', payload),

  getDayBookNotes: (payload: DayBookNotesRequest): Promise<AxiosResponse<DayBookNotesResponse>> =>
    gatewayClient.post('/api/v1/daybooknote/listByMskAndDatesRange', payload),

  createDayBookNote: (payload: DayBookNoteCreateData): Promise<AxiosResponse<DayBookNotesResponse>> =>
    ApiGateway.requestWithCSRFToken('/api/v1/daybooknote/create', payload),

  updateDayBookNote: (payload: UpdateDayBookNoteRequest): Promise<AxiosResponse<DayBookNotesResponse>> =>
    ApiGateway.putRequestWithCSRFToken(`/api/v1/daybooknote/update/${payload.messageId}`, payload),

  deleteDayBookNote: (payload: DeleteDayBookNoteRequest): Promise<AxiosResponse<any>> =>
    ApiGateway.deleteRequestWithCSRFToken(`/api/v1/daybooknote/destroy/${payload.messageId}`),

  deleteDayBookNoteByCampaignId: (payload: DeleteDayBookNoteForCampaignRequest): Promise<AxiosResponse<any>> =>
    ApiGateway.deleteRequestWithCSRFToken(`/api/v1/daybooknote/destroyByCampaignId/${payload.campaignId}`),

  fetchStaticTemplates: (): Promise<AxiosResponse<any>> =>
    gatewayClient.post('/api/v1/emailMarketing/proxy', { endpoint: '/messaging-templates/list/static' }),

  fetchCustomerTemplatesBriefList: (): Promise<AxiosResponse<any>> =>
    gatewayClient.post('/api/v1/emailMarketing/proxy', { endpoint: '/messaging-templates/list/custom' }),

  fetchCustomerTemplatesPreviews: (payload: { idList: string[] }): Promise<AxiosResponse<any>> =>
    gatewayClient.post('/api/v1/emailMarketing/proxy', {
      endpoint: '/messaging-templates/previews',
      postPayload: payload
    }),

  saveTemplate: (payload: any): Promise<AxiosResponse<any>> =>
    gatewayClient.post('/api/v1/emailMarketing/proxy', {
      endpoint: '/messaging-templates/save/custom',
      postPayload: payload
    }),

  deleteCustomerTemplate: (payload: string): Promise<AxiosResponse<any>> =>
    gatewayClient.post('/api/v1/emailMarketing/proxy', {
      endpoint: '/messaging-templates/delete/custom',
      postPayload: { id: payload }
    }),

  selectCustomerTemplate: async (payload: string): Promise<ServerTemplate[]> => {
    const data: ServerTemplate[] = await gatewayClient.post('/api/v1/emailMarketing/proxy', {
      endpoint: '/messaging-templates/get/custom',
      postPayload: { id: payload }
    });

    return convertServerTemplates(data);
  },

  fetchContactsCount: (payload: {
    locations: string[];
    includeContactsWithoutLocations: boolean;
    memberTypes?: ContactMemberType[];
  }): Promise<any> =>
    gatewayClient.post('/api/v1/emailMarketing/proxy', { endpoint: '/contacts/count', postPayload: payload }),

  fetchContacts: async (payload: any): Promise<any> =>
    gatewayClient.post('/api/v1/emailMarketing/proxy', { endpoint: '/contacts/list', postPayload: payload }),

  fetchUnsubscribed: async (payload: any): Promise<any> =>
    gatewayClient.post('/api/v1/emailMarketing/proxy', {
      endpoint: '/contacts/list/unsubscribed',
      postPayload: payload
    }),

  fetchBounced: async (payload: any): Promise<any> =>
    gatewayClient.post('/api/v1/emailMarketing/proxy', {
      endpoint: '/contacts/list/bounced',
      postPayload: payload
    }),

  checkDeletionJobStatus: async (jobId: string): Promise<AxiosResponse<any>> =>
    gatewayClient.post('/api/v1/emailMarketing/proxy', {
      endpoint: '/contacts/delete/progress',
      postPayload: { deletionJobId: jobId }
    }),

  createContact: (
    firstName: string,
    lastName: string,
    email: string,
    birthday: string,
    location?: string
  ): Promise<AxiosResponse<any>> =>
    gatewayClient.post('/api/v1/emailMarketing/proxy', {
      endpoint: '/contacts/add',
      postPayload: {
        FirstName: firstName,
        LastName: lastName,
        Email: email,
        Birthday: birthday,
        Location: location || '',
        id: 0
      }
    }),

  updateContact: (
    firstName: string,
    lastName: string,
    email: string,
    id: any,
    birthday: string,
    location?: string
  ): Promise<AxiosResponse<any>> =>
    gatewayClient.post('/api/v1/emailMarketing/proxy', {
      endpoint: '/contacts/update',
      postPayload: {
        FirstName: firstName,
        LastName: lastName,
        Email: email,
        Birthday: birthday,
        Location: location || '',
        id
      }
    }),

  deleteList: (
    isCheckedAllMode: boolean,
    list: number[],
    totalCount: number,
    locations: string[],
    includeContactsWithoutLocations: boolean
  ): Promise<AxiosResponse<any>> =>
    gatewayClient.post('/api/v1/emailMarketing/proxy', {
      endpoint: '/contacts/delete',
      postPayload: {
        isCheckedAllMode,
        list,
        totalCount,
        locations,
        includeContactsWithoutLocations
      }
    }),

  saveAgreement: (payload: any): Promise<AxiosResponse<any>> =>
    gatewayClient.post('/api/v1/emailMarketing/proxy', { endpoint: '/legal/agreement/save', postPayload: payload }),

  sendTestCampaign: (payload: any): Promise<AxiosResponse<any>> =>
    gatewayClient.post('/api/v1/emailMarketing/proxy', { endpoint: '/campaigns/test-campaign', postPayload: payload }),

  createCampaign: (payload: any): Promise<AxiosResponse<any>> =>
    gatewayClient.post('/api/v1/emailMarketing/proxy', { endpoint: '/campaigns/create', postPayload: payload }),

  getCampaign: (campaignId: number): Promise<AxiosResponse<EMCampaignsDataResponse>> =>
    gatewayClient.post('/api/v1/emailMarketing/proxy', { endpoint: '/campaigns/get', postPayload: { campaignId } }),

  uploadImage: (payload: any): any =>
    gatewayClient.post('/api/v1/emailMarketing/proxy?endpoint=/images/upload', payload, {
      headers: { 'Content-Type': 'multipart/form-data' }
    }),

  importContacts: (payload: any, location: string | number, ageLimit: number): any =>
    gatewayClient.post(
      `/api/v1/emailMarketing/proxy?endpoint=/contacts/import&getPayload={"location":"${location}","ageLimit":"${ageLimit}"}`,
      payload,
      { headers: { 'Content-Type': 'multipart/form-data', Location: location, AgeLimit: ageLimit } }
    ),

  checkImportStatus: (payload: any): any =>
    gatewayClient.post('/api/v1/emailMarketing/proxy', {
      endpoint: '/contacts/import/status',
      postPayload: { jobNo: payload }
    }),

  saveToAndroidPay: (payload: any): Promise<AxiosResponse<any>> =>
    gatewayClient.post('/api/v1/emailMarketing/proxy', { endpoint: '/google-pay/offer/create', postPayload: payload }),

  searchMerchants: (payload: SearchMerchantsPayload): Promise<AxiosResponse<MerchantData[]>> =>
    gatewayClient.post('/api/v1/supportsearch', payload),

  getJWTForMerchantList: (payload: { merchantSequenceKeyList: string[] }) =>
    gatewayClient.post('/api/v1/supportsearch/getJWTLinkForMerchantSequenceKeyList', payload),

  setDigestData: (payload: SetDigestRequest): Promise<AxiosResponse<DigestStatusConfig>> =>
    ApiGateway.requestWithCSRFToken('/api/v1/digest/setDigestData', payload),

  updateSettings: (payload: UpdateSettingsRequest): Promise<AxiosResponse<SettingsConfig>> =>
    ApiGateway.requestWithCSRFToken('/api/v1/settings/update', payload),

  setAutoAlertStatus: (payload: AutoAlertRequest): Promise<AxiosResponse<AutoAlertsStatusConfig>> =>
    ApiGateway.requestWithCSRFToken('/api/v1/autoAlerts/setStatus', payload),

  fetchLocationsSearchData: (): Promise<AxiosResponse<any>> => gatewayClient.post('/api/v1/locationssearch/getData'),

  saveLocationFilter: (payload: LocationFilter): Promise<AxiosResponse<{ ok: boolean }>> =>
    ApiGateway.requestWithCSRFToken('/api/v1/locationssearch/addFilter', payload),

  deleteLocationFilter: (name: string): Promise<AxiosResponse<{ ok: boolean }>> =>
    ApiGateway.requestWithCSRFToken('/api/v1/locationssearch/removeFilter', { name }),

  addRecentSearch: (term: string): Promise<AxiosResponse<{ ok: boolean }>> =>
    ApiGateway.requestWithCSRFToken('/api/v1/locationssearch/addRecentSearch', { term }),

  getCountriesData: (countryID: number): Promise<AxiosResponse<CountriesData>> =>
    gatewayClient.post('/api/v1/emailMarketing/proxy', { endpoint: '/regions/info', postPayload: { countryID } }),

  getCustomerSettings: (): Promise<AxiosResponse<EmailMarketingCustomerSettings>> =>
    gatewayClient.post('/api/v1/emailMarketing/proxy', { endpoint: '/user/settings/view', postPayload: {} }),

  updateCustomerSettings: (
    data: EmailMarketingCustomerSettings
  ): Promise<AxiosResponse<EmailMarketingCustomerSettings>> =>
    gatewayClient.post('/api/v1/emailMarketing/proxy', { endpoint: '/user/settings/save', postPayload: { data } }),

  fetchPublicContactForm: (): Promise<AxiosResponse<PublicContactForm>> =>
    gatewayClient.post('/api/v1/emailMarketing/proxy', { endpoint: '/user/get-public-contact-form' }),

  fetchEmCampaigns: (userTimezone: string): Promise<AxiosResponse<EMCampaign[]>> =>
    gatewayClient.post('/api/v1/emailMarketing/proxy', { endpoint: '/campaigns/list', postPayload: { userTimezone } }),

  deleteEmCampaign: (id: number): Promise<AxiosResponse<EMCampaign>> =>
    gatewayClient.post('/api/v1/emailMarketing/proxy', { endpoint: '/campaigns/delete', postPayload: { id } }),

  checkIfTemplateLinkedToMessage: (id: number | string): Promise<AxiosResponse<any>> =>
    gatewayClient.post('/api/v1/emailMarketing/proxy', {
      endpoint: '/messaging-templates/check-if-template-linked-to-companies',
      postPayload: { id }
    }),

  viewEmCampaignDetails: (id: number, status?: number): Promise<AxiosResponse<EMCampaign>> =>
    gatewayClient.post('/api/v1/emailMarketing/proxy', { endpoint: '/campaigns/stats', postPayload: { id, status } }),

  getRewardsPromos: (payload: PromosRequest): Promise<AxiosResponse<PromosResponse>> =>
    gatewayClient.post('/api/v1/reward/getPromos', { payload }),

  getRewardsPromoSettings: (payload: PromoSettingsRequest): Promise<AxiosResponse<PromoSettingsResponse>> =>
    gatewayClient.post('/api/v1/reward/getPromo', { payload }),

  getRewardsActiveCoreProgram: (payload: any): Promise<any> =>
    gatewayClient.post('/api/v1/reward/getActiveCoreProgram'),

  saveRewardsPromos: (payload: SavePromosRequest): Promise<AxiosResponse<SavePromosResponse>> =>
    gatewayClient.post('/api/v1/emailMarketing/proxy', { endpoint: '/reward-promos/set', postPayload: payload }),

  getCRMRewardsStatus: (): Promise<AxiosResponse<CRMStatusResponse>> =>
    gatewayClient.post('/api/v1/emailMarketing/proxy', { endpoint: '/reward-core/status' }),

  fetchAutomationTemplates: (): Promise<AxiosResponse<any>> =>
    gatewayClient.post('/api/v1/emailMarketing/proxy', { endpoint: '/reward-templates/list' }),

  fetchAutomationStatuses: (): Promise<AxiosResponse<any>> =>
    gatewayClient.post('/api/v1/emailMarketing/proxy', { endpoint: '/reward-campaigns/get-statuses' }),

  getRewardsLoyaltyData: (payload: LoyaltyRequest): Promise<AxiosResponse<RewardsLoyaltyData>> =>
    gatewayClient.post('/api/v1/ApiChartsProxy', { ...payload }),

  getAutomationCoreStatus: (): Promise<AxiosResponse<any>> =>
    gatewayClient.post('/api/v1/emailMarketing/proxy', { endpoint: '/reward-core/is-active' }),

  saveAutomationTemplate: (
    payload: SaveAutomationTemplateRequest
  ): Promise<AxiosResponse<SaveAutomationTemplateResponse>> =>
    gatewayClient.post('/api/v1/emailMarketing/proxy', { endpoint: '/reward-templates/set', postPayload: payload }),

  getAutomationTemplate: (payload: string): Promise<AxiosResponse<any>> =>
    gatewayClient.post('/api/v1/emailMarketing/proxy', {
      endpoint: '/reward-templates/get',
      postPayload: { id: payload }
    }),

  createAutomationCampaign: (payload: any): Promise<AxiosResponse<any>> =>
    gatewayClient.post('/api/v1/emailMarketing/proxy', { endpoint: '/reward-campaigns/set', postPayload: payload }),

  updateSessionTimeout: (): Promise<AxiosResponse<{ result: string }>> =>
    gatewayClient.post('/api/v2/status/sessionInfo'),

  crmAutomationStatus: (): Promise<AxiosResponse<{ result: string }>> =>
    gatewayClient.post('/api/v1/emailMarketing/proxy', { endpoint: '/automation/status' }),

  crmAutomationTurnOn: (payload: EventName): Promise<AxiosResponse<{ result: string }>> =>
    gatewayClient.post('/api/v1/emailMarketing/proxy', {
      endpoint: '/automation/campaign/turnOn',
      postPayload: { eventName: payload }
    }),

  crmAutomationUpdateCampaignTemplate: (payload: EventName): Promise<AxiosResponse<{ result: string }>> =>
    gatewayClient.post('/api/v1/emailMarketing/proxy', {
      endpoint: '/automation/campaign/updateTemplate',
      postPayload: { eventName: payload }
    }),

  crmAutomationTurnOff: (payload: EventName): Promise<AxiosResponse<{ result: string }>> =>
    gatewayClient.post('/api/v1/emailMarketing/proxy', {
      endpoint: '/automation/campaign/turnOff',
      postPayload: { eventName: payload }
    }),

  crmAutomationFetchTemplates: (): Promise<AxiosResponse<CRMAutomationTemplate<ServerMailBox>[]>> =>
    gatewayClient.post('/api/v1/emailMarketing/proxy', { endpoint: '/automation/template/list' }),

  crmAutomationSaveTemplate: (payload: {
    AutomatedCampaignTypeName: string;
    data: SaveTemplatePayload;
  }): Promise<AxiosResponse<void>> =>
    gatewayClient.post('/api/v1/emailMarketing/proxy', { endpoint: '/automation/template/save', postPayload: payload }),

  sendCRMTemplatesForCoreLoyaltyUpdate: (payload: UpdateLoyaltyCoreProgramRequest): Promise<AxiosResponse<{}>> =>
    gatewayClient.post('/api/v1/emailMarketing/proxy', {
      endpoint: '/reward-core/loyalty-update',
      postPayload: payload
    }),

  getPDF: (payload: PdfRequest): Promise<Blob> =>
    gatewayClient.post('/customerInsightsLineChart/getPdfByPayload', payload, { responseType: 'blob' }),

  getPdfForPage: (payload: PdfRequest): Promise<Blob> =>
    gatewayClient.post('/api/v1/pdf/getPdfForPage', payload, { responseType: 'blob' }),

  generatePdfFromPuppeteer: (payload: { html: string; options?: PDFOptions }): Promise<AxiosResponse<Blob>> =>
    gatewayClient.post(
      '/api/v1/pdf/puppeteerProxy',
      {
        path: '/api/pdf/fromHtml',
        method: 'POST',
        payload,
        withBuffer: true
      },
      { responseType: 'blob' }
    ),

  getTerms: (payload: { locale: string; type: string; withPersonalData: boolean }): Promise<AxiosResponse<any>> =>
    gatewayClient.post('/api/v1/branding/getTermsAndConditions', payload),

  agreeTerms: (payload: { dataProvider: string }): Promise<AxiosResponse<any>> =>
    gatewayClient.post('/api/v1/termsAndConditions/alxAgreements', { ...payload, isAccepted: true }),

  createLog: (payload: PageViewRequestPayload): Promise<AxiosResponse<{}>> =>
    ApiGateway.requestWithCSRFToken('/api/v1/log/create', payload),

  generateJWT: (payload: JWTGenerationPayload): Promise<AxiosResponse<string>> =>
    gatewayClient.post('/api/v1/auth/generateJwt', payload),

  notifyMPL: (payload: MPLPayload): Promise<AxiosResponse<{}>> =>
    gatewayClient.post('/api/v1/MPL/coreLoyaltyUpdateNotification', payload),

  getEMAndLoyaltyCancellation: (): Promise<AxiosResponse<{}>> =>
    gatewayClient.post('/api/v1/MPL/getEMAndLoyaltyCancellation'),

  updateStoreboarding: (payload: UpdateStoreboardingPayload): Promise<AxiosResponse<{ result: string }>> =>
    gatewayClient.post('/api/v1/Storeboarding/updateStore', payload),

  fetchUberallDirectoriesConfig: (): Promise<AxiosResponse<UberallActiveDirectories>> =>
    cacheLayer.performApiCall('get', `/api/proxy/reputation/api/uberall/directories`),
  fetchUberallIdentityMapping: (id: string): Promise<AxiosResponse<UberallIdentityResponse>> =>
    cacheLayer.performApiCall('get', `/api/proxy/reputation/api/uberall/location/identityMapping/${id}`),
  fetchUberallStatistic: (id: number): Promise<AxiosResponse<UberallStatisticsResponse>> =>
    gatewayClient.get(`/api/proxy/reputation/api/uberall/location/${id}/statistic`),
  uberallSignup: (data: UberallSignupDataRequest): Promise<AxiosResponse<UberallIdentityResponse>> =>
    gatewayClient.post('/api/proxy/reputation/api/uberall/location/', data),
  editLocation: (id: number, data: UberallSignupDataRequest): Promise<AxiosResponse<UberallLocationInfo>> =>
    gatewayClient.post(`/api/proxy/reputation/api/uberall/location/${id}`, data),
  uberallSearchCategories: (query: string, lng = 'en'): Promise<UberallCategory[]> =>
    cacheLayer.performApiCall('get', `/api/proxy/reputation/api/uberall/categories?query=${query}&lng=${lng}`),
  fetchUberallReplies: (reviews: string): Promise<AxiosResponse<UberallRepliesResponse>> =>
    cacheLayer.performApiCall('get', `/api/proxy/reputation/api/uberall/reviews/replies`, {
      params: { reviews }
    }),
  sendUberallReply: ({ reviewId, reply }: { reviewId: number; reply: string }): Promise<AxiosResponse<UberallReview>> =>
    gatewayClient.post(`/api/proxy/reputation/api/uberall/reviews/${reviewId}/reply`, {
      reply
    }),
  fetchLocationInfo: (id: number): Promise<AxiosResponse<UberallLocationInfo>> =>
    gatewayClient.get(`/api/proxy/reputation/api/uberall/location/${id}`),
  disconnectUberallDirectory: ({
    uberallId,
    directory
  }: UberallDisconnectData): Promise<AxiosResponse<UberallDisconnectResponse>> =>
    gatewayClient.delete(`/api/proxy/reputation/api/uberall/location/${uberallId}/disconnect/${directory}`),

  payments
};

gatewayClient.interceptors.response.use((response: any): any => response.data);

let responseInterceptor: any = null;

export const setupResponseInterceptor = (onError: any): any => {
  if (responseInterceptor !== null) {
    gatewayClient.interceptors.response.eject(responseInterceptor);
  }

  responseInterceptor = gatewayClient.interceptors.response.use(undefined, onError);
};

export const onNotAuthorizedError =
  (store: Store): any =>
  (axiosResponse: any): any => {
    const {
      response: { status, data }
    } = axiosResponse;

    store.dispatch(apiGatewayActions.requestError(status, data));
    return Promise.reject(axiosResponse);
  };

export const setupNotAuthorizedResponseInterceptor = (store: Store): any => {
  setupResponseInterceptor(onNotAuthorizedError(store));
};

export const onAuthorizedError =
  (store: Store): any =>
  (axiosResponse: any) => {
    const {
      response: { status, data }
    } = axiosResponse;

    if (status === 403) {
      store.dispatch(apiGatewayActions.requestError(status, data));
      return Promise.reject(axiosResponse);
    }

    return Promise.reject(axiosResponse);
  };

export const setupAuthorizedResponseInterceptor = (store: Store): any => {
  setupResponseInterceptor(onAuthorizedError(store));
};
