import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { detect } from 'detect-browser';
import { getColor } from 'layout/theme';
import { deviceMedia } from 'appConstants';

const browser = detect();

const WidgetWrapper = styled.div<{ isGrey?: boolean; isHideBorder?: boolean }>`
  height: 100%;
  ${props => (!props.isHideBorder ? `border: 1px solid ${getColor(props, 'blueGrey')};` : '')};
  padding: 20px 30px 20px;

  background: #fff;
  position: relative;

  @media ${deviceMedia.mobile} {
    border-left-width: 0;
    border-right-width: 0;
    padding-left: 10px;
    padding-right: 10px;
  }

  ${({ isGrey, ...props }) =>
    isGrey
      ? `
    color: ${getColor(props, 'mockedGrey')};
    background: rgba(255, 255, 255, 0.6);
    *:not(.__react_component_tooltip):not(.ignoreMock) {
      color: ${getColor(props, 'mockedGrey')} !important;
    }
    svg {
      fill: ${getColor(props, 'mockedGrey')} !important;
      filter: grayscale(100%);
      text {
        fill: ${getColor(props, 'mockedGrey')} !important;
      }
    }
    img {
      filter: grayscale(100%);
      opacity: 0.7;
    }
    [data-test-id="MoMYoY-block"] svg {
      filter: none;
      #ICONS {
        fill: ${getColor(props, 'mockedGrey')} !important;
        stroke: ${getColor(props, 'mockedGrey')} !important;
      }
    }
    button {
      pointer-events: none;
      &.button-selected {
        background-color: ${getColor(props, 'grey')};
        border-color: ${getColor(props, 'grey')};
      }
    }
  `
      : ''};
`;

const SVGWithGrayedFilter = styled.svg`
  height: 0;
  width: 0;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  z-index: -1;
  left: -9999px;
`;

const matchSVGFilterWithElements = (
  wrapperRef: React.RefObject<HTMLDivElement>,
  FILTER_ID: string,
  isGrey?: boolean
): void => {
  if (!(wrapperRef && wrapperRef.current)) return;
  const groupsToBeGrayed = wrapperRef.current.querySelectorAll('svg > g');
  groupsToBeGrayed.forEach(g => {
    if (!isGrey) {
      g.removeAttribute('filter');
      return;
    }
    g.setAttribute('filter', `url(#${FILTER_ID})`);
  });
};

const renderCrossBrowserSVGGrayedFilter = (FILTER_ID: string): JSX.Element => (
  <SVGWithGrayedFilter className="grayed-filter">
    <defs>
      <filter id={FILTER_ID}>
        <feComposite in="SourceGraphic" in2="SourceGraphic" operator="arithmetic" k1="0" k2="1" k3="0" k4="0" />
        <feColorMatrix type="saturate" values="0" />
      </filter>
    </defs>
  </SVGWithGrayedFilter>
);

interface Props {
  isGrey?: boolean;
  isHideBorder?: boolean;
  className?: string;
  children?: string | null | React.ReactNode | React.ReactNode[];
}

export default ({ isGrey, isHideBorder, className, children, ...rest }: Props) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const FILTER_ID = 'grayscale';
  const isIE = browser && browser.name === 'ie';
  useEffect(() => {
    if (isIE) {
      matchSVGFilterWithElements(wrapperRef, FILTER_ID, isGrey);
    }
  });
  return (
    <WidgetWrapper className={className} isGrey={isGrey} ref={wrapperRef} {...rest} isHideBorder={isHideBorder}>
      {children}
      {isGrey && isIE && renderCrossBrowserSVGGrayedFilter(FILTER_ID)}
    </WidgetWrapper>
  );
};
