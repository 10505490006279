import React, { ReactElement } from 'react';
import { TextBox as MailBoxTextBoxData } from '../../../store/emailMarketing/emailTemplateEditor';
import { MailBoxProps } from '../types';

export const Text = (props: MailBoxProps<MailBoxTextBoxData>): ReactElement => {
  const { boxData, styles = {} } = props;

  const { color = '' } = styles;
  const { text = '' } = boxData;

  const ownStyles = { color, padding: '3px' };

  /* eslint-disable react/no-danger */
  return <div style={ownStyles} dangerouslySetInnerHTML={{ __html: text }} />;
};
