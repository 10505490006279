import { ActionType, createReducer } from 'typesafe-actions';
import { TransactionsTrendState } from './types';
import { transactionsTrendActions } from './actions';

const initialState: TransactionsTrendState = {
  loading: false,
  error: false,
  data: null
};

export const transactionsTrendReducer = createReducer<
  TransactionsTrendState,
  ActionType<typeof transactionsTrendActions>
>(initialState)
  .handleAction(transactionsTrendActions.fetchTransactionsTrend.request, state => ({
    ...state,
    loading: true,
    error: false
  }))
  .handleAction(transactionsTrendActions.fetchTransactionsTrend.success, (state, action) => ({
    ...state,
    loading: false,
    error: false,
    data: action.payload
  }))
  .handleAction(transactionsTrendActions.fetchTransactionsTrend.failure, () => ({ ...initialState, error: true }))
  .handleAction(transactionsTrendActions.resetTransactionsTrend, () => initialState);
