import React from 'react';
import { DEFAULT_NS } from 'appConstants/translationNamespaces';
import { useTranslation } from 'react-i18next';
import { ErrorPageProps } from './ErrorPageWrapper';
import { ErrorPageTemplate } from './ErrorPageTemplate';
import { Link } from './styles';

export const Error404Component = ({ homeUrl, errorUrl: { sessionExpirationRedirectURL } }: ErrorPageProps) => {
  const { t } = useTranslation(DEFAULT_NS);

  const link = {
    title: t('page404HomeLink', 'Return Home'),
    href: sessionExpirationRedirectURL || homeUrl
  };
  return (
    <ErrorPageTemplate
      renderTitle={() => t('page404Title', 'Uh-oh! Page Not Found')}
      renderDescription={() => (
        <>
          <p>{t('page404Description', 'The link you followed has moved, or does not exist')}</p>
          <Link>
            <a href={link.href}>{link.title}</a>
          </Link>
        </>
      )}
    />
  );
};
