import styled from 'styled-components';
import React, { useEffect, useRef, useState } from 'react';
import { getColor } from '../layout/theme';
import TextInputWithValidation from './TextInputWithValidation';
import { Button } from './Button';
import { deviceMedia } from '../appConstants';
import { bindDocumentClickOrTouchListener } from '../services/utils';

const Container = styled.div`
  z-index: 10;
  position: absolute;
  top: 100%;
  right: 0;
  background: #fff;
  width: 350px;
  max-width: 100%;
  margin-top: 3px;
  box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.5);
  @media ${deviceMedia.mobile} {
    margin-right: 3px;
    width: 320px;
  }
`;
Container.displayName = 'Container';

export const Heading = styled.div`
  color: ${props => getColor(props, 'color1')};
  font-weight: bold;
  padding: 10px 15px;
  border-bottom: 1px solid ${props => getColor(props, 'blueGrey')};
`;
Heading.displayName = 'Heading';

const Content = styled.div`
  padding: 10px 15px;
`;

export const Footer = styled.div`
  background: ${props => getColor(props, 'lightBlue')};
  border-top: 1px solid ${props => getColor(props, 'blueGrey')};
  padding: 10px 15px;
  display: flex;
  justify-content: flex-end;
`;
Footer.displayName = 'Footer';

export const InputLabel = styled.div`
  text-transform: uppercase;
  font-weight: bold;
  color: ${props => getColor(props, 'mediumGrey')};
  margin-bottom: 5px;
`;
InputLabel.displayName = 'InputLabel';

type PopUpWithInputFieldTranslations = {
  [key in 'title' | 'inputLabel' | 'inputPlaceholder' | 'primaryBtn' | 'secondaryBtn']: string;
};

interface Props {
  shown: boolean;
  onApply: (name: string) => void;
  onCancel: () => void;
  validators?: string[];
  translations: PopUpWithInputFieldTranslations;
}

export const PopUpWithInputField = (props: Props) => {
  const { translations, shown, onApply, onCancel, validators = [] } = props;
  const containerEl = useRef<HTMLDivElement>(null);
  const [name, setName] = useState<string>('');

  const cancelHandler = () => {
    setName('');
    onCancel();
  };

  const documentClickHandler = (e: MouseEvent) => {
    if (containerEl && containerEl.current && !containerEl.current.contains(e.target as Node)) {
      cancelHandler();
    }
  };

  useEffect(() => {
    if (shown) return bindDocumentClickOrTouchListener(documentClickHandler);
    return () => {
      setName('');
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shown]);

  const onChangeHandler = (value: string) => setName(value.trimStart());

  return shown ? (
    <Container ref={containerEl} data-test-id="popup-with-input-field">
      <Heading data-test-id="popup-title">{translations.title}</Heading>
      <Content data-test-id="content">
        <InputLabel data-test-id="input-field-title">{translations.inputLabel}</InputLabel>
        <TextInputWithValidation
          data-test-id="input-field"
          value={name}
          placeholder={translations.inputPlaceholder}
          onChange={onChangeHandler}
          validation={validators}
        />
      </Content>
      <Footer data-test-id="buttons">
        <Button onClick={cancelHandler} data-test-id="cancel">
          {translations.secondaryBtn}
        </Button>
        <Button
          disabled={!name.length}
          primary
          onClick={() => {
            onApply(name);
          }}
          data-test-id="save"
        >
          {translations.primaryBtn}
        </Button>
      </Footer>
    </Container>
  ) : null;
};
