import { createAction, createAsyncAction } from 'typesafe-actions';
import * as types from './types';

const fetchDataActions = createAsyncAction('@rfm/fetch', '@rfm/fetch/success', '@rfm/fetch/error')<
  void,
  types.FetchRFMDataActionSuccessPayload,
  void
>();

const setRange = createAction('@rfm/dateRange/set', action => (range: number) => action(range));

export const rfmActions = {
  fetchData: fetchDataActions,
  setRange
};
