import { createAsyncAction, createAction } from 'typesafe-actions';
import { ReviewsTrendPeriod } from './types';

const fetchReviewsTrend = createAsyncAction(
  '@merchantCentric/reviewsTrend/fetch',
  '@merchantCentric/reviewsTrend/success',
  '@merchantCentric/reviewsTrend/error'
)<void, ReviewsTrendPeriod[], Error>();

const resetReviewsTrend = createAction('@merchantCentric/reviewsTrend/reset', action => () => action());

export const reviewsTrendActions = {
  fetchReviewsTrend,
  resetReviewsTrend
};
