import styled from 'styled-components';
import React from 'react';
import { ellipsisText } from 'layout/mixins';
import { UserLocation } from 'store/settings';
import { getColor } from 'layout/theme';
import { deviceMedia } from 'appConstants';
import { Checkbox } from '../Checkbox';

const DivWithTitle = ({ text, ...rest }: { text: string }) => (
  <div {...rest} title={text}>
    {text}
  </div>
);

const LocationContainer = styled.div<{ Disabled: boolean }>`
  display: flex;
  margin: -10px -11px;
  padding: 10px 11px;
  ${props =>
    props.Disabled
      ? `
    position: relative;
    opacity: .7;
    cursor: not-allowed;
    &:before{
      content: '';
      display: block;
      background: ${getColor(props, 'blueGrey')};
      position: absolute;
      top: 0px;
      bottom: 0px;
      left: 0px;
      right: 0px;
      z-index: -1;
    }
  `
      : ''};
`;

const CheckboxWrapper = styled.div`
  padding-top: 2px;
`;

const ContentWrapper = styled.div<{ CheckboxWidth: number }>`
  display: flex;
  flex: 1;
  padding-left: 5px;
  max-width: calc(100% - ${props => props.CheckboxWidth}px);
  & > * {
    padding: 0 5px;
    width: 50%;
    max-width: 50%;
    & > * {
      width: 100%;
      ${ellipsisText};
      &:first-child {
        font-weight: bold;
      }
    }
  }

  @media ${deviceMedia.mobile} {
    flex-wrap: wrap;
    font-size: 0.85em;
    & > * {
      width: 100%;
      max-width: 100%;
      margin-bottom: 3px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

interface Props {
  item: UserLocation;
  onClick: (id: string) => void;
  checkSelectedFn: (item: UserLocation) => boolean;
  disabled?: boolean;
  countryCode?: string;
}

const splitAt = (stringToSplit: string, ...indices: number[]): string[] =>
  [0, ...indices].map((n, i, m) => stringToSplit.slice(n, m[i + 1]));

const addDashToZipAtIndex = (zip: string, index: number): string => splitAt(zip, index).join('-');

const formatUSZip = (zip: string): string => {
  const MAX_ZIP_LENGTH = 9;
  const DEFAULT_ZIP_LENGTH = 5;
  const zipLength = zip.length;

  if (zipLength > DEFAULT_ZIP_LENGTH && zipLength < MAX_ZIP_LENGTH) {
    return zip.substring(0, DEFAULT_ZIP_LENGTH);
  }
  if (zipLength > DEFAULT_ZIP_LENGTH && zipLength <= MAX_ZIP_LENGTH) {
    return addDashToZipAtIndex(zip, DEFAULT_ZIP_LENGTH);
  }
  if (zipLength > MAX_ZIP_LENGTH) {
    return addDashToZipAtIndex(zip.substring(0, MAX_ZIP_LENGTH), DEFAULT_ZIP_LENGTH);
  }
  return zip;
};

const checkZip = (zip: string, countryCode?: string): string => {
  const onlyNumbers = /^\d+$/;
  if (countryCode === 'US' && onlyNumbers.test(zip)) {
    return formatUSZip(zip);
  }
  return zip;
};

const getItemLocationString = (item: UserLocation, countryCode?: string): string =>
  `${item.city}, ${item.state}, ${checkZip(item.zip, countryCode)}`;

export const LocationItem = ({ item, onClick, checkSelectedFn, disabled, countryCode }: Props): JSX.Element => {
  const stopPropagation = (e: React.SyntheticEvent): void => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  };
  const checkboxSize = 16;

  return (
    <LocationContainer
      Disabled={!!disabled}
      onClick={e => {
        stopPropagation(e);
        if (!disabled) {
          onClick(item.merchant_sequence_key);
        }
      }}
    >
      <CheckboxWrapper onClick={stopPropagation} data-test-id="checkbox">
        <Checkbox
          disabled={!!disabled}
          onChange={() => {
            if (!disabled) {
              onClick(item.merchant_sequence_key);
            }
          }}
          checked={checkSelectedFn(item)}
          borderWidth={1}
          size={checkboxSize}
        />
      </CheckboxWrapper>
      <ContentWrapper CheckboxWidth={checkboxSize}>
        <div>
          <DivWithTitle text={item.merchant_name} data-test-id="merchant-name" />
          <DivWithTitle text={item.merchant_number} data-test-id="merchant-number" />
        </div>
        <div>
          <DivWithTitle text={item.address_1} data-test-id="merchant-address" />
          <DivWithTitle text={getItemLocationString(item, countryCode)} data-test-id="merchant-city-region-zip" />
        </div>
      </ContentWrapper>
    </LocationContainer>
  );
};
