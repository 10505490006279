import React, { useState, useEffect, useRef, ReactElement } from 'react';
import moment, { Moment } from 'moment';
import { chunk, isEmpty } from 'lodash';
import { DEFAULT_NS } from 'appConstants/translationNamespaces';
import { useTranslation } from 'react-i18next';
import { dateFormatter } from 'services/dateFormatter';
import { bindDocumentClickOrTouchListener } from 'services/utils';
import { Button } from 'shared-components/Button';
import { useWindowWidth } from 'services/effects/useWindowWidth';
import { laptopSWidth, YEAR_MONTH_FORMAT } from 'appConstants';
import {
  Container,
  Dropdown,
  Footer,
  Toggle,
  Content,
  StyledCalendarIcon,
  YearSelector,
  MonthSelector,
  MonthsRow,
  MonthItem,
  NavButton
} from './components';

interface PropTypes {
  appliedPeriod: string;
  lmiPeriods: string[];
  onApply: (period: string) => void;
  onCancel?: () => void;
}

type Translations = {
  [key in 'thru' | 'applyBtnText' | 'cancelBtnText' | 'periodVisualizerDataUnavailable']: string;
};

const LEFT_DROPDOWN_POSITION = 'left:0;';
const RIGHT_DROPDOWN_POSITION = 'right:0';

type DropDownPosition = typeof LEFT_DROPDOWN_POSITION | typeof RIGHT_DROPDOWN_POSITION;

const formatLabelDate = (date: Moment): string =>
  window.innerWidth <= laptopSWidth ? dateFormatter.dateMonthShortYear(date) : dateFormatter.dateMonthYear(date);

const renderLabel = (period: string | null, i18n: { [key: string]: string }) => (isOpen: boolean) => {
  if (!period) return <span>{i18n.periodVisualizerDataUnavailable}</span>;

  const startDateString = formatLabelDate(moment(period).startOf('month'));
  const endDateString = formatLabelDate(
    moment().format(YEAR_MONTH_FORMAT) === period ? moment() : moment(period).endOf('month')
  );
  return (
    <>
      <StyledCalendarIcon data-test-id="month-picker-icon" />
      <span data-test-id="month-picker-value">
        <strong>{startDateString}</strong>
        {startDateString !== endDateString && <span>{i18n.thru}</span>}
        {startDateString !== endDateString && <strong>{endDateString}</strong>}
        <i
          data-test-id={`month-picker-toggle-${isOpen ? 'up' : 'down'}`}
          className={`fas fa-chevron-${isOpen ? 'up' : 'down'}`}
        />
      </span>
    </>
  );
};

export const MonthPicker = (props: PropTypes): ReactElement => {
  const { appliedPeriod, lmiPeriods, onApply: onApplyHandler, onCancel: onCancelHandler = () => {} } = props;
  const { t } = useTranslation(DEFAULT_NS);
  const translations: Translations = {
    thru: t('thru', 'thru'),
    applyBtnText: t('apply', 'Apply'),
    cancelBtnText: t('labelCancel', 'Cancel'),
    periodVisualizerDataUnavailable: t('PeriodVisualizerDataUnavailable', 'Data Unavailable')
  };

  const [windowWidth] = useWindowWidth();

  const [period, setPeriod] = useState<string>(appliedPeriod);
  const [year, setYear] = useState<number>(+moment(period || undefined).format('YYYY'));
  const [dropdownPosition, setDropdownPosition] = useState<DropDownPosition>(LEFT_DROPDOWN_POSITION);
  const [isOpen, toggle] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const toggleRef = useRef<HTMLButtonElement | null>(null);
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const onApply = (newPeriod: string): void => {
    onApplyHandler(newPeriod);
    toggle(false);
  };

  const onCancel = (): void => {
    toggle(false);
    onCancelHandler();
  };

  const closeDropDown = (e: Event): void => {
    if (wrapperRef && wrapperRef.current && !wrapperRef.current.contains(e.target as Node)) onCancel();
  };

  useEffect(() => {
    if (isOpen) {
      setPeriod(appliedPeriod);
      setYear(+moment(appliedPeriod).format('YYYY'));
    }
  }, [isOpen, appliedPeriod]);

  useEffect(() => {
    if (containerRef && containerRef.current && toggleRef && toggleRef.current) {
      const dropDownEl = containerRef.current;
      const toggleEl = toggleRef.current;
      const nextPosition =
        window.innerWidth - toggleEl.getBoundingClientRect().left > dropDownEl.offsetWidth
          ? LEFT_DROPDOWN_POSITION
          : RIGHT_DROPDOWN_POSITION;

      if (nextPosition !== dropdownPosition) setDropdownPosition(nextPosition);
    }
  }, [windowWidth, isOpen]);

  useEffect(() => {
    if (isOpen) return bindDocumentClickOrTouchListener(closeDropDown);
  }, [isOpen]);

  const isPreviousYearEnabled = lmiPeriods.some(key => {
    const startOfYear = moment(year, 'YYYY').startOf('year');
    return moment(key, YEAR_MONTH_FORMAT).isBefore(startOfYear);
  });
  const isNextYearEnabled = lmiPeriods.some(key => {
    const endOfYear = moment(year, 'YYYY').endOf('year');
    return moment(key, YEAR_MONTH_FORMAT).isAfter(endOfYear);
  });

  const months = moment.monthsShort().map((month: string, index) => {
    const monthNumber = index >= 9 ? index + 1 : `0${index + 1}`;
    return {
      month,
      period: `${year}-${monthNumber}`
    };
  });

  return (
    <Container ref={wrapperRef} data-test-id="month-picker-main-container">
      <Toggle
        ref={toggleRef}
        onClick={() => {
          if (appliedPeriod) toggle(!isOpen);
        }}
        data-test-id="month-picker-toggle"
      >
        {renderLabel(appliedPeriod, translations)(isOpen)}
      </Toggle>
      {isOpen && (
        <Dropdown
          positionStyles={dropdownPosition}
          ref={containerRef}
          data-test-id="month-picker-expanded-view-container"
        >
          <Content>
            <YearSelector data-test-id="month-picker-year-selector">
              <NavButton
                disabled={!isPreviousYearEnabled}
                onClick={() => setYear(+moment(year, 'YYYY').subtract(1, 'y').format('YYYY'))}
              >
                <i className="fas fa-chevron-left" data-test-id="month-picker-chevron-left" />
              </NavButton>
              <span>{year}</span>
              <NavButton
                disabled={!isNextYearEnabled}
                onClick={() => setYear(+moment(year, 'YYYY').add(1, 'y').format('YYYY'))}
              >
                <i className="fas fa-chevron-right" data-test-id="month-picker-chevron-right" />
              </NavButton>
            </YearSelector>
            <MonthSelector>
              {chunk(months, 4).map(monthsRow => (
                <MonthsRow key={`month-row-${monthsRow.map(item => item.period).join('-')}`}>
                  {monthsRow.map(item => {
                    const disabledMonth = !lmiPeriods || !lmiPeriods.includes(item.period);
                    const isPrimaryMonth = period === item.period;

                    const dataTestPrimaryMonth = isPrimaryMonth ? 'primary' : 'secondary';
                    const dataDisabledMonth = disabledMonth ? 'disabled' : 'enabled';
                    return (
                      <MonthItem
                        data-test-id={`month-item-${dataTestPrimaryMonth}-${dataDisabledMonth}`}
                        key={item.period}
                        primary={isPrimaryMonth}
                        secondary={!isPrimaryMonth}
                        disabled={disabledMonth}
                        onClick={() => setPeriod(item.period)}
                      >
                        {item.month}
                      </MonthItem>
                    );
                  })}
                </MonthsRow>
              ))}
            </MonthSelector>
          </Content>
          <Footer>
            <Button data-test-id="month-picker-cancel-button" secondary onClick={onCancel}>
              {translations.cancelBtnText}
            </Button>
            <Button
              data-test-id="month-picker-confirm-button"
              primary
              disabled={isEmpty(lmiPeriods)}
              onClick={() => onApply(period)}
            >
              {translations.applyBtnText}
            </Button>
          </Footer>
        </Dropdown>
      )}
    </Container>
  );
};
