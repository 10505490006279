import { Link } from 'react-router-dom';
import React from 'react';

interface LogoProps {
  imageSrc: string;
  imageAltText: string;
  imgClassName?: string;
}

interface OwnProps extends LogoProps {
  linkTo?: string;
  linkClassName?: string;
  onlyImg?: boolean;
}

const Logo = ({ imageSrc, imageAltText, imgClassName }: LogoProps): JSX.Element => (
  <img className={imgClassName} src={imageSrc} alt={imageAltText} />
);

export const LinkedLogo = ({
  linkTo = '/',
  linkClassName,
  imageSrc,
  imageAltText,
  onlyImg,
  imgClassName
}: OwnProps): JSX.Element =>
  onlyImg ? (
    <span className={linkClassName}>
      <Logo imageAltText={imageAltText} imageSrc={imageSrc} imgClassName={imgClassName} />
    </span>
  ) : (
    <Link to={linkTo} className={linkClassName}>
      <Logo imageAltText={imageAltText} imageSrc={imageSrc} imgClassName={imgClassName} />
    </Link>
  );
