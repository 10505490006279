import React, { useState, MouseEvent, ReactNode } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';
import { deviceMedia, USER_NAME_LOCAL_STORAGE_KEY } from 'appConstants';
import Modal from 'shared-components/Modal';
import { googleAnalytics } from 'services/googleAnalytics';
import { useSelector } from 'react-redux';
import { getBrandingLayout } from 'store/branding';
import { getColor } from '../theme';
import { bold, resetButtonStyles, textRegular } from '../mixins';
import { ProfileIcon } from './ProfileIcon';

const prepareName = (name: string) =>
  name
    .split(' ')
    .map((word: string) => {
      // 22 is the maximum symbols amount that can be rendered in account dropdown
      if (word.length > 22) {
        return `<div style="word-break: break-all;">${word}</div>`;
      }
      return word;
    })
    .join(' ');

interface AccountOptionsTranslations {
  welcomeLabel: string;
  logOutModalHeader: string;
  logOutModalBody: string;
  logOutModalOkButtonText: string;
  logOutModalCancelButtonText: string;
  logOutLinkText: string;
  contactSupportLabel: string;
}

const FullName = styled.div`
  ${bold};
  color: ${props => getColor(props, 'color1')};
  width: 100%;
  text-transform: capitalize;
`;

interface Initials {
  firstLetter: string;
  lastLetter: string;
}

interface AccountOptionsProps {
  className?: string;
  firstName: string;
  lastName: string;
  isUserNameIsNotEmpty: boolean;
  i18n: AccountOptionsTranslations;
  supportPageRoute?: string;
}

const Link = ({ to, children }: { to: string; children: ReactNode }) => (
  <RouterLink to={to} target="_blank">
    {children}
  </RouterLink>
);

export const AccountOptionsComponent = (props: AccountOptionsProps & Initials) => {
  const { className, firstName, lastName, firstLetter, lastLetter, isUserNameIsNotEmpty, i18n, supportPageRoute } =
    props;

  const [logoutModalOpen, setLogoutModalState] = useState(false);
  const brandingLayout = useSelector(getBrandingLayout);

  const onLogoutClick = (): void => {
    setLogoutModalState(true);
  };

  const label = isUserNameIsNotEmpty ? prepareName(`${firstName} ${lastName}`) : i18n.welcomeLabel;
  const { isLogoutDisabled } = brandingLayout?.account || {};

  return (
    <div
      role="presentation"
      className={className}
      onClick={(e: MouseEvent) => e.nativeEvent.stopImmediatePropagation()}
      data-test-id="expanded-view"
    >
      <FirstAccountOptionsItem data-test-id="full-name-block">
        <ProfileIcon firstLetter={firstLetter} lastLetter={lastLetter} isUserNameIsNotEmpty={isUserNameIsNotEmpty} />
        <FullName data-test-id="full-name-label" dangerouslySetInnerHTML={{ __html: label }} />
      </FirstAccountOptionsItem>
      {!!supportPageRoute && (
        <AccountOptionsItem data-test-id="support-search-block">
          <Link to={supportPageRoute}>{i18n.contactSupportLabel}</Link>
        </AccountOptionsItem>
      )}
      {!isLogoutDisabled && (
        <AccountOptionsItem data-test-id="logout-block">
          <button data-test-id="logout-button" type="button" onClick={onLogoutClick}>
            {i18n.logOutLinkText}
          </button>
          <Modal
            isOpen={logoutModalOpen}
            modalHeader={i18n.logOutModalHeader}
            onRequestClose={({ response }): void => {
              setLogoutModalState(false);
              if (response) {
                console.log('Closing Insights App');
                localStorage.removeItem(USER_NAME_LOCAL_STORAGE_KEY);
                googleAnalytics.events.portal.open('Logout');
                document.location.replace('/api/v1/auth/logout');
              }
            }}
            buttonSecondaryText={i18n.logOutModalCancelButtonText}
            buttonPrimaryText={i18n.logOutModalOkButtonText}
            buttonSecondarySettings={{
              secondary: true
            }}
            buttonPrimarySettings={{
              primary: true
            }}
          >
            <span>{i18n.logOutModalBody}</span>
          </Modal>
        </AccountOptionsItem>
      )}
    </div>
  );
};

export const AccountOptions = styled(AccountOptionsComponent)`
  /* IE 11 hack start */
  display: flex;
  flex-direction: column;
  /* IE 11 hack end */

  position: absolute;
  z-index: 101;
  right: 24px;
  margin-top: 10px;
  background-color: ${props => getColor(props, 'white')};
  width: 193px;
  @media ${deviceMedia.mobile} {
    margin-right: -14px;
  }
`;

export const AccountOptionsItem = styled.div`
  display: flex;
  align-items: center;
  max-heigh: 38px;
  padding: 11px 10px 10px;
  border: 1px solid ${props => getColor(props, 'mediumGrey')};
  border-top: none;

  &: first-child {
    border-top: 1px solid ${props => getColor(props, 'mediumGrey')};
  }

  & > * {
    ${textRegular};
  }

  & button,
  a {
    ${resetButtonStyles};
    ${bold};
    text-decoration: none;
    color: ${props => getColor(props, 'color1')};
    &:disabled {
      cursor: not-allowed;
      color: ${props => getColor(props, 'grey')};
    }
  }
`;

const FirstAccountOptionsItem = styled(AccountOptionsItem)`
  padding: 10px;
  max-height: 60px;
`;
