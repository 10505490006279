import { capitalize } from 'lodash';
import i18n from 'i18n';
import { ACCOUNT_NAMES_WITH_TEASER_CONDITION, benchmarkRoot } from 'appConstants';
import { translationsPageDefaultTitle } from 'appConstants/translationsPageDefaultTitle';
import { isTeaser } from 'store/navigation';
import { AppState } from 'store/rootReducer';
import { isRewardsEnabled } from 'store/rewards/selectors';
import { PageResponseObject, PagesList } from './layout';
import { isFeatureOrComponentEnabled } from './selectors';
import { EMAIL_MARKETING_AUTOMATIONS_SUB_PAGE, EMAIL_MARKETING_CRM_AUTOMATIONS_SUB_PAGE } from '../constants';

export function translatePagesName(pages: PageResponseObject[]): PagesList {
  return pages.map((page: PageResponseObject) => {
    const defaultPageTitle = translationsPageDefaultTitle[page.translationKey] || undefined;
    const titleValue = page.doNotCapitalizeTitle
      ? (i18n.t(page.translationKey, defaultPageTitle) as string)
      : (i18n.t(page.translationKey, defaultPageTitle) as string).split(' ').map(capitalize).join(' ');
    return {
      ...page,
      title: titleValue,
      subPages: page.subPages && Array.isArray(page.subPages) ? translatePagesName(page.subPages) : []
    };
  });
}

const isAnalyticsRootOccursFewTimes = (merchantPrograms: string[][], pageId: string) =>
  merchantPrograms.filter(child => child.includes(pageId)).length > 1;

const isAnalyticsCompareNeeded = (merchantPrograms: string[][], locationsLength: number, pageId: string = '-1') =>
  locationsLength > 1 && isAnalyticsRootOccursFewTimes(merchantPrograms, pageId);

export function filterDependantSubPages(
  state: AppState,
  pages: PagesList,
  merchantPrograms: string[][],
  locations: string[]
): PagesList {
  const pagesState: AppState = { ...state, appMenu: { ...state.appMenu, pages } };
  return pages.map(page => ({
    ...page,
    subPages: page.subPages.filter(subPage => {
      // Do not render automation sub menu if rewards program is off
      if (subPage.pageName === EMAIL_MARKETING_AUTOMATIONS_SUB_PAGE) {
        return isFeatureOrComponentEnabled(
          pagesState,
          'rewards_root',
          'ExtraFeatures',
          'emailMarketingMenu_automation'
        );
      }

      if (subPage.pageName === EMAIL_MARKETING_CRM_AUTOMATIONS_SUB_PAGE) {
        return !isRewardsEnabled(pagesState);
      }

      if (subPage.pageName.includes('compare')) {
        if (subPage.pageName.includes('analytics')) {
          return isAnalyticsCompareNeeded(merchantPrograms, locations.length, page.programId);
        }

        return locations.length > 1;
      }

      return true;
    })
  }));
}

export function getValidPages(accountName: string, pages: PagesList): PagesList {
  const pageIds = pages.map(page => page.pageId);
  const pageNames = pages.map(({ pageName }) => pageName);
  if (ACCOUNT_NAMES_WITH_TEASER_CONDITION.includes(accountName)) {
    return pages.filter(
      page =>
        !(isTeaser(page) && page.pageId.match(/_(customerIntelligence|ci)_root/) && pageNames.includes(benchmarkRoot))
    );
  }

  if (accountName === 'default') {
    return pages.filter(
      page =>
        !(
          isTeaser(page) &&
          page.pageId === 'hic_customerIntelligence_root' &&
          pageIds.some(pageId => ['hic_benchmark_root', 'hic_reputation_root', 'hic_ci_root'].includes(pageId))
        )
    );
  }

  return pages;
}
