/* eslint-disable */
/* Return RO Static Automations Templates */

const AlmostThereEmailData = {
  styles: {
    width: '600px',
    color: '#949599',
    backgroundColor: '#fff',
    couponBorderColor: '#fff',
    buttonsDividersColor: '#fff',
    sicailLinksColor: '#fff',
  },
  components: [
    {
      type: 'spacer',
    },
    {
      type: 'image',
      image: 'em-email-images/RO/GPro_550x300_19.+Almost+There+Template_RO.jpg',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<h1 style="font-style: italic; color: #949599;">Sunteți aproape gata!</h1>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<p style="color: #949599; font-weight: bold;">Felicitări! Sunteți la o singură vizită/articol distanță de a primi următorul premiu. Abia așteptăm sa ne întâlnim.</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const AnniversaryEmailData = {
  styles: {
    width: '600px',
    color: '#949599',
    backgroundColor: '#fff',
    couponBorderColor: '#fff',
    buttonsDividersColor: '#fff',
    sicailLinksColor: '#fff',
  },
  components: [
    {
      type: 'spacer',
    },
    {
      type: 'image',
      image: 'em-email-images/RO/GPro_550x300_21.+Happy+Anniversary+Template_RO.jpg',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<h1 style="font-style: italic; color: #949599;">Vă mulțumim pentru un an uimitor!</h1>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<p style="color: #949599; font-weight: bold;">Timpul zboară când te distrezi. Suntem atât de fericiți că faceți parte din familia noastră. Asteptăm sa vă revedem in curand!</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const RewardEmailData = {
  styles: {
    width: '600px',
    color: '#949599',
    backgroundColor: '#fff',
    couponBorderColor: '#fff',
    buttonsDividersColor: '#fff',
    sicailLinksColor: '#fff',
  },
  components: [
    {
      type: 'spacer',
    },
    {
      type: 'image',
      image: 'em-email-images/RO/GPro_550x300_22.+Reward+Template_RO.jpg',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<h1 style="font-style: italic; color: #949599;">Ați câștigat un premiu!</h1>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<p style="color: #949599; font-weight: bold;">Felicitări! Îl meritați. Asteptam sa vă revedem in curand.</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const WelcomeEmailData = {
  styles: {
    width: '600px',
    color: '#949599',
    backgroundColor: '#fff',
    couponBorderColor: '#fff',
    buttonsDividersColor: '#fff',
    sicailLinksColor: '#fff',
  },
  components: [
    {
      type: 'spacer',
    },
    {
      type: 'image',
      image: 'em-email-images/RO/GPro_550x300_18.+Welcome+Template_RO.jpg',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<h1 style="font-style: italic; color: #949599;">Mulțumim că ni te-ai alăturat!</h1>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<p style="color: #949599; font-weight: bold;">Acum veți primiți cele mai recente oferte, livrate direct în căsuța dvs. de e-mail. Abia așteptăm sa ne întâlnim!</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const WeMissYouEmailData = {
  styles: {
    width: '600px',
    color: '#949599',
    backgroundColor: '#fff',
    couponBorderColor: '#fff',
    buttonsDividersColor: '#fff',
    sicailLinksColor: '#fff',
  },
  components: [
    {
      type: 'spacer',
    },
    {
      type: 'image',
      image: 'em-email-images/RO/GPro_550x300_20.+We+Miss+You+Template_RO.jpg',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<h1 style="font-style: italic; color: #949599;">Ce mai faceți?</h1>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<p style="color: #949599; font-weight: bold;">Pare o veșnicie de când v-am văzut ultima dată. Am vrut doar să vă salutăm și sperăm că ne veți vizita din nou în curând.</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

export const roStaticTemplates = [
  {
    id: 'zgku0uwhj2q19snuj5dw',
    promoId: 'almost_reward',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticAutomationTemplateAlmostThereTitle',
    status: 'origin',
    favorites: 'false',
    components: AlmostThereEmailData.components,
    styles: AlmostThereEmailData.styles,
    thumb: 'almost-there-email-preview-ro.jpg',
  },
  {
    id: '36bnoa5uun2flyr1ysrf',
    promoId: 'anniversary',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticAutomationTemplateAnniversaryTitle',
    status: 'origin',
    favorites: 'false',
    components: AnniversaryEmailData.components,
    styles: AnniversaryEmailData.styles,
    thumb: 'anniversary-email-preview-ro.jpg',
  },
  {
    id: 'bf0lku35gdfsnsb34ihh',
    promoId: 'loyalty_reward',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticAutomationTemplateRewardTitle',
    status: 'origin',
    favorites: 'false',
    components: RewardEmailData.components,
    styles: RewardEmailData.styles,
    thumb: 'reward-email-preview-ro.jpg',
  },
  {
    id: 'xk8q2zzzl3k3x0gj6nl2',
    promoId: 'miss_you',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticAutomationTemplateWeMissYouTitle',
    status: 'origin',
    favorites: 'false',
    components: WeMissYouEmailData.components,
    styles: WeMissYouEmailData.styles,
    thumb: 'we-miss-you-email-preview-ro.jpg',
  },
  {
    id: 'm3qgggpdqc6pevn4clfi',
    promoId: 'welcome',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticAutomationTemplateWelcomeTitle',
    status: 'origin',
    favorites: 'false',
    components: WelcomeEmailData.components,
    styles: WelcomeEmailData.styles,
    thumb: 'welcome-email-preview-ro.jpg',
  },
];


