import styled from 'styled-components';
import React from 'react';
import { DEFAULT_NS } from 'appConstants/translationNamespaces';
import { useTranslation } from 'react-i18next';
import { LocationFor } from 'components/AdvancedAnalyticsEvaluate/types';
import { LocationSelectorType } from '../../store/settings/layout';
import { ToolTipWrapper } from '../Hint';
import { deviceMedia, LOCATION_SELECTOR_MULTI_TYPE } from '../../appConstants';
import { Translate } from '../Translate';

const SelectHint = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  margin: auto;
  opacity: 0.9;
  visibility: visible;
  left: 100%;
  top: 0;
  width: 200px;
  bottom: 0;
  height: 65px;
  @media ${deviceMedia.mobile} {
    left: 0;
    right: 0;
    bottom: auto;
    top: 100%;
  }
`;

interface Props {
  type?: LocationSelectorType;
  show?: boolean;
  showDefaultHint?: boolean;
  isMobile?: boolean;
  isTablet?: boolean;
  locationFor?: LocationFor;
  numberOfSelectedLocations: number;
}

export const LocationSelectorHint = ({
  type,
  show,
  showDefaultHint,
  isMobile,
  locationFor,
  isTablet,
  numberOfSelectedLocations
}: Props) => {
  const { t } = useTranslation(DEFAULT_NS);
  const testGroupHint = (
    <>
      <div data-test-id="analytics-evaluate-test-group-tooltip-text">
        {t(
          'AnalyticsEvaluateTestHint',
          'Let’s expand your search. In order to compare results, please select at least one location for the Test Group.'
        )}
      </div>
    </>
  );

  const controlGroupHint = (
    <>
      <div data-test-id="analytics-evaluate-control-group-tooltip-text">
        {t('AnalyticsEvaluateCompareHint', 'Please select at least one location to compare against the Test Group.')}
      </div>
    </>
  );

  const comparePageHint = (
    <>
      <div data-test-id="tooltip-text-row-1">{t('chooseTwoLocationsForComparison', "Let's expand your search.")}</div>
      <div data-test-id="analytics-compare-tooltip-text-row-2">
        {t('chooseOneLocationHintPart2', 'Please choose')} {t('chooseAtLeastOneLocation', 'at least')}{' '}
        {t('chooseTwoLocationsForComparisonHint', 'TWO locations for comparison')}
      </div>
    </>
  );

  const hintForSingleLocationSelector =
    numberOfSelectedLocations === 0 ? (
      <Translate i18nKey="singleLocationHintTextWithoutSelectedLocations">
        <div data-test-id="tooltip-text-row-1">Let&quot;s expand your search.</div>
        <div data-test-id="analytics-dashboard-tooltip-text-row-2">Please choose ONE location.</div>
      </Translate>
    ) : (
      <Translate i18nKey="singleLocationHintText">
        <div data-test-id="tooltip-text-row-1">Let&quot;s narrow your search.</div>
        <div data-test-id="analytics-dashboard-tooltip-text-row-2">Please choose ONE location.</div>
      </Translate>
    );

  const hintForMultiLocationSelector = (
    <Translate i18nKey="multiLocationHintText">
      <div data-test-id="tooltip-text-row-1">Let&quot;s expand your search.</div>
      <div data-test-id="analytics-dashboard-tooltip-text-row-2">Please choose more than ONE location.</div>
    </Translate>
  );

  const defaultHint =
    type === LOCATION_SELECTOR_MULTI_TYPE ? hintForMultiLocationSelector : hintForSingleLocationSelector;

  let currentHint;

  switch (locationFor) {
    case LocationFor.testGroup:
      currentHint = testGroupHint;
      break;
    case LocationFor.controlGroup:
      currentHint = controlGroupHint;
      break;
    default:
      showDefaultHint ? (currentHint = defaultHint) : (currentHint = comparePageHint);
  }

  const isFlexibleToContent = !!locationFor;
  const isLeftPosition = locationFor === LocationFor.controlGroup && !isMobile && !isTablet;
  const isMobileView = isFlexibleToContent && isTablet ? isTablet : isMobile;
  const regularPlacement = isMobileView ? 'place-bottom' : 'place-right';
  const placePosition = isLeftPosition ? 'place-left' : regularPlacement;

  return show ? (
    <ToolTipWrapper isLeftPosition={isLeftPosition} isFlexibleToContent={isFlexibleToContent}>
      <SelectHint
        data-test-id="no-selected-locations-tooltip"
        className={`__react_component_tooltip ${placePosition} type-dark `}
      >
        {currentHint}
      </SelectHint>
    </ToolTipWrapper>
  ) : null;
};
