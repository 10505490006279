import { takeLatest, call, put, select, debounce } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { setActivePage } from 'store/navigation';
import { ApiGateway } from '../../services/apiGateway';
import { pageViewActions } from './actions';
import { locationsActions } from '../locations';
import { composeDataForRequest, isLogAllowed } from './modifiers';
import { termsActions } from '../terms';
import { googleAnalytics } from '../../services/googleAnalytics';

export const LEVEL_INFO = 'info';
export const TYPE_PAGE_VIEW = 'pageView';

export function* PageViewSagas() {
  yield takeLatest(getType(setActivePage), function* () {
    try {
      const state = yield select();
      const pageViewInfo = composeDataForRequest(state);
      googleAnalytics.sendPageView(pageViewInfo.path);
    } catch (error) {
      console.error(error);
    }
  });

  yield debounce(
    1000,
    [getType(setActivePage), getType(locationsActions.search.list.success), getType(termsActions.confirmTerms)],
    function* () {
      const state = yield select();
      const pageViewInfo = composeDataForRequest(state);

      if (isLogAllowed(state, pageViewInfo)) {
        yield put(pageViewActions.sendPageViewLog.request(pageViewInfo));
      }
    }
  );

  yield takeLatest(
    getType(pageViewActions.sendPageViewLog.request),
    function* (action: ReturnType<typeof pageViewActions.sendPageViewLog.request>) {
      const { payload, merchantSequenceKeys, path } = action.payload;
      try {
        const response = yield call(ApiGateway.createLog, {
          keys: merchantSequenceKeys.join(','),
          level: LEVEL_INFO,
          payload,
          type: TYPE_PAGE_VIEW,
          path
        });

        if (response.log) {
          yield put(pageViewActions.sendPageViewLog.success(response));
        } else {
          yield put(pageViewActions.sendPageViewLog.failure(response));
        }
      } catch (e) {
        yield put(pageViewActions.sendPageViewLog.failure({ log: false }));
        console.error(e);
      }
    }
  );
}
