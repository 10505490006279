import React from 'react';
import styled from 'styled-components';
import { DEFAULT_NS } from 'appConstants/translationNamespaces';
import { useTranslation } from 'react-i18next';
import { CloseIconPure } from '../../assets/icons/close';
import { getColor } from '../../layout/theme';
import { resetButtonStyles, textMetaInfo } from '../../layout/mixins';
import { LocationFilter } from '../../store/locations';

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const ItemName = styled.div`
  color: ${props => getColor(props, 'color1')};
  font-weight: bold;
  margin-right: auto;
  cursor: pointer;
  max-width: 60%;
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover {
    text-decoration: underline;
  }
`;

const ItemMeta = styled.div`
  color: #a7a9ac;
  font-weight: bold;
  ${textMetaInfo};
`;
const RemoveBtn = styled.button`
  ${resetButtonStyles};
  display: flex;
  margin-left: 10px;
`;

interface Props {
  item: LocationFilter;
  onNameClick: (item: LocationFilter) => void;
  onRemoveClick: (item: LocationFilter) => void;
}
export const SavedFilterItem = ({ item, onRemoveClick, onNameClick }: Props) => {
  const stopPropagation = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  };
  const { t } = useTranslation(DEFAULT_NS);
  return (
    <Container data-test-id="saved-filter-item">
      <ItemName
        onClick={e => {
          stopPropagation(e);
          onNameClick(item);
        }}
        data-test-id="saved-filter-name"
      >
        <span>{item.name}</span>
      </ItemName>
      <ItemMeta data-test-id="number-of-saved-locations">
        {item.locations.length} {t('newLocationSelectorSaveFilterMerchant', 'Merchant Numbers')}
      </ItemMeta>
      <RemoveBtn
        onClick={() => {
          onRemoveClick(item);
        }}
        data-test-id="saved-filter-delete-button"
      >
        <CloseIconPure size={14} color="red" />
      </RemoveBtn>
    </Container>
  );
};
