import { createAction, createAsyncAction } from 'typesafe-actions';
import { AvailablePages, AnalyticsPageOptions, PdfCookiesTypes, GeneratePdfForPage } from './types';

export const pdfActions = {
  getDataFromCookies: createAction(
    '@pdf/getDataFromCookies',
    action => (payload: Partial<PdfCookiesTypes>) => action(payload)
  ),
  addChartInfo: createAction(
    '@pdf/addChartInfo',
    action => (payload: { options: Partial<AnalyticsPageOptions>; page: AvailablePages }) => action(payload)
  ),
  switchPdfMode: createAction('@pdf/switchPdfMode', action => (payload: boolean) => action(payload)),
  generatePdf: createAsyncAction('@pdf/generatePdf', '@pdf/generatePdf/success', '@pdf/generatePdf/error')<
    void,
    void,
    void
  >(),
  generatePdfForPage: createAsyncAction(
    '@pdf/generatePdfForPage',
    '@pdf/generatePdfForPage/success',
    '@pdf/generatePdfForPage/error'
  )<GeneratePdfForPage, void, void>()
};
