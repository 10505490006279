import { combineReducers } from 'redux';
import { connectRouter, RouterState } from 'connected-react-router';
import { History } from 'history';
import { rfmReducer, RFMState } from 'store/rfm';
import { createBrandingReducer, BrandingState } from './branding';
import { errorsReducer } from './errors/reducer';
import { ErrorsState } from './errors/types';
import { notificationsReducer, NotificationsMap } from './notifications';
import { emailMarketingReducer, EmailMarketingState } from './emailMarketing';
import { dateRangePickerReducer, DateRangePickerState } from './appDateRangePicker';
import { analyticsReducer, AnalyticsState } from './transactions';
import { MerchantCentricState } from './merchantCentric/types';
import merchantCentricReducer from './merchantCentric/reducer';
import { MasterCardState } from './masterCard/types';
import { masterCardReducer } from './masterCard/reducer';
import { weatherReducer } from './weather/store';
import { WeatherState } from './weather/types';
import { DayBookState, dayBookNoteReducer } from './daybook';
import { SupportSearchState, supportSearchReducer } from './supportSearch';
import { settingsReducer, SettingsState } from './settings';
import { apiGatewayReducer, ApiGatewayState } from './apiGateway';
import { locationSelectorReducer, LocationsSelectorState } from './locations';
import { RewardsState } from './rewards/types';
import { rewardsReducer } from './rewards/store';
import { termsReducer, TermsState } from './terms';
import { AppMenusInterface, appMenusReducer } from './navigation';
import { pdfReducer, PdfState } from './pdf';
import { helpersModeReducer, HelpersModeState } from './helpers';
import { AuthAndDeclinesState, authAndDeclinesReducer } from './authorizations&Declines';
import { declineTrendsReducer, DeclineTrendsState } from './declineTrends';
import { feeTrendsReducer, FeeTrendsState } from './feeTrends';
import { salesReducer, SalesState } from './sales';
import { refundsChargebackReducer, RefundsChargebacksState } from './refundsAndChargebacks';
import { pdfPuppeteerReducer, PdfPuppeteerState } from './pdfPuppeteer/store';
import { uberallReducer } from './uberall/reducer';
import { UberallState } from './uberall/types';

export interface AppState {
  router: RouterState;
  apiGateway: ApiGatewayState;
  branding: BrandingState;
  settings: SettingsState;
  notifications: NotificationsMap;
  appMenu: AppMenusInterface;
  dateRange: DateRangePickerState;
  locationSelector: LocationsSelectorState;
  analytics: AnalyticsState;
  merchantCentric: MerchantCentricState;
  uberall: UberallState;
  masterCard: MasterCardState;
  weather: WeatherState;
  dayBook: DayBookState;
  supportSearch: SupportSearchState;
  emailMarketing: EmailMarketingState;
  rewards: RewardsState;
  terms: TermsState;
  rfm: RFMState;
  pdf: PdfState;
  pdfPuppeteer: PdfPuppeteerState;
  helpersMode: HelpersModeState;
  authAndDeclines: AuthAndDeclinesState;
  declineTrends: DeclineTrendsState;
  feeTrends: FeeTrendsState;
  sales: SalesState;
  refundsChargebacks: RefundsChargebacksState;
  errors: ErrorsState;
}

export default (history: History, branding: BrandingState) =>
  combineReducers({
    router: connectRouter(history),
    apiGateway: apiGatewayReducer,
    branding: createBrandingReducer(branding),
    settings: settingsReducer,
    notifications: notificationsReducer,
    appMenu: appMenusReducer,
    emailMarketing: emailMarketingReducer,
    dateRange: dateRangePickerReducer,
    locationSelector: locationSelectorReducer,
    analytics: analyticsReducer,
    merchantCentric: merchantCentricReducer,
    uberall: uberallReducer,
    masterCard: masterCardReducer,
    weather: weatherReducer,
    dayBook: dayBookNoteReducer,
    supportSearch: supportSearchReducer,
    rewards: rewardsReducer,
    terms: termsReducer,
    rfm: rfmReducer,
    pdf: pdfReducer,
    pdfPuppeteer: pdfPuppeteerReducer,
    helpersMode: helpersModeReducer,
    authAndDeclines: authAndDeclinesReducer,
    declineTrends: declineTrendsReducer,
    feeTrends: feeTrendsReducer,
    sales: salesReducer,
    refundsChargebacks: refundsChargebackReducer,
    errors: errorsReducer
  });
