import { ActionType, createReducer } from 'typesafe-actions';
import { DEFAULT_SORT_ORDER, CONTACTS_PER_PAGE } from 'appConstants/emailMarketing';
import { unsubscribedContactsActions } from './actions';
import * as types from '../types';

const defaultState = {
  limit: CONTACTS_PER_PAGE,
  page: 0,
  keyword: '',
  totalCount: 0,
  overallCount: 0,
  sort: DEFAULT_SORT_ORDER,
  data: []
};

type Store = types.ContactsList;
type Actions = ActionType<typeof unsubscribedContactsActions>;

export const unsubscribedContactsReducer = createReducer<Store, Actions>(defaultState)
  .handleAction(unsubscribedContactsActions.setContactsList, (state, action) => ({
    ...state,
    data: action.payload.data
  }))
  .handleAction(unsubscribedContactsActions.fetchContacts.request, state => ({
    ...state,
    loading: true,
    fetched: true
  }))
  .handleAction(unsubscribedContactsActions.fetchContacts.success, (state, action) => ({
    ...state,
    ...action.payload,
    loading: false
  }))
  .handleAction(unsubscribedContactsActions.fetchContacts.failure, state => ({ ...state, loading: false }))
  .handleAction(unsubscribedContactsActions.setUnsubscribedSortFieldAndOrder, (state, action) => ({
    ...state,
    sort: action.payload
  }));
