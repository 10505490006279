import styled from 'styled-components';
import { getColor } from 'layout/theme';
import { resetButtonStyles } from 'layout/mixins';
import { deviceMedia } from 'appConstants';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { Button } from 'shared-components/Button';

export const StyledCalendarIcon = styled(CalendarIcon)`
  width: 15px;
  min-width: 15px;
  height: 18px;
  [fill^='#'] {
    fill: #fff;
  }
`;

export const Container = styled.div`
  position: relative;
  display: inline-block;
  @media ${deviceMedia.mobile} {
    display: block;
    width: 100%;
  }
`;

export const Dropdown = styled.div<{ positionStyles: string }>`
  border: 1px solid ${props => getColor(props, 'blueGrey')};
  position: absolute;
  top: 100%;
  z-index: 1;
  background: #fff;
  margin: 10px -10px;
  width: 330px;
  ${props => props.positionStyles};
  &:before,
  &:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid ${props => getColor(props, 'blueGrey')};
    position: absolute;
    bottom: 100%;
    margin: 0 10px;
    ${props => props.positionStyles};
  }
  &:after {
    border-bottom-color: white;
    margin-bottom: -1px;
  }
  @media ${deviceMedia.mobile} {
    width: auto;
    margin: 0 -15px;
    border-width: 0 0 1px 0;
    left: 0;
    right: 0;
    &:before,
    &:after {
      display: none;
    }
  }
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 8px;
  background: ${props => getColor(props, 'lightGrey')};
  border-top: 1px solid ${props => getColor(props, 'blueGrey')};
  > * {
    margin: 0 3px;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
`;

export const Toggle = styled.button`
  ${resetButtonStyles};
  color: #fff;
  display: flex;
  align-items: center;
  span {
    @supports (-webkit-overflow-scrolling: touch) {
      margin-bottom: -3px;
    }
  }

  strong {
    margin: 0 5px;
  }

  @media ${deviceMedia.mobile} {
    width: 100%;
    padding: 15px 0;
    justify-content: center;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  @media ${deviceMedia.mobile} {
    flex-direction: column;
  }
`;

export const YearSelector = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  color: ${props => getColor(props, 'darkGrey')};
  font-weight: bold;
  font-size: 16px;
`;

export const MonthSelector = styled.div`
  width: 100%
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const MonthsRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: no-wrap;
  @media ${deviceMedia.mobile} {
    justify-content: space-around;
  }
`;

export const MonthItem = styled(Button)`
  margin: 5px;
  padding: 8px 10px;
  font-weight: ${props => (props.primary ? 'bold' : 'normal')};
  color: ${props => getColor(props, props.secondary ? 'color1' : 'white')};
  &:disabled {
    background: ${props => getColor(props, 'lightGrey')};
    border-color: ${props => getColor(props, 'lightGrey')};
    color: ${props => getColor(props, 'grey')};
  }
`;

export const NavButton = styled(Button)`
  min-width: 0;
  padding: 0;
  margin: 0;
  &:disabled {
    background: none;
    border: none;
    color: ${props => getColor(props, 'grey')};
  }
`;
