/* eslint-disable */
/* de_DE Static Templates Data */

const AutomotiveServiceShopEmailData = {
  styles: {
    width: '600px',
    color: '#898989',
    backgroundColor: '#fff',
    couponBorderColor: '#00aeef',
    buttonsDividersColor: '#898989',
    sicailLinksColor: '#00aeef',
  },
  components: [
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_29.+Automotive+Service+Shops+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center">Kostenlose Reifeneinlagerung!</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Sie erhalten bei den meisten Reparaturen Ihr Auto noch am selben Tag zurück.</p>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Bei uns bekommen Sie einen Kostenvoranschlag ohne versteckte Kosten.</p>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Rufen Sie noch heute an, um einen Termin zu vereinbaren!</p>',
    },
    {
      type: 'coupon',
      text: '<h2 class="ql-align-center">Wenn Sie bei uns eine Handwäsche für Ihr Fahrzeug und einen Reifenwechsel buchen, lagern wir Ihre Saisonreifen kostenlos ein!</h2><p class="ql-align-center" style="font-size: 10px;">Bitte beachten Sie, dass dieses Angebot nicht mit anderen Angeboten oder Rabattaktionen kombinierbar ist. Keine Barauszahlung möglich.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const BakeryEmailData = {
  styles: {
    width: '600px',
    color: '#898989',
    backgroundColor: '#fff',
    couponBorderColor: '#00aeef',
    buttonsDividersColor: '#898989',
    sicailLinksColor: '#00aeef',
  },
  components: [
    {
      type: 'image',
      image: 'em-logos/bakery-email-logo_1498080292703.png',
    },
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_1.+Bakery+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center">10 % Rabatt auf Ihren nächsten Einkauf</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Als Dankeschön möchten wir Ihnen ein kleines Geschenk schicken. Verwenden Sie diesen Gutschein bei Ihrem nächsten Besuch in Bob\'s Bakery, wo alles vor Ort gebacken wird und nur die frischesten Zutaten verwendet werden. Kommen Sie vorbei und wählen Sie aus unserem reichhaltigen Angebot und genießen Sie frische Torten und Kuchen. Nehmen Sie unsere Köstlichkeiten mit nach Hause oder noch besser: Lehnen Sie sich zurück und genießen Sie unsere leckeren Produkte direkt bei uns! Wir freuen uns darauf, Sie bald wiederzusehen!</p>',
    },
    {
      type: 'coupon',
      text: '<h2 class="ql-align-center">10 % Rabatt auf Ihren nächsten Einkauf.</h2><p class="ql-align-center" style="font-size: 10px;">Bitte beachten Sie, dass dieses Angebot nicht mit anderen Angeboten und/oder Rabattaktionen kombinierbar ist. Keine Barauszahlung möglich.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const BarberShopEmailData = {
  styles: {
    width: '600px',
    color: '#898989',
    backgroundColor: '#fff',
    couponBorderColor: '#00aeef',
    buttonsDividersColor: '#898989',
    sicailLinksColor: '#00aeef',
  },
  components: [
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_25.+Barber+Shop+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center">Komplettes Pflegepaket jetzt mit 10 % Rabatt</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Lehnen Sie sich zurück und gönnen Sie Ihrem Haar und Ihrem Bart eine entspannte Pflege. Neben dem perfekten Schnitt genießen Sie erstklassigen Kaffee bei uns.</p>',
    },
    {
      type: 'coupon',
      text: '<h2 class="ql-align-center">Ein komplettes Pflegepaket inklusive Bartkur, jetzt mit 10 % Rabatt.</h2><p class="ql-align-center" style="font-size: 10px;">Bitte beachten Sie, dass dieses Angebot nicht mit anderen Angeboten oder Rabattaktionen kombinierbar ist. Keine Barauszahlung möglich.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const BarPubEmailData = {
  styles: {
    width: '600px',
    color: '#fff',
    backgroundColor: '#898989',
    couponBorderColor: '#fff',
    buttonsDividersColor: '#fff',
    sicailLinksColor: '#fff',
  },
  components: [
    {
      type: 'image',
      image: 'em-logos/bar-pub-email-logo_1498080596725.png',
    },
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_2.+Bar+Pub+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center">HAPPY HOUR JEDEN TAG</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center" style="font-weight: bold;">Täglich von 16:00 bis 19:00 Uhr</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Wöchentlich wechselnde Getränkeangebote.</p>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Kommen Sie vorbei und schauen Sie sich unsere täglichen Angebote an!</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'coupon',
      text: '<h4 class="ql-align-center">Happy-Hour-Artikel mit 1€-Rabatt.</h4><p class="ql-align-center">Gültig für einen Artikel.</p><br><p class="ql-align-center" style="font-size: 10px;">Einmalig pro Kunde/in einlösbar. Bitte beachten Sie, dass dieses Angebot nicht mit anderen Angeboten oder Rabattaktionen kombinierbar ist. Keine Barauszahlung möglich.</p><br>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const BeautyShopEmailData = {
  styles: {
    width: '600px',
    color: '#898989',
    backgroundColor: '#fff',
    couponBorderColor: '#00aeef',
    buttonsDividersColor: '#898989',
    sicailLinksColor: '#00aeef',
  },
  components: [
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_26.+Beauty+Shop+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center">Beeilen Sie sich und sichern Sie sich 10 % Rabatt auf exklusive Naturkosmetik</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Kommen Sie zu uns, um sich zu entspannen. Wir kümmern uns von Kopf bis Fuß um Sie.</p>',
    },
    {
      type: 'coupon',
      text: '<h2 class="ql-align-center">Nach Abschluss einer Schönheitsbehandlung in unserem Salon erhalten Sie 10 % Rabatt auf exklusive Naturkosmetik.</h2><p class="ql-align-center" style="font-size: 10px;">Bitte beachten Sie, dass dieses Angebot nicht mit anderen Angeboten oder Rabattaktionen kombinierbar ist. Keine Barauszahlung möglich.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const BurgerEmailData = {
  styles: {
    width: '600px',
    backgroundColor: '#e11b22',
    color: '#fff',
    headingsColor: '#fff',
    buttonsDividersColor: '#fff',
  },
  components: [
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_3.+Burger+Template_CZ.jpg',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">ZWEI ZUM PREIS VON EINEM. MIT JEDEM MENÜ KOMBINIERBAR.</p>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Unsere 100 % Rindfleisch-Burger mit geräuchertem Speck und frischem Gemüse sind da! Nehmen Sie ein gekühltes Bier dazu und starten Sie mit einem Lächeln in die Woche!</p>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Zeigen Sie diesen Coupon direkt unserem Personal oder drucken Sie ihn aus.</p>',
    },
    {
      type: 'coupon',
      text: '<h2 class="ql-align-center">BEIM KAUF EINES MENÜS ERHALTEN SIE EIN ZWEITES GRATIS</h2><p class="ql-align-center">(mit gleichem oder geringerem Preis)</p><p class="ql-align-center">Einmalig pro Kunde/in einlösbar. Bitte beachten Sie, dass dieses Angebot nicht mit anderen Angeboten oder Rabattaktionen kombinierbar ist. Keine Barauszahlung möglich.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const ChineseEmailData = {
  styles: {
    width: '600px',
    color: '#fff',
    backgroundColor: '#231f20',
    couponBorderColor: '#fff',
    buttonsDividersColor: '#fff',
    sicailLinksColor: '#fff',
  },
  components: [
    {
      type: 'image',
      image: 'em-logos/chinese-email-logo_1498080685909.png',
    },
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_4.+Chinese+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center" style="font-style:italic;">EINMAL FRÜHLINGSROLLEN KOSTENLOS ZU JEDER BESTELLUNG.</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">oder eine andere Vorspeise als Ersatz (2)</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Sehen Sie sich auch unsere Getränke-Angebote und unsere aktualisierte Speisekarte an.</p>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Rufen Sie uns einfach an und reservieren Sie Ihren Tisch noch heute!</p>',
    },
    {
      type: 'coupon',
      text: '<h4 class="ql-align-center">FRÜHLINGSROLLEN KOSTENLOS ZU JEDER BESTELLUNG.</h4><br><p class="ql-align-center" style="font-size: 10px;">Einmalig pro Kunde/in einlösbar. Bitte beachten Sie, dass dieses Angebot nicht mit anderen Angeboten oder Rabattaktionen kombinierbar ist. Keine Barauszahlung möglich.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const ClothingShopEmailData = {
  styles: {
    width: '600px',
    color: '#898989',
    backgroundColor: '#fff',
    couponBorderColor: '#00aeef',
    buttonsDividersColor: '#898989',
    sicailLinksColor: '#00aeef',
  },
  components: [
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_27.+Clothing+Store+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center">Entdecken Sie Ihren neuen Style</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Kommen Sie zu uns und lassen Sie sich inspirieren. Diese Woche haben wir eine Gratisaktion für Sie vorbereitet. Erhalten Sie beim Kauf von einem Produkt ein weiteres Gratis dazu.</p>',
    },
    {
      type: 'coupon',
      text: '<h2 class="ql-align-center">Bitte beachten Sie, dass dieses Angebot nicht mit anderen Angeboten oder Rabattaktionen kombinierbar ist. Keine Barauszahlung möglich.</h2>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const CoffeeEmailData = {
  styles: {
    width: '600px',
    backgroundColor: '#e6e7e8',
    color: '#000',
    headingsColor: '#000',
    buttonsDividersColor: '#000',
  },
  components: [
    {
      type: 'image',
      image: 'em-logos/top_1497255967016.png',
    },
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_5.+Coffee+Template.jpg',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<h3 class="ql-align-center">STARTEN SIE MIT UNSREM FRISCH GERÖSTETEN KAFFEE IN DEN TAG.</h3>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Es gibt keinen besseren Start in den Tag als bei uns mit einem frisch gerösteten Kaffee! Der volle und reichhaltige Geschmack zaubert Ihnen garantiert ein Lächeln aufs Gesicht. Besuchen Sie uns noch heute für eine Kostprobe. Natürlich kostenlos!</p>',
    },
    {
      type: 'coupon',
      text: '<h4 class="ql-align-center">TÄGLICH FRISCHE BACKWAREN</h4><h3 class="ql-align-center">MITTAGSANGEBOTE VON MONTAG - FREITAG</h3><p class="ql-align-center">Angebote können pro Standort variieren.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },

  ],
};

const DessertEmailData = {
  styles: {
    width: '600px',
    color: '#464646',
    backgroundColor: '#dfd',
    couponBorderColor: '#ff7d52',
    buttonsDividersColor: '#ff7d52',
    sicailLinksColor: '#464646',
  },
  components: [
    {
      type: 'image',
      image: 'em-logos/dessert-email-logo_1498080768821.png',
    },
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_6.+Dessert+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center" style="color:#ff7d52; font-style:italic;">NEUE MENÜS SIND DA!</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Der Frühling ist da und mit ihm unsere neue Karte!</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Kommen Sie diese Woche vorbei und zahlen Sie nur die Hälfte auf alle neuen Artikel in unserer Karte.</p>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Hier finden Sie leckere rosa Makronen, Karottenkuchen, Erdbeertorten und vieles mehr.</p>',
    },
    {
      type: 'coupon',
      text: '<h4 class="ql-align-center">ALLE NEUEN ARTIKEL AUS UNSERER KARTE ZUM HALBEN PREIS!</h4><p class="ql-align-center">(nur diese Woche)</p><br><p class="ql-align-center" style="font-size: 10px;">Einmalig pro Kunde/in einlösbar. Bitte beachten Sie, dass dieses Angebot nicht mit anderen Angeboten oder Rabattaktionen kombinierbar ist. Keine Barauszahlung möglich.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const DinerEmailData = {
  styles: {
    width: '600px',
    color: '#f00',
    backgroundColor: '#fff',
    couponBorderColor: '#f00',
    buttonsDividersColor: '#f00',
    sicailLinksColor: '#f00',
  },
  components: [
    {
      type: 'image',
      image: 'em-logos/diner-email-logo_1498081690316.png',
    },
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_7.+Diner+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center">10% Rabatt bei Ihren nächsten Besuch</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Kommen Sie zu uns und genießen Sie die angenehme Atmosphäre! In unserem Restaurant verzaubert Sie unser Koch mit seinen Kreationen.</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'spacer',
    },
    {
      type: 'coupon',
      text: '<h2 class="ql-align-center">10% Rabatt bei Ihren nächsten Besuch</h2><p class="ql-align-center" style="font-size: 10px;">Einmalig pro Kunde/in einlösbar. Bitte beachten Sie, dass dieses Angebot nicht mit anderen Angeboten oder Rabattaktionen kombinierbar ist. Keine Barauszahlung möglich.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const FamilyDiningEmailData = {
  styles: {
    width: '600px',
    color: '#fff',
    backgroundColor: '#fc702e',
    couponBorderColor: '#fff',
    buttonsDividersColor: '#fff',
    sicailLinksColor: '#000',
  },
  components: [
    {
      type: 'image',
      image: 'em-logos/family-dining-email-2-logo_1498081751965.png',
    },
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_8.+Family+Dining+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center" style="font-style:italic;">DIENSTAGS ESSEN KINDER GRATIS!</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Das Abendessen ist im Crosby\'s Kitchen Familienzeit. JEDEN Dienstag essen Ihre Kinder gratis beim Kauf einer beliebigen Speise für Erwachsene.</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
        text: '<p class="ql-align-center">Wir garantieren, dass Sie und Ihre Kleinen das Essen lieben werden.</p>',
    },
    {
      type: 'coupon',
        text: '<h4 class="ql-align-center">1x KOSTENLOSE KINDERMAHLZEIT!</h4><p class="ql-align-center">(beim Kauf einer beliebigen Speise für Erwachsene)</p><br><p class="ql-align-center" style="font-size: 10px;">Bitte beachten Sie, dass dieses Angebot nicht mit anderen Angeboten oder Rabattaktionen kombinierbar ist. Keine Barauszahlung möglich.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const FineDiningEmailData = {
  styles: {
    width: '600px',
    backgroundColor: '#f5f3ef',
    color: '#000',
    couponBorderColor: '#770e1f',
    buttonsDividersColor: '#770e1f',
    sicailLinksColor: '#000',
  },
  components: [
    {
      type: 'image',
      image: 'em-logos/fine-dining-email-logo_1498081793107.png',
    },
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_9.+Fine+Dining.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center" style="font-style:italic; color:#770e1f;">SIE SIND EINGELADEN!</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Kommen Sie zu uns und erleben Sie das perfekte Erlebnis-Menü, das wir exklusiv unseren treuesten Kunden anbieten.</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center" style="font-weight:bold;">75€ Verkostungsmenü</p>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">(beinhaltet ein 4-Gänge-Menü und passender Weinbegleitung)</p>',
    },
    {
      type: 'coupon',
      text: '<h4 class="ql-align-center" style="font-weight:normal;">RESERVIEREN SIE HIER, wenn Sie an diesem besonderen Event teilnehmen möchten.</h4><p class="ql-align-center" style="font-style:italic;">Beeilen Sie sich, die Anzahl der Plätze ist begrenzt!</p><br><p class="ql-align-center" style="font-size: 10px;">Bitte beachten Sie, dass dieses Angebot nicht mit anderen Angeboten oder Rabattaktionen kombinierbar ist. Keine Barauszahlung möglich.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const FloristsEmailData = {
  styles: {
    width: '600px',
    color: '#898989',
    backgroundColor: '#fff',
    couponBorderColor: '#00aeef',
    buttonsDividersColor: '#898989',
    sicailLinksColor: '#00aeef',
  },
  components: [
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_30.+Florists+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center">Der Sommer ist da!</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Wir haben unseren Laden in ein Sommerkleid gepackt! Wie zu jeder Jahreszeit haben wir unsere Räumlichkeiten so eingerichtet, dass Sie den Sommer sehen und spüren können. Lassen Sie sich inspirieren und nehmen Sie ein Stück Sommer mit.</p>',
    },
    {
      type: 'coupon',
      text: '<h2 class="ql-align-center">Einjährige Pflanzen in Töpfen nur diese Woche mit 10 % Rabatt.</h2><p class="ql-align-center" style="font-size: 10px;">Bitte beachten Sie, dass dieses Angebot nicht mit anderen Angeboten oder Rabattaktionen kombinierbar ist. Keine Barauszahlung möglich.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const FruitEmailData = {
  styles: {
    width: '600px',
    color: '#f00',
    backgroundColor: '#fff',
    couponBorderColor: '#f00',
    buttonsDividersColor: '#f00',
    sicailLinksColor: '#f00',
  },
  components: [
    {
      type: 'image',
      image: 'em-logos/fruit-email-logo_1498081891611.png',
    },
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_10.+Fruit+Template_CZ.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center">10 % auf Ihre erste Bestellung</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Unser Obst ist garantiert frisch! Holen Sie sich gesunde Snacks für sich und Ihre Freunde. Unsere Obstkörbe zaubern garantiert ein Lächeln auf jedes Gesicht.</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'spacer',
    },
    {
      type: 'coupon',
      text: '<h2 class="ql-align-center">10 % Rabatt auf die erste Bestellung.</h2><p class="ql-align-center" style="font-size: 10px;">Einmalig pro Kunde/in einlösbar. Bitte beachten Sie, dass dieses Angebot nicht mit anderen Angeboten oder Rabattaktionen kombinierbar ist. Keine Barauszahlung möglich.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const ItalianEmailData = {
  styles: {
    width: '600px',
    color: '#000',
    backgroundColor: '#fcefd8',
    couponBorderColor: '#d82c1f',
    buttonsDividersColor: '#d82c1f',
    sicailLinksColor: '#000',
  },
  components: [
    {
      type: 'image',
      image: 'em-logos/italian-email-logo_1498081923428.png',
    },
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_11.+Italian+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center" style="font-style:italic; color:#d82c1f;">BEI IHREM NÄCHSTEN BESUCH ERHALTEN SIE 10 € RABATT!</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Sie werden unsere fantastischen Gerichte lieben, sei es unsere berühmte Bolognese Pomodoro oder Penne Carbonara.</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Zeigen Sie diese E-Mail vor und erhalten Sie 10 € Rabatt auf Ihre Rechnung. </p>',
    },
    {
      type: 'coupon',
      text: '<h4 class="ql-align-center" style="color:#d82c1f;">10 € RABATT FÜR IHREN NÄCHSTEN BESUCH</h4><br><p class="ql-align-center" style="font-size: 10px;">Bitte beachten Sie, dass dieses Angebot nicht mit anderen Angeboten oder Rabattaktionen kombinierbar ist. Keine Barauszahlung möglich.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const MexicanEmailData = {
  styles: {
    width: '600px',
    color: '#fff',
    backgroundColor: '#efae2a',
    couponBorderColor: '#fff',
    buttonsDividersColor: '#fff',
    sicailLinksColor: '#fff',
  },
  components: [
    {
      type: 'image',
      image: 'em-logos/mexican-email-logo_1498082003319.png',
    },
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_12.+Mexican+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center">10 € Rabatt auf Ihren nächsten Besuch</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">"Unsere authentisch mexikanischen Gerichte werden Ihnen garantiert schmecken. Kommen Sie zu uns und genießen Sie ein gekühltes Getränk und leckere Vorspeisen zu unschlagbaren Preisen."</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'coupon',
      text: '<h2 class="ql-align-center">10 % Rabatt auf Ihren nächsten Besuch!</h2><p class="ql-align-center" style="font-size: 10px;">Bitte beachten Sie, dass dieses Angebot nicht mit anderen Angeboten oder Rabattaktionen kombinierbar ist. Keine Barauszahlung möglich.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const MoveItSportsEmailData = {
  styles: {
    width: '600px',
    color: '#000',
    backgroundColor: '#fff',
    buttonsDividersColor: '#000',
    sicailLinksColor: '#0779be',
  },
  components: [
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<h1 class="ql-align-center" style="color:#0779be; font-style:italic;">Du hast großes Potenzial.</h1>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Schauen Sie sich unsere Getränke-Angebote und unsere neue Karte an.</p>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center" style="font-style:italic; font-weight:bold;">Rufen Sie uns einfach an und reservieren Sie Ihren Tisch noch heute!</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_13.+Move+It+Sports+Template.jpg',
    },
  ],
};

const PetShopEmailData = {
  styles: {
    width: '600px',
    color: '#898989',
    backgroundColor: '#fff',
    couponBorderColor: '#00aeef',
    buttonsDividersColor: '#898989',
    sicailLinksColor: '#00aeef',
  },
  components: [
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_28.+Pet+Shop+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center">Immer eine große Auswahl!</h2>',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center">10 % Rabatt auf Ihren nächsten Einkauf</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Alles für Ihr Haustier, inklusive einer professionellen Beratung.</p>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Bei uns können Sie genau das kaufen, was Ihr Tier wirklich braucht.</p>',
    },
    {
      type: 'coupon',
      text: '<h2 class="ql-align-center">10 % Rabatt auf Ihren nächsten Einkauf.</h2><p class="ql-align-center" style="font-size: 10px;">Bitte beachten Sie, dass dieses Angebot nicht mit anderen Angeboten oder Rabattaktionen kombinierbar ist. Keine Barauszahlung möglich.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const PizzaEmailData = {
  styles: {
    width: '600px',
    color: '#000',
    backgroundColor: '#fdd85c',
    couponBorderColor: '#eb6464',
    buttonsDividersColor: '#eb6464',
    sicailLinksColor: '#000',
  },
  components: [
    {
      type: 'image',
      image: 'em-logos/pizza-email-logo_1498082059029.png',
    },
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_14.+Pizza+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center" style="font-style:italic">KAUFE EINE PIZZA UND ERHALTEN SIE DIE ZWEITE GRATIS! </h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Von Pizza Funghi oder Peperoni bis hin zu Oliven und Sardellen, Sie haben die Wahl. Bestellen Sie eine beliebige Pizza und erhalten Sie die Zweite gratis dazu!</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Zeigen Sie uns einfach diese E-Mail vor und erhalten Sie die zweite Pizza gratis. </p>',
    },
    {
      type: 'coupon',
      text: '<h3 class="ql-align-center">KAUFE EINE PIZZA UND ERHALTEN SIE DIE ZWEITE GRATIS!</h3><p class="ql-align-center">(zum gleichen oder zu einem niedrigeren Preis)</p><br><p class="ql-align-center" style="font-size: 10px;">Bitte beachten Sie, dass dieses Angebot nicht mit anderen Angeboten oder Rabattaktionen kombinierbar ist. Keine Barauszahlung möglich.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const QsrEmailData = {
  styles: {
    width: '600px',
    color: '#000',
    backgroundColor: '#fff',
    couponBorderColor: '#000',
    buttonsDividersColor: '#000',
    sicailLinksColor: '#000',
  },
  components: [
    {
      type: 'image',
      image: 'em-logos/burger_1505312326850_1505312462526.png',
    },
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_15.+QSR+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center" style="font-style:italic; color:#aa181c;">FREITAGS KOSTENLOSE POMMES</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Wir lieben Pommes. Sie lieben Pommes. Wir wetten, dass Sie KOSTENLOSE Pommes noch mehr lieben werden. </p>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Bestellen Sie einen beliebigen Burger oder ein Sandwich und erhalten Sie eine große Portion Pommes gratis!</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Beeilen Sie sich, das Angebot gilt nur im März!</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'coupon',
      text: '<h3 class="ql-align-center" style="color:#aa181c;">KOSTENLOSE GROSSE POMMES!</h3><p class="ql-align-center">(beim Kauf eines Burgers oder Sandwiches)</p><br><p class="ql-align-center" style="font-size: 10px;">Bitte beachten Sie, dass dieses Angebot nicht mit anderen Angeboten oder Rabattaktionen kombinierbar ist. Keine Barauszahlung möglich.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const SushiEmailData = {
  styles: {
    width: '600px',
    color: '#fff',
    backgroundColor: '#231f20',
    couponBorderColor: '#fff',
    buttonsDividersColor: '#fff',
    sicailLinksColor: '#fff',
  },
  components: [
    {
      type: 'image',
      image: 'em-logos/sushi-email-logo_1498082220393.png',
    },
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_16.+Sushi+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center" style="font-style:italic;">HALBER PREIS-HAPPY HOUR!</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Ausgewählte Sushi und Vorspeisen von 3,99 € bis 9,99 €!</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Sehen Sie sich auch unsere Getränke und aktualisierte Speisekarte an.</p>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Rufen Sie uns einfach an und reservieren Sie Ihren Tisch noch heute!</p>',
    },
    {
      type: 'coupon',
      text: '<h4 class="ql-align-center">KAUFEN SIE EIN SUSHI-GERICHT UND ERHALTEN SIE DAS ZWEITE ZUM HALBEN PREIS.</h4><p class="ql-align-center">(bei gleichem oder geringerem Preis)</p><br><p class="ql-align-center" style="font-size: 10px;">Einmalig pro Kunde/in einlösbar. Bitte beachten Sie, dass dieses Angebot nicht mit anderen Angeboten oder Rabattaktionen kombinierbar ist. Keine Barauszahlung möglich.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const TimberlineEmailData = {
  styles: {
    width: '600px',
    color: '#000',
    backgroundColor: '#fff',
    sicailLinksColor: '#c58fbb',
    buttonsDividersColor: '#000',
  },
  components: [
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<h1 class="ql-align-center" style="font-style:italic;">SOMMERSCHLUSSVERKAUF</h1>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Der Sommer nähert sich dem Ende, aber wir haben noch ein letztes Sommerschnäppchen für Sie. Diese Woche gibt es eine Gartis-Aktion bei uns im Geschäft.</p>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center" style="font-style:italic; font-weight:bold;">Kaufen Sie beim Sommerschlussverkauf ein!</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_17.+Timberline+Template.jpg',
    },
  ],
};

const BlankEmailData = {
  styles: {
    width: '600px',
    color: '#000',
    backgroundColor: '#fff',
    buttonsDividersColor: '#000',
    sicailLinksColor: '#000',
  },
  components: [
  ],
};

export const deStaticTemplates = [
  {
    id: '4g64i88a5yd6cxb5gzko',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateAutomotiveServiceShopTitle',
    status: 'origin',
    favorites: 'false',
    components: AutomotiveServiceShopEmailData.components,
    styles: AutomotiveServiceShopEmailData.styles,
    thumb: 'automotive-service-shop-email-preview-de.jpg'
  },
  {
    id: 'qaemdbtpqebbkdzsrico',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateBakeryTitle',
    status: 'origin',
    favorites: 'false',
    components: BakeryEmailData.components,
    styles: BakeryEmailData.styles,
    thumb: 'bakery-email-preview-de.jpg',
  },
  {
    id: 'f8u88e3r269b174lrccc',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateBarberShopTitle',
    status: 'origin',
    favorites: 'false',
    components: BarberShopEmailData.components,
    styles: BarberShopEmailData.styles,
    thumb: 'barber-shop-email-preview-de.jpg',
  },
  {
    id: 'ccq83k24s9wyuj398rmy',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateBarPubTitle',
    status: 'origin',
    favorites: 'false',
    components: BarPubEmailData.components,
    styles: BarPubEmailData.styles,
    thumb: 'bar-pub-email-preview-de.jpg',
  },
  {
    id: 'p3aaxcs047iycsvfllgj',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateBeautyShopTitle',
    status: 'origin',
    favorites: 'false',
    components: BeautyShopEmailData.components,
    styles: BeautyShopEmailData.styles,
    thumb: 'beauty-shop-email-preview-de.jpg',
  },
  {
    id: '2dyhmwyc3kc29k1yf9ud',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateBurgerTitle',
    status: 'origin',
    favorites: 'false',
    components: BurgerEmailData.components,
    styles: BurgerEmailData.styles,
    thumb: 'burger-email-preview-de.jpg',
  },
  {
    id: 'mtvur94p0523uzmpkk5e',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateChineseTitle',
    status: 'origin',
    favorites: 'false',
    components: ChineseEmailData.components,
    styles: ChineseEmailData.styles,
    thumb: 'chinese-email-preview-de.jpg',
  },
  {
    id: '63xi2lhyz5pzv67giql2',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateClothingShopTitle',
    status: 'origin',
    favorites: 'false',
    components: ClothingShopEmailData.components,
    styles: ClothingShopEmailData.styles,
    thumb: 'clothing-shop-email-preview-de.jpg',
  },
  {
    id: '4taesmnb96r77f2jbcux',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateCoffeeTitle',
    status: 'origin',
    favorites: 'false',
    components: CoffeeEmailData.components,
    styles: CoffeeEmailData.styles,
    thumb: 'coffee-email-preview-de.jpg',
  },
  {
    id: 'jv6p2u4tn27qlc3qrd8x',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateDessertTitle',
    status: 'origin',
    favorites: 'false',
    components: DessertEmailData.components,
    styles: DessertEmailData.styles,
    thumb: 'dessert-email-preview-de.jpg',
  },
  {
    id: 'pa6cl8e4dtitwa8x93iw',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateDinerTitle',
    status: 'origin',
    favorites: 'false',
    components: DinerEmailData.components,
    styles: DinerEmailData.styles,
    thumb: 'diner-email-preview-de.jpg',
  },
  {
    id: '0kntg9imt0a6hnscix4p',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateFamilyDiningTitle',
    status: 'origin',
    favorites: 'false',
    components: FamilyDiningEmailData.components,
    styles: FamilyDiningEmailData.styles,
    thumb: 'family-dining-email-preview-de.jpg',
  },
  {
    id: '879a80r7pf4tr4217wzy',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateFineDiningTitle',
    status: 'origin',
    favorites: 'false',
    components: FineDiningEmailData.components,
    styles: FineDiningEmailData.styles,
    thumb: 'fine-dining-email-preview-de.jpg',
  },
  {
    id: 'i08fvwvhytzcrkndskdp',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateFloristsTitle',
    status: 'origin',
    favorites: 'false',
    components: FloristsEmailData.components,
    styles: FloristsEmailData.styles,
    thumb: 'florists-email-preview-de.jpg',
  },
  {
    id: '1aq9a7dnjpaz1cqiwe9y',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateFruitTitle',
    status: 'origin',
    favorites: 'false',
    components: FruitEmailData.components,
    styles: FruitEmailData.styles,
    thumb: 'fruit-email-preview-de.jpg',
  },
  {
    id: 'zi06rcceg1ei7oys0x9h',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateItalianTitle',
    status: 'origin',
    favorites: 'false',
    components: ItalianEmailData.components,
    styles: ItalianEmailData.styles,
    thumb: 'italian-email-preview-de.jpg',
  },
  {
    id: '6oev389zs7dayry0yras',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateMexicanTitle',
    status: 'origin',
    favorites: 'false',
    components: MexicanEmailData.components,
    styles: MexicanEmailData.styles,
    thumb: 'mexican-email-preview-de.jpg',
  },
  {
    id: 'evxfym9d02n3xf4cm32v',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateMoveItSportsTitle',
    status: 'origin',
    favorites: 'false',
    components: MoveItSportsEmailData.components,
    styles: MoveItSportsEmailData.styles,
    thumb: 'move-it-sports-email-preview-de.jpg',
  },
  {
    id: '0lu23a23uxqqua3gem5t',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplatePetShopTitle',
    status: 'origin',
    favorites: 'false',
    components: PetShopEmailData.components,
    styles: PetShopEmailData.styles,
    thumb: 'pet-shop-email-preview-de.jpg',
  },
  {
    id: 'q85kypsecig0m57doujz',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplatePizzaTitle',
    status: 'origin',
    favorites: 'false',
    components: PizzaEmailData.components,
    styles: PizzaEmailData.styles,
    thumb: 'pizza-email-preview-de.jpg',
  },
  {
    id: '1io6w327ptka7c8hmox6',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateQSRTitle',
    status: 'origin',
    favorites: 'false',
    components: QsrEmailData.components,
    styles: QsrEmailData.styles,
    thumb: 'qsr-email-preview-de.jpg',
  },
  {
    id: 'gonjddmkqnlshfendudm',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateSushiTitle',
    status: 'origin',
    favorites: 'false',
    components: SushiEmailData.components,
    styles: SushiEmailData.styles,
    thumb: 'sushi-email-preview-de.jpg',
  },
  {
    id: 'f291grnc2fdzg55xuzak',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateTimberlineTitle',
    status: 'origin',
    favorites: 'false',
    components: TimberlineEmailData.components,
    styles: TimberlineEmailData.styles,
    thumb: 'timberline-email-preview-de.jpg',
  },
  {
    id: 'pkisadx4ncc5q7dz9n7p',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateBlankTitle',
    status: 'origin',
    favorites: 'false',
    components: BlankEmailData.components,
    styles: BlankEmailData.styles,
    thumb: 'blank-de.png',
  },
];
