import React, { useCallback, useEffect, useState } from 'react';

export const useOverflowDetection = (ref: React.RefObject<HTMLDivElement>) => {
  const [isOverflow, setIsOverflow] = useState<boolean>(false);

  const isOverflowDetected = useCallback((e?: HTMLElement | null) => {
    if (e) {
      return e.offsetHeight < e.scrollHeight || e.offsetWidth < e.scrollWidth;
    }
    return false;
  }, []);

  useEffect(() => {
    if (isOverflowDetected(ref.current)) {
      setIsOverflow(true);
      return;
    }
    setIsOverflow(false);
  }, [isOverflowDetected]);

  return isOverflow;
};
