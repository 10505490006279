import { createAction, createAsyncAction } from 'typesafe-actions';
import { CountriesData, EmailMarketingCustomerSettings, JwtResult } from './types';

export const emailMarketingSettings = {
  generateJWT: createAsyncAction(
    '@em/settings/generateJWT',
    '@em/settings/generateJWT/success',
    '@em/settings/generateJWT/error'
  )<boolean, JwtResult, void>(),

  fetchCountriesData: createAsyncAction(
    '@em/settings/getCountries',
    '@em/settings/getCountries/success',
    '@em/settings/getCountries/error'
  )<number, CountriesData, void>(),

  fetchCustomerData: createAsyncAction(
    '@em/settings/customer',
    '@em/settings/customer/success',
    '@em/settings/customer/error'
  )<void, EmailMarketingCustomerSettings, void>(),

  updateCustomerData: createAsyncAction(
    '@em/settings/customer/update',
    '@em/settings/customer/update/success',
    '@em/settings/customer/update/error'
  )<EmailMarketingCustomerSettings, EmailMarketingCustomerSettings, void>(),

  setGoToRewardsModalState: createAction(
    '@em/settings/goToRewardsModalState/set',
    action => (state: boolean) => action(state)
  )
};
