/* eslint-disable */
/* de_DE Static Templates Data */

const AutomotiveServiceShopEmailData = {
  styles: {
    width: '600px',
    color: '#898989',
    backgroundColor: '#fff',
    couponBorderColor: '#00aeef',
    buttonsDividersColor: '#898989',
    sicailLinksColor: '#00aeef',
  },
  components: [
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_29.+Automotive+Service+Shops+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center">Kostenlose saisonale Reifeneinlagerung!</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Genießen Sie bei bei den meisten Autoreparaturen unseren Service noch am selben Tag</p>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Holen Sie sich einen klaren Kostenvoranschlag ohne versteckte Kosten</p>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Rufen Sie noch heute an, um einen Termin zu vereinbaren!</p>',
    },
    {
      type: 'coupon',
      text: '<h2 class="ql-align-center">Wenn Sie bei uns eine Handwäsche für Ihr Fahrzeug und einen Reifenwechsel kaufen, lagern wir Ihre Saisonreifen kostenlos ein!</h2><p class="ql-align-center" style="font-size: 10px;">Bitte beachten Sie, dass dieses Angebot nicht mit anderen Angeboten und/oder Rabattaktionen kombinierbar ist. Keine Barablöse möglich.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const BakeryEmailData = {
  styles: {
    width: '600px',
    color: '#898989',
    backgroundColor: '#fff',
    couponBorderColor: '#00aeef',
    buttonsDividersColor: '#898989',
    sicailLinksColor: '#00aeef',
  },
  components: [
    {
      type: 'image',
      image: 'em-logos/bakery-email-logo_1498080292703.png',
    },
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_1.+Bakery+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center">10 % Rabatt auf Ihren nächsten Einkauf</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Als Dankeschön möchten wir Ihnen ein kleines Geschenk schicken. Verwenden Sie diesen Gutschein bei Ihrem nächsten Besuch in Bob\'s Bakery, wo alles vor Ort gebacken wird und nur die frischesten Zutaten verwendet werden. Kommen Sie vorbei und wählen Sie aus unserem reichhaltigen Angebot und genießen Sie frische Torte und Kuchen. Oder noch besser, lehnen Sie sich zurück und genießen Sie unsere leckeren Produkte direkt bei uns! Wir freuen uns darauf, Sie bald wieder zu sehen!</p>',
    },
    {
      type: 'coupon',
      text: '<h2 class="ql-align-center">10 % Rabatt auf Ihren nächsten Einkauf.</h2><p class="ql-align-center" style="font-size: 10px;">Bitte beachten Sie, dass dieses Angebot nicht mit anderen Angeboten und/oder Rabattaktionen kombinierbar ist. </p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const BarberShopEmailData = {
  styles: {
    width: '600px',
    color: '#898989',
    backgroundColor: '#fff',
    couponBorderColor: '#00aeef',
    buttonsDividersColor: '#898989',
    sicailLinksColor: '#00aeef',
  },
  components: [
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_25.+Barber+Shop+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center">Komplettes Pflegepaket jetzt mit 10% Rabatt</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Lehnen Sie sich zurück und vertrauen Sie Ihr Haar und Ihren Bart der Pflege eines professionellen Friseurs an. Neben dem perfekten Schnitt genießen Sie erstklassigen Kaffee bei uns.</p>',
    },
    {
      type: 'coupon',
      text: '<h2 class="ql-align-center">Ein komplettes Pflegepaket, das auch eine Bartkur beinhaltet, jetzt mit 10 % Rabatt und Rum nach Wahl von unseren Premium-Rums gratis dazu.</h2><p class="ql-align-center" style="font-size: 10px;">Bitte beachten Sie, dass dieses Angebot nicht mit anderen Angeboten und/oder Rabattaktionen kombinierbar ist. Keine Barablöse möglich.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const BarPubEmailData = {
  styles: {
    width: '600px',
    color: '#fff',
    backgroundColor: '#898989',
    couponBorderColor: '#fff',
    buttonsDividersColor: '#fff',
    sicailLinksColor: '#fff',
  },
  components: [
    {
      type: 'image',
      image: 'em-logos/bar-pub-email-logo_1498080596725.png',
    },
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_2.+Bar+Pub+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center">WIR HABEN JEDEN TAG EINE HAPPY HOUR</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center" style="font-weight: bold;">Täglich von 16:00 bis 19:00 Uhr</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Wir aktualisieren wöchentlich unsere Getränkekarte.</p>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Schauen Sie vorbei an, was wir zu bieten haben.</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'coupon',
      text: '<h4 class="ql-align-center">Happy-Hour-Artikel mit einem Rabatt von 1 €.</h4><p class="ql-align-center">Gültig für 1 Artikel.</p><br><p class="ql-align-center" style="font-size: 10px;">Auf einen Kunden beschränkt. Bitte beachten Sie, dass dieses Angebot nicht mit anderen Angeboten und/oder Rabattaktionen kombinierbar ist. </p><br>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const BeautyShopEmailData = {
  styles: {
    width: '600px',
    color: '#898989',
    backgroundColor: '#fff',
    couponBorderColor: '#00aeef',
    buttonsDividersColor: '#898989',
    sicailLinksColor: '#00aeef',
  },
  components: [
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_26.+Beauty+Shop+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center">Beeilen Sie sich und sichern Sie sich 10 % Rabatt auf exklusive Naturkosmetik</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Kommen Sie zu uns, um sich zu entspannen und die Veränderung nach Ihren eigenen Wünschen zu erleben. Wir kümmern uns von Kopf bis Fuß um Sie.</p>',
    },
    {
      type: 'coupon',
      text: '<h2 class="ql-align-center">Nach Abschluss einer Schönheitsbehandlung in unserem Salon erhalten Sie 10 % Rabatt auf exklusive Naturkosmetik.</h2><p class="ql-align-center" style="font-size: 10px;">Bitte beachten Sie, dass dieses Angebot nicht mit anderen Angeboten und/oder Rabattaktionen kombinierbar ist. Keine Barablöse möglich.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const BurgerEmailData = {
  styles: {
    width: '600px',
    backgroundColor: '#e11b22',
    color: '#fff',
    headingsColor: '#fff',
    buttonsDividersColor: '#fff',
  },
  components: [
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_3.+Burger+Template_CZ.jpg',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">KAUFEN SIE EINEN, ERHALTEN SIE NOCH EINEN GRATIS.</p>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Unsere saisonale, 100% Rindfleisch-Pastetchen mit schmackhaftem geräuchertem Speck und frischem Gemüse sind da! Dazu ein gekühltes Handwerksbier und mit einem Lächeln in die Woche starten!</p>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Zeigen Sie uns diesen Coupon oder diesen Coupon bitte selbst ausdrucken.</p>',
    },
    {
      type: 'coupon',
      text: '<h2 class="ql-align-center">KAUFEN SIE EIN MENÜ, ERHALTEN SIE EIN ZWEITES GRATIS</h2><p class="ql-align-center">(gleicher Wert oder kleiner Wert)</p><p class="ql-align-center">Auf einen Kunden beschränkt. Bitte beachten Sie, dass dieses Angebot nicht mit anderen Angeboten und/oder Rabattaktionen kombinierbar ist.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const ChineseEmailData = {
  styles: {
    width: '600px',
    color: '#fff',
    backgroundColor: '#231f20',
    couponBorderColor: '#fff',
    buttonsDividersColor: '#fff',
    sicailLinksColor: '#fff',
  },
  components: [
    {
      type: 'image',
      image: 'em-logos/chinese-email-logo_1498080685909.png',
    },
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_4.+Chinese+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center" style="font-style:italic;">Kostenlose Eierrolle!</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">oder Ersatz für Frühlingsrollen (2)</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Sehen Sie sich auch unsere Getränke-Spezialitäten und unsere aktualisierte Speisekarte an.</p>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Rufen Sie uns einfach an und reservieren Sie Ihren Tisch noch heute!</p>',
    },
    {
      type: 'coupon',
      text: '<h2 class="ql-align-center">KOSTENLOSE EIERROLLE</h2><p class="ql-align-center">oder Frühlingsrolle (2)</p><br><p class="ql-align-center" style="font-size: 10px;">Auf eine/n Kund:in beschränkt. Bitte beachten Sie, dass dieses Angebot nicht mit anderen Angeboten und/oder Rabattaktionen kombinierbar ist. Keine Barablöse möglich.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const ClothingShopEmailData = {
  styles: {
    width: '600px',
    color: '#898989',
    backgroundColor: '#fff',
    couponBorderColor: '#00aeef',
    buttonsDividersColor: '#898989',
    sicailLinksColor: '#00aeef',
  },
  components: [
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_27.+Clothing+Store+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center">ENTDECKEN SIE IHREN NEUEN STYLE</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Kommen Sie zu uns und lassen Sie sich inspirieren. Diese Woche haben wir eine 1+1 gratis Aktion für Sie vorbereitet.</p>',
    },
    {
      type: 'coupon',
      text: '<h2 class="ql-align-center">Bitte beachten Sie, dass dieses Angebot nicht mit anderen Angeboten und/oder Rabattaktionen kombinierbar ist. Keine Barablöse möglich.</h2>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const CoffeeEmailData = {
  styles: {
    width: '600px',
    backgroundColor: '#e6e7e8',
    color: '#000',
    headingsColor: '#000',
    buttonsDividersColor: '#000',
  },
  components: [
    {
      type: 'image',
      image: 'em-logos/top_1497255967016.png',
    },
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_5.+Coffee+Template.jpg',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<h3 class="ql-align-center">WACHEN SIE MIT UNSEREM FRISCH GERÖSTETEN KAFFEE AUF!</h3>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Es gibt keinen besseren Start in den Tag als bei uns mit einem frisch gerösteten Kaffee! Der volle und reichhaltige Geschmack zaubert Ihnen garantiert ein Lächeln aufs Gesicht. Besuchen Sie uns noch heute für eine Verkostung. Natürlich kostenlos!</p>',
    },
    {
      type: 'coupon',
      text: '<h4 class="ql-align-center">TÄGLICH FRISCHE BACKWAREN</h4><h3 class="ql-align-center">MENÜ AKTIONEN MONTAG – FREITAG</h3><p class="ql-align-center">Die Angebote können an den einzelnen Standorten variieren.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },

  ],
};

const DessertEmailData = {
  styles: {
    width: '600px',
    color: '#464646',
    backgroundColor: '#dfd',
    couponBorderColor: '#ff7d52',
    buttonsDividersColor: '#ff7d52',
    sicailLinksColor: '#464646',
  },
  components: [
    {
      type: 'image',
      image: 'em-logos/dessert-email-logo_1498080768821.png',
    },
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_6.+Dessert+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center" style="color:#ff7d52; font-style:italic;">DAS NEUE ANGEBOT IST DA!</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Der Frühling ist da und mit dem Frühling auch unsere neue Karte!</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Kommen Sie diese Woche vorbei und zahlen Sie nur die Hälfte für alle neuen Artikel </p><p class="ql-align-center">auf unserer Karte.</p>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Sie finden bei uns schöne rosa Makronen, cremige Karottenkuchen,</p><p class="ql-align-center">Sachertorten und vieles mehr.</p>',
    },
    {
      type: 'coupon',
      text: '<h3 class="ql-align-center">ALLE NEUEN ARTIKEL UNSERER KARTE ZUM HALBEN PREIS!</h3><p class="ql-align-center">(nur diese Woche)</p><br><p class="ql-align-center" style="font-size: 10px;">Bitte beachten Sie, dass dieses Angebot nicht mit anderen Angeboten und/oder Rabattaktionen kombinierbar ist. Keine Barablöse möglich.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const DinerEmailData = {
  styles: {
    width: '600px',
    color: '#f00',
    backgroundColor: '#fff',
    couponBorderColor: '#f00',
    buttonsDividersColor: '#f00',
    sicailLinksColor: '#f00',
  },
  components: [
    {
      type: 'image',
      image: 'em-logos/diner-email-logo_1498081690316.png',
    },
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_7.+Diner+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center">10% Rabatt auf Ihre nächste Bestellung</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Kommen sie zu uns und genießen Sie die angenehme Atmosphäre! In Dave\'s Diner hat ein lächelndes Service immer oberste Priorität.</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'spacer',
    },
    {
      type: 'coupon',
      text: '<h2 class="ql-align-center">10 % Rabatt auf Ihre nächste Bestellung.</h2><p class="ql-align-center" style="font-size: 10px;">Bitte beachten Sie, dass dieses Angebot nicht mit anderen Angeboten und/oder Rabattaktionen kombinierbar ist. Keine Barablöse möglich.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const FamilyDiningEmailData = {
  styles: {
    width: '600px',
    color: '#fff',
    backgroundColor: '#fc702e',
    couponBorderColor: '#fff',
    buttonsDividersColor: '#fff',
    sicailLinksColor: '#000',
  },
  components: [
    {
      type: 'image',
      image: 'em-logos/family-dining-email-2-logo_1498081751965.png',
    },
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_8.+Family+Dining+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center" style="font-style:italic;">DIENSTAGS ESSEN KINDER GRATIS!</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Das Abendessen ist im Crosby\'s Kitchen Familienzeit. JEDEN Dienstag essen Ihre Kinder gratis beim Kauf einer beliebigen Speise für Erwachsene.</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Wir garantieren, dass Sie und Ihre Kleinen das Essen lieben werden.</p>',
    },
    {
      type: 'coupon',
      text: '<h2 class="ql-align-center">1x KOSTENLOSE KINDERSPEISE!</h2><p class="ql-align-center">(beim Kauf einer beliebigen Speise für Erwachsene)</p><br><p class="ql-align-center" style="font-size: 10px;">Bitte beachten Sie, dass dieses Angebot nicht mit anderen Angeboten und/oder Rabattaktionen kombinierbar ist. Keine Barablöse möglich.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const FineDiningEmailData = {
  styles: {
    width: '600px',
    backgroundColor: '#f5f3ef',
    color: '#000',
    couponBorderColor: '#770e1f',
    buttonsDividersColor: '#770e1f',
    sicailLinksColor: '#000',
  },
  components: [
    {
      type: 'image',
      image: 'em-logos/fine-dining-email-logo_1498081793107.png',
    },
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_9.+Fine+Dining.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center" style="font-style:italic; color:#770e1f;">EINLADUNG FÜR SIE!</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Machen Sie mit und erleben Sie das perfekte Food-Pairing-Erlebnis, das wir exklusiv unseren treuesten Fans anbieten.</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center" style="font-weight:bold;">$75 Verkostungsmenü</p>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">(beinhaltet ein 4-Gänge-Menü mit Essen und passender Weinbegleitung)</p>',
    },
    {
      type: 'coupon',
      text: '<h2 class="ql-align-center" style="font-weight:normal;">RESERVIEREN SIE HIER, wenn Sie an diesem besonderen Event teilnehmen möchten.</h2><p class="ql-align-center" style="font-style:italic;">Beeilen Sie sich, die Anzahl der Plätze ist begrenzt!</p><br><p class="ql-align-center" style="font-size: 10px;">Bitte beachten Sie, dass dieses Angebot nicht mit anderen Angeboten und/oder Rabattaktionen kombinierbar ist. Keine Barablöse möglich.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const FloristsEmailData = {
  styles: {
    width: '600px',
    color: '#898989',
    backgroundColor: '#fff',
    couponBorderColor: '#00aeef',
    buttonsDividersColor: '#898989',
    sicailLinksColor: '#00aeef',
  },
  components: [
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_30.+Florists+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center">Der Sommer ist da!</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Wir haben unseren Laden in ein Sommerkleid gepackt! Wie zu jeder Jahreszeit haben wir unsere Räumlichkeiten so eingerichtet, dass Sie den Sommer sehen und spüren können. Lassen Sie sich inspirieren und nehmen Sie ein Stück Sommer mit.</p>',
    },
    {
      type: 'coupon',
      text: '<h2 class="ql-align-center">Einjährige Pflanzen in Töpfen nur diese Woche mit 10 % Rabatt</h2><p class="ql-align-center" style="font-size: 10px;">Bitte beachten Sie, dass dieses Angebot nicht mit anderen Angeboten und/oder Rabattaktionen kombinierbar ist. Keine Barablöse möglich.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const FruitEmailData = {
  styles: {
    width: '600px',
    color: '#f00',
    backgroundColor: '#fff',
    couponBorderColor: '#f00',
    buttonsDividersColor: '#f00',
    sicailLinksColor: '#f00',
  },
  components: [
    {
      type: 'image',
      image: 'em-logos/fruit-email-logo_1498081891611.png',
    },
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_10.+Fruit+Template_CZ.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center">10% Rabatt auf die erste Bestellung</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Unser Obst ist garantiert frisch! Holen Sie sich gesunde Snacks für sich und Ihre Freunde. Unsere Obstkörbe zaubern garantiert ein Lächeln auf jedes Gesicht.</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'spacer',
    },
    {
      type: 'coupon',
      text: '<h2 class="ql-align-center">10% Rabatt auf die erste Bestellung.</h2><p class="ql-align-center" style="font-size: 10px;">10 % Rabatt auf die erste Bestellung. Auf eine/n Kund:in beschränkt. Bitte beachten Sie, dass dieses Angebot nicht mit anderen Angeboten und/oder Rabattaktionen kombinierbar ist. Keine Barablöse möglich.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const ItalianEmailData = {
  styles: {
    width: '600px',
    color: '#000',
    backgroundColor: '#fcefd8',
    couponBorderColor: '#d82c1f',
    buttonsDividersColor: '#d82c1f',
    sicailLinksColor: '#000',
  },
  components: [
    {
      type: 'image',
      image: 'em-logos/italian-email-logo_1498081923428.png',
    },
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_11.+Italian+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center" style="font-style:italic; color:#d82c1f;">BEI ​​IHREM NÄCHSTEN BESUCH ERHALTEN SIE EINEN RABATT VON 10 €!</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Sie werden unser fantastisches Essen lieben, sei es unsere berühmte Pomodoro Bolognese oder Penne Carbonara.</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Zeigen Sie uns dieses E-Mail und Sie erhalten 10 € Rabatt auf Ihre Ausgaben.</p>',
    },
    {
      type: 'coupon',
      text: '<h2 class="ql-align-center" style="color:#d82c1f;">10 € RABATT FÜR DEN NÄCHSTEN BESUCH</h2><br><p class="ql-align-center" style="font-size: 10px;"> Bitte beachten Sie, dass dieses Angebot nicht mit anderen Angeboten und/oder Rabattaktionen kombinierbar ist. Keine Barablöse möglich.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const MexicanEmailData = {
  styles: {
    width: '600px',
    color: '#fff',
    backgroundColor: '#efae2a',
    couponBorderColor: '#fff',
    buttonsDividersColor: '#fff',
    sicailLinksColor: '#fff',
  },
  components: [
    {
      type: 'image',
      image: 'em-logos/mexican-email-logo_1498082003319.png',
    },
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_12.+Mexican+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center">10% Rabatt auf Ihre nächste Bestellung</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Unser authentisches Essen wird Ihren Tag garantiert erhellen! Kommen Sie zu uns und genießen Sie ein gekühltes Getränk und leckere Vorspeisen zu unschlagbaren Preisen. Die Fiesta kann beginnen!</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'coupon',
      text: '<h2 class="ql-align-center">10 % Rabatt auf Ihre nächste Bestellung.</h2><p class="ql-align-center" style="font-size: 10px;">Bitte beachten Sie, dass dieses Angebot nicht mit anderen Angeboten und/oder Rabattaktionen kombinierbar ist. Keine Barablöse möglich.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const MoveItSportsEmailData = {
  styles: {
    width: '600px',
    color: '#000',
    backgroundColor: '#fff',
    buttonsDividersColor: '#000',
    sicailLinksColor: '#0779be',
  },
  components: [
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<h1 class="ql-align-center" style="color:#0779be; font-style:italic;">Sie kommen voran!</h1>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Sehen Sie sich unsere Getränke-Spezialitäten und die aktualisierte Karte an.</p>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center" style="font-style:italic; font-weight:bold;">Rufen Sie uns einfach an und reservieren Sie Ihren Tisch noch heute!</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_13.+Move+It+Sports+Template.jpg',
    },
  ],
};

const PetShopEmailData = {
  styles: {
    width: '600px',
    color: '#898989',
    backgroundColor: '#fff',
    couponBorderColor: '#00aeef',
    buttonsDividersColor: '#898989',
    sicailLinksColor: '#00aeef',
  },
  components: [
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_28.+Pet+Shop+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center">Immer eine große Auswahl!</h2>',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center">10% Rabatt auf Ihre nächste Bestellung</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Alles für Ihre Haustiere mit einer professionellen Beratung.</p>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Bei uns können Sie genau das kaufen, was Ihr Tier wirklich braucht.</p>',
    },
    {
      type: 'coupon',
      text: '<h2 class="ql-align-center">10% Rabatt auf Ihre nächste Bestellung</h2><p class="ql-align-center">Bitte beachten Sie, dass dieses Angebot nicht mit anderen Angeboten und/oder Rabattaktionen kombinierbar ist. Keine Barablöse möglich.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const PizzaEmailData = {
  styles: {
    width: '600px',
    color: '#000',
    backgroundColor: '#fdd85c',
    couponBorderColor: '#eb6464',
    buttonsDividersColor: '#eb6464',
    sicailLinksColor: '#000',
  },
  components: [
    {
      type: 'image',
      image: 'em-logos/pizza-email-logo_1498082059029.png',
    },
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_14.+Pizza+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center" style="font-style:italic">1+1 GRATIS!</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Von Pilzen und Peperoni bis hin zu Oliven und Sardellen, Sie haben die Wahl. Bestellen Sie eine beliebige Pizza und Sie erhalten die zweite GRATIS dazu!</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Zeigen Sie uns einfach dieses E-Mail, um ein tolles Angebot zu erhalten. </p>',
    },
    {
      type: 'coupon',
      text: '<h3 class="ql-align-center"> 1+1 GRATIS!</h3><p class="ql-align-center"> (zum gleichen oder zu einem niedrigeren Preis)</p><br><p class="ql-align-center" style="font-size: 10px;">Bitte beachten Sie, dass dieses Angebot nicht mit anderen Angeboten und/oder Rabattaktionen kombinierbar ist. Keine Barablöse möglich.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const QsrEmailData = {
  styles: {
    width: '600px',
    color: '#000',
    backgroundColor: '#fff',
    couponBorderColor: '#000',
    buttonsDividersColor: '#000',
    sicailLinksColor: '#000',
  },
  components: [
    {
      type: 'image',
      image: 'em-logos/burger_1505312326850_1505312462526.png',
    },
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_15.+QSR+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center" style="font-style:italic; color:#aa181c;">AM FREITAG KOSTENLOSE POMMES</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Wir lieben Pommes Frites. Sie lieben Pommes Frites. Wir wetten, dass Sie KOSTENLOSE Pommes Frites noch mehr lieben werden.</p>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Bestellen Sie einen beliebigen Hamburger oder ein Sandwich und Sie erhalten eine große Portion Pommes Frites gratis!</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Beeilen Sie sich, das Angebot gilt nur im März!</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'coupon',
      text: '<h3 class="ql-align-center" style="color:#aa181c;">KOSTENLOSE GROSSE POMMES!</h3><p class="ql-align-center">(beim Kauf von Hamburgern oder Sandwiches)</p><br><p class="ql-align-center" style="font-size: 10px;">Bitte beachten Sie, dass dieses Angebot nicht mit anderen Angeboten und/oder Rabattaktionen kombinierbar ist. Keine Barablöse möglich.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const SushiEmailData = {
  styles: {
    width: '600px',
    color: '#fff',
    backgroundColor: '#231f20',
    couponBorderColor: '#fff',
    buttonsDividersColor: '#fff',
    sicailLinksColor: '#fff',
  },
  components: [
    {
      type: 'image',
      image: 'em-logos/sushi-email-logo_1498082220393.png',
    },
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_16.+Sushi+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center" style="font-style:italic;">HALBER PREIS WÄHREND DER HAPPY HOUR!</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Ausgewählte Sushi und Vorspeisen von 3,99 € bis 9,99 €!</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Sehen Sie sich auch unsere Getränke-Spezialitäten und unsere aktualisierte Speisekarte an.</p>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Rufen Sie uns einfach an und reservieren Sie Ihren Tisch noch heute!</p>',
    },
    {
      type: 'coupon',
      text: '<h3 class="ql-align-center">KAUFEN SIE EINE ROLLE UND SIE ERHALTEN DIE ZWEITE UM DEN HALBEN PREIS</h3><p class="ql-align-center">(bei gleichem oder geringerem Preis)</p><br><p class="ql-align-center" style="font-size: 10px;">Auf eine/n Kund:in beschränkt. Bitte beachten Sie, dass dieses Angebot nicht mit anderen Angeboten und/oder Rabattaktionen kombinierbar ist. Keine Barablöse möglich.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const TimberlineEmailData = {
  styles: {
    width: '600px',
    color: '#000',
    backgroundColor: '#fff',
    sicailLinksColor: '#c58fbb',
    buttonsDividersColor: '#000',
  },
  components: [
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<h1 class="ql-align-center" style="font-style:italic;">SOMMERSCHLUSSVERKAUF</h1>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Der Sommer nähert sich langsam seinem Ende, aber wir haben für Sie noch ein letztes Sommervergnügen. Diese Woche gibt es eine 1+1 gratis-Aktion bei uns.</p>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center" style="font-style:italic; font-weight:bold;">Kaufen Sie beim Sommerschlussverkauf ein!</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_17.+Timberline+Template.jpg',
    },
  ],
};

const BlankEmailData = {
  styles: {
    width: '600px',
    color: '#000',
    backgroundColor: '#fff',
    buttonsDividersColor: '#000',
    sicailLinksColor: '#000',
  },
  components: [
  ],
};

export const atStaticTemplates = [
  {
    id: '4g64i88a5yd6cxb5gzko',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateAutomotiveServiceShopTitle',
    status: 'origin',
    favorites: 'false',
    components: AutomotiveServiceShopEmailData.components,
    styles: AutomotiveServiceShopEmailData.styles,
    thumb: 'automotive-service-shop-email-preview-de.jpg'
  },
  {
    id: 'qaemdbtpqebbkdzsrico',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateBakeryTitle',
    status: 'origin',
    favorites: 'false',
    components: BakeryEmailData.components,
    styles: BakeryEmailData.styles,
    thumb: 'bakery-email-preview-de.jpg',
  },
  {
    id: 'f8u88e3r269b174lrccc',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateBarberShopTitle',
    status: 'origin',
    favorites: 'false',
    components: BarberShopEmailData.components,
    styles: BarberShopEmailData.styles,
    thumb: 'barber-shop-email-preview-de.jpg',
  },
  {
    id: 'ccq83k24s9wyuj398rmy',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateBarPubTitle',
    status: 'origin',
    favorites: 'false',
    components: BarPubEmailData.components,
    styles: BarPubEmailData.styles,
    thumb: 'bar-pub-email-preview-de.jpg',
  },
  {
    id: 'p3aaxcs047iycsvfllgj',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateBeautyShopTitle',
    status: 'origin',
    favorites: 'false',
    components: BeautyShopEmailData.components,
    styles: BeautyShopEmailData.styles,
    thumb: 'beauty-shop-email-preview-de.jpg',
  },
  {
    id: '2dyhmwyc3kc29k1yf9ud',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateBurgerTitle',
    status: 'origin',
    favorites: 'false',
    components: BurgerEmailData.components,
    styles: BurgerEmailData.styles,
    thumb: 'burger-email-preview-de.jpg',
  },
  {
    id: 'mtvur94p0523uzmpkk5e',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateChineseTitle',
    status: 'origin',
    favorites: 'false',
    components: ChineseEmailData.components,
    styles: ChineseEmailData.styles,
    thumb: 'chinese-email-preview-de.jpg',
  },
  {
    id: '63xi2lhyz5pzv67giql2',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateClothingShopTitle',
    status: 'origin',
    favorites: 'false',
    components: ClothingShopEmailData.components,
    styles: ClothingShopEmailData.styles,
    thumb: 'clothing-shop-email-preview-de.jpg',
  },
  {
    id: '4taesmnb96r77f2jbcux',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateCoffeeTitle',
    status: 'origin',
    favorites: 'false',
    components: CoffeeEmailData.components,
    styles: CoffeeEmailData.styles,
    thumb: 'coffee-email-preview-de.jpg',
  },
  {
    id: 'jv6p2u4tn27qlc3qrd8x',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateDessertTitle',
    status: 'origin',
    favorites: 'false',
    components: DessertEmailData.components,
    styles: DessertEmailData.styles,
    thumb: 'dessert-email-preview-de.jpg',
  },
  {
    id: 'pa6cl8e4dtitwa8x93iw',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateDinerTitle',
    status: 'origin',
    favorites: 'false',
    components: DinerEmailData.components,
    styles: DinerEmailData.styles,
    thumb: 'diner-email-preview-de.jpg',
  },
  {
    id: '0kntg9imt0a6hnscix4p',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateFamilyDiningTitle',
    status: 'origin',
    favorites: 'false',
    components: FamilyDiningEmailData.components,
    styles: FamilyDiningEmailData.styles,
    thumb: 'family-dining-email-preview-de.jpg',
  },
  {
    id: '879a80r7pf4tr4217wzy',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateFineDiningTitle',
    status: 'origin',
    favorites: 'false',
    components: FineDiningEmailData.components,
    styles: FineDiningEmailData.styles,
    thumb: 'fine-dining-email-preview-de.jpg',
  },
  {
    id: 'i08fvwvhytzcrkndskdp',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateFloristsTitle',
    status: 'origin',
    favorites: 'false',
    components: FloristsEmailData.components,
    styles: FloristsEmailData.styles,
    thumb: 'florists-email-preview-de.jpg',
  },
  {
    id: '1aq9a7dnjpaz1cqiwe9y',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateFruitTitle',
    status: 'origin',
    favorites: 'false',
    components: FruitEmailData.components,
    styles: FruitEmailData.styles,
    thumb: 'fruit-email-preview-de.jpg',
  },
  {
    id: 'zi06rcceg1ei7oys0x9h',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateItalianTitle',
    status: 'origin',
    favorites: 'false',
    components: ItalianEmailData.components,
    styles: ItalianEmailData.styles,
    thumb: 'italian-email-preview-de.jpg',
  },
  {
    id: '6oev389zs7dayry0yras',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateMexicanTitle',
    status: 'origin',
    favorites: 'false',
    components: MexicanEmailData.components,
    styles: MexicanEmailData.styles,
    thumb: 'mexican-email-preview-de.jpg',
  },
  {
    id: 'evxfym9d02n3xf4cm32v',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateMoveItSportsTitle',
    status: 'origin',
    favorites: 'false',
    components: MoveItSportsEmailData.components,
    styles: MoveItSportsEmailData.styles,
    thumb: 'move-it-sports-email-preview-de.jpg',
  },
  {
    id: '0lu23a23uxqqua3gem5t',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplatePetShopTitle',
    status: 'origin',
    favorites: 'false',
    components: PetShopEmailData.components,
    styles: PetShopEmailData.styles,
    thumb: 'pet-shop-email-preview-de.jpg',
  },
  {
    id: 'q85kypsecig0m57doujz',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplatePizzaTitle',
    status: 'origin',
    favorites: 'false',
    components: PizzaEmailData.components,
    styles: PizzaEmailData.styles,
    thumb: 'pizza-email-preview-de.jpg',
  },
  {
    id: '1io6w327ptka7c8hmox6',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateQSRTitle',
    status: 'origin',
    favorites: 'false',
    components: QsrEmailData.components,
    styles: QsrEmailData.styles,
    thumb: 'qsr-email-preview-de.jpg',
  },
  {
    id: 'gonjddmkqnlshfendudm',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateSushiTitle',
    status: 'origin',
    favorites: 'false',
    components: SushiEmailData.components,
    styles: SushiEmailData.styles,
    thumb: 'sushi-email-preview-de.jpg',
  },
  {
    id: 'f291grnc2fdzg55xuzak',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateTimberlineTitle',
    status: 'origin',
    favorites: 'false',
    components: TimberlineEmailData.components,
    styles: TimberlineEmailData.styles,
    thumb: 'timberline-email-preview-de.jpg',
  },
  {
    id: 'pkisadx4ncc5q7dz9n7p',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateBlankTitle',
    status: 'origin',
    favorites: 'false',
    components: BlankEmailData.components,
    styles: BlankEmailData.styles,
    thumb: 'blank-de.png',
  },
];
