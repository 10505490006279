import { connect } from 'react-redux';
import { AppState } from 'store/rootReducer';
import { bindActionCreators } from 'redux';
import { isSupportSearch } from 'store/navigation';
import { isMastercardTermsConfirmed } from 'store/terms';
import { useEffect } from 'react';
import { applicationStore } from 'index';
import { watchFetchMasterCardCompareData } from 'store/masterCard/sagas';
import { masterCardActions } from 'store/masterCard/actions';

export const COMPONENT_NAME = 'MastercardCompareDataFetcher';
const SAGA_KEY = 'masterCard/compareData/watch';

interface ConnectedProps {
  dataFetchingAllowed: boolean;
}

interface ConnectedActions {
  fetchMasterCardCompareData: typeof masterCardActions.fetchMasterCardCompareData.request;
}

const DataFetcher = ({ dataFetchingAllowed, fetchMasterCardCompareData }: ConnectedActions & ConnectedProps): null => {
  useEffect(() => {
    if (!dataFetchingAllowed) return () => {};
    applicationStore.injectSaga(SAGA_KEY, watchFetchMasterCardCompareData);

    fetchMasterCardCompareData();

    return () => {
      applicationStore.cancelSaga(SAGA_KEY);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFetchingAllowed]);

  return null;
};

const ConnectedMasterCardDataFetcher = connect(
  (state: AppState) => {
    const isInternal = isSupportSearch(state);
    const isTermsConfirmed = isMastercardTermsConfirmed(state);

    return { dataFetchingAllowed: isTermsConfirmed || isInternal };
  },
  dispatch =>
    bindActionCreators(
      {
        fetchMasterCardCompareData: masterCardActions.fetchMasterCardCompareData.request
      },
      dispatch
    )
)(DataFetcher);

export default ConnectedMasterCardDataFetcher;
