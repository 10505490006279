import React, {
  useState,
  useEffect,
  ReactChild,
  useRef,
  MutableRefObject,
  ReactElement,
  ReactNode,
  useMemo
} from 'react';
import { isNil } from 'lodash';
import styled, { FlattenSimpleInterpolation } from 'styled-components';
import { ellipsisText } from 'layout/mixins';
import { getColor } from 'layout/theme';
import { DEFAULT_NS } from 'appConstants/translationNamespaces';
import { useTranslation } from 'react-i18next';
import { bindDocumentClickOrTouchListener } from 'services/utils';
import { Button } from './Button';

export enum DropDownDirection {
  TOP,
  BOTTOM
}
export type DropDownValue = number | string;

export interface DropdownItem {
  label: string | ReactChild[];
  bodyLabel?: ReactChild;
  value: DropDownValue;
  disabled?: boolean;
  hint?: string;
  dataTestId?: string;
  hintPlace?: 'top' | 'bottom' | 'right' | 'left';
}
interface ButtonWithChevronStyles {
  color: string;
  isShadowed: boolean;
}

interface OwnProps {
  className?: string;
  style?: React.CSSProperties;
  items: DropdownItem[];
  selectedValue: DropDownValue;
  placeholder?: ReactNode;
  parent?: MutableRefObject<HTMLDivElement | null> | string;
  onChange?: (value: DropDownValue) => void;
  onToggle?: (value: boolean) => void;
  isCheckIconEnabled?: boolean;
  isCustomInputEnabled?: boolean;
  maxCustomValueAmount?: number;
  customInputFilter?: Function;
  customInputItemNameFilter?: Function;
  customInputPlaceholder?: string;
  customInputItemName?: string;
  disabled?: boolean;
  invalid?: boolean;
  width?: string;
  manualDirection?: DropDownDirection;
  buttonWithChevronStyles?: ButtonWithChevronStyles;
  alwaysShowPlaceholder?: boolean;
  backgroundForActive?: string;
  dataTestId?: string;
  renderCustomButton?: (props: { direction: DropDownDirection; invalid: boolean; open: boolean }) => JSX.Element;
  customButtonStylesMixin?: FlattenSimpleInterpolation;
  isChevronHidden?: boolean;
}

const setDirection = (props: { direction: DropDownDirection; invalid: boolean }): string => {
  if (props.direction === DropDownDirection.TOP) {
    return `
    top: auto;
    bottom: 100%;
    border-bottom-width: 0;
  `;
  }

  return `
    top: 100%;
    bottom: auto;
    border-top-width: 0;
  `;
};

const DropDownBody = styled.div<{ direction: DropDownDirection; invalid: boolean }>`
  display: flex;
  flex-direction: column;
  position: absolute;
  max-height: 172px;
  width: 100%;
  overflow-y: auto;
  z-index: 99;
  border: 1px solid ${props => (!props.invalid ? getColor(props, 'grey') : getColor(props, 'red'))};
  background: ${props => getColor(props, 'white')};

  & button {
    border: none;
    border-bottom: 1px solid ${props => getColor(props, 'grey')};

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      background-color: ${props => getColor(props, 'lightBlue')};
    }
  }

  ${setDirection};
`;
DropDownBody.displayName = 'DropDownBody';

interface CustomButtonProps {
  active: boolean;
  disabled?: boolean;
  backgroundForActive?: string;
}

const buttonsSize = 'min-height: 34px; padding: 6px 12px;';

const DropDownItem = styled(Button)<CustomButtonProps>`
  ${ellipsisText};
  ${buttonsSize};
  width: 100%;
  user-select: none;
  outline: none;
  cursor: pointer;
  background: ${props => getColor(props, 'white')};
  text-align: left;
  font-weight: ${props => (props.active ? 'bold' : 'normal')};
  color: ${props => getColor(props, 'darkGrey')} !important;
  ${props =>
    props.active && props.backgroundForActive
      ? `background: ${getColor(props, props.backgroundForActive)} !important;`
      : ''};
  ${props =>
    props.disabled
      ? `
    cursor: not-allowed;
    background: ${getColor(props, 'blueGrey')} !important;

    &:hover {
      background-color: ${getColor(props, 'blueGrey')} !important;
    }
  `
      : ''};

  i {
    margin-right: 10px;
  }
`;
DropDownItem.displayName = 'DropDownItem';

const DropDownInput = styled.input`
  ${buttonsSize};
  outline: none;
  color: ${props => getColor(props, 'darkGrey')};
  background: ${props => getColor(props, 'white')};
  border: none;
  text-align: left;
`;
DropDownInput.displayName = 'DropDownInput';

const calculateDirectionDependingOnParent = (rect: DOMRect, parent: Element): DropDownDirection => {
  const parentRect = parent.getBoundingClientRect();
  const MIN_ACCEPTABLE_DISTANCE_TO_DROPDOWN_TOP_BORDER = 140;
  return parentRect.bottom - rect.top < MIN_ACCEPTABLE_DISTANCE_TO_DROPDOWN_TOP_BORDER
    ? DropDownDirection.TOP
    : DropDownDirection.BOTTOM;
};

const calculateDependingOnWindowHeight = (rect: DOMRect): DropDownDirection => {
  const MIN_ACCEPTABLE_DISTANCE_TO_DROPDOWN_TOP_BORDER = 200;
  return window.innerHeight - rect.top < MIN_ACCEPTABLE_DISTANCE_TO_DROPDOWN_TOP_BORDER
    ? DropDownDirection.TOP
    : DropDownDirection.BOTTOM;
};

const handleParentAsSelector = (rect: DOMRect, parent: string): DropDownDirection => {
  const element = document.querySelector(parent);
  if (element) return calculateDirectionDependingOnParent(rect, element);
  console.info(`Make sure you passed a valid "parent" selector. Passed "${parent}" is not found in the DOM.`);
  return calculateDependingOnWindowHeight(rect);
};

const handleParentAsRef = (rect: DOMRect, parent?: MutableRefObject<HTMLDivElement | null>): DropDownDirection => {
  if (parent && parent.current) return calculateDirectionDependingOnParent(rect, parent.current);
  return calculateDependingOnWindowHeight(rect);
};

export const calculateDropDirection = (
  ref: MutableRefObject<HTMLDivElement | null>,
  parent?: MutableRefObject<HTMLDivElement | null> | string
): DropDownDirection => {
  if (ref && ref.current instanceof Node) {
    const rect = ref.current.getBoundingClientRect();
    if (typeof parent === 'string') {
      return handleParentAsSelector(rect, parent);
    }
    return handleParentAsRef(rect, parent);
  }
  return DropDownDirection.BOTTOM;
};

interface ButtonWithChevronProps {
  direction: DropDownDirection;
  invalid: boolean;
  open: boolean;
  styles: ButtonWithChevronStyles;
  isChevronHidden?: boolean;
}

const ButtonWithChevron = styled(Button)<ButtonWithChevronProps>`
  ${ellipsisText};
  ${buttonsSize};
  width: 100%;
  position: relative;
  border: 1px solid ${props => (!props.invalid ? getColor(props, 'grey') : getColor(props, 'red'))};
  color: ${props => getColor(props, props.styles.color)};
  ${({ styles }) =>
    styles.isShadowed
      ? `
    text-shadow: 0px 0px 1px;
  `
      : ''};
  text-align: left;
  background: #fff;
  &:after {
    position: absolute;
    color: ${props => getColor(props, 'darkGrey')};
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    width: 0;
    height: 0;
    content: '';
    border-top: 5px solid;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    ${props => (props.open ? 'border-top: 0; border-bottom: 5px solid;' : '')};
    ${props => (props.isChevronHidden ? 'border: none;' : '')};
  }
`;
ButtonWithChevron.displayName = 'ButtonWithChevron';

const CustomizableButton = styled(Button)<{ stylesMixin?: FlattenSimpleInterpolation }>`
  padding: 0;
  min-width: 0;
  min-height: 0;
  border: none;
  ${({ stylesMixin }) => stylesMixin || ''};
`;

export const DropDownComponent = (props: OwnProps): ReactElement => {
  const {
    style,
    className,
    items,
    selectedValue,
    disabled,
    invalid = false,
    placeholder = '',
    parent,
    onChange = (value: DropDownValue) => {
      console.info(`"onChange" handler does not specified for DropDown. Selected value: ${value}`);
    },
    onToggle = (value: boolean) => {
      console.info(`"onToggle" handler does not specified for DropDown. Selected value: ${value}`);
    },
    isCheckIconEnabled,
    isCustomInputEnabled,
    maxCustomValueAmount = 6,
    customInputFilter = (value: string) => value,
    customInputItemNameFilter = (value: string) => value,
    manualDirection,
    buttonWithChevronStyles = { color: 'color1', isShadowed: true },
    alwaysShowPlaceholder,
    backgroundForActive,
    dataTestId,
    renderCustomButton,
    customButtonStylesMixin,
    isChevronHidden
  } = props;

  const { t } = useTranslation(DEFAULT_NS);
  const customInputPlaceholder = t('LoyaltyCustomInputPlaceholder', 'Enter value');
  const customInputItemName = t('LoyaltyThreshHoldOption4', 'Custom');
  const wrapperRef = useRef<HTMLDivElement>(null);

  const [open, toggleOpen] = useState(false);
  const [focusCustomInput, toggleFocus] = useState(false);
  const [customInputValue, setCustomValue] = useState<DropDownValue>('');

  useEffect(() => {
    onToggle(open);
  }, [open]);

  const closeDropDown = (): void => {
    toggleOpen(false);
  };

  const onChangeInputValue = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const newValue = customInputFilter(e.target.value);
    if (!isNil(newValue)) {
      setCustomValue(newValue);
    }
  };

  const onClose = (e: Event): void => {
    if (wrapperRef && wrapperRef.current && !wrapperRef.current.contains(e.target as Node)) {
      toggleOpen(false);
    }
  };

  const onBlurInput = (): void => {
    if (customInputValue) {
      onChange(customInputValue);
    }
    toggleFocus(false);
    closeDropDown();
  };

  const onKeyUp = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter' && customInputValue) {
      onChange(customInputValue);
      toggleFocus(false);
      closeDropDown();
    }
  };

  useEffect(() => {
    if (open) return bindDocumentClickOrTouchListener(onClose);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  let selectedItem: DropdownItem = items.find((item: DropdownItem) => item.value === selectedValue) || {
    label: '',
    value: ''
  };

  useEffect(() => {
    if (!selectedItem.value && selectedValue) setCustomValue(selectedValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue]);

  const isCustomItemSelected = selectedValue !== '' && customInputValue === selectedValue;
  if (isCustomInputEnabled && !selectedItem.value && isCustomItemSelected) {
    selectedItem = { label: customInputItemNameFilter(customInputValue), value: customInputValue };
  }

  const direction = typeof manualDirection === 'number' ? manualDirection : calculateDropDirection(wrapperRef, parent);

  const closedStatePlaceholder = !open ? selectedItem.label || placeholder : <span>&nbsp;</span>;

  const buttonOnclickHandler = (e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault();
    if (!disabled) toggleOpen(!open);
  };

  const buttonClass = useMemo(() => {
    if (open) {
      return 'isOpen';
    }
    if (disabled) {
      return 'disabled';
    }

    return '';
  }, [open, disabled]);

  return (
    <div style={style} className={className} ref={wrapperRef} data-test-id={dataTestId}>
      {renderCustomButton ? (
        <CustomizableButton
          onClick={buttonOnclickHandler}
          className={buttonClass}
          stylesMixin={customButtonStylesMixin}
        >
          {renderCustomButton({ direction, invalid, open })}
        </CustomizableButton>
      ) : (
        <ButtonWithChevron
          onClick={buttonOnclickHandler}
          direction={direction}
          invalid={invalid}
          open={open}
          styles={buttonWithChevronStyles}
          className={buttonClass}
          isChevronHidden={isChevronHidden}
        >
          {alwaysShowPlaceholder ? placeholder : closedStatePlaceholder}
        </ButtonWithChevron>
      )}
      {open && (
        <DropDownBody direction={direction} invalid={invalid} data-test-id={dataTestId && `${dataTestId}-items`}>
          {items.map((item: DropdownItem): React.ReactNode => {
            const { label, value: currentValue, bodyLabel, disabled: disabledButton, hint, hintPlace } = item;
            return (
              <DropDownItem
                data-test-id={item.dataTestId}
                key={currentValue}
                onClick={() => {
                  closeDropDown();
                  onChange(currentValue);
                  setCustomValue('');
                }}
                active={currentValue === selectedValue}
                disabled={disabledButton}
                backgroundForActive={backgroundForActive}
                hintText={hint}
                textAlign="left"
                hintPlace={hintPlace}
              >
                {isCheckIconEnabled && currentValue === selectedValue && <i className="fa fa-check" />}
                {bodyLabel && bodyLabel}
                {!bodyLabel && label}
              </DropDownItem>
            );
          })}
          {isCustomInputEnabled && (
            <>
              {!focusCustomInput ? (
                <DropDownItem
                  onClick={e => {
                    e.nativeEvent.stopImmediatePropagation();
                    toggleFocus(true);
                  }}
                  active={isCustomItemSelected}
                >
                  {isCheckIconEnabled && isCustomItemSelected && <i className="fa fa-check" />}
                  {customInputValue ? customInputItemNameFilter(customInputValue) : customInputItemName}
                </DropDownItem>
              ) : (
                <DropDownInput
                  autoFocus
                  type="text"
                  maxLength={maxCustomValueAmount}
                  placeholder={customInputPlaceholder}
                  value={customInputValue}
                  onClick={e => {
                    e.nativeEvent.stopImmediatePropagation();
                  }}
                  onChange={onChangeInputValue}
                  onBlur={onBlurInput}
                  onKeyUp={onKeyUp}
                />
              )}
            </>
          )}
        </DropDownBody>
      )}
    </div>
  );
};

export const DropDown = styled(DropDownComponent)`
  display: inline-block;
  position: relative;
  width: ${props => props.width || '100%'};
  ${props =>
    props.disabled
      ? `
    cursor: not-allowed;
    opacity: .7;
    > * {
      pointer-events: none;
    }
  `
      : ''};
`;
