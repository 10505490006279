import { get, isEmpty } from 'lodash';
import moment from 'moment';

import { AppState } from 'store/rootReducer';
import { EU_ACCOUNTS, YEAR_MONTH_FORMAT } from 'appConstants';
import { getSelectedLocationsInfo, isOneLocationSelected } from 'store/settings';
import { MapKeyString } from 'appConstants/commonTypes';
import { initialState } from 'store/masterCard/reducer';
import { ComparisonTableOptions, DemographicCompareData, LmiPeriods, LmiRecord } from './types';

interface LmiDataInterface {
  period: string | null | undefined;
  data: { location: LmiRecord; competitors: LmiRecord; industry: LmiRecord } | {} | null;
}

export const isLoading = (state: AppState): boolean => state.masterCard.loading;

export const isGeoInfoLoading = (state: AppState): boolean => get(state, 'masterCard.geoInfo.loading', false);

export const getSelectedPeriod = (state: AppState): string => get(state, 'masterCard.period', initialState.period);

export const getData = (state: AppState): LmiPeriods | null => get(state, 'masterCard.data.result', null);

export const getDataForLastPeriod = (state: AppState): LmiDataInterface => {
  const data: LmiPeriods | null = getData(state);
  const { period } = state.masterCard;
  if (data) {
    return {
      period,
      data: period ? data[period] : {}
    };
  }

  return { period: null, data: null };
};

export const getPostalCodesInfo = (state: AppState) => get(state, 'masterCard.geoInfo.postalCodesInfo', {});

export const getLocationCoordinates = (state: AppState) => get(state, 'masterCard.geoInfo.locationCoordinates', {});

export const getGeoInfoForSelectedLocation = (state: AppState) => get(state, 'data.selectedLocationInfo', {});

export const getCountryCode = (state: AppState): string => get(state, 'masterCard.geoInfo.country', '');

export const getDataForPreviousPeriod = (state: AppState): LmiDataInterface => {
  const period = getSelectedPeriod(state);
  const prevPeriod = period ? moment(period).subtract(1, 'month').format(YEAR_MONTH_FORMAT) : null;

  return {
    period: prevPeriod,
    data: prevPeriod ? get(getData(state), prevPeriod, {}) : {}
  };
};

export const getDataForYearAgoPeriod = (state: AppState): LmiDataInterface => {
  const period = getSelectedPeriod(state);
  const yearAgoPeriod = period ? moment(period).subtract(12, 'month').format(YEAR_MONTH_FORMAT) : null;
  return {
    period: yearAgoPeriod,
    data: yearAgoPeriod ? get(getData(state), yearAgoPeriod, {}) : {}
  };
};

export const getHistoricalData = (state: AppState, duration = 6) => {
  const result = [];
  let period = getSelectedPeriod(state) || moment().subtract(1, 'month').format(YEAR_MONTH_FORMAT);
  while (result.length <= duration - 1) {
    result.push({
      period,
      data: get(getData(state), period, {})
    });
    period = moment(period, YEAR_MONTH_FORMAT).subtract(1, 'month').format(YEAR_MONTH_FORMAT);
  }
  return result;
};

export const getCompareData = (state: AppState): MapKeyString<DemographicCompareData[]> | null =>
  get(state, 'masterCard.compareData', null);

export const getMasterCardCompareDataForSelectedPeriod = (state: AppState): DemographicCompareData[] => {
  const compareData = getCompareData(state);
  if (compareData) {
    const { period } = state.masterCard;
    const locationsInfo = getSelectedLocationsInfo(state);

    return locationsInfo.map(locationInfo => {
      const merchantData = compareData[period]
        ? compareData[period].find(({ mid }) => mid === locationInfo.merchant_number) || {}
        : {};

      return {
        ...merchantData,
        mid: locationInfo.merchant_number,
        locationInfo
      };
    });
  }
  return [];
};

export const getMasterCardCompareTableOptions = (state: AppState): ComparisonTableOptions =>
  state.masterCard.comparisonTableOptions;

export const getMockedDataForPrevious6Months = () =>
  new Array(6)
    .fill({ period: '', data: {} })
    .map((nothing, index) => ({
      period: moment().subtract(index, 'months').format(YEAR_MONTH_FORMAT),
      data: {}
    }))
    .reverse();

export const isMasterCardMocked = (state: AppState): boolean =>
  !isOneLocationSelected(state) || isEmpty(getDataForLastPeriod(state).data);

export const getOverallSalesByPostalCode = (data: LmiDataInterface) =>
  get(data, 'data.location.overallSalesByPostalCodePercen', {});
export const getNewCustomersByPostalCode = (data: LmiDataInterface) =>
  get(data, 'data.location.newCustomersByPostalCodePercen', {});
export const getCompetitorsByPostalCode = (data: LmiDataInterface) =>
  get(data, 'data.competitors.competitorsByZipCodePercen', {});

export const getPeriods = (state: AppState, isCompare?: boolean): string[] => {
  const data = isCompare ? getCompareData(state) : getData(state);
  return data ? Object.keys(data) : [];
};

export const isEUAccount = (state: AppState) => {
  const accountName = state.settings.accountName;
  return (accountName && EU_ACCOUNTS.includes(accountName)) || false;
};
