import styled from 'styled-components';
import Tippy from '@tippyjs/react';
import { getColor } from '../../layout/theme';

export const DayPickerTippy = styled(Tippy)`
  background-color: ${props => getColor(props, 'darkGrey')} !important;
  opacity: 0.9;
  padding: 7px;
  font-size: 10px !important;
  border-radius: 0 !important;
  line-height: 1.2 !important;

  .tippy-arrow {
    color: ${props => getColor(props, 'darkGrey')};
    opacity: 0.9;

    ::before {
      border-top-color: ${props => getColor(props, 'darkGrey')} !important;
    }
  }
`;
