/* eslint-disable camelcase */
export interface Contact {
  id: number;
  first_name: string;
  last_name: string;
  mobile_phone: string;
  email: string;
  birthday: string;
  location_id: number;
  member_type: string;
  created_at: string;
  is_company_contact_source: boolean;
}

export interface ContactValidationError {
  email?: string;
  first_name?: string;
  birthday?: string;
  last_name?: string;
  mobile_phone?: string;
  location_id?: number;
}

export interface ContactsList {
  page: number;
  limit: number;
  keyword: string;
  totalCount: number;
  overallCount: number;
  data: Contact[];
  isTermsAndConditionsAccepted?: boolean;
  showEmailExistsAsCompanyEmailModal?: boolean;
  sort?: string;
  validationErrors?: ContactValidationError;
  savingStatus?: ContactSavingStatus;
  loading?: boolean;
  fetched?: boolean;
  emContactsCount?: number;
  loyaltyContactsCount?: number;
}

export interface BackgroundJobStatus {
  working: boolean;
  done: boolean;
  name: string;
  title: string;
  body: string;
}

export interface ContactsState {
  active: ContactsList;
  unsubscribed: ContactsList;
  bounced: ContactsList;
  isTermsAndConditionsAccepted: boolean;
  showEmailExistsAsCompanyEmailModal: boolean;
  backgroundJobStatus: BackgroundJobStatus;
}

export type ContactsTableColumn = 'FirstName' | 'LastName' | 'E-MailAddress' | 'Birthday';

export enum ContactSavingStatus {
  Pending = 0,
  Success = 1,
  Failure = -1
}
