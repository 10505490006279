import { get, isEmpty, cloneDeep, pickBy, mapValues } from 'lodash';
import { SegmentsTranslations } from 'components/LargestSegments/types';
import segmentsDictionary from 'components/LargestSegments/segmentsDictionary';
import { categoriesDictionary } from 'components/CustomerDemographics/categoriesDictionary';
import { Tick } from 'shared-components/AverageBarChart/types';
import { DemographicDictionary, DictionaryTranslations, Features } from 'components/CustomerDemographics/types';
import { DemographicCompareResponse, LmiPeriod } from 'store/masterCard/types';
import moment from 'moment';
import { YEAR_MONTH_FORMAT } from 'appConstants';
import { AppState } from '../rootReducer';
import { getDataForLastPeriod } from './selectors';
import { initialState } from 'store/masterCard/reducer';

export const mapLmiDataToDemographics = (
  lmiData: { period: string; data: LmiPeriod | {} }[],
  i18n: DictionaryTranslations,
  features: Features
) => {
  const result: { [key: string]: DemographicDictionary } = {};
  for (const period of lmiData) {
    const categories = cloneDeep(categoriesDictionary(i18n, features));
    result[period.period] = categories;
    if (!isEmpty(period.data)) {
      Object.entries(categories).forEach(([categoryId, categoryData]) => {
        Object.entries(categoryData.segments).forEach(([segmentId, segmentData]) => {
          // eslint-disable-next-line no-param-reassign
          segmentData.value = get(period, `data.location.${categoryId}Percen[${segmentId.replace('segment', '')}]`, 0);
        });
      });
    }
  }
  return result;
};

export const mapSegmentsWithDictionary = (state: AppState, i18n: SegmentsTranslations) => {
  const segments = cloneDeep(segmentsDictionary(i18n));
  const period = getDataForLastPeriod(state);
  const segmentsData = pickBy(
    get(period, 'data.location.segPercen', {}),
    (value, segmentId) => value && Object.keys(segments).includes(segmentId)
  );

  if (isEmpty(period.data) || isEmpty(segmentsData)) return pickBy(segments, segment => segment.isDefault);

  return mapValues(segmentsData, (segmentValue, segmentId) => ({
    ...get(segments, segmentId),
    value: segmentValue
  }));
};

export const mapLmiDataToBarChartEntries = (lmiData: { period: string; data: {} }[], property: string) => {
  const chartData: Tick[] = [];
  while (chartData.length < lmiData.length) {
    const periodData = lmiData[chartData.length];
    const locationData = get(periodData, `data.location.${property}`);
    const competitorsData = get(periodData, `data.competitors.${property}`);
    const isLocationDataEmpty = locationData === undefined;
    const isCompetitorsDataEmpty = competitorsData === undefined;

    const location: number =
      isLocationDataEmpty && chartData.length ? chartData[chartData.length - 1].location : locationData || 0;

    const competitors: number =
      isCompetitorsDataEmpty && chartData.length ? chartData[chartData.length - 1].competitors : competitorsData || 0;

    chartData.push({
      period: periodData.period,
      location,
      isLocationDataEmpty,
      competitors,
      isCompetitorsDataEmpty
    });
  }
  return chartData;
};

export const defineLatestAvailablePeriod = (periods: string[] | null, currentPeriod: string): string => {
  if (periods && !periods.includes(currentPeriod)) {
    const lastPeriod = moment.max(periods.map(period => moment(period)));
    return lastPeriod.format(YEAR_MONTH_FORMAT);
  }
  return currentPeriod;
};

export const filterResponseByLastAvailablePeriod = (data: DemographicCompareResponse): DemographicCompareResponse =>
  Object.entries(data)
    .filter(([period]) => moment(period, YEAR_MONTH_FORMAT) <= moment(initialState.period, YEAR_MONTH_FORMAT))
    .reduce((accum, [period, periodData]) => ({ ...accum, [period]: periodData }), {});
