import React, { ReactElement } from 'react';
import styled from 'styled-components';

import Text from './icons/text.svg';
import Button from './icons/button.svg';
import Image from './icons/image.svg';
import Coupon from './icons/coupon.svg';
import Divider from './icons/divider.svg';
import Spacer from './icons/spacer.svg';
import Text2Col from './icons/text2col.svg';
import Social from './icons/social.svg';

const icons: { [key: string]: string } = {
  text: Text,
  button: Button,
  image: Image,
  coupon: Coupon,
  divider: Divider,
  spacer: Spacer,
  text2col: Text2Col,
  social: Social
};

const Element = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60px;
  user-select: none;
  cursor: grab;

  margin: 10px;

  & img {
    width: 60px;
    height: 60px;
  }

  & span {
    width: 100%;
    height: 20px;
    text-align: center;
  }
`;

interface OwnProps {
  type: string;
  translations: { [key: string]: string };
}

export const DesignElement = (props: OwnProps): ReactElement => {
  const { type, translations } = props;
  const upperCaseType = `${type.charAt(0).toUpperCase()}${type.slice(1)}`;
  const icon: string = icons[type];
  // @ts-ignore
  const label: string = translations[`editorItems${upperCaseType}Name`];

  return (
    <Element data-type={type}>
      <img src={icon} alt={type} />
      <span>{label}</span>
    </Element>
  );
};
