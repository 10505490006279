import styled from 'styled-components';
import React, { useEffect, useRef } from 'react';
import { AppTray } from '@app-tray-ui/react-wrapper';
import { debounce } from 'lodash';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  closeLeftHandSideMenu,
  getLeftSideMenuState,
  getMenuPagesWithFiltration,
  isTopMenuShown,
  LeftMenuStates,
  openLeftHandSideMenu,
  toggleTopMenu
} from 'store/navigation';

import { getColor } from 'layout/theme';
import { Page, PagesList } from 'store/settings/layout';
import { AppState } from 'store/rootReducer';
import { deviceMedia, headerHeight, topMenuOverlayZIndex } from 'appConstants';
import { ReactComponent as CloseIcon } from 'assets/icons/close-filled.svg';
import { resetButtonStyles } from 'layout/mixins';
import { getPortalPageItem, isAppHubTrayEnabled as isAppHubTrayEnabledSelector } from 'store/branding/selectors';
import { googleAnalytics } from 'services/googleAnalytics';
import { bindDocumentClickOrTouchListener } from 'services/utils';
import { ReactComponent as TrayIcon } from 'assets/icons/trey.svg';
import { useTranslation } from 'react-i18next';
import { DEFAULT_NS } from 'appConstants/translationNamespaces';
import {
  getAppHubTrayItemsSelector,
  getGPUserId,
  getLoginJWT,
  isAppHubTraySelector,
  settingsActions
} from 'store/settings';
import { DropdownItems } from './DropdownItems';
import { DropdownMenuToolbar } from './DropdownToolbar';

const MAX_ITEMS = 8;
const MAX_ITEMS_MOBILE = 6;

const DropdownMenuContainer = styled.div`
  background: #fff;
  display: inline-block;
  width: 700px;
  padding-top: ${headerHeight}px;
  position: absolute;
  top: 0px;
  right: 0px;
  pointer-events: initial;
  @media ${deviceMedia.tablet} {
    width: 100%;
  }
  z-index: ${topMenuOverlayZIndex + 1};
`;

const DropdownMenuMoreBtn = styled.button`
  ${resetButtonStyles};
  height: 50px;
  border-top: 1px solid ${props => getColor(props, 'mediumGrey')};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => getColor(props, 'color1')};
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  width: 100%;
`;

DropdownMenuMoreBtn.displayName = 'DropdownMenuMoreBtn';

const StyledCloseIcon = styled(CloseIcon)`
  width: 100%;
  height: 100%;
`;

const CloseButton = styled.button`
  ${resetButtonStyles};
  width: 20px;
  height: 20px;
  position: absolute;
  top: ${headerHeight / 2}px;
  left: 20px;
  transform: translateY(-50%);
`;

const ToggleMenuIcon = styled(TrayIcon)`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  g[fill] {
    fill: ${props => getColor(props, 'darkGrey')};
  }
`;

const ToggleMenuButton = styled.button`
  ${resetButtonStyles};
  position: relative;
  width: 25px;
  height: 23px;
  z-index: ${topMenuOverlayZIndex + 2};
`;

const MenuButtonContainer = styled.div<{ active?: boolean; isSmallZIndex?: boolean }>`
  position: relative;
  padding: 5px;
  border-radius: 5px;
  z-index: ${({ isSmallZIndex }) => (isSmallZIndex ? 10 : topMenuOverlayZIndex + 2)};
  ${props => (props.active ? ` background-color: #EFEFEF; ` : '')}
`;

const MenuContainer = styled.div`
  margin-right: -5px;
`;

interface OwnProps {
  isMobile?: boolean;
  isTablet?: boolean;
}

interface ConnectedProps {
  pages: PagesList;
  shown: boolean;
  leftMenuState: LeftMenuStates;
}

interface ConnectedActions {
  toggleMenu: typeof toggleTopMenu;
  openLeftMenu: typeof openLeftHandSideMenu;
  closeLeftMenu: typeof closeLeftHandSideMenu;
  getAppHubTrayItems: typeof settingsActions.getAppHubTrayItems.request;
}

type PropTypes = ConnectedProps & ConnectedActions & OwnProps;

const StyledAppTray = styled(AppTray)`
  position: absolute;
  top: 100%;
  right: 32px;
  margin-top: -1px;

  @media ${deviceMedia.mobile} {
    right: 0;
    left: 0;
    width: calc(100% + 1px);
    max-width: calc(100% + 1px);
  }
`;

export const DropdownMenuComponent = ({
  shown,
  pages,
  toggleMenu,
  leftMenuState,
  openLeftMenu,
  closeLeftMenu,
  getAppHubTrayItems,
  isMobile,
  isTablet
}: PropTypes): JSX.Element => {
  const { t } = useTranslation(DEFAULT_NS);
  const containerEl = useRef<HTMLDivElement>(null);
  const [searchString, setSearchString] = React.useState<string>('');
  const [showAll, setShowAll] = React.useState<boolean>(false);

  const gpUserId = useSelector(getGPUserId);
  const jwt = useSelector(getLoginJWT);
  const isAppHubTrayEnabled = useSelector(isAppHubTrayEnabledSelector);
  const isAppHubTray = useSelector(isAppHubTraySelector);
  const appHubTrayItems = useSelector(getAppHubTrayItemsSelector);

  const filteredPages = pages.filter(
    page => page.title && page.title.toLowerCase().includes(searchString.toString().toLowerCase())
  );

  const documentClickHandler = debounce((e: MouseEvent) => {
    if (containerEl && containerEl.current && !containerEl.current.contains(e.target as Node)) toggleMenu(false);
  }, 100);

  useEffect(() => {
    if (shown) return bindDocumentClickOrTouchListener(documentClickHandler);
    return () => {};
  }, [shown, documentClickHandler]);

  const onItemClick = (page: Page): void => {
    if (page.pageName === 'portal') {
      googleAnalytics.events.portal.open('Menu');
    }
    googleAnalytics.events.dotsMenu.selectPage(page.title);
    toggleMenu();
    if (leftMenuState === 'openTabsHidden' && Array.isArray(page.subPages) && page.subPages.length) {
      openLeftMenu();
    }
  };

  const onToggleClick = (): void => {
    if (appHubTrayItems.loading) return;
    if (isTablet && !shown && leftMenuState === 'open') closeLeftMenu();
    toggleMenu();
  };

  const maxItems = isMobile ? MAX_ITEMS_MOBILE : MAX_ITEMS;

  useEffect(() => {
    if (isAppHubTrayEnabled && gpUserId && jwt) getAppHubTrayItems({ gpUserId, jwt });
  }, [isAppHubTrayEnabled, gpUserId, getAppHubTrayItems]);

  const menuElement = isAppHubTray ? (
    <StyledAppTray applications={appHubTrayItems.data} />
  ) : (
    <DropdownMenuContainer data-test-id="expanded-4dot-menu">
      <CloseButton
        data-test-id="4dot-menu-close-button"
        onClick={() => {
          toggleMenu(false);
        }}
      >
        <StyledCloseIcon />
      </CloseButton>

      <DropdownMenuToolbar
        searchValue={searchString}
        onSearchChange={event => setSearchString(event.currentTarget.value)}
        searchPlaceholder={t('fourDotsAppPlaceholder', 'Search apps')}
        title={t('fourDotsAppTitle', 'Applications')}
      />

      <DropdownItems
        onItemClick={onItemClick}
        items={showAll ? filteredPages : filteredPages.slice(0, maxItems)}
        notFoundEl={t('newLocationSelectorNoLocations', 'No results found')}
      />

      {filteredPages.length > maxItems && (
        <DropdownMenuMoreBtn data-test-id="4dot-menu-more-btn" onClick={() => setShowAll(!showAll)}>
          {showAll ? t('less', 'Less') : t('more', 'More')}
        </DropdownMenuMoreBtn>
      )}
    </DropdownMenuContainer>
  );

  return (
    <MenuContainer ref={containerEl}>
      <MenuButtonContainer active={shown} isSmallZIndex={isAppHubTray}>
        <ToggleMenuButton data-test-id="4dot-menu" onClick={onToggleClick}>
          <ToggleMenuIcon />
        </ToggleMenuButton>
      </MenuButtonContainer>
      {shown ? menuElement : null}
    </MenuContainer>
  );
};

export const DropdownMenu = connect<ConnectedProps, ConnectedActions, OwnProps, AppState>(
  (state: AppState) => {
    const pages: PagesList = getMenuPagesWithFiltration(state, 'topMenu');
    const portalPageItem = getPortalPageItem(state);
    if (portalPageItem) pages.push(portalPageItem);

    return {
      pages,
      shown: isTopMenuShown(state),
      leftMenuState: getLeftSideMenuState(state)
    };
  },
  dispatch =>
    bindActionCreators(
      {
        toggleMenu: toggleTopMenu,
        openLeftMenu: openLeftHandSideMenu,
        closeLeftMenu: closeLeftHandSideMenu,
        getAppHubTrayItems: settingsActions.getAppHubTrayItems.request
      },
      dispatch
    )
)(DropdownMenuComponent);
