interface AuthDeclinesStatItem {
  authPercent: number;
  authorizationCount: number;
  declineCount: number;
  totalCount: number;
  datetime: string;
}

export interface PaymentsRequestPayload {
  locations: string[];
  start: string;
  end: string;
}
export interface PaymentsRequestGroupedPayload extends PaymentsRequestPayload {
  groupBy: 'hour' | 'day';
}

export type PaymentsDeclineCodesResponse = { count: number; code: string }[];

export interface PaymentsAuthRateStatResponse {
  stat: AuthDeclinesStatItem[];
  lowest: AuthDeclinesStatItem | null;
  highest: AuthDeclinesStatItem | null;
}

export interface PaymentTransactionsSummary {
  ratePercent: number;
  transactionsCount: number;
  momPercent: number;
  yoyPercent: number;
}

export interface SalesSummary {
  value: number;
  momPercent: number;
  yoyPercent: number;
}

export type PaymentsSalesSummaryResponse = Record<'gross' | 'net', SalesSummary>;
export type PaymentsRefundsChargebackSummaryResponse = Record<'chargebackAmount' | 'refundAmount', SalesSummary> &
  Record<'refundRate' | 'chargebackRate', PaymentTransactionsSummary>;

export interface SalesGrossNetStatResponse {
  authorizationSalesAmount: number;
  chargebackSalesAmount: number;
  refundSalesAmount: number;
  grossSalesAmount: number;
  netSalesAmount: number;
  datetime: string;
}

export type PaymentsSalesGrossNetStatResponse = SalesGrossNetStatResponse[];

export enum PaymentsCardTypesEnum {
  VISA = 'VISA',
  MASTERCARD = 'MASTERCARD',
  AMERICAN_EXPRESS = 'AMERICAN_EXPRESS',
  DISCOVER = 'DISCOVER'
}

export interface RefundsChargebacksCard {
  amount: number | null;
  percent: number | null;
  cardType: PaymentsCardTypesEnum;
}

export type PaymentsRefundsChargebacksCardsResponse = RefundsChargebacksCard[];

export type PaymentsTransactionsRefundedResponse = {
  count: number | null;
  datetime: string;
}[];

export type PaymentsTransactionsRefundResponse = {
  refundPercent: number;
  datetime: string;
}[];

export type PaymentsTransactionsChargebackResponse = {
  chargebackPercent: number;
  datetime: string;
}[];
