import { createAction } from 'typesafe-actions';
import { PagesList } from '../settings/layout';
import { LeftMenuStates } from './types';
import { MerchantPages } from '../settings';

export const openLeftHandSideMenu = createAction(
  '@menu/left/CHANGE_STATE',
  action => () => action({ state: 'open' as LeftMenuStates })
);

export const closeLeftHandSideMenu = createAction(
  '@menu/left/CHANGE_STATE',
  action => () => action({ state: 'closed' as LeftMenuStates })
);

export const hideTabsLeftHandSideMenu = createAction(
  '@menu/left/CHANGE_STATE',
  action => () => action({ state: 'openTabsHidden' as LeftMenuStates })
);

export const setActivePage = createAction(
  '@menu/SET_ACTIVE_PAGE',
  action => (pathname: string) => action({ location: { pathname } })
);

export const receivePages = createAction(
  '@menu/RECEIVE_PAGES',
  action => (pages: PagesList, merchantPagesStatus: MerchantPages) => action({ pages, merchantPagesStatus })
);

export const toggleTopMenu = createAction('@menu/top/CHANGE_STATE', action => (shown?: boolean) => action({ shown }));

export const navigationActions = {
  openLeftHandSideMenu,
  closeLeftHandSideMenu,
  hideTabsLeftHandSideMenu,
  setActivePage,
  receivePages,
  toggleTopMenu
};
