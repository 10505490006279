import { get } from 'lodash';
import { createReducer } from 'typesafe-actions';
import { campaignEditorActions } from './actions';

const defaultCampaignEditorState: any = {
  staticTemplates: [],
  customerTemplates: [],
  customerTemplatePreview: { pending: false, template: undefined },
  optionsTabId: 0,
  crmCampaignId: -1,
  campaignName: '',
  startDate: '',
  selectedContacts: [],
  isContactsInverted: true,
  loading: false,
  sendingCampaign: false,
  sendTestCampaignErrorType: '',
  memberTypes: [],
  linkedCompanies: []
};

const {
  selectOptionsTab,
  selectCampaign,
  fetchStaticTemplates,
  fetchCustomerTemplates,
  getCustomerTemplatePreview,
  deleteCustomerTemplate,
  selectCustomerTemplate,
  saveCustomerTemplate,
  sendCampaignToCustomEmail,
  clearTestCampaignErrorType,
  createCampaign,
  storeLinkedToTemplateCompanies
} = campaignEditorActions;

export const campaignEditorReducer = createReducer(defaultCampaignEditorState)
  // Options
  .handleAction(selectOptionsTab, (state, action: any) => ({ ...state, optionsTabId: action.payload }))
  // Select Campaign
  .handleAction(selectCampaign, (state, action: any) => ({
    ...state,
    crmCampaignId: get(action.payload, 'campaignId', -1),
    campaignName: get(action.payload, 'name', ''),
    startDate: get(action.payload, 'startDate', ''),
    selectedContacts: get(action.payload, 'selectedContacts', []),
    isContactsInverted: get(action.payload, 'isContactsInverted', true),
    memberTypes: get(action.payload, 'memberTypes', [])
  }))
  // Static Templates
  .handleAction(fetchStaticTemplates.request, state => ({ ...state, loading: true }))
  .handleAction(fetchStaticTemplates.success, (state, action: any) => ({
    ...state,
    staticTemplates: action.payload,
    loading: false
  }))
  .handleAction(fetchStaticTemplates.failure, state => ({ ...state, loading: false }))
  // Customer Templates
  .handleAction(fetchCustomerTemplates.request, state => ({ ...state, loading: true }))
  .handleAction(fetchCustomerTemplates.success, (state, action: any) => ({
    ...state,
    customerTemplates: action.payload,
    loading: false
  }))
  .handleAction(fetchCustomerTemplates.failure, state => ({ ...state, loading: false }))
  // Customer Template Preview
  .handleAction(getCustomerTemplatePreview.request, state => ({
    ...state,
    customerTemplatePreview: { pending: true, template: undefined }
  }))
  .handleAction(getCustomerTemplatePreview.success, (state, action) => ({
    ...state,
    customerTemplatePreview: { pending: false, template: action.payload }
  }))
  .handleAction(getCustomerTemplatePreview.failure, state => ({
    ...state,
    customerTemplatePreview: { pending: false, template: undefined }
  }))
  // Select Customer Template
  .handleAction(selectCustomerTemplate.request, state => ({ ...state, loading: true }))
  .handleAction(selectCustomerTemplate.success, state => ({ ...state, loading: false }))
  .handleAction(selectCustomerTemplate.failure, state => ({ ...state, loading: false }))
  // Delete
  .handleAction(deleteCustomerTemplate.success, (state, action: any) => ({
    ...state,
    customerTemplates: action.payload
  }))
  .handleAction(deleteCustomerTemplate.failure, state => ({ ...state, loading: false }))
  .handleAction(storeLinkedToTemplateCompanies, (state, action: any) => ({
    ...state,
    loading: false,
    linkedCompanies: action.payload
  }))
  // Save
  .handleAction(saveCustomerTemplate.request, state => ({ ...state, loading: true }))
  .handleAction(saveCustomerTemplate.success, state => ({ ...state, loading: false }))
  .handleAction(saveCustomerTemplate.failure, state => ({ ...state, loading: false }))
  // Send Test Campaign
  .handleAction(sendCampaignToCustomEmail.request, state => ({
    ...state,
    sendingCampaign: true,
    sendTestCampaignErrorType: ''
  }))
  .handleAction(sendCampaignToCustomEmail.success, state => ({
    ...state,
    sendingCampaign: false,
    sendTestCampaignErrorType: ''
  }))
  .handleAction(sendCampaignToCustomEmail.failure, (state, action: any) => ({
    ...state,
    sendingCampaign: false,
    sendTestCampaignErrorType: action.payload
  }))
  .handleAction(clearTestCampaignErrorType, state => ({
    ...state,
    sendTestCampaignErrorType: ''
  }))
  // Create Campaign
  .handleAction(createCampaign.request, state => ({ ...state, sendingCampaign: true }))
  .handleAction(createCampaign.success, state => ({ ...state, sendingCampaign: false }))
  .handleAction(createCampaign.failure, state => ({ ...state, sendingCampaign: false, loading: false }));
