import React, { useCallback, useEffect, useState } from 'react';
import { debounce, isInteger, isNaN, isNil, toNumber } from 'lodash';
import styled from 'styled-components';
import { textRegularBold, textSubtitleBold } from 'layout/mixins';
import { CURRENCY_SIGN_POSITION } from 'appConstants';
import { Hint } from 'shared-components/Hint';
import { DropDown } from 'shared-components/DropDown';
import { InputDecorator } from 'shared-components/InputDecorator';
import { localizationService } from 'services/localization';
import { CalculatorFormTranslations } from '../types';

const DEFAULT_STATE = {
  industry: {
    value: '',
    isValid: false,
    touched: false
  },
  costOfGoods: {
    value: '',
    isValid: false,
    touched: false
  },
  averageTicket: {
    value: '',
    isValid: false,
    touched: false
  },
  averageFrequency: {
    value: '',
    isValid: false,
    touched: false
  }
};

interface CalculatorFormProps {
  className?: string;
  translations: CalculatorFormTranslations;
  onFilled: Function;
  costOfGoodsOptions: any[];
  resetSuggestion: Function;
}

const Subtitle = styled.h3`
  ${textSubtitleBold};
`;

const FormGroup = styled.div`
  margin-bottom: 15px;
`;

const Label = styled.label`
  display: inline-flex;
  margin-bottom: 10px;
  touch-action: manipulation;
  align-items: center;
  ${textRegularBold};
`;

const StyledDropDown = styled(DropDown)`
  max-width: 250px;
`;

const Input = styled.input`
  display: block;
  height: 35px;
  margin: 0;
  padding: 6px 12px;
  min-height: 34px;
  outline: none;
  width: 100%;
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.theme.baseColors.grey};
  color: ${props => props.theme.baseColors.darkGrey} !important;
  border-radius: 0;

  ::placeholder {
    color: ${props => props.theme.baseColors.darkGrey} !important;
    opacity: 1;
  }
`;

export const validateNumber = (value: any, min: any, max: any, onlyInteger?: any) => {
  const valueToNumber = toNumber(value);
  if (isNaN(valueToNumber)) return false;

  if (onlyInteger && !isInteger(valueToNumber)) return false;

  return min < valueToNumber && valueToNumber < max;
};

const localizeInputNumber = (
  configDecimalDivider: string,
  value: string,
  isReplaceDecimalDotsToComma: boolean,
  maxLength: number
): string => {
  if (configDecimalDivider !== ',') return value;
  if (isReplaceDecimalDotsToComma && value.includes('.'))
    return value.replace(new RegExp(`^([0-9]{1,${maxLength}}).([0-9]{0,2})`), '$1,$2');
  return value.includes(',') ? value.replace(new RegExp(`^([0-9]{1,${maxLength}}),([0-9]{0,2})`), '$1.$2') : value;
};

export const validate = (name: any, value: any) => {
  switch (name) {
    case 'industry':
    case 'costOfGoods':
      return !!value;
    case 'averageTicket':
      return validateNumber(value, 0, 100000);
    case 'averageFrequency':
      return validateNumber(value, 0, 100);
    default:
      return false;
  }
};

const numberValuesFilter = (value: string, maxLength: number, currentValue: string, configDecimalDivider: string) => {
  if (value === '') return '';
  if (currentValue === '0' && value !== '0.' && value !== '0,') return undefined;
  const regexWithLimit = new RegExp(`^[0-9]{1,${maxLength}}(?:\\${configDecimalDivider}[0-9]{0,2})?$`);
  const formattedValue = localizeInputNumber(configDecimalDivider, value, false, maxLength);
  if (regexWithLimit.test(value) && +formattedValue >= 0) return formattedValue;
};

export const CalculatorForm = (props: CalculatorFormProps) => {
  const decimalDelimiter = localizationService.getConfigNumberDelimiters().decimal;
  const { translations, className } = props;
  const [state, setState] = useState<any>(DEFAULT_STATE);

  const currentSignPosition = localizationService.getCurrencySignPosition();

  const setFilter = (key: any) => (value: any) => {
    setState({
      ...state,
      [key]: {
        value,
        isValid: validate(key, value),
        touched: true
      }
    });
  };

  const onInputChange = debounce((name, value, maxLength: number) => {
    const number = numberValuesFilter(value, maxLength, state[name].value, decimalDelimiter);
    if (!isNil(number)) {
      setFilter(name)(number);
    }
  }, 100);

  const onBlurChange = (name: string, event: React.FocusEvent<HTMLInputElement>, maxLength: number) => {
    const { value } = event.target;
    if (parseFloat(localizeInputNumber(decimalDelimiter, value, false, maxLength)) === 0) {
      setFilter(name)('');
    }
  };

  const isFormFilled = () => {
    const fieldsState = Object.values(state).map((formField: any) => formField.isValid);
    const hasErrors = fieldsState.includes(false);
    return !hasErrors;
  };

  const submit = () => {
    const values: any = {};
    for (const [name, data] of Object.entries<any>(state)) {
      values[name] = data.value;
    }
    props.onFilled(values);
  };

  const getDecorator = useCallback(
    position => (currentSignPosition === position ? localizationService.getCurrencySymbol() : 0),
    [currentSignPosition]
  );

  const formData = {
    industry: {
      type: 'dropdown',
      label: translations.industry,
      options: [
        { value: 1, label: translations.industryOption1 },
        { value: 2, label: translations.industryOption2 },
        { value: 3, label: translations.industryOption3 }
      ],
      hint: translations.industryHint
    },
    costOfGoods: {
      type: 'dropdown',
      label: translations.COG,
      options: props.costOfGoodsOptions,
      hint: translations.COGHint
    },
    averageTicket: {
      type: 'input',
      label: translations.averageTicket,
      hint: translations.averageTicketHint,
      maxLength: 5,
      decoratorBefore: getDecorator(CURRENCY_SIGN_POSITION.before),
      decoratorAfter: getDecorator(CURRENCY_SIGN_POSITION.after)
    },
    averageFrequency: {
      type: 'input',
      label: translations.ACF,
      hint: translations.ACFHint,
      maxLength: 2
    }
  };

  useEffect(() => {
    if (isFormFilled()) {
      submit();
    } else {
      props.resetSuggestion();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return (
    <div className={className}>
      <Subtitle data-test-id="loyalty-calculator-form-header">{translations.calculatorFormTitle}</Subtitle>
      <p data-test-id="loyalty-calculator-form-description">{translations.calculatorGuide}</p>
      {Object.entries<any>(formData).map(([name, data]) => (
        <FormGroup key={name} data-test-id={`loyalty-calculator-form-${name}`}>
          <Label htmlFor={name}>
            {data.label} <Hint text={data.hint} placement="right" size={16} />
          </Label>
          {data.type === 'dropdown' && (
            <div>
              <StyledDropDown
                items={data.options}
                selectedValue={state[name].value}
                placeholder={translations.dropdownPlaceholder}
                customInputPlaceholder={translations.dropdownPlaceholder}
                invalid={!state[name].isValid && state[name].touched}
                onChange={setFilter(name)}
                dataTestId={`loyalty-calculator-form-${name}-dropDown`}
              />
            </div>
          )}
          {data.type === 'input' && (
            <InputDecorator
              dataTestId={`loyalty-calculator-form-${name}-input-wrap`}
              isBeforeShown={!!state[name].value && !!data.decoratorBefore}
              isAfterShown={!!state[name].value && !!data.decoratorAfter}
              beforeContent={data.decoratorBefore}
              afterContent={data.decoratorAfter}
              afterOffset={data.maxLength + 3}
              id={name}
              width={250}
            >
              <Input
                id={name}
                type="text"
                placeholder={translations.inputPlaceholder}
                value={
                  state[name].value && localizeInputNumber(decimalDelimiter, state[name].value, true, data.maxLength)
                }
                onChange={e => onInputChange(name, e.target.value, data.maxLength)}
                onBlur={e => onBlurChange(name, e, data.maxLength)}
              />
            </InputDecorator>
          )}
        </FormGroup>
      ))}
    </div>
  );
};
