import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import { ApiGateway } from 'services/apiGateway';
import { dataProviders, localizationService } from 'services/localization';
import { showApiError } from '../errors/utils';
import { transactionsActions } from './actions';
import { AppState } from '../rootReducer';
import { setDateRange } from '../appDateRangePicker';
import { extendCompareLocationsData, sumAllTicketTiers } from './modifiers';
import { locationsActions } from '../locations';
import { getConfig, isFeatureOrComponentEnabled, getMomentSettingsSelector } from '../settings';
import { getTransactionsEvaluatePayload, getTransactionsPayload } from './selectors';
import { getTransactionsFetcherPages } from '../navigation';
import { TransactionsEvaluateGroupResponse } from './types';
import { LocationFor } from '../../components/AdvancedAnalyticsEvaluate/types';

export function* TransactionsSagas() {
  yield takeLatest(transactionsActions.initFetchTransactionsData, function* () {
    // todo: cache already fetched data
    const state: AppState = yield select();
    const momentSettings = getMomentSettingsSelector(state, localizationService.getLocale());
    const payload = momentSettings
      ? { ...getTransactionsPayload(state), momentSettings }
      : getTransactionsPayload(state);

    if (payload.locations.length) {
      yield put(transactionsActions.fetchTransactionsData.request(payload));
    } else {
      yield put(
        transactionsActions.fetchTransactionsData.request({
          ...payload,
          locations: [getConfig(state).mockedMerchantSequenceKey]
        })
      );
    }
  });

  yield takeLatest(
    transactionsActions.fetchTransactionsData.request,
    function* ({ payload }: ReturnType<typeof transactionsActions.fetchTransactionsData.request>) {
      try {
        const state = yield select();
        const { analyticsHomePage, analyticsComparePage, advancedAnalyticsSalesPage } =
          getTransactionsFetcherPages(state);
        const isGrowthPercentageEnabled = isFeatureOrComponentEnabled(
          state,
          analyticsHomePage,
          'Overview',
          'growthPercentageEnabled'
        );
        const features = [];

        if (
          isFeatureOrComponentEnabled(state, analyticsHomePage, 'SalesTimeSegments') ||
          isFeatureOrComponentEnabled(state, analyticsHomePage, 'VisitsTimeSegments') ||
          isFeatureOrComponentEnabled(state, advancedAnalyticsSalesPage, 'SalesTimeSegments') ||
          isFeatureOrComponentEnabled(state, advancedAnalyticsSalesPage, 'VisitsTimeSegments')
        ) {
          features.push('DayPartData');
        }

        if (isFeatureOrComponentEnabled(state, analyticsHomePage, 'LineChart')) {
          if (isFeatureOrComponentEnabled(state, analyticsHomePage, 'LineChart', 'predictiveData')) {
            features.push('LineChartWithPrediction');
          } else {
            features.push('LineChart');
          }
        }

        if (isFeatureOrComponentEnabled(state, analyticsHomePage, 'Overview')) features.push('InsightsOverview');
        if (isFeatureOrComponentEnabled(state, analyticsHomePage, 'Spotlights')) features.push('AveragesAllData');
        if (isFeatureOrComponentEnabled(state, analyticsComparePage, 'CompareLocationsTable')) {
          features.push('CompareLocations');
        }
        const response = yield call(ApiGateway.fetchTransactions, payload, features);
        localizationService.setCurrentCurrencies(dataProviders.transactions, response.lineChart.currencies);
        yield put(transactionsActions.fetchTransactionsData.success(extendCompareLocationsData(response, state)));
        if (isGrowthPercentageEnabled && 'insightsOverview' in response) {
          const overviewGrowth = yield call(ApiGateway.fetchTransactionsOverviewGrowth, {
            ...payload,
            currentOverview: response.insightsOverview
          });
          yield put(transactionsActions.setOverviewGrowthData(overviewGrowth));
        }
      } catch (e) {
        yield put(transactionsActions.fetchTransactionsData.failure(e));
      }
    }
  );

  yield takeLatest([getType(locationsActions.select), getType(setDateRange)], function* () {
    yield put(transactionsActions.initFetchTransactionsData());
  });

  yield takeLatest([transactionsActions.fetchTransactionsData.failure], showApiError);
}

export function* TicketTiersSagas() {
  yield takeLatest(
    transactionsActions.fetchTicketTiers.request,
    function* (action: ReturnType<typeof transactionsActions.fetchTicketTiers.request>) {
      const state: AppState = yield select();
      try {
        let ticketTiers;
        if (action.payload.locations.length) {
          ticketTiers = yield call(ApiGateway.fetchTicketTiers, action.payload);
        } else {
          ticketTiers = yield call(ApiGateway.fetchTicketTiers, {
            ...action.payload,
            locations: [getConfig(state).mockedMerchantSequenceKey]
          });
        }
        yield put(transactionsActions.fetchTicketTiers.success(sumAllTicketTiers(ticketTiers)));
      } catch (e) {
        yield put(transactionsActions.fetchTicketTiers.failure(e));
      }
    }
  );

  yield takeLatest([getType(locationsActions.select), getType(setDateRange)], function* () {
    const state: AppState = yield select();
    yield put(transactionsActions.fetchTicketTiers.request(getTransactionsPayload(state)));
  });

  yield takeLatest([transactionsActions.fetchTicketTiers.failure], showApiError);
}

export function* WeeklySalesBreakdownSagas() {
  yield takeLatest(
    transactionsActions.fetchWeeklySalesBreakdown.request,
    function* (action: ReturnType<typeof transactionsActions.fetchWeeklySalesBreakdown.request>) {
      try {
        const state: AppState = yield select();
        const selectedPayload = action.payload.locations.length
          ? action.payload
          : {
              ...action.payload,
              locations: [getConfig(state).mockedMerchantSequenceKey]
            };
        const weeklySalesBreakdown = yield call(ApiGateway.fetchWeeklySalesBreakdown, selectedPayload);
        yield put(transactionsActions.fetchWeeklySalesBreakdown.success(weeklySalesBreakdown));
      } catch (e) {
        console.log(e);
      }
    }
  );

  yield takeLatest([getType(locationsActions.select), getType(setDateRange)], function* () {
    const state: AppState = yield select();
    yield put(transactionsActions.fetchWeeklySalesBreakdown.request(getTransactionsPayload(state)));
  });
}

export function* EvaluateTransactionsSagas() {
  yield all([
    takeEvery(transactionsActions.fetchTransactionsEvaluate.request, function* () {
      try {
        const state: AppState = yield select();
        const payloadTestGroup = getTransactionsEvaluatePayload(state, LocationFor.testGroup);
        const payloadControlGroup = getTransactionsEvaluatePayload(state, LocationFor.controlGroup);

        const [responseTestGroup, responseControlGroup]: TransactionsEvaluateGroupResponse[] = yield all([
          call(ApiGateway.fetchTransactionsEvaluate, payloadTestGroup),
          call(ApiGateway.fetchTransactionsEvaluate, payloadControlGroup)
        ]);
        yield put(
          transactionsActions.fetchTransactionsEvaluate.success({
            testGroup: responseTestGroup,
            controlGroup: responseControlGroup
          })
        );
      } catch (error) {
        yield put(transactionsActions.fetchTransactionsEvaluate.failure(error));
      }
    })
  ]);

  yield takeLatest([transactionsActions.fetchTransactionsEvaluate.failure], showApiError);
}
