import { createReducer } from 'typesafe-actions';
import { errorActions } from './actions';
import { ErrorState } from './types';

const defaultCampaignEditorState: ErrorState = {
  hasError: false,
  title: '',
  message: '',
  code: 200
};

const { setError, dropError } = errorActions;

export const errorReducer = createReducer(defaultCampaignEditorState)
  .handleAction(setError, (state, action: any) => ({ ...state, ...action.payload }))
  .handleAction(dropError, state => ({ ...state, ...defaultCampaignEditorState }));
