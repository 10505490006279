/* eslint-disable */
import { MASTERCARD, MERCHANT_CENTRIC, UBERALL, PAYMENT_ANALYTICS } from 'appConstants';

export const setLocal = (key: string, value: any): void => {
  try {
    window.localStorage.setItem(key, JSON.stringify(value));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
};

export const getLocal = (key: string) => {
  let value;

  try {
    value = window.localStorage.getItem(key);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
  if (value) return JSON.parse(value);

  return null;
};

export const keys: { [key: string]: string } = {
  [MERCHANT_CENTRIC]: 'showMerchantCentricTerms',
  [MASTERCARD]: 'showMasterCardTerms',
  [UBERALL]: 'showUberallCardTerms',
  [PAYMENT_ANALYTICS]: 'showPaymentAnalyticsCardTerms'
};
