import React, { Component, ReactElement, Suspense } from 'react';
import styled from 'styled-components';
import { LayoutElement, ElementProperties } from 'store/settings/layout';
import { getColor } from 'layout/theme';
import { deviceMedia, tabletMaxWidth } from 'appConstants';
import { SettingsState } from '../../store/settings';
import { LazyComponent } from './LazyComponent';
import ModuleLoading from './ModuleLoading';

const EXTRA_FEATURES_COMPONENT_NAME = 'ExtraFeatures';

interface LayoutElementProps {
  styles: ElementProperties;
  isTeaser?: boolean;
}

const applyStyles = ({
  styles: {
    display,
    direction,
    order,
    width,
    wrapOnLaptopM,
    laptopMWidth,
    maxHeight,
    minHeight,
    maxWidth,
    minWidth,
    padding,
    isTopAligned,
    tablet,
    mobile,
    marginTop,
    laptopM
  }
}: LayoutElementProps): string => {
  const stylesAggregator = [];

  if (width) {
    stylesAggregator.push(`flex: 1 1 ${width}%`);
    stylesAggregator.push(`max-width: ${width}%`);
  }
  if (wrapOnLaptopM) {
    stylesAggregator.push('flex-wrap: wrap;');
  }
  if (isTopAligned) {
    stylesAggregator.push('align-self: flex-start;');
  }
  if (laptopMWidth) {
    stylesAggregator.push(`
      @media ${deviceMedia.laptopM} and (min-width: ${tabletMaxWidth}px) {
        flex: 1 1 ${laptopMWidth}%;
        max-width: ${laptopMWidth}%;
      }
    `);
  }
  if (display) stylesAggregator.push(`display: ${display}`);
  if (direction) stylesAggregator.push(`flex-direction: ${direction}`);
  if (maxHeight) stylesAggregator.push(`max-height: ${maxHeight}`);
  if (minHeight) stylesAggregator.push(`min-height: ${minHeight}`);
  if (maxWidth) stylesAggregator.push(`max-width: ${maxWidth}px`);
  if (minWidth) stylesAggregator.push(`min-width: ${minWidth}px`);
  if (typeof padding === 'number' || typeof padding === 'string') stylesAggregator.push(`padding: ${padding}`);

  stylesAggregator.push(`order: ${order}`);
  if (laptopM && Object.keys(laptopM).length) {
    stylesAggregator.push(`
      @media ${deviceMedia.laptopM} {
        ${Object.entries(laptopM)
          .map(([propName, propValue]) => `${propName}:${propValue}`)
          .join(';')}
      }
    `);
  }
  if (tablet && Object.keys(tablet).length) {
    stylesAggregator.push(`
      @media ${deviceMedia.tablet} {
        ${Object.entries(tablet)
          .map(([propName, propValue]) => `${propName}:${propValue}`)
          .join(';')}
      }
    `);
  }

  if (mobile && Object.keys(mobile).length) {
    stylesAggregator.push(`
      @media ${deviceMedia.mobile} {
        ${Object.entries(mobile)
          .map(([propName, propValue]) => `${propName}:${propValue}`)
          .join(';')}
      }
    `);
  }
  if (marginTop) {
    stylesAggregator.push(`margin-top: ${marginTop}`);
  }

  return stylesAggregator.join(';');
};

export const PageRow = styled.div<LayoutElementProps>`
  display: flex;
  align-items: stretch;
  flex: 1 1 auto;

  @media ${deviceMedia.tablet} {
    flex-direction: column;
    flex: 1 1 100%;
    max-width: 100%;
  }
  /* ie11 hack*/
  @media (-ms-high-contrast: active), (-ms-high-contrast: none) {
    flex-basis: auto;
  }

  ${applyStyles};
`;

export const PageElement = styled.div<LayoutElementProps>`
  color: ${props => getColor(props, 'darkGrey')};
  ${props => !props.isTeaser && 'padding: 15px;'};

  @media ${deviceMedia.mobile} {
    ${props =>
      (props.styles.padding !== undefined && `padding: ${props.styles.padding};`) ||
      (!props.isTeaser && 'padding: 5px 0;')};
  }

  @media ${deviceMedia.tablet} {
    flex: 1 1 100%;
    max-width: 100%;
    width: 100%;
  }
  /* ie11 hack*/
  @media (-ms-high-contrast: active), (-ms-high-contrast: none) {
    flex-basis: auto;
  }

  ${applyStyles};
`;

interface ComponentProps {
  schema: LayoutElement[];
  isDemo: boolean;
}

export class Composer extends Component<ComponentProps> {
  public static displayName: string = 'PageLayoutComposer';

  public loadComponentSafely = (componentName?: string) => async () => {
    try {
      // TODO get path to components from env variable
      const module = await import(`page-elements/${componentName}`);
      return module;
    } catch (e) {
      return import('./ModuleLoadingError');
    }
  };

  public renderElement = (element: LayoutElement): ReactElement => {
    const { componentName, properties, attributes, features } = element;
    const isTeaser = !!componentName && !!componentName.match(/teaser/i);
    return (
      <PageElement
        className="page-element"
        {...attributes}
        key={element.componentName}
        styles={{ ...properties }}
        isTeaser={isTeaser}
        data-page-element-id={element.componentName}
      >
        <Suspense fallback={<ModuleLoading />}>
          <LazyComponent componentName={componentName} features={features} properties={properties} />
        </Suspense>
      </PageElement>
    );
  };

  public renderRow = (row: LayoutElement, i: number): ReactElement | null => {
    const { elements, dependsOnPath, componentName, properties } = row;
    // exit
    if (componentName === EXTRA_FEATURES_COMPONENT_NAME || !window.location.href.includes(dependsOnPath || ''))
      return null;
    if (!elements) return this.renderElement(row);
    // skip rows without elements
    if (elements.length === 0) return null;

    return (
      <PageRow styles={{ ...properties }} key={i}>
        {elements.map(this.renderRow)}
      </PageRow>
    );
  };

  public render(): React.ReactElement {
    const { schema, isDemo } = this.props;
    return <>{schema.filter(element => !isDemo || !element?.isDemoHided).map(this.renderRow)}</>;
  }
}
