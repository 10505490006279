import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import {
  DateRangePickerContainer,
  DateRangePickerDropdownElement,
  DateRangePickerToggle,
  PickerContent,
  StyledCalendarIcon
} from 'shared-components/DatePickerBasic/components';
import { getColor } from 'layout/theme';
import { bindDocumentClickOrTouchListener, formatString } from 'services/utils';
import { DEFAULT_NS } from 'appConstants/translationNamespaces';
import { useTranslation } from 'react-i18next';
import { Translations } from 'services/localization/types';
import { baseColors, deviceMedia } from 'appConstants';

interface PropTypes {
  className?: string;
  ranges: number[];
  selectedRange: number;
  daysLabelEdge: number;
  onApply: (range: number) => void;
}

const DatePickerItem = styled.div<{ selected: boolean }>`
  color: ${props => (props.selected ? getColor(props, 'white') : getColor(props, 'darkGrey'))};
  background-color: ${props => (props.selected ? getColor(props, 'color1') : getColor(props, 'lightGrey'))};
  text-align: center;
  padding: 8px 17px;
  margin-bottom: 5px;
  &:last-child {
    margin-bottom: 0;
  }
  cursor: pointer;
`;

const DatePickerItemsContainer = styled.div`
  width: 140px;
  @media ${deviceMedia.mobile} {
    width: 100%;
  }
`;

type TranslationsLabels = 'daysTitle' | 'monthsTitle';

const getLabelText = (range: number, daysLabelEdge: number, i18n: Translations<TranslationsLabels>) =>
  range > daysLabelEdge
    ? formatString(i18n.monthsTitle, range / 30).join('')
    : formatString(i18n.daysTitle, range).join('');

const renderLabel = (
  range: number,
  daysLabelEdge: number,
  color: string,
  shown: boolean,
  i18n: Translations<TranslationsLabels>
) => (
  <>
    <StyledCalendarIcon color={color} />
    <span>
      <strong>{getLabelText(range, daysLabelEdge, i18n)}</strong>
      <i className={`hideForPDF fas fa-chevron-${shown ? 'up' : 'down'}`} />
    </span>
  </>
);

const SimpleDayPickerComponent = (props: PropTypes) => {
  const { ranges, daysLabelEdge, onApply } = props;
  const [inputFocus, setInputFocus] = useState<boolean>(false);
  const [selectedRange, setSelectedRange] = useState(props.selectedRange);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation(DEFAULT_NS);

  const translations = {
    daysTitle: t('AnalyticsRFMDaysRangeTitle', 'Last {0} Days'),
    monthsTitle: t('AnalyticsRFMMonthsRangeTitle', 'Last {0} Months')
  };

  const closeDropDown = (e: Event) => {
    if (wrapperRef && wrapperRef.current && !wrapperRef.current.contains(e.target as Node)) setInputFocus(false);
  };

  useEffect(() => {
    if (inputFocus) return bindDocumentClickOrTouchListener(closeDropDown);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputFocus]);

  return (
    <DateRangePickerContainer ref={wrapperRef}>
      <DateRangePickerToggle
        data-test-id="date-picker-collapsed"
        color={baseColors.white}
        onClick={() => {
          setInputFocus(!inputFocus);
        }}
      >
        {renderLabel(selectedRange, daysLabelEdge, baseColors.white, inputFocus, translations)}
      </DateRangePickerToggle>
      {inputFocus && (
        <DateRangePickerDropdownElement positionStyles="left:0;">
          <PickerContent>
            <DatePickerItemsContainer>
              {ranges.map(range => (
                <DatePickerItem
                  key={range}
                  selected={range === selectedRange}
                  onClick={() => {
                    setSelectedRange(range);
                    setInputFocus(false);
                    setTimeout(() => onApply(range));
                  }}
                >
                  {getLabelText(range, daysLabelEdge, translations)}
                </DatePickerItem>
              ))}
            </DatePickerItemsContainer>
          </PickerContent>
        </DateRangePickerDropdownElement>
      )}
    </DateRangePickerContainer>
  );
};

export const SimpleDayPicker = styled(SimpleDayPickerComponent)``;
