import styled from 'styled-components';
import React, { ChangeEvent, ReactNode } from 'react';
import { getColor } from 'layout/theme';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { textTitle } from 'layout/mixins';
import { deviceMedia, toolBarHeight } from 'appConstants';

const DropdownMenuToolbarContainer = styled.div`
  height: ${toolBarHeight}px;
  padding: 0 20px;
  background: ${props => getColor(props, 'headerColor')};
  display: flex;
  align-items: center;
  color: ${props => getColor(props, 'white')};
  justify-content: space-between;
`;

const Title = styled.div`
  ${textTitle};
  @media ${deviceMedia.mobile} {
    font-size: 16px;
  }
`;

const Search = styled.input`
  background: ${props => getColor(props, 'white')};
  border-radius: 30px;
  padding: 7px 15px;
  line-height: 1.25;
  border: none;
  outline: none;
  font-style: italic;
  text-align: left;
  font-size: 14px;
  color: ${props => getColor(props, 'darkGrey')};
  width: 100%;
  margin-left: 5px;
`;

const SearchWrapper = styled.div`
  width: 244px;
  position: relative;
  input {
    padding-left: 43px;
  }
  @media ${deviceMedia.mobile} {
    width: ${244 * 0.8}px;
  }
`;

const StyledIcon = styled(SearchIcon)`
  position: absolute;
  width: 16px;
  height: 16px;
  left: 16px;
  top: -1px;
  bottom: 0;
  margin: auto;
`;

interface PropTypes {
  title: ReactNode;
  searchPlaceholder: ReactNode;
  onSearchChange: (event: ChangeEvent<HTMLInputElement>) => void;
  searchValue: string;
  hideSearch?: boolean;
}

export const DropdownMenuToolbar = ({
  title,
  searchPlaceholder,
  onSearchChange,
  searchValue,
  hideSearch = false
}: PropTypes): JSX.Element => (
  <DropdownMenuToolbarContainer>
    <Title>{title}</Title>
    {!hideSearch && (
      <SearchWrapper>
        <StyledIcon />
        <Search value={searchValue} onChange={onSearchChange} placeholder={`${searchPlaceholder}`} />
      </SearchWrapper>
    )}
  </DropdownMenuToolbarContainer>
);
