import React, { ReactElement } from 'react';
import { getLuminance } from 'polished';
import { androidPayButton } from '../../../appConstants';
import { Coupon as MailBoxCouponData } from '../../../store/emailMarketing/emailTemplateEditor';
import { MailBoxProps } from '../types';

export const Coupon = (props: MailBoxProps<MailBoxCouponData>): ReactElement => {
  const { boxData, styles = {} } = props;

  const { backgroundColor = '#fff', color, width } = styles;

  const { title = '', text = '', androidPay, androidPayLink = '#', startDate, endDate } = boxData;

  const androidPayIcon = getLuminance(backgroundColor) > 0.5 ? androidPayButton.dark : androidPayButton.light;

  return (
    <div style={{ backgroundColor, width }}>
      <div style={{ margin: '25px', padding: '25px', border: `2px solid ${color}` }}>
        <h1 style={{ color }}>{title}</h1>
        <div style={{ color }} dangerouslySetInnerHTML={{ __html: text }} />
        {androidPay && (
          <div>
            <a href={androidPayLink}>
              <img style={{ width: '272px', margin: '10px 0' }} src={androidPayIcon} alt="Google Pay" />
            </a>
            <div style={{ color, margin: '0 10px' }}>{`Offer valid ${startDate} through ${endDate}`}</div>
          </div>
        )}
      </div>
    </div>
  );
};
