import { createAsyncAction, createAction } from 'typesafe-actions';
import { IdentityMappingData } from './types';

const fetchIdentityMapping = createAsyncAction(
  '@merchantCentric/identityMapping/fetch',
  '@merchantCentric/identityMapping/success',
  '@merchantCentric/identityMapping/error'
)<void, IdentityMappingData, Error>();

const fetchEnterpriseData = createAction(
  '@merchantCentric/identityMapping/fetchEnterpriseData',
  action => (merchantSequenceKeys: string[]) => action({ merchantSequenceKeys })
);

export const identityMappingActions = {
  fetchIdentityMapping,
  fetchEnterpriseData
};
