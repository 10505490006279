export const getCookieByName = (name: string): string => {
  const pairs = document.cookie.split('; ');

  const aggregator = new Map<string, string>();

  pairs.forEach(pair => {
    const [key, value] = pair.split('=');
    aggregator.set(key, value);
  });

  const result = aggregator.get(name);

  return typeof result === 'undefined' || result === 'undefined' ? '' : result;
};

export const setCookie = ({
  name,
  value,
  maxAge,
  path: pathArg
}: {
  name: string;
  value: string;
  maxAge?: number;
  path?: string;
}): void => {
  const expiration = maxAge ? `;max-age=${maxAge}` : '';
  const path = pathArg ? `;path=${pathArg}` : '';
  document.cookie = `${name}=${value}${expiration}${path}`;
};
