import { createAction, createAsyncAction } from 'typesafe-actions';
import { DropdownWithButtonsListItem } from 'shared-components/DropdownWithButtons/types';
import {
  TransactionsOverviewGrowth,
  TransactionsResponse,
  FetchTransactionsRequestPayload,
  TicketTiersResponse,
  ComparisonTableOptions,
  ChartFilterValue,
  TransactionsEvaluateResponse,
  WeeklySalesBreakdownRequestPayload,
  WeeklySalesBreakdownResponse
} from './types';

const fetchTransactionsData = createAsyncAction(
  '@transactions/fetch',
  '@transactions/fetch/success',
  '@transactions/fetch/error'
)<FetchTransactionsRequestPayload, TransactionsResponse, void>();

const fetchTransactionsEvaluate = createAsyncAction(
  '@transactions/evaluate/fetch',
  '@transactions/evaluate/fetch/success',
  '@transactions/evaluate/fetch/error'
)<void, TransactionsEvaluateResponse, void>();

const setTransactionsEvaluateDefaultState = createAction('@transactions/evaluate/default', action => () => action({}));

const initFetchTransactionsData = createAction('@transactions/fetch/init', action => () => action({}));

const setOverviewGrowthData = createAction(
  '@transactions/setOverviewGrowth',
  action => (payload: TransactionsOverviewGrowth) => action(payload)
);

const setExpandedCharts = createAction(
  '@transactions/evaluate/setExpandedCharts',
  action => (payload: string[]) => action(payload)
);

const setComparisonTableOptions = createAction(
  '@transactions/setComparisonTableOptions',
  action => (payload: ComparisonTableOptions) => action(payload)
);

const fetchTicketTiers = createAsyncAction(
  '@transactions/ticketTiers/fetch',
  '@transactions/ticketTiers/success',
  '@transactions/ticketTiers/error'
)<FetchTransactionsRequestPayload, TicketTiersResponse, void>();

const fetchWeeklySalesBreakdown = createAsyncAction(
  '@transactions/WeeklySalesBreakdown/fetch',
  '@transactions/WeeklySalesBreakdown/success',
  '@transactions/WeeklySalesBreakdown/error'
)<WeeklySalesBreakdownRequestPayload, WeeklySalesBreakdownResponse, void>();

const clearTransactionsState = createAction('@transactions/clear-data', action => () => action({}));

const setTempChartFilter = createAction(
  '@transactions/set-temp-chart-filter',
  action => (payload: DropdownWithButtonsListItem<ChartFilterValue>[]) => action(payload)
);

export const transactionsActions = {
  fetchTransactionsData,
  initFetchTransactionsData,
  setOverviewGrowthData,
  fetchTicketTiers,
  setComparisonTableOptions,
  clearTransactionsState,
  fetchWeeklySalesBreakdown,
  setTempChartFilter,
  fetchTransactionsEvaluate,
  setTransactionsEvaluateDefaultState,
  setExpandedCharts
};
