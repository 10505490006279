import { pick, debounce, difference, sortBy } from 'lodash';
import { connect } from 'react-redux';
import React, { Component, ReactElement } from 'react';
import { bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import {
  LOCATION_SELECTOR_MULTI_TYPE,
  LOCATION_SELECTOR_SINGLE_TYPE,
  DEFAULT_PAGE,
  REPUTATION_PAGE,
  CUSTOMER_DEMOGRAPHICS_PAGE
} from 'appConstants';
import { AppState } from 'store/rootReducer';
import { isComparePageActive, getActivePage, isSupportSearch, getAdvancedAnalyticsRootPath } from 'store/navigation';
import { EventHandler, LocationEvent, LocationsList, MerchantPages, UserLocation } from 'store/settings/types';
import { getMerchantPages, getUserLocations, getConfig } from 'store/settings';
import { getLocationsSearchData, LocationFilter, locationsActions, LocationSearch } from 'store/locations';
import { LocationSelectorType, Page } from 'store/settings/layout';
import { AdvancedSelector } from 'shared-components/AdvancedSelector';
import { AdvancesSelectorTexts } from 'shared-components/AdvancedSelector/types';
import Modal, { ModalResponse } from 'shared-components/Modal';
import { PopUpWithInputField } from 'shared-components/PopUpWithInput';
import { formatString } from 'services/utils';
import { withTranslation } from 'react-i18next';
import { TProps } from 'utils/types';
import { DEFAULT_NS } from 'appConstants/translationNamespaces';
import { googleAnalytics } from 'services/googleAnalytics';
import { getPagePath } from 'services/navigation';
import { LocationFor } from 'components/AdvancedAnalyticsEvaluate/types';
import { LocationItem } from './LocationItem';
import { SimpleModeSearchSting } from './SimpleModeLocation';
import { SavedFilterItem } from './SavedFilterItem';
import { ActionsBtns } from './ActionBtns';
import { LocationSelectorHint } from './LocationSelectorHint';

const Container = styled.div`
  position: relative;
`;
const SingleModeLabelContainer = styled.div`
  font-style: italic;
  font-weight: bold;
`;

interface ConnectedProps {
  locations: LocationsList;
  searchData?: LocationSearch;
  merchantPages: MerchantPages;
  activePageId: string;
  isSupportSearch: boolean;
  isEMPagesActive: boolean;
  activePage?: Page;
  countryCode?: string;
  isDropDownShown: boolean;
  isTestGroupDropDownShown: boolean;
  isControlGroupDropDownShown: boolean;
  locationSelectorPlace: string;
  advancedAnalyticsPath?: string;
}

interface OwnProps {
  selectedLocations: string[];
  onApply: (selectedLocations: string[]) => void;
  type?: LocationSelectorType;
  showHint?: boolean;
  allItemsEnabled?: boolean;
  isTablet?: boolean;
  isMobile?: boolean;
  dropDownFullHeight?: boolean;
  disableApplyOnNoCheck?: boolean;
  disabled?: boolean;
  place?: string;
  withBorder?: boolean;
  locationFor?: LocationFor;
  doNotCheckPlacement?: boolean;
  onOpenFilterModal?: (isOpen: boolean) => void;
}

interface ConnectedActions {
  getLocationsSearch: typeof locationsActions.search.list.request;
  saveFilter: typeof locationsActions.search.item.create.request;
  deleteFilter: typeof locationsActions.search.item.delete.request;
  addRecentSearch: typeof locationsActions.search.addRecent.request;
  isDropDownShownAction: typeof locationsActions.isDropDownShown;
  isTestGroupDropDownShownAction: typeof locationsActions.isTestGroupDropDownShown;
  isControlGroupDropDownShownAction: typeof locationsActions.isControlGroupDropDownShown;
}

type PropTypes = ConnectedProps & OwnProps & ConnectedActions & RouteComponentProps & TProps;

interface State {
  selectedLocations: string[];
  filterString?: string;
  showOnlySelected: boolean;
  selectedFilter?: LocationFilter;
  saveFilterModalShown?: boolean;
  filterForDeletion?: LocationFilter;
  confirmFilterUpdate?: string;
}

class LocationSelectorComponent extends Component<PropTypes, State> {
  private cleanFilterStringRegexp = /[$&+,:;=?@#|\\/\\'"<>.^*()%!\-_\s]/g;

  public state: State = {
    selectedLocations: [],
    showOnlySelected: false,
    saveFilterModalShown: false,
    confirmFilterUpdate: undefined
  };

  public componentDidMount(): void {
    this.setState({ selectedLocations: [...this.props.selectedLocations] });
    this.props.getLocationsSearch();
  }

  public componentWillReceiveProps(nextProps: Readonly<PropTypes>): void {
    if (
      difference(this.props.selectedLocations, nextProps.selectedLocations).length ||
      difference(nextProps.selectedLocations, this.props.selectedLocations).length
    ) {
      this.setState({ selectedLocations: nextProps.selectedLocations });
    }
  }

  public componentDidUpdate(prevProps: Readonly<PropTypes>, prevState: Readonly<State>) {
    if (
      prevProps.locationFor !== this.props.locationFor ||
      prevState.saveFilterModalShown !== this.state.saveFilterModalShown
    ) {
      if (this.props.locationFor && this.props.onOpenFilterModal) {
        this.props.onOpenFilterModal(!!this.state.saveFilterModalShown);
      }
    }
  }

  public onFilterStrChange = debounce((val: string) => {
    this.setState({
      filterString: this.sanitizeString(val),
      showOnlySelected: false
    });
  }, 300);

  public sanitizeString = (value: string) => value.toLowerCase().replace(this.cleanFilterStringRegexp, '');

  public isLocationSelected = (item: UserLocation) =>
    !!this.state.selectedLocations.includes(item.merchant_sequence_key);

  public isLocationDisabled = (item: UserLocation) => {
    const { merchantPages, activePageId, allItemsEnabled } = this.props;

    // Render all items as enabled
    // Used in email marketing coupon editor
    if (allItemsEnabled) return false;

    return !(merchantPages[item.merchant_sequence_key] || []).includes(activePageId);
  };

  public getActiveLocations = () => {
    const { locations } = this.props;
    return locations.filter(location => !this.isLocationDisabled(location));
  };

  public sendGoogleAnalytics = (event: LocationEvent) => {
    const { locationFor } = this.props;
    let currentEventHandler = EventHandler.locationSelector;
    if (locationFor === LocationFor.testGroup) {
      currentEventHandler = EventHandler.testGroup;
    } else if (locationFor === LocationFor.controlGroup) {
      currentEventHandler = EventHandler.controlGroup;
    }
    googleAnalytics.events[currentEventHandler][event]();
  };

  public isComparePageActive = () => {
    // todo: remove all hardcoded paths
    const analyticsRootUrls = [DEFAULT_PAGE];
    if (this.props.advancedAnalyticsPath) analyticsRootUrls.push(this.props.advancedAnalyticsPath);
    return isComparePageActive(analyticsRootUrls, this.props.location, this.props.activePage);
  };

  public isLocationLineupPageActive = () =>
    isComparePageActive([CUSTOMER_DEMOGRAPHICS_PAGE, REPUTATION_PAGE], this.props.location, this.props.activePage);

  public onLocationItemClick = (id: string) => {
    const { selectedLocations } = this.state;
    const { type } = this.props;
    const alreadySelected = selectedLocations.includes(id);
    if (type === LOCATION_SELECTOR_MULTI_TYPE) {
      const newSelectedLocationsState = alreadySelected
        ? selectedLocations.filter(item => id !== item)
        : [...selectedLocations, id];
      const newShowOnlySelectedState =
        this.state.showOnlySelected && !newSelectedLocationsState.length ? false : this.state.showOnlySelected;
      this.setState(
        { selectedLocations: newSelectedLocationsState, showOnlySelected: newShowOnlySelectedState },
        () => {
          if (alreadySelected) {
            this.sendGoogleAnalytics(LocationEvent.unSelectItem);
          } else {
            this.sendGoogleAnalytics(LocationEvent.selectItem);
          }
        }
      );
    }
    if (type === LOCATION_SELECTOR_SINGLE_TYPE) {
      this.setState({ selectedLocations: [id] }, () => {
        this.sendGoogleAnalytics(LocationEvent.selectItem);
      });
    }
  };

  public setShowOnlySelected = (showOnlySelected: boolean) => {
    this.setState({ showOnlySelected });
  };

  public onApplyButtonClick = () => {
    this.setState({ showOnlySelected: false });
    this.props.onApply(this.state.selectedLocations);
    switch (this.props.locationFor) {
      case LocationFor.testGroup:
        this.props.isTestGroupDropDownShownAction(false);
        break;
      case LocationFor.controlGroup:
        this.props.isControlGroupDropDownShownAction(false);
        break;
      default:
        this.props.isDropDownShownAction(false);
    }
  };

  public onCancelButtonClick = () => {
    this.setState({ selectedLocations: this.props.selectedLocations, showOnlySelected: false });
    switch (this.props.locationFor) {
      case LocationFor.testGroup:
        this.props.isTestGroupDropDownShownAction(false);
        break;
      case LocationFor.controlGroup:
        this.props.isControlGroupDropDownShownAction(false);
        break;
      default:
        this.props.isDropDownShownAction(false);
    }
  };

  public getSortedLocations = () => {
    const { locations } = this.props;
    return sortBy(locations, location => location.merchant_name);
  };

  public getFilteredLocations = (): LocationsList => {
    const locations = this.getSortedLocations();
    const { filterString, showOnlySelected, selectedLocations } = this.state;

    if (showOnlySelected) return locations.filter(l => selectedLocations.includes(l.merchant_sequence_key));
    if (!filterString) return locations;
    if (filterString.length < 2) return locations;
    if (filterString.length === 2) {
      const filteredByState = locations.filter(l => l.state.toLowerCase() === filterString);
      if (filteredByState.length) return filteredByState;
    }

    return locations.filter(location => {
      const locationValues = Object.values(pick(location, ['merchant_name', 'address_1', 'city']));
      const partialMatch = locationValues
        .map(item => (item ? this.sanitizeString(item) : ''))
        .some(f => f.toLowerCase().includes(filterString));
      const completeMatch = Object.values(pick(location, ['zip', 'merchant_number']))
        .map(item => (item ? this.sanitizeString(item) : ''))
        .some(f => f.toLowerCase() === filterString);
      return partialMatch || completeMatch;
    });
  };

  public applySavedFilter = (item: LocationFilter) => {
    const activeLocations = this.getActiveLocations().map(location => location.merchant_sequence_key);
    this.setState(
      {
        selectedFilter: item,
        selectedLocations: item.locations.filter(savedLocation => activeLocations.includes(savedLocation))
      },
      () => {
        this.props.onApply(this.state.selectedLocations);
        googleAnalytics.events.filter.apply();
      }
    );
  };

  public removeFavoriteFilter = (item: LocationFilter) => {
    this.setState({ filterForDeletion: item });
  };

  public onRemoveFilterModalDecide = ({ response }: ModalResponse) => {
    if (response && this.state.filterForDeletion) {
      this.props.deleteFilter(this.state.filterForDeletion);
    }
    this.setState({ filterForDeletion: undefined });
  };

  public checkFilterNameIsAvailable = (name: string) => {
    const { searchData } = this.props;
    if (
      searchData &&
      Array.isArray(searchData.filters) &&
      searchData.filters.length &&
      searchData.filters.map(({ name }) => name).includes(name)
    ) {
      this.setState({ confirmFilterUpdate: name });
    } else {
      this.onFilterNameSave(name);
    }
  };

  public onFilterNameSave = (name: string) => {
    const filter = this.state.filterString;
    const payload = {
      name,
      locations: this.state.selectedLocations,
      terms: typeof filter === 'string' && filter.length ? [filter] : []
    };
    this.setState({ saveFilterModalShown: false });
    this.props.saveFilter(payload);
  };

  public getActionBtns = (
    isSimpleMode: boolean,
    {
      isSupportSearch,
      isDropDownShownAction,
      place,
      isControlGroupDropDownShownAction,
      isTestGroupDropDownShownAction
    }: PropTypes
  ) => {
    if (this.props.type === LOCATION_SELECTOR_MULTI_TYPE) {
      return ActionsBtns({
        selectedLocationsLength: this.state.selectedLocations.length,
        totalLocationsLength: this.getActiveLocations().length,
        allowSaveFilter: !isSimpleMode,
        isSupportSearch,
        onlySelectedShown: this.state.showOnlySelected,
        onSaveFilter: () => {
          this.setState({ saveFilterModalShown: true }, () => {
            switch (this.props.locationFor) {
              case LocationFor.testGroup:
                isTestGroupDropDownShownAction(false, place);
                break;
              case LocationFor.controlGroup:
                isControlGroupDropDownShownAction(false, place);
                break;
              default:
                isDropDownShownAction(false, place);
            }

            if (this.state.selectedLocations !== this.props.selectedLocations) this.onApplyButtonClick();
          });
        },
        onClearSelection: () => {
          this.setState({ selectedLocations: [], showOnlySelected: false }, () => {
            this.sendGoogleAnalytics(LocationEvent.unSelectAll);
          });
        },
        onSelectAll: () => {
          this.setState(
            {
              selectedLocations: this.getActiveLocations().map(el => el.merchant_sequence_key)
            },
            () => {
              this.sendGoogleAnalytics(LocationEvent.selectAll);
            }
          );
        }
      });
    }
    return [
      () => <SingleModeLabelContainer>{this.props.t('chooseOneLocationLabel', 'Choose one:')}</SingleModeLabelContainer>
    ];
  };

  public getActionBars = (isSimpleMode: boolean, componentProps: PropTypes) => {
    const bars = [this.getActionBtns(isSimpleMode, componentProps)];

    if (this.props.type === LOCATION_SELECTOR_SINGLE_TYPE) {
      bars.push(
        ActionsBtns({
          selectedLocationsLength: this.state.selectedLocations.length,
          totalLocationsLength: this.props.locations.length,
          isSupportSearch: componentProps.isSupportSearch,
          onClearSelection: () => {
            this.setState({ selectedLocations: [], showOnlySelected: false }, () => {
              this.sendGoogleAnalytics(LocationEvent.unSelectAll);
            });
          }
        })
      );
    }

    return bars;
  };

  public showHint = (): boolean => {
    const { selectedLocations, showHint, type } = this.props;

    if (!showHint) return false;

    if (this.props.isEMPagesActive) {
      return false;
    }

    if (this.isLocationLineupPageActive() && selectedLocations.length < 1) {
      return true;
    }

    if (this.isComparePageActive() && selectedLocations.length < 2) {
      return true;
    }

    switch (type) {
      case LOCATION_SELECTOR_MULTI_TYPE:
        return selectedLocations.length === 0;
      case LOCATION_SELECTOR_SINGLE_TYPE:
        return selectedLocations.length !== 1;
      default:
        return false;
    }
  };

  public getDropdownState = (): boolean => {
    switch (this.props.locationFor) {
      case LocationFor.testGroup:
        return this.props.isTestGroupDropDownShown;
      case LocationFor.controlGroup:
        return this.props.isControlGroupDropDownShown;
      default:
        return this.props.isDropDownShown;
    }
  };

  public render(): ReactElement {
    const {
      locations,
      selectedLocations,
      searchData,
      type,
      isSupportSearch,
      isMobile,
      isTablet,
      disableApplyOnNoCheck,
      disabled,
      isDropDownShown,
      locationSelectorPlace,
      isDropDownShownAction,
      isTestGroupDropDownShownAction,
      isControlGroupDropDownShownAction,
      place,
      doNotCheckPlacement,
      withBorder,
      t,
      activePage
    } = this.props;
    const {
      showOnlySelected,
      selectedFilter,
      saveFilterModalShown,
      filterForDeletion,
      confirmFilterUpdate,
      selectedLocations: checkedLocations
    } = this.state;

    const isSimpleMode = locations.length <= 5;
    const isCurrentDropdownShown = this.getDropdownState();
    const checkPlace = doNotCheckPlacement ? true : place === locationSelectorPlace;
    const showSpecificDropdown = isCurrentDropdownShown && checkPlace;
    const translations: AdvancesSelectorTexts = {
      searchPlaceholder: t('newLocationsSelectorPlaceholder', 'Filter Locations'),
      recentSearchesLabel: t('newLocationsSelectorRecentSearches', 'Recent Searches'),
      statusBarLabel: t('newLocationSelectorAvailableLocations', 'Available Locations'),
      applyBtn: t('newLocationSelectorApplyAction', 'Apply'),
      cancelBtn: t('newLocationSelectorCancelAction', 'Cancel'),
      paginationShowUpTo: t('AnalyticsComparisonPaginationHeaderText', 'Show up to'),
      paginationSelectAllBtn: t('selectAll', 'Select All'),
      emptyItemsList: t('newLocationSelectorNoLocations', 'No results found'),
      selectLocationsText: t('newLocationsSelectorPlaceholderWithoutFilter', 'Select Locations'),
      noSavedFilters: t('newLocationSelectorNoSavedFilters', 'No Saved Filters')
    };

    return (
      <>
        <Container data-test-id={`location-selector-${isSimpleMode ? 'simple' : 'advanced'}`}>
          <LocationSelectorHint
            isMobile={isMobile}
            isTablet={isTablet}
            type={type}
            show={this.showHint()}
            showDefaultHint={!this.isComparePageActive()}
            locationFor={this.props.locationFor}
            numberOfSelectedLocations={selectedLocations.length}
          />
          <AdvancedSelector
            setShowOnlySelected={this.setShowOnlySelected}
            locationFor={this.props.locationFor}
            dropDownFullHeight={this.props.dropDownFullHeight}
            isMobile={isMobile}
            isFilterToggleEnabled={activePage?.locationSelectorWithFilterToggle}
            onSearchEnter={(val: string) => {
              if (val) this.props.addRecentSearch(val);
            }}
            selectedFilter={selectedFilter}
            selectedFilterTerm={selectedFilter && selectedFilter.terms.length ? selectedFilter.terms[0] : ''}
            isSimpleMode={isSimpleMode}
            isSupportSearch={isSupportSearch}
            withBorder={withBorder}
            recentSearchesList={
              isMobile ? undefined : searchData && searchData.searchTerms ? searchData.searchTerms.slice(0, 5) : []
            }
            favoriteItems={searchData && Array.isArray(searchData.filters) ? searchData.filters : []}
            renderFavoriteItemFn={(item: LocationFilter) => (
              <SavedFilterItem
                item={item}
                onNameClick={this.applySavedFilter}
                onRemoveClick={this.removeFavoriteFilter}
              />
            )}
            renderItemFn={(item: UserLocation) => (
              <LocationItem
                disabled={this.isLocationDisabled(item)}
                item={item}
                onClick={this.onLocationItemClick}
                checkSelectedFn={this.isLocationSelected}
                countryCode={this.props.countryCode}
              />
            )}
            items={this.getFilteredLocations()}
            availableCount={locations.length}
            selectedCount={selectedLocations.length}
            checkedCount={checkedLocations.length}
            onApply={this.onApplyButtonClick}
            onCancel={this.onCancelButtonClick}
            actionBars={this.getActionBars(isSimpleMode, this.props)}
            onFilterStringChange={this.onFilterStrChange}
            onSelectedCountClick={() => this.setState({ showOnlySelected: !showOnlySelected })}
            isOnlySelectedShown={showOnlySelected}
            simpleToolbarRenderFn={() => (
              <SimpleModeSearchSting
                locations={this.props.locations}
                selectedLocations={this.props.selectedLocations}
                placeholder={translations.selectLocationsText}
              />
            )}
            selectedCountString={`${checkedLocations.length} ${
              !isMobile ? t('newLocationsSelectorSelectedLocations', 'selected') : ''
            }`}
            textsMap={translations}
            onFavoriteClick={(shown: boolean) => {
              if (shown) this.props.getLocationsSearch();
            }}
            showDropdown={showSpecificDropdown}
            onDropDownOpen={() => {
              if (!showSpecificDropdown) {
                switch (this.props.locationFor) {
                  case LocationFor.testGroup:
                    isTestGroupDropDownShownAction(true, place);
                    break;
                  case LocationFor.controlGroup:
                    isControlGroupDropDownShownAction(true, place);
                    break;
                  default:
                    isDropDownShownAction(true, place);
                }
                this.sendGoogleAnalytics(LocationEvent.expand);
              }
            }}
            disableApplyOnNoCheck={disableApplyOnNoCheck}
            disabled={disabled}
          />

          <PopUpWithInputField
            translations={{
              title: t('newLocationSelectorSaveAction', 'Save Filter'),
              inputLabel: t('newLocationSelectorSaveFilterLabel', 'Filter name'),
              inputPlaceholder: t('newLocationSelectorSaveFilterPlaceholder', 'Type filter name'),
              primaryBtn: t('labelSave', 'Save'),
              secondaryBtn: t('labelCancel', 'Cancel')
            }}
            onApply={this.checkFilterNameIsAvailable}
            onCancel={() => {
              this.setState({ saveFilterModalShown: false });
            }}
            shown={!!saveFilterModalShown}
          />
        </Container>

        {!!filterForDeletion && (
          <Modal
            isOpen={!!filterForDeletion}
            type="warning"
            modalHeader={t('confirmDelete', 'Do you confirm the deletion?')}
            buttonSecondaryText={t('labelCancel', 'Cancel')}
            buttonPrimaryText={t('deleteFilterConfirmationPrimaryBtn', 'Yes, Delete Filter')}
            onRequestClose={this.onRemoveFilterModalDecide}
          >
            <div>
              <div data-test-id="confirmation-text">
                <b>{t('deleteFilterConfirmationSubTitle', 'Are you sure you want to delete the filter below?')}</b>
              </div>
              <br />
              <div data-test-id="name-of-delete-filter">{filterForDeletion.name}</div>
            </div>
          </Modal>
        )}

        {!!confirmFilterUpdate && (
          <Modal
            isOpen={!!confirmFilterUpdate}
            type="warning"
            modalHeader={t('confirmReplace', 'Do you confirm the replacing?')}
            buttonSecondaryText={t('button_Cancel', 'Cancel')}
            buttonPrimaryText={t('button_Confirm', 'Confirm')}
            onRequestClose={({ response }: ModalResponse) => {
              if (response) {
                this.onFilterNameSave(confirmFilterUpdate);
              }
              this.setState({ confirmFilterUpdate: undefined });
            }}
          >
            <>
              {formatString(
                t(
                  'newLocationSelectorSaveConfirmationText',
                  'The filter {0} already exists. Do you want to replace it?'
                ),
                confirmFilterUpdate
              )}
            </>
          </Modal>
        )}
      </>
    );
  }
}

export const LocationSelector = withRouter(
  withTranslation(DEFAULT_NS)(
    connect<ConnectedProps, ConnectedActions, OwnProps, AppState>(
      state => {
        const page = getActivePage(state);
        const isEMPagesActive = !!(page && page.path === getPagePath(state.appMenu.pages, 'emailMarketing_root', ''));
        return {
          activePage: page,
          locations: getUserLocations(state),
          searchData: getLocationsSearchData(state),
          merchantPages: getMerchantPages(state),
          activePageId: page ? page.pageId : '',
          isSupportSearch: isSupportSearch(state),
          isEMPagesActive,
          countryCode: getConfig(state).countryCode,
          isDropDownShown: state.locationSelector.isDropDownShown,
          isTestGroupDropDownShown: state.locationSelector.isTestGroupDropDownShown,
          isControlGroupDropDownShown: state.locationSelector.isControlGroupDropDownShown,
          locationSelectorPlace: state.locationSelector.locationSelectorPlace,
          advancedAnalyticsPath: getAdvancedAnalyticsRootPath(state)
        };
      },
      dispatch =>
        bindActionCreators(
          {
            getLocationsSearch: locationsActions.search.list.request,
            saveFilter: locationsActions.search.item.create.request,
            deleteFilter: locationsActions.search.item.delete.request,
            addRecentSearch: locationsActions.search.addRecent.request,
            isDropDownShownAction: locationsActions.isDropDownShown,
            isTestGroupDropDownShownAction: locationsActions.isTestGroupDropDownShown,
            isControlGroupDropDownShownAction: locationsActions.isControlGroupDropDownShown
          },
          dispatch
        )
    )(LocationSelectorComponent)
  )
);
