export enum PdfChartsModeMap {
  lineChartMode = 'lineChartMode',
  lineChartPredictiveData = 'lineChartPredictiveData',
  spotlightsChartMode = 'spotlightsChartMode',
  ticketTiersChartMode = 'ticketTiersChartMode',
  weeklySalesBreakdownChartMode = 'weeklySalesBreakdownChartMode',
  salesTimeSegmentsChartMode = 'salesTimeSegmentsChartMode',
  visitsTimeSegmentsChartMode = 'visitsTimeSegmentsChartMode'
}

export enum PdfCommonOptionsMap {
  pdfMode = 'pdfMode',
  startDate = 'transactionsStartDate',
  endDate = 'transactionsEndDate'
}

export interface PdfCookiesTypes {
  pdfMode: boolean;
  startDate: string;
  endDate: string;
  lineChartMode: LineChartMode;
  spotlightsChartMode: CustomerTypes;
}

export interface PdfRequest extends AnalyticsPageOptions {
  [PdfCommonOptionsMap.startDate]: string;
  [PdfCommonOptionsMap.endDate]: string;
  locations: string[];
  programs: string[];
}

export enum AvailablePages {
  analytics = 'analytics'
}

export enum LineChartMode {
  sales = 'sales',
  visitors = 'visitors'
}

export enum CustomerTypes {
  new = 'new',
  returning = 'returning',
  all = 'all'
}

export interface AnalyticsPageOptions {
  [PdfChartsModeMap.lineChartMode]: LineChartMode;
  [PdfChartsModeMap.spotlightsChartMode]: CustomerTypes;
  [PdfChartsModeMap.ticketTiersChartMode]: CustomerTypes;
  [PdfChartsModeMap.weeklySalesBreakdownChartMode]: CustomerTypes;
  [PdfChartsModeMap.salesTimeSegmentsChartMode]: CustomerTypes;
  [PdfChartsModeMap.visitsTimeSegmentsChartMode]: CustomerTypes;
  [PdfChartsModeMap.lineChartPredictiveData]: boolean;
}

export interface PdfState {
  loading: boolean;
  [PdfCommonOptionsMap.pdfMode]: boolean;
  [AvailablePages.analytics]: AnalyticsPageOptions;
}

export interface PdfPageTokenData {
  locations: string[];
  programs: string[];
}

export interface PdfPageCommonData {
  transactionsStartDate: string;
  transactionsEndDate: string;
}

export interface GeneratePdfForPage {
  pageUrl: string;
  filename: string;
  pdfPageTokenData: PdfPageTokenData;
  pdfPageCommonData: PdfPageCommonData;
  pdfPageUniqueData: any;
}
