import styled from 'styled-components';
import React from 'react';
import { getColor } from '../../layout/theme';
import { textMetaInfo } from '../../layout/mixins';

const StatusBarContainer = styled.div`
  background: ${props => getColor(props, 'color1')};
  height: 33px;
  color: #fff;
  display: flex;
  ${textMetaInfo};
  align-items: center;
  padding: 5px 11px;
  margin: 0 -1px;
`;

const CountLabel = styled.div`
  display: inline-block;
  ${textMetaInfo};
  background: #fff;
  margin: 0 6px;
  height: 20px;
  min-width: 20px;
  padding: 0 3px;
  line-height: 20px;
  text-align: center;
  border-radius: 5px;
  color: ${props => getColor(props, 'darkGrey')};
`;

interface PropTypes {
  itemsCount: number;
  label: string;
}

export const StatusBar = (props: PropTypes) => (
  <StatusBarContainer data-test-id="statusbar">
    {props.label}
    <CountLabel>{props.itemsCount}</CountLabel>
  </StatusBarContainer>
);
