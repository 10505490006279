import { ServerTemplate } from 'store/emailMarketing/campaignEditor/types';
import { localizationService } from 'services/localization';
import { variablesService } from '../../../services/variables';
import { enStaticTemplates } from './en_US';
import { deStaticTemplates } from './de_DE';
import { atStaticTemplates } from './de_AT';
import { czStaticTemplates } from './cs_CZ';
import { roStaticTemplates } from './ro_RO';
import { skStaticTemplates } from './sk_SK';

const staticTemplates: { [key: string]: ServerTemplate[] } = {
  'en-US': enStaticTemplates,
  'de-DE': deStaticTemplates,
  'de-AT': atStaticTemplates,
  'cs-CZ': czStaticTemplates,
  'ro-RO': roStaticTemplates,
  'sk-SK': skStaticTemplates
};

export const getStaticTemplates = () => {
  const regionLocale = localizationService.getDanubeRegionLocale();
  return (staticTemplates[regionLocale] || staticTemplates['en-US']).map(template => ({
    ...template,
    components: template.components.map(component => ({
      ...component,
      image: `${variablesService.getItem('crmS3ImageUrl') || ''}/${component.image}`
    }))
  }));
};
