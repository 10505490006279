import React from 'react';
import { NotificationsMap } from 'store/notifications';
import { NotificationWrapper } from 'shared-components/NotificationWrapper';

interface OwnProps {
  className?: string;
  notifications: NotificationsMap;
}

export const Notifications = (props: OwnProps) => {
  const { notifications } = props;
  const { pathname } = window.location;

  const notification = notifications[pathname];

  if (!notification) return null;

  return <NotificationWrapper notification={notification} />;
};
