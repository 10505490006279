import React, { ReactElement } from 'react';
import { MailBoxProps } from '../types';

export const Divider = (props: MailBoxProps<{}>): ReactElement => {
  const { styles } = props;

  const ownStyles = {
    margin: '23px 0',
    borderTop: '2px solid',
    width: '100%'
  };

  return (
    <div style={styles}>
      <div style={ownStyles} />
    </div>
  );
};
