import { put, select, takeEvery, takeLatest, call } from '@redux-saga/core/effects';
import i18n from 'i18n';
import { getType } from 'typesafe-actions';
import { getMenuPages } from 'store/navigation';
import { ApiGateway } from 'services/apiGateway';
import { getPagePath } from 'services/navigation';
import { showApiError } from 'store/errors/utils';
import { templateEditorComponentsSelector } from './selectors';
import { emailTemplateEditorActions } from './actions';
import { getEmCustomerSettings } from '../settings';
import { alignRows, isSocialLinksListEmpty } from '../utils';

function* addBox({ payload }: ReturnType<typeof emailTemplateEditorActions.addBox>) {
  const store = yield select();
  const boxes = templateEditorComponentsSelector(store);
  const settings = getEmCustomerSettings(store);
  const newBoxes = [...boxes];

  const { box, index } = payload;

  if (box.type === 'text') {
    box.boxData = {
      text: `<p class="ql-align-center">${i18n.t(
        'editor_Items_Text_Text',
        'Please replace this sample text with your own special message.'
      )}</p>`
    };
  }

  if (box.type === 'button') {
    box.boxData = {
      title: i18n.t('editor_Items_Button_Name', 'Button'),
      link: 'https://#'
    };
  }

  if (box.type === 'coupon') {
    box.boxData = {
      title: i18n.t('couponDefaultText', 'BOGO Coupon'),
      text: alignRows(
        i18n.t('editor_Items_Coupon_Text', '<h6>Buy one and get one free!</h6><p>This is a limited time special.</p>'),
        'ql-align-center'
      ),
      androidPay: false,
      androidPayLink: '',
      buttonStyle: '',
      startDate: '',
      endDate: '',
      locations: []
    };
  }

  if (box.type === 'text2col') {
    box.boxData = {
      textCol1: `<p class="ql-align-center">${i18n.t(
        'editor_Items_2ColText_Placeholder',
        'Please replace this sample text with your own special message.'
      )}</p>`,
      textCol2: `<p class="ql-align-center">${i18n.t(
        'editor_Items_2ColText_Placeholder',
        'Please replace this sample text with your own special message.'
      )}</p>`
    };
  }

  if (box.type === 'image') {
    box.boxData = { src: '' };
  }

  if (box.type === 'social' && settings && isSocialLinksListEmpty(settings.socialLinks)) {
    const message = i18n.t('templateEditor_Error_Social', 'Please edit the social links in your');
    const linkTitle = i18n.t('templateEditor_Error_Social_ProfileLink', 'Profile');

    const pages = getMenuPages(store);
    yield put(
      emailTemplateEditorActions.setMessage({
        title: '',
        body: message,
        linkTitle,
        linkTo: getPagePath(pages, 'emailMarketing_root', 'emailMarketing_profile')
      })
    );
    // terminate social links block addition if no social links is filled
    return;
  }

  newBoxes.splice(index, 0, box);

  yield put(emailTemplateEditorActions.setBoxes(newBoxes));
}

function* updateBox({ payload }: ReturnType<typeof emailTemplateEditorActions.updateBox>) {
  const store = yield select();
  const boxes = templateEditorComponentsSelector(store);
  const newBoxes = [...boxes];

  const { id, boxData } = payload;

  const index = boxes.findIndex((box: any): boolean => box.id === id);

  if (index === -1) return;

  const box = boxes[index];
  newBoxes.splice(index, 1, { ...box, boxData: { ...box.boxData, ...boxData } });

  yield put(emailTemplateEditorActions.setBoxes(newBoxes));
}

function* moveBox({ payload }: ReturnType<typeof emailTemplateEditorActions.moveBox>) {
  const store = yield select();
  const boxes = templateEditorComponentsSelector(store);
  const newBoxes = [...boxes];

  const { oldIndex, newIndex } = payload;

  const selectedBox = boxes[oldIndex];

  // remove selected element
  newBoxes.splice(oldIndex, 1);
  // put it to the new position
  newBoxes.splice(newIndex, 0, selectedBox);

  yield put(emailTemplateEditorActions.setBoxes(newBoxes));
}

function* removeBox({ payload }: ReturnType<typeof emailTemplateEditorActions.removeBox>) {
  const store = yield select();
  const boxes = templateEditorComponentsSelector(store);

  const { id } = payload;

  yield put(emailTemplateEditorActions.setBoxes(boxes.filter((box: any): boolean => box.id !== id)));
}

function* saveAgreement({ payload }: ReturnType<typeof emailTemplateEditorActions.saveAgreement.request>) {
  try {
    const state = yield select();
    const emSettings = getEmCustomerSettings(state);

    if (!emSettings) return;

    const { customerId, hierarchyId } = emSettings;
    const { actionType, termsType, isAgree } = payload;

    const data = {
      customerId,
      hierarchyId,
      actionType,
      termsType,
      isAgree
    };

    yield call(ApiGateway.saveAgreement, data);
  } catch (e) {
    console.error(e);
    yield put(emailTemplateEditorActions.saveAgreement.failure());
  }
}

function* updateTimeout() {
  try {
    yield call(ApiGateway.updateSessionTimeout);
  } catch (e) {
    console.error(e);
  }
}

export function* emailMarketingCampaignTemplateEditorSagas() {
  yield takeEvery(getType(emailTemplateEditorActions.addBox), addBox);
  yield takeEvery(getType(emailTemplateEditorActions.updateBox), updateBox);
  yield takeEvery(getType(emailTemplateEditorActions.moveBox), moveBox);
  yield takeEvery(getType(emailTemplateEditorActions.removeBox), removeBox);
  yield takeLatest(emailTemplateEditorActions.saveAgreement.request, saveAgreement);

  yield takeEvery(getType(emailTemplateEditorActions.setBoxes), updateTimeout);
  yield takeLatest(emailTemplateEditorActions.saveAgreement.failure, showApiError);
}
