import { createReducer } from 'typesafe-actions';
import { FeeTrendsState } from './types';
import { FeeTrendsActions, feeTrendsActions } from './actions';

const initialState: FeeTrendsState = {
  isFeeTrendsDisabled: false
};

export const feeTrendsReducer = createReducer<FeeTrendsState, FeeTrendsActions>(initialState).handleAction(
  feeTrendsActions.setIsFeeTrendsDisabled,
  (state, { payload }): FeeTrendsState => ({ ...state, isFeeTrendsDisabled: payload })
);
