import { css } from 'styled-components';
import { getColor } from '../theme';

export const centered = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const bold = css`
  font-weight: 600;
`;

export const resetButtonStyles = css`
  border: none;
  outline: none;
  cursor: pointer;
  background: transparent;
  padding: 0;
  margin: 0;
`;

export const ellipsisText = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const button = css`
  min-height: 33px;
  min-width: 68px;
  padding: 8px 17px;
  user-select: none;
  outline: none;
  cursor: pointer;
`;

export const buttonPrimary = css`
  color: ${props => getColor(props, 'white')};
  background-color: ${props => getColor(props, 'color1')};
  border: 1px solid ${props => getColor(props, 'color1')};
`;

export const buttonSecondary = css`
  color: ${props => getColor(props, 'darkGrey')};
  background-color: ${props => getColor(props, 'white')};
  border: 1px solid ${props => getColor(props, 'grey')};
`;

export const textNumericCallout = css`
  font-size: 26px;
  font-weight: bold;
`;

export const textTitle = css`
  font-size: 20px;
  font-weight: bold;
`;

export const textSubtitle = css`
  font-size: 16px;
`;

export const textSubtitleBold = css`
  ${textSubtitle};
  font-weight: bold;
`;

export const textRegular = css`
  font-size: 14px;
`;

export const textRegularBold = css`
  ${textRegular};
  font-weight: bold;
`;

export const textMetaInfo = css`
  font-size: 12px;
`;

export const shadow = css`
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
`;
