import React from 'react';
import styled from 'styled-components';
import { isTopMenuShown, getActivePage, getMenuPages } from 'store/navigation';
import { connect, useSelector } from 'react-redux';
import { topMenuOverlayZIndex } from 'appConstants';
import { AppState } from 'store/rootReducer';
import { DropdownMenu } from '../navigation/headerDropdownMenu';
import { Account } from '../account/Account';
import { isAppHubTraySelector } from '../../store/settings';

interface OwnProps {
  isMobile?: boolean;
  isTablet?: boolean;
}

interface NavProps {
  topMenuShown?: boolean;
  supportPageRoute?: string;
  isNoZIndex?: boolean;
}

const Nav = styled.nav<NavProps>`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-left: 10px;
  ${props =>
    props.topMenuShown
      ? `
    z-index: ${topMenuOverlayZIndex + 2};
    pointer-events: initial;
  `
      : ''};
`;

const NavItem = styled.div<NavProps>`
  margin: 0 8px;
  ${props =>
    props.topMenuShown && !props.isNoZIndex
      ? `
    z-index: 1;
  `
      : ''};
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
`;
Nav.displayName = 'Nav';
NavItem.displayName = 'NavItem';

const HeaderMenuComponent = ({ topMenuShown, isTablet, isMobile, supportPageRoute }: NavProps & OwnProps) => {
  const isAppHubTray = useSelector(isAppHubTraySelector);

  return (
    <Nav topMenuShown={topMenuShown}>
      <NavItem topMenuShown={topMenuShown}>
        <DropdownMenu isTablet={isTablet} isMobile={isMobile} />
      </NavItem>

      <NavItem topMenuShown={topMenuShown} isNoZIndex={isAppHubTray}>
        <Account supportPageRoute={supportPageRoute} />
      </NavItem>
    </Nav>
  );
};

export const HeaderMenu = connect<NavProps, void, OwnProps, AppState>((state: AppState): NavProps => {
  const activePage = getActivePage(state) || { path: '' };
  const supportPageRoute = (getMenuPages(state) || []).find(page => page.pageName === 'support_root')?.path;

  return {
    topMenuShown: isTopMenuShown(state),
    supportPageRoute: supportPageRoute && supportPageRoute !== activePage.path ? supportPageRoute : ''
  };
})(HeaderMenuComponent);
