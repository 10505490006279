import { createAsyncAction } from 'typesafe-actions';
import { SocialPlatformsSettings } from './types';

const fetchSocialPlatformsSettings = createAsyncAction(
  '@merchantCentric/socialPlatformsSettings/fetch',
  '@merchantCentric/socialPlatformsSettings/success',
  '@merchantCentric/socialPlatformsSettings/error'
)<void, SocialPlatformsSettings[], Error>();

export const socialPlatformsSettingsActions = {
  fetchSocialPlatformsSettings
};
