import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment, { Moment } from 'moment';
import {
  openLeftHandSideMenu,
  closeLeftHandSideMenu,
  hideTabsLeftHandSideMenu,
  LeftMenuStates,
  getLeftSideMenuState,
  getActivePage,
  getPathname,
  useShowLeftHandSideMenuWithoutModulesLinks
} from 'store/navigation';
import { Checkbox } from 'shared-components/Checkbox';
import {
  baseColors,
  deviceMedia,
  leftMenuIconContainerWidth,
  leftMenuPaddingForTextContainer,
  leftMenuTextContainerWidth,
  toolBarHeight,
  HEADER_LOCATION_SELECTOR,
  DEFAULT_PREDICTIVE_DATE_RANGE_30_DAYS
} from 'appConstants';
import { getDaysRange } from 'shared-components/AdvancedDayPicker/utils';

import { AppState } from 'store/rootReducer';
import { LocationSelector } from 'shared-components/LocationSelector';
import { SimpleDayPicker } from 'shared-components/SimpleDayPicker';
import { getSelectedLocations, locationsActions, getIsContactsWithoutLocations } from 'store/locations';
import { Page, LocationSelectorType, DateRangePickerType } from 'store/settings/layout';
import { getPeriods, getSelectedPeriod } from 'store/masterCard/selectors';
import { DateRange, DateRangePicker } from 'shared-components/DayPicker';
import { AdvancedDateRangePicker } from 'shared-components/AdvancedDayPicker';
import { getDateRange, setDateRange } from 'store/appDateRangePicker';
import { getRFMRange, rfmActions } from 'store/rfm';
import { masterCardActions } from 'store/masterCard/actions';
import { googleAnalytics } from 'services/googleAnalytics';
import { centered, textSubtitleBold } from 'layout/mixins';
import { getColor } from 'layout/theme';
import { PeriodVisualizer } from 'layout/toolbar/PeriodVisualizer';
import { MonthPicker } from 'shared-components/MonthPicker';
import { isComponentEnabledOnThePage, Config } from 'store/settings';
import { COMPONENT_NAME } from 'page-elements/MastercardCompareDataFetcher';
import { getMerchantCentricLastReceivedDate } from 'store/merchantCentric/compare/selectors';
import { getHelpersModeState } from 'store/helpers';
import { getConfig } from 'store/settings/selectors';
import { withTranslation } from 'react-i18next';
import { TProps } from 'utils/types';
import { DEFAULT_NS } from 'appConstants/translationNamespaces';
import { errorsActions } from '../../store/errors/actions';
import { useOverflowDetection } from '../../utils/useOverflowDetection';
import { ToolTip } from './ToolbarTooltip/ToolbarHint';
import { generateUID } from '../../services/utils';

export const getPageDateRangePickerValue = (activePage: Page, pathname: string): DateRangePickerType | undefined => {
  const { subPages } = activePage;
  if (!subPages || subPages.length === 0) return activePage.dateRangeSelector;

  const subPage = subPages.find(page => `${activePage.path}${page.path}` === pathname);
  if (!subPage) return activePage.dateRangeSelector;

  return 'dateRangeSelector' in subPage && subPage.dateRangeSelector !== null
    ? subPage.dateRangeSelector
    : activePage.dateRangeSelector;
};

export const ToolbarItem = styled.div`
  ${centered};
  color: ${props => getColor(props, 'white')};
  background-color: ${props => getColor(props, 'color1')};
  min-height: ${toolBarHeight}px;
  border-right: 1px solid ${props => getColor(props, 'white')};
  overflow: hidden;
  box-sizing: border-box;
`;

const PageNameContainer = styled.div<{ showBottomBorder?: boolean }>`
  display: flex;
  @media ${deviceMedia.tablet} {
    width: 100%;
    ${props => (props.showBottomBorder ? 'border-bottom: 1px solid;' : '')};
  }
`;

export const SlideOutMenuButton = styled(ToolbarItem)`
  width: ${leftMenuIconContainerWidth + 1}px;
  background-color: ${props => getColor(props, 'headerColor')};
  cursor: pointer;
`;

export const PageName = styled(ToolbarItem)<{ usePredefinedWidth?: boolean }>`
  width: ${props => (props.usePredefinedWidth ? `${leftMenuTextContainerWidth}px` : 'auto')};
  justify-content: left;
  padding: 0 ${leftMenuPaddingForTextContainer}px;
  text-transform: uppercase;
  ${textSubtitleBold};
  background-color: ${props => getColor(props, 'headerColor')};
  @media ${deviceMedia.tablet} {
    width: auto;
    border: none;
  }
`;

const PageTitleWrapper = styled.div`
  hyphens: auto;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
`;

export const ToolbarSection = styled.section`
  position: relative;
  display: flex;
  min-height: ${toolBarHeight}px;
  z-index: 99;
  background-color: ${props => getColor(props, 'headerColor')};
  color: #fff;
  @media ${deviceMedia.tablet} {
    flex-wrap: wrap;
  }
`;
ToolbarSection.displayName = 'ToolbarSection';

const ToolsContainer = styled.div<{ disabled?: boolean; withToolbarPaddingOnTablet?: boolean }>`
  display: flex;
  align-items: center;
  flex: 1;
  max-width: calc(100% - ${leftMenuIconContainerWidth + leftMenuTextContainerWidth}px);

  & > * {
    ${props => (props.disabled ? 'pointer-events: none;' : '')};
  }
  @media ${deviceMedia.tablet} {
    width: 100%;
    max-width: 100%;
    flex: 0 0 auto;
    ${({ withToolbarPaddingOnTablet }) => (withToolbarPaddingOnTablet ? 'min-height: 45px;' : '')};
  }
  @media ${deviceMedia.mobile} {
    flex-wrap: wrap;
  }
`;
ToolsContainer.displayName = 'ToolsContainer';

const LocationSelectorContainer = styled.div`
  width: 40%;
  position: relative;
  padding: 10px 15px;
  @media ${deviceMedia.laptopM} {
    width: 45%;
  }
  @media ${deviceMedia.laptopS} {
    width: 60%;
  }
  &:after {
    content: '';
    display: inline-block;
    width: 1px;
    background: #ffffff;
    position: absolute;
    right: 0;
    top: 0px;
    bottom: 0px;
    margin: auto;
  }
  @media ${deviceMedia.mobile} {
    width: 100%;
    padding: 0;
    &:after {
      display: none;
    }
  }
`;

const DateRangePickerContainer = styled.div`
  padding: 5px 15px;
  width: 40%;
  @media ${deviceMedia.mobile} {
    width: 100%;
    display: flex;
    flex: 0 0 auto;
    justify-content: center;
    padding-top: 0;
    padding-bottom: 0;
  }
`;

const ContactsWithoutLocationsContainer = styled.div`
  display: flex;
  padding: 5px 15px;
  width: 40%;
  align-items: center;
  @media ${deviceMedia.mobile} {
    width: 100%;
    display: flex;
    flex: 0 0 auto;
    padding: 15px 20px;
  }
`;

const ContactsWithoutLocationsLabel = styled.label<{ disabled?: boolean }>`
  cursor: pointer;
  margin-left: 5px;
  ${props => (!props.disabled ? 'cursor: pointer;' : 'cursor: not-allowed;')};
`;
interface OwnProps {
  isTablet?: boolean;
  isMobile?: boolean;
  windowWidth?: number;
  isLeftMenuHidden?: boolean;
  pdfMode: boolean;
}
interface AttachedProps extends DateRange {
  collapsedState: LeftMenuStates;
  selectedLocations: string[];
  isContactsWithoutLocations: boolean;
  activePage?: Page;
  config: Config;
  currentSubPage?: Page;
  pathname: string;
  rfmRange: number;
  lmiPeriod: string;
  lmiPeriods: string[];
  merchantCentricPeriod: string;
  isHelpersModeStateEnabled: boolean;
  useShowLeftHandSideMenuWithoutModulesLinks: boolean;
}
interface AttachedActions {
  openSideMenu: typeof openLeftHandSideMenu;
  closeSideMenu: typeof closeLeftHandSideMenu;
  hideTabsSideMenu: typeof hideTabsLeftHandSideMenu;
  setSelected: typeof locationsActions.select;
  setIsContactsWithoutLocations: typeof locationsActions.isContactsWithoutLocations;
  setDateRangeAction: typeof setDateRange;
  setRFMRangeAction: typeof rfmActions.setRange;
  setLmiPeriod: typeof masterCardActions.setPeriod;
  resetApiError: typeof errorsActions.hideApiError;
}
type PropTypes = AttachedProps & AttachedActions & OwnProps & TProps;

export const ToolbarComponent = (props: PropTypes) => {
  const {
    pathname,
    collapsedState,
    activePage,
    config,
    isTablet,
    isMobile,
    windowWidth,
    startDate,
    endDate,
    merchantCentricPeriod,
    isContactsWithoutLocations,
    setIsContactsWithoutLocations,
    rfmRange,
    lmiPeriod,
    lmiPeriods,
    isLeftMenuHidden,
    pdfMode,
    currentSubPage,
    isHelpersModeStateEnabled,
    endPredictiveDate,
    t,
    resetApiError,
    setSelected,
    closeSideMenu,
    openSideMenu,
    hideTabsSideMenu,
    setDateRangeAction,
    selectedLocations,
    setLmiPeriod,
    setRFMRangeAction,
    useShowLeftHandSideMenuWithoutModulesLinks
  } = props;

  const onApplyButtonClick = (selectedLocations: string[]) => {
    resetApiError();
    setSelected(selectedLocations);
  };

  useEffect(() => {
    if (isTablet) {
      closeSideMenu();
    }
  }, []);

  const onSlideOutButtonClick = () => {
    switch (collapsedState) {
      case 'open':
        useShowLeftHandSideMenuWithoutModulesLinks ? closeSideMenu() : hideTabsSideMenu();
        break;
      case 'openTabsHidden':
        closeSideMenu();
        break;
      case 'closed':
        openSideMenu();
        break;
      default:
        break;
    }
  };

  const applyDateRange = (start: Moment, end: Moment, rangeName: string) => {
    googleAnalytics.events.datePicker.selectRange(rangeName);
    resetApiError();
    setDateRangeAction(start, end);
  };

  const checkboxSize = 16;
  const borderCheckboxSize = 1;
  const { elements } = currentSubPage || {};
  const [featuresConfig] = elements || [];
  const { features } = featuresConfig || {};
  const { withToolbarPaddingOnTablet } = features || {};

  let stateOfLocationSelectorInSubPage: boolean | string = '';
  let shouldShowDateRangePicker = true;
  let typeOfLocationSelectorInSubPage: LocationSelectorType | undefined;
  if (currentSubPage && currentSubPage.locationSelectorState) {
    typeOfLocationSelectorInSubPage = currentSubPage.locationSelector;
    stateOfLocationSelectorInSubPage = currentSubPage.locationSelectorState;
  }
  if (currentSubPage && currentSubPage?.isDateRangePickerHidden) {
    shouldShowDateRangePicker = !currentSubPage.isDateRangePickerHidden;
  }

  const isLocationSelectorInSubPageDisabled = stateOfLocationSelectorInSubPage === 'disabled';
  const isLocationSelectorInSubPageHidden = stateOfLocationSelectorInSubPage === 'hidden';
  const showContactsWithoutLocationsContainer =
    activePage &&
    !!activePage.locationSelector &&
    !!activePage.contactsWithoutLocationsCheckbox &&
    !isLocationSelectorInSubPageHidden;
  const { advancedAnalyticsDateRange } = config;
  const range = getDaysRange(startDate, endDate);
  const advancedAnalyticsStartDate = moment().subtract(advancedAnalyticsDateRange, 'days');
  const isMoreThanOneWeekRange = range && range > +advancedAnalyticsDateRange;
  const shouldShowToolBar = !(isLocationSelectorInSubPageHidden && !shouldShowDateRangePicker);

  const is30DaysPredictionData = config.defaultPredictiveDateRange === DEFAULT_PREDICTIVE_DATE_RANGE_30_DAYS;

  const composePageTitle = (): string => {
    const pageTitle = activePage ? activePage.title : '';
    if (!pdfMode) return pageTitle;

    const subPageTitle = currentSubPage ? currentSubPage.title : '';
    return `${pageTitle} ${subPageTitle}`;
  };
  const overflowRef = useRef<HTMLDivElement>(null);
  const isTooltipEnabled = useOverflowDetection(overflowRef);
  const composedPageTitle = composePageTitle();
  const ID = generateUID();

  return (
    <ToolbarSection>
      {!isLeftMenuHidden && (
        <PageNameContainer
          showBottomBorder={activePage && (!!activePage.locationSelector || !!activePage.dateRangeSelector)}
        >
          {!pdfMode ? (
            <SlideOutMenuButton onClick={onSlideOutButtonClick}>
              <i className={collapsedState !== 'closed' ? 'fa fa-arrow-left' : 'fa fa-bars'} />
            </SlideOutMenuButton>
          ) : null}
          <PageName
            usePredefinedWidth={!pdfMode}
            data-tip={composedPageTitle}
            data-for={ID}
            data-event="mouseover mouseenter touchstart"
            data-event-off="mouseout mouseleave"
          >
            <PageTitleWrapper ref={overflowRef}>{composedPageTitle}</PageTitleWrapper>
            <ToolTip
              id={ID}
              multiline={true}
              effect="solid"
              textAlign="left"
              place="bottom"
              type="light"
              disable={!isTooltipEnabled}
            />
          </PageName>
        </PageNameContainer>
      )}

      {shouldShowToolBar && (
        <ToolsContainer
          id="app-toolbar-tools-container"
          disabled={isHelpersModeStateEnabled}
          withToolbarPaddingOnTablet={withToolbarPaddingOnTablet}
        >
          {!pdfMode && activePage && !!activePage.locationSelector && !isLocationSelectorInSubPageHidden ? (
            <LocationSelectorContainer>
              <LocationSelector
                isTablet={isTablet}
                isMobile={isMobile}
                place={HEADER_LOCATION_SELECTOR}
                showHint
                type={typeOfLocationSelectorInSubPage || activePage.locationSelector}
                disabled={isLocationSelectorInSubPageDisabled}
                selectedLocations={selectedLocations}
                onApply={onApplyButtonClick}
              />
            </LocationSelectorContainer>
          ) : null}
          {activePage && (!!activePage.dateRangeSelector || (currentSubPage && !!currentSubPage.dateRangeSelector)) ? (
            <DateRangePickerContainer>
              {getPageDateRangePickerValue(activePage, pathname) === 'lmiPeriodSelector' && (
                <MonthPicker
                  appliedPeriod={lmiPeriod}
                  lmiPeriods={lmiPeriods}
                  onApply={appliedPeriod => setLmiPeriod(appliedPeriod)}
                />
              )}

              {getPageDateRangePickerValue(activePage, pathname) === 'lmiPeriodView' && (
                <PeriodVisualizer period={lmiPeriod} />
              )}

              {getPageDateRangePickerValue(activePage, pathname) === 'merchantCentricPeriodView' && (
                <PeriodVisualizer period={merchantCentricPeriod} useFullDate />
              )}

              {getPageDateRangePickerValue(activePage, pathname) === 'rfmView' && (
                <SimpleDayPicker
                  ranges={[30, 90, 180, 360]}
                  daysLabelEdge={90}
                  selectedRange={rfmRange}
                  onApply={setRFMRangeAction}
                />
              )}

              {getPageDateRangePickerValue(activePage, pathname) === 'advancedAnalyticsDateRange' && (
                <AdvancedDateRangePicker
                  labelColor={baseColors.white}
                  appliedEndDate={endDate}
                  appliedStartDate={isMoreThanOneWeekRange ? advancedAnalyticsStartDate : startDate}
                  onApply={applyDateRange}
                  isTablet={isTablet}
                  isMobile={isMobile}
                  windowWidth={windowWidth}
                  hideChevron={pdfMode}
                  appliedEndPredictiveDate={endPredictiveDate}
                  is30DaysPredictionData={is30DaysPredictionData}
                />
              )}

              {getPageDateRangePickerValue(activePage, pathname) === true && (
                <DateRangePicker
                  labelColor={baseColors.white}
                  appliedEndDate={endDate}
                  appliedStartDate={startDate}
                  onApply={applyDateRange}
                  isTablet={isTablet}
                  isMobile={isMobile}
                  windowWidth={windowWidth}
                  hideChevron={pdfMode}
                  appliedEndPredictiveDate={endPredictiveDate}
                  is30DaysPredictionData={is30DaysPredictionData}
                />
              )}
            </DateRangePickerContainer>
          ) : null}
          {showContactsWithoutLocationsContainer ? (
            <ContactsWithoutLocationsContainer>
              <Checkbox
                colorInverted
                disabled={isLocationSelectorInSubPageDisabled}
                size={checkboxSize}
                borderWidth={borderCheckboxSize}
                id="ContactsWithoutLocations"
                checked={isContactsWithoutLocations}
                onChange={() => {
                  if (!isLocationSelectorInSubPageDisabled) {
                    setIsContactsWithoutLocations(!isContactsWithoutLocations);
                  }
                }}
              />
              <ContactsWithoutLocationsLabel
                htmlFor="ContactsWithoutLocations"
                disabled={isLocationSelectorInSubPageDisabled}
              >
                {t('contactsWithoutLocationsLabel', 'Include contacts with no location')}
              </ContactsWithoutLocationsLabel>
            </ContactsWithoutLocationsContainer>
          ) : null}
        </ToolsContainer>
      )}
    </ToolbarSection>
  );
};

export const Toolbar = withTranslation(DEFAULT_NS)(
  connect<AttachedProps, AttachedActions, OwnProps & TProps, AppState>(
    (state: AppState): AttachedProps => {
      const activePage = getActivePage(state);
      const pathname = getPathname(state);
      const config = getConfig(state);
      const currentSubPage =
        activePage && Array.isArray(activePage.subPages)
          ? activePage.subPages.find((page): boolean => pathname.includes(page.path))
          : undefined;
      const lmiPeriods = isComponentEnabledOnThePage(currentSubPage || activePage, COMPONENT_NAME)
        ? getPeriods(state, true)
        : getPeriods(state);

      return {
        activePage,
        currentSubPage,
        config,
        pathname,
        useShowLeftHandSideMenuWithoutModulesLinks: useShowLeftHandSideMenuWithoutModulesLinks(state),
        collapsedState: getLeftSideMenuState(state),
        selectedLocations: getSelectedLocations(state),
        isContactsWithoutLocations: getIsContactsWithoutLocations(state),
        rfmRange: getRFMRange(state),
        ...getDateRange(state),
        lmiPeriod: getSelectedPeriod(state),
        lmiPeriods,
        merchantCentricPeriod: getMerchantCentricLastReceivedDate(state),
        isHelpersModeStateEnabled: getHelpersModeState(state)
      };
    },
    dispatch =>
      bindActionCreators(
        {
          openSideMenu: openLeftHandSideMenu,
          closeSideMenu: closeLeftHandSideMenu,
          hideTabsSideMenu: hideTabsLeftHandSideMenu,
          setSelected: locationsActions.select,
          setIsContactsWithoutLocations: locationsActions.isContactsWithoutLocations,
          setDateRangeAction: setDateRange,
          setRFMRangeAction: rfmActions.setRange,
          setLmiPeriod: masterCardActions.setPeriod,
          resetApiError: errorsActions.hideApiError
        },
        dispatch
      )
  )(ToolbarComponent)
);
