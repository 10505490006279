import { createAsyncAction } from 'typesafe-actions';
import { WeatherData } from './types';

const fetchWeatherActions = createAsyncAction(
  '@weather/fetch/request',
  '@weather/fetch/success',
  '@weather/fetch/error'
)<void, WeatherData[], void>();

export const fetchWeather = fetchWeatherActions;
