import { createAction, createAsyncAction } from 'typesafe-actions';
import { EMCampaignsDataResponse } from 'store/emailMarketing/campaigns';
import { ServerTemplate, SendTestEmailPayload } from './types';

const selectOptionsTab = createAction(
  '@em/campaignEditor/options/tab/select',
  action => (tabId: number) => action(tabId)
);

const selectStaticTemplate = createAction(
  '@em/campaignEditor/template/select',
  action => (templateId: string) => action(templateId)
);

const selectCampaign = createAction(
  '@em/campaignEditor/campaign/select',
  action => (campaign?: EMCampaignsDataResponse) => action(campaign)
);

const fetchStaticTemplates = createAsyncAction(
  '@em/campaignEditor/staticTemplates/fetch',
  '@em/campaignEditor/staticTemplates/success',
  '@em/campaignEditor/staticTemplates/error'
)<void, ServerTemplate[], void>();

const fetchCustomerTemplates = createAsyncAction(
  '@em/campaignEditor/customerTemplates/fetch',
  '@em/campaignEditor/customerTemplates/success',
  '@em/campaignEditor/customerTemplates/error'
)<void, any, void>();

const getCustomerTemplatePreview = createAsyncAction(
  '@em/campaignEditor/customerTemplatePreview/fetch',
  '@em/campaignEditor/customerTemplatePreview/success',
  '@em/campaignEditor/customerTemplatePreview/error'
)<string, ServerTemplate, void>();

const saveCustomerTemplate = createAsyncAction(
  '@em/campaignEditor/template/save/request',
  '@em/campaignEditor/template/save/success',
  '@em/campaignEditor/template/save/error'
)<string, void, void>();

const deleteCustomerTemplate = createAsyncAction(
  '@em/campaignEditor/customerTemplate/delete/request',
  '@em/campaignEditor/customerTemplate/delete/success',
  '@em/campaignEditor/customerTemplate/delete/error'
)<string, any[], void>();

const storeLinkedToTemplateCompanies = createAction(
  '@em/campaignEditor/customerTemplate/delete/storeLinkedToTemplateCompanies',
  action => (companiesIds: string[]) => action(companiesIds)
);

const selectCustomerTemplate = createAsyncAction(
  '@em/campaignEditor/customerTemplate/select/request',
  '@em/campaignEditor/customerTemplate/select/success',
  '@em/campaignEditor/customerTemplate/select/error'
)<string, void, void>();

const sendCampaignToCustomEmail = createAsyncAction(
  '@em/campaignEditor/sendCampaignToCustomEmail/fetch/request',
  '@em/campaignEditor/sendCampaignToCustomEmail/fetch/success',
  '@em/campaignEditor/sendCampaignToCustomEmail/fetch/error'
)<SendTestEmailPayload, void, string>();

const clearTestCampaignErrorType = createAction(
  '@em/campaignEditor/sendCampaignToCustomEmail/clearErrorType',
  action => () => action()
);

const createCampaign = createAsyncAction(
  '@em/campaignEditor/createCampaign/fetch/request',
  '@em/campaignEditor/createCampaign/fetch/success',
  '@em/campaignEditor/createCampaign/fetch/error'
)<any, void, void>();

export const campaignEditorActions = {
  selectOptionsTab,
  selectStaticTemplate,
  selectCampaign,
  fetchStaticTemplates,
  fetchCustomerTemplates,
  getCustomerTemplatePreview,
  saveCustomerTemplate,
  deleteCustomerTemplate,
  selectCustomerTemplate,
  sendCampaignToCustomEmail,
  clearTestCampaignErrorType,
  createCampaign,
  storeLinkedToTemplateCompanies
};
