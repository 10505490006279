import { createAsyncAction } from 'typesafe-actions';
import { User } from './types';

type SuccessResponse = User & { page: string };

export const authDashboardActions = createAsyncAction(
  '@dashboard/send-request',
  '@dashboard/request-success',
  '@dashboard/request-error'
)<string, SuccessResponse, void>();
