import { delay, fork, put, select, takeEvery } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { notificationTimeout } from '../../appConstants';
import { notificationsActions } from './actions';
import { getNotifications } from './selectors';

export function* notificationsSagas(): any {
  function* removeNotification(pathname: string, id?: string): any {
    yield delay(notificationTimeout);

    const { notifications } = yield select();

    const current = notifications[pathname];
    if (current && current.id !== id) return;

    yield put(notificationsActions.setNotificationsMap({ ...notifications, [pathname]: undefined }));
  }

  yield takeEvery(
    notificationsActions.addNotification,
    function* gen(action: ActionType<typeof notificationsActions.addNotification>) {
      const state = yield select();
      const notifications = getNotifications(state);
      const { type } = action.payload;
      const notificationId = `${Date.now()}`;
      const { pathname } = window.location;

      if (type === 'toast') {
        yield fork(removeNotification, pathname, notificationId);
      }

      yield put(
        notificationsActions.setNotificationsMap({
          ...notifications,
          [pathname]: { ...action.payload, id: notificationId }
        })
      );
    }
  );
}
