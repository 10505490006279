import { DateRangeState } from 'store/appDateRangePicker';
import { transactionsActions, TransactionsEvaluateGroupResponse } from 'store/transactions';
import { LocationsList } from 'store/settings';
import { Moment } from 'moment';

export enum LocationFor {
  testGroup = 'testGroup',
  controlGroup = 'controlGroup'
}

export interface DateRange {
  startDate: Moment;
  endDate: Moment;
}

export interface AAEvaluateTranslations {
  pageHeader: string;
  testGroupHeaderHint: string;
  controlGroupHeaderHint: string;
  transactionsTitle: string;
  salesValueTitle: string;
  averageTicketTitle: string;
  testGroupTitle: string;
  controlGroupTitle: string;
  export: string;
}

export interface ConnectedProps {
  testGroupSelectedLocations: string[];
  controlGroupSelectedLocations: string[];
  dateRange: DateRangeState;
  testGroupData: TransactionsEvaluateGroupResponse;
  controlGroupData: TransactionsEvaluateGroupResponse;
  isTestGroupDropDownShown: boolean;
  isControlGroupDropDownShown: boolean;
  isDateRangeDropdownOpen: boolean;
  expandedCharts: string[];
  locations: LocationsList;
}

export interface EvaluateViewPropsOwn {
  translations: AAEvaluateTranslations;
  setSelectedTestGroup: (val: string[]) => void;
  setSelectedControlGroup: (val: string[]) => void;
  setExpandedCharts: typeof transactionsActions.setExpandedCharts;
  isPdfMode?: boolean;
}

export type EvaluateViewProps = EvaluateViewPropsOwn & ConnectedProps;

export interface CompareItemChartStats {
  date: string;
  value: number | null;
}

export interface ControlGroupItemChartStats {
  date: string;
  controlGroupValue: number | null;
}

export interface IntersectingDataChunkStats {
  date: string;
  chunkValue: number | null;
}

export enum StatisticCardTypes {
  transactions = 'transactions',
  sales = 'sales',
  averageTicket = 'averageTicket'
}
