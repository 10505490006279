import { ActionType, createReducer } from 'typesafe-actions';
import { IdentityMappingState } from './types';
import { identityMappingActions } from './actions';

const initialState: IdentityMappingState = {
  loading: false,
  error: false,
  data: null,
  merchantSequenceKey: null,
  selectedEnterpriseMerchants: null
};

export const identityMappingReducer = createReducer<IdentityMappingState, ActionType<typeof identityMappingActions>>(
  initialState
)
  .handleAction(identityMappingActions.fetchIdentityMapping.request, () => ({ ...initialState, loading: true }))
  .handleAction(identityMappingActions.fetchIdentityMapping.success, (state, { payload }) => ({
    ...state,
    loading: false,
    error: false,
    data: payload.identityMapping,
    merchantSequenceKey: payload.merchantSequenceKey
  }))
  .handleAction(identityMappingActions.fetchIdentityMapping.failure, () => ({ ...initialState, error: true }))
  .handleAction(identityMappingActions.fetchEnterpriseData, (state, { payload }) => ({
    ...state,
    selectedEnterpriseMerchants: payload.merchantSequenceKeys
  }));
