import moment from 'moment';
import * as types from './types';

const getValue = (n: number, multiplier: number) => {
  if (n % 3) return 100 * multiplier;
  if (n % 3) return 200 * multiplier;
  return 300 * multiplier;
};

export const getMockedData = (range: number): types.RFMData => {
  const start = moment('2020-05-01');

  const days = new Array(range).fill(undefined).map((nothing, index) => index + 1);

  const tier1Data = days.map(n => ({
    starttime: moment(start).add(n, 'days').format('YYYY-MM-DD'),
    recency: getValue(n, 1) % 30,
    frequency: getValue(n, 12) - n * 0.01,
    monetary: getValue(n, 13) - n * 0.01
  }));

  const tier2Data = days.map(n => ({
    starttime: moment(start).add(n, 'days').format('YYYY-MM-DD'),
    recency: getValue(n, 2) % 29,
    frequency: getValue(n, 22) - n,
    monetary: getValue(n, 23) - n
  }));

  const tier3Data = days.map(n => ({
    starttime: moment(start).add(n, 'days').format('YYYY-MM-DD'),
    recency: getValue(n, 3) % 28,
    frequency: getValue(n, 32) - n,
    monetary: getValue(n, 33) - n
  }));

  const tier4Data = days.map(n => ({
    starttime: moment(start).add(n, 'days').format('YYYY-MM-DD'),
    recency: getValue(n, 4) % 27,
    frequency: getValue(n, 42) - n,
    monetary: getValue(n, 43) - n
  }));

  const tier5Data = days.map(n => ({
    starttime: moment(start).add(n, 'days').format('YYYY-MM-DD'),
    recency: getValue(n, 5) % 26,
    frequency: getValue(n, 52) - n,
    monetary: getValue(n, 53) - n
  }));

  return {
    tier1: {
      data: tier1Data,
      lifetimeValue: 10000
    },
    tier2: {
      data: tier2Data,
      lifetimeValue: 25000
    },
    tier3: {
      data: tier3Data,
      lifetimeValue: 50000
    },
    tier4: {
      data: tier4Data,
      lifetimeValue: 75000
    },
    tier5: {
      data: tier5Data,
      lifetimeValue: 100000
    }
  };
};
