import { isEmpty } from 'lodash';
import { baseColors, defaultColorSchema } from 'appConstants';
import { BrandingState } from 'store/branding';
import { ConfigurableColors, ApplicationColorTheme } from './types';

export const oneUiThemeToConfigurableColors = (oneUITheme: BrandingState['theme']): ConfigurableColors => ({
  color1: oneUITheme?.palette?.brand?.primary || defaultColorSchema.color1,
  color2: oneUITheme?.palette?.additional?.color1 || defaultColorSchema.color2,
  color3: oneUITheme?.palette?.additional?.color2 || defaultColorSchema.color3,
  color4: oneUITheme?.palette?.additional?.color3 || defaultColorSchema.color4,
  color5: oneUITheme?.palette?.additional?.color4 || defaultColorSchema.color5,
  color6: oneUITheme?.palette?.additional?.color6 || defaultColorSchema.color6,
  color7: oneUITheme?.palette?.additional?.color5 || defaultColorSchema.color7,
  headerColor: oneUITheme?.palette?.brand?.secondary || defaultColorSchema.headerColor
});

export const getTheme = (configurableColors: ConfigurableColors): ApplicationColorTheme => ({
  baseColors,
  configurableColors
});

export const getColor = (props: any, colorName: string): string => {
  const { theme } = props;
  const { baseColors: baseColorsProp, configurableColors } = theme;

  if (isEmpty(theme)) return '';

  if (colorName in configurableColors) return configurableColors[colorName];
  if (colorName in baseColorsProp) return baseColorsProp[colorName];

  return '';
};
