import { createAsyncAction } from 'typesafe-actions';
import { CRMAutomationData, EventName, Status } from './types';

export const emailMarketingCrmAutomation = {
  fetchStatuses: createAsyncAction(
    '@em/crmAutomation/getStatuses',
    '@em/crmAutomation/getStatuses/success',
    '@em/crmAutomation/getStatuses/error'
  )<void, CRMAutomationData[], void>(),

  turnOn: createAsyncAction(
    '@em/crmAutomation/turnOn',
    '@em/crmAutomation/turnOn/success',
    '@em/crmAutomation/turnOn/error'
  )<{ eventName: EventName; saveTemplateChanges?: boolean; campaignStatus?: Status }, void, void>(),

  turnOff: createAsyncAction(
    '@em/crmAutomation/turnOff',
    '@em/crmAutomation/turnOff/success',
    '@em/crmAutomation/turnOff/error'
  )<EventName, void, void>(),

  fetchTemplates: createAsyncAction(
    '@em/crmAutomation/getTemplates',
    '@em/crmAutomation/getTemplates/success',
    '@em/crmAutomation/getTemplates/error'
  )<void, any[], void>(),

  saveTemplate: createAsyncAction(
    '@em/crmAutomation/saveTemplate',
    '@em/crmAutomation/saveTemplate/success',
    '@em/crmAutomation/saveTemplate/error'
  )<void, void, void>()
};
