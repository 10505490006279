import React, { ReactElement } from 'react';
import { ReactComponent as ApiErrorSvg } from 'assets/error/apiError.svg';
import { useTranslation } from 'react-i18next';
import { ERROR_PAGES } from '../../appConstants/translationNamespaces';
import { Container } from './styles';

export const ApiError = (): ReactElement => {
  const { t } = useTranslation(ERROR_PAGES);
  return (
    <Container>
      <div>
        <ApiErrorSvg />
        <p>{t('apiError', 'Uh-oh! There’s been an error. Please refresh the page or try back again later.')}</p>
      </div>
    </Container>
  );
};
