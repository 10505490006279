import { createAction, createAsyncAction } from 'typesafe-actions';
import { Contact } from './types';

const setTermsAndConditionsAcceptState = createAction(
  '@em/contacts/termsAndConditions/accept',
  action => (value: boolean) => action({ value })
);

const setContactsList = createAction('@em/contacts/list/set', action => (data: Contact[]) => action({ data }));

const setUnsubscribedContactsList = createAction(
  '@em/contacts/unsubscribed/list/set',
  action => (data: Contact[]) => action({ data })
);

const setBouncedList = createAction('@em/contacts/bounced/list/set', action => (data: Contact[]) => action({ data }));

const fetchContacts = createAsyncAction(
  '@em/contacts/active/fetch/request',
  '@em/contacts/active/fetch/success',
  '@em/contacts/active/fetch/error'
)<any, any, void>();

const fetchUnsubscribedContacts = createAsyncAction(
  '@em/contacts/unsubscribed/fetch/request',
  '@em/contacts/unsubscribed/fetch/success',
  '@em/contacts/unsubscribed/fetch/error'
)<any, any, void>();

const fetchBouncedContacts = createAsyncAction(
  '@em/contacts/bounced/fetch/request',
  '@em/contacts/bounced/fetch/success',
  '@em/contacts/bounced/fetch/error'
)<any, any, void>();

const fetchContactsCount = createAsyncAction(
  '@em/contactsCount/fetch/request',
  '@em/contactsCount/fetch/success',
  '@em/contactsCount/fetch/error'
)<any, any, void>();

const createContact = createAsyncAction(
  '@em/contacts/create/request',
  '@em/contacts/create/success',
  '@em/contacts/create/error'
)<any, any, void>();

const updateContact = createAsyncAction(
  '@em/contacts/update/request',
  '@em/contacts/update/success',
  '@em/contacts/update/error'
)<any, void, void>();

const deleteContact = createAsyncAction(
  '@em/contacts/delete/request',
  '@em/contacts/delete/success',
  '@em/contacts/delete/error'
)<any, any, void>();

const importContacts = createAsyncAction(
  '@em/contacts/import/request',
  '@em/contacts/import/success',
  '@em/contacts/import/error'
)<any, any, void>();

const updateBackgroundJobStatus = createAction('@em/backgroundJob/update', action => (status: any) => action(status));

const resetBackgroundJobStatus = createAction('@em/backgroundJob/reset', action => () => action());

const setEmailExistsAsCompanyEmailModalState = createAction(
  '@em/emailExistsAsCompanyEmailModalState/set',
  action => (state: boolean) => action(state)
);

export const contactsActions = {
  fetchContacts,
  fetchUnsubscribedContacts,
  fetchBouncedContacts,
  fetchContactsCount,
  setTermsAndConditionsAcceptState,
  setContactsList,
  setUnsubscribedContactsList,
  setBouncedList,
  createContact,
  updateContact,
  deleteContact,
  importContacts,
  updateBackgroundJobStatus,
  resetBackgroundJobStatus,
  setEmailExistsAsCompanyEmailModalState
};
