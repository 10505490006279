import styled from 'styled-components';
import { getColor } from '../../layout/theme';
import { resetButtonStyles, textMetaInfo } from '../../layout/mixins';

export const ActionsBarContainer = styled.div`
  padding: 5px 11px;
  height: 30px;
  display: flex;
  ${textMetaInfo};
  border-bottom: 1px solid ${props => getColor(props, 'blueGrey')};
  align-items: center;
  button {
    ${resetButtonStyles};
    color: ${props => getColor(props, 'color1')};
    font-weight: bold;
    margin-right: 25px;
    &:last-child {
      margin-right: 0;
    }
    &:disabled {
      cursor: not-allowed;
      color: ${props => getColor(props, 'grey')};
      #ICONS {
        fill: ${props => getColor(props, 'grey')};
      }
    }
  }
`;
