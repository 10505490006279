import React, { ReactElement } from 'react';
import styled from 'styled-components';
import 'react-quill/dist/quill.snow.css';
import { getColor } from 'layout/theme';
import { textRegular } from 'layout/mixins';
import { MailBox as MailBoxType } from 'store/emailMarketing/emailTemplateEditor';
import { Text } from './Text';
import { Button } from './Button';
import { Image } from './Image';
import { Coupon } from './Coupon';
import { Text2Col } from './Text2Col';
import { Divider } from './Divider';
import { Spacer } from './Spacer';
import { Social } from './Social';

const components: { [key: string]: any } = {
  text: Text,
  button: Button,
  image: Image,
  coupon: Coupon,
  text2col: Text2Col,
  divider: Divider,
  spacer: Spacer,
  social: Social
};

interface OwnProps {
  className?: string;
  editable?: boolean;
  box: MailBoxType;
  styles?: any;
  settings?: any;
  translations: { [key: string]: string };
}

const MailBoxComponent = (props: OwnProps): ReactElement => {
  const { className, box, styles, settings, translations } = props;

  const Box = components[box.type];

  const { backgroundColor = '' } = styles || {};

  return (
    <div style={{ backgroundColor }} className={className}>
      <Box translations={translations} styles={styles} settings={settings} {...box} />
    </div>
  );
};

const ifEditable = (props: { editable?: boolean }) => {
  if (props.editable) return `border: 1px dashed ${getColor(props, 'grey')}`;
  return '';
};
export const MailBox = styled(MailBoxComponent)`
  ${textRegular};
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: move;

  width: 602px;
  padding: 3px 0;

  line-height: 1.42857143;
  text-align: center;

  img {
    vertical-align: middle;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: initial;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  h1 {
    font-size: 36px;
  }
  h2 {
    font-size: 30px;
  }
  h3 {
    font-size: 24px;
  }
  h4 {
    font-size: 18px;
  }
  h5 {
    font-size: 14px;
  }
  h6 {
    font-size: 12px;
  }

  p {
    margin: 0;
  }

  a {
    color: ${props => getColor(props, 'color1')};
    &:visited {
      color: ${props => getColor(props, 'color1')};
    }
    text-decoration: none;
  }

  .ql-font-sans-serif {
    font-family: sans-serif;
  }

  .ql-font-arial {
    font-family: Arial;
  }

  .ql-font-verdana {
    font-family: Verdana;
  }

  .ql-font-georgia {
    font-family: Georgia;
  }

  .ql-font-timesnewroman {
    font-family: 'Times New Roman';
  }

  .ql-align-left {
    text-align: left;
  }

  .ql-align-right {
    text-align: right;
  }

  .ql-align-center {
    text-align: center;
  }

  .ql-align-justify {
    text-align: justify;
  }

  ${ifEditable};

  > div {
    width: 100% !important;
    img {
      max-width: 100% !important;
    }
  }
`;
