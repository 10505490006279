import React, { MutableRefObject, useEffect, useRef, useState, ReactElement } from 'react';
import { bindDocumentClickOrTouchListener } from 'services/utils';
import { SearchBar } from './SearchBar';
import { Button } from '../Button';
import { RecentSearches } from './RecentSearches';
import { StatusBar } from './StatusBar';
import { ActionsBarContainer } from './ActionsBar';
import { OptionsList, OptionsListContainer } from './Options';
import { Container, DropdownContainer, Footer, FooterButtons, PaginationWrapper, DisableLayer } from './components';
import { AdvancedSelectorProps } from './types';
import { Pagination } from '../Pagination';

const paginationItemsPerPage = 25;

export const documentClickHandler =
  (
    inputRef: MutableRefObject<HTMLInputElement | null>,
    containerEl: MutableRefObject<HTMLDivElement | null>,
    fn: () => void
  ) =>
  (e: MouseEvent) => {
    if (containerEl && containerEl.current && !containerEl.current.contains(e.target as Node)) {
      fn();
      if (inputRef && inputRef.current) inputRef.current.blur();
    }
  };

export function AdvancedSelector<I, T>(props: AdvancedSelectorProps<I, T>): ReactElement {
  const {
    items,
    renderItemFn,
    recentSearchesList,
    availableCount,
    onApply,
    onCancel,
    actionBars,
    onFilterStringChange,
    onSelectedCountClick,
    isOnlySelectedShown,
    isSimpleMode,
    isSupportSearch,
    simpleToolbarRenderFn,
    isMobile,
    dropDownFullHeight,
    selectedCountString,
    textsMap,
    onFavoriteClick,
    favoriteItems,
    renderFavoriteItemFn,
    selectedFilter,
    selectedFilterTerm,
    showDropdown,
    onSearchEnter = (val: string) => {},
    onDropDownOpen = () => {},
    disableApplyOnNoCheck,
    checkedCount,
    disabled,
    withBorder,
    setShowOnlySelected,
    isFilterToggleEnabled
  } = props;

  /*
   * Refs
   * */
  const containerEl = useRef<HTMLDivElement>(null);
  const footerEl = useRef<HTMLDivElement>(null);
  const listEl = useRef<HTMLDivElement>(null);
  const searchBarRef = useRef<HTMLInputElement | null>(null);
  /*
   * State
   * */
  const [filterString, setFilterString] = useState('');
  const [isDropDownShown, setDropDownShownState] = useState(false);
  const [paginationSelectedPage, setSelectedPaginationPage] = useState(0);
  const [isFavoriteShown, setFavoriteShownState] = useState(false);
  const [scrollListHeight, setScrollListHeight] = useState<number>(318);

  /*
   * Effects
   * */
  useEffect(() => {
    if (typeof showDropdown === 'boolean' && showDropdown !== isDropDownShown) {
      setDropDownShownState(showDropdown);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showDropdown]);

  useEffect(() => {
    if (isDropDownShown || isFavoriteShown) {
      return bindDocumentClickOrTouchListener(
        documentClickHandler(searchBarRef, containerEl, () => {
          setFavoriteShownState(false);
          setDropDownShownState(false);
          onCancel();
        })
      );
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDropDownShown, isFavoriteShown]);

  useEffect(() => {
    if (isDropDownShown) onDropDownOpen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDropDownShown]);

  useEffect(() => {
    if (
      (isMobile || dropDownFullHeight) &&
      isDropDownShown &&
      listEl &&
      listEl.current &&
      footerEl &&
      footerEl.current
    ) {
      const nextScrollHeight =
        window.innerHeight - listEl.current.getBoundingClientRect().top - footerEl.current.scrollHeight;
      if (nextScrollHeight !== scrollListHeight) {
        setScrollListHeight(nextScrollHeight);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile, isDropDownShown]);

  useEffect(() => {
    if (listEl && listEl.current instanceof Node) {
      listEl.current.scrollTop = 0;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationSelectedPage]);

  useEffect(() => {
    if (selectedFilter) {
      setFavoriteShownState(false);
      setDropDownShownState(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilter]);

  /*
   * Methods
   * */
  const applyButtonHandler = (): void => {
    setDropDownShownState(false);
    onApply();
  };

  const cancelButtonHandler = (): void => {
    setDropDownShownState(false);
    onCancel();
  };

  const changePaginationPageHandler = (page: number): void => {
    setSelectedPaginationPage(page);
  };

  const filterStringChangeHandler = (val: string): void => {
    const valueToSet = val.trimStart();

    setFilterString(valueToSet);
    changePaginationPageHandler(0);
    if (typeof onFilterStringChange === 'function') onFilterStringChange(valueToSet);
  };

  const selectedPageCountClickHandler = (): void => {
    if (typeof onSelectedCountClick === 'function') onSelectedCountClick();
    setDropDownShownState(true);
    changePaginationPageHandler(0);
    setFavoriteShownState(false);
  };

  const favoriteIconClickHandler = (): void => {
    const nextState = !isFavoriteShown;
    setFavoriteShownState(nextState);
    onFavoriteClick(nextState);
  };

  useEffect(() => {
    filterStringChangeHandler(selectedFilterTerm !== undefined ? selectedFilterTerm : '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilterTerm]);

  /*
   * Render
   * */
  const itemsForRender = items.slice(
    paginationSelectedPage * paginationItemsPerPage,
    (paginationSelectedPage + 1) * paginationItemsPerPage
  );

  return (
    <Container ref={containerEl}>
      {disabled && (
        <DisableLayer
          onClick={e => {
            e.stopPropagation();
          }}
        />
      )}
      <SearchBar
        isMobile={isMobile}
        inputRef={searchBarRef}
        onEnter={onSearchEnter}
        onFavoriteClick={favoriteIconClickHandler}
        simpleMode={isSimpleMode}
        isSupportSearch={isSupportSearch}
        simpleBarContent={simpleToolbarRenderFn}
        isDropdownShown={isDropDownShown}
        labelString={selectedCountString}
        searchValue={filterString}
        onSearchStringChange={filterStringChangeHandler}
        onInputFocus={() => {
          setDropDownShownState(true);
          setFavoriteShownState(false);
        }}
        onClick={() => setShowOnlySelected(false)}
        onRotateArrowClick={() => setDropDownShownState(!isDropDownShown)}
        onLabelClick={selectedPageCountClickHandler}
        isLabelActive={isOnlySelectedShown}
        searchPlaceholder={textsMap.searchPlaceholder}
        withBorder={withBorder}
        isFilterToggleEnabled={isFilterToggleEnabled}
      />

      {isDropDownShown && !isFavoriteShown && (
        <DropdownContainer data-test-id="expanded-view" isMobile={isMobile} withBorder={withBorder}>
          {!isSimpleMode && Array.isArray(recentSearchesList) && (
            <RecentSearches
              label={textsMap.recentSearchesLabel}
              onItemClick={filterStringChangeHandler}
              currentSearchString={filterString}
              items={recentSearchesList}
            />
          )}

          {!isSimpleMode && typeof availableCount === 'number' && (
            <StatusBar label={textsMap.statusBarLabel} itemsCount={availableCount} />
          )}

          {Array.isArray(actionBars) &&
            actionBars.map((bar, barIndex) => (
              // eslint-disable-next-line react/no-array-index-key
              <ActionsBarContainer key={barIndex}>
                {/* eslint-disable-next-line react/no-array-index-key */}
                {bar.map((fn, barElIndex) => (
                  <React.Fragment key={barElIndex}>{fn()}</React.Fragment>
                ))}
              </ActionsBarContainer>
            ))}

          <OptionsListContainer MobileHeight={scrollListHeight} ref={listEl} FixedHeight data-test-id="options-list">
            <OptionsList notFoundText={textsMap.emptyItemsList} items={itemsForRender} renderFn={renderItemFn} />
          </OptionsListContainer>

          <Footer ref={footerEl}>
            {items.length > paginationItemsPerPage && (
              <PaginationWrapper>
                <Pagination
                  showPerPageCtrl={false}
                  selectedPage={paginationSelectedPage}
                  onPageSelect={changePaginationPageHandler}
                  itemsCount={items.length}
                  pageSize={paginationItemsPerPage}
                />
              </PaginationWrapper>
            )}
            <FooterButtons>
              <Button onClick={cancelButtonHandler} secondary data-test-id="cancel">
                {textsMap.cancelBtn}
              </Button>
              <Button
                disabled={disableApplyOnNoCheck && checkedCount === 0}
                onClick={applyButtonHandler}
                primary
                data-test-id="apply"
              >
                {textsMap.applyBtn}
              </Button>
            </FooterButtons>
          </Footer>
        </DropdownContainer>
      )}

      {isFavoriteShown && (
        <DropdownContainer data-test-id="favorite-items-list" isMobile={isMobile}>
          <OptionsListContainer ref={listEl}>
            <OptionsList notFoundText={textsMap.noSavedFilters} items={favoriteItems} renderFn={renderFavoriteItemFn} />
          </OptionsListContainer>
        </DropdownContainer>
      )}
    </Container>
  );
}
