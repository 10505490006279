import { createAction, createAsyncAction } from 'typesafe-actions';
import * as types from './types';

const addBox = createAction(
  '@em/templateEditor/box/add',
  action => (box: types.MailBox, index: number) => action({ box, index })
);

const updateBox = createAction(
  '@em/templateEditor/box/update',
  action => (id: string, boxData: types.MailBoxData) => action({ id, boxData })
);

const moveBox = createAction(
  '@em/templateEditor/box/move',
  action => (oldIndex: number, newIndex: number) => action({ oldIndex, newIndex })
);

const removeBox = createAction('@em/templateEditor/box/remove', action => (id: string) => action({ id }));

const setBackgroundColor = createAction(
  '@em/templateEditor/background/set',
  action => (color: string) => action({ color })
);

const setBoxes = createAction(
  '@em/templateEditor/boxesList/set',
  action => (boxes: types.MailBox[]) => action({ components: boxes })
);

const resetEditor = createAction(
  '@em/templateEditor/reset',
  action => (boxes: types.MailBox[]) => action({ components: boxes })
);

const setTemplate = createAction('@em/templateEditor/template/set', action => (template: any) => action({ template }));

const setSubjectAndPreheader = createAction(
  '@em/templateEditor/subjectAndPreheader/set',
  action => (data: any) => action({ data })
);

const setMessage = createAction('@em/templateEditor/message/set', action => (message: any) => action({ message }));

const dropMessage = createAction('@em/templateEditor/message/drop', action => () => action());

const saveAgreement = createAsyncAction(
  '@em/templateEditor/saveAgreement/fetch/request',
  '@em/templateEditor/saveAgreement/fetch/success',
  '@em/templateEditor/saveAgreement/fetch/error'
)<any, any, void>();

export const emailTemplateEditorActions = {
  addBox,
  updateBox,
  moveBox,
  removeBox,
  setBoxes,
  setBackgroundColor,
  setTemplate,
  saveAgreement,
  setSubjectAndPreheader,
  setMessage,
  dropMessage,
  resetEditor
};
