import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import React, { ReactElement } from 'react';
import { textMetaInfo } from 'layout/mixins';
import { deviceMedia } from 'appConstants';

interface ToolTipWrapperProps {
  left?: number;
  textAlign?: string;
  withoutCustomMobileTooltip?: boolean;
  width?: number;
  isLeftPosition?: boolean;
  isFlexibleToContent?: boolean;
}

export const ToolTipWrapper = styled.div<ToolTipWrapperProps>`
  .__react_component_tooltip {
    max-width: ${props => props.width || 170}px;
    white-space: normal;
    font-weight: normal;
    font-size: 16px !important;
    color: rgba(4, 4, 4, 0.8);
    background-color: rgba(255, 255, 255, 1);
    opacity: 1 !important;
    box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.2);
    text-transform: none;
    text-align: ${props => props.textAlign || 'inherit'};
    ${({ isFlexibleToContent }) =>
      isFlexibleToContent ? 'height: fit-content; min-height: 65px; z-index: 2;' : 'z-index: 999;'};
    ${textMetaInfo};
    border-radius: 0;
    line-height: 1.2;
    padding: 10px 15px;

    ${({ isLeftPosition }) => (isLeftPosition ? 'left: auto; right: 100%; margin-right: 10px;' : '')};
    &.type-dark {
      &.place-top {
        :before,
        :after {
          border-top-color: rgba(79, 79, 79, 0.9);
        }
      }
      &.place-left {
        :before,
        :after {
          border-left-color: rgba(79, 79, 79, 0.9);
        }
      }
      &.place-right {
        :before,
        :after {
          border-right-color: rgba(79, 79, 79, 0.9);
        }
      }
      &.place-bottom {
        :before,
        :after {
          border-bottom-color: rgba(79, 79, 79, 0.9);
        }
      }
    }
  }

  @media ${({ isFlexibleToContent }) => (isFlexibleToContent ? deviceMedia.tablet : deviceMedia.mobile)} {
    ${props =>
      !props.withoutCustomMobileTooltip
        ? `
      .__react_component_tooltip {
        left: 10px !important;
        right: 0;
        width: calc(100% - 20px);
        max-width: 100%;
        &:after {
          left: ${props.left}px;
      }
    `
        : ''};
    ${({ isFlexibleToContent }) => (isFlexibleToContent ? 'top: 100%; z-index: 1; left: 0 !important;' : '')};
    ${props =>
      !props.withoutCustomMobileTooltip && props.left === 0
        ? `
      .__react_component_tooltip {
        &:after {
          content: none;
        }
      }
    `
        : ''};
  }
`;
type tooltipProps = ReactTooltip.Props & {
  questionRef?: React.RefObject<HTMLSpanElement> | null;
  textAlign?: string;
  withoutCustomMobileTooltip?: boolean;
  maxContentWidth?: number;
};
export const ToolTip = (props: tooltipProps): ReactElement => {
  const getPos = (ref: React.RefObject<HTMLSpanElement> | undefined | null): number => {
    if (!(ref && ref.current)) return 0;
    const TOOLTIP_LEFT_INDENT = 10;
    const halfOfQuestionIconWidth = ref.current.getBoundingClientRect().width / 2;
    return ref.current.getBoundingClientRect().left - (TOOLTIP_LEFT_INDENT - halfOfQuestionIconWidth);
  };
  return (
    <ToolTipWrapper
      left={getPos(props.questionRef)}
      textAlign={props.textAlign}
      withoutCustomMobileTooltip={props.withoutCustomMobileTooltip}
      width={props.maxContentWidth}
    >
      <ReactTooltip globalEventOff="touchstart" {...props} />
    </ToolTipWrapper>
  );
};
ToolTip.displayName = 'ToolTip';
