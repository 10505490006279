import { createAsyncAction } from 'typesafe-actions';
import { PageViewLog, PageViewResponse } from './types';

export const pageViewActions = {
  sendPageViewLog: createAsyncAction('@pageView/sendLog', '@pageView/sendLog/success', '@pageView/sendLog/error')<
    PageViewLog,
    PageViewResponse,
    PageViewResponse
  >()
};
