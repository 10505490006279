import React, { useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { authDashboardActions } from '../store/authDashboard/actions';

interface ConnectedProps {
  dashboardSendRequest: typeof authDashboardActions.request;
}

const DashboardComponent = (props: ConnectedProps & RouteComponentProps) => {
  useEffect(() => {
    try {
      const url = new URL(window.location.href);
      const jwt = url.searchParams.get('jwt') || '';
      const payload = JSON.parse(atob(jwt.split('.')[1]));
      if (payload && payload.demoEnv) {
        window.location.href = encodeURI(`/api/v1/dashboard${props.location.search}`);
        return;
      }
    } catch (e) {
      console.error(e);
    }
    props.dashboardSendRequest(props.location.search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <div />;
};

export const AuthDashboard = withRouter(
  connect<ConnectedProps>(null, dispatch =>
    bindActionCreators({ dashboardSendRequest: authDashboardActions.request }, dispatch)
  )(DashboardComponent)
);
