import React, { useState, useMemo, ReactElement } from 'react';
import { MapKeyString } from 'appConstants/commonTypes';

export interface PageElementProps<T = MapKeyString<boolean>> {
  componentName?: string;
  features: T;
  properties?: {
    width?: number | string;
  };
}

export const LazyComponent = ({ componentName, features, properties }: PageElementProps): ReactElement => {
  const [Component, setComponent] = useState<React.LazyExoticComponent<React.ComponentType<any>>>();

  useMemo(() => {
    const lazyComponent = React.lazy(async () => {
      try {
        const module = await import(`page-elements/${componentName}`);
        return module;
      } catch (e) {
        return import('./ModuleLoadingError');
      }
    });

    setComponent(lazyComponent);
  }, [componentName]);

  if (Component) {
    return <Component features={features} properties={properties} />;
  }

  return <div />;
};
