import { SettingsDataResponse } from 'store/settings';

type Data = SettingsDataResponse['variables'];

class VariablesService {
  private data: Data = {
    pendoKey: undefined,
    googleMapsKey: undefined,
    weatherKey: undefined,
    weatherHost: undefined,
    logTranslations: `${process.env.NODE_ENV}`.toLowerCase() === 'development',
    crmS3ImageUrl: undefined
  };

  public provide(data: Data): void {
    Object.entries(data).forEach(([key, value]) => {
      if (['string', 'boolean', 'number'].includes(typeof value)) this.data[key as keyof Data] = value;
    });
  }

  public getItem(key: keyof Data): string | null {
    return this.data[key];
  }
}

export const variablesService = new VariablesService();
