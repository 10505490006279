/* eslint-disable @typescript-eslint/no-explicit-any */
import hash from 'object-hash';

interface Cache {
  [key: string]: { ttl: number; response: any };
}

export class InMemoryCache {
  private readonly apiClient: any;
  private readonly postalCodesService: any;

  private static cache: Cache = {};

  private static timeToLiveMinutes: number = 5;

  public constructor(apiClient: any, postalCodesService?: any) {
    this.apiClient = apiClient;
    if (postalCodesService) this.postalCodesService = postalCodesService;
  }

  public async performApiCall(method: string, url: string, payload?: any, isErrorPage = false): Promise<any> {
    const cacheKey = InMemoryCache.buildCacheKey(method, url, payload);
    const cachedResponse = InMemoryCache.getFromCache(cacheKey);

    if (cachedResponse && !isErrorPage) {
      return cachedResponse;
    }
    const response = await this.apiClient[method](url, payload);
    InMemoryCache.addToCache(cacheKey, response);

    return response;
  }

  public async getPostalCodes(postalCode: string, countryCode: string) {
    if (!this.postalCodesService) throw new Error('InMemoryCache.getPostalCodes method not allowed');

    const cacheKey = InMemoryCache.buildCacheKey('postalCodes', postalCode, { postalCode, countryCode });
    const cachedResponse = InMemoryCache.getFromCache(cacheKey);

    if (cachedResponse) {
      return cachedResponse;
    }

    const response = await this.postalCodesService.area({ postalCode, countryCode });
    InMemoryCache.addToCache(cacheKey, response);

    return response;
  }

  public dropCache(): void {
    InMemoryCache.cache = {};
  }

  private static buildCacheKey(method: string, url: string, payload: any): string {
    return hash({ method, url, payload });
  }

  private static addToCache(key: string, value: any): void {
    this.cache[key] = { ttl: this.countTimeToLive(), response: value };
  }

  private static getFromCache(key: string): any {
    const cachedValue = this.cache[key];

    if (cachedValue) {
      if (cachedValue.ttl > new Date().getTime()) {
        return cachedValue.response;
      }
      delete this.cache[key];
    }

    return null;
  }

  private static countTimeToLive(): number {
    const nowDate = new Date().getTime();
    return nowDate + this.timeToLiveMinutes * 60000;
  }
}
