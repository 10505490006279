import React from 'react';
import moment, { Moment } from 'moment';
import { DEFAULT_NS } from 'appConstants/translationNamespaces';
import { useTranslation } from 'react-i18next';
import { formatString } from 'services/utils';
import { DateRangePickerBasic, CustomRangeInterface, DatePickerBasisProps } from 'shared-components/DatePickerBasic';
import { PrevBtn, NextBtn } from './components';
import { OnCancelPropTypes, DateChangeHandlerPropTypes } from '../DatePickerBasic';

interface PropTypes {
  appliedStartDate: Moment;
  appliedEndDate: Moment | null;
  onApply: (startDate: Moment, endDate: Moment, rangeName: string) => void;
  labelColor?: string;
  monthFormat?: string;
  isTablet?: boolean;
  isMobile?: boolean;
  windowWidth?: number;
  hideChevron?: boolean;
  appliedEndPredictiveDate?: Moment | null;
  is30DaysPredictionData: boolean;
}

type TranslationKeys =
  | 'yesterday'
  | 'last7Days'
  | 'last3Days'
  | 'thru'
  | 'applyBtnText'
  | 'cancelBtnText'
  | 'customRange'
  | 'dateRangeText'
  | 'predictiveDayTooltip'
  | 'predictive30DaysTooltip';

type DateRangePickerTranslations = {
  [key in TranslationKeys]: string;
};

const createRanges = (labels: DateRangePickerTranslations): CustomRangeInterface => {
  const createRange = (startDate: Moment, endDate: Moment) => ({ startDate, endDate });
  const ranges: CustomRangeInterface = {};
  ranges[labels.yesterday] = createRange(moment().subtract(1, 'days'), moment().subtract(1, 'days'));
  ranges[labels.last3Days] = createRange(moment().subtract(2, 'days'), moment());
  ranges[labels.last7Days] = createRange(moment().subtract(6, 'days'), moment());
  return ranges;
};

export const AdvancedDateRangePicker = (props: PropTypes) => {
  const {
    appliedEndDate,
    appliedStartDate,
    appliedEndPredictiveDate,
    onApply: onApplyHandler,
    isMobile,
    windowWidth,
    labelColor,
    is30DaysPredictionData
  } = props;
  const { t } = useTranslation(DEFAULT_NS);
  const translations: DateRangePickerTranslations = {
    yesterday: t('rangeYesterday', 'Yesterday'),
    dateRangeText: t('dateRangeAdvancedAnalyticsText', 'For the best result, please choose between 1-7 days'),
    last7Days: `${formatString(t('rangeLastDays', 'Last {0} Days'), '7').join('')}`,
    last3Days: `${formatString(t('rangeLastDays', 'Last {0} Days'), '3').join('')}`,
    thru: t('thru', 'thru'),
    customRange: t('customRange', 'Custom Range'),
    applyBtnText: t('apply', 'Apply'),
    cancelBtnText: t('labelCancel', 'Cancel'),
    predictiveDayTooltip: t('predictiveDayTooltip', '<b>Forecast Dates</b> show up to 7-days in <br/> the future'),
    predictive30DaysTooltip: t(
      'predictive30DaysTooltip',
      '<b>Forecast Dates</b> show up to 30-days in <br/> the future.'
    )
  };
  const ranges = createRanges(translations);

  const checkIsOutsideRange: DatePickerBasisProps['checkIsOutsideRange'] =
    (startDate, endDate, isEndDateSelected) => day => {
      const currentDay = day.clone().startOf('day');
      if (startDate && (!isEndDateSelected || endDate == null)) {
        return (
          currentDay.isBefore(moment(startDate).subtract(1, 'day')) ||
          currentDay.isAfter(moment(startDate).add(6, 'day')) ||
          currentDay.isAfter(moment())
        );
      }
      return currentDay.isAfter(moment());
    };

  const onCancel = (onCalcelProps: OnCancelPropTypes): void => {
    onCalcelProps.setInputFocus(null);
  };

  const dateChangeHandler = (dateChangeHandlerProps: DateChangeHandlerPropTypes): void => {
    const {
      setStartDate,
      nextStartDate,
      nextEndDate,
      setEndDateSelected,
      setEndDate,
      startDate,
      setSelectedRange,
      setInputFocus
    } = dateChangeHandlerProps;
    setStartDate(nextStartDate);
    if (nextEndDate == null) {
      setEndDateSelected(false);
    } else {
      setEndDateSelected(true);
    }
    setEndDate(nextStartDate && nextEndDate && nextStartDate !== startDate ? null : nextEndDate);
    setSelectedRange(undefined);
    if (nextStartDate && nextEndDate) setInputFocus('startDate');
  };

  return (
    <DateRangePickerBasic
      checkIsOutsideRange={checkIsOutsideRange}
      labelColor={labelColor}
      nextBtn={NextBtn}
      prevBtn={PrevBtn}
      onCancel={onCancel}
      dateChangeHandler={dateChangeHandler}
      isAdvancedCalendar
      appliedEndDate={appliedEndDate}
      appliedStartDate={appliedStartDate}
      onApply={onApplyHandler}
      ranges={ranges}
      isMobile={isMobile}
      windowWidth={windowWidth}
      translations={translations}
      appliedEndPredictiveDate={appliedEndPredictiveDate}
      is30DaysPredictionData={is30DaysPredictionData}
    />
  );
};
