import React, { useEffect, useState } from 'react';
import { isEmail, isEmpty, isInt, isURL } from 'validator';
import TextArea from 'react-autosize-textarea';
import styled from 'styled-components';
import { INPUT_MAX_SYMBOLS_COUNT } from 'appConstants';
import { stripTags } from 'services/utils';
import { getColor } from 'layout/theme';
import { textMetaInfo } from 'layout/mixins';
import { DEFAULT_NS } from 'appConstants/translationNamespaces';
import { useTranslation } from 'react-i18next';
import { onlyAlphabeticOrNumberRegexp, validationSpecialChartsForbiddenRegexp } from '../appConstants/emailMarketing';

export interface CustomValidationMessages {
  validationInvalidEmail?: string;
  validationInvalidUrl?: string;
  validationRequired?: string;
  validationInt?: string;
  validationMaxStringSize?: string;
  validationLength?: string;
  validationOnlyAlphabeticOrNumbers?: string;
  validationSpecialChartsForbidden?: string;
}

interface Validators {
  [k: string]: { message: string; isValid: (value: string, ...rest: string[]) => boolean };
}

interface TranslationsvalidationsMessage {
  email: string;
  url: string;
  required: string;
  numeric: string;
  maxStringSize: string;
  length: string;
  onlyAlphabeticOrNumbers: string;
  specialChartsForbidden: string;
}

export const createValidators = (
  messages: CustomValidationMessages,
  translations: TranslationsvalidationsMessage
): Validators => {
  const {
    validationInvalidEmail,
    validationInvalidUrl,
    validationRequired,
    validationInt,
    validationMaxStringSize,
    validationLength,
    validationOnlyAlphabeticOrNumbers,
    validationSpecialChartsForbidden
  } = messages;

  return {
    email: {
      message: validationInvalidEmail || translations.email,
      isValid: (value: string) => isEmpty(value) || isEmail(value)
    },
    url: {
      message: validationInvalidUrl || translations.url,
      isValid: (value: string) => isEmpty(value) || isURL(value)
    },
    required: {
      message: validationRequired || translations.required,
      isValid: (value: string) => !isEmpty(value, { ignore_whitespace: true })
    },
    numeric: {
      message: validationInt || translations.numeric,
      isValid: (value: string) => isInt(value, { min: 0, max: 1000000 })
    },
    maxStringSize: {
      message: validationMaxStringSize || translations.maxStringSize,
      isValid: (value: string) => String(value).length <= INPUT_MAX_SYMBOLS_COUNT
    },
    length: {
      message: validationLength || translations.length,
      isValid: (value: string, length: string) => value.length <= parseInt(length, 10)
    },
    onlyAlphabeticOrNumbers: {
      message: validationOnlyAlphabeticOrNumbers || translations.onlyAlphabeticOrNumbers,
      isValid: (value: string) => !isEmpty(value) && !onlyAlphabeticOrNumberRegexp.test(value)
    },
    validationSpecialChartsForbidden: {
      message: validationSpecialChartsForbidden || translations.specialChartsForbidden,
      isValid: (value: string) => validationSpecialChartsForbiddenRegexp.test(value)
    }
  };
};

interface TextInputWithValidationProps {
  value: string;
  onChange: Function;
  onSuccess?: any;
  onError?: any;
  validation?: any[];
  placeholder?: string;
  size?: number;
  disabled?: boolean;
  className?: string;
  multiline?: boolean;
  rows?: number;
  maxRows?: number;
  onKeyDown?: any;
  isTrimEnabled?: boolean;
  ref?: any;
  tipComponent?: any;
  changeValueOnInput?: (value: string) => boolean;
  validationMessages?: CustomValidationMessages;
  skipValidation?: (value: string) => boolean;
}

export const ValidationErrorText = styled.p`
  color: ${props => props.theme.baseColors.red};
  margin: 5px 0 0 0;
  ${textMetaInfo};
`;
ValidationErrorText.displayName = 'ValidationErrorText';

export const CustomInput: any = styled.input`
  border-width: 1px;
  border-style: solid;
  color: ${props => getColor(props, 'darkGrey')};
  border-color: ${(props: any) => (props.isValid ? props.theme.baseColors.grey : props.theme.baseColors.red)};
  appearance: none;
  border-radius: 0;
`;

export const CustomTextArea: any = styled(({ isValid, ...rest }) => <TextArea {...rest} />)`
  line-height: 1.5;
  border-color: ${(props: any) => (props.isValid ? props.theme.baseColors.grey : props.theme.baseColors.red)};
  appearance: none;
  border-radius: 0;
`;
CustomTextArea.displayName = 'CustomTextArea';

const TextInputWithValidationComponent = React.forwardRef((props: TextInputWithValidationProps, ref: any) => {
  const {
    value = '',
    size = 10,
    disabled = false,
    multiline = false,
    rows = 3,
    maxRows,
    onKeyDown = () => null,
    placeholder = '',
    className,
    isTrimEnabled = false,
    validation = [],
    onChange,
    onSuccess = () => {},
    onError = () => {},
    tipComponent,
    changeValueOnInput = () => true,
    validationMessages = {},
    skipValidation
  } = props;

  const { t } = useTranslation(DEFAULT_NS);

  const [isValid, toggleValid] = useState(true);
  const [message, setMessage] = useState('');

  const translations: TranslationsvalidationsMessage = {
    email: t('validationInvalidEmail', 'Please check the email format.'),
    url: t('validationInvalidUrl', 'Please check the URL format.'),
    required: t('validationRequired', 'This field is required.'),
    numeric: t('validationInt', 'Value should be positive integer number.'),
    maxStringSize: t('validationMaxStringSize', 'Text length should be 255 chars or less.'),
    length: t('validationLength', 'Too long value.'),
    onlyAlphabeticOrNumbers: t(
      'validationAlphabeticOrNumbers',
      'Only alphabetical and numerical characters are allowed.'
    ),
    specialChartsForbidden: t('validationSpecialChartsForbidden', 'Special characters are not supported.')
  };

  const validators = createValidators(validationMessages, translations);

  const validateInput = (nextValue: string) => {
    let safeValue = stripTags(nextValue);
    if (isTrimEnabled) safeValue = safeValue.trim();
    const firstFailedValidator = validation.find(item => {
      const [validatorName, ...rest] = item.split(':');
      if (!validators[validatorName]) {
        console.error(`Wrong validator "${validatorName}"`);
        return false;
      }
      return !validators[validatorName].isValid(safeValue, ...rest);
    });

    if (!disabled && firstFailedValidator) {
      const errorMessage = validators[firstFailedValidator.split(':')[0]].message;

      if (!changeValueOnInput(safeValue)) return;

      toggleValid(false);
      setMessage(errorMessage);
      onChange(safeValue);

      if (typeof onError === 'function') {
        onError(safeValue, errorMessage);
      }
    } else {
      if (!changeValueOnInput(safeValue)) return;

      toggleValid(true);
      setMessage('');
      onChange(safeValue);

      if (typeof onSuccess === 'function') {
        onSuccess(safeValue);
      }
    }
  };

  useEffect(
    () => {
      validateInput(value);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [disabled, value]
  );

  const valueChanged = ({ target }: { target: HTMLInputElement }) => {
    skipValidation
      ? !disabled && !skipValidation(target.value) && validateInput(target.value)
      : !disabled && validateInput(target.value);
  };

  const renderMultiLineInput = (): JSX.Element => (
    <>
      <CustomTextArea
        data-test-id="input-with-validation-multiline"
        ref={ref}
        className={className}
        value={value}
        onChange={valueChanged}
        onKeyDown={onKeyDown}
        title={message}
        placeholder={placeholder}
        disabled={disabled}
        rows={rows}
        maxRows={maxRows}
        isValid={isValid}
      />
      {message && <ValidationErrorText data-test-id="validation-error-text">{message}</ValidationErrorText>}
    </>
  );

  const renderSingleLineInput = (): JSX.Element => (
    <>
      <CustomInput
        data-test-id="input-with-validation-multiline"
        ref={ref}
        className={className}
        value={value}
        onChange={valueChanged}
        onKeyDown={onKeyDown}
        title={message}
        placeholder={placeholder}
        size={size}
        disabled={disabled}
        isValid={isValid}
      />
      {tipComponent && <>{tipComponent}</>}
      {message && <ValidationErrorText data-test-id="validation-error-text">{message}</ValidationErrorText>}
    </>
  );

  return multiline ? renderMultiLineInput() : renderSingleLineInput();
});

const TextInputWithValidation = styled(TextInputWithValidationComponent)`
  display: block;
  margin: 0;
  padding: 6px 12px;
  min-height: 34px;
  resize: none;
  outline: none;
  width: 100%;
  border-width: 1px;
  border-style: solid;
  ${props =>
    props.disabled
      ? `
    cursor: not-allowed;
    opacity: .7;
    > * {
      pointer-events: none;
    }
  `
      : ''};
`;
TextInputWithValidation.displayName = 'TextInputWithValidation';

export default TextInputWithValidation;
