import { get } from 'lodash';
import { PagesList } from '../store/settings/layout';

export const getPagePath = (pages: PagesList, rootName: string, subPageName: string) => {
  const root = pages.find(page => page.pageName === rootName);
  if (root && root.subPages && root.subPages.length) {
    const subPage = root.subPages.find(page => page.pageName === subPageName);
    return `${get(root, 'path', '')}${get(subPage, 'path', '')}`;
  }
  return get(root, 'path', '');
};

export const getSubPagePathFromMultiRoots = (pages: PagesList, rootName: string, subPageName: string) => {
  const roots = pages.filter(page => page.pageName === rootName);
  const results = roots.map(root => {
    if (root && root.subPages && root.subPages.length) {
      const subPage = root.subPages.find(page => page.pageName === subPageName);
      if (subPage) {
        return `${get(root, 'path', '')}${get(subPage, 'path', '')}`;
      }
      return false;
    }
    return false;
  });
  return results.find(path => !!path) || '';
};
