import { takeLatest, call, put, select } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import moment from 'moment';
import { AppState } from 'store/rootReducer';
import i18n from 'i18n';
import { getStartEndDates, setDateRange, getDateRange } from 'store/appDateRangePicker';
import { getSelectedLocations } from 'store/settings';
import { notificationsActions } from 'store/notifications';
import { locationsActions } from 'store/locations';
import { ApiGateway } from 'services/apiGateway';
import { googleAnalytics } from 'services/googleAnalytics';
import { INTERNATIONAL_DATE_FORMAT } from 'appConstants';
import { showApiError } from '../errors/utils';
import { dayBookNoteActions } from './actions';
import { DayBookNotesRequest, DayBookNotesResponse, DayBookNoteCreateData, UpdateDayBookNoteRequest } from './types';

const addNotification = (message: string, defaultMessage: string) =>
  put(
    notificationsActions.addNotification({
      type: 'toast',
      title: i18n.t('successMessage', 'Success!').toUpperCase(),
      text: i18n.t(message, defaultMessage)
    })
  );

const isPayloadMerchantSequenceKeyInSelectedList = (state: AppState, merchantSequenceKeys: string[]): boolean => {
  const selectedLocations = getSelectedLocations(state);
  return merchantSequenceKeys.some(merchantSequenceKey => selectedLocations.includes(merchantSequenceKey));
};

const isDayBookInSelectedDateRange = (state: AppState, selectedDate: string): boolean => {
  const allowedDateRange = getDateRange(state);
  const currentDate = moment.utc(selectedDate);
  const startDate = moment.utc(allowedDateRange.startDate.format(INTERNATIONAL_DATE_FORMAT));
  const endDate = moment.utc(allowedDateRange.endDate.format(INTERNATIONAL_DATE_FORMAT)).add(1, 'days').startOf('day');
  return currentDate.isSameOrAfter(startDate) && currentDate.isBefore(endDate);
};

const shouldDaybookListBeUpdated = (
  state: AppState,
  payload: UpdateDayBookNoteRequest | DayBookNoteCreateData
): boolean =>
  isPayloadMerchantSequenceKeyInSelectedList(state, payload.merchantSequenceKeys) &&
  isDayBookInSelectedDateRange(state, payload.noteDate);
export function* dayBookNotesSagas() {
  yield takeLatest(
    getType(dayBookNoteActions.deleteItem.request),
    function* ({ payload }: ReturnType<typeof dayBookNoteActions.deleteItem.request>) {
      try {
        yield call(ApiGateway.deleteDayBookNote, payload);
        googleAnalytics.events.dayBook.delete();
        yield put(dayBookNoteActions.deleteItem.success(payload));
        yield addNotification('noteDeletionSuccess', 'Your note has been deleted.');
      } catch (e) {
        yield put(dayBookNoteActions.deleteItem.failure());
      }
    }
  );

  yield takeLatest(
    getType(dayBookNoteActions.updateItem.request),
    function* ({ payload }: ReturnType<typeof dayBookNoteActions.updateItem.request>) {
      try {
        const state: AppState = yield select();
        yield call(ApiGateway.updateDayBookNote, payload);
        googleAnalytics.events.dayBook.edit();
        if (shouldDaybookListBeUpdated(state, payload)) {
          yield put(dayBookNoteActions.updateItem.success(payload));
        } else {
          yield put(dayBookNoteActions.deleteItem.success({ messageId: payload.messageId }));
        }
        yield addNotification('noteUpdateSuccess', 'Your new changes have been applied.');
      } catch (e) {
        yield put(dayBookNoteActions.updateItem.failure());
      }
    }
  );

  yield takeLatest(
    getType(dayBookNoteActions.createItem.request),
    function* ({ payload }: ReturnType<typeof dayBookNoteActions.createItem.request>) {
      try {
        const state: AppState = yield select();
        const response = yield call(ApiGateway.createDayBookNote, payload);
        googleAnalytics.events.dayBook.add();
        if (shouldDaybookListBeUpdated(state, payload)) {
          yield put(dayBookNoteActions.createItem.success(response));
        }
        yield addNotification('noteCreationSuccess', 'Your new note has been created.');
      } catch (e) {
        yield put(dayBookNoteActions.createItem.failure());
      }
    }
  );

  yield takeLatest(getType(dayBookNoteActions.fetchList.request), function* () {
    const state: AppState = yield select();
    const { selectedLocations } = state.locationSelector;
    try {
      const request: DayBookNotesRequest = {
        ...getStartEndDates(state, {
          formatStartDate: INTERNATIONAL_DATE_FORMAT,
          formatEndDate: INTERNATIONAL_DATE_FORMAT
        }),
        merchantSequenceKeys: selectedLocations
      };
      const response: DayBookNotesResponse = yield call(ApiGateway.getDayBookNotes, request);
      yield put(dayBookNoteActions.fetchList.success(response.dayBookNotes));
    } catch (e) {
      yield put(dayBookNoteActions.fetchList.failure());
    }
  });

  yield takeLatest([getType(locationsActions.select), getType(setDateRange)], function* () {
    yield put(dayBookNoteActions.fetchList.request());
  });

  yield takeLatest(
    [
      dayBookNoteActions.fetchList.failure,
      dayBookNoteActions.createItem.failure,
      dayBookNoteActions.updateItem.failure,
      dayBookNoteActions.deleteItem.failure
    ],
    showApiError
  );
}
