import WebFont from 'webfontloader';

export default () => {
  const url = `https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.9.0/css/all.min.css?v=${new Date().getTime()}`;

  WebFont.load({
    custom: {
      families: ['Font Awesome 5 Free'],
      urls: [url]
    }
  });
};
