import type { ContactMemberType } from 'components/EmailMarketing/createCampaign/types';
import { Moment } from 'moment';

export interface EMCampaign {
  id: number;
  name: string;
  stepsCount: number;
  scheduleType: string;
  startDate: Moment;
  createdBy: string;
  statsCount: number;
  emailCampaign: boolean;
  offerCampaign: boolean;
  inboxCampaign: boolean;
  pushCampaign: boolean;
  status: number;
  formattedDate: string;
}

export interface EMCampaignDetails {
  error?: boolean;
  includeContactsWithoutLocations?: boolean;
  memberTypes?: ContactMemberType[];
  demographic?: {
    merchantSequenceKey: string;
    merchantNumber: string;
    merchantName: string;
    phoneNumber: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zipCode: string;
  }[];
  stats: Partial<{
    sent: number;
    opened: number;
    uniqueClicks: number;
    bounced: number;
    unsubscribed: number;
  }>;
  templateHTML: string;
}

export interface EMCampaignDetailsResponse {
  id: number;
  details: EMCampaignDetails;
}

export interface EMCampaignsDetailsState {
  [key: number]: EMCampaignDetails;
}

export interface EMCampaignsState {
  list: EMCampaign[];
  loading: boolean;
  fetched: boolean;
  details: EMCampaignsDetailsState;
  deletedCampaign?: EMCampaign;
}

export enum CampaignStatus {
  Automation,
  Scheduled,
  Sent
}

export interface EMCampaignsDataResponse {
  campaignId: number;
  demographic: {
    merchantSequenceKey: string;
    merchantNumber: string;
    merchantName: string;
    phoneNumber: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zipCode: string;
  }[];
  includeContactsWithoutLocations: boolean;
  messagingTemplateId: number;
  name: string;
  startDate: string;
  templateId: string;
}
