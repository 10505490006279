export interface ApiGatewayState {
  error: {
    message: string;
    status?: number;
    isSessionExpired?: boolean;
    type: ErrorTypes;
  };
  authorized: boolean;
}

export interface ApiGatewayResponseError {
  userFriendlyError?: string;
  error?: string;
  showOops?: boolean;
  isSessionExpired?: boolean;
  type?: ErrorTypes;
}

export enum ErrorTypes {
  sessionError = 'sessionError',
  unknown = 'unknown'
}
