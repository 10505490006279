import { createAsyncAction, createAction, createCustomAction } from 'typesafe-actions';
import * as types from './types';
import { PaginationOptions } from '../reviews/types';

const fetchPickedCompetitorsActions = createAsyncAction(
  '@merchantCentric/pickedCompetitors/fetch',
  '@merchantCentric/pickedCompetitors/fetch/success',
  '@merchantCentric/pickedCompetitors/fetch/error'
)<void, types.FetchPickedCompetitorsResponse, void>();

const pickCompetitors = createAsyncAction(
  '@merchantCentric/pickCompetitors/pick',
  '@merchantCentric/pickCompetitors/pick/success',
  '@merchantCentric/pickCompetitors/pick/error',
  '@merchantCentric/pickCompetitors/pick/reset'
)<string[], void, void, void>();

const beginPickCompetitor = createAction('@merchantCentric/pickCompetitors/begin');

const setPickedCompetitorsErrors = createCustomAction(
  '@merchantCentric/pickedCompetitors/errors',
  type => (errors: string[]) => ({ type, errors })
);

const resetPickedCompetitorsErrors = createCustomAction(
  '@merchantCentric/pickedCompetitors/errors',
  type => (errors: string[]) => ({ type, errors })
);

const fetchPickedCompetitorsDataActions = createAsyncAction(
  '@merchantCentric/pickedCompetitorsData/fetch',
  '@merchantCentric/pickedCompetitorsData/fetch/success',
  '@merchantCentric/pickedCompetitorsData/fetch/error'
)<void, types.Competitor[], void>();

const fetchCompetitorReviews = createAsyncAction(
  '@merchantCentric/competitorReviews/fetch',
  '@merchantCentric/competitorReviews/fetch/success',
  '@merchantCentric/competitorReviews/fetch/error'
)<string, any, void>();

const competitorReviewsPagination = {
  setPagination: createAction(
    '@merchantCentric/competitorReviews/pagination/setPagination',
    action => (competitorId: string, paginationOptions: PaginationOptions) =>
      action({ competitorId, paginationOptions })
  )
};

const fetchInvalidCompetitors = createAsyncAction(
  '@merchantCentric/invalidCompetitors/fetch',
  '@merchantCentric/invalidCompetitors/success',
  '@merchantCentric/invalidCompetitors/error'
)<void, string[], void>();

const fetchCompetitorsActions = createAsyncAction(
  '@merchantCentric/competitors/fetch',
  '@merchantCentric/competitors/fetch/success',
  '@merchantCentric/competitors/fetch/error'
)<string[], types.CompetitorData[], void>();

const filterCompetitors = {
  addFilter: createAction(
    '@merchantCentric/filterCompetitors/add',
    action => (filter: { [filterName: string]: string | number }) => action(filter)
  ),
  resetFilters: createAction('@merchantCentric/filterCompetitors/resetFilters')
};

const reset = createAction('@merchantCentric/competitors/reset');

export const competitorsActions = {
  fetchPickedCompetitors: fetchPickedCompetitorsActions,
  pickCompetitors,
  fetchPickedCompetitorsData: fetchPickedCompetitorsDataActions,
  fetchInvalidCompetitors,
  fetchCompetitorReviews,
  competitorReviewsPagination,
  fetchCompetitors: fetchCompetitorsActions,
  filterCompetitors,
  beginPickCompetitor,
  setPickedCompetitorsErrors,
  resetPickedCompetitorsErrors,
  reset
};
