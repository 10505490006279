import styled from 'styled-components';
import React from 'react';
import { LocationsList } from 'store/settings';

const Container = styled.span`
  span {
    &:after {
      content: ',';
      margin-right: 5px;
    }
  }
  &:last-child {
    span {
      &:after {
        display: none;
      }
    }
  }
`;

interface Props {
  locations: LocationsList;
  selectedLocations: string[];
  placeholder: string;
}

export const SimpleModeSearchSting = ({ locations, selectedLocations, placeholder }: Props) =>
  selectedLocations.length ? (
    <>
      {locations
        .filter(el => selectedLocations.includes(el.merchant_sequence_key))
        .map((el, i) => (
          <Container key={el.merchant_number}>
            {el.merchant_name}
            <span>{` (${el.merchant_number})`}</span>
          </Container>
        ))}
    </>
  ) : (
    <>{placeholder}</>
  );
