import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { Image as MailBoxImageData } from '../../../store/emailMarketing/emailTemplateEditor';
import { MailBoxProps } from '../types';
import { getColor } from '../../../layout/theme';

const Placeholder = styled.div`
  padding: 14px;
  margin: 28px;
  border: 2px dashed ${props => getColor(props, 'grey')};
`;
export const Image = (props: MailBoxProps<MailBoxImageData>): ReactElement => {
  const { boxData, styles = {}, translations = {} } = props;
  const { src } = boxData;

  const { width } = styles;
  const ownStyles = {
    margin: '10px 0',
    maxWidth: width
  };

  return (
    <div style={styles}>
      {src && <img style={ownStyles} src={src} alt="" />}
      {!src && <Placeholder>{translations.imageEditorPlaceholder}</Placeholder>}
    </div>
  );
};
