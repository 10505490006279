export const translationsPageDefaultTitle: { [key: string]: string } = {
  analyticsTitle: 'Payment Analytics',
  emailmarketingTitle: 'Email Marketing',
  customerIntelligenceTitle: 'Customer Demographics',
  benchmarkTitle: 'Competitive Benchmark',
  portalReputationTitle: 'REPUTATION',
  portalDemographicTitle: 'Demographics',
  loyaltyTitle: 'Loyalty',
  eCommerceTitle: 'eCommerce',
  assistantTitle: 'Assistant',
  supportSearchTitle: 'Admin',
  CIMenuProfile: 'PROFILE',
  CIMenuCompetition: 'COMPETITION',
  CIMenuReviews: 'REVIEWS',
  CIMenuCustomerReach: 'REACH',
  BenchmarkMenuSpend: 'Spend',
  BenchmarkMenuBehaviour: 'Behavior',
  BenchmarkMenuReach: 'Reach',
  CIMenuOverview: 'OVERVIEW',
  CIMenuCompare: 'Compare',
  reputationTitle: 'Reputation Management',
  labelSettigns: 'Settings',
  settingsMyAccountSectionTitle: 'My Account',
  mainMenu_Automation: 'AUTOMATION',
  mainMenu_Automations: 'AUTOMATIONS',
  LoyaltyMenuAutomation: 'Automation',
  mainMenu_CRMAutomation: 'Automations',
  mainMenu_Campaigns: 'CAMPAIGNS',
  AnalyticsDashboardTab: 'Dashboard',
  AnalyticsCompareTab: 'Compare',
  LoyaltyMenuCore: 'Core program',
  LoyaltyMenuOverview: 'Overview',
  settingsNotificationsSectionTitle: 'Notifications',
  reviews: 'Reviews',
  SupportSearchTitle: 'User Search',
  mainMenu_Profile: 'PROFILE',
  CIMenuAreaMetrics: 'AREA METRICS',
  mainMenu_Contacts: 'CONTACTS',
  roiTitle: 'Restaurant Analytics',
  locationLineupTitle: 'Location Lineup',
  googleDriveTitle: 'Drive',
  googleGmailTitle: 'Gmail',
  adsProgramTitle: 'Google Ads',
  gmbProgramTitle: 'Google Business Profile',
  advancedAnalyticsTitle: 'Payment Analytics Plus',
  workspaceProgramTitle: 'Workspace Admin',
  AdvancedAnalyticsOverviewTab: 'Overview',
  AdvancedAnalyticsCompareTab: 'Compare',
  AdvancedAnalyticsAuthDeclineseTab: 'Authorizations and Declines',
  AdvancedAnalyticsSalesTab: 'Sales',
  AdvancedAnalyticsRefundsTab: 'Refunds and Chargebacks',
  AdvancedAnalyticsFeesTab: 'Fees',
  AdvancedAnalyticsEvaluateTab: 'Evaluate',
  AnalyticsEvaluateTab: 'Evaluate',
  pageNameCreateCampaign: '',
  RFM: ''
};
