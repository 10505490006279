import React from 'react';
import styled from 'styled-components';
import { getColor } from '../../layout/theme';
import { textRegular } from '../../layout/mixins';
import { deviceMedia } from '../../appConstants';

export const OptionsListContainer = styled.div<{ FixedHeight?: boolean; MobileHeight?: number }>`
  ${props => (props.FixedHeight ? 'height' : 'max-height')}: 318px;
  overflow: auto;
  @media ${deviceMedia.mobile} {
    height: ${props => `${props.MobileHeight}px`};
  }
`;

const OptionItemContainer = styled.div`
  ${textRegular};
  padding: 10px 11px;
  border-bottom: 1px solid ${props => getColor(props, 'blueGrey')};
`;

interface PropTypes<T> {
  items: T[];
  renderFn: (item: T) => JSX.Element;
  notFoundText: string;
}

export function OptionsList<T>({ items, renderFn, notFoundText }: PropTypes<T>) {
  return (
    <>
      {Array.isArray(items) && items.length ? (
        items.map((item, i) => <OptionItemContainer key={i}>{renderFn(item)}</OptionItemContainer>)
      ) : (
        <OptionItemContainer data-test-id="no-results-found">{notFoundText}</OptionItemContainer>
      )}
    </>
  );
}
