import { TicketTiersResponse, TransactionsResponse } from './types';
import { AppState } from '../rootReducer';
import { getUserLocations } from '../settings';

export const extendCompareLocationsData = (
  transactionsResponse: TransactionsResponse,
  state: AppState
): TransactionsResponse => {
  if (transactionsResponse.compareLocations) {
    const locations = getUserLocations(state);
    if (transactionsResponse.compareLocations.locations.length > 1) {
      transactionsResponse.compareLocations.locations.forEach(data => {
        Object.assign(data, { locationInfo: locations.find(location => location.merchant_number === data.mid) });
      });
    } else {
      transactionsResponse.compareLocations.locations = [];
    }
  }
  return transactionsResponse;
};

export function sumAllTicketTiers(ticketTiers: TicketTiersResponse) {
  // eslint-disable-next-line no-param-reassign
  ticketTiers.ALL = Object.values(ticketTiers).reduce((total, data) => {
    Object.entries(data).forEach(([tier, tierData]) => {
      if (!(tier in total)) {
        total[tier] = { DEBIT: 0, CREDIT: 0 };
      }
      total[tier].CREDIT += tierData.CREDIT;
      total[tier].DEBIT += tierData.DEBIT;
    });
    return total;
  }, {});
  return ticketTiers;
}
