import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'resize-observer-polyfill/dist/ResizeObserver.global';
import 'element-closest-polyfill';

import 'moment/locale/en-gb';
import 'moment/locale/fr-ca';
import 'moment/locale/es-mx';
import 'moment/locale/de-at';
import 'moment/locale/cs';
import 'moment/locale/sk';
import 'moment/locale/ro';
import 'moment/locale/de';
import 'moment/locale/hu';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { Store } from 'redux';
import { ThemeProvider } from 'styled-components';
import { history, createRootStore } from 'store/rootStore';
import { RootComponent } from 'RootComponent';
import { ErrorPage } from 'components/error-pages/ErrorPageWrapper';
import { AppState } from 'store/rootReducer';
import { getTheme, oneUiThemeToConfigurableColors } from 'layout/theme';
import { ENVIRONMENTS, ERROR_PAGES } from 'appConstants';
import 'normalize.css/normalize.css';
import { ApplicationStore } from 'store/utils';
import { AuthDashboard } from 'components/AuthDashboard';
import { ApiGateway, setupAuthorizedResponseInterceptor } from 'services/apiGateway';
import { localizationService } from 'services/localization';
import { AceGlobalStyles } from 'layout/globalStyles';
import { unregister } from 'serviceWorker';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { I18nextProvider } from 'react-i18next';
import loadFontAwesome from './services/loadFontAwesome';
import i18n from './i18n';
import { DEFAULT_NS } from './appConstants/translationNamespaces';

unregister();
export let applicationStore: ApplicationStore;

const createApp = (store: Store<AppState>) => {
  const {
    branding: { theme }
  } = store.getState();

  setupAuthorizedResponseInterceptor(store);

  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <ThemeProvider theme={getTheme(oneUiThemeToConfigurableColors(theme))}>
          <>
            <I18nextProvider i18n={i18n}>
              <AceGlobalStyles />
              <Switch>
                <Route exact path="/dashboard" component={AuthDashboard} />
                <Route path={ERROR_PAGES} component={ErrorPage} />

                <Route path="/" component={RootComponent} />
              </Switch>
            </I18nextProvider>
          </>
        </ThemeProvider>
      </ConnectedRouter>
    </Provider>
  );
};

function onInitError(error: Error) {
  console.error(error);
  const rootComponent = document.getElementById('root');
  if (rootComponent) {
    rootComponent.innerText = 'Error occurred during loading initial data.';
  }
}

function insertFavIcon(favIcon: string): void {
  const el: HTMLLinkElement | null = document.querySelector('head > link[rel="shortcut icon"]');
  if (el) {
    el.href = favIcon;
  }
}
if (process.env.NODE_ENV !== ENVIRONMENTS.TEST) {
  i18n
    .changeLanguage(localizationService.getDetectedLanguage())
    .then(() => Promise.all([ApiGateway.fetchBrandingData(), i18n.loadNamespaces(DEFAULT_NS)]))
    .then(([branding]) => {
      insertFavIcon(branding.favicon);
      applicationStore = createRootStore(branding);
      const App = createApp(applicationStore);
      document.documentElement.lang = localizationService.getLanguageFromLocale();
      ReactDOM.render(App, document.getElementById('root'));
    }, onInitError);
}
loadFontAwesome();
