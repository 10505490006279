/* eslint-disable */
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import createRootReducer from './rootReducer';
import { BrandingState } from './branding';
import { notificationsSagas } from './notifications';
import { createSagaInjector } from './utils';
import { settingsSagas } from './settings';
import { apiGatewaySagas } from './apiGateway';
import { DashboardSagas } from './authDashboard';
import { LocationSelectorSagas } from './locations';
import { PageViewSagas } from './pageViewLogs/sagas';
import { menuSagas } from './navigation';
import { PdfSagas } from './pdf';
import { ENVIRONMENTS } from 'appConstants';
import { PdfPuppeteerSaga } from './pdfPuppeteer/saga';

const sagaMiddleware = createSagaMiddleware();
export const history = createBrowserHistory();

export const createRootStore = (branding: BrandingState) => {
  //prettier-ignore
  const composeEnhancers = (process.env.NODE_ENV !== ENVIRONMENTS.PRODUCTION && '__REDUX_DEVTOOLS_EXTENSION_COMPOSE__' in window)
    // @ts-ignore: the property is provided by chrome Redux Dev Tools extension
    // eslint-disable-next-line no-underscore-dangle
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;

  const middlewares = [sagaMiddleware, routerMiddleware(history)];

  if (process.env.NODE_ENV !== ENVIRONMENTS.PRODUCTION) {
    middlewares.push(createLogger({ collapsed: true, duration: true }));
  }

  const store = createSagaInjector(
    createStore(createRootReducer(history, branding), composeEnhancers(applyMiddleware(...middlewares))),
    sagaMiddleware
  );

  sagaMiddleware.run(DashboardSagas, store);
  sagaMiddleware.run(apiGatewaySagas, store);
  sagaMiddleware.run(settingsSagas);
  sagaMiddleware.run(PdfSagas);
  sagaMiddleware.run(PdfPuppeteerSaga);
  sagaMiddleware.run(notificationsSagas);
  sagaMiddleware.run(menuSagas);
  sagaMiddleware.run(LocationSelectorSagas);
  sagaMiddleware.run(PageViewSagas);
  return store;
};
