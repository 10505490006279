import { takeLatest, all, call, put } from 'redux-saga/effects';

import { ApiGateway } from 'services/apiGateway';
import { authAndDeclinesActions } from './actions';

export function* fetchAuthRateStatSaga({
  payload
}: ReturnType<typeof authAndDeclinesActions.fetchAuthRateStats.request>) {
  try {
    if (payload.locations.length) {
      const data = yield call(ApiGateway.payments.authAndDeclines.fetchAuthRateStats, payload);
      yield put(authAndDeclinesActions.fetchAuthRateStats.success(data));
    } else {
      yield put(authAndDeclinesActions.fetchAuthRateStats.success({}));
    }
  } catch (e) {
    yield put(authAndDeclinesActions.fetchAuthRateStats.failure(e));
  }
}

export function* fetchAuthAndDeclinesSaga({ payload }: ReturnType<typeof authAndDeclinesActions.fetchSummary.request>) {
  try {
    if (payload.locations.length) {
      const data = yield call(ApiGateway.payments.authAndDeclines.fetchSummary, payload);
      yield put(authAndDeclinesActions.fetchSummary.success(data));
    } else {
      yield put(authAndDeclinesActions.fetchSummary.success({}));
    }
  } catch (e) {
    yield put(authAndDeclinesActions.fetchSummary.failure(e));
  }
}

export function* fetchDeclineCodesSaga({
  payload
}: ReturnType<typeof authAndDeclinesActions.fetchDeclineCodes.request>) {
  try {
    if (payload.locations.length) {
      const data = yield call(ApiGateway.payments.authAndDeclines.fetchDeclineCodes, payload);
      yield put(authAndDeclinesActions.fetchDeclineCodes.success(data));
    } else {
      yield put(authAndDeclinesActions.fetchDeclineCodes.success([]));
    }
  } catch (e) {
    yield put(authAndDeclinesActions.fetchDeclineCodes.failure(e));
  }
}

export function* authAndDeclinesSagas() {
  yield all([takeLatest(authAndDeclinesActions.fetchSummary.request, fetchAuthAndDeclinesSaga)]);
  yield all([takeLatest(authAndDeclinesActions.fetchDeclineCodes.request, fetchDeclineCodesSaga)]);
  yield all([takeLatest(authAndDeclinesActions.fetchAuthRateStats.request, fetchAuthRateStatSaga)]);
}
