import { createAsyncAction, createAction } from 'typesafe-actions';
import { TransactionsTrendPeriod } from './types';

const fetchTransactionsTrend = createAsyncAction(
  '@merchantCentric/transactionsTrend/fetch',
  '@merchantCentric/transactionsTrend/success',
  '@merchantCentric/transactionsTrend/error'
)<void, TransactionsTrendPeriod[], Error>();

const resetTransactionsTrend = createAction('@merchantCentric/transactionsTrend/reset', action => () => action());

export const transactionsTrendActions = {
  fetchTransactionsTrend,
  resetTransactionsTrend
};
