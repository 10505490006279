import React, { ReactElement } from 'react';
import { MailBoxProps } from '../types';

export const Spacer = (props: MailBoxProps<{}>): ReactElement => {
  const { className, styles } = props;

  return (
    <div style={styles} className={className}>
      <div>
        <br />
        <br />
      </div>
    </div>
  );
};
