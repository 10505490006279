import React, { useEffect } from 'react';
import styled from 'styled-components';
import { DEFAULT_NS } from 'appConstants/translationNamespaces';
import { useTranslation } from 'react-i18next';
import { HeaderContainer } from '../../layout/header/Header';
import { Logo } from '../../layout/header/Logo';
import { ToolbarSection } from '../../layout/toolbar';
import { getColor } from '../../layout/theme';
import { ErrorPageProps } from './ErrorPageWrapper';

const TextContainer = styled.div`
  padding: 15px;
  text-align: center;
  font-size: 20px;
  a {
    color: ${props => getColor(props, 'color1')};
  }
  p {
    margin: 0.5em 0;
    &:first-child {
      font-size: 32px;
      font-weight: bold;
    }
  }
`;

export const SessionExpiration = ({
  errorUrl: { portalUrl, sessionExpirationRedirectURL, preventSessionExpirationAutoRedirect },
  isSessionExpired
}: ErrorPageProps) => {
  const { t } = useTranslation(DEFAULT_NS);

  useEffect(() => {
    if (!preventSessionExpirationAutoRedirect && sessionExpirationRedirectURL) {
      window.location.href = sessionExpirationRedirectURL;
    }
  }, [preventSessionExpirationAutoRedirect, sessionExpirationRedirectURL]);

  const link =
    preventSessionExpirationAutoRedirect && sessionExpirationRedirectURL
      ? {
          herf: sessionExpirationRedirectURL,
          title: sessionExpirationRedirectURL
        }
      : { herf: portalUrl, title: portalUrl };

  const sessionExpiredMsg = (
    <>
      <p>{t('sessionExpiredHeartlandBegin', 'Oops, it appears your session has expired.')}</p>
      <p>
        {' '}
        {t('sessionExpiredHeartlandMiddle', 'Please click')} <a href={link.herf}>{link.title}</a>{' '}
        {t('sessionExpiredHeartlandEnd', 'to log back in!')}
      </p>
    </>
  );

  const defaultMsg = (
    <>
      <p>{t('sessionExpiredHeartlandBegin', 'Oops, it appears your session has expired.')}</p>
      <p>{t('sessionNotFoundMsg', 'Please relaunch the application.')}</p>
    </>
  );

  return (
    <div>
      <HeaderContainer>
        <Logo />
      </HeaderContainer>
      <ToolbarSection />
      <TextContainer>{isSessionExpired ? sessionExpiredMsg : defaultMsg}</TextContainer>
    </div>
  );
};
