import { createAction, createAsyncAction } from 'typesafe-actions';
import * as types from '../campaignEditor/types';

const fetchAutomationTemplates = createAsyncAction(
  '@em/automation/templates/fetch',
  '@em/automation/templates/success',
  '@em/automation/templates/error'
)<void, types.ServerTemplate[], void>();

const saveAutomationTemplate = createAsyncAction(
  '@em/automation/template/save/request',
  '@em/automation/template/save/success',
  '@em/automation/template/save/error'
)<void, void, void>();

const getAutomationCoreStatus = createAsyncAction(
  '@em/automation/coreStatus/request',
  '@em/automation/coreStatus/success',
  '@em/automation/coreStatus/error'
)<void, boolean, void>();

const getAutomationCampaignsStatus = createAsyncAction(
  '@em/automation/campaignsStatus/request',
  '@em/automation/campaignsStatus/success',
  '@em/automation/campaignsStatus/error'
)<void, void, void>();

const selectAutomationTemplate = createAsyncAction(
  '@em/automation/selectTemplate/request',
  '@em/automation/selectTemplate/success',
  '@em/automation/selectTemplate/error'
)<string, void, void>();

const createCampaign = createAsyncAction(
  '@em/automation/createCampaign/request',
  '@em/automation/createCampaign/success',
  '@em/automation/createCampaign/error'
)<void, void, void>();

const setWelcomeModalState = createAction(
  '@em/automation/welcomeModal/set',
  action => (showWelcomeModal: boolean) => action({ showWelcomeModal })
);

const setAssignSuccessModalState = createAction(
  '@em/automation/assignSuccessModal/set',
  action => (showAssignSuccessModal: boolean) => action({ showAssignSuccessModal })
);

export const automationActions = {
  fetchAutomationTemplates,
  saveAutomationTemplate,
  selectAutomationTemplate,
  getAutomationCoreStatus,
  createCampaign,
  setWelcomeModalState,
  setAssignSuccessModalState,
  getAutomationCampaignsStatus
};
