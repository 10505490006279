/* eslint-disable */
/* Return SK Static Automations Templates */

const AlmostThereEmailData = {
  styles: {
    width: '600px',
    color: '#949599',
    backgroundColor: '#fff',
    couponBorderColor: '#fff',
    buttonsDividersColor: '#fff',
    sicailLinksColor: '#fff',
  },
  components: [
    {
      type: 'spacer',
    },
    {
      type: 'image',
      image: 'em-email-images/SK/GPsk_550x300_19.+Almost+There+Template_SK.jpg',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<h1 style="font-style: italic; color: #949599;">Už to skoro máte!</h1>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<p style="color: #949599; font-weight: bold;">Gratulujeme! Od získania ďalšej odmeny vás delí už len jedna návšteva/položka. Dúfame, že sa čoskoro uvidíme.</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const AnniversaryEmailData = {
  styles: {
    width: '600px',
    color: '#949599',
    backgroundColor: '#fff',
    couponBorderColor: '#fff',
    buttonsDividersColor: '#fff',
    sicailLinksColor: '#fff',
  },
  components: [
    {
      type: 'spacer',
    },
    {
      type: 'image',
      image: 'em-email-images/SK/GPsk_550x300_21.+Happy+Anniversary+Template_SK.jpg',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<h1 style="font-style: italic; color: #949599;">Ďakujeme za úžasný rok!</h1>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<p style="color: #949599; font-weight: bold;">Čas letí, keď sa bavíte. Sme šťastní, že ste súčasťou našej rodiny. Tešíme sa na skoré videnie!</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const RewardEmailData = {
  styles: {
    width: '600px',
    color: '#949599',
    backgroundColor: '#fff',
    couponBorderColor: '#fff',
    buttonsDividersColor: '#fff',
    sicailLinksColor: '#fff',
  },
  components: [
    {
      type: 'spacer',
    },
    {
      type: 'image',
      image: 'em-email-images/SK/GPsk_550x300_22.+Reward+Template_SK.jpg',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<h1 style="font-style: italic; color: #949599;">Získali ste odmenu!</h1>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<p style="color: #949599; font-weight: bold;">Gratulujeme! Zaslúžite si to. Tešíme sa na skoré videnie.</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const WelcomeEmailData = {
  styles: {
    width: '600px',
    color: '#949599',
    backgroundColor: '#fff',
    couponBorderColor: '#fff',
    buttonsDividersColor: '#fff',
    sicailLinksColor: '#fff',
  },
  components: [
    {
      type: 'spacer',
    },
    {
      type: 'image',
      image: 'em-email-images/SK/GPsk_550x300_18.+Welcome+Template_SK.jpg',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<h1 style="font-style: italic; color: #949599;">Vitajte!</h1>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<p style="color: #949599; font-weight: bold;">Teraz budete dostávať naše najnovšie ponuky priamo do vašej emailovej schránky. Tešíme sa na skoré videnie!</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const WeMissYouEmailData = {
  styles: {
    width: '600px',
    color: '#949599',
    backgroundColor: '#fff',
    couponBorderColor: '#fff',
    buttonsDividersColor: '#fff',
    sicailLinksColor: '#fff',
  },
  components: [
    {
      type: 'spacer',
    },
    {
      type: 'image',
      image: 'em-email-images/SK/GPsk_550x300_20.+We+Miss+You+Template_SK.jpg',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<h1 style="font-style: italic; color: #949599;">Ako sa vám darí?</h1>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<p style="color: #949599; font-weight: bold;">Zdá sa to byť večnosť, čo sme vás naposledy videli. Chceli sme vás len pozdraviť a dúfame, že nás čoskoro opäť navštívite.</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

export const skStaticTemplates = [
  {
    id: 'q1hng8dqy4g34lfqbpta',
    promoId: 'almost_reward',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticAutomationTemplateAlmostThereTitle',
    status: 'origin',
    favorites: 'false',
    components: AlmostThereEmailData.components,
    styles: AlmostThereEmailData.styles,
    thumb: 'almost-there-email-preview-sk.jpg',
  },
  {
    id: '8t7a1utn2d4an6ul4f0j',
    promoId: 'anniversary',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticAutomationTemplateAnniversaryTitle',
    status: 'origin',
    favorites: 'false',
    components: AnniversaryEmailData.components,
    styles: AnniversaryEmailData.styles,
    thumb: 'anniversary-email-preview-sk.jpg',
  },
  {
    id: 'e7cvokemkyim6on60dqa',
    promoId: 'loyalty_reward',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticAutomationTemplateRewardTitle',
    status: 'origin',
    favorites: 'false',
    components: RewardEmailData.components,
    styles: RewardEmailData.styles,
    thumb: 'reward-email-preview-sk.jpg',
  },
  {
    id: 'mliyare2qdnxmaa8ng47',
    promoId: 'miss_you',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticAutomationTemplateWeMissYouTitle',
    status: 'origin',
    favorites: 'false',
    components: WeMissYouEmailData.components,
    styles: WeMissYouEmailData.styles,
    thumb: 'we-miss-you-email-preview-sk.jpg',
  },
  {
    id: '7i41zkyvlcbr95eigc4a',
    promoId: 'welcome',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticAutomationTemplateWelcomeTitle',
    status: 'origin',
    favorites: 'false',
    components: WelcomeEmailData.components,
    styles: WelcomeEmailData.styles,
    thumb: 'welcome-email-preview-sk.jpg',
  },
];

