import styled from 'styled-components';
import React, { ReactNode } from 'react';
import { getColor } from '../../layout/theme';
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';
import { resetButtonStyles, textMetaInfo } from '../../layout/mixins';

const RecentSearchesBarContainer = styled.div`
  padding: 5px 11px;
  ${textMetaInfo};
`;
const RecentSearchesLabel = styled.div`
  color: ${props => getColor(props, 'darkGrey')};
  margin-bottom: 5px;
  font-size: 10px;
`;

const RecentSearchIcon = styled(SearchIcon)`
  display: inline-block;
  width: 11px;
  height: 12px;
  margin-right: 4px;
  margin-top: 1px;
`;

const RecentSearchItemWrapper = styled.div`
  display: inline-flex;
  align-items: flex-start;
`;

const RecentSearchText = styled.div<{ isActive?: boolean }>`
  color: ${props => getColor(props, 'darkGrey')};
  max-width: 70px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
  border-bottom: 1px solid ${props => (props.isActive ? '' : 'transparent')};
`;

const RecentSearchItem = ({ isActive, children }: { children: ReactNode; isActive?: boolean }) => (
  <RecentSearchItemWrapper>
    <RecentSearchIcon data-test-id="recent-search-icon" />
    <RecentSearchText isActive={isActive} data-test-id="recent-search-text">
      {children}
    </RecentSearchText>
  </RecentSearchItemWrapper>
);

const RecentSearchButton = styled.button`
  ${resetButtonStyles};
  margin-right: 12px;
  align-items: center;
  &:last-child {
    margin-right: 0;
  }
`;
RecentSearchButton.displayName = 'RecentSearchButton';

interface PropTypes {
  items: string[];
  currentSearchString: string;
  onItemClick: (val: string) => void;
  label: string;
}

export const RecentSearches = ({ items, currentSearchString, onItemClick, label }: PropTypes) => (
  <RecentSearchesBarContainer data-test-id="recent-search-section">
    <RecentSearchesLabel data-test-id="recent-search-section-title">{label}</RecentSearchesLabel>
    <div>
      {items.map((item, i) => (
        <RecentSearchButton
          onClick={() => {
            onItemClick(item);
          }}
          key={i}
          data-test-id={`recent-search-item-${i}`}
        >
          <RecentSearchItem isActive={currentSearchString === item}>{item}</RecentSearchItem>
        </RecentSearchButton>
      ))}
    </div>
  </RecentSearchesBarContainer>
);
