import { createAction } from 'typesafe-actions';

const update = createAction('@em/backgroundJob/update', action => (status: any) => action(status));

const reset = createAction('@em/backgroundJob/reset', action => () => action());

export const backgroundJobActions = {
  update,
  reset
};
