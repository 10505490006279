/* eslint-disable */
/* sk_SK Static Templates Data */

const AutomotiveServiceShopEmailData = {
  styles: {
    width: '600px',
    color: '#898989',
    backgroundColor: '#fff',
    couponBorderColor: '#00aeef',
    buttonsDividersColor: '#898989',
    sicailLinksColor: '#00aeef',
  },
  components: [
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_29.+Automotive+Service+Shops+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center">Uskladnenie sezónnych pneumatík zdarma!</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Servis v ten istý deň pri väčšine opráv Jasný odhad bez skrytých nákladov</p>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Zavolajte ešte dnes a dohodnite si termín!</p>',
    },
    {
      type: 'coupon',
      text: '<h2 class="ql-align-center">Ak si u nás zakúpite ručné umytie auta a výmenu pneumatík, uskladnenie sezónnych pneumatík máte zdarma!</h2><p class="ql-align-center" style="font-size: 10px;">Nie je možné kombinovať s inými zľavami alebo ponukami.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const BakeryEmailData = {
  styles: {
    width: '600px',
    color: '#898989',
    backgroundColor: '#fff',
    couponBorderColor: '#00aeef',
    buttonsDividersColor: '#898989',
    sicailLinksColor: '#00aeef',
  },
  components: [
    {
      type: 'image',
      image: 'em-logos/bakery-email-logo_1498080292703.png',
    },
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_1.+Bakery+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center">Zľava 10% na váš ďalší nákup</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Chceli sme vám poslať malý darček ako poďakovanie. Neváhajte použiť tento kupón pri vašej ďalšej návšteve pekárne Bob\'s Bakery, kde sa všetko pečie priamo na mieste, len z tých najčerstvejších surovín. Príďte si k nám vybrať zo širokého množstva druhov našich čerstvo upečených zákuskov a koláčov. Alebo ešte lepšie, sadnite si a vychutnajte si ich priamo u nás. Tešíme sa na vás!</p>',
    },
    {
      type: 'coupon',
      text: '<h2 class="ql-align-center">Zľava 10 % na váš ďalší nákup.</h2><p class="ql-align-center" style="font-size: 10px;">Nie je možné kombinovať s inými zľavami alebo ponukami.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const BarberShopEmailData = {
  styles: {
    width: '600px',
    color: '#898989',
    backgroundColor: '#fff',
    couponBorderColor: '#00aeef',
    buttonsDividersColor: '#898989',
    sicailLinksColor: '#00aeef',
  },
  components: [
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_25.+Barber+Shop+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center">Balíček kompletnej starostlivosti teraz s 10% zľavou</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Zaborte sa do pohodlného kresla a zverte svoje vlasy a fúzy do starostlivosti profesionálneho holiča. Okrem perfektného strihu si môžete v čisto pánskom prostredí vychutnať kávu prémiovej kvality. Alebo máte chuť na niečo ostrejšie?</p>',
    },
    {
      type: 'coupon',
      text: '<h2 class="ql-align-center">Balíček kompletnej starostlivosti, ktorá zahŕňa aj úpravu fúzov, teraz s 10% zľavou a k tomu rum podľa vášho výberu z našich prémiových rumov zadarmo.</h2><p class="ql-align-center" style="font-size: 10px;">Nie je možné kombinovať s inými zľavami alebo ponukami.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const BarPubEmailData = {
  styles: {
    width: '600px',
    color: '#fff',
    backgroundColor: '#898989',
    couponBorderColor: '#fff',
    buttonsDividersColor: '#fff',
    sicailLinksColor: '#fff',
  },
  components: [
    {
      type: 'image',
      image: 'em-logos/bar-pub-email-logo_1498080596725.png',
    },
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_2.+Bar+Pub+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center">HAPPY HOUR KAŽDÝ DEŇ</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center" style="font-weight: bold;">Denne od 16:00 do 19:00</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Nápojový lístok aktualizujeme každý týždeň.</p>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Príďte sa pozrieť, čo dnes ponúkame!</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'coupon',
      text: '<h4 class="ql-align-center">Položka "Happy Hour" so zľavou 1 €.</h4><p class="ql-align-center">Platí na 1 položku.</p><br><p class="ql-align-center" style="font-size: 10px;">Obmedzenie na 1 zákazníka. Nie je možné kombinovať s  inými zľavami alebo ponukami.</p><br>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const BeautyShopEmailData = {
  styles: {
    width: '600px',
    color: '#898989',
    backgroundColor: '#fff',
    couponBorderColor: '#00aeef',
    buttonsDividersColor: '#898989',
    sicailLinksColor: '#00aeef',
  },
  components: [
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_26.+Beauty+Shop+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center">Poponáhľajte sa a získajte 10% zľavu na exkluzívnu prírodnú kozmetiku</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Príďte si k nám oddýchnuť a zažite zmenu podľa vlastných predstáv. My sa o vás postaráme od hlavy až po päty.</p>',
    },
    {
      type: 'coupon',
      text: '<h2 class="ql-align-center">Po absolvovaní kozmetického ošetrenia v našom salóne získate 10% zľavu na exkluzívnu prírodnú kozmetiku.</h2><p class="ql-align-center" style="font-size: 10px;">Nie je možné kombinovať s inými zľavami alebo ponukami.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const BurgerEmailData = {
  styles: {
    width: '600px',
    backgroundColor: '#e11b22',
    color: '#fff',
    headingsColor: '#fff',
    buttonsDividersColor: '#fff',
  },
  components: [
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_3.+Burger+Template_CZ.jpg',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">KÚP JEDEN, DRUHÝ DOSTANEŠ ZDARMA.</p>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Naša sezónne, 100% hovädzie karbonátky s údenou slaninkou a čerstvou zeleninou sú tu! Pridajte k tomu vychladené remeselné pivo a začnite týždeň s úsmevom!</p>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Ukážte našej obsluhe priamo tento kupón, alebo si ho vytlačte.</p>',
    },
    {
      type: 'coupon',
      text: '<h2 class="ql-align-center">KÚPTE JEDNO MENU, ZÍSKAJTE DRUHÉ ZDARMA</h2><p class="ql-align-center">(za rovnakú alebo nižšiu cenu)</p><p class="ql-align-center">Obmedzenie na 1 zákazníka. Nie je možné kombinovať s inými zľavami alebo ponukami.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const ChineseEmailData = {
  styles: {
    width: '600px',
    color: '#fff',
    backgroundColor: '#231f20',
    couponBorderColor: '#fff',
    buttonsDividersColor: '#fff',
    sicailLinksColor: '#fff',
  },
  components: [
    {
      type: 'image',
      image: 'em-logos/chinese-email-logo_1498080685909.png',
    },
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_4.+Chinese+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center" style="font-style:italic;">Vaječná rolka zdarma!</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">alebo vymeňte za jarné rolky (2)</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Pozrite si aj naše špeciálne drinky a aktualizované menu.</p>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Zavolajte vopred a rezervujte si stôl ešte dnes!</p>',
    },
    {
      type: 'coupon',
      text: '<h4 class="ql-align-center">Vaječná rolka zdarma!</h4><p class="ql-align-center">alebo jarné rolky</p><br><p class="ql-align-center" style="font-size: 10px;">Obmedzenie na 1 zákazníka. Nie je možné kombinovať s inými zľavami alebo ponukami.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const ClothingShopEmailData = {
  styles: {
    width: '600px',
    color: '#898989',
    backgroundColor: '#fff',
    couponBorderColor: '#00aeef',
    buttonsDividersColor: '#898989',
    sicailLinksColor: '#00aeef',
  },
  components: [
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_27.+Clothing+Store+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center">Objavte svoj nový štýl</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Príďte k nám a nechajte sa inšpirovať. Tento týždeň máme pre vás pripravenú akciu 1 + 1 zdarma.</p>',
    },
    {
      type: 'coupon',
      text: '<h2 class="ql-align-center">Nie je možné kombinovať s inými zľavami alebo ponukami.</h2>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const CoffeeEmailData = {
  styles: {
    width: '600px',
    backgroundColor: '#e6e7e8',
    color: '#000',
    headingsColor: '#000',
    buttonsDividersColor: '#000',
  },
  components: [
    {
      type: 'image',
      image: 'em-logos/top_1497255967016.png',
    },
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_5.+Coffee+Template.jpg',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<h3 class="ql-align-center">ZOBUĎTE SA S NAŠOU ČERSTVO PRAŽENOU KÁVOU!</h3>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Nie je lepší spôsob, ako začať deň s našou čerstvo praženou kávou. Plná a bohatá chuť vo vás zaručene vyčarí úsmev. Príďte nás navštíviť ešte dnes, máme ochutnávku zdarma!</p>',
    },
    {
      type: 'coupon',
      text: '<h4 class="ql-align-center">ČERSTVÉ PEČIVO DENNE</h4><h3 class="ql-align-center">OBEDOVÉ AKCIE PONDELOK – PIATOK</h3><p class="ql-align-center">Ponuka v jednotlivých prevádzkach sa môže líšiť.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },

  ],
};

const DessertEmailData = {
  styles: {
    width: '600px',
    color: '#464646',
    backgroundColor: '#dfd',
    couponBorderColor: '#ff7d52',
    buttonsDividersColor: '#ff7d52',
    sicailLinksColor: '#464646',
  },
  components: [
    {
      type: 'image',
      image: 'em-logos/dessert-email-logo_1498080768821.png',
    },
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_6.+Dessert+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center" style="color:#ff7d52; font-style:italic;">NOVÁ PONUKA JE TU!</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Prišla jar a s ňou aj naše nové menu!</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Zastavte sa kedykoľvek tento týždeň a získajte polovičnú zľavu na akúkoľvek novú položku menu.</span></p>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Nájdete u nás pekné ružové makrónky, smotanové mrkvové koláčiky, jahodové tyčinky a ďalšie.</p>',
    },
    {
      type: 'coupon',
      text: '<h4 class="ql-align-center">POLOVIČNÁ ZĽAVA NA NOVÉ POLOŽKY V MENU!</h4><p class="ql-align-center">(Iba tento týždeň)</p><br><p class="ql-align-center" style="font-size: 10px;">Nie je možné kombinovať s inými zľavami alebo ponukami.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const DinerEmailData = {
  styles: {
    width: '600px',
    color: '#f00',
    backgroundColor: '#fff',
    couponBorderColor: '#f00',
    buttonsDividersColor: '#f00',
    sicailLinksColor: '#f00',
  },
  components: [
    {
      type: 'image',
      image: 'em-logos/diner-email-logo_1498081690316.png',
    },
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_7.+Diner+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center">Zľava 10 % na vašu ďalšiu objednávku</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Príďte sa k nám najesť a užiť si pohodovú atmosféru! V Dave\'s Diner je pre nás vždy najvyššou prioritou obsluha s úsmevom.</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'spacer',
    },
    {
      type: 'coupon',
      text: '<h2 class="ql-align-center">Zľava 10 % na vašu ďalšiu objednávku.</h2><p class="ql-align-center" style="font-size: 10px;">Nie je možné kombinovať s inými zľavami alebo ponukami. </p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const FamilyDiningEmailData = {
  styles: {
    width: '600px',
    color: '#fff',
    backgroundColor: '#fc702e',
    couponBorderColor: '#fff',
    buttonsDividersColor: '#fff',
    sicailLinksColor: '#000',
  },
  components: [
    {
      type: 'image',
      image: 'em-logos/family-dining-email-2-logo_1498081751965.png',
    },
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_8.+Family+Dining+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center" style="font-style:italic;">UTORKY MAJÚ DETI JEDLO ZDARMA!</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Večera je rodinný čas v Crosby\'s Kitchen. Príďte AKÝKOĽVEK utorok a deti jedia zadarmo pri kúpe akéhokoľvek jedla pre dospelých.</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Garantujeme vám, že jedlo si zamilujete vy aj vaši najmenší.</p>',
    },
    {
      type: 'coupon',
      text: '<h4 class="ql-align-center">1 DETSKÉ JEDLO ZDARMA!</h4><p class="ql-align-center">(pri kúpe akéhokoľvek jedla pre dospelých)</p><br><p class="ql-align-center" style="font-size: 10px;">Nie je možné kombinovať s inými zľavami alebo ponukami.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const FineDiningEmailData = {
  styles: {
    width: '600px',
    backgroundColor: '#f5f3ef',
    color: '#000',
    couponBorderColor: '#770e1f',
    buttonsDividersColor: '#770e1f',
    sicailLinksColor: '#000',
  },
  components: [
    {
      type: 'image',
      image: 'em-logos/fine-dining-email-logo_1498081793107.png',
    },
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_9.+Fine+Dining.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center" style="font-style:italic; color:#770e1f;">POZVÁNKA PRE VÁS!</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Pripojte sa k nám a získajte zážitok z kombinácie chutí, ktoré ponúkame výhradne našim najvernejším priaznivcom.</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center" style="font-weight:bold;">Degustačné menu za 75 €</p>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">(zahŕňa 4-chodové menu doplnené vínom)</p>',
    },
    {
      type: 'coupon',
      text: '<h4 class="ql-align-center" style="font-weight:normal;">PROSÍM ODPOVEDZTE, či sa k nám pridáte na túto špeciálnu udalosť.</h4><p class="ql-align-center" style="font-style:italic;">Poponáhľajte sa, počet miest je obmedzený!</p><br><p class="ql-align-center" style="font-size: 10px;">Nie je možné kombinovať s inými zľavami alebo ponukami. Platí len pri platbe platobnou kartou.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const FloristsEmailData = {
  styles: {
    width: '600px',
    color: '#898989',
    backgroundColor: '#fff',
    couponBorderColor: '#00aeef',
    buttonsDividersColor: '#898989',
    sicailLinksColor: '#00aeef',
  },
  components: [
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_30.+Florists+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center">Leto je tu!</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Našu predajňu sme obliekli do letného šatu! Tak ako každú sezónu, aj teraz sme naše priestory naaranžovali tak, aby z nich bolo vidieť aj cítiť leto. Príďte sa inšpirovať a odniesť si kúsok leta so sebou.</p>',
    },
    {
      type: 'coupon',
      text: '<h2 class="ql-align-center">Letničky v črepníkoch len tento týždeň so zľavou 10%</h2><p class="ql-align-center" style="font-size: 10px;">Nie je možné kombinovať s inými zľavami alebo ponukami.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const FruitEmailData = {
  styles: {
    width: '600px',
    color: '#f00',
    backgroundColor: '#fff',
    couponBorderColor: '#f00',
    buttonsDividersColor: '#f00',
    sicailLinksColor: '#f00',
  },
  components: [
    {
      type: 'image',
      image: 'em-logos/fruit-email-logo_1498081891611.png',
    },
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_10.+Fruit+Template_CZ.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center">10 % zľava na prvú objednávku</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Naše ovocie je zaručene čerstvé! Zásobte sa zdravým občerstvením pre vás a vašich priateľov. Naše košíky s ovocím určite vyčaria na každej tvári úsmev.</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'spacer',
    },
    {
      type: 'coupon',
      text: '<h2 class="ql-align-center">10 % zľava na prvú objednávku</h2><p class="ql-align-center" style="font-size: 10px;">Obmedzenie na 1 zákazníka. Nie je možné kombinovať s inými zľavami alebo ponukami.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const ItalianEmailData = {
  styles: {
    width: '600px',
    color: '#000',
    backgroundColor: '#fcefd8',
    couponBorderColor: '#d82c1f',
    buttonsDividersColor: '#d82c1f',
    sicailLinksColor: '#000',
  },
  components: [
    {
      type: 'image',
      image: 'em-logos/italian-email-logo_1498081923428.png',
    },
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_11.+Italian+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center" style="font-style:italic; color:#d82c1f;">UŽITE SI ZĽAVU 10 € PRI VAŠEJ ĎALŠEJ NÁVŠTEVE!</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Určite si vychutnáte fantastické jedlo, či už ide o naše famózne Pomodoro Bolognese alebo Penne Carbonara.</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Ukážte nám tento e-mail a získajte zľavu 10 €.</p>',
    },
    {
      type: 'coupon',
      text: '<h4 class="ql-align-center" style="color:#d82c1f;">ZĽAVA 10 € PRI VAŠEJ ĎALŠEJ NÁVŠTEVE!</h4><br><p class="ql-align-center" style="font-size: 10px;">Nie je možné kombinovať s inými zľavami alebo ponukami. Neplatí pri platbe v hotonosti.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const MexicanEmailData = {
  styles: {
    width: '600px',
    color: '#fff',
    backgroundColor: '#efae2a',
    couponBorderColor: '#fff',
    buttonsDividersColor: '#fff',
    sicailLinksColor: '#fff',
  },
  components: [
    {
      type: 'image',
      image: 'em-logos/mexican-email-logo_1498082003319.png',
    },
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_12.+Mexican+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center">10% zľava pri vašej ďalšej návšteve</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Naše autentické menu vám zaručene rozjasní deň! Príďte k nám a vychutnajte si chladený nápoj a chutné predjedlá za bezkonkurenčné ceny. Fiesta môže začať!</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'coupon',
      text: '<h2 class="ql-align-center">Zľava 10 % na vašu ďalšiu objednávku.</h2><p class="ql-align-center" style="font-size: 10px;">Nie je možné kombinovať s inými zľavami alebo ponukami.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const MoveItSportsEmailData = {
  styles: {
    width: '600px',
    color: '#000',
    backgroundColor: '#fff',
    buttonsDividersColor: '#000',
    sicailLinksColor: '#0779be',
  },
  components: [
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<h1 class="ql-align-center" style="color:#0779be; font-style:italic;">Máte na to!</h1>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Pozrite si aj naše špeciálne drinky a aktualizované menu.</p>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center" style="font-style:italic; font-weight:bold;">Zavolajte nám a rezervujte si stôl ešte dnes!</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_13.+Move+It+Sports+Template.jpg',
    },
  ],
};

const PetShopEmailData = {
  styles: {
    width: '600px',
    color: '#898989',
    backgroundColor: '#fff',
    couponBorderColor: '#00aeef',
    buttonsDividersColor: '#898989',
    sicailLinksColor: '#00aeef',
  },
  components: [
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_28.+Pet+Shop+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center">Vždy veľký výber!</h2>',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center">Zľava 10 % na vašu ďalšiu objednávku</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Všetko pre vašich domácich miláčikov s odborným poradenstvom.</p>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">U nás nakúpite presne to, čo váš domáci miláčik naozaj potrebuje.</p>',
    },
    {
      type: 'coupon',
      text: '<h2 class="ql-align-center">Zľava 10 % na vašu ďalšiu objednávku</h2><p class="ql-align-center" style="font-size: 10px;">Nie je možné kombinovať s inými zľavami alebo ponukami.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const PizzaEmailData = {
  styles: {
    width: '600px',
    color: '#000',
    backgroundColor: '#fdd85c',
    couponBorderColor: '#eb6464',
    buttonsDividersColor: '#eb6464',
    sicailLinksColor: '#000',
  },
  components: [
    {
      type: 'image',
      image: 'em-logos/pizza-email-logo_1498082059029.png',
    },
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_14.+Pizza+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center" style="font-style:italic">1+1 ZDARMA!</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Od húb a feferóniek po olivy a ančovičky, výber je na vás. Objednajte si akúkoľvek pizzu a druhú dostanete ZADARMO!</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Pre získanie výhodnej ponuky vám stačí ukázať tento email. </p>',
    },
    {
      type: 'coupon',
      text: '<h3 class="ql-align-center">1+1 ZDARMA!</h3><p class="ql-align-center">(za rovnakú alebo nižšiu cenu)</p><br><p class="ql-align-center" style="font-size: 10px;">Nie je možné kombinovať s inými zľavami alebo ponukami.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const QsrEmailData = {
  styles: {
    width: '600px',
    color: '#000',
    backgroundColor: '#fff',
    couponBorderColor: '#000',
    buttonsDividersColor: '#000',
    sicailLinksColor: '#000',
  },
  components: [
    {
      type: 'image',
      image: 'em-logos/burger_1505312326850_1505312462526.png',
    },
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_15.+QSR+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center" style="font-style:italic; color:#aa181c;">V PIATOK HRANOLKY ZDARMA</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Milujeme hranolky. Milujete hranolky. Stavíme sa, že hranolky ZDARMA budete milovať ešte viac.</p>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Objednajte si akýkoľvek hamburger alebo sendvič a dostanete veľké hranolky zdarma.</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Poponáhľajte sa, ponuka platí len v marci!</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'coupon',
      text: '<h3 class="ql-align-center" style="color:#aa181c;">VEĽKÉ HRANOLKY ZDARMA!</h3><p class="ql-align-center">(pri kúpe akéhokoľvek hamburgera alebo sendviča)</p><br><p class="ql-align-center" style="font-size: 10px;">Nie je možné kombinovať s inými zľavami alebo ponukami.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const SushiEmailData = {
  styles: {
    width: '600px',
    color: '#fff',
    backgroundColor: '#231f20',
    couponBorderColor: '#fff',
    buttonsDividersColor: '#fff',
    sicailLinksColor: '#fff',
  },
  components: [
    {
      type: 'image',
      image: 'em-logos/sushi-email-logo_1498082220393.png',
    },
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_16.+Sushi+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center" style="font-style:italic;">POLOVIČNÉ CENY POČAS HAPPY HOUR!</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Vybrané sushi a predjedlá od 3,99 € do 9,99 €!</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Pozrite si aj naše špeciálne nápoje a aktualizované menu.</p>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Zavolajte nám a rezervujte si stôl ešte dnes!</p>',
    },
    {
      type: 'coupon',
      text: '<h4 class="ql-align-center">KÚPTE SI JEDNU ROLKU, ZÍSKAJTE DRUHÚ ZA POLOVICU</h4><p class="ql-align-center">(za rovnakú alebo nižšiu cenu)</p><br><p class="ql-align-center" style="font-size: 10px;">Obmedzenie na 1 zákazníka. Nie je možné kombinovať s inými zľavami alebo ponukami.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const TimberlineEmailData = {
  styles: {
    width: '600px',
    color: '#000',
    backgroundColor: '#fff',
    sicailLinksColor: '#c58fbb',
    buttonsDividersColor: '#000',
  },
  components: [
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<h1 class="ql-align-center" style="font-style:italic;">KONIEC LETNÉHO VÝPREDAJA</h1>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Letné slnko pomaly zapadá a my pre vás máme posledné letné potešenie. Tento týždeň máme pre vás pripravenú akciu 1 + 1 zdarma.</p>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center" style="font-style:italic; font-weight:bold;">Nakupujte vo výpredaji!</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_17.+Timberline+Template.jpg',
    },
  ],
};

const BlankEmailData = {
  styles: {
    width: '600px',
    color: '#000',
    backgroundColor: '#fff',
    buttonsDividersColor: '#000',
    sicailLinksColor: '#000',
  },
  components: [
  ],
};

export const skStaticTemplates = [
  {
    id: 'xfymst7f6nfm650jfny8',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateAutomotiveServiceShopTitle',
    status: 'origin',
    favorites: 'false',
    components: AutomotiveServiceShopEmailData.components,
    styles: AutomotiveServiceShopEmailData.styles,
    thumb: 'automotive-service-shop-email-preview-sk.jpg'
  },
  {
    id: 'q5fb3c34exm46x12kx7e',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateBakeryTitle',
    status: 'origin',
    favorites: 'false',
    components: BakeryEmailData.components,
    styles: BakeryEmailData.styles,
    thumb: 'bakery-email-preview-sk.jpg',
  },
  {
    id: 'kr4w2wlpsw44fy0dc32d',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateBarberShopTitle',
    status: 'origin',
    favorites: 'false',
    components: BarberShopEmailData.components,
    styles: BarberShopEmailData.styles,
    thumb: 'barber-shop-email-preview-sk.jpg',
  },
  {
    id: 'qnqks52ydaxmbu9wf00u',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateBarPubTitle',
    status: 'origin',
    favorites: 'false',
    components: BarPubEmailData.components,
    styles: BarPubEmailData.styles,
    thumb: 'bar-pub-email-preview-sk.jpg',
  },
  {
    id: 'zdji8s20848u5q3do4xs',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateBeautyShopTitle',
    status: 'origin',
    favorites: 'false',
    components: BeautyShopEmailData.components,
    styles: BeautyShopEmailData.styles,
    thumb: 'beauty-shop-email-preview-sk.jpg',
  },
  {
    id: '4fzeg3i90hvyyv35ajpc',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateBurgerTitle',
    status: 'origin',
    favorites: 'false',
    components: BurgerEmailData.components,
    styles: BurgerEmailData.styles,
    thumb: 'burger-email-preview-sk.jpg',
  },
  {
    id: 'k2sjamghjp2kcb6y7wlb',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateChineseTitle',
    status: 'origin',
    favorites: 'false',
    components: ChineseEmailData.components,
    styles: ChineseEmailData.styles,
    thumb: 'chinese-email-preview-sk.jpg',
  },
  {
    id: '38jqxq9ge0ba6c5zm9ez',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateClothingShopTitle',
    status: 'origin',
    favorites: 'false',
    components: ClothingShopEmailData.components,
    styles: ClothingShopEmailData.styles,
    thumb: 'clothing-shop-email-preview-sk.jpg',
  },
  {
    id: 'zxv4ynnee9n06140q0vs',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateCoffeeTitle',
    status: 'origin',
    favorites: 'false',
    components: CoffeeEmailData.components,
    styles: CoffeeEmailData.styles,
    thumb: 'coffee-email-preview-sk.jpg',
  },
  {
    id: '6y69tpm15m6j93pnoe2l',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateDessertTitle',
    status: 'origin',
    favorites: 'false',
    components: DessertEmailData.components,
    styles: DessertEmailData.styles,
    thumb: 'dessert-email-preview-sk.jpg',
  },
  {
    id: 'r0y7jiw7t3gcb8luh7lv',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateDinerTitle',
    status: 'origin',
    favorites: 'false',
    components: DinerEmailData.components,
    styles: DinerEmailData.styles,
    thumb: 'diner-email-preview-sk.jpg',
  },
  {
    id: 'li3g3jdzx4mueaq8hamy',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateFamilyDiningTitle',
    status: 'origin',
    favorites: 'false',
    components: FamilyDiningEmailData.components,
    styles: FamilyDiningEmailData.styles,
    thumb: 'family-dining-email-preview-sk.jpg',
  },
  {
    id: 'f0a3em6r7drfz2gtdckw',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateFineDiningTitle',
    status: 'origin',
    favorites: 'false',
    components: FineDiningEmailData.components,
    styles: FineDiningEmailData.styles,
    thumb: 'fine-dining-email-preview-sk.jpg',
  },
  {
    id: '4fca2urqur7d0xwr0gvj',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateFloristsTitle',
    status: 'origin',
    favorites: 'false',
    components: FloristsEmailData.components,
    styles: FloristsEmailData.styles,
    thumb: 'florists-email-preview-sk.jpg',
  },
  {
    id: '9i6vv6lqa80ns2asdtax',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateFruitTitle',
    status: 'origin',
    favorites: 'false',
    components: FruitEmailData.components,
    styles: FruitEmailData.styles,
    thumb: 'fruit-email-preview-sk.jpg',
  },
  {
    id: 'q3dzifzei5ht5692jwdh',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateItalianTitle',
    status: 'origin',
    favorites: 'false',
    components: ItalianEmailData.components,
    styles: ItalianEmailData.styles,
    thumb: 'italian-email-preview-sk.jpg',
  },
  {
    id: 'cdps3ifdqosdzot5wrns',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateMexicanTitle',
    status: 'origin',
    favorites: 'false',
    components: MexicanEmailData.components,
    styles: MexicanEmailData.styles,
    thumb: 'mexican-email-preview-sk.jpg',
  },
  {
    id: 'jkgr4tk6z4p38fhnaf06',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateMoveItSportsTitle',
    status: 'origin',
    favorites: 'false',
    components: MoveItSportsEmailData.components,
    styles: MoveItSportsEmailData.styles,
    thumb: 'move-it-sports-email-preview-sk.jpg',
  },
  {
    id: '71xrlsj735mlbfzsel7q',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplatePetShopTitle',
    status: 'origin',
    favorites: 'false',
    components: PetShopEmailData.components,
    styles: PetShopEmailData.styles,
    thumb: 'pet-shop-email-preview-sk.jpg',
  },
  {
    id: '6d6mtz028e603dv4jbur',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplatePizzaTitle',
    status: 'origin',
    favorites: 'false',
    components: PizzaEmailData.components,
    styles: PizzaEmailData.styles,
    thumb: 'pizza-email-preview-sk.jpg',
  },
  {
    id: 'hq4tzfygk36nfe6rumjv',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateQSRTitle',
    status: 'origin',
    favorites: 'false',
    components: QsrEmailData.components,
    styles: QsrEmailData.styles,
    thumb: 'qsr-email-preview-sk.jpg',
  },
  {
    id: 'qqh2zfnytddi5ny0njzl',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateSushiTitle',
    status: 'origin',
    favorites: 'false',
    components: SushiEmailData.components,
    styles: SushiEmailData.styles,
    thumb: 'sushi-email-preview-sk.jpg',
  },
  {
    id: 'gv67inseayrror655j8b',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateTimberlineTitle',
    status: 'origin',
    favorites: 'false',
    components: TimberlineEmailData.components,
    styles: TimberlineEmailData.styles,
    thumb: 'timberline-email-preview-sk.jpg',
  },
  {
    id: 'es70zk0noj21j9ioicky',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateBlankTitle',
    status: 'origin',
    favorites: 'false',
    components: BlankEmailData.components,
    styles: BlankEmailData.styles,
    thumb: 'blank-sk.png',
  },
];
