import React from 'react';

interface GreyableImageProps {
  grayscale: boolean;
  imgSrc: string;
  height: string;
  width: string;
}

const GrayableImage = ({ imgSrc, height, width, grayscale }: GreyableImageProps) => (
  <svg className="grayable-image" width={width} height={height}>
    <defs>
      <filter id="filtersPicture">
        <feComposite
          result="inputTo_38"
          in="SourceGraphic"
          in2="SourceGraphic"
          operator="arithmetic"
          k1="0"
          k2="1"
          k3="0"
          k4="0"
        />
        <feColorMatrix id="filter_38" type="saturate" values="0" data-filterid="38" />
      </filter>
    </defs>
    {grayscale ? (
      <image filter='url("#filtersPicture")' x="0" y="0" width={width} height={height} xlinkHref={imgSrc} />
    ) : (
      <image x="0" y="0" width={width} height={height} xlinkHref={imgSrc} />
    )}
  </svg>
);

export default GrayableImage;
