import { takeLatest, all, call, put } from 'redux-saga/effects';

import { ApiGateway } from 'services/apiGateway';
import { refundsChargebacksActions } from './actions';

export function* fetchRefundsChargebackSaga({
  payload
}: ReturnType<typeof refundsChargebacksActions.fetchSummary.request>) {
  try {
    if (payload.locations.length) {
      const data = yield call(ApiGateway.payments.refundsAndChargebacks.fetchSummary, payload);
      yield put(refundsChargebacksActions.fetchSummary.success(data));
    } else {
      yield put(refundsChargebacksActions.fetchSummary.success(null));
    }
  } catch (e) {
    yield put(refundsChargebacksActions.fetchSummary.failure(e));
  }
}

export function* fetchCardsSaga({ payload }: ReturnType<typeof refundsChargebacksActions.fetchCards.request>) {
  try {
    if (payload.locations.length) {
      const data = yield call(ApiGateway.payments.refundsAndChargebacks.fetchCards, payload);
      yield put(refundsChargebacksActions.fetchCards.success(data));
    } else {
      yield put(refundsChargebacksActions.fetchCards.success(null));
    }
  } catch (e) {
    yield put(refundsChargebacksActions.fetchCards.failure(e));
  }
}

export function* fetchTransactionsRefunded({
  payload
}: ReturnType<typeof refundsChargebacksActions.fetchTransactionsRefunded.request>) {
  try {
    if (payload.locations.length) {
      const data = yield call(ApiGateway.payments.refundsAndChargebacks.fetchTransactionsRefunded, payload);
      yield put(refundsChargebacksActions.fetchTransactionsRefunded.success(data));
    } else {
      yield put(refundsChargebacksActions.fetchTransactionsRefunded.success(null));
    }
  } catch (e) {
    yield put(refundsChargebacksActions.fetchTransactionsRefunded.failure(e));
  }
}

export function* fetchTransactionsRefundRatesSaga({
  payload
}: ReturnType<typeof refundsChargebacksActions.fetchTransactionsRefundRates.request>) {
  try {
    if (payload.locations.length) {
      const data = yield call(ApiGateway.payments.refundsAndChargebacks.fetchTransactionsRefundRates, payload);
      yield put(refundsChargebacksActions.fetchTransactionsRefundRates.success(data));
    } else {
      yield put(refundsChargebacksActions.fetchTransactionsRefundRates.success(null));
    }
  } catch (e) {
    yield put(refundsChargebacksActions.fetchTransactionsRefundRates.failure(e));
  }
}

export function* fetchTransactionsChargebackRatesSaga({
  payload
}: ReturnType<typeof refundsChargebacksActions.fetchTransactionsChargebackRates.request>) {
  try {
    if (payload.locations.length) {
      const data = yield call(ApiGateway.payments.refundsAndChargebacks.fetchTransactionsChargebackRates, payload);
      yield put(refundsChargebacksActions.fetchTransactionsChargebackRates.success(data));
    } else {
      yield put(refundsChargebacksActions.fetchTransactionsChargebackRates.success(null));
    }
  } catch (e) {
    yield put(refundsChargebacksActions.fetchTransactionsChargebackRates.failure(e));
  }
}

export function* refundsChargebackSagas() {
  yield all([
    takeLatest(refundsChargebacksActions.fetchSummary.request, fetchRefundsChargebackSaga),
    takeLatest(refundsChargebacksActions.fetchCards.request, fetchCardsSaga),
    takeLatest(refundsChargebacksActions.fetchTransactionsRefunded.request, fetchTransactionsRefunded),
    takeLatest(refundsChargebacksActions.fetchTransactionsRefundRates.request, fetchTransactionsRefundRatesSaga),
    takeLatest(refundsChargebacksActions.fetchTransactionsChargebackRates.request, fetchTransactionsChargebackRatesSaga)
  ]);
}
