import { ActionType, getType } from 'typesafe-actions';
import { Page } from '../settings/layout';
import { supportSearchPathname } from '../../appConstants';
import { navigationActions } from './actions';
import { AppMenusInterface } from './types';

const initialState: AppMenusInterface = {
  activePage: undefined,
  leftMenuState: 'open',
  pages: [],
  topMenuShown: false,
  isSupportSearch: false,
  history: []
};

export function appMenusReducer(
  state: AppMenusInterface = initialState,
  action: ActionType<typeof navigationActions>
): AppMenusInterface {
  switch (action.type) {
    case getType(navigationActions.openLeftHandSideMenu):
      return { ...state, leftMenuState: action.payload.state };
    case getType(navigationActions.receivePages): {
      if (action.payload.pages.length < 1) return state;
      const isSupportSearch = !!action.payload.pages.find(page => page.path === supportSearchPathname);
      return { ...state, pages: action.payload.pages, isSupportSearch };
    }
    case getType(navigationActions.setActivePage): {
      const { pathname } = action.payload.location;

      const isSupportSearch =
        pathname === supportSearchPathname ||
        (state.pages.length === 1 && state.pages[0].path === supportSearchPathname);

      const filteredPages = isSupportSearch
        ? state.pages
        : state.pages.filter(({ path }) => path !== supportSearchPathname);
      const activePage =
        filteredPages.find((route: Page): boolean => route.path === `/${pathname.split('/')[1]}`) || filteredPages[0];
      const pages: Page[] = isSupportSearch
        ? state.pages.filter(({ path }) => path === supportSearchPathname)
        : state.pages;
      const nextHistory = [pathname, ...state.history];
      if (nextHistory.length > 100) nextHistory.length = 100;
      return activePage ? { ...state, activePage, pages, history: nextHistory } : state;
    }
    case getType(navigationActions.toggleTopMenu): {
      const { shown } = action.payload;
      return { ...state, topMenuShown: typeof shown === 'boolean' ? shown : !state.topMenuShown };
    }
    default:
      return state;
  }
}
