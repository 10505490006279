import { ActionType, createReducer } from 'typesafe-actions';
import { CustomerTypes, LineChartMode, PdfChartsModeMap, PdfState } from './types';
import { pdfActions } from './actions';

const DEFAULT_CUSTOMER_TYPE = CustomerTypes.new;

const defaultState: PdfState = {
  loading: false,
  pdfMode: false,
  analytics: {
    lineChartMode: LineChartMode.sales,
    spotlightsChartMode: DEFAULT_CUSTOMER_TYPE,
    ticketTiersChartMode: DEFAULT_CUSTOMER_TYPE,
    salesTimeSegmentsChartMode: CustomerTypes.all,
    visitsTimeSegmentsChartMode: CustomerTypes.all,
    weeklySalesBreakdownChartMode: DEFAULT_CUSTOMER_TYPE,
    [PdfChartsModeMap.lineChartPredictiveData]: true
  }
};

export const pdfReducer = createReducer<PdfState, ActionType<typeof pdfActions>>(defaultState)
  .handleAction(pdfActions.addChartInfo, (state, { payload }) => ({
    ...state,
    [payload.page]: {
      ...state[payload.page],
      ...payload.options
    }
  }))
  .handleAction(pdfActions.switchPdfMode, (state, { payload }) => ({
    ...state,
    pdfMode: payload
  }))
  .handleAction(pdfActions.generatePdf.request, state => ({ ...state, loading: true }))
  .handleAction(pdfActions.generatePdf.success, state => ({ ...state, loading: false }))
  .handleAction(pdfActions.generatePdf.failure, state => ({ ...state, loading: false }))

  .handleAction(pdfActions.generatePdfForPage.request, state => ({ ...state, loading: true }))
  .handleAction(pdfActions.generatePdfForPage.success, state => ({ ...state, loading: false }))
  .handleAction(pdfActions.generatePdfForPage.failure, state => ({ ...state, loading: false }));
