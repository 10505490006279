import styled from 'styled-components';
import React from 'react';
import { getColor } from 'layout/theme';
import { deviceMedia } from 'appConstants';

const NavigationBtn = styled.i`
  color: ${props => getColor(props, 'color1')};
  font-size: 13px;
  @media ${deviceMedia.mobile} {
    font-size: 18px;
  }
`;

export const PrevBtn = () => <NavigationBtn className="fas fa-chevron-left" data-test-id="left-icon" />;
export const NextBtn = () => <NavigationBtn className="fas fa-chevron-right" data-test-id="right-icon" />;
