import i18n, { InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import { DEFAULT_NS } from './appConstants/translationNamespaces';
import logger from './i18n.logger';

const localOptions: InitOptions = {
  fallbackLng: {
    'fr-CA': ['en-US-Canada'],
    'es-MX': ['en-US-Mexico'],
    'de-AT': ['en-GB-Danube'],
    cs: ['en-GB-Danube'],
    ro: ['en-GB-Danube'],
    sk: ['en-GB-Danube'],
    de: ['en-GB-Germany'],
    hu: ['en-GB-Hungary'],
    default: ['en-US'],
    'en-GB-Danube': ['en-GB'],
    'en-GB-Germany': ['en-GB'],
    'en-GB-Hungary': ['en-GB'],
    'en-US-Canada': ['en-US'],
    'en-US-Mexico': ['en-US']
  },
  preload: false,
  load: 'currentOnly',
  debug: true,
  ns: [],
  react: {
    useSuspense: false
  },
  defaultNS: DEFAULT_NS,
  saveMissing: true,
  missingKeyHandler: () => {
    // logic moved to i18n.logger.ts, but function should be presented here
  },
  backend: {
    loadPath: '/translations/{{lng}}/{{ns}}.json',
    crossDomain: false,
    queryStringParams: { origin: window.location.host }
  }
};

const i18nInstance = i18n.createInstance(localOptions);

i18nInstance.use(Backend).use(initReactI18next).use(logger).init();

export default i18nInstance;
