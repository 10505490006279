import React from 'react';
import ReactModal from 'react-modal';
import styled, { FlattenSimpleInterpolation } from 'styled-components';
import { ReactComponent as CloseIcon } from '../assets/icons/close-filled.svg';
import { resetButtonStyles, textSubtitle, textTitle } from '../layout/mixins';
import { Button, ButtonProps } from './Button';
import { getColor } from '../layout/theme';
import { ReactComponent as WarningIcon } from '../assets/icons/exclamation-triangle.svg';
import { ReactComponent as SuccessIcon } from '../assets/icons/check-circle.svg';
import { deviceMedia } from '../appConstants';

type ModalType = 'warning' | 'success' | 'default';

export interface ModalProps {
  className?: string;
  children?: React.ReactElement | string | null;
  modalHeader?: React.ReactElement | string | null;
  modalFooter?: React.ReactElement | string | null;
  isOpen: boolean;
  onRequestClose?: (e: any) => void;
  buttonSecondarySettings?: ButtonProps;
  buttonPrimarySettings?: ButtonProps;
  buttonSecondaryText?: string;
  buttonPrimaryText?: string;
  type?: ModalType;
  rootElement?: string;
  overlayCustomClass?: string;
  hideCloseIcon?: boolean;
  onWindowClick?: () => void;
  isEventPropagationPrevented?: boolean;
  customCss?: string;
  overlayCustomCss?: any;
  customModalContentStylesMixin?: FlattenSimpleInterpolation;
  customModalPopupStylesMixin?: FlattenSimpleInterpolation;
  dataTestIdPrefix?: string;
}

export interface ModalResponse<T = any> {
  response: boolean;
  by: 'closeIcon' | 'cancelButton' | 'overlayOrEscButton' | 'okButton';
  payload: T;
}

export const modalCloseTypes = {
  closeIcon: { response: false, by: 'closeIcon' },
  cancelButton: { response: false, by: 'cancelButton' },
  overlayOrEscButton: { response: false, by: 'overlayOrEscButton' },
  okButton: { response: true, by: 'okButton' }
};
const headerColors: { [key in ModalType]: string } = {
  warning: 'color2',
  success: 'color4',
  default: 'darkGrey'
};

const headerIcons: { [key in ModalType]: JSX.Element | null } = {
  warning: <WarningIcon data-test-id="warning-icon" />,
  success: <SuccessIcon data-test-id="success-icon" />,
  default: null
};

const ModalHeaderContainer = styled.div`
  position: relative;
  padding: 30px 30px 15px 30px;
  border-bottom: 1px solid ${props => props.theme.baseColors.grey};
  @media ${deviceMedia.mobile} {
    padding: 15px 35px 15px 15px;
  }
`;
ModalHeaderContainer.displayName = 'ModalHeaderContainer';

const ModalHeader = styled.h1<{ type: ModalType }>`
  margin: 0;
  ${textTitle};
  text-transform: uppercase;
  align-items: center;
  display: flex;
  width: calc(100% - 25px);
  word-break: break-word;

  svg {
    margin-right: 10px;
    max-width: 30px;

    [fill^='#'] {
      fill: ${props => getColor(props, headerColors[props.type])};
    }
  }
`;
ModalHeader.displayName = 'ModalHeader';

const StyledCloseIcon = styled(CloseIcon)`
  width: 100%;
  height: 100%;
`;

const CloseButton = styled.button`
  ${resetButtonStyles};
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
  @media ${deviceMedia.mobile} {
    right: 10px;
  }
`;
CloseButton.displayName = 'CloseButton';

const ModalPopup = styled.div<{ stylesMixin?: FlattenSimpleInterpolation }>`
  ${({ stylesMixin }) => stylesMixin || ''};
`;
ModalPopup.displayName = 'ModalPopup';

const ModalContentContainer = styled.div<{ stylesMixin?: FlattenSimpleInterpolation }>`
  position: relative;
  padding: 30px;
  flex: 1;
  word-wrap: normal;
  word-break: normal;
  overflow-x: auto;
  line-height: 1.4;
  ${textSubtitle};
  @media ${deviceMedia.mobile} {
    padding: 15px;
  }
  ${({ stylesMixin }) => stylesMixin};
`;
const MODAL_CONTENT_CONTAINER = 'ModalContentContainer';
ModalContentContainer.displayName = MODAL_CONTENT_CONTAINER;

const ModalFooterContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 15px 30px;
  background-color: ${props => props.theme.baseColors.lightGrey};
  border-top: 1px solid ${props => props.theme.baseColors.grey};

  @media ${deviceMedia.mobile} {
    padding: 15px;
  }
`;
ModalFooterContainer.displayName = 'ModalFooterContainer';

const ModalFooter = styled.p`
  flex: 1;
  margin: 0;
`;
ModalFooter.displayName = 'ModalFooter';

const ModalFooterButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;
ModalFooterButtonContainer.displayName = 'ModalFooterButtonContainer';

const ButtonWrapper = styled.div`
  & + & {
    margin-left: 15px;
  }
`;

const ModalComponent = (props: ModalProps): JSX.Element => {
  const {
    isOpen,
    onRequestClose,
    className,
    modalHeader,
    modalFooter,
    children,
    buttonPrimarySettings = { primary: true },
    buttonSecondarySettings = { secondary: true },
    buttonPrimaryText,
    buttonSecondaryText,
    type = 'default',
    rootElement = 'applicationRootId',
    overlayCustomClass,
    hideCloseIcon,
    onWindowClick,
    isEventPropagationPrevented = true,
    customModalContentStylesMixin,
    customModalPopupStylesMixin,
    overlayCustomCss = {},
    dataTestIdPrefix = ''
  } = props;

  const overlayCss = {
    overlay: Object.assign(
      {
        backgroundColor: 'rgba(79,79,79,0.9)',
        zIndex: '999'
      },
      overlayCustomCss
    ),
    content: {}
  };

  return (
    <ReactModal
      {...props}
      className={className}
      style={overlayCss}
      isOpen={isOpen}
      onRequestClose={() => onRequestClose && onRequestClose(modalCloseTypes.overlayOrEscButton)}
      parentSelector={() => document.getElementById(rootElement) || document.body}
      overlayClassName={overlayCustomClass}
      ariaHideApp={false}
    >
      <ModalPopup
        stylesMixin={customModalPopupStylesMixin}
        data-test-id={`${dataTestIdPrefix}confirmation-pop-up`}
        role="presentation"
        onTouchStart={e => {
          if (isEventPropagationPrevented) {
            e.nativeEvent.stopImmediatePropagation();
          }
        }}
        onClick={e => {
          if (onWindowClick) onWindowClick();
          if (isEventPropagationPrevented) {
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
          }
        }}
      >
        {modalHeader && (
          <ModalHeaderContainer data-test-id="header">
            <ModalHeader type={type} data-test-id="title">
              {headerIcons[type]} {modalHeader}
            </ModalHeader>
            {!hideCloseIcon ? (
              <CloseButton
                onClick={() => onRequestClose && onRequestClose(modalCloseTypes.closeIcon)}
                data-test-id="close-icon"
              >
                <StyledCloseIcon />
              </CloseButton>
            ) : null}
          </ModalHeaderContainer>
        )}
        {children && (
          <ModalContentContainer
            stylesMixin={customModalContentStylesMixin}
            data-test-id="body"
            className={MODAL_CONTENT_CONTAINER}
          >
            {children}
          </ModalContentContainer>
        )}
        {modalFooter || buttonSecondaryText || buttonPrimaryText ? (
          <ModalFooterContainer data-test-id="footer" className="modal-footer-container">
            <ModalFooter>{modalFooter}</ModalFooter>
            <ModalFooterButtonContainer>
              {buttonSecondaryText && (
                <ButtonWrapper>
                  <Button
                    {...buttonSecondarySettings}
                    onClick={() => onRequestClose && onRequestClose(modalCloseTypes.cancelButton)}
                    data-test-id="cancel-button"
                  >
                    {buttonSecondaryText}
                  </Button>
                </ButtonWrapper>
              )}
              {buttonPrimaryText && (
                <ButtonWrapper>
                  <Button
                    {...buttonPrimarySettings}
                    onClick={() => onRequestClose && onRequestClose(modalCloseTypes.okButton)}
                    data-test-id="ok-button"
                  >
                    {buttonPrimaryText}
                  </Button>
                </ButtonWrapper>
              )}
            </ModalFooterButtonContainer>
          </ModalFooterContainer>
        ) : null}
      </ModalPopup>
    </ReactModal>
  );
};

const Modal = styled(ModalComponent)`
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 70px;
  max-width: 600px;
  width: 100%;
  z-index: 99999;
  outline: none;
  background-color: ${props => props.theme.baseColors.white};
  border: 1px solid ${props => props.theme.baseColors.grey};
  margin-bottom: 10px;
  @media ${deviceMedia.laptopS} {
    top: 25px;
  }
  @media ${deviceMedia.mobile} {
    width: 98%;
    top: 10px;
  }
  ${props => props.customCss};
`;

export default Modal;
