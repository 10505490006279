import React, { ReactElement } from 'react';
import { ReactComponent as ApiErrorSvg } from 'assets/error/apiError.svg';
import { useTranslation } from 'react-i18next';
import { ERROR_PAGES } from '../../appConstants/translationNamespaces';
import { Container } from './styles';

export const OfflineError = (): ReactElement => {
  const { t } = useTranslation(ERROR_PAGES);
  return (
    <Container>
      <div data-test-id="offline-error-wrapper">
        <ApiErrorSvg data-test-id="error-image" />
        <p data-test-id="offline-error-message">
          {t(
            'offlineError',
            'Uh-oh! You seem to be offline. Please check your internet connection or try back again later.'
          )}
        </p>
      </div>
    </Container>
  );
};
