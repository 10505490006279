import { ActionType, createReducer } from 'typesafe-actions';
import * as types from './types';
import { supportSearchActions } from './actions';

const defaultSupportSearchState = {
  merchants: [],
  showTutorialScreen: true
};

type State = types.SupportSearchState;
type Action = ActionType<typeof supportSearchActions>;

const { searchActions, getJWTForMerchantList, clearAllFields } = supportSearchActions;

export const supportSearchReducer = createReducer<State, Action>(defaultSupportSearchState)
  .handleAction(searchActions.request, state => ({ ...state, loading: true }))
  .handleAction(searchActions.success, (state, action) => ({
    ...state,
    merchants: action.payload,
    loading: false,
    showTutorialScreen: false
  }))
  .handleAction(searchActions.failure, state => ({ ...state, loading: false }))
  .handleAction(getJWTForMerchantList.request, state => ({ ...state, loading: true }))
  .handleAction(getJWTForMerchantList.success, state => ({ ...state, loading: false }))
  .handleAction(getJWTForMerchantList.failure, state => ({ ...state, loading: false }))
  .handleAction(clearAllFields, state => ({ ...state, merchants: [], showTutorialScreen: true }));
