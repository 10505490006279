import { createAction, createAsyncAction } from 'typesafe-actions';

const setContactsList = createAction('@em/contacts/bounced/list/set', action => (data: any[]) => action({ data }));

const fetchContacts = createAsyncAction(
  '@em/contacts/bounced/fetch/request',
  '@em/contacts/bounced/fetch/success',
  '@em/contacts/bounced/fetch/error'
)<any, any, void>();

const setBouncedSortFieldAndOrder = createAction(
  '@em/contacts/bounced/sort/set',
  action => (sort: string) => action(sort)
);

export const bouncedContactsActions = {
  setContactsList,
  fetchContacts,
  setBouncedSortFieldAndOrder
};
