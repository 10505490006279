import { createAction, createAsyncAction } from 'typesafe-actions';
import { CampaignStatus, EMCampaign, EMCampaignDetailsResponse } from './types';

export const emCampaignsActions = {
  getList: createAsyncAction(
    '@em/campaigns/list/fetch',
    '@em/campaigns/list/fetch/success',
    '@em/campaigns/list/fetch/error'
  )<void, EMCampaign[], void>(),

  deleteItem: createAsyncAction(
    '@em/campaigns/item/delete',
    '@em/campaigns/item/delete/success',
    '@em/campaigns/item/delete/error'
  )<number, number, void>(),

  getItem: createAsyncAction(
    '@em/campaigns/item/getStatistic',
    '@em/campaigns/item/getStatistic/success',
    '@em/campaigns/item/getStatistic/error'
  )<{ id: number; status?: CampaignStatus }, EMCampaignDetailsResponse, number>(),

  getItemData: createAsyncAction(
    '@em/campaigns/item/getData/request',
    '@em/campaigns/item/getData/success',
    '@em/campaigns/item/getData/error'
  )<{ id: number }, void, void>(),

  setDeletedCampaign: createAction(
    '@em/campaigns/deletedCampaign/set',
    action => (campaign?: EMCampaign) => action(campaign)
  )
};
