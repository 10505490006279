import { call, put, select, takeEvery, takeLatest } from '@redux-saga/core/effects';
import { getType } from 'typesafe-actions';
import { get } from 'lodash';
import i18next from 'i18n';
import { getMenuPages } from 'store/navigation';
import { NotificationAppearance, notificationsActions } from 'store/notifications';
import { NOTE_TITLE } from 'appConstants/emailMarketing';
import { history } from 'store/rootStore';
import { isDanubeCompanyWithLoyaltyEnabled } from 'store/rewards/selectors';
import {
  getChockStoneDomain,
  getKatanaKey,
  getMerchantNumbersMap,
  getPublicSitesDomain,
  getSelectedLocations,
  getUserLocations
} from 'store/settings';
import { getPagePath } from 'services/navigation';
import { getIsContactsWithoutLocations } from 'store/locations';
import { getStaticTemplates } from 'shared-components/EmailTemplateEditor/StaticTemplates';
import { ApiGateway } from 'services/apiGateway';
import { generateUID } from 'services/utils';
import { showApiError } from 'store/errors/utils';
import { getAccountName } from 'store/settings/selectors';
import { emailTemplateEditorActions, templateEditorSelector } from '../emailTemplateEditor';
import { getCustomerTemplates, ServerTemplate } from './index';
import { getEmSettings, isDanubeCompany } from '../settings';
import { getContactsCount, getDefaultTemplateName, getDefaultCampaignName, getTemplateDataForSave } from '../utils';
import { getActiveContacts } from '../contacts';
import { campaignEditorActions } from './actions';
import { convertServerTemplates } from './modifiers';

function* selectTemplate({ payload }: ReturnType<typeof campaignEditorActions.selectStaticTemplate>) {
  const templateId = payload;
  const staticTemplates = convertServerTemplates(getStaticTemplates());

  const template = staticTemplates.find((item: ServerTemplate) => item.id === templateId);

  if (template) {
    yield put(emailTemplateEditorActions.setTemplate(template));
  }
}

function* fetchStaticTemplates() {
  try {
    const store = yield select();
    const editor = templateEditorSelector(store);
    const modifiedResponse = convertServerTemplates(getStaticTemplates());
    if (!editor.id) {
      const template = get(modifiedResponse, '[0]', {});
      yield put(emailTemplateEditorActions.setTemplate(template));
    }
    yield put(campaignEditorActions.fetchStaticTemplates.success(modifiedResponse));
  } catch (e) {
    console.error(e);
    yield put(campaignEditorActions.fetchStaticTemplates.failure());
  }
}

function* fetchCustomerTemplates() {
  try {
    const briefList = yield call(ApiGateway.fetchCustomerTemplatesBriefList);
    const templatesMap: any = {};

    briefList.forEach((template: any) => {
      templatesMap[template.id] = {
        ...template,
        name: decodeURIComponent(template.name)
      };
    });

    const templatesKeys = Object.keys(templatesMap).slice(0, 30);
    const previews = yield call(ApiGateway.fetchCustomerTemplatesPreviews, { idList: templatesKeys });

    previews.forEach((preview: any) => {
      templatesMap[preview.id].thumb = `data:image/png;base64, ${preview.img}`;
    });

    yield put(campaignEditorActions.fetchCustomerTemplates.success(Object.values(templatesMap)));
  } catch (e) {
    console.error(e);
    yield put(campaignEditorActions.fetchCustomerTemplates.failure());
  }
}

function* saveTemplate({ payload }: any) {
  try {
    const state = yield select();
    const templateEditor = templateEditorSelector(state);
    const templateForSave = payload.id && templateEditor?.id !== payload.id ? payload : templateEditor;
    const emSettings = getEmSettings(state);
    const isDanubeWithLoyalty = isDanubeCompanyWithLoyaltyEnabled(state);
    const isDanubeAccount = isDanubeCompany(state);
    const accountName = getAccountName(state);
    const templateName = payload.name || payload;
    const katanaKey = getKatanaKey(state);
    const publicSitesDomain = getPublicSitesDomain(state);
    const chockStoneDomain = getChockStoneDomain(state);

    const { templateId, crmTemplateId } = templateForSave;

    const data = yield getTemplateDataForSave(
      templateForSave,
      emSettings,
      templateName,
      emailTemplateEditorActions.updateBox,
      isDanubeWithLoyalty,
      isDanubeAccount,
      accountName,
      katanaKey,
      publicSitesDomain,
      chockStoneDomain
    );

    const id: string = templateId || generateUID();
    const crmId: string | boolean = crmTemplateId || false;

    const savedTemplate = yield call(ApiGateway.saveTemplate, { id, crmId, data, withLoyalty: isDanubeWithLoyalty });
    yield fetchCustomerTemplates();
    yield put(campaignEditorActions.saveCustomerTemplate.success());

    return savedTemplate;
  } catch (e) {
    console.error(e);
    yield put(campaignEditorActions.saveCustomerTemplate.failure());
    yield put(
      notificationsActions.addNotification({
        type: 'toast',
        title: i18next.t('campaign_Test_Email_Error', 'Error!', { ns: 'TestCampaignEmail' }),
        text: i18next.t('campaign_Test_Email_Error_Message', 'Something went wrong. Please try again later.', {
          ns: 'TestCampaignEmail'
        }),
        appearance: NotificationAppearance.error
      })
    );
  }

  return null;
}

function* deleteCustomerTemplate({ payload }: ReturnType<typeof campaignEditorActions.deleteCustomerTemplate.request>) {
  try {
    const { status, companyNames } = yield call(ApiGateway.checkIfTemplateLinkedToMessage, payload);

    if (status) {
      yield put(campaignEditorActions.storeLinkedToTemplateCompanies(companyNames));
    } else {
      const response = yield call(ApiGateway.deleteCustomerTemplate, payload);
      const { id: deletedTemplateId } = response;
      const customerTemplates = getCustomerTemplates(yield select());
      yield put(
        campaignEditorActions.deleteCustomerTemplate.success(
          customerTemplates.filter(template => template.id !== deletedTemplateId)
        )
      );
    }
  } catch (e) {
    console.error(e);
    yield put(campaignEditorActions.deleteCustomerTemplate.failure());
  }
}

function* selectCustomerTemplate({ payload }: any) {
  try {
    const response = yield call(ApiGateway.selectCustomerTemplate, payload);
    const template = get(response, '[0]', {});
    yield put(emailTemplateEditorActions.setTemplate(template));
    yield put(campaignEditorActions.selectCustomerTemplate.success());
  } catch (e) {
    console.error(e);
    yield put(campaignEditorActions.selectCustomerTemplate.failure());
  }
}

function* sendCampaignToCustomEmail({
  payload
}: ReturnType<typeof campaignEditorActions.sendCampaignToCustomEmail.request>) {
  const customOverlayStyles = 'z-index: 10000; top:5px; background: transparent;';
  try {
    const { email, template } = payload;
    const state = yield select();
    const selectedLocation = getUserLocations(state)[0];
    const { timezone } = selectedLocation;
    if (!email) return;

    const name = getDefaultCampaignName();
    const { crmTemplateID } = yield saveTemplate({ payload: { ...template, name } });

    const data = {
      contactEmail: email,
      crmTemplateID,
      name
    };

    yield call(ApiGateway.sendTestCampaign, { data, timezone });
    yield put(campaignEditorActions.sendCampaignToCustomEmail.success());
    yield put(
      notificationsActions.addNotification({
        type: 'toast',
        title: i18next.t('campaign_Test_Email_Success', 'Success!', { ns: 'TestCampaignEmail' }),
        text: i18next.t('campaign_Test_Email_Success_Message', 'The test email was successfully sent.', {
          ns: 'TestCampaignEmail'
        }),
        customOverlayStyles,
        appearance: NotificationAppearance.success
      })
    );
  } catch (e) {
    console.error(e);
    const errorType = e.response.data.type;
    yield put(campaignEditorActions.sendCampaignToCustomEmail.failure(e.response.data.type));

    if (!errorType) {
      yield put(
        notificationsActions.addNotification({
          type: 'toast',
          title: i18next.t('campaign_Test_Email_Error', 'Error!', { ns: 'TestCampaignEmail' }),
          text: i18next.t('campaign_Test_Email_Error_Message', 'Something went wrong. Please try again later.', {
            ns: 'TestCampaignEmail'
          }),
          appearance: NotificationAppearance.error,
          customOverlayStyles
        })
      );
    }
  }
}

function* createCampaign({ payload }: any) {
  try {
    const { checkedContacts = [], selectAllMode, name, date, userTimezone, crmCampaignId, memberTypes } = payload;

    const state = yield select();
    const userLocation = getUserLocations(state)[0];
    const selectedLocations = getSelectedLocations(state);
    const merchantNumbersMap = getMerchantNumbersMap(state);
    const contacts = getActiveContacts(state);
    const includeContactsWithoutLocations = getIsContactsWithoutLocations(state);
    const { timezone } = userLocation;

    const locations = selectedLocations.map(location => merchantNumbersMap[location]);

    const savedTemplate = yield saveTemplate({ payload: getDefaultTemplateName() });
    if (!savedTemplate) throw Error('Error while saving Email Template');

    const { crmTemplateID } = savedTemplate;

    const data = {
      contactCount: getContactsCount(contacts.overallCount, checkedContacts.length, selectAllMode),
      contacts: checkedContacts.map((contactId: number) => ({ ID: contactId })),
      crmTemplateID,
      date,
      isContactsInverted: selectAllMode,
      name,
      noteTitle: `${NOTE_TITLE} ${name}`,
      userTimezone,
      locations,
      includeContactsWithoutLocations,
      memberTypes,
      campaignId: `${crmCampaignId}`
    };

    // Delete old campaign and DayBookNote if editing
    if (crmCampaignId !== -1) {
      yield call(ApiGateway.deleteDayBookNoteByCampaignId, { campaignId: crmCampaignId });
      yield call(ApiGateway.deleteEmCampaign, crmCampaignId);
    }
    yield call(ApiGateway.createCampaign, { data, timezone });

    yield put(campaignEditorActions.createCampaign.success());
    history.push(`${getPagePath(getMenuPages(state), 'emailMarketing_root', 'emailMarketing_campaigns')}`);

    const textTranslation: { key: string; defaultValue: string } =
      crmCampaignId === -1
        ? { key: 'contacts_CreateCampaignMessage', defaultValue: 'Campaign was scheduled successfully' }
        : { key: 'contacts_UpdateCampaignMessage', defaultValue: 'The campaign was successfully update' };

    yield put(
      notificationsActions.addNotification({
        type: 'toast',
        title: i18next.t('contacts_SuccessLabel', 'Success!'),
        text: i18next.t(textTranslation.key, textTranslation.defaultValue)
      })
    );
  } catch (e) {
    console.error(e);
    yield put(campaignEditorActions.createCampaign.failure());
  }
}

function* getCustomerTemplatePreview({
  payload
}: ReturnType<typeof campaignEditorActions.getCustomerTemplatePreview.request>) {
  try {
    const response = yield call(ApiGateway.selectCustomerTemplate, payload);
    const template: ServerTemplate = get(response, '[0]', {});

    yield put(campaignEditorActions.getCustomerTemplatePreview.success(template));
  } catch (e) {
    yield put(campaignEditorActions.getCustomerTemplatePreview.failure());
  }
}

export function* campaignEditorSagas() {
  yield takeEvery(getType(campaignEditorActions.selectStaticTemplate), selectTemplate);
  yield takeLatest(campaignEditorActions.fetchStaticTemplates.request, fetchStaticTemplates);
  yield takeLatest(campaignEditorActions.fetchCustomerTemplates.request, fetchCustomerTemplates);
  yield takeLatest(campaignEditorActions.getCustomerTemplatePreview.request, getCustomerTemplatePreview);
  yield takeLatest(campaignEditorActions.saveCustomerTemplate.request, saveTemplate);
  yield takeLatest(campaignEditorActions.deleteCustomerTemplate.request, deleteCustomerTemplate);
  yield takeLatest(campaignEditorActions.selectCustomerTemplate.request, selectCustomerTemplate);
  yield takeLatest(campaignEditorActions.sendCampaignToCustomEmail.request, sendCampaignToCustomEmail);
  yield takeLatest(campaignEditorActions.createCampaign.request, createCampaign);
  yield takeLatest(
    [
      campaignEditorActions.fetchStaticTemplates.failure,
      campaignEditorActions.deleteCustomerTemplate.failure,
      campaignEditorActions.selectCustomerTemplate.failure,
      campaignEditorActions.fetchCustomerTemplates.failure,
      campaignEditorActions.getCustomerTemplatePreview.failure
    ],
    showApiError
  );
}
