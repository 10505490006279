import moment, { Moment } from 'moment';
import { ActionType, createAction, getType } from 'typesafe-actions';
import { AppState } from 'store/rootReducer';

export interface DateRangeState {
  startDate: Moment;
  endDate: Moment;
}

export interface DateRangePickerState {
  startDate: Moment;
  endDate: Moment;
  endPredictiveDate?: Moment | null;
  isDropdownOpen: boolean;
}

const defaultState: DateRangePickerState = {
  endDate: moment().subtract(1, 'days'),
  startDate: moment().subtract(14, 'days'),
  isDropdownOpen: false
};

export const setDateRange = createAction(
  '@dateRangeSelector/SET_DATE_RANGE',
  action => (startDate: Moment, endDate: Moment, endPredictiveDate?: Moment | null) =>
    action({ startDate, endDate, endPredictiveDate })
);

export const setDateRangeDropdownState = createAction(
  '@dateRangeSelector/SET_DATE_RANGE_DROPDOWN_STATE',
  action => (isOpen: boolean) => action(isOpen)
);

const dateRangePickerActions = {
  setDateRange,
  setDateRangeDropdownState
};

type dateRangeActionTypes = ActionType<typeof dateRangePickerActions>;

export const dateRangePickerReducer = (state: DateRangePickerState = defaultState, action: dateRangeActionTypes) => {
  switch (action.type) {
    case getType(setDateRange):
      if (!action.payload.endPredictiveDate) {
        return { ...state, ...{ startDate: action.payload.startDate, endDate: action.payload.endDate } };
      }
      return { ...state, ...action.payload };
    case getType(setDateRangeDropdownState):
      return {
        ...state,
        isDropdownOpen: action.payload
      };
    default:
      return state;
  }
};

interface TimeFormats {
  formatStartDate: string;
  formatEndDate: string;
}

const defaultTimeFormats: TimeFormats = {
  formatStartDate: 'YYYY-MM-DD 00:00:00',
  formatEndDate: 'YYYY-MM-DD 23:59:59'
};

export const getDateRange = (state: AppState): DateRangePickerState => state.dateRange;
export const getDateRangeDropdownOpenStatus = (state: AppState): boolean => state.dateRange.isDropdownOpen;

export const getStartEndDates = (
  state: AppState,
  { formatStartDate, formatEndDate }: TimeFormats = defaultTimeFormats
): { start: string; end: string } => {
  const range = getDateRange(state);
  return { start: range.startDate.format(formatStartDate), end: range.endDate.format(formatEndDate) };
};

export const getDateRangeDuration = (state: AppState): number => {
  const dateRange = getDateRange(state);
  return moment.duration(dateRange.endDate.diff(dateRange.startDate)).asDays();
};

export const getDateRangeGroupBy = (state: AppState): 'day' | 'hour' =>
  getDateRangeDuration(state) >= 3 ? 'day' : 'hour';
