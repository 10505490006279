import { createAction, createAsyncAction } from 'typesafe-actions';
import { SearchMerchantsPayload, MerchantData } from './types';

export const searchActions = createAsyncAction(
  '@supportSearch/search/request',
  '@supportSearch/search/success',
  '@supportSearch/search/error'
)<SearchMerchantsPayload, MerchantData[], void>();

export const getJWTForMerchantList = createAsyncAction(
  '@supportSearch/getJWT/request',
  '@supportSearch/getJWT/success',
  '@supportSearch/getJWT/error'
)<string[], string, void>();

const clearAllFields = createAction('@supportSearch/fields/clear', action => () => action());

export const supportSearchActions = {
  searchActions,
  getJWTForMerchantList,
  clearAllFields
};
