import { combineReducers } from 'redux';
import { identityMappingReducer } from './identityMapping/reducer';
import { scoreReducer } from './score/reducer';
import { reviewsReducer } from './reviews/reducer';
import { reviewsSummaryReducer } from './reviewsSummary/reducer';
import { reviewsTrendReducer } from './reviewsTrend/reducer';
import { transactionsTrendReducer } from './transactionsTrend/reducer';
import { competitorsReducer } from './competitors/reducer';
import { compareReducer } from './compare/reducer';
import { socialPlatformsSettingsReducer } from './socialPlatformsSettings/reducer';
import { facebookConnectReducer } from './facebookConnect/reducer';

export default combineReducers({
  identityMapping: identityMappingReducer,
  socialPlatformsSettings: socialPlatformsSettingsReducer,
  score: scoreReducer,
  reviews: reviewsReducer,
  reviewsSummary: reviewsSummaryReducer,
  reviewsTrend: reviewsTrendReducer,
  transactionsTrend: transactionsTrendReducer,
  competitors: competitorsReducer,
  compare: compareReducer,
  facebookConnect: facebookConnectReducer
});
