/* eslint-disable */
/* Return СZ Static Automations Templates */

const AlmostThereEmailData = {
  styles: {
    width: '600px',
    color: '#949599',
    backgroundColor: '#fff',
    couponBorderColor: '#fff',
    buttonsDividersColor: '#fff',
    sicailLinksColor: '#fff',
  },
  components: [
    {
      type: 'spacer',
    },
    {
      type: 'image',
      image: 'em-email-images/CZ/GPcz_550x300_19.+Almost+There+Template_CZ.jpg',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<h1 style="font-style: italic; color: #949599;">Už to skoro máte!</h1>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<p style="color: #949599; font-weight: bold;">Gratulujeme! Od získání další odměny vás dělí už jen jedna návštěva/položka. Doufáme, že se brzy uvidíme.</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const AnniversaryEmailData = {
  styles: {
    width: '600px',
    color: '#949599',
    backgroundColor: '#fff',
    couponBorderColor: '#fff',
    buttonsDividersColor: '#fff',
    sicailLinksColor: '#fff',
  },
  components: [
    {
      type: 'spacer',
    },
    {
      type: 'image',
      image: 'em-email-images/CZ/GPcz_550x300_21.+Happy+Anniversary+Template_CZ.jpg',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<h1 style="font-style: italic; color: #949599;">Děkujeme za úžasný rok!</h1>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<p style="color: #949599; font-weight: bold;">Čas letí, když se bavíte. Jsme šťastní, že jste součástí naší rodiny. Těšíme se na brzké shledání!</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const RewardEmailData = {
  styles: {
    width: '600px',
    color: '#949599',
    backgroundColor: '#fff',
    couponBorderColor: '#fff',
    buttonsDividersColor: '#fff',
    sicailLinksColor: '#fff',
  },
  components: [
    {
      type: 'spacer',
    },
    {
      type: 'image',
      image: 'em-email-images/CZ/GPcz_550x300_22.+Reward+Template_CZ.jpg',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<h1 style="font-style: italic; color: #949599;">Získali jste odměnu!</h1>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<p style="color: #949599; font-weight: bold;">Gratulujeme! Zasloužíte si to. Těšíme se na brzké shledání.</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const WelcomeEmailData = {
  styles: {
    width: '600px',
    color: '#949599',
    backgroundColor: '#fff',
    couponBorderColor: '#fff',
    buttonsDividersColor: '#fff',
    sicailLinksColor: '#fff',
  },
  components: [
    {
      type: 'spacer',
    },
    {
      type: 'image',
      image: 'em-email-images/CZ/GPcz_550x300_18.+Welcome+Template_CZ.jpg',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<h1 style="font-style: italic; color: #949599;">Děkujeme, že jste se k nám připojili!</h1>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<p style="color: #949599; font-weight: bold;">Nyní budete dostávat naše nejnovější nabídky přímo do vaší e-mailové schránky. Těšíme se navidenou!</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const WeMissYouEmailData = {
  styles: {
    width: '600px',
    color: '#949599',
    backgroundColor: '#fff',
    couponBorderColor: '#fff',
    buttonsDividersColor: '#fff',
    sicailLinksColor: '#fff',
  },
  components: [
    {
      type: 'spacer',
    },
    {
      type: 'image',
      image: 'em-email-images/CZ/GPcz_550x300_20.+We+Miss+You+Template_CZ.jpg',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<h1 style="font-style: italic; color: #949599;">Jak se vám daří?</h1>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<p style="color: #949599; font-weight: bold;">Zdá se to být věčnost, co jsme vás naposledy viděli. Chtěli jsme vás jen pozdravit a doufáme, že nás brzy opět navštívíte.</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

export const czStaticTemplates = [
  {
    id: 'y2e53d1a5962crog4nob',
    promoId: 'almost_reward',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticAutomationTemplateAlmostThereTitle',
    status: 'origin',
    favorites: 'false',
    components: AlmostThereEmailData.components,
    styles: AlmostThereEmailData.styles,
    thumb: 'almost-there-email-preview-cz.jpg',
  },
  {
    id: 'raa9x0x30efpqshhlwh7',
    promoId: 'anniversary',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticAutomationTemplateAnniversaryTitle',
    status: 'origin',
    favorites: 'false',
    components: AnniversaryEmailData.components,
    styles: AnniversaryEmailData.styles,
    thumb: 'anniversary-email-preview-cz.jpg',
  },
  {
    id: 'ekv8rh7io2mp2wd8lnzi',
    promoId: 'loyalty_reward',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticAutomationTemplateRewardTitle',
    status: 'origin',
    favorites: 'false',
    components: RewardEmailData.components,
    styles: RewardEmailData.styles,
    thumb: 'reward-email-preview-cz.jpg',
  },
  {
    id: 'as3o8rj4l4binx96hpkd',
    promoId: 'miss_you',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticAutomationTemplateWeMissYouTitle',
    status: 'origin',
    favorites: 'false',
    components: WeMissYouEmailData.components,
    styles: WeMissYouEmailData.styles,
    thumb: 'we-miss-you-email-preview-cz.jpg',
  },
  {
    id: 'y17zayrntq67k38pvp3o',
    promoId: 'welcome',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticAutomationTemplateWelcomeTitle',
    status: 'origin',
    favorites: 'false',
    components: WelcomeEmailData.components,
    styles: WelcomeEmailData.styles,
    thumb: 'welcome-email-preview-cz.jpg',
  },
];



