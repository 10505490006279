import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { getColor } from '../layout/theme';
import { ReactComponent as CheckedIcon } from '../assets/icons/checked.svg';

interface CheckboxInterface {
  size?: number;
  borderWidth?: number;
  disabled?: boolean;
  colorInverted?: boolean;
  notAccepted?: boolean;
  id?: string;
}

const getSize = (props: CheckboxInterface): number => props.size || 25;
const getBorderWidth = (props: CheckboxInterface): number => props.borderWidth || 2;

const getBorder = (props: CheckboxInterface): string => {
  const disabledColor = !props.colorInverted ? 'mediumGrey' : 'blueGrey';
  const activeColor = !props.colorInverted ? 'color1' : 'white';
  return getColor(props, props.disabled ? disabledColor : activeColor);
};

export const CheckboxContainer = styled.label<CheckboxInterface>`
  ${props => `
    border: ${getBorderWidth(props)}px solid ${props.notAccepted ? getColor(props, 'red') : getBorder(props)};
    ${props.disabled && props.colorInverted ? `background-color: ${getColor(props, 'blueGrey')};` : ''};
    display: inline-block;
    width: ${getSize(props)}px;
    min-width: ${getSize(props)}px;
    height: ${getSize(props)}px;
    position: relative;
    cursor: pointer;
    div{
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0;
      background: ${getColor(props, 'white')};
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        position: absolute;
        top:0;
        bottom:0;
        left:0;
        right:0;
        margin:auto;
        fill: ${getColor(props, !props.colorInverted ? 'white' : 'color1')};
        opacity: 0;
        max-height: ${getSize(props) / 1.5}px;
        max-width: ${getSize(props) / 1.5}px;
      }
    }
    input{
      display: none;
      &:checked+div{
        background: ${getColor(props, !props.colorInverted ? 'color1' : 'white')};
        svg {
          opacity: 1;
        }
      }
      &:disabled+div{
        opacity: .7;
        cursor: not-allowed;
        background: ${getColor(props, 'blueGrey')};
      }
    }
  `};
`;

CheckboxContainer.displayName = 'CheckboxContainer';

export type CheckboxType = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> &
  CheckboxInterface;

export const Checkbox = (props: CheckboxType): ReactElement => {
  const { size, borderWidth, disabled, colorInverted, notAccepted, children, id, ...rest } = props;

  return (
    <CheckboxContainer
      notAccepted={notAccepted}
      disabled={disabled}
      size={size}
      borderWidth={borderWidth}
      colorInverted={colorInverted}
    >
      <input {...rest} disabled={disabled} type="checkbox" id={id} />
      <div>
        <CheckedIcon />
      </div>
    </CheckboxContainer>
  );
};
