import { ActionType, createReducer } from 'typesafe-actions';
import { facebookConnectActions } from './actions';
import { FacebookConnectState } from './types';

const initialState: FacebookConnectState = {
  loading: false,
  data: {}
};

export const facebookConnectReducer = createReducer<FacebookConnectState, ActionType<typeof facebookConnectActions>>(
  initialState
)
  .handleAction(facebookConnectActions.fetchFacebookConnectInfo.request, state => ({ ...state, loading: true }))
  .handleAction(facebookConnectActions.fetchFacebookConnectInfo.success, (state, action) => ({
    ...state,
    loading: false,
    data: {
      ...state.data,
      listingReviewId: action.payload.listingReviewId,
      tokenExpiresAt: action.payload.expiresAt,
      visibilityPageUrl: action.payload.url
    }
  }))
  .handleAction(facebookConnectActions.fetchFacebookConnectInfo.failure, state => ({ ...state, loading: false }))
  .handleAction(facebookConnectActions.reset, () => initialState);
