import i18n from 'i18n';
import { AppState } from '../rootReducer';
import { FooterLink, BrandingState, BrandingLayout } from './types';
import { Page } from '../settings/layout';
import { getApphubBackOfficeUrl } from '../../utils/apphubUtils';
import { formatString } from '../../services/utils';
import { getLocatedFooterLink } from './modifiers';

export const getBrandTitle = (state: AppState): string => state.branding.appTitle || '';
export const getFooterLogoStyles = (state: AppState): string => state.branding.footerLogo?.styles || '';
export const getHeaderLogoUrl = (state: AppState): string => state.branding.headerLogo?.url || '';
export const getHeaderLogoAltText = (state: AppState): string => state.branding.headerLogo?.altText || '';
export const getHeaderLogoStyles = (state: AppState): string => state.branding.headerLogo?.styles || '';
export const getHeaderLogoMobileStyles = (state: AppState): string => state.branding.headerLogo?.mobileStyles || '';
export const getHeaderLogoMobileUrl = (state: AppState): string => state.branding.headerLogo?.mobileUrl || '';
export const getCopyright = (state: AppState): string[] =>
  state.branding.copyright
    ? state.branding.copyright.map(item => formatString(i18n.t(item), new Date().getFullYear()).join(' '))
    : [];
export const getFooterLinks = (state: AppState, locale: string): FooterLink[] =>
  (state.branding.footerLinks || []).map((el: FooterLink) => ({
    url: getLocatedFooterLink(locale, el.url),
    text: i18n.t(el.text)
  }));
export const getPortalName = (state: AppState): string => state.branding.portal?.title || '';
export const getPortalPageItem = ({ branding, settings }: AppState): Page | false => {
  const {
    config: { isAppHubPartner }
  } = settings;

  if (branding.portal?.icon) {
    const portalUrl = isAppHubPartner ? getApphubBackOfficeUrl() : branding.portal.url;
    if (isAppHubPartner && !portalUrl) return false;

    return {
      pageId: '',
      enabled: true,
      translationKey: branding.portal.title,
      topMenuIcon: branding.portal.icon,
      pageName: 'portal',
      title: i18n.t(branding.portal.title),
      path: portalUrl,
      elements: [],
      subPages: [],
      locationSelector: false,
      dateRangeSelector: false
    };
  }

  return false;
};

export const getFooterLogo = (state: AppState): string => state.branding.footerLogo.url || '';
export const getFooterLogoAltText = (state: AppState): string => state.branding.footerLogo.altText || '';
export const getSupportContactNumber = (state: AppState): string => state.branding.supportNumber || '';
export const getLogoutUrl = (state: AppState): string => state.branding.portal.logoutUrl || '';
export const getPortalUrl = (state: AppState): string => state.branding.portal.url || '';
export const getAppHubTray = (state: AppState): BrandingState['appHubTray'] => state.branding.appHubTray;
export const isAppHubTrayEnabled = (state: AppState): boolean => Boolean(state.branding.appHubTray?.baseUrlEnv);
export const getBrandingLayout = (state: AppState): BrandingLayout | undefined => state.branding.layout;
export const getPreventSessionExpirationAutoRedirect = (state: AppState): boolean | undefined =>
  state.branding.errorPages?.['403']?.preventSessionExpirationAutoRedirect;
export const getContactNumber = (state: AppState): string => state.branding.contactNumber || '';
export const getContactEmail = (state: AppState): string => state.branding.contactEmail || '';
