import { createAsyncAction, createAction } from 'typesafe-actions';
import { ComparisonTableOptions } from 'store/transactions';
import { MarketingScoreCompareData, ReviewsCompareResponse, ReceivedAtDate } from './types';

const fetchCompareData = createAsyncAction(
  '@merchantCentric/compare/fetch',
  '@merchantCentric/compare/success',
  '@merchantCentric/compare/error'
)<void, MarketingScoreCompareData[], Error>();

const fetchReviewsCompareData = createAsyncAction(
  '@merchantCentric/reviewsCompare/fetch',
  '@merchantCentric/reviewsCompare/success',
  '@merchantCentric/reviewsCompare/error'
)<void, ReviewsCompareResponse[], Error>();

const setComparisonTableOptions = createAction(
  '@merchantCentric/compare/setComparisonTableOptions',
  action => (payload: ComparisonTableOptions) => action(payload)
);

const setReceivedAtDates = createAction(
  '@merchantCentric/compare/setReceivedAtDates',
  action => (payload: ReceivedAtDate[]) => action(payload)
);

export const compareActions = {
  fetchCompareData,
  fetchReviewsCompareData,
  setComparisonTableOptions,
  setReceivedAtDates
};
