import { isEmpty } from 'lodash';
import { delay, put, select } from '@redux-saga/core/effects';
import i18n from 'i18n';
import { ApiGateway } from 'services/apiGateway';
import { formatString } from 'services/utils';
import { CONTACTS_PER_PAGE, DEFAULT_SORT_ORDER } from 'appConstants/emailMarketing';
import { notificationsActions } from 'store/notifications';
import { getIsContactsWithoutLocations } from 'store/locations';
import { getMerchantNumbersMap, getSelectedLocations } from 'store/settings';
import { activeContactsActions } from './actions';
import { getActiveContacts } from '../selectors';
import { getEmCustomerSettings } from '../../settings';
import { backgroundJobActions } from '../backgroundJob';
import { fetchContacts } from '../../utils';

const fetchContactsFirstPagePayload = {
  keyword: '',
  page: 1,
  sort: DEFAULT_SORT_ORDER
};

function* checkDeletionStatus(jobNo: string): any {
  yield delay(1000);
  const response = yield ApiGateway.checkDeletionJobStatus(jobNo);

  if (!response) throw Error('Error while checking deletion job status');
  const { status } = response;

  if (status === 'done') return response;

  return yield checkDeletionStatus(jobNo);
}

function* checkImportStatus(jobNo: string): any {
  yield delay(1000);
  const response = yield ApiGateway.checkImportStatus(jobNo);
  if (!response) throw Error('Error while checking import job status');

  const { totalRemaining, totalComplete, totalFileSize } = response;

  const title = i18n.t('contacts_SuccessLabel', 'Success!');
  const body = formatString(
    i18n.t('contacts_ImportJobMessage', "We're busy importing{0}contact(s). This could take a few minutes."),
    totalFileSize - totalComplete
  ).join(' ');
  const name = i18n.t('emailMarketingContactsBackgroundJobImporting', 'importing');

  const jobStatus = {
    working: true,
    done: false,
    title,
    body,
    name
  };

  yield put(backgroundJobActions.update(jobStatus));

  if (totalRemaining === 0) return response;

  return yield checkImportStatus(jobNo);
}

export function* fetchActiveContacts({ payload }: any) {
  const state = getActiveContacts(yield select());

  yield fetchContacts(payload, state, ApiGateway.fetchContacts, {
    success: activeContactsActions.fetchContacts.success,
    failure: activeContactsActions.fetchContacts.failure,
    setList: activeContactsActions.setContactsList
  });
}

export function* createContact({ payload }: ReturnType<typeof activeContactsActions.createContact.request>) {
  try {
    const state = yield select();
    const settings = getEmCustomerSettings(state);
    const { firstName = 'F_Name', lastName = 'L_Name', email, birthday, location } = payload;

    if (!settings || !settings.email) {
      console.error('Error at createContact saga: Customer email is not defined ');
      return;
    }

    if (email === settings.email) {
      yield put(activeContactsActions.setEmailExistsAsCompanyEmailModalState(true));
      yield put(activeContactsActions.setEmailExistsAsCompanyEmailError());
      return;
    }

    const response = yield ApiGateway.createContact(firstName, lastName, email, birthday, location);
    const duplicateError = i18n.t(
      'emailDuplicateValidationError',
      'Sorry, a contact with this email address already exists in your email marketing program.'
    );

    if (response.duplicates && response.duplicates.length) {
      yield put(activeContactsActions.setValidationError('email', duplicateError));
      return;
    }

    yield put(activeContactsActions.createContact.success());
    yield put(
      notificationsActions.addNotification({
        type: 'toast',
        title: i18n.t('contacts_SuccessLabel', 'Success!'),
        text: i18n.t('contacts_SaveContactMessage', 'Contact was saved successfully')
      })
    );
  } catch (e) {
    console.log('Error at createContact saga: ', e);
    yield put(activeContactsActions.createContact.failure());
  }
}

export function* deleteContact({ payload }: ReturnType<typeof activeContactsActions.deleteContact.request>) {
  try {
    const state = yield select();
    const contacts = getActiveContacts(state);
    const includeContactsWithoutLocations = getIsContactsWithoutLocations(state);
    const selectedLocations = getSelectedLocations(state);
    const merchantNumbersMap = getMerchantNumbersMap(state);
    const limit = contacts.limit || CONTACTS_PER_PAGE;

    const locations = selectedLocations.map(location => merchantNumbersMap[location]);

    const { isCheckedAllMode, list, selectedCount, keyword = '' } = payload;

    const jobName = i18n.t('emailMarketingContactsBackgroundJobRemoval', 'removal');

    const hasError = (result: any = {}) => {
      const { success, message } = result;
      // throw error only if response has not timeout error
      if (!success && typeof message === 'string') return message.indexOf('ESOCKETTIMEDOUT') === -1;
      return false;
    };

    // Showing progress bar
    const step1 = {
      title: i18n.t('contacts_SuccessLabel', 'Success!'),
      body: formatString(
        i18n.t('contacts_DeletingJobMessage', "We're busy deleting {0} contact(s). This could take a few minutes."),
        selectedCount
      ).join(''),
      name: jobName
    };

    yield put(backgroundJobActions.update({ working: true, done: false, ...step1 }));

    const response = yield ApiGateway.deleteList(
      isCheckedAllMode,
      list,
      contacts.overallCount,
      locations,
      includeContactsWithoutLocations
    );

    const { deletionJobId } = response;

    if (deletionJobId) {
      const { result } = yield checkDeletionStatus(deletionJobId);
      const parsedResult = JSON.parse(result);
      const resultHasErrors = parsedResult.reduce((acc: boolean, chunk: any) => acc || hasError(chunk), false);

      if (resultHasErrors) {
        // Showing error modal
        const stepError = {
          title: i18n.t('contacts_DeletingWarningTitle', 'Removal Warning'),
          body: i18n.t('contacts_DeletingWarning', 'Some contacts were not deleted'),
          name: jobName,
          type: 'warning'
        };

        yield put(
          backgroundJobActions.update({
            working: false,
            done: true,
            ...stepError
          })
        );
        return;
      }
    }

    // Showing success modal
    const step2Body = formatString(
      selectedCount > 1
        ? i18n.t('contacts_MultipleDeletionJobSuccessBody', '{0} contacts were deleted successfully.')
        : i18n.t('contacts_SingleDeletionJobSuccessBody', '{0} contact was deleted successfully.'),
      selectedCount
    ).join('');

    const step2 = {
      title: i18n.t('contacts_DeletingJobSuccessTitle', 'Success!'),
      body: step2Body,
      name: jobName,
      type: 'success'
    };

    yield put(activeContactsActions.fetchContacts.request({ ...fetchContactsFirstPagePayload, keyword, limit }));
    yield put(
      backgroundJobActions.update({
        working: false,
        done: true,
        ...step2
      })
    );
  } catch (e) {
    console.log('Error at deleteContact saga: ', e);
    yield put(activeContactsActions.deleteContact.failure());
    yield put(backgroundJobActions.reset());
  }
}

export function* updateContact({ payload }: ReturnType<typeof activeContactsActions.updateContact.request>) {
  try {
    const { firstName, lastName, email, birthday, id, location } = payload;

    const state = yield select();
    const contacts = getActiveContacts(state);

    const { data = [] } = contacts;

    const response = yield ApiGateway.updateContact(firstName, lastName, email, id, birthday, location);
    const duplicateError = i18n.t(
      'emailDuplicateValidationError',
      'Sorry, a contact with this email address already exists in your email marketing program.'
    );

    if (response.duplicates && response.duplicates.length) {
      yield put(activeContactsActions.setValidationError('email', duplicateError));
      return;
    }

    const dataCopy = [...data];
    const updatedRecordIndex = dataCopy.findIndex((record: any) => record.id === response.id);

    if (updatedRecordIndex === -1) {
      console.log('Updated ID is not found', response.id);
      return;
    }

    const updatedRecord = dataCopy[updatedRecordIndex];

    dataCopy.splice(updatedRecordIndex, 1, {
      ...updatedRecord,
      first_name: firstName,
      last_name: lastName,
      email,
      birthday,
      location_id: location
    });

    yield put(activeContactsActions.setContactsList(dataCopy));
    yield put(activeContactsActions.updateContact.success());

    yield put(
      notificationsActions.addNotification({
        type: 'toast',
        title: i18n.t('contacts_SuccessLabel', 'Success!'),
        text: i18n.t('contacts_SaveContactMessage', 'Contact was saved successfully')
      })
    );
  } catch (e) {
    console.log('Error at updateContact saga: ', e);
    yield put(activeContactsActions.updateContact.failure());
  }
}

export function* importContacts({
  payload
}: ReturnType<typeof activeContactsActions.importContacts.request>): IterableIterator<any> {
  try {
    const jobName = i18n.t('emailMarketingContactsBackgroundJobImporting', 'importing');
    const { file, location, ageLimit = 0 } = payload;

    const data = new FormData();
    data.append('contacts', file, file.name);

    // Showing progress bar
    const step1 = {
      title: i18n.t('contacts_SuccessLabel', 'Success!'),
      body: formatString(
        i18n.t('contacts_ImportJobMessage', "We're busy importing{0}contact(s). This could take a few minutes."),
        ' '
      ).join(''),
      name: jobName
    };

    yield put(backgroundJobActions.update({ working: true, done: false, ...step1 }));

    const response: any = yield ApiGateway.importContacts(data, location, ageLimit);

    if (!response || !response.success) {
      const stepError = {
        title: i18n.t('contacts_ImportWarning', 'Import Warning'),
        body: response.msg,
        name: jobName,
        type: 'warning'
      };

      yield put(
        backgroundJobActions.update({
          working: false,
          done: true,
          ...stepError
        })
      );
      return;
    }

    const { jobNo } = response;

    const jobResponse: any = yield checkImportStatus(jobNo);

    if (isEmpty(jobResponse) || isEmpty(jobResponse.results)) {
      console.log('Error: jobResponse is absence or malformed');
      yield put(backgroundJobActions.reset());
      return;
    }

    const { results } = jobResponse;
    const importedContactsCount = results.reduce((acc: number, item: any) => acc + item.records, 0);

    yield put(
      backgroundJobActions.update({
        working: false,
        done: true,
        title: i18n.t('contacts_ImportJobSuccessTitle', 'Import Completed'),
        body: formatString(
          i18n.t('contacts_ImportJobSuccessBody', '{0} contact(s) were imported successfully.'),
          importedContactsCount
        ).join('')
      })
    );

    yield put(activeContactsActions.fetchContacts.request(fetchContactsFirstPagePayload));
  } catch (e) {
    console.log('Error at importContacts saga: ', e);
    yield put(activeContactsActions.importContacts.failure());
    yield put(backgroundJobActions.reset());
  }
}

export function* setActiveSortFieldAndOrder({
  payload
}: ReturnType<typeof activeContactsActions.setActiveSortFieldAndOrder>) {
  const state = yield select();
  const contacts = getActiveContacts(state);
  yield put(
    activeContactsActions.fetchContacts.request({
      page: contacts.page,
      limit: contacts.limit || CONTACTS_PER_PAGE,
      keyword: contacts.keyword,
      sort: payload
    })
  );
}
