import { get } from 'lodash';

import { keys, getLocal } from 'services/localStorage';
import { MASTERCARD, MERCHANT_CENTRIC, PAYMENT_ANALYTICS, UBERALL } from 'appConstants';
import { TermsState, AvailableTermsTypes } from 'store/terms/types';
import { AppState } from '../rootReducer';
import { defaultState } from './store';
import { isFeatureOrComponentEnabled } from '../settings';

export const getTermsState = (state: AppState): TermsState => get(state, 'terms', defaultState);
export const isPaymentAnalyticsTermsConfirmed = (state: AppState): boolean => {
  // if TermsModal component isn't enabled, we consider the terms to be accepted
  if (!isFeatureOrComponentEnabled(state, 'analytics_root', 'TermsModal')) {
    return true;
  }

  return getTermsState(state)[PAYMENT_ANALYTICS].confirmed;
};
export const isMastercardTermsConfirmed = (state: AppState): boolean => getTermsState(state)[MASTERCARD].confirmed;
export const isMerchantCentricTermsConfirmed = (state: AppState): boolean =>
  getTermsState(state)[MERCHANT_CENTRIC].confirmed;

const getHash = (locations: string[]): string =>
  locations
    .map(msk => parseInt(msk))
    .sort((a, b) => (a > b ? 1 : b > a ? -1 : 0))
    .join('');

export const getLocalKey = (locations: string[], selector: string): string => `${keys[selector]}-${getHash(locations)}`;

const isTermsConfirmed = (val: boolean | null): boolean => typeof val === 'boolean' && !val;

export const getConfirmedTermsFromLocalStorage = (locations: string[]): AvailableTermsTypes[] => {
  const localMastercardTermsKey = getLocal(getLocalKey(locations, MASTERCARD));
  const localMerchantCentricKey = getLocal(getLocalKey(locations, MERCHANT_CENTRIC));
  const localUberallKey = getLocal(getLocalKey(locations, UBERALL));
  const paymentAnalyticsKey = getLocal(getLocalKey(locations, PAYMENT_ANALYTICS));

  const confirmedTerms: AvailableTermsTypes[] = [];

  if (isTermsConfirmed(localUberallKey)) confirmedTerms.push(UBERALL);
  if (isTermsConfirmed(localMastercardTermsKey)) confirmedTerms.push(MASTERCARD);
  if (isTermsConfirmed(localMerchantCentricKey)) confirmedTerms.push(MERCHANT_CENTRIC);
  if (isTermsConfirmed(paymentAnalyticsKey)) confirmedTerms.push(PAYMENT_ANALYTICS);

  return confirmedTerms;
};
