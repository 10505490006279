import { DayPickerRangeController, FocusedInputShape } from 'react-dates';
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { FlattenInterpolation } from 'styled-components';
import { useDispatch } from 'react-redux';
import moment, { Moment } from 'moment';
import { setDateRangeDropdownState } from 'store/appDateRangePicker';
import { dateFormatter } from 'services/dateFormatter';
import { localizationService } from 'services/localization';
import { bindDocumentClickOrTouchListener } from 'services/utils';
import { baseColors, laptopSWidth } from 'appConstants';
import { LocationFor } from 'components/AdvancedAnalyticsEvaluate/types';
import { Button } from '../Button';
import {
  AdvancedDatePickerRangesContainer,
  DatePickerRangesContainer,
  DateRangeItem,
  AdvancedDateRangeItem,
  DateRangePickerContainer,
  DateRangePickerDropdownElement,
  DateRangePickerFooter,
  DateRangePickerToggle,
  ManualDateRangeInputs,
  ManualAdvancedDateRangeInputs,
  StyledP,
  PickerContent,
  ReactDatesWrapper,
  StyledCalendarIcon
} from './components';
import { DayPickerTooltip } from '../DayPickerTooltip';

export interface DateRange {
  startDate: Moment;
  endDate: Moment | null;
  endPredictiveDate?: Moment | null;
}

export interface CustomRangeInterface {
  [name: string]: DateRange;
}
export interface OnCancelPropTypes {
  setInputFocus: (arg: null) => void;
  setSelectedRange: (arg: string | undefined) => void;
}

export interface DateChangeHandlerPropTypes {
  startDate: Moment | null;
  nextStartDate: Moment | null;
  nextEndDate: Moment | null;
  setStartDate: (arg: Moment | null) => void;
  setEndDateSelected: (arg: boolean) => void;
  setEndDate: (arg: Moment | null) => void;
  setSelectedRange: (arg: string | undefined) => void;
  setInputFocus: (arg: FocusedInputShape | null) => void;
}

interface PropTypes {
  appliedStartDate: Moment;
  appliedEndDate: Moment | null;
  onApply: (startDate: Moment, endDate: Moment, rangeName: string) => void;
  onCancel: (arg: OnCancelPropTypes) => void;
  dateChangeHandler: (arg: DateChangeHandlerPropTypes) => void;
  labelColor?: string;
  monthFormat?: string;
  isTablet?: boolean;
  isMobile?: boolean;
  moveArrowLeftOnTablet?: boolean;
  isRightSide?: boolean;
  additionalStyles?: FlattenInterpolation<any>;
  locationFor?: LocationFor;
  translations?: any;
  ranges: CustomRangeInterface;
  isAdvancedCalendar?: boolean;
  windowWidth?: number;
  hideChevron?: boolean;
  appliedEndPredictiveDate?: Moment | null;
  prevBtn: () => JSX.Element;
  nextBtn: () => JSX.Element;
  checkIsOutsideRange: (
    startDate: Moment | null,
    endDate: Moment | null,
    isEndDateSelected: boolean,
    isPredictionDataEnabled: boolean
  ) => (day: Moment) => boolean;
  is30DaysPredictionData: boolean;
}

export type DatePickerBasisProps = PropTypes;

const LEFT_DROPDOWN_POSITION = 'left:0;';
const RIGHT_DROPDOWN_POSITION = 'right:0';
const DAY_TOOLTIP_TAG = 'day-tooltip';
const CANCEL_TAG = 'cancel';
const FIRST_PREDICTIVE_DATE_TAG = 'first-predictive-date';
const CALENDAR_OUTSIDE_DATE_CLASS = 'CalendarDay__outside';
const TIPPY_ROOT_SELECTOR = 'div[data-tippy-root]';

type DropDownPosition = typeof LEFT_DROPDOWN_POSITION | typeof RIGHT_DROPDOWN_POSITION;

const formatLabelDate = (date: Moment): string =>
  window.innerWidth <= laptopSWidth ? dateFormatter.dateMonthShortYear(date) : dateFormatter.dateMonthYear(date);

const defineArrowDestination = (shown: boolean): string => (shown ? 'up' : 'down');

const renderLabel =
  ({ startDate, endDate }: DateRange, i18n: { [key: string]: string }, color: string) =>
  (shown: boolean) => {
    const startDateString = startDate ? formatLabelDate(startDate) : null;
    const endDateString = endDate ? formatLabelDate(endDate) : null;
    return (
      <>
        <StyledCalendarIcon color={color} data-test-id="date-picker-icon" />
        <span data-test-id="date-picker-date-range">
          <strong>{startDateString}</strong>
          {startDateString !== endDateString && <span>{i18n.thru}</span>}
          {startDateString !== endDateString && <strong>{endDateString}</strong>}
          <i
            className={`hideForPDF fas fa-chevron-${defineArrowDestination(shown)}`}
            data-test-id={`date-picker-chevron-${defineArrowDestination(shown)}`}
          />
        </span>
      </>
    );
  };

export const DateRangePickerBasic = (props: PropTypes) => {
  const {
    appliedEndDate,
    prevBtn,
    nextBtn,
    dateChangeHandler,
    ranges,
    translations,
    isAdvancedCalendar,
    appliedStartDate,
    appliedEndPredictiveDate,
    onApply: onApplyHandler,
    onCancel,
    labelColor = baseColors.darkGrey,
    monthFormat = localizationService.getDateTimeFormat('monthShortYear'),
    isMobile,
    isTablet,
    windowWidth,
    checkIsOutsideRange,
    moveArrowLeftOnTablet,
    isRightSide,
    additionalStyles,
    locationFor,
    is30DaysPredictionData
  } = props;
  const [isEndDateSelected, setEndDateSelected] = useState<boolean>(true);
  const [startDate, setStartDate] = useState<Moment | null>(appliedStartDate);
  const [endDate, setEndDate] = useState<Moment | null>(appliedEndDate);
  const isPredictionDataEnabled = !!appliedEndPredictiveDate && appliedEndPredictiveDate.isSameOrAfter(moment(), 'day');
  const [inputFocus, setInputFocus] = useState<FocusedInputShape | null>(null);
  const [selectedRange, setSelectedRange] = useState<string | undefined>();
  const [dropdownPosition, setDropdownPosition] = useState<DropDownPosition>(LEFT_DROPDOWN_POSITION);
  const [key, setKey] = useState(`${startDate}-${endDate}`);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const [isVisibleDayTooltip, setIsVisibleDayTooltip] = useState(false);

  const dispatch = useDispatch();

  const onApply = ({ startDate: start, endDate: end }: DateRange, rangeName: string) => {
    if (start && end) {
      onApplyHandler(start, end, rangeName);
      setTimeout(() => {
        setInputFocus(null);
      }, 100);
    }
  };

  const manualDateChange = ({ startDate: start, endDate: end }: DateRange) => {
    setKey(`${start}-${end}`);
    setStartDate(start);
    setEndDate(end);
  };

  const predefinedDateRangeSelect = (range: DateRange, rangeName: string) => {
    manualDateChange(range);
    setSelectedRange(rangeName);
    onApply(range, rangeName);
  };

  const prepareDateChangeHandler = ({
    startDate: nextStartDate,
    endDate: nextEndDate
  }: {
    startDate: Moment | null;
    endDate: Moment | null;
  }) => {
    dateChangeHandler({
      startDate,
      nextStartDate,
      nextEndDate,
      setStartDate,
      setEndDateSelected,
      setEndDate,
      setSelectedRange,
      setInputFocus
    });
  };

  const checkIsDayHighlighted = useCallback(
    (day: Moment) =>
      isPredictionDataEnabled &&
      day.isSameOrAfter(moment(), 'day') &&
      !!startDate &&
      day.isSameOrAfter(startDate, 'day') &&
      !!endDate &&
      day.isSameOrBefore(endDate, 'day') &&
      endDate.isSameOrAfter(moment(), 'day'),
    [startDate, endDate, isPredictionDataEnabled]
  );

  const checkIsFirstDayWithPredictiveData = useCallback(
    (day: Moment) =>
      !!startDate &&
      ((day.isSame(moment(), 'day') && startDate.isSameOrBefore(moment(), 'day')) || day.isSame(startDate, 'day')),
    [startDate]
  );

  const renderDayContents = useCallback(
    (day: Moment) => {
      if (checkIsDayHighlighted(day)) {
        return (
          <>
            {checkIsFirstDayWithPredictiveData(day) ? (
              <DayPickerTooltip
                content={
                  is30DaysPredictionData ? translations.predictive30DaysTooltip : translations.predictiveDayTooltip
                }
                visible={isVisibleDayTooltip}
              >
                <div
                  data-tag={DAY_TOOLTIP_TAG}
                  data-predictive-date-tag={FIRST_PREDICTIVE_DATE_TAG}
                  onMouseOver={() => setIsVisibleDayTooltip(true)}
                  onMouseLeave={() => setIsVisibleDayTooltip(false)}
                >
                  {day.format('D')}
                </div>
              </DayPickerTooltip>
            ) : (
              <div
                data-tag={DAY_TOOLTIP_TAG}
                onMouseOver={() => setIsVisibleDayTooltip(true)}
                onMouseLeave={() => setIsVisibleDayTooltip(false)}
              >
                {day.format('D')}
              </div>
            )}
          </>
        );
      }
      return day.format('D');
    },
    [checkIsDayHighlighted, checkIsFirstDayWithPredictiveData, isVisibleDayTooltip]
  );

  const closeDropDown = useCallback(
    (e: any) => {
      if (
        wrapperRef &&
        wrapperRef.current &&
        !wrapperRef.current.contains(e.target as Node) &&
        e.target &&
        ![DAY_TOOLTIP_TAG, CANCEL_TAG].includes(e.target.dataset.tag)
      ) {
        onCancel({ setInputFocus, setSelectedRange });
      }
    },
    [wrapperRef]
  );

  useEffect(() => {
    if (inputFocus) return;
    if (appliedStartDate !== startDate) setStartDate(appliedStartDate);
    if (appliedEndDate !== endDate) setEndDate(appliedEndDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appliedStartDate, appliedEndDate, inputFocus]);

  useEffect(() => {
    if (inputFocus) detectDropDownPosition();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputFocus, selectedRange, windowWidth]);

  useEffect(() => {
    if (inputFocus && !!locationFor) dispatch(setDateRangeDropdownState(true));
    if (!inputFocus && !!locationFor) dispatch(setDateRangeDropdownState(false));
    if (inputFocus) return bindDocumentClickOrTouchListener(closeDropDown);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputFocus]);

  // [NV] Code to control tooltips for dates when calendar has 2 same dates on both calendars.
  useEffect(() => {
    if (!inputFocus || !isVisibleDayTooltip) return;
    const firstPredictiveDays = document.querySelectorAll(`div[data-predictive-date-tag=${FIRST_PREDICTIVE_DATE_TAG}]`);
    if (firstPredictiveDays.length <= 1) return;
    // @ts-ignore
    if (firstPredictiveDays[0].parentElement?.classList.contains(CALENDAR_OUTSIDE_DATE_CLASS)) {
      // @ts-ignore
      document.querySelectorAll(TIPPY_ROOT_SELECTOR)[0].style.display = 'none';
    } else {
      // @ts-ignore
      if (document.querySelectorAll(TIPPY_ROOT_SELECTOR)[0].style.display !== 'none') {
        // @ts-ignore
        document.querySelectorAll(TIPPY_ROOT_SELECTOR)[1].style.display = 'none';
      }
    }
  }, [inputFocus, isVisibleDayTooltip]);

  const detectDropDownPosition = () => {
    if (containerRef && containerRef.current && buttonRef && buttonRef.current) {
      const dropDownEl = containerRef.current;
      const btnEl = buttonRef.current;
      const nextPosition =
        window.innerWidth - btnEl.getBoundingClientRect().left > dropDownEl.offsetWidth
          ? LEFT_DROPDOWN_POSITION
          : RIGHT_DROPDOWN_POSITION;

      if (nextPosition !== dropdownPosition) setDropdownPosition(nextPosition);
    }
  };

  const currentDropdownPosition = moveArrowLeftOnTablet && !isMobile && isTablet ? 'left: -75%;' : dropdownPosition;

  return (
    <DateRangePickerContainer ref={wrapperRef} data-test-id="date-picker-section">
      <DateRangePickerToggle
        data-test-id="date-picker-collapsed"
        color={labelColor}
        ref={buttonRef}
        onClick={() => {
          setInputFocus(inputFocus ? null : 'startDate');
        }}
      >
        {renderLabel({ startDate: appliedStartDate, endDate: appliedEndDate }, translations, labelColor)(!!inputFocus)}
      </DateRangePickerToggle>
      {inputFocus && (
        <DateRangePickerDropdownElement
          positionStyles={currentDropdownPosition}
          ref={containerRef}
          data-test-id="date-picker-expanded"
          isRightSide={isRightSide && !isMobile && !isTablet}
          additionalStyles={additionalStyles}
        >
          <PickerContent data-test-id="picker-content">
            {isAdvancedCalendar ? (
              <ReactDatesWrapper data-test-id="calendars-wrapper">
                <StyledP>{translations.dateRangeText}</StyledP>
                <DayPickerRangeController
                  orientation={isMobile ? 'vertical' : 'horizontal'}
                  key={key}
                  daySize={isMobile ? 32 : 28}
                  renderCalendarInfo={() => (
                    <>
                      <ManualAdvancedDateRangeInputs
                        startDate={startDate || moment()}
                        endDate={endDate}
                        isAdvancedCalendar
                        data-test-id="start-date-input"
                      />
                      <AdvancedDatePickerRangesContainer data-test-id="range-options">
                        {Object.entries(ranges).map(([text, range], index) => (
                          <AdvancedDateRangeItem
                            data-test-id={`date-picker-${index}`}
                            secondary
                            key={text}
                            selected={text === selectedRange}
                            onClick={() => {
                              predefinedDateRangeSelect(range, text);
                            }}
                          >
                            {text}
                          </AdvancedDateRangeItem>
                        ))}
                        <AdvancedDateRangeItem
                          data-test-id="custom-range"
                          secondary
                          selected={!selectedRange && !!startDate && !!endDate}
                          onClick={() => {
                            setSelectedRange(undefined);
                          }}
                        >
                          {translations.customRange}
                        </AdvancedDateRangeItem>
                      </AdvancedDatePickerRangesContainer>
                    </>
                  )}
                  calendarInfoPosition="top"
                  keepOpenOnDateSelect
                  focusedInput={inputFocus}
                  startDate={startDate}
                  endDate={endDate}
                  onFocusChange={(focus: FocusedInputShape | null) => {
                    setInputFocus(focus);
                  }}
                  onDatesChange={prepareDateChangeHandler}
                  numberOfMonths={isMobile ? 1 : 2}
                  noBorder
                  onOutsideClick={() => {}}
                  transitionDuration={0}
                  minimumNights={0}
                  hideKeyboardShortcutsPanel
                  monthFormat={monthFormat}
                  enableOutsideDays
                  initialVisibleMonth={() => moment(startDate || moment())}
                  navPrev={prevBtn()}
                  navNext={nextBtn()}
                  isDayHighlighted={checkIsDayHighlighted}
                  renderDayContents={renderDayContents}
                  isOutsideRange={checkIsOutsideRange(startDate, endDate, isEndDateSelected, isPredictionDataEnabled)}
                />
              </ReactDatesWrapper>
            ) : (
              <>
                <DatePickerRangesContainer data-test-id="range-options">
                  {Object.entries(ranges).map(([text, range], index) => (
                    <DateRangeItem
                      data-test-id={`date-picker-${index}`}
                      secondary
                      key={text}
                      selected={text === selectedRange}
                      onClick={() => {
                        predefinedDateRangeSelect(range, text);
                      }}
                    >
                      {text}
                    </DateRangeItem>
                  ))}
                  <DateRangeItem
                    data-test-id="custom-range"
                    secondary
                    selected={!selectedRange && !!startDate && !!endDate}
                    onClick={() => {
                      setSelectedRange(undefined);
                    }}
                  >
                    {translations.customRange}
                  </DateRangeItem>
                </DatePickerRangesContainer>
                {!selectedRange && (
                  <ReactDatesWrapper data-test-id="calendars-wrapper">
                    <DayPickerRangeController
                      orientation={isMobile ? 'vertical' : 'horizontal'}
                      key={key}
                      daySize={isMobile ? 32 : 28}
                      renderCalendarInfo={() => (
                        <ManualDateRangeInputs
                          startDate={startDate || moment()}
                          endDate={endDate || moment()}
                          onChange={manualDateChange}
                          data-test-id="start-date-input"
                        />
                      )}
                      calendarInfoPosition="top"
                      keepOpenOnDateSelect
                      focusedInput={inputFocus}
                      startDate={startDate}
                      endDate={endDate}
                      onFocusChange={(focus: FocusedInputShape | null) => {
                        setInputFocus(focus);
                      }}
                      onDatesChange={prepareDateChangeHandler}
                      numberOfMonths={isMobile ? 1 : 2}
                      noBorder
                      onOutsideClick={() => {}}
                      transitionDuration={0}
                      minimumNights={0}
                      hideKeyboardShortcutsPanel
                      monthFormat={monthFormat}
                      enableOutsideDays
                      initialVisibleMonth={() => moment(startDate || moment())}
                      navPrev={prevBtn()}
                      navNext={nextBtn()}
                      isDayHighlighted={checkIsDayHighlighted}
                      renderDayContents={renderDayContents}
                      isOutsideRange={checkIsOutsideRange(
                        startDate,
                        endDate,
                        isEndDateSelected,
                        isPredictionDataEnabled
                      )}
                    />
                  </ReactDatesWrapper>
                )}
              </>
            )}
          </PickerContent>
          {!selectedRange && (
            <DateRangePickerFooter data-test-id="footer">
              <Button
                data-test-id="cancel"
                secondary
                onClick={() => onCancel({ setInputFocus, setSelectedRange })}
                data-tag={CANCEL_TAG}
              >
                {translations.cancelBtnText}
              </Button>
              <Button
                data-test-id="apply"
                primary
                disabled={!startDate || !endDate}
                onClick={() => {
                  if (startDate && endDate) {
                    onApply({ startDate, endDate }, translations.customRange);
                  }
                }}
              >
                {translations.applyBtnText}
              </Button>
            </DateRangePickerFooter>
          )}
        </DateRangePickerDropdownElement>
      )}
    </DateRangePickerContainer>
  );
};
