import { takeLatest, call, put } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { ApiGateway } from 'services/apiGateway';
import { supportSearchActions } from './actions';

const { searchActions, getJWTForMerchantList } = supportSearchActions;

function* search({ payload }: ReturnType<typeof searchActions.request>) {
  try {
    const response = yield call(ApiGateway.searchMerchants, payload);
    yield put(searchActions.success(response));
  } catch (e) {
    yield put(searchActions.failure());
  }
}

function* getJWT({ payload }: ReturnType<typeof getJWTForMerchantList.request>) {
  try {
    const response = yield call(ApiGateway.getJWTForMerchantList, { merchantSequenceKeyList: payload });
    document.location.replace(response);
  } catch (e) {
    yield put(getJWTForMerchantList.failure());
  }
}

export function* supportSearchSagas() {
  yield takeLatest(getType(searchActions.request), search);
  yield takeLatest(getType(getJWTForMerchantList.request), getJWT);
}
