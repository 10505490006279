import { takeLatest } from '@redux-saga/core/effects';
import { showApiError } from 'store/errors/utils';

import {
  activeContactsActions,
  fetchActiveContacts,
  createContact,
  deleteContact,
  updateContact,
  importContacts,
  setActiveSortFieldAndOrder
} from './active';
import { bouncedContactsActions, fetchBouncedContacts, setBouncedSortFieldAndOrder } from './bounced';
import {
  unsubscribedContactsActions,
  fetchUnsubscribedContacts,
  setUnsubscribedSortFieldAndOrder
} from './unsubscribed';

export function* emailMarketingContactsSagas() {
  yield takeLatest(activeContactsActions.fetchContacts.request, fetchActiveContacts);
  yield takeLatest(activeContactsActions.createContact.request, createContact);
  yield takeLatest(activeContactsActions.deleteContact.request, deleteContact);
  yield takeLatest(activeContactsActions.updateContact.request, updateContact);
  yield takeLatest(activeContactsActions.importContacts.request, importContacts);
  yield takeLatest(activeContactsActions.setActiveSortFieldAndOrder, setActiveSortFieldAndOrder);

  yield takeLatest(bouncedContactsActions.fetchContacts.request, fetchBouncedContacts);
  yield takeLatest(bouncedContactsActions.setBouncedSortFieldAndOrder, setBouncedSortFieldAndOrder);

  yield takeLatest(unsubscribedContactsActions.fetchContacts.request, fetchUnsubscribedContacts);
  yield takeLatest(unsubscribedContactsActions.setUnsubscribedSortFieldAndOrder, setUnsubscribedSortFieldAndOrder);

  yield takeLatest(
    [
      activeContactsActions.deleteContact.failure,
      activeContactsActions.importContacts.failure,
      activeContactsActions.updateContact.failure,
      activeContactsActions.createContact.failure,
      activeContactsActions.fetchContacts.failure,
      bouncedContactsActions.fetchContacts.failure,
      unsubscribedContactsActions.fetchContacts.failure
    ],
    showApiError
  );
}
