import React, { ReactElement } from 'react';
import { S3_SOCIAL_LINKS } from '../../../appConstants/emailMarketing';

const iconStyles = {
  height: '32px',
  width: '32px'
};

export const Social = (props: any): ReactElement => {
  const { className, settings = {} } = props;
  const { socialLinks = [] } = settings;
  const filteredSocialLinks = socialLinks
    .filter((socialLink: any) => !!socialLink.link)
    .map((socialLink: any) => {
      const [name] = socialLink.icon.split('.');
      // @ts-ignore
      return { link: decodeURIComponent(socialLink.link), icon: S3_SOCIAL_LINKS[name] };
    });

  return (
    <div className={className}>
      <table style={{ margin: '10px auto' }}>
        <tbody>
          <tr>
            {filteredSocialLinks.map((socialLink: any, i: number) => (
              // eslint-disable-next-line react/no-array-index-key
              <td key={`${socialLink.link}-${i}`}>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={socialLink.link}
                  style={{ margin: '0px 5px' }}
                  draggable={false}
                >
                  <img src={socialLink.icon} style={iconStyles} draggable={false} alt="" />
                </a>
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
};
