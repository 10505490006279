import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { ReactComponent as Icon } from '../../assets/icons/error404icon.svg';
import { getColor } from '../../layout/theme';
import { textSubtitle } from '../../layout/mixins';

interface Props {
  renderTitle?: () => ReactElement | string | null;
  renderDescription?: () => ReactElement | string | null;
}

const PageContainer = styled.div`
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const ContentContainer = styled.div`
  margin-top: -200px;
  ${textSubtitle};
  padding: 15px;
  font-size: 20px;
  a {
    color: ${props => getColor(props, 'color1')};
    text-decoration: none;
    border-bottom: 1px solid;
  }
  p {
    margin: 0.5em 0;
  }
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 26px;
`;

const StyledIcon = styled(Icon)`
  margin-bottom: 15px;
`;

export const ErrorPageTemplate = ({ renderTitle = () => null, renderDescription = () => null }: Props) => (
  <PageContainer>
    <ContentContainer>
      <div>
        <StyledIcon />
      </div>
      <Title data-test-id="error-page-title">{renderTitle()}</Title>
      <div data-test-id="error-page-description">{renderDescription()}</div>
    </ContentContainer>
  </PageContainer>
);
