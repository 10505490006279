import { createAsyncAction } from 'typesafe-actions';
import {
  PaymentsRefundsChargebacksCardsResponse,
  PaymentsRefundsChargebackSummaryResponse,
  PaymentsRequestGroupedPayload,
  PaymentsRequestPayload,
  PaymentsTransactionsRefundResponse,
  PaymentsTransactionsChargebackResponse,
  PaymentsTransactionsRefundedResponse
} from 'services/apiGateway/payments';

export const refundsChargebacksActions = {
  fetchSummary: createAsyncAction(
    '@advancedAnalytics/refundsChargebacks/fetchSummary/Request',
    '@advancedAnalytics/refundsChargebacks/fetchSummary/Success',
    '@advancedAnalytics/refundsChargebacks/fetchSummary/Error'
  )<PaymentsRequestPayload, PaymentsRefundsChargebackSummaryResponse | null, string>(),
  fetchCards: createAsyncAction(
    '@advancedAnalytics/refundsChargebacks/fetchCards/Request',
    '@advancedAnalytics/refundsChargebacks/fetchCards/Success',
    '@advancedAnalytics/refundsChargebacks/fetchCards/Error'
  )<PaymentsRequestPayload, PaymentsRefundsChargebacksCardsResponse | null, string>(),
  fetchTransactionsRefunded: createAsyncAction(
    '@advancedAnalytics/refundsChargebacks/fetchTransactionsRefunded/Request',
    '@advancedAnalytics/refundsChargebacks/fetchTransactionsRefunded/Success',
    '@advancedAnalytics/refundsChargebacks/fetchTransactionsRefunded/Error'
  )<PaymentsRequestGroupedPayload, PaymentsTransactionsRefundedResponse | null, string>(),
  fetchTransactionsRefundRates: createAsyncAction(
    '@advancedAnalytics/refundsChargebacks/fetchTransactionsRefundRates/Request',
    '@advancedAnalytics/refundsChargebacks/fetchTransactionsRefundRates/Success',
    '@advancedAnalytics/refundsChargebacks/fetchTransactionsRefundRates/Error'
  )<PaymentsRequestGroupedPayload, PaymentsTransactionsRefundResponse | null, string>(),
  fetchTransactionsChargebackRates: createAsyncAction(
    '@advancedAnalytics/refundsChargebacks/fetchTransactionsChargebackRates/Request',
    '@advancedAnalytics/refundsChargebacks/fetchTransactionsChargebackRates/Success',
    '@advancedAnalytics/refundsChargebacks/fetchTransactionsChargebackRates/Error'
  )<PaymentsRequestGroupedPayload, PaymentsTransactionsChargebackResponse | null, string>()
};
