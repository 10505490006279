import { ReactElement } from 'react';
import { detect } from 'detect-browser';
import { MapKeyString } from 'appConstants/commonTypes';
import { localizationService } from './localization';

export const generateDataTestId = (value: string, postfix: string) =>
  `${value} ${postfix}`.toLowerCase().split(' ').join('-');

export const removeMinutes = (time: string) => time.replace(/:\d{2}/, '');

export const stripTags = (value: string) =>
  typeof value === 'string' ? value.replace(/(<([^>]+)>)|(`)|(\*)/gi, '') : '';

export const formatPercent = (value: number): string =>
  value !== 0 ? localizationService.formatPercent(`${value}`) : `${value}`;

export const cropStringWithThreeDots = (string: string, oneLineLength: number, maxLength: number) => {
  let croppedString = string;
  if (string.length > maxLength) {
    croppedString = `${string.slice(0, maxLength)}...`;
  }
  const arrayOfWords = croppedString.split(' ');
  return arrayOfWords
    .map((word: string) => {
      if (word.length > oneLineLength) {
        return `<div style="word-break: break-all;">${word}</div>`;
      }
      return word;
    })
    .join(' ');
};

export const goToURLNoReferer = (url: string) => {
  const a = document.createElement('a');
  a.setAttribute('href', url);
  a.setAttribute('rel', 'noopener noreferrer');
  a.style.display = 'none';
  (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(a);
  a.click();
};

export const generateUID = () => `${Date.now() * Math.random() * Math.random()}`;

export function defaultSortMethod(a: string | number, b: string | number): number {
  // force null and undefined to the bottom
  // eslint-disable-next-line no-param-reassign
  a = a === null || a === undefined ? -Infinity : a;
  // eslint-disable-next-line no-param-reassign
  b = b === null || b === undefined ? -Infinity : b;
  // force any string values to lowercase
  // eslint-disable-next-line no-param-reassign
  a = typeof a === 'string' ? a.toLowerCase() : a;
  // eslint-disable-next-line no-param-reassign
  b = typeof b === 'string' ? b.toLowerCase() : b;
  // Return either 1 or -1 to indicate a sort priority
  if (a > b) {
    return 1;
  }
  if (a < b) {
    return -1;
  }
  // returning 0 or undefined will use any subsequent column sorting methods or the row index as a tiebreaker
  return 0;
}

export function alphanumericSort(a: string, b: string, language: string, desc: boolean): number {
  const isEmptyA = !(typeof a === 'string' && a.trim());
  const isEmptyB = !(typeof b === 'string' && b.trim());

  if (isEmptyA) {
    if (isEmptyB) return 0;
    return desc ? -1 : 1;
  }
  if (isEmptyB) {
    return desc ? 1 : -1;
  }
  return a.toLowerCase().localeCompare(b.toLowerCase(), language, { numeric: true });
}

// TODO: merge with defaultSortMethod.
const getNumericValue = (val: number | null, desc: boolean): number => {
  // this function allows to force null to the bottom of the list regardless of sorting order
  if (typeof val === 'number') return val;
  return desc ? -Infinity : Infinity;
};
export const numbersSortMethod = (a: number | null, b: number | null, desc: boolean): 1 | -1 | 0 => {
  const valA = getNumericValue(a, desc);
  const valB = getNumericValue(b, desc);
  // Return either 1 or -1 to indicate a sort priority
  if (valA > valB) {
    return 1;
  }
  if (valA < valB) {
    return -1;
  }
  // returning 0 or undefined will use any subsequent column sorting methods or the row index as a tiebreaker
  return 0;
};

export const roundTo2Decimals = (number: number) => Math.round(number * 100) / 100;

export function formatString(string: string, ...substitutions: (string | number | ReactElement)[]) {
  const regex = /({\d+})/;
  return string
    .split(regex)
    .filter(substring => !!substring)
    .map(substring => (substring.match(regex) ? substitutions[parseInt(substring.slice(1, -1), 10)] : substring));
}

export const scrollToTop = (elements: string[] = [], timeout: number = 0) => {
  if (!elements.length) return;
  setTimeout(() => {
    elements.forEach((element: string) => {
      const el = document.querySelector(element);
      if (el) el.scrollTop = 0;
    });
  }, timeout);
};

export const sleep = (seconds: number): Promise<null> =>
  new Promise(resolve => setTimeout(() => resolve(null), seconds * 1000));

export const bindDocumentClickOrTouchListener = (listener: (e: any) => void) => {
  const eventName = 'ontouchstart' in window ? 'touchstart' : 'click';
  document.addEventListener(eventName, listener);
  return () => {
    document.removeEventListener(eventName, listener);
  };
};

export const getScrollWidth = (): number => {
  const div = document.createElement('div');

  div.style.overflowY = 'scroll';
  div.style.width = '50px';
  div.style.height = '50px';

  // must put it in the document, otherwise sizes will be 0
  document.body.appendChild(div);
  const scrollWidth = div.offsetWidth - div.clientWidth;
  document.body.removeChild(div);
  return scrollWidth ? scrollWidth + 1 : 0;
};

export const composeQueryString = (params: { [key: string]: string }): string =>
  Object.entries(params)
    .map(([key, value]) => {
      if (value) return `${key}=${encodeURIComponent(value)}`;
      return '';
    })
    .filter(param => param)
    .join('&');

// this util can be used with lodash sortBy function to define sorting options and their order;
export const getIteratees = (options: MapKeyString<number>) =>
  Object.entries(options).map(([key, multiplier]) => (object: MapKeyString<any>) => {
    const property = typeof object[key] === 'number' ? object[key] : -Infinity;
    return property === 0 ? property : property * multiplier;
  });

export const isIE = (): boolean => {
  const browser = detect();
  return !!(browser && browser.name === 'ie');
};

export const isNumeric = (value: string): boolean => /^-?\d+$/.test(value);

export const isOdd = (num: number): boolean => !!(num % 2);

export const separateLastWord = (value: string): [string, string] => {
  if (!value) return ['', ''];
  const splitArray = value.trim().split(' ');
  const lastWord = splitArray.pop() || '';
  const withoutLastWord = splitArray.join(' ');

  return [withoutLastWord, lastWord];
};
