import { LocationFilter, LocationSearch } from './types';

export const decodeLocationsFilter = (data: LocationFilter) => ({
  ...data,
  name: decodeURIComponent(data.name),
  terms: data.terms.map(term => decodeURIComponent(term))
});

export const encodeLocationsFilter = (data: LocationFilter) => ({
  ...data,
  name: encodeURIComponent(data.name),
  terms: data.terms.map(term => encodeURIComponent(term))
});

export const decodeLocationsSearch = (data: LocationSearch) => ({
  ...data,
  filters: data.filters ? data.filters.map(decodeLocationsFilter) : [],
  searchTerms: data.searchTerms ? data.searchTerms.reverse().map((term: string) => decodeURIComponent(term)) : []
});
