import { ActionType, createReducer } from 'typesafe-actions';
import { EMCampaignsState } from './types';
import { emCampaignsActions } from './actions';
import { contactsActions } from '../contacts/actions';

const defaultState: EMCampaignsState = {
  list: [],
  loading: false,
  fetched: false,
  details: {},
  deletedCampaign: undefined
};

export const emCampaignsReducer = createReducer<
  EMCampaignsState,
  ActionType<typeof emCampaignsActions | typeof contactsActions>
>(defaultState)
  .handleAction(emCampaignsActions.getList.request, state => ({ ...state, loading: true, fetched: true }))
  .handleAction(emCampaignsActions.getList.success, (state, action) => ({
    ...state,
    list: action.payload,
    loading: false
  }))
  .handleAction(emCampaignsActions.getList.failure, state => ({ ...state, loading: false }))
  .handleAction(emCampaignsActions.deleteItem.request, state => ({ ...state, loading: true }))
  .handleAction(emCampaignsActions.deleteItem.success, (state, action) => ({
    ...state,
    loading: false,
    deletedCampaign: state.list.find(el => el.id === action.payload),
    list: state.list.filter(el => el.id !== action.payload)
  }))
  .handleAction(emCampaignsActions.deleteItem.failure, state => ({ ...state, loading: false }))
  .handleAction(emCampaignsActions.getItem.request, (state, action) => {
    const { [action.payload.id]: _, ...details } = state.details;
    return { ...state, loading: true, details };
  })
  .handleAction(emCampaignsActions.getItem.success, (state, action) => ({
    ...state,
    loading: false,
    details: {
      ...state.details,
      [action.payload.id]: action.payload.details
    }
  }))
  .handleAction(emCampaignsActions.getItem.failure, (state, action) => ({
    ...state,
    loading: false,
    details: {
      ...state.details,
      [action.payload]: {
        error: true,
        stats: {},
        templateHTML: ''
      }
    }
  }))
  .handleAction(emCampaignsActions.setDeletedCampaign, (state, action) => ({
    ...state,
    deletedCampaign: action.payload
  }))
  .handleAction(contactsActions.fetchContacts.request, state => ({ ...state, loading: true }))
  .handleAction(contactsActions.fetchContacts.success, state => ({ ...state, loading: false }))
  .handleAction(contactsActions.fetchContacts.failure, state => ({ ...state, loading: false }))
  .handleAction(emCampaignsActions.getItemData.request, state => ({ ...state, loading: true }))
  .handleAction(emCampaignsActions.getItemData.success, state => ({ ...state, loading: false }))
  .handleAction(emCampaignsActions.getItemData.failure, state => ({ ...state, loading: false }));
