import { gatewayClient } from '../index';
import {
  PaymentsAuthRateStatResponse,
  PaymentsDeclineCodesResponse,
  PaymentsRefundsChargebacksCardsResponse,
  PaymentsRefundsChargebackSummaryResponse,
  PaymentsRequestGroupedPayload,
  PaymentsRequestPayload,
  PaymentsTransactionsRefundedResponse,
  PaymentsTransactionsRefundResponse,
  PaymentsTransactionsChargebackResponse
} from './types';

const paymentsUrl = '/api/v1/transactions/payments';

export const authAndDeclines = {
  fetchSummary: (payload: PaymentsRequestPayload) =>
    gatewayClient.post(paymentsUrl, {
      method: 'auth-declines-summary',
      ...payload
    }),

  fetchDeclineCodes: (payload: PaymentsRequestPayload): Promise<PaymentsDeclineCodesResponse> =>
    gatewayClient.post(paymentsUrl, {
      method: 'decline-codes',
      ...payload
    }),

  fetchAuthRateStats: (payload: PaymentsRequestGroupedPayload): Promise<PaymentsAuthRateStatResponse> =>
    gatewayClient.post(paymentsUrl, {
      method: 'auth-rate-stat',
      ...payload
    })
};

export const sales = {
  fetchSummary: (payload: PaymentsRequestPayload): Promise<PaymentsAuthRateStatResponse> =>
    gatewayClient.post(paymentsUrl, {
      method: 'sales-summary',
      ...payload
    }),

  fetchSalesGrossNetStat: (payload: PaymentsRequestPayload): Promise<PaymentsAuthRateStatResponse> =>
    gatewayClient.post(paymentsUrl, {
      method: 'sales-gross-net-stat',
      ...payload
    })
};

const refundsAndChargebacks = {
  fetchSummary: (payload: PaymentsRequestPayload): Promise<PaymentsRefundsChargebackSummaryResponse> =>
    gatewayClient.post(paymentsUrl, {
      method: 'refunds-chargebacks-summary',
      ...payload
    }),
  fetchCards: (payload: PaymentsRequestPayload): Promise<PaymentsRefundsChargebacksCardsResponse> =>
    gatewayClient.post(paymentsUrl, {
      method: 'refunds-chargebacks-cards',
      ...payload
    }),
  fetchTransactionsRefunded: (payload: PaymentsRequestGroupedPayload): Promise<PaymentsTransactionsRefundedResponse> =>
    gatewayClient.post(paymentsUrl, {
      method: 'transactions-refunded',
      ...payload
    }),
  fetchTransactionsRefundRates: (payload: PaymentsRequestGroupedPayload): Promise<PaymentsTransactionsRefundResponse> =>
    gatewayClient.post(paymentsUrl, {
      method: 'transactions-refund-rates',
      ...payload
    }),
  fetchTransactionsChargebackRates: (
    payload: PaymentsRequestGroupedPayload
  ): Promise<PaymentsTransactionsChargebackResponse> =>
    gatewayClient.post(paymentsUrl, {
      method: 'transactions-chargeback-rates',
      ...payload
    })
};

export const payments = {
  authAndDeclines,
  sales,
  refundsAndChargebacks
};
