export class LocaleName {
  public ISOName: string = '';

  public country: string = '';

  public language: string = '';

  public constructor(name: string) {
    if (typeof name !== 'string' || name.length !== 5) {
      this.ISOName = '';
      return;
    }
    this.language = LocaleName.languageFromLocale(name);
    this.country = LocaleName.countryFromLocale(name);
    this.ISOName = `${this.language}-${this.country}`;
  }

  public isValid() {
    return this.ISOName !== '';
  }

  public getISOString(): string {
    return this.ISOName;
  }

  public getLanguage(): string {
    return this.language;
  }

  public getCountry(): string {
    return this.country;
  }

  public static languageFromLocale(locale: string): string {
    return locale.substr(0, 2).toLowerCase();
  }

  public static countryFromLocale(locale: string): string {
    return locale.substr(-2, 2).toUpperCase();
  }
}
