import { ActionType, createAction } from 'typesafe-actions';

export const errorsActions = {
  showApiError: createAction(
    '@errors/showApiError',
    action => (payload: { path: string; message?: string }) => action(payload)
  ),
  hideApiError: createAction('@errors/hideApiError', action => () => action())
};

export type ErrorsActions = ActionType<typeof errorsActions>;
