import { ActionType, createReducer } from 'typesafe-actions';
import { BackgroundJobStatus } from '../types';
import { backgroundJobActions } from './actions';

const defaultState: BackgroundJobStatus = {
  working: false,
  done: false,
  name: '',
  title: '',
  body: ''
};

type Store = BackgroundJobStatus;
type Actions = ActionType<typeof backgroundJobActions>;

export const backgroundJobReducer = createReducer<Store, Actions>(defaultState)
  .handleAction(backgroundJobActions.update, (state, action: any) => ({ ...state, ...action.payload }))
  .handleAction(backgroundJobActions.reset, () => ({ ...defaultState }));
