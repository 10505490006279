import { isNil, capitalize } from 'lodash';
import moment from 'moment';
import { localizationService } from 'services/localization';

export const isPositive = (value: number | null | undefined): boolean => !isNil(value) && value >= 0;

export const calculatePercents = (current: number, previous: number): number => {
  const result = (current / previous - 1) * 100;
  return isPositive(previous) ? result : result * -1;
};

export const calculatePercentsInProportion = (x: number, y: number): number => Math.abs((x * 100) / y);

export const preparePercentsInProportion = (x?: number | null, y?: number | null): number | null =>
  typeof x === 'number' && typeof y === 'number' && y ? calculatePercentsInProportion(x, y) : null;

export const calculateStars = (ratingCurrent?: number | null, ratingTotal?: number | null): number | null =>
  typeof ratingCurrent === 'number' && typeof ratingTotal === 'number' && ratingTotal
    ? ratingCurrent / ratingTotal
    : null;

export const capitalizeFirstLetters = (str: string) => str.split(' ').map(capitalize).join(' ');

export const getPeriodStart = (merchantCentricPeriod: string) =>
  merchantCentricPeriod
    ? localizationService.formatDate(moment(merchantCentricPeriod), 'monthDateYearByDashShort')
    : '';
