import React, { ReactNode } from 'react';
import { TippyProps } from '@tippyjs/react';

import { DayPickerTippy } from './index';

const renderTooltipContent = (content: ReactNode): React.ReactElement => (
  <div data-test-id="dayPickerTooltip-content" dangerouslySetInnerHTML={{ __html: content as string }} />
);

export const DayPickerTooltip = (props: TippyProps): React.ReactElement => (
  <DayPickerTippy content={renderTooltipContent(props.content)} visible={props.visible}>
    {props.children}
  </DayPickerTippy>
);
