/* eslint-disable */
import { SagaMiddleware, SagaIterator } from 'redux-saga';
import { cancel, fork, takeLatest } from 'redux-saga/effects';
import { Store } from 'redux';

export type InjectSaga = (key: string, saga: () => SagaIterator) => void;
export type CancelSaga = (key: string) => void;
export type ApplicationStore = Store & { injectSaga: InjectSaga; cancelSaga: CancelSaga };

export function createSagaInjector(store: Store, middleware: SagaMiddleware): ApplicationStore {
  const CANCEL_SAGA = '@sagaInjector/CANCEL';
  const injectedSagas = new Map();
  const isInjected = (key: string) => injectedSagas.has(key);

  const runSaga = (key: string, saga: any) => {
    const runnableSaga = function* () {
      const sagaTask = yield fork(saga);

      yield takeLatest(`${CANCEL_SAGA}/${key}`, function* () {
        injectedSagas.delete(key);
        yield cancel(sagaTask);
      });
    };

    middleware.run(runnableSaga);
  };

  const cancelSaga: CancelSaga = (key: string) => {
    store.dispatch({ type: `${CANCEL_SAGA}/${key}` });
  };

  const injectSaga: InjectSaga = (key: string, saga: () => SagaIterator) => {
    if (isInjected(key)) return;
    injectedSagas.set(key, runSaga(key, saga));
  };

  return Object.assign(store, { injectSaga, cancelSaga });
}
