import { DropdownWithButtonsListItem } from 'shared-components/DropdownWithButtons/types';
import { localizationService } from 'services/localization';
import { LocationFor } from 'components/AdvancedAnalyticsEvaluate/types';
import { AppState } from '../rootReducer';
import {
  ChartFilterValue,
  ComparisonTableLocations,
  FetchTransactionsEvaluateRequestPayload,
  FetchTransactionsRequestPayload,
  PerfomerBoxData,
  PerformersDataKey,
  WeeklySalesBreakdownRequestPayload
} from './types';
import { getLocationByMid } from '../settings';
import { getAnalyticsModuleName } from '../navigation';

export const getDayPartData = (state: AppState) => state.analytics.data.dayPartData;
export const getTransactionsOverview = (state: AppState) => state.analytics.data.insightsOverview;
export const getTransactionsOverviewGrowth = (state: AppState) => state.analytics.data.overviewGrowth;
export const getSpotlightsData = (state: AppState) => state.analytics.data.averagesAllData;
export const getTicketTiersData = (state: AppState) => state.analytics.data.ticketTiers;
export const getWeeklySalesBreakdownData = (state: AppState) => state.analytics.weeklySalesBreakdown;
export const getExpandedCharts = (state: AppState) => state.analytics.expandedCharts;
export const getLineChartData = (state: AppState) => state.analytics.data.lineChart;
export const getComparisonTableOptions = (state: AppState) => state.analytics.comparisonTableOptions;
export const getEvaluationTestGroup = (state: AppState) => state.analytics.testGroup;
export const getEvaluationControlGroup = (state: AppState) => state.analytics.controlGroup;
export const isLoading = (state: AppState): boolean => state.analytics.loading;
export const getPerformerMid = ({ analytics }: AppState, key: PerformersDataKey) => {
  const data = analytics.data.compareLocations;
  if (data && data.performers && data.performers[key]) {
    return data.performers[key].mid;
  }
};
export const getPerformerBoxData = (state: AppState, key: 'top' | 'lowest'): PerfomerBoxData => ({
  sales: {
    new: getLocationByMid(state, getPerformerMid(state, `${key}SalesNew` as PerformersDataKey)),
    returning: getLocationByMid(state, getPerformerMid(state, `${key}SalesReturning` as PerformersDataKey))
  },
  visitors: {
    new: getLocationByMid(state, getPerformerMid(state, `${key}VisitsNew` as PerformersDataKey)),
    returning: getLocationByMid(state, getPerformerMid(state, `${key}VisitsReturning` as PerformersDataKey))
  }
});

export const getCompareLocation = ({ analytics }: AppState): ComparisonTableLocations => {
  if (analytics.data.compareLocations) {
    return analytics.data.compareLocations.locations;
  }
  return [];
};

export const getTempChartFilter = (store: AppState): DropdownWithButtonsListItem<ChartFilterValue>[] =>
  store.analytics.temp.chartFilter;

export const getTransactionsPayload = (state: AppState): FetchTransactionsRequestPayload => {
  const { dateRange, locationSelector } = state;
  const dateFormat = 'YYYY-MM-DD 00:00:00';
  const moduleName = getAnalyticsModuleName(state);
  return {
    start: dateRange.startDate.format(dateFormat),
    end: dateRange.endDate.format(dateFormat),
    locations: locationSelector.selectedLocations,
    moduleName
  };
};

export const getWeeklySalesBreakdownPayload = ({
  dateRange,
  locationSelector
}: AppState): WeeklySalesBreakdownRequestPayload => ({
  start: localizationService.formatDate(dateRange.startDate, 'monthDateYearByDash'),
  end: localizationService.formatDate(dateRange.endDate, 'monthDateYearByDash'),
  locations: locationSelector.selectedLocations
});

export const getTransactionsEvaluatePayload = (
  state: AppState,
  group: LocationFor
): FetchTransactionsEvaluateRequestPayload => {
  const { dateRange, locationSelector } = state;
  const dateFormat = 'YYYY-MM-DD 00:00:00';
  const { startDate } = dateRange;
  const { endDate } = dateRange;

  return {
    start: startDate.format(dateFormat),
    end: endDate.format(dateFormat),
    locations: locationSelector[group].selectedLocations
  };
};
