import { createAction, createAsyncAction } from 'typesafe-actions';
import {
  PaymentsAuthRateStatResponse,
  PaymentsDeclineCodesResponse,
  PaymentsRequestGroupedPayload,
  PaymentsRequestPayload
} from 'services/apiGateway/payments';
import { AuthDeclinesSummary, DeclineCodesTableOptions } from './types';

const setDeclineCodesTableOptions = createAction(
  '@advancedAnalytics/authAndDeclines/setDeclineCodesTableOptions',
  action => (payload: DeclineCodesTableOptions) => action(payload)
);
export const authAndDeclinesActions = {
  setDeclineCodesTableOptions,
  fetchSummary: createAsyncAction(
    '@advancedAnalytics/authAndDeclines/fetchSummary/Request',
    '@advancedAnalytics/authAndDeclines/fetchSummary/Success',
    '@advancedAnalytics/authAndDeclines/fetchSummary/Error'
  )<PaymentsRequestPayload, AuthDeclinesSummary, string>(),
  fetchDeclineCodes: createAsyncAction(
    '@advancedAnalytics/authAndDeclines/fetchDeclineCodes/Request',
    '@advancedAnalytics/authAndDeclines/fetchDeclineCodes/Success',
    '@advancedAnalytics/authAndDeclines/fetchDeclineCodes/Error'
  )<PaymentsRequestPayload, PaymentsDeclineCodesResponse, string>(),
  fetchAuthRateStats: createAsyncAction(
    '@advancedAnalytics/authAndDeclines/fetchAuthRateStat/Request',
    '@advancedAnalytics/authAndDeclines/fetchAuthRateStat/Success',
    '@advancedAnalytics/authAndDeclines/fetchAuthRateStat/Error'
  )<PaymentsRequestGroupedPayload, Partial<PaymentsAuthRateStatResponse>, string>()
};
