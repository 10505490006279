import React from 'react';
import { ErrorTypes } from '../../store/apiGateway';
import { ErrorPageTemplate } from './ErrorPageTemplate';
import { ErrorPageProps } from './ErrorPageWrapper';
import { SessionExpiration } from './SessionExpirationContent';

export const Error403Component = (props: ErrorPageProps): JSX.Element => {
  const { status, message, type } = props;

  if (type === ErrorTypes.sessionError && status === 403) {
    return <SessionExpiration {...props} />;
  }

  return <ErrorPageTemplate renderDescription={() => <p dangerouslySetInnerHTML={{ __html: message }} />} />;
};
