import { createAsyncAction } from 'typesafe-actions';
import { GeneratePdfPayload } from './types';

export const pdfPuppeteerActions = {
  generatePdf: createAsyncAction(
    '@pdf/generatePdfPuppeteer',
    '@pdf/generatePdfPuppeteer/success',
    '@pdf/generatePdfPuppeteer/error'
  )<GeneratePdfPayload, void, void>()
};
