import { createAction } from 'typesafe-actions';
import { NotificationsMap, Notification } from './types';

const addNotification = createAction(
  '@notifications/add',
  action => (notification: Notification) => action(notification)
);

const setNotificationsMap = createAction(
  '@notifications/list/set',
  action => (notifications: NotificationsMap) => action(notifications)
);

const cancelNotificationsTimeout = createAction('@notifications/timeout/cancel', action => () => action());

const restartNotificationsTimeout = createAction('@notifications/timeout/restart', action => () => action());

export const notificationsActions = {
  addNotification,
  setNotificationsMap,
  cancelNotificationsTimeout,
  restartNotificationsTimeout
};
