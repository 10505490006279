import styled from 'styled-components';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import i18n from 'i18n';
import {
  getLeftSideMenuState,
  isTopMenuShown,
  LeftMenuStates,
  getActivePage,
  closeLeftHandSideMenu
} from 'store/navigation';
import {
  deviceMedia,
  footerHeight,
  headerHeight,
  leftMenuIconContainerWidth,
  leftMenuTextContainerWidth,
  toolBarHeight,
  topMenuOverlayZIndex,
  leftMenuWidthAnimationDuration
} from 'appConstants';
import { AppState } from 'store/rootReducer';
import { getNotifications } from 'store/notifications/selectors';
import { NotificationsMap } from 'store/notifications';
import { isPdfModeEnabled } from 'store/pdf';
import { AppRouting } from 'AppRouting';
import { useWindowWidth } from 'services/effects/useWindowWidth';
import { LocationsVisualiser } from 'components/LocationsVisualizer';
import { LeftHandSideMenu } from './navigation';
import { Header } from './header/Header';
import { Toolbar } from './toolbar';
import { Footer } from './footer/Footer';
import { getColor } from './theme';
import { Notifications } from './notifications';
import { getHelpersModeState } from '../store/helpers';
import { getBrandTitle, isAppHubTrayEnabled } from '../store/branding';
import { ErrorWrapper } from './ErrorWrapper';

interface RootComponentProps {
  showTopMenuOverlay?: boolean;
  pdfMode?: boolean;
}

export const Root = styled.div<RootComponentProps>`
  position: absolute;
  height: ${props => (props.pdfMode ? 'auto' : '100%')};
  width: 100%;
  overflow: hidden;
  ${props =>
    props.showTopMenuOverlay
      ? `overflow: hidden;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: ${getColor(props, 'darkGrey')};
      opacity: .7;
      z-index: ${topMenuOverlayZIndex};
    }
  `
      : ''};
  ${props =>
    props.pdfMode
      ? `
    * {
      /* used to display correct colors on the page */
      -webkit-print-color-adjust: exact;
      color-adjust: exact;

      /* used to render text on the MacOS */
      text-shadow: none !important;
    }
    * .hideForPDF {
      display: none
    }
  `
      : ''};
  @media ${deviceMedia.mobile} {
    height: auto;
    min-height: 100%;
  }
`;

const ContentWrapper = styled.div<{ toolBarHeight: number }>`
  height: ${props => `calc(100% - ${props.toolBarHeight}px)`};
  background: ${props => getColor(props, 'lightGrey')};
  display: flex;
`;

const Column = styled.div<{ menuWidth?: number }>`
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  transition: width ${leftMenuWidthAnimationDuration}ms ease-in;
`;

const MainContentColumn = styled(Column)<{ isScrollDisabled: boolean }>`
  ${props => `width: calc(100% - ${props.menuWidth}px)`};
  -webkit-overflow-scrolling: touch;

  ${({ isScrollDisabled }) => (isScrollDisabled ? 'overflow-y: hidden; overflow-x: hidden;' : '')};

  @media ${deviceMedia.tablet} {
    width: 100%;
  }
`;

const LeftMenuColumn = styled(Column)<{ closed: boolean }>`
  ${props => `width: ${props.menuWidth}px;`};
  min-width: ${leftMenuIconContainerWidth}px;
  -ms-overflow-style: none;

  ::-webkit-scrollbar {
    display: none;
  }

  @media ${deviceMedia.tablet} {
    transition: transform 0.1s 0.1s linear;
    z-index: 100;
    position: absolute;
    top: ${toolBarHeight}px;
    ${props => (props.closed ? 'transform: translateX(-100%);' : 'box-shadow:8px 4px 8px 0 rgba(0,0,0,0.2);')};
  }

  @media ${deviceMedia.mobile} {
    width: 100%;
    height: auto;
  }
`;

const LeftMenuMobileOverlay = styled.div<{ shown: boolean }>`
  display: block;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.2s linear;
  position: absolute;
  top: ${toolBarHeight}px;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${props => getColor(props, 'darkGrey')};
  z-index: 99;
  @media ${deviceMedia.tablet} {
    ${props => (props.shown ? 'visibility: visible; opacity: .7' : '')};
  }
`;
LeftMenuMobileOverlay.displayName = 'LeftMenuMobileOverlay';

const RoutesContainer = styled.div<{ footerHeight: number; disabled: boolean }>`
  min-height: calc(100% - ${props => props.footerHeight}px);
  position: relative;

  & > * {
    ${props => (props.disabled ? 'pointer-events: none;' : '')};
  }

  ${props =>
    props.disabled
      ? `
    position: sticky;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
  `
      : ''};
`;
RoutesContainer.displayName = 'RoutesContainer';

const NavigationContainer = styled.div`
  position: relative;
  z-index: 0;
  height: calc(100% - ${headerHeight}px);
`;

interface ConnectedActions {
  closeMenu: typeof closeLeftHandSideMenu;
}

type LayoutProps = RootComponentProps & {
  leftMenuState: LeftMenuStates;
  withoutFooter?: boolean | null;
  dateSelector?: boolean;
  locationSelector?: boolean;
  notifications: NotificationsMap;
  isLeftMenuHidden?: boolean;
  pdfMode: boolean;
  isHelpersModeStateEnabled: boolean;
};

export const AceLayoutComponent = ({
  showTopMenuOverlay,
  leftMenuState,
  withoutFooter,
  closeMenu,
  dateSelector,
  locationSelector,
  notifications,
  isLeftMenuHidden,
  pdfMode,
  isHelpersModeStateEnabled
}: LayoutProps & ConnectedActions): JSX.Element => {
  const toolbar = useRef<HTMLInputElement>(null);
  const footerRef = useRef<HTMLInputElement | null>(null);

  const [screenWidth, isTablet, isMobile] = useWindowWidth();

  const [toolBarResponsiveHeight, setToolbarResponsiveHeight] = useState<number>(toolBarHeight);
  const [footerResponsiveHeight, setFooterResponsiveHeight] = useState<number>(footerHeight);

  useEffect(() => {
    setTimeout(() => {
      if (toolbar && toolbar.current) setToolbarResponsiveHeight(toolbar.current.offsetHeight);
    }, 100);
  }, [screenWidth, dateSelector, locationSelector]);

  useEffect(() => {
    if (footerRef && footerRef.current) {
      setFooterResponsiveHeight(footerRef.current.offsetHeight);
    }
  }, []);

  const isOpen = leftMenuState !== 'closed';
  const menuWidth =
    !pdfMode && !isLeftMenuHidden
      ? (isOpen ? leftMenuIconContainerWidth + leftMenuTextContainerWidth : leftMenuIconContainerWidth) + 1
      : 0;

  return (
    <Root showTopMenuOverlay={showTopMenuOverlay} id="page-container" pdfMode={pdfMode}>
      <Notifications notifications={notifications} />
      <Header isTablet={isTablet} isMobile={isMobile} hideMenu={pdfMode} />
      <NavigationContainer>
        <div ref={toolbar} id="app-toolbar">
          <Toolbar isMobile={isMobile} isTablet={isTablet} isLeftMenuHidden={isLeftMenuHidden} pdfMode={pdfMode} />
        </div>

        <ContentWrapper toolBarHeight={toolBarResponsiveHeight}>
          {!pdfMode && !isLeftMenuHidden && (
            <>
              <LeftMenuColumn
                data-test-id={`left-menu-column-${isOpen ? 'open' : 'not-open'}`}
                menuWidth={menuWidth}
                closed={!isOpen}
              >
                <LeftHandSideMenu isMobile={isMobile} isTablet={isTablet} />
              </LeftMenuColumn>
              <LeftMenuMobileOverlay
                id="left-menu-mobile-overlay"
                onClick={() => {
                  if (isOpen) {
                    closeMenu();
                  }
                }}
                shown={isOpen}
              />
            </>
          )}

          <MainContentColumn menuWidth={menuWidth} id="main-container" isScrollDisabled={isHelpersModeStateEnabled}>
            <RoutesContainer
              id="elements-container"
              disabled={isHelpersModeStateEnabled}
              footerHeight={withoutFooter ? 0 : footerResponsiveHeight}
            >
              <ErrorWrapper>
                <LocationsVisualiser />
                <AppRouting />
              </ErrorWrapper>
            </RoutesContainer>
            {!withoutFooter && <Footer footerRef={footerRef} />}
          </MainContentColumn>
        </ContentWrapper>
      </NavigationContainer>
    </Root>
  );
};

export const AceLayout = connect<LayoutProps, ConnectedActions, {}, AppState>(
  state => {
    const activePage = getActivePage(state);
    const brandTitle = getBrandTitle(state);
    document.title = activePage?.translationKey
      ? `${brandTitle} | ${i18n.t(activePage.translationKey)}`
      : `${brandTitle}`;
    return {
      showTopMenuOverlay: isTopMenuShown(state) && !isAppHubTrayEnabled(state),
      leftMenuState: getLeftSideMenuState(state),
      withoutFooter: activePage && activePage.withoutFooter,
      dateSelector: activePage ? !!activePage.dateRangeSelector : false,
      locationSelector: activePage ? !!activePage.locationSelector : false,
      isLeftMenuHidden: activePage ? !!activePage.leftMenuHidden : false,
      notifications: getNotifications(state),
      pdfMode: isPdfModeEnabled(state),
      isHelpersModeStateEnabled: getHelpersModeState(state)
    };
  },
  dispatch => bindActionCreators({ closeMenu: closeLeftHandSideMenu }, dispatch)
)(AceLayoutComponent);
