import React from 'react';
import ReactDOMServer from 'react-dom/server';
import emojiRegex from 'emoji-regex';
import {
  NAME_SYMBOLS_PER_LINE,
  NAME_LINE_HEIGHT,
  NAME_BOTTOM_PADDING,
  WRAPPER_PADDING_BORDER,
  IMG_WIDTH
} from 'appConstants/emailMarketing';
import { MailBox } from 'store/emailMarketing/emailTemplateEditor';
import { BriefServerTemplate } from 'store/emailMarketing/campaignEditor';
import { stripZeroWidthSpace } from 'store/emailMarketing/utils';
import { EmailTable } from './EmailTable';
import { DataTestIdButton } from './types';

export const emojiStrip = (value: string) => value.replace(emojiRegex(), '');

export const stripHtmlTags = (str: string) => {
  // validation
  if (!str || typeof str === 'undefined' || str === null) {
    return false;
  }

  // eslint-disable-next-line prefer-regex-literals
  const re = new RegExp('(<([^>]+)>)', 'ig');

  return str.replace(re, '');
};

/* eslint-disable */
export const prepareHTMLToSend = (rawHtmlBody: string, preheader: string, title: string) => {
  const htmlBody = stripZeroWidthSpace(rawHtmlBody);

  return `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
   <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
      <head>
        <title>${title}</title>
        <link href="https://fonts.googleapis.com/css?family=Oswald" rel="stylesheet" type="text/css">
        <meta name="viewport" content="width=device-width, initial-scale=1">
        <meta charset="utf-8">
         <!--[if gte mso 9]><xml> <o:OfficeDocumentSettings> <o:AllowPNG/> <o:PixelsPerInch>96</o:PixelsPerInch> </o:OfficeDocumentSettings> </xml><![endif]-->
         <style type="text/css">
            * { box-sizing:border-box; font-family:"Helvetica", arial, sans-serif; }
            html { -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }
            body { margin:0; padding:0; color:#5d6168; font-size:14px;}
            .oswald { font-family:"Oswald", Helvetica, Arial, sans-serif; }
            a{ text-decoration: none; }
            h1,h2,h3,h4,h5,h6{font-weight: initial;}
            h1{font-size: 36px;} h2{font-size: 30px;} h3{font-size: 24px;} h4{font-size: 18px;} h5{font-size: 14px;} h6{font-size: 12px;}
            @media screen and (max-width: 525px) { table[class="full"] { width:100%; } td[class="desktop"] { display:none !important; } img[class="img-responsive"] { width:100% !important; height:auto !important; } img[class="logo"] { margin:0 auto !important; } #click-here { text-align:center !important; } a[class="mobile-button"]{ width:100% !important; padding: 15px !important; border: 0 !important; font-size: 16px !important; } table[id="social-icons"] { width:240px !important; } td[class="social"] img { width:60px !important; height:60px !important; } td[class="spacer"] { width:30px !important; } }
            p { margin: 0 }
            .ql-fontsansserif { font-family: sans-serif; }
            .ql-font-arial { font-family: Arial, sans-serif; }
            .ql-font-verdana { font-family: Verdana, sans-serif; }
            .ql-font-georgia { font-family: Georgia, serif; }
            .ql-font-timesnewroman { font-family: 'Times New Roman', serif; }
            .ql-align-left { text-align: left; }
            .ql-align-right { text-align: right; }
            .ql-align-center { text-align: center; }
            .ql-align-justify { text-align: justify; }
         </style>
      </head>
      <body><span class="preheader" style="display: none;">${preheader}${' '}</span>${htmlBody}</body>
   </html>`;
};
/* eslint-enable */

export const convertMailBoxesToHTML = (
  boxes: MailBox[],
  styles: any,
  settings: Parameters<typeof EmailTable>[0]['settings'],
  translations: { [key: string]: string },
  isDanubeWithLoyalty: boolean,
  isDanubeAccount: boolean,
  accountName: string,
  katanaKey?: string,
  publicSitesDomain?: string,
  chockStoneDomain?: string
): string => {
  const tsx = (
    <EmailTable
      components={boxes}
      styles={styles}
      settings={settings}
      translations={translations}
      isDanubeWithLoyalty={isDanubeWithLoyalty}
      isDanubeAccount={isDanubeAccount}
      accountName={accountName}
      katanaKey={katanaKey}
      publicSitesDomain={publicSitesDomain}
      chockStoneDomain={chockStoneDomain}
    />
  );

  return ReactDOMServer.renderToStaticMarkup(tsx);
};

export const getRowTemplates = (rowIndex: number, cellsPerRow: number, templates: BriefServerTemplate[]) => {
  const first = rowIndex * cellsPerRow;
  return templates.slice(first, first + cellsPerRow);
};

export const getCellHeight = (name: string, dimensions: { height: number; width: number }) => {
  const { height, width } = dimensions;

  const tmplNameHeight = Math.ceil(name.length / NAME_SYMBOLS_PER_LINE) * NAME_LINE_HEIGHT + NAME_BOTTOM_PADDING;
  const wrapperPadding = WRAPPER_PADDING_BORDER * 2;

  return height / (width / IMG_WIDTH) + tmplNameHeight + wrapperPadding;
};

export const calcListHeight = (templates: BriefServerTemplate[], cellsPerRow: number) => {
  let listHeight = 0;

  const lastRowHeight = templates.reduce((acc, template, index) => {
    if (!(index % cellsPerRow)) {
      listHeight += acc;
      return getCellHeight(template.name, template.imgDimensions);
    }
    return Math.max(acc, getCellHeight(template.name, template.imgDimensions));
  }, 0);

  if (!(templates.length % cellsPerRow)) {
    listHeight += lastRowHeight;
  }

  return listHeight;
};

export const maxHeightReducer = (maxHeight: number, template: BriefServerTemplate) =>
  Math.max(maxHeight, getCellHeight(template.name, template.imgDimensions));

export const getStateNameByCode = (regions: any[], code: number) => {
  const customerRegion = regions.find((region: any) => region.stateID === code) || {};
  const { stateName = '' } = customerRegion;
  return stateName;
};

export const getDataTestIdForButton = (index: number) => {
  let type: DataTestIdButton = 'select';
  if (index === 1) {
    type = 'preview';
  }
  if (index === 2) {
    type = 'delete';
  }
  return `template-thumbnail-${type}-button`;
};
