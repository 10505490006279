import { createReducer } from 'typesafe-actions';
import { LocationActions, LocationsSelectorState } from './types';
import { locationsActions } from './actions';

const defaultState: LocationsSelectorState = {
  selectedLocations: [],
  search: {
    searchTerms: [],
    filters: []
  },
  isContactsWithoutLocations: true,
  isDropDownShown: false,
  locationSelectorPlace: '',
  testGroup: { selectedLocations: [] },
  controlGroup: { selectedLocations: [] },
  isTestGroupDropDownShown: false,
  isControlGroupDropDownShown: false
};

export const locationSelectorReducer = createReducer<LocationsSelectorState, LocationActions>(defaultState)
  .handleAction(locationsActions.select, (state, action) => ({
    ...state,
    selectedLocations: action.payload
  }))
  .handleAction(locationsActions.selectTestGroup, (state, action) => ({
    ...state,
    testGroup: { selectedLocations: action.payload }
  }))
  .handleAction(locationsActions.selectControlGroup, (state, action) => ({
    ...state,
    controlGroup: { selectedLocations: action.payload }
  }))
  .handleAction(locationsActions.search.list.success, (state, action) => ({
    ...state,
    search: action.payload
  }))
  .handleAction(locationsActions.search.item.create.success, (state, action) => {
    let nextFilters = [];
    if (!state.search || !Array.isArray(state.search.filters)) {
      nextFilters = [action.payload];
    } else {
      const existingItem = state.search.filters.find(el => el.name === action.payload.name);
      nextFilters = existingItem
        ? state.search.filters.map(el => (el.name !== existingItem.name ? el : action.payload))
        : [...state.search.filters, action.payload];
    }
    return {
      ...state,
      search: {
        ...state.search,
        filters: nextFilters
      }
    };
  })
  .handleAction(locationsActions.search.item.delete.success, (state, action) => ({
    ...state,
    search: {
      ...state.search,
      filters:
        state.search && Array.isArray(state.search.filters)
          ? state.search.filters.filter(el => el.name !== action.payload.name)
          : []
    }
  }))
  .handleAction(locationsActions.search.addRecent.success, (state, action) => ({
    ...state,
    search: {
      ...state.search,
      searchTerms:
        state.search && state.search.searchTerms
          ? [...new Set([action.payload, ...state.search.searchTerms])]
          : [action.payload]
    }
  }))
  .handleAction(locationsActions.isContactsWithoutLocations, (state, action) => ({
    ...state,
    isContactsWithoutLocations: action.payload
  }))
  .handleAction(locationsActions.isDropDownShown, (state, { payload }) => ({
    ...state,
    isDropDownShown: payload.shown,
    locationSelectorPlace: payload.place || ''
  }))
  .handleAction(locationsActions.isControlGroupDropDownShown, (state, { payload }) => ({
    ...state,
    isControlGroupDropDownShown: payload.shown,
    locationSelectorPlace: payload.place || ''
  }))
  .handleAction(locationsActions.isTestGroupDropDownShown, (state, { payload }) => ({
    ...state,
    isTestGroupDropDownShown: payload.shown,
    locationSelectorPlace: payload.place || ''
  }));
