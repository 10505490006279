import moment from 'moment';
import { ActionType, createReducer } from 'typesafe-actions';
import { Ids as CategoriesEnum } from 'components/CustomerDemographics/types';
import { YEAR_MONTH_FORMAT } from 'appConstants';
import { MasterCardState } from './types';
import { masterCardActions } from './actions';

export const initialState: MasterCardState = {
  loading: false,
  error: false,
  data: null,
  period: moment().subtract(1, 'month').format(YEAR_MONTH_FORMAT),
  compareData: null,
  demographicsCategory: Object.values(CategoriesEnum)[0],
  comparisonTableOptions: {
    sorted: [
      {
        id: 'locationInfo',
        desc: false
      }
    ],
    pageSize: 10,
    page: 0
  },
  geoInfo: {
    loading: false,
    country: '',
    locationCoordinates: {
      lat: 0,
      lng: 0
    },
    postalCodesInfo: {
      transactions: [],
      newCustomers: [],
      competitors: []
    }
  },
  features: []
};

// TODO: Replace with combineReducers
const updateGeoInfo = (state: MasterCardState, updates: {}): MasterCardState => ({
  ...state,
  geoInfo: {
    ...state.geoInfo,
    ...updates
  }
});

export const masterCardReducer = createReducer<MasterCardState, ActionType<typeof masterCardActions>>(initialState)
  .handleAction(masterCardActions.fetchMasterCardData.request, state => ({ ...state, loading: true, error: false }))
  .handleAction(masterCardActions.fetchMasterCardData.success, (state, action) => ({
    ...state,
    loading: false,
    error: false,
    data: action.payload
  }))
  .handleAction(masterCardActions.fetchMasterCardData.failure, () => ({ ...initialState, error: true }))
  .handleAction(masterCardActions.fetchGeoInfoForSelectedLocation.request, state =>
    updateGeoInfo(state, {
      ...initialState.geoInfo,
      loading: true
    })
  )
  .handleAction(masterCardActions.fetchGeoInfoForSelectedLocation.success, (state, action) =>
    updateGeoInfo(state, {
      ...action.payload,
      loading: false
    })
  )
  .handleAction(masterCardActions.fetchGeoInfoForSelectedLocation.failure, state =>
    updateGeoInfo(state, { loading: false })
  )
  .handleAction(masterCardActions.fetchPostalCodesInfo.request, state => updateGeoInfo(state, { loading: true }))
  .handleAction(masterCardActions.fetchPostalCodesInfo.success, (state, action) =>
    updateGeoInfo(state, {
      ...action.payload,
      loading: false
    })
  )
  .handleAction(masterCardActions.fetchPostalCodesInfo.failure, state => updateGeoInfo(state, { loading: false }))
  .handleAction(masterCardActions.fetchMasterCardCompareData.request, state => ({
    ...state,
    loading: true,
    error: false
  }))
  .handleAction(masterCardActions.fetchMasterCardCompareData.success, (state, action) => ({
    ...state,
    loading: false,
    error: false,
    compareData: action.payload
  }))
  .handleAction(masterCardActions.fetchMasterCardCompareData.failure, state => ({ ...state, loading: false }))
  .handleAction(masterCardActions.setComparisonTableOptions, (state, action) => ({
    ...state,
    comparisonTableOptions: action.payload
  }))
  .handleAction(masterCardActions.setPeriod, (state, action) => ({ ...state, period: action.payload }))
  .handleAction(masterCardActions.setFeatures, (state, action) => ({ ...state, features: action.payload }))
  .handleAction(masterCardActions.setDemographicsCategory, (state, action) => ({
    ...state,
    demographicsCategory: action.payload
  }))
  .handleAction(masterCardActions.resetMasterCardData, ({ demographicsCategory }) => ({
    ...initialState,
    demographicsCategory
  }));
