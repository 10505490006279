import { createAsyncAction, createAction } from 'typesafe-actions';
import { FetchScoreResponse } from './types';

const fetchScore = createAsyncAction(
  '@merchantCentric/score/fetch',
  '@merchantCentric/score/success',
  '@merchantCentric/score/error'
)<void, FetchScoreResponse, Error>();

const resetScore = createAction('@merchantCentric/reviewsTrend/reset', action => () => action());

export const scoreActions = {
  fetchScore,
  resetScore
};
