import { get } from 'lodash';
import moment from 'moment';
import { select, put, takeLatest, call } from '@redux-saga/core/effects';
import { getType } from 'typesafe-actions';
import { INTERNATIONAL_DATE_FORMAT } from 'appConstants';
import { ApiGateway } from 'services/apiGateway';
import { getSelectedLocations } from 'store/locations';
import { DayBookNote } from 'store/daybook';
import { showApiError } from '../errors/utils';
import { rfmActions } from './actions';
import { getLineChartData } from './modifiers';
import { RFMTierValue } from './types';
import { getMockedData } from './mock';
import { getRFMRange } from './selectors';

const getTierAverageValues = (rfmData: { [key: string]: any } | RFMTierValue[], selector?: string) => {
  const tierData: RFMTierValue[] = selector ? get(rfmData, selector, []) : rfmData;
  if (tierData.length === 0) return { recency: 0, frequency: 0, monetary: 0 };

  const summarized = tierData.reduce(
    (acc, item) => ({
      recency: acc.recency + item.recency,
      frequency: acc.frequency + item.frequency,
      monetary: acc.monetary + item.monetary
    }),
    { recency: 0, frequency: 0, monetary: 0 }
  );

  return {
    recency: summarized.recency / tierData.length,
    frequency: summarized.frequency / tierData.length,
    monetary: summarized.frequency / tierData.length
  };
};

export function* rfmSagas() {
  yield takeLatest(getType(rfmActions.fetchData.request), function* () {
    try {
      const state = yield select();
      const range = getRFMRange(state);
      const data = getMockedData(range);
      const selectedLocations = getSelectedLocations(state);

      const currentDay = moment().startOf('day');
      const end = currentDay.format(INTERNATIONAL_DATE_FORMAT);
      const start = currentDay.subtract(range, 'days').format(INTERNATIONAL_DATE_FORMAT);

      let dayBooks: DayBookNote[] = [];

      if (selectedLocations.length === 1) {
        const dayBooksResponse = yield call(ApiGateway.getDayBookNotes, {
          merchantSequenceKeys: selectedLocations,
          start,
          end
        });

        dayBooks = dayBooksResponse.dayBookNotes;
      }
      const lineChartData = getLineChartData(data, dayBooks);

      const tier1 = getTierAverageValues(data, 'tier1.data');
      const tier2 = getTierAverageValues(data, 'tier2.data');
      const tier3 = getTierAverageValues(data, 'tier3.data');
      const tier4 = getTierAverageValues(data, 'tier4.data');
      const tier5 = getTierAverageValues(data, 'tier5.data');

      const tiersAverage = {
        tier1: { ...tier1, monetaryLifeTimeValue: data.tier1.lifetimeValue },
        tier2: { ...tier2, monetaryLifeTimeValue: data.tier2.lifetimeValue },
        tier3: { ...tier3, monetaryLifeTimeValue: data.tier3.lifetimeValue },
        tier4: { ...tier4, monetaryLifeTimeValue: data.tier4.lifetimeValue },
        tier5: { ...tier5, monetaryLifeTimeValue: data.tier5.lifetimeValue }
      };

      const allTiersAverage = getTierAverageValues([tier1, tier2, tier3, tier4, tier5]);
      const payload = {
        data,
        lineChartData,
        allTiersAverage,
        tiersAverage
      };

      yield put(rfmActions.fetchData.success(payload));
    } catch (e) {
      yield put(rfmActions.fetchData.failure(e));
    }
  });

  yield takeLatest([rfmActions.fetchData.failure], showApiError);
}
