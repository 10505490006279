import { createAsyncAction } from 'typesafe-actions';
import { Application } from '@app-tray-ui/react-wrapper';
import {
  DistanceMetrics,
  ReturningFrequency,
  MyAccountSuccessResponse,
  NotificationSettingsSuccess,
  NotificationsSettingsRequest,
  SettingsDataResponse,
  TemperatureMetrics
} from './types';

const info = createAsyncAction('@settings/fetch', '@settings/fetch/success', '@settings/fetch/error')<
  void,
  SettingsDataResponse,
  void
>();

const updateMyAccount = createAsyncAction(
  '@settings/my-account/update',
  '@settings/my-account/update/success',
  '@settings/my-account/update/error'
)<
  {
    distanceMetrics: DistanceMetrics;
    email: string;
    returningFrequency: ReturningFrequency;
    temperatureMetrics: TemperatureMetrics;
  },
  MyAccountSuccessResponse,
  void
>();

const updateNotifications = createAsyncAction(
  '@settings/notifications/update',
  '@settings/notifications/update/success',
  '@settings/notifications/update/error'
)<NotificationsSettingsRequest, NotificationSettingsSuccess, void>();

const getAppHubTrayItems = createAsyncAction(
  '@settings/fetch/appHubTrayItems',
  '@settings/fetch/appHubTrayItems/success',
  '@settings/fetch/appHubTrayItems/error'
)<{ gpUserId: string; jwt: string }, Application[], string>();

export const settingsActions = {
  info,
  updateMyAccount,
  updateNotifications,
  getAppHubTrayItems
};
