/* eslint-disable */
/* cz_CZ Static Templates Data */

const AutomotiveServiceShopEmailData = {
  styles: {
    width: '600px',
    color: '#898989',
    backgroundColor: '#fff',
    couponBorderColor: '#00aeef',
    buttonsDividersColor: '#898989',
    sicailLinksColor: '#00aeef',
  },
  components: [
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_29.+Automotive+Service+Shops+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center">Uskladnění sezónních pneumatik zdarma!</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Servis ve stejný den u většiny oprav</p>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Jasný odhad bez skrytých nákladů</p>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Zavolejte ještě dnes a domluvte si termín!</p>',
    },
    {
      type: 'coupon',
      text: '<h2 class="ql-align-center">Pokud si u nás zakoupíte ruční umytí auta a výměnu pneumatik, uskladnění sezónních pneumatik máte zdarma!</h2><p class="ql-align-center" style="font-size: 10px;">Nelze kombinovat s jinými slevami nebo nabídkami.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const BakeryEmailData = {
  styles: {
    width: '600px',
    color: '#898989',
    backgroundColor: '#fff',
    couponBorderColor: '#00aeef',
    buttonsDividersColor: '#898989',
    sicailLinksColor: '#00aeef',
  },
  components: [
    {
      type: 'image',
      image: 'em-logos/bakery-email-logo_1498080292703.png',
    },
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_1.+Bakery+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center">Sleva 10% na váš další nákup</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Chtěli jsme vám poslat malý dárek jako poděkování. Neváhejte použít tento kupón při vaší příští návštěvě pekárny Bob\'s Bakery, kde se vše peče přímo na místě, jen z těch nejčerstvějších surovin. Přijďte si k nám vybrat ze širokého množství druhů našich čerstvě upečených zákusků a koláčů. Nebo ještě lépe, sedněte si a vychutnejte si je přímo u nás. Těšíme se na brzké vidění!</p>',
    },
    {
      type: 'coupon',
      text: '<h2 class="ql-align-center">Sleva 10% na váš další nákup.</h2><p class="ql-align-center" style="font-size: 10px;">Nelze kumulovat s jinými slevami nebo nabídkami.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const BarberShopEmailData = {
  styles: {
    width: '600px',
    color: '#898989',
    backgroundColor: '#fff',
    couponBorderColor: '#00aeef',
    buttonsDividersColor: '#898989',
    sicailLinksColor: '#00aeef',
  },
  components: [
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_25.+Barber+Shop+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center">Balíček kompletní péče nyní s 10% slevou</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Zabořte se do pohodlného křesla a svěřte své vlasy a vousy do péče profesionálního holiče. Kromě perfektního střihu si můžete v čistě pánském prostředí vychutnat kávu prémiové kvality. Je libo něco ostřejšího?</p>',
    },
    {
      type: 'coupon',
      text: '<h2 class="ql-align-center">Balíček kompletní péče, která zahrnuje i úpravu vousů, nyní s 10% slevou a k tomu rum dle vašeho výběru z našich prémiových rumů zdarma.</h2><p class="ql-align-center" style="font-size: 10px;">Nelze kumulovat s jinými slevami nebo nabídkami.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const BarPubEmailData = {
  styles: {
    width: '600px',
    color: '#fff',
    backgroundColor: '#898989',
    couponBorderColor: '#fff',
    buttonsDividersColor: '#fff',
    sicailLinksColor: '#fff',
  },
  components: [
    {
      type: 'image',
      image: 'em-logos/bar-pub-email-logo_1498080596725.png',
    },
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_2.+Bar+Pub+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center">KAŽDÝ DEN MÁME HAPPY HOUR </h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center" style="font-weight: bold;">Denně od 16:00 do 19:00</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Nápojový lístek aktualizujeme každý týden.</p>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Přijďte se podívat, co dnes nabízíme!</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'coupon',
      text: '<h4 class="ql-align-center">Položka "Happy Hour" se slevou 25 Kč.</h4><p class="ql-align-center">Platí na 1 položku.</p><br><p class="ql-align-center" style="font-size: 10px;">Omezení na 1 zákazníka. Nelze kombinovat s jinými slevami a zvýhodněnými nabídkami. </p><br>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const BeautyShopEmailData = {
  styles: {
    width: '600px',
    color: '#898989',
    backgroundColor: '#fff',
    couponBorderColor: '#00aeef',
    buttonsDividersColor: '#898989',
    sicailLinksColor: '#00aeef',
  },
  components: [
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_26.+Beauty+Shop+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center">Pospěšte si a získejte 10% slevu na exkluzivní přírodní kosmetiku</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Přijďte si k nám odpočinout a zažijte změnu podle vašich představ. Postaráme se o vás od hlavy až k patě.</p>',
    },
    {
      type: 'coupon',
      text: '<h2 class="ql-align-center">Po absolvování kosmetického ošetření v našem salonu získáte 10% slevu na exkluzivní přírodní kosmetiku.</h2><p class="ql-align-center" style="font-size: 10px;">Nelze kombinovat s jinými slevami nebo nabídkami. </p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const BurgerEmailData = {
  styles: {
    width: '600px',
    backgroundColor: '#e11b22',
    color: '#fff',
    headingsColor: '#fff',
    buttonsDividersColor: '#fff',
  },
  components: [
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_3.+Burger+Template_CZ.jpg',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">KUPTE JEDEN A DRUHÝ DOSTANETE ZDARMA.</p>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Naše sezónní, 100% hovězí karbanátky s uzenou slaninkou a čerstvou zeleninou jsou tady! Přidejte k tomu vychlazené řemeslné pivo a začněte týden s úsměvem!</p>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Ukažte naší obsluze přímo tento kupon, nebo si jej vytiskněte.</p>',
    },
    {
      type: 'coupon',
      text: '<h2 class="ql-align-center">KUPTE JEDNO MENU, ZÍSKEJTE DRUHÉ ZDARMA</h2><p class="ql-align-center">(za stejnou nebo nižší cenu)</p><p class="ql-align-center">Omezení na 1 zákazníka. Nelze kumulovat s jinými slevami nebo nabídkami.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const ChineseEmailData = {
  styles: {
    width: '600px',
    color: '#fff',
    backgroundColor: '#231f20',
    couponBorderColor: '#fff',
    buttonsDividersColor: '#fff',
    sicailLinksColor: '#fff',
  },
  components: [
    {
      type: 'image',
      image: 'em-logos/chinese-email-logo_1498080685909.png',
    },
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_4.+Chinese+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center" style="font-style:italic;">Vaječný závitek zdarma!</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">nebo vyměňte za jarní role (2)</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Podívejte se také na naše speciální nápoje a aktualizované menu.</p>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Zavolejte předem a rezervujte si stůl ještě dnes!</p>',
    },
    {
      type: 'coupon',
      text: '<h4 class="ql-align-center">Vaječný závitek zdarma!</h4><p class="ql-align-center">nebo jarní závitky</p><br><p class="ql-align-center" style="font-size: 10px;">Omezení na 1 zákazníka. Nelze kombinovat s jinými slevami a zvýhodněnými nabídkami.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const ClothingShopEmailData = {
  styles: {
    width: '600px',
    color: '#898989',
    backgroundColor: '#fff',
    couponBorderColor: '#00aeef',
    buttonsDividersColor: '#898989',
    sicailLinksColor: '#00aeef',
  },
  components: [
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_27.+Clothing+Store+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center">Objevte svůj nový styl</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Přijďte k nám a nechte se inspirovat. Tento týden máme pro vás připravenou akci 1+1 zdarma.</p>',
    },
    {
      type: 'coupon',
      text: '<h2 class="ql-align-center">Nelze kumulovat s jinými slevami nebo nabídkami.</h2>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const CoffeeEmailData = {
  styles: {
    width: '600px',
    backgroundColor: '#e6e7e8',
    color: '#000',
    headingsColor: '#000',
    buttonsDividersColor: '#000',
  },
  components: [
    {
      type: 'image',
      image: 'em-logos/top_1497255967016.png',
    },
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_5.+Coffee+Template.jpg',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<h3 class="ql-align-center">PROBUĎTE SE S NAŠÍ ČERSTVO PRAŽENOU KÁVOU!</h3>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Není lepší způsob, jak začít den, než s naší čerstvě praženou kávou. Plná a bohatá chuť vám zaručeně vykouzlí úsměv na tváři. Navštivte nás ještě dnes pro ochutnávku zdarma!</p>',
    },
    {
      type: 'coupon',
      text: '<h4 class="ql-align-center">ČERSTVÉ PEČIVO DENNĚ</h4><h3 class="ql-align-center">OBĚDOVÉ AKCE PONDĚLEK – PÁTEK</h3><p class="ql-align-center">Nabídka v jednotlivých provozovnách se může lišit.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },

  ],
};

const DessertEmailData = {
  styles: {
    width: '600px',
    color: '#464646',
    backgroundColor: '#dfd',
    couponBorderColor: '#ff7d52',
    buttonsDividersColor: '#ff7d52',
    sicailLinksColor: '#464646',
  },
  components: [
    {
      type: 'image',
      image: 'em-logos/dessert-email-logo_1498080768821.png',
    },
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_6.+Dessert+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center" style="color:#ff7d52; font-style:italic;">NOVÁ NABÍDKA JE ZDE!</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Přišlo jaro a s ním i naše nové menu!</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Zastavte se kdykoli tento týden a získejte poloviční slevu na jakoukoli novou položku menu.</span></p>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Najdete u nás pěkné růžové makronky, smetanové mrkvové koláčky, jahodové tyčinky a další.</p>',
    },
    {
      type: 'coupon',
      text: '<h4 class="ql-align-center">POLOVIČNÍ SLEVA NA NOVÉ POLOŽKY V MENU!</h4><p class="ql-align-center">(pouze tento týden)</p><br><p class="ql-align-center" style="font-size: 10px;">Nelze kumulovat s jinými slevami nebo nabídkami.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const DinerEmailData = {
  styles: {
    width: '600px',
    color: '#f00',
    backgroundColor: '#fff',
    couponBorderColor: '#f00',
    buttonsDividersColor: '#f00',
    sicailLinksColor: '#f00',
  },
  components: [
    {
      type: 'image',
      image: 'em-logos/diner-email-logo_1498081690316.png',
    },
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_7.+Diner+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center">Sleva 10 % na vaši příští objednávku</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Přijďte se k nám najíst a užít si pohodovou atmosféru! V Dave\'s Diner je pro nás vždy nejvyšší prioritou obsluha s úsměvem.</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'spacer',
    },
    {
      type: 'coupon',
      text: '<h2 class="ql-align-center">Sleva 10 % na vaši příští objednávku.</h2><p class="ql-align-center" style="font-size: 10px;">Nelze kumulovat s jinými slevami nebo nabídkami.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const FamilyDiningEmailData = {
  styles: {
    width: '600px',
    color: '#fff',
    backgroundColor: '#fc702e',
    couponBorderColor: '#fff',
    buttonsDividersColor: '#fff',
    sicailLinksColor: '#000',
  },
  components: [
    {
      type: 'image',
      image: 'em-logos/family-dining-email-2-logo_1498081751965.png',
    },
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_8.+Family+Dining+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center" style="font-style:italic;">ÚTERKY MAJÍ DĚTI JÍDLO ZDARMA</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Večeře je rodinný čas v Crosby\'s Kitchen. Přijďte v JAKÉKOLI úterý a děti jedí zdarma při koupi jakéhokoli jídla pro dospělé.</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Garantujeme vám, že jídlo si zamilujete vy i vaši nejmenší.</p>',
    },
    {
      type: 'coupon',
      text: '<h4 class="ql-align-center">1 DĚTSKÉ JÍDLO ZDARMA!</h4><p class="ql-align-center">(při koupi jakéhokoli jídla pro dospělé)</p><br><p class="ql-align-center" style="font-size: 10px;">Nelze kombinovat s jinými slevami nebo nabídkami. </p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const FineDiningEmailData = {
  styles: {
    width: '600px',
    backgroundColor: '#f5f3ef',
    color: '#000',
    couponBorderColor: '#770e1f',
    buttonsDividersColor: '#770e1f',
    sicailLinksColor: '#000',
  },
  components: [
    {
      type: 'image',
      image: 'em-logos/fine-dining-email-logo_1498081793107.png',
    },
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_9.+Fine+Dining.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center" style="font-style:italic; color:#770e1f;">POZVÁNKA PRO VÁS!</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Připojte se k nám a získejte dokonalý zážitek díky kombinaci chutí nabízené výhradně našim nejvěrnějším příznivcům.</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center" style="font-weight:bold;">Degustační menu za 1 500 Kč</p>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">(zahrnuje 4-chodové menu doplněné vínem)</p>',
    },
    {
      type: 'coupon',
      text: '<h4 class="ql-align-center" style="font-weight:normal;">PROSÍM ODPOVĚZTE ZDE jestli se k nám přidáte na tuto speciální událost.</h4><p class="ql-align-center" style="font-style:italic;">Pospěšte si, počet míst je omezen!</p><br><p class="ql-align-center" style="font-size: 10px;">Nelze kumulovat s jinými slevami nebo nabídkami. Platí pouze při platbě kartou.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const FloristsEmailData = {
  styles: {
    width: '600px',
    color: '#898989',
    backgroundColor: '#fff',
    couponBorderColor: '#00aeef',
    buttonsDividersColor: '#898989',
    sicailLinksColor: '#00aeef',
  },
  components: [
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_30.+Florists+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center">Léto je tady!</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Naši prodejnu jsme oblékli do letního šatu! Tak jako každou sezónu, i nyní jsme naše prostory naaranžovali tak, abyste zde zažili opravdové léto. Přijďte se inspirovat a odnést si kousek léta s sebou.</p>',
    },
    {
      type: 'coupon',
      text: '<h2 class="ql-align-center">Letničky ve květináčích jen tento týden se slevou 10%</h2><p class="ql-align-center" style="font-size: 10px;">Nelze kombinovat s jinými slevami nebo nabídkami.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const FruitEmailData = {
  styles: {
    width: '600px',
    color: '#f00',
    backgroundColor: '#fff',
    couponBorderColor: '#f00',
    buttonsDividersColor: '#f00',
    sicailLinksColor: '#f00',
  },
  components: [
    {
      type: 'image',
      image: 'em-logos/fruit-email-logo_1498081891611.png',
    },
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_10.+Fruit+Template_CZ.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center">10 % sleva na první objednávku</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Naše ovocie je zaručene čerstvé! Zásobte sa zdravým občerstvením pre vás a vašich priateľov. Naše košíky s ovocím určite vyčaria na každej tvári úsmev.</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'spacer',
    },
    {
      type: 'coupon',
      text: '<h2 class="ql-align-center">10 % sleva na první objednávku</h2><p class="ql-align-center" style="font-size: 10px;">Limitace na 1 zákazníka. Nelze kombinovat s jinými slevami a zvýhodněnými nabídkami.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const ItalianEmailData = {
  styles: {
    width: '600px',
    color: '#000',
    backgroundColor: '#fcefd8',
    couponBorderColor: '#d82c1f',
    buttonsDividersColor: '#d82c1f',
    sicailLinksColor: '#000',
  },
  components: [
    {
      type: 'image',
      image: 'em-logos/italian-email-logo_1498081923428.png',
    },
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_11.+Italian+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center" style="font-style:italic; color:#d82c1f;">UŽIJTE SI SLEVU 100 Kč PŘI VAŠÍ DALŠÍ NÁVŠTĚVĚ!</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Zajisté si vychutnáte fantastické jídlo, ať se jedná o naše famózní Pomodoro Bolognese nebo Penne Carbonara.</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Ukažte nám tento e-mail a získejte slevu 10O Kč z vaší útraty.</p>',
    },
    {
      type: 'coupon',
      text: '<h4 class="ql-align-center" style="color:#d82c1f;">SLEVA 100 Kč PŘI DALŠÍ NÁVŠTĚVĚ</h4><br><p class="ql-align-center" style="font-size: 10px;">Nelze kombinovat s jinými slevami a zvýhodněnými nabídkami.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const MexicanEmailData = {
  styles: {
    width: '600px',
    color: '#fff',
    backgroundColor: '#efae2a',
    couponBorderColor: '#fff',
    buttonsDividersColor: '#fff',
    sicailLinksColor: '#fff',
  },
  components: [
    {
      type: 'image',
      image: 'em-logos/mexican-email-logo_1498082003319.png',
    },
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_12.+Mexican+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center">10% sleva při vaši další návštěvě</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Naše autentické menu vám zaručeně rozjasní den! Přijďte k nám a vychutnejte si chlazený nápoj a chutná předkrmy za bezkonkurenční ceny. Fiesta může začít!</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'coupon',
      text: '<h2 class="ql-align-center">Sleva 10 % na vaši příští objednávku.</h2><p class="ql-align-center" style="font-size: 10px;">Nelze kumulovat s jinými slevami nebo nabídkami.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const MoveItSportsEmailData = {
  styles: {
    width: '600px',
    color: '#000',
    backgroundColor: '#fff',
    buttonsDividersColor: '#000',
    sicailLinksColor: '#0779be',
  },
  components: [
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<h1 class="ql-align-center" style="color:#0779be; font-style:italic;">Máte na to!</h1>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Mrkněte na naše speciální nápoje a aktualizované menu.</p>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center" style="font-style:italic; font-weight:bold;">Zavolejte nám a rezervujte si stůl ještě dnes!</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_13.+Move+It+Sports+Template.jpg',
    },
  ],
};

const PetShopEmailData = {
  styles: {
    width: '600px',
    color: '#898989',
    backgroundColor: '#fff',
    couponBorderColor: '#00aeef',
    buttonsDividersColor: '#898989',
    sicailLinksColor: '#00aeef',
  },
  components: [
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_28.+Pet+Shop+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center">Vždy velký výběr!</h2>',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center">Sleva 10 % na vaši příští objednávku</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Vše pro vaše domácí mazlíčky včetně odborného poradenství.</p>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">U nás nakoupíte přesně to, co váš domácí mazlíček opravdu potřebuje.</p>',
    },
    {
      type: 'coupon',
      text: '<h2 class="ql-align-center">Sleva 10 % na vaši příští objednávku</h2><p class="ql-align-center" style="font-size: 10px;">Nelze kombinovat s jinými slevami nebo nabídkami.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const PizzaEmailData = {
  styles: {
    width: '600px',
    color: '#000',
    backgroundColor: '#fdd85c',
    couponBorderColor: '#eb6464',
    buttonsDividersColor: '#eb6464',
    sicailLinksColor: '#000',
  },
  components: [
    {
      type: 'image',
      image: 'em-logos/pizza-email-logo_1498082059029.png',
    },
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_14.+Pizza+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center" style="font-style:italic">1+1 ZDARMA!</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Od hub a feferonek po olivy a ančovičky, výběr je na vás. Objednejte si jakoukoli pizzu a druhou dostanete ZDARMA!</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Stačí nám ukázat tento email pro získání výhodné nabídky.</p>',
    },
    {
      type: 'coupon',
      text: '<h2 class="ql-align-center">1+1 ZDARMA!</h2><p class="ql-align-center">(za stejnou nebo nižší cenu)</p><br><p class="ql-align-center" style="font-size: 10px;">Nelze kombinovat s jinými slevami a zvýhodněnými nabídkami.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const QsrEmailData = {
  styles: {
    width: '600px',
    color: '#000',
    backgroundColor: '#fff',
    couponBorderColor: '#000',
    buttonsDividersColor: '#000',
    sicailLinksColor: '#000',
  },
  components: [
    {
      type: 'image',
      image: 'em-logos/burger_1505312326850_1505312462526.png',
    },
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_15.+QSR+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center" style="font-style:italic; color:#aa181c;">V PÁTEK HRANOLKY ZDARMA</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Milujeme hranolky. Milujete hranolky. Vsadíme se, že hranolky ZDARMA budete milovat ještě víc.</p>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Objednejte si jakýkoli hamburger nebo sendvič a dostanete velké hranolky zdarma.</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Pospěšte si, nabídka platí jen v březnu!</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'coupon',
      text: '<h3 class="ql-align-center" style="color:#aa181c;">VELKÉ HRANOLKY ZDARMA!</h3><p class="ql-align-center">(při koupi jakéhokoli hamburgeru nebo sendviče)</p><br><p class="ql-align-center" style="font-size: 10px;">Nelze kombinovat s jinými slevami a zvýhodněnými nabídkami.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const SushiEmailData = {
  styles: {
    width: '600px',
    color: '#fff',
    backgroundColor: '#231f20',
    couponBorderColor: '#fff',
    buttonsDividersColor: '#fff',
    sicailLinksColor: '#fff',
  },
  components: [
    {
      type: 'image',
      image: 'em-logos/sushi-email-logo_1498082220393.png',
    },
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_16.+Sushi+Template.jpg',
    },
    {
      type: 'text',
      text: '<h2 class="ql-align-center" style="font-style:italic;">POLOVIČNÍ CENY V RÁMCI HAPPY HOUR!</h2>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Vybrané sushi a předkrmy od 99 Kč do 249 Kč!</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Podívejte se také na naše speciální nápoje a aktualizované menu.</p>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Zavolejte nám a rezervujte si stůl ještě dnes!</p>',
    },
    {
      type: 'coupon',
      text: '<h4 class="ql-align-center">KUPTE SI JEDNU ROLKU, ZÍSKEJTE DRUHOU ZA POLOVICI</h4><p class="ql-align-center">(za stejnou nebo nižší cenu)</p><br><p class="ql-align-center" style="font-size: 10px;">Omezení na 1 zákazníka. Nelze kombinovat s jinými slevami a zvýhodněnými nabídkami.</p>',
    },
    {
      type: 'divider',
    },
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
  ],
};

const TimberlineEmailData = {
  styles: {
    width: '600px',
    color: '#000',
    backgroundColor: '#fff',
    sicailLinksColor: '#c58fbb',
    buttonsDividersColor: '#000',
  },
  components: [
    {
      type: 'social',
      links: {
        facebook_link: 'https:www.facebook.com/',
        twitter_link: 'https:twitter.com/',
        youtube_link: 'https:www.youtube.com/',
      },
    },
    {
      type: 'spacer',
    },
    {
      type: 'text',
      text: '<h1 class="ql-align-center" style="font-style:italic;">KONEC LETNÍHO VÝPRODEJE</h1>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center">Letní slunce pomalu zapadá a my pro vás máme poslední letní potěšení. Tento týden máme pro vás připravenou akci 1+1 zdarma.</p>',
    },
    {
      type: 'text',
      text: '<p class="ql-align-center" style="font-style:italic; font-weight:bold;">Nakupujte ve výprodeji!</p>',
    },
    {
      type: 'spacer',
    },
    {
      type: 'image',
      image: 'em-email-images/common/GP_550x300_17.+Timberline+Template.jpg',
    },
  ],
};

const BlankEmailData = {
  styles: {
    width: '600px',
    color: '#000',
    backgroundColor: '#fff',
    buttonsDividersColor: '#000',
    sicailLinksColor: '#000',
  },
  components: [
  ],
};

export const czStaticTemplates = [
  {
    id: '06twltb0ww9x3wd9tqjq',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateAutomotiveServiceShopTitle',
    status: 'origin',
    favorites: 'false',
    components: AutomotiveServiceShopEmailData.components,
    styles: AutomotiveServiceShopEmailData.styles,
    thumb: 'automotive-service-shop-email-preview-cz.jpg'
  },
  {
    id: '2or26236rrnh32hz7vuq',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateBakeryTitle',
    status: 'origin',
    favorites: 'false',
    components: BakeryEmailData.components,
    styles: BakeryEmailData.styles,
    thumb: 'bakery-email-preview-cz.jpg',
  },
  {
    id: 'ohbcim8ebgmdhlz2n3si',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateBarberShopTitle',
    status: 'origin',
    favorites: 'false',
    components: BarberShopEmailData.components,
    styles: BarberShopEmailData.styles,
    thumb: 'barber-shop-email-preview-cz.jpg',
  },
  {
    id: '3ntzsgz6egu5gt36kv3q',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateBarPubTitle',
    status: 'origin',
    favorites: 'false',
    components: BarPubEmailData.components,
    styles: BarPubEmailData.styles,
    thumb: 'bar-pub-email-preview-cz.jpg',
  },
  {
    id: '2npfuxl1uqljkthnzsfh',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateBeautyShopTitle',
    status: 'origin',
    favorites: 'false',
    components: BeautyShopEmailData.components,
    styles: BeautyShopEmailData.styles,
    thumb: 'beauty-shop-email-preview-cz.jpg',
  },
  {
    id: '70gtl79ctkr77w4wauof',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateBurgerTitle',
    status: 'origin',
    favorites: 'false',
    components: BurgerEmailData.components,
    styles: BurgerEmailData.styles,
    thumb: 'burger-email-preview-cz.jpg',
  },
  {
    id: '2dufgf4568eglhc9plxh',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateChineseTitle',
    status: 'origin',
    favorites: 'false',
    components: ChineseEmailData.components,
    styles: ChineseEmailData.styles,
    thumb: 'chinese-email-preview-cz.jpg',
  },
  {
    id: 'c6yk70kxfewboedik9s5',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateClothingShopTitle',
    status: 'origin',
    favorites: 'false',
    components: ClothingShopEmailData.components,
    styles: ClothingShopEmailData.styles,
    thumb: 'clothing-shop-email-preview-cz.jpg',
  },
  {
    id: 'o0x3qfxwatisc0agvmd8',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateCoffeeTitle',
    status: 'origin',
    favorites: 'false',
    components: CoffeeEmailData.components,
    styles: CoffeeEmailData.styles,
    thumb: 'coffee-email-preview-cz.jpg',
  },
  {
    id: 'd2660h7r2lorgeyhrx6b',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateDessertTitle',
    status: 'origin',
    favorites: 'false',
    components: DessertEmailData.components,
    styles: DessertEmailData.styles,
    thumb: 'dessert-email-preview-cz.jpg',
  },
  {
    id: 'm5ob2n9kacxtffusb8ck',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateDinerTitle',
    status: 'origin',
    favorites: 'false',
    components: DinerEmailData.components,
    styles: DinerEmailData.styles,
    thumb: 'diner-email-preview-cz.jpg',
  },
  {
    id: '9hw3q4o8f3dnbzk2tpcs',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateFamilyDiningTitle',
    status: 'origin',
    favorites: 'false',
    components: FamilyDiningEmailData.components,
    styles: FamilyDiningEmailData.styles,
    thumb: 'family-dining-email-preview-cz.jpg',
  },
  {
    id: 'bm1f7lho6b77jhsm0f1b',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateFineDiningTitle',
    status: 'origin',
    favorites: 'false',
    components: FineDiningEmailData.components,
    styles: FineDiningEmailData.styles,
    thumb: 'fine-dining-email-preview-cz.jpg',
  },
  {
    id: 'agw2jd2xlfctbzejk28b',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateFloristsTitle',
    status: 'origin',
    favorites: 'false',
    components: FloristsEmailData.components,
    styles: FloristsEmailData.styles,
    thumb: 'florists-email-preview-cz.jpg',
  },
  {
    id: '8778dy1linh95ebeo35y',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateFruitTitle',
    status: 'origin',
    favorites: 'false',
    components: FruitEmailData.components,
    styles: FruitEmailData.styles,
    thumb: 'fruit-email-preview-cz.jpg',
  },
  {
    id: 'wd2ht0kwm61uhwecf81k',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateItalianTitle',
    status: 'origin',
    favorites: 'false',
    components: ItalianEmailData.components,
    styles: ItalianEmailData.styles,
    thumb: 'italian-email-preview-cz.jpg',
  },
  {
    id: 'ih68rtqyjdn81arz9hbo',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateMexicanTitle',
    status: 'origin',
    favorites: 'false',
    components: MexicanEmailData.components,
    styles: MexicanEmailData.styles,
    thumb: 'mexican-email-preview-cz.jpg',
  },
  {
    id: 'yea5u921ve2wwod97nwd',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateMoveItSportsTitle',
    status: 'origin',
    favorites: 'false',
    components: MoveItSportsEmailData.components,
    styles: MoveItSportsEmailData.styles,
    thumb: 'move-it-sports-email-preview-cz.jpg',
  },
  {
    id: 'tp5wwv36nb0uuc8qdh5o',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplatePetShopTitle',
    status: 'origin',
    favorites: 'false',
    components: PetShopEmailData.components,
    styles: PetShopEmailData.styles,
    thumb: 'pet-shop-email-preview-cz.jpg',
  },
  {
    id: 'jc9g5uxfj9b2z1177i1j',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplatePizzaTitle',
    status: 'origin',
    favorites: 'false',
    components: PizzaEmailData.components,
    styles: PizzaEmailData.styles,
    thumb: 'pizza-email-preview-cz.jpg',
  },
  {
    id: 'rrurh9rjqo2fg1ew17i5',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateQSRTitle',
    status: 'origin',
    favorites: 'false',
    components: QsrEmailData.components,
    styles: QsrEmailData.styles,
    thumb: 'qsr-email-preview-cz.jpg',
  },
  {
    id: 'wdc7wpdkj5mnvrsurcm1',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateSushiTitle',
    status: 'origin',
    favorites: 'false',
    components: SushiEmailData.components,
    styles: SushiEmailData.styles,
    thumb: 'sushi-email-preview-cz.jpg',
  },
  {
    id: 'ssdy3o1vk7cfxu9fg52g',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateTimberlineTitle',
    status: 'origin',
    favorites: 'false',
    components: TimberlineEmailData.components,
    styles: TimberlineEmailData.styles,
    thumb: 'timberline-email-preview-cz.jpg',
  },
  {
    id: 'kem3qb5keay31jnvzpk1',
    crmid: '',
    name: '_i18n_StaticTemplates.StaticTemplateBlankTitle',
    status: 'origin',
    favorites: 'false',
    components: BlankEmailData.components,
    styles: BlankEmailData.styles,
    thumb: 'blank-cz.png',
  },
];
