export interface CampaignData {
  name: string;
  date: string;
  checkedContacts: number[];
  selectAllMode: boolean;
  userTimezone: string;
  memberTypes: ContactMemberType[];
}

export enum ContactMemberType {
  EMAIL_MARKETING = 'Email Marketing',
  LOYALTY = 'loyalty'
}

export const CONTACT_MEMBER_TYPES_LENGTH = 2;
