import { ActionType, createReducer } from 'typesafe-actions';
import * as types from './types';
import { helpersModeActions } from './actions';

const defaultHelpersModeState: types.HelpersModeState = {
  isHelpersModeEnabled: false
};

const { setHelpersMode } = helpersModeActions;

export const helpersModeReducer = createReducer<types.HelpersModeState, ActionType<typeof helpersModeActions>>(
  defaultHelpersModeState
).handleAction(setHelpersMode, (state, action) => ({
  ...state,
  isHelpersModeEnabled: action.payload
}));
