import { createAction, createAsyncAction } from 'typesafe-actions';

const setContactsList = createAction('@em/contacts/list/set', action => (data: any[]) => action({ data }));

const fetchContacts = createAsyncAction(
  '@em/contacts/active/fetch/request',
  '@em/contacts/active/fetch/success',
  '@em/contacts/active/fetch/error'
)<any, any, void>();

const fetchContactsCount = createAsyncAction(
  '@em/contactsCount/fetch/request',
  '@em/contactsCount/fetch/success',
  '@em/contactsCount/fetch/error'
)<any, any, void>();

const createContact = createAsyncAction(
  '@em/contacts/create/request',
  '@em/contacts/create/success',
  '@em/contacts/create/error'
)<any, void, void>();

const updateContact = createAsyncAction(
  '@em/contacts/update/request',
  '@em/contacts/update/success',
  '@em/contacts/update/error'
)<any, void, void>();

const deleteContact = createAsyncAction(
  '@em/contacts/delete/request',
  '@em/contacts/delete/success',
  '@em/contacts/delete/error'
)<any, any, void>();

const importContacts = createAsyncAction(
  '@em/contacts/import/request',
  '@em/contacts/import/success',
  '@em/contacts/import/error'
)<any, any, void>();

const setEmailExistsAsCompanyEmailModalState = createAction(
  '@em/emailExistsAsCompanyEmailModalState/set',
  action => (state: boolean) => action(state)
);

const setEmailExistsAsCompanyEmailError = createAction(
  '@em/emailExistsAsCompanyEmailError/set',
  action => () => action()
);

const setTermsAndConditionsAcceptState = createAction(
  '@em/contacts/termsAndConditions/accept',
  action => (value: boolean) => action({ value })
);

const setActiveSortFieldAndOrder = createAction(
  '@em/contacts/active/sort/set',
  action => (sort: string) => action(sort)
);

const setValidationError = createAction(
  '@em/contacts/active/validationErrors/set',
  action => (field: string, value: string) => action({ field, value })
);

const clearValidationErrors = createAction('@em/contacts/active/validationErrors/clear', action => () => action());

export const activeContactsActions = {
  fetchContacts,
  fetchContactsCount,
  setContactsList,
  createContact,
  updateContact,
  deleteContact,
  importContacts,
  setEmailExistsAsCompanyEmailModalState,
  setTermsAndConditionsAcceptState,
  setActiveSortFieldAndOrder,
  setValidationError,
  clearValidationErrors,
  setEmailExistsAsCompanyEmailError
};
