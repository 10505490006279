import { put, call, takeEvery, all } from 'redux-saga/effects';
import fileSaver from 'file-saver';
import { ApiGateway } from 'services/apiGateway';
import { pdfPuppeteerActions } from './actions';

export function* getPdfPuppeteerSaga({ payload }: ReturnType<typeof pdfPuppeteerActions.generatePdf.request>) {
  try {
    const pdfFile = yield call(ApiGateway.generatePdfFromPuppeteer, {
      html: payload.html,
      options: payload.options
    });

    fileSaver.saveAs(pdfFile, `${payload.fileName}.pdf`);

    yield put(pdfPuppeteerActions.generatePdf.success());
  } catch (error) {
    yield put(pdfPuppeteerActions.generatePdf.failure(error));
  }
}

export function* PdfPuppeteerSaga() {
  yield all([takeEvery(pdfPuppeteerActions.generatePdf.request, getPdfPuppeteerSaga)]);
}

PdfPuppeteerSaga.id = 'getPdfPuppeteerSaga';
