import { ActionType, createReducer } from 'typesafe-actions';
import { scoreActions } from './actions';
import { ScoreState } from './types';

const initialState: ScoreState = {
  loading: false,
  error: false,
  data: undefined
};

export const scoreReducer = createReducer<ScoreState, ActionType<typeof scoreActions>>(initialState)
  .handleAction(scoreActions.fetchScore.request, state => ({ ...state, loading: true, error: false }))
  .handleAction(scoreActions.fetchScore.success, (state, action) => ({
    ...state,
    loading: false,
    error: false,
    data: action.payload
  }))
  .handleAction(scoreActions.fetchScore.failure, () => ({ ...initialState, loading: false, error: true }))
  .handleAction(scoreActions.resetScore, () => initialState);
