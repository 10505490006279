import { AppState } from 'store/rootReducer';
import moment from 'moment';
import { getLocationsMapByMid, getSelectedLocations } from 'store/settings';
import { calculatePercents, preparePercentsInProportion, calculateStars } from 'components/CIComparisonTable/utils';
import { INTERNATIONAL_DATE_FORMAT } from 'appConstants';
import {
  TrendingMarketingScoreCompareData,
  ReviewsCompareData,
  ComparisonTableOptions,
  ReviewsCompareResponse
} from './types';

export const getMerchantCentricCompareDataBySelectedLocations = (
  state: AppState
): TrendingMarketingScoreCompareData[] => {
  try {
    const locationsInfo = getLocationsMapByMid(state);
    const selectedLocations = getSelectedLocations(state);

    return state.merchantCentric.compare.compareData
      .map(data => {
        const quarterlyTrend = data.monthsAgo3 ? calculatePercents(data.current, data.monthsAgo3) : null;
        const yearTrend = data.monthsAgo12 ? calculatePercents(data.current, data.monthsAgo12) : null;

        return {
          ...data,
          quarterlyTrend,
          yearTrend,
          locationInfo: locationsInfo[data.mid]
        };
      })
      .filter(({ locationInfo }) => selectedLocations.includes(locationInfo.merchant_sequence_key));
  } catch (e) {
    return [];
  }
};

const calculateReviewsQuarterlyTrend = (data: ReviewsCompareResponse) => {
  if (
    data.starRatingCurrent &&
    data.starRating3MonthsAgo &&
    data.starRating3MonthsAgo &&
    data.totalReviews3MonthsAgoCount
  ) {
    const currentStarRating = calculateStars(data.starRatingCurrent, data.totalReviewsCount);
    const previousStarRating = calculateStars(data.starRating3MonthsAgo, data.totalReviews3MonthsAgoCount);
    return currentStarRating && previousStarRating ? calculatePercents(currentStarRating, previousStarRating) : null;
  }
  return null;
};

export const getMerchantCentricReviewsCompareDataBySelectedLocations = (state: AppState): ReviewsCompareData[] => {
  try {
    const locationsInfo = getLocationsMapByMid(state);
    const selectedLocations = getSelectedLocations(state);
    return state.merchantCentric.compare.reviewsCompareData
      .map(data => {
        const quarterlyTrend = calculateReviewsQuarterlyTrend(data);
        const avgStarRating = calculateStars(data.starRatingCurrent, data.totalReviewsCount);
        return {
          ...data,
          stars: calculateStars(data.starRatingCurrent, data.totalReviewsCount),
          positivePercent: preparePercentsInProportion(data.positiveReviewsCount, data.totalReviewsCount),
          neutralPercent: preparePercentsInProportion(data.neutralReviewsCount, data.totalReviewsCount),
          negativePercent: preparePercentsInProportion(data.negativeReviewsCount, data.totalReviewsCount),
          locationInfo: locationsInfo[data.mid],
          quarterlyTrend,
          avgStarRating
        };
      })
      .filter(({ locationInfo }) => selectedLocations.includes(locationInfo.merchant_sequence_key));
  } catch (e) {
    return [];
  }
};

export const getFilteredReviewsCompareData = (
  state: AppState
): {
  filteredTrending: ReviewsCompareData[];
  filteredScore: ReviewsCompareData[];
} => {
  const reviewsCompareData = getMerchantCentricReviewsCompareDataBySelectedLocations(state);

  const filteredTrending: ReviewsCompareData[] = [];
  const filteredScore: ReviewsCompareData[] = [];

  reviewsCompareData.forEach(locationData => {
    if (locationData.totalReviewsCount) {
      if (locationData.quarterlyTrend !== null) filteredTrending.push(locationData);
      if (locationData.starRatingCurrent !== null) filteredScore.push(locationData);
    }
  });

  return { filteredTrending, filteredScore };
};

export const getMerchantCentricCompareTableOptions = (state: AppState): ComparisonTableOptions =>
  state.merchantCentric.compare.comparisonTableOptions;

export const getMerchantCentricLastReceivedDate = (state: AppState): string => {
  const periods = state.merchantCentric.compare.receivedAtDates
    .map(receivedAt => moment(`${receivedAt}`))
    .filter(str => moment(str).isValid());
  // This ternary operator used since the expression moment.max([]) returns a moment object with current date
  return periods.length > 0 ? moment.max(periods).format(INTERNATIONAL_DATE_FORMAT) : '';
};
