import { ActionType, createReducer } from 'typesafe-actions';
import * as types from './types';
import { dayBookNoteActions } from './actions';

const defaultDayBookState: types.DayBookState = {
  notesList: [],
  loading: false
};

const { fetchList, updateItem, deleteItem, createItem } = dayBookNoteActions;

export const dayBookNoteReducer = createReducer<types.DayBookState, ActionType<typeof dayBookNoteActions>>(
  defaultDayBookState
)
  .handleAction(fetchList.request, state => ({ ...state, loading: false }))
  .handleAction(fetchList.success, (state, action) => ({ ...state, notesList: action.payload }))
  .handleAction(fetchList.failure, state => ({ ...state, loading: false }))
  .handleAction(createItem.request, state => ({ ...state, loading: true }))
  .handleAction(createItem.failure, state => ({ ...state, loading: false }))
  .handleAction(createItem.success, (state, action) => ({
    ...state,
    loading: false,
    notesList: [...state.notesList, action.payload.dayBookNote]
  }))
  .handleAction(updateItem.request, state => ({ ...state, loading: true }))
  .handleAction(updateItem.success, (state, action) => ({
    ...state,
    loading: false,
    notesList: state.notesList.map(note =>
      note._id !== action.payload.messageId
        ? note
        : {
            ...note,
            label: action.payload.label,
            merchantSequenceKeys: action.payload.merchantSequenceKeys,
            textNote: action.payload.textNote,
            noteDate: action.payload.noteDate,
            noteTime: action.payload.noteTime,
            type: action.payload.type
          }
    )
  }))
  .handleAction(updateItem.failure, state => ({ ...state, loading: false }))
  .handleAction(deleteItem.request, state => ({ ...state, loading: true }))
  .handleAction(deleteItem.success, (state, action) => ({
    ...state,
    loading: false,
    notesList: state.notesList.filter(note => note._id !== action.payload.messageId)
  }))
  .handleAction(deleteItem.failure, state => ({ ...state, loading: false }));
