import { ActionType, createReducer } from 'typesafe-actions';
import { RequestStatusEnum, UberallState } from './types';
import { uberallActions } from './actions';

const initialState: UberallState = {
  identity: {},
  signup: {},
  statistics: {},
  locationInfo: {},
  replies: {},
  reply: {},
  editLocation: {},
  transactions: {},
  disconnectDirectory: {}
};

export const uberallReducer = createReducer<UberallState, ActionType<typeof uberallActions>>(initialState)
  .handleAction(uberallActions.reset, () => initialState)
  .handleAction(
    uberallActions.fetchIdentityMapping.request,
    (state, { payload: { merchantNumber } }): UberallState => ({
      ...state,
      identity: {
        ...state.identity,
        [merchantNumber]: {
          ...(state.identity[merchantNumber] || {}),
          requestStatus: RequestStatusEnum.LOADING
        }
      }
    })
  )
  .handleAction(
    uberallActions.fetchIdentityMapping.success,
    (state, { payload: { merchantNumber, data } }): UberallState => ({
      ...state,
      identity: {
        ...state.identity,
        [merchantNumber]: {
          ...(state.identity[merchantNumber] || {}),
          requestStatus: RequestStatusEnum.SUCCEEDED,
          error: null,
          data
        }
      }
    })
  )
  .handleAction(
    uberallActions.fetchIdentityMapping.failure,
    (state, { payload: { merchantNumber, error } }): UberallState => ({
      ...state,
      identity: {
        ...state.identity,
        [merchantNumber]: {
          ...(state.identity[merchantNumber] || {}),
          requestStatus: RequestStatusEnum.FAILED,
          error,
          data: null
        }
      }
    })
  )
  .handleAction(
    uberallActions.fetchStatistics.request,
    (state, { payload: { uberallId } }): UberallState => ({
      ...state,
      statistics: {
        ...state.statistics,
        [uberallId]: {
          ...(state.statistics[uberallId] || {}),
          requestStatus: RequestStatusEnum.LOADING
        }
      }
    })
  )
  .handleAction(
    uberallActions.fetchStatistics.success,
    (state, { payload: { uberallId, data } }): UberallState => ({
      ...state,
      statistics: {
        ...state.statistics,
        [uberallId]: {
          ...(state.statistics[uberallId] || {}),
          requestStatus: RequestStatusEnum.SUCCEEDED,
          error: null,
          data
        }
      }
    })
  )
  .handleAction(
    uberallActions.fetchStatistics.failure,
    (state, { payload: { uberallId, error } }): UberallState => ({
      ...state,
      statistics: {
        ...state.statistics,
        [uberallId]: {
          ...(state.statistics[uberallId] || {}),
          requestStatus: RequestStatusEnum.FAILED,
          data: null,
          error
        }
      }
    })
  )
  .handleAction(
    uberallActions.fetchReplies.request,
    (state, { payload: { uberallId } }): UberallState => ({
      ...state,
      replies: {
        ...state.replies,
        [uberallId]: {
          ...(state.replies[uberallId] || {}),
          requestStatus: RequestStatusEnum.LOADING
        }
      }
    })
  )
  .handleAction(
    uberallActions.fetchReplies.success,
    (state, { payload: { uberallId, data } }): UberallState => ({
      ...state,
      replies: {
        ...state.replies,
        [uberallId]: {
          ...(state.replies[uberallId] || {}),
          requestStatus: RequestStatusEnum.SUCCEEDED,
          error: null,
          data: {
            ...(state.replies[uberallId]?.data || {}),
            ...data
          }
        }
      }
    })
  )
  .handleAction(
    uberallActions.fetchReplies.failure,
    (state, { payload: { uberallId, error } }): UberallState => ({
      ...state,
      replies: {
        ...state.replies,
        [uberallId]: {
          ...(state.replies[uberallId] || {}),
          requestStatus: RequestStatusEnum.FAILED,
          data: null,
          error
        }
      }
    })
  )
  .handleAction(
    uberallActions.signup.request,
    (state, { payload: { merchantNumber } }): UberallState => ({
      ...state,
      signup: {
        ...state.signup,
        [merchantNumber]: {
          ...(state.signup[merchantNumber] || {}),
          requestStatus: RequestStatusEnum.LOADING
        }
      }
    })
  )
  .handleAction(
    uberallActions.signup.success,
    (state, { payload: { merchantNumber } }): UberallState => ({
      ...state,
      signup: {
        ...state.signup,
        [merchantNumber]: {
          ...(state.signup[merchantNumber] || {}),
          errors: undefined,
          requestStatus: RequestStatusEnum.SUCCEEDED
        }
      }
    })
  )
  .handleAction(
    uberallActions.signup.failure,
    (state, { payload: { merchantNumber, errors, generalError } }): UberallState => ({
      ...state,
      signup: {
        ...state.signup,
        [merchantNumber]: {
          ...(state.signup[merchantNumber] || {}),
          errors,
          requestStatus: RequestStatusEnum.FAILED
        }
      }
    })
  )
  .handleAction(
    uberallActions.resetSignup,
    (state, { payload: { merchantNumber } }): UberallState => ({
      ...state,
      signup: {
        ...state.signup,
        [merchantNumber]: {
          ...(state.signup[merchantNumber] || {}),
          errors: undefined,
          requestStatus: RequestStatusEnum.IDLE,
          data: null
        }
      }
    })
  )
  .handleAction(
    uberallActions.fetchLocationInfo.request,
    (state, { payload: { uberallId } }): UberallState => ({
      ...state,
      locationInfo: {
        ...state.locationInfo,
        [uberallId]: {
          ...(state.locationInfo[uberallId] || {}),
          requestStatus: RequestStatusEnum.LOADING
        }
      }
    })
  )
  .handleAction(
    uberallActions.fetchLocationInfo.success,
    (state, { payload: { uberallId, data } }): UberallState => ({
      ...state,
      locationInfo: {
        ...state.locationInfo,
        [uberallId]: {
          ...(state.locationInfo[uberallId] || {}),
          error: undefined,
          requestStatus: RequestStatusEnum.SUCCEEDED,
          data
        }
      }
    })
  )

  .handleAction(
    uberallActions.fetchLocationInfo.failure,
    (state, { payload: { uberallId, error } }): UberallState => ({
      ...state,
      locationInfo: {
        ...state.locationInfo,
        [uberallId]: {
          ...(state.locationInfo[uberallId] || {}),
          error,
          requestStatus: RequestStatusEnum.FAILED
        }
      }
    })
  )
  .handleAction(
    uberallActions.sendReply.request,
    (state): UberallState => ({
      ...state,
      reply: {
        error: undefined,
        requestStatus: RequestStatusEnum.LOADING,
        data: null
      }
    })
  )
  .handleAction(
    uberallActions.sendReply.success,
    (state, { payload }): UberallState => ({
      ...state,
      reply: {
        error: undefined,
        requestStatus: RequestStatusEnum.SUCCEEDED,
        data: payload
      }
    })
  )
  .handleAction(
    uberallActions.sendReply.failure,
    (state, { payload }): UberallState => ({
      ...state,
      reply: {
        error: payload,
        requestStatus: RequestStatusEnum.FAILED,
        data: null
      }
    })
  )
  .handleAction(
    uberallActions.editLocation.request,
    (state, { payload }): UberallState => ({
      ...state,
      editLocation: {
        ...state.editLocation,
        [payload.uberallId]: {
          ...(state.editLocation[payload.uberallId] || {}),
          error: undefined,
          requestStatus: RequestStatusEnum.LOADING
        }
      }
    })
  )
  .handleAction(
    uberallActions.editLocation.success,
    (state, { payload }): UberallState => ({
      ...state,
      editLocation: {
        ...state.editLocation,
        [payload.uberallId]: {
          ...(state.editLocation[payload.uberallId] || {}),
          error: undefined,
          requestStatus: RequestStatusEnum.SUCCEEDED
        }
      }
    })
  )
  .handleAction(
    uberallActions.editLocation.failure,
    (state, { payload }): UberallState => ({
      ...state,
      editLocation: {
        ...state.editLocation,
        [payload.uberallId]: {
          ...(state.editLocation[payload.uberallId] || {}),
          error: payload.error,
          requestStatus: RequestStatusEnum.FAILED
        }
      }
    })
  )
  .handleAction(
    uberallActions.fetchTransactions.request,
    (state, { payload: { merchantNumber } }): UberallState => ({
      ...state,
      transactions: {
        ...state.transactions,
        [merchantNumber]: {
          ...(state.transactions[merchantNumber] || {}),
          requestStatus: RequestStatusEnum.LOADING
        }
      }
    })
  )
  .handleAction(
    uberallActions.fetchTransactions.success,
    (state, { payload: { merchantNumber, data } }): UberallState => ({
      ...state,
      transactions: {
        ...state.transactions,
        [merchantNumber]: {
          ...(state.transactions[merchantNumber] || {}),
          requestStatus: RequestStatusEnum.SUCCEEDED,
          error: null,
          data
        }
      }
    })
  )
  .handleAction(
    uberallActions.fetchTransactions.failure,
    (state, { payload: { merchantNumber, error } }): UberallState => ({
      ...state,
      transactions: {
        ...state.transactions,
        [merchantNumber]: {
          ...(state.transactions[merchantNumber] || {}),
          requestStatus: RequestStatusEnum.FAILED,
          error,
          data: null
        }
      }
    })
  )
  .handleAction(
    uberallActions.fetchDirecroriesConfig.request,
    (state): UberallState => ({
      ...state,
      directoriesConfig: {
        ...(state.directoriesConfig || {}),
        requestStatus: RequestStatusEnum.LOADING
      }
    })
  )
  .handleAction(
    uberallActions.fetchDirecroriesConfig.success,
    (state, { payload: { data } }): UberallState => ({
      ...state,
      directoriesConfig: {
        ...(state.directoriesConfig || {}),
        requestStatus: RequestStatusEnum.SUCCEEDED,
        data
      }
    })
  )
  .handleAction(
    uberallActions.fetchDirecroriesConfig.failure,
    (state, { payload: { error } }): UberallState => ({
      ...state,
      directoriesConfig: {
        ...(state.directoriesConfig || {}),
        requestStatus: RequestStatusEnum.FAILED,
        error
      }
    })
  )
  .handleAction(
    uberallActions.disconnectDirectory.request,
    (state): UberallState => ({
      ...state,
      disconnectDirectory: {
        ...(state.disconnectDirectory || {}),
        requestStatus: RequestStatusEnum.LOADING
      }
    })
  )
  .handleAction(
    uberallActions.disconnectDirectory.success,
    (state): UberallState => ({
      ...state,
      disconnectDirectory: {
        ...(state.disconnectDirectory || {}),
        requestStatus: RequestStatusEnum.SUCCEEDED
      }
    })
  )
  .handleAction(
    uberallActions.disconnectDirectory.failure,
    (state): UberallState => ({
      ...state,
      disconnectDirectory: {
        ...(state.disconnectDirectory || {}),
        requestStatus: RequestStatusEnum.FAILED
      }
    })
  );
