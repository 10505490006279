import i18n from 'i18n';

export const declineCodes = ['05', '101', '102', '103', '111', '14', '55', '65', 'EC'];

const declineCodesTranslationsDefaultValue: { [key: string]: string } = {
  14: 'CARD NO. ERROR',
  55: 'WRONG PIN',
  65: 'CHIP READ REQ., INSERT CARD',
  101: 'VARIOUS DECLINE',
  102: 'CALL AUTH CENTRE',
  103: 'DECLINE & PICKUP',
  111: 'Strong Customer Authentication Required',
  '05': 'DECLINE',
  EC: 'CID FORMAT ERROR'
};

export const getDeclineCodesOptions = (labelWithoutCode?: boolean) =>
  declineCodes.map(code => ({
    value: `${code}`,
    label: labelWithoutCode
      ? i18n.t(`_i18n_DeclineCodes.${code}`, declineCodesTranslationsDefaultValue[code] || '')
      : `${code} ${i18n.t(`_i18n_DeclineCodes.${code}`, declineCodesTranslationsDefaultValue[code] || '')}`
  }));
