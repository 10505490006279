import { combineReducers } from 'redux';
import { StateType } from 'typesafe-actions';
import { emailTemplateEditorReducer } from './emailTemplateEditor';
import { contactsReducer } from './contacts';
import { emailMarketingSettingsReducer } from './settings';
import { emCampaignsReducer } from './campaigns';
import { campaignEditorReducer } from './campaignEditor';
import { errorReducer } from './error';
import { AutomationReducer } from './automation';
import { emailMarketingCrmAutomationReducer } from './crmAutomation';

export const emailMarketingReducer = combineReducers({
  emailTemplateEditor: emailTemplateEditorReducer,
  campaignEditor: campaignEditorReducer,
  contacts: contactsReducer,
  settings: emailMarketingSettingsReducer,
  campaigns: emCampaignsReducer,
  error: errorReducer,
  automation: AutomationReducer,
  crmAutomation: emailMarketingCrmAutomationReducer
});

export type EmailMarketingState = StateType<typeof emailMarketingReducer>;
