import React from 'react';
import styled from 'styled-components';
import { ReactComponent as DefaultAccountComponent } from 'assets/icons/default-profile.svg';
import { getColor } from '../theme';

import { centered } from '../mixins';

export interface ProfileIconProps {
  className?: string;
  firstLetter: string;
  lastLetter: string;
  isUserNameIsNotEmpty: boolean;
  onClick?: (e: React.MouseEvent) => void;
}

export const ProfileIconComponent = (props: ProfileIconProps) => {
  const { className, firstLetter, lastLetter, isUserNameIsNotEmpty, onClick } = props;

  return (
    <div role="presentation" onClick={onClick} className={className} data-test-id="profile-icon">
      {!isUserNameIsNotEmpty && <DefaultAccountComponent />}
      {isUserNameIsNotEmpty && `${firstLetter}${lastLetter}`}
    </div>
  );
};

export const setActiveState = (props: { IsActive?: boolean }) => {
  if (props.IsActive) {
    return `
      background-color: ${getColor(props, 'darkGrey')};
      circle {
        fill: ${getColor(props, 'darkGrey')};
      }
    `;
  }
  return `
    background-color: ${getColor(props, 'headerColor')};
    circle {
      fill: ${getColor(props, 'headerColor')};
    }
  `;
};
export const ProfileIcon = styled(ProfileIconComponent)`
  ${centered};
  height: 40px;
  min-width: 40px;
  margin-right: 10px;
  border-radius: 100%;
  color: ${props => getColor(props, 'white')};
  font-weight: bold;
  text-transform: uppercase;
  ${setActiveState};
`;
