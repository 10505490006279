import { ActionType, createReducer } from 'typesafe-actions';
import { DEFAULT_SORT_ORDER, CONTACTS_PER_PAGE } from 'appConstants/emailMarketing';
import { activeContactsActions } from './actions';
import * as types from '../types';

const defaultState = {
  limit: CONTACTS_PER_PAGE,
  page: 0,
  keyword: '',
  totalCount: 0,
  overallCount: 0,
  sort: DEFAULT_SORT_ORDER,
  data: [],
  isTermsAndConditionsAccepted: false,
  showEmailExistsAsCompanyEmailModal: false,
  validationErrors: {},
  savingStatus: types.ContactSavingStatus.Pending
};

type Store = types.ContactsList;
type Actions = ActionType<typeof activeContactsActions>;

export const activeContactsReducer = createReducer<Store, Actions>(defaultState)
  .handleAction(activeContactsActions.setEmailExistsAsCompanyEmailModalState, (state, action) => ({
    ...state,
    showEmailExistsAsCompanyEmailModal: action.payload
  }))
  .handleAction(activeContactsActions.setEmailExistsAsCompanyEmailError, state => ({
    ...state,
    saving: false,
    savingStatus: types.ContactSavingStatus.Failure
  }))
  .handleAction(activeContactsActions.setContactsList, (state, action) => ({
    ...state,
    data: action.payload.data
  }))
  .handleAction(activeContactsActions.fetchContacts.request, state => ({ ...state, loading: true, fetched: true }))
  .handleAction(activeContactsActions.fetchContacts.success, (state, action) => ({
    ...state,
    ...action.payload,
    loading: false
  }))
  .handleAction(activeContactsActions.fetchContacts.failure, state => ({ ...state, loading: false }))
  .handleAction(activeContactsActions.createContact.request, state => ({
    ...state,
    saving: true,
    savingStatus: types.ContactSavingStatus.Pending
  }))
  .handleAction(activeContactsActions.createContact.success, state => ({
    ...state,
    saving: false,
    savingStatus: types.ContactSavingStatus.Success
  }))
  .handleAction(activeContactsActions.createContact.failure, state => ({
    ...state,
    saving: false,
    savingStatus: types.ContactSavingStatus.Failure
  }))
  .handleAction(activeContactsActions.updateContact.request, state => ({
    ...state,
    saving: true,
    savingStatus: types.ContactSavingStatus.Pending
  }))
  .handleAction(activeContactsActions.updateContact.success, state => ({
    ...state,
    saving: false,
    savingStatus: types.ContactSavingStatus.Success
  }))
  .handleAction(activeContactsActions.updateContact.failure, state => ({
    ...state,
    saving: false,
    savingStatus: types.ContactSavingStatus.Failure
  }))
  .handleAction(activeContactsActions.setTermsAndConditionsAcceptState, (state, action) => ({
    ...state,
    isTermsAndConditionsAccepted: action.payload.value
  }))
  .handleAction(activeContactsActions.setActiveSortFieldAndOrder, (state, action) => ({
    ...state,
    sort: action.payload
  }))
  .handleAction(activeContactsActions.setValidationError, (state, action) => ({
    ...state,
    saving: false,
    validationErrors: {
      ...state.validationErrors,
      [action.payload.field]: action.payload.value
    }
  }))
  .handleAction(activeContactsActions.clearValidationErrors, state => ({ ...state, validationErrors: {} }));
