import React, { ReactElement } from 'react';
import { Button as MailBoxButtonData } from '../../../store/emailMarketing/emailTemplateEditor';
import { MailBoxProps } from '../types';

export const Button = (props: MailBoxProps<MailBoxButtonData>): ReactElement => {
  const { boxData, styles = {}, translations = {} } = props;

  const { color = '' } = styles;

  const ownStyles = {
    color,
    display: 'inline-block',
    border: '2px solid',
    padding: '14px',
    textDecoration: 'none',
    borderRadius: '8px',
    margin: '10px'
  };

  const link = boxData.link || 'https://';
  const title = boxData.title || translations.editorItemsButtonName;

  return (
    <div style={styles}>
      <a rel="noopener noreferrer" target="_blank" style={ownStyles} href={link}>
        {title}
      </a>
    </div>
  );
};
