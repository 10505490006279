import styled from 'styled-components';
import React from 'react';
import ReactTooltip from 'react-tooltip';
import i18n from 'i18n';
import { getColor } from '../../layout/theme';
import { ReactComponent as FavoriteIcon } from '../../assets/icons/favorites.svg';
import { generateUID } from '../../services/utils';

const SaveFilterContainer = styled.div`
  margin-left: auto;
  button {
    display: flex;
    align-items: center;
    svg {
      width: 16px;
      height: 16px;
      margin: 0 5px;
      [fill^='#'] {
        fill: ${props => getColor(props, 'color2')};
      }
    }
  }
`;

interface Props {
  onSelectAll?: () => void;
  onClearSelection?: () => void;
  onSaveFilter?: () => void;
  selectedLocationsLength: number;
  totalLocationsLength: number;
  allowSaveFilter?: boolean;
  isSupportSearch: boolean;
  onlySelectedShown?: boolean;
}

export const ActionsBtns = (props: Props) => {
  const {
    onSelectAll,
    onClearSelection,
    onSaveFilter,
    selectedLocationsLength,
    totalLocationsLength,
    allowSaveFilter,
    isSupportSearch,
    onlySelectedShown
  } = props;
  const actions = [];
  if (typeof onSelectAll === 'function') {
    actions.push(() => (
      <button
        key="locationSelectorSelectAllBtn"
        type="button"
        onClick={onSelectAll}
        disabled={selectedLocationsLength === totalLocationsLength || onlySelectedShown}
        data-test-id="select-all"
      >
        {i18n.t('selectAll', 'Select All')}
      </button>
    ));
  }

  if (typeof onClearSelection === 'function') {
    actions.push(() => (
      <button
        key="locationSelectorClearSelectionBtn"
        type="button"
        onClick={onClearSelection}
        disabled={selectedLocationsLength === 0}
        data-test-id="clear-selected"
      >
        {i18n.t('resetSelection', 'Clear Selected')}
      </button>
    ));
  }

  if (typeof onSaveFilter === 'function' && !!allowSaveFilter) {
    const hintId = generateUID();

    actions.push(() => (
      <SaveFilterContainer key="locationSelectorSaveFilterBtn">
        <div
          data-tip={i18n.t(
            'SupportSearchUserLimitation',
            'As a security precaution, this button has been disabled for support users.'
          )}
          data-for={hintId}
        >
          <button
            type="button"
            disabled={isSupportSearch || selectedLocationsLength === 0}
            onClick={onSaveFilter}
            data-test-id="save-filter"
          >
            <FavoriteIcon />
            {i18n.t('newLocationSelectorSaveAction', 'Save Filter')}
          </button>
          {isSupportSearch && <ReactTooltip id={hintId} multiline effect="solid" place="bottom" />}
        </div>
      </SaveFilterContainer>
    ));
  }
  return actions;
};
