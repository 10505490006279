import { Store } from 'redux';
import { put, takeLatest, select } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { push } from 'connected-react-router';
import { get } from 'lodash';
import { setupNotAuthorizedResponseInterceptor } from '../../services/apiGateway';
import { apiGatewayActions } from './actions';
import { handleErrorCodes } from './index';

export function* apiGatewaySagas(store: Store) {
  yield takeLatest(
    getType(apiGatewayActions.requestError),
    function* ({ payload }: ReturnType<typeof apiGatewayActions.requestError>) {
      const { status, response } = payload;
      const state = yield select();

      setupNotAuthorizedResponseInterceptor(store);

      if (handleErrorCodes.includes(status)) {
        const path = response.showOops ? '/oops' : `/${status}`;
        if (get(state, 'router.location.pathname') !== path) {
          yield put(push(response.showOops ? '/oops' : `/${status}`));
        }
      } else {
        yield put({ type: 'Unhandled apiGateway request error' });
      }
    }
  );
}
