import { ActionType, createReducer } from 'typesafe-actions';
import { emailTemplateEditorActions } from './actions';
import * as types from './types';

const defaultMessageState = {
  hasMessage: false,
  title: '',
  body: '',
  linkTitle: '',
  linkTo: ''
};

const defaultEmailTemplateEditorState: types.EmailTemplateEditorState = {
  id: '',
  subject: '',
  preheader: '',
  displayName: '',
  styles: {},
  components: [],
  crmTemplateId: '',
  templateId: '',
  message: defaultMessageState,
  touched: false
};

const {
  setBoxes,
  setBackgroundColor,
  setTemplate,
  setSubjectAndPreheader,
  setMessage,
  dropMessage,
  resetEditor,
  removeBox,
  moveBox,
  updateBox,
  addBox
} = emailTemplateEditorActions;

type Store = types.EmailTemplateEditorState;
type Actions = ActionType<typeof emailTemplateEditorActions>;

export const emailTemplateEditorReducer = createReducer<Store, Actions>(defaultEmailTemplateEditorState)
  .handleAction(setBoxes, (state, action) => ({ ...state, components: action.payload.components }))
  .handleAction(setBackgroundColor, (state, action) => ({
    ...state,
    styles: { ...state.styles, backgroundColor: action.payload.color }
  }))
  .handleAction(setSubjectAndPreheader, (state, action) => ({ ...state, ...action.payload.data, touched: true }))
  .handleAction(setMessage, (state, action) => ({ ...state, message: { hasMessage: true, ...action.payload.message } }))
  .handleAction(dropMessage, state => ({ ...state, message: defaultMessageState }))
  .handleAction(resetEditor, () => ({ ...defaultEmailTemplateEditorState }))
  .handleAction(removeBox, state => ({ ...state, touched: true }))
  .handleAction(moveBox, state => ({ ...state, touched: true }))
  .handleAction(updateBox, state => ({ ...state, touched: true }))
  .handleAction(addBox, state => ({ ...state, touched: true }))
  .handleAction(setTemplate, (state, action) => {
    const { template = {} } = action.payload;

    const {
      subject = '',
      preheader = '',
      fromName = '',
      components = [],
      styles = {},
      crmTemplateId = '',
      templateId = '',
      id = ''
    } = template;

    return {
      ...state,
      subject,
      preheader,
      displayName: fromName,
      components,
      styles,
      crmTemplateId,
      templateId,
      id
    };
  });
