import { put, call, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { getType } from 'typesafe-actions';
import { get, sortBy } from 'lodash';
import { ApplicationStore } from '../utils';
import { USER_NAME_LOCAL_STORAGE_KEY } from '../../appConstants';
import { authDashboardActions } from './actions';
import {
  ApiGateway,
  setupAuthorizedResponseInterceptor,
  setupNotAuthorizedResponseInterceptor
} from '../../services/apiGateway';
import { getValidPages } from '../settings';

const sendAuthDashboardRequestSaga = (store: ApplicationStore) =>
  function* (action: ReturnType<typeof authDashboardActions.request>) {
    try {
      setupNotAuthorizedResponseInterceptor(store);
      localStorage.removeItem('leftHandSideMenuWasOpened');
      localStorage.setItem('googleAnalyticsFirstLoginFlag', 'true');
      const response = yield call(ApiGateway.authDashboard, action.payload);
      const firstName = `${get(response, 'firstName')}`.trim();
      const lastName = `${get(response, 'lastName')}`.trim();
      const page = get(response, 'page', '/');

      yield put(authDashboardActions.success({ firstName, lastName, page }));
    } catch (e) {
      console.log(e);
      yield put(authDashboardActions.failure());
    }
  };

const successfullyAuthorizedSaga = (store: any) =>
  function* (action: ReturnType<typeof authDashboardActions.success>) {
    setupAuthorizedResponseInterceptor(store);

    const { page, firstName, lastName } = action.payload;

    try {
      localStorage.setItem(USER_NAME_LOCAL_STORAGE_KEY, JSON.stringify({ firstName, lastName }));
    } catch (e) {
      console.log(e);
    }

    let defaultLink = '/';

    try {
      const response = yield call(ApiGateway.fetchSettingsData);
      const validPages = getValidPages(response.config.name, response.pages);
      const sortedPagesByOrder = sortBy(validPages, 'order');
      const firstPageWithoutTeasers = sortedPagesByOrder.filter(p => !p?.isTeaserPage)[0];

      if (sortedPagesByOrder.length && firstPageWithoutTeasers?.regularLink) {
        defaultLink = firstPageWithoutTeasers.path || '/';
      }
    } catch (e) {
      console.log(e);
    }

    window.location.replace(page || defaultLink); // for cookies to be applied

    yield put(push(page));
  };

export function* DashboardSagas(store: any) {
  yield takeLatest(getType(authDashboardActions.request), sendAuthDashboardRequestSaga(store));
  yield takeLatest(getType(authDashboardActions.success), successfullyAuthorizedSaga(store));
}
