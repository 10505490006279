import moment, { Moment } from 'moment';
import { localizationService } from './localization';

export type IncomeDate = Date | Moment | string;

export const dateFormatter = {
  dateMonthYear: (date: IncomeDate): string => localizationService.formatDate(date, 'dateMonthYear'),

  dateMonthShortYear: (date: IncomeDate): string => localizationService.formatDate(date, 'dateMonthShortYear'),

  dateMonthShort: (date: IncomeDate): string => localizationService.formatDate(date, 'dateMonthShort'),

  monthShort: (date: IncomeDate): string => localizationService.formatDate(date, 'monthShort'),

  monthShortYear: (date: IncomeDate): string => localizationService.formatDate(date, 'monthShortYear'),

  hoursFormat: (date: IncomeDate): string => localizationService.formatDate(date, 'hours'),

  weekDayShortMonthShortDateYear: (date: IncomeDate): string =>
    localizationService.formatDate(date, 'weekDayShortMonthShortDateYear'),

  dateTimeTimeZone: (date: IncomeDate): string =>
    localizationService.formatDate(date, 'dateMonthShortYearHoursAndMinutesTimezone'),

  dateTime: (date: IncomeDate): string => localizationService.formatDate(date, 'dateMonthShortYearHoursAndMinutes'),

  monthShortYearShortSeparated: (date: IncomeDate): string =>
    localizationService.formatDate(date, 'monthShortYearShortSeparated'),

  getListOfYears(start: Moment, end: Moment): string[] {
    const years = moment(end).diff(start, 'years');
    const yearsBetween = [];
    for (let year = 0; year < years; year += 1) {
      yearsBetween.push(start.add(1, 'year').get('years').toString());
    }
    return yearsBetween.reverse();
  },

  weekDayWithMonthDay: (date: IncomeDate) => moment(date).locale(localizationService.getLocale()).format('dddd DD'),
  weekDayWithMonthDayHours: (date: IncomeDate) =>
    `${dateFormatter.weekDayWithMonthDay(date)} - ${dateFormatter.hoursFormat(date)}`
};
