import { ActionType, createReducer } from 'typesafe-actions';
import { EmailMarketingSettings } from './types';
import { emailMarketingSettings } from './actions';

const defaultState: EmailMarketingSettings = {
  loading: false,
  locationData: {
    regions: [],
    countries: []
  },
  showGoToRewardsModal: false,
  activeVersionJWT: undefined,
  futureVersionJWT: undefined
};

export const emailMarketingSettingsReducer = createReducer<
  EmailMarketingSettings,
  ActionType<typeof emailMarketingSettings>
>(defaultState)
  .handleAction(emailMarketingSettings.fetchCountriesData.success, (state, action) => ({
    ...state,
    locationData: {
      ...state.locationData,
      ...action.payload
    }
  }))
  .handleAction(
    [emailMarketingSettings.fetchCustomerData.success, emailMarketingSettings.updateCustomerData.success],
    (state, action) => ({
      ...state,
      customer: { ...state.customer, ...action.payload },
      loading: false
    })
  )
  .handleAction(
    [emailMarketingSettings.fetchCustomerData.request, emailMarketingSettings.updateCustomerData.request],
    state => ({ ...state, loading: true })
  )
  .handleAction(emailMarketingSettings.setGoToRewardsModalState, (state, action) => ({
    ...state,
    showGoToRewardsModal: action.payload
  }))
  .handleAction(emailMarketingSettings.generateJWT.success, (state, action) => ({ ...state, ...action.payload }));
