import { createAsyncAction } from 'typesafe-actions';
import * as types from './types';

const fetchDayBookActions = createAsyncAction(
  '@dayBookNotes/fetch',
  '@dayBookNotes/fetch/success',
  '@dayBookNotes/fetch/error'
)<void, types.DayBookNotesList, void>();

const createDayBookActions = createAsyncAction(
  '@dayBookNotes/create',
  '@dayBookNotes/create/success',
  '@dayBookNotes/create/error'
)<types.DayBookNoteCreateData, types.DayBookNoteCreateResponse, void>();

const updateDayBookNoteActions = createAsyncAction(
  '@dayBookNotes/update',
  '@dayBookNotes/update/success',
  '@dayBookNotes/update/error'
)<types.UpdateDayBookNoteRequest, types.UpdateDayBookNoteRequest, void>();

const deleteDayBookNoteActions = createAsyncAction(
  '@dayBookNotes/delete',
  '@dayBookNotes/delete/success',
  '@dayBookNotes/delete/error'
)<types.DeleteDayBookNoteRequest, types.DeleteDayBookNoteRequest, void>();

export const dayBookNoteActions = {
  fetchList: fetchDayBookActions,
  createItem: createDayBookActions,
  updateItem: updateDayBookNoteActions,
  deleteItem: deleteDayBookNoteActions
};
