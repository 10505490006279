import { ActionType, createReducer } from 'typesafe-actions';
import { ReviewsSummaryState } from './types';
import { reviewsSummaryActions } from './actions';

const initialState: ReviewsSummaryState = {
  loading: false,
  error: false,
  data: null
};

export const reviewsSummaryReducer = createReducer<ReviewsSummaryState, ActionType<typeof reviewsSummaryActions>>(
  initialState
)
  .handleAction(reviewsSummaryActions.fetchReviewsSummary.request, state => ({ ...state, loading: true, error: false }))
  .handleAction(reviewsSummaryActions.fetchReviewsSummary.success, (state, action) => ({
    ...state,
    loading: false,
    error: false,
    data: action.payload
  }))
  .handleAction(reviewsSummaryActions.fetchReviewsSummary.failure, () => ({ ...initialState, error: true }))
  .handleAction(reviewsSummaryActions.resetReviewsSummary, () => ({ ...initialState }));
