import { createAsyncAction } from 'typesafe-actions';
import {
  PaymentsRequestPayload,
  PaymentsSalesGrossNetStatResponse,
  PaymentsSalesSummaryResponse
} from 'services/apiGateway/payments';

export const salesActions = {
  fetchSummary: createAsyncAction(
    '@advancedAnalytics/sales/fetchSummary/Request',
    '@advancedAnalytics/sales/fetchSummary/Success',
    '@advancedAnalytics/sales/fetchSummary/Error'
  )<PaymentsRequestPayload, PaymentsSalesSummaryResponse | null, string>(),
  fetchSalesGrossNetStat: createAsyncAction(
    '@advancedAnalytics/sales/fetchSalesGrossNetStat/Request',
    '@advancedAnalytics/sales/fetchSalesGrossNetStat/Success',
    '@advancedAnalytics/sales/fetchSalesGrossNetStat/Error'
  )<PaymentsRequestPayload, PaymentsSalesGrossNetStatResponse | null, string>()
};
