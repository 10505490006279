import moment from 'moment-timezone';
import { select } from '@redux-saga/core/effects';
import { call, put, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { AppState } from 'store/rootReducer';
import { get } from 'lodash';
import { campaignEditorActions } from 'store/emailMarketing/campaignEditor';
import { showApiError } from 'store/errors/utils';
import { emCampaignsActions } from './actions';
import { ApiGateway } from '../../../services/apiGateway';
import { history } from '../../rootStore';
import { getPagePath } from '../../../services/navigation';
import { getMenuPages } from '../../navigation';
import { emailTemplateEditorActions } from '../emailTemplateEditor';
import { locationsActions } from '../../locations';

export function* emailMarketingCampaignsSagas() {
  yield takeLatest(getType(emCampaignsActions.getList.request), function* () {
    try {
      const userTimezone = moment.tz.guess();

      const campaigns = yield call(ApiGateway.fetchEmCampaigns, userTimezone);
      yield put(emCampaignsActions.getList.success(campaigns));
    } catch (e) {
      console.error(e);
      yield put(emCampaignsActions.getList.failure());
    }
  });

  yield takeLatest(
    getType(emCampaignsActions.deleteItem.request),
    function* (action: ReturnType<typeof emCampaignsActions.deleteItem.request>) {
      try {
        const id = action.payload;
        yield call(ApiGateway.deleteEmCampaign, id);
        yield put(emCampaignsActions.deleteItem.success(id));
      } catch (e) {
        console.error(e);
        yield put(emCampaignsActions.deleteItem.failure());
      }
    }
  );

  yield takeLatest(
    getType(emCampaignsActions.getItem.request),
    function* (action: ReturnType<typeof emCampaignsActions.getItem.request>) {
      const { id, status } = action.payload;

      try {
        const details = yield call(ApiGateway.viewEmCampaignDetails, id, status);
        yield put(emCampaignsActions.getItem.success({ details, id }));
      } catch (e) {
        console.error(e);
        yield put(emCampaignsActions.getItem.failure(id));
      }
    }
  );

  yield takeLatest(
    getType(emCampaignsActions.getItemData.request),
    function* (action: ReturnType<typeof emCampaignsActions.getItemData.request>) {
      try {
        const { payload } = action;
        const state: AppState = yield select();
        const campaign = yield call(ApiGateway.getCampaign, payload.id);

        const { templateId, includeContactsWithoutLocations, demographic } = campaign;

        const response = yield call(ApiGateway.selectCustomerTemplate, templateId);
        const template = get(response, '[0]', {});

        yield put(emailTemplateEditorActions.setTemplate(template));
        yield put(campaignEditorActions.selectOptionsTab(1));
        yield put(campaignEditorActions.selectCampaign(campaign));

        yield put(locationsActions.isContactsWithoutLocations(includeContactsWithoutLocations));
        yield put(locationsActions.select(demographic.map((d: any) => d.merchantSequenceKey)));

        yield put(emCampaignsActions.getItemData.success());
        history.push(`${getPagePath(getMenuPages(state), 'emailMarketing_root', 'emailMarketing_createCampaign')}`);
      } catch (error) {
        yield put(emCampaignsActions.getItemData.failure());
      }
    }
  );

  yield takeLatest(
    [
      emCampaignsActions.getItemData.failure,
      emCampaignsActions.deleteItem.failure,
      emCampaignsActions.getItem.failure,
      emCampaignsActions.getList.failure
    ],
    showApiError
  );
}
