import { ActionType, createReducer } from 'typesafe-actions';
import { authAndDeclinesActions } from './actions';
import { AuthAndDeclinesState } from './types';

const initialState: AuthAndDeclinesState = {
  declineCodesTableOptions: {
    sorted: [
      { id: 'percent', desc: true },
      { id: 'code', desc: true },
      { id: 'description', desc: false }
    ],
    pageSize: 10,
    page: 0
  },
  summary: {
    data: {},
    pending: false
  },
  declineCodes: {
    data: [],
    pending: false
  },
  authRate: {
    data: {},
    pending: false
  }
};

export const authAndDeclinesReducer = createReducer<AuthAndDeclinesState, ActionType<typeof authAndDeclinesActions>>(
  initialState
)
  .handleAction(authAndDeclinesActions.setDeclineCodesTableOptions, (state, action) => ({
    ...state,
    declineCodesTableOptions: action.payload
  }))
  .handleAction(
    authAndDeclinesActions.fetchSummary.request,
    (state): AuthAndDeclinesState => ({
      ...state,
      summary: {
        ...state.summary,
        pending: true,
        error: undefined
      }
    })
  )
  .handleAction(
    authAndDeclinesActions.fetchSummary.success,
    (state, { payload }): AuthAndDeclinesState => ({
      ...state,
      summary: {
        ...state.summary,
        data: payload,
        pending: false,
        error: undefined
      }
    })
  )
  .handleAction(
    authAndDeclinesActions.fetchSummary.failure,
    (state, { payload }): AuthAndDeclinesState => ({
      ...state,
      summary: {
        ...state.summary,
        data: {},
        pending: false,
        error: payload
      }
    })
  )
  .handleAction(
    authAndDeclinesActions.fetchDeclineCodes.request,
    (state): AuthAndDeclinesState => ({
      ...state,
      declineCodes: {
        ...state.declineCodes,
        pending: true,
        error: undefined
      }
    })
  )
  .handleAction(
    authAndDeclinesActions.fetchDeclineCodes.success,
    (state, { payload }): AuthAndDeclinesState => ({
      ...state,
      declineCodes: {
        ...state.declineCodes,
        data: payload,
        pending: false,
        error: undefined
      }
    })
  )
  .handleAction(
    authAndDeclinesActions.fetchDeclineCodes.failure,
    (state, { payload }): AuthAndDeclinesState => ({
      ...state,
      declineCodes: {
        ...state.declineCodes,
        data: [],
        pending: false,
        error: payload
      }
    })
  )
  .handleAction(
    authAndDeclinesActions.fetchAuthRateStats.request,
    (state): AuthAndDeclinesState => ({
      ...state,
      authRate: {
        ...state.authRate,
        pending: true,
        error: undefined
      }
    })
  )
  .handleAction(
    authAndDeclinesActions.fetchAuthRateStats.success,
    (state, { payload }): AuthAndDeclinesState => ({
      ...state,
      authRate: {
        ...state.authRate,
        data: payload,
        pending: false,
        error: undefined
      }
    })
  )
  .handleAction(
    authAndDeclinesActions.fetchAuthRateStats.failure,
    (state, { payload }): AuthAndDeclinesState => ({
      ...state,
      authRate: {
        ...state.authRate,
        data: {},
        pending: false,
        error: payload
      }
    })
  );
