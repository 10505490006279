import * as googleMap from 'services/googleMaps';
import { cacheLayer } from 'services/apiGateway';

export const getPolygonCoordinates = async (postalCode: string, countryCode: string) => {
  try {
    // await uses here to handle error on getting postal codes info and return correct data in case of the error
    return await cacheLayer.getPostalCodes(postalCode, countryCode);
  } catch (e) {
    console.error(`Unable to get polygon coordinates for postalCode: ${postalCode} countryCode: ${countryCode}`);
    return { polygons: [[]] };
  }
};

export const getDetailedZipInfo = async (zip: string, countryCode: string) => {
  const rawGoogleData = await googleMap.getAddressInfo(zip, countryCode);

  return {
    address: formatAddress(googleMap.getAddress(rawGoogleData, zip)),
    coordinates: googleMap.getCoordinates(rawGoogleData)
  };
};

const formatAddress = (address: string) => {
  const addressToArray = address.split(', ');
  const addressWithoutCountry = addressToArray.slice(0, -1);
  return addressToArray.length && addressWithoutCountry.length ? addressWithoutCountry.join(', ') : address;
};
