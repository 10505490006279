import { createAsyncAction, createAction } from 'typesafe-actions';
import { FetchReviewsSummaryResponse } from './types';

const fetchReviewsSummary = createAsyncAction(
  '@merchantCentric/reviewsSummary/fetch',
  '@merchantCentric/reviewsSummary/success',
  '@merchantCentric/reviewsSummary/error'
)<void, FetchReviewsSummaryResponse, void>();

const resetReviewsSummary = createAction('@merchantCentric/reviewsSummary/reset', action => () => action());

export const reviewsSummaryActions = {
  fetchReviewsSummary,
  resetReviewsSummary
};
